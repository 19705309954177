/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Tabs } from "@material-ui/core";
import { deleteDB, getDB, getDBComplex } from '../../../../crud/api';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";
import { GenerateSubTabs } from '../../Components/Translations/tabsTitles';
import TableComponent from '../../Components/TableComponent';
import ModalLayoutEmployees from './modals/ModalLayoutEmployees';
import ModalLayoutStages from './modals/ModalLayoutStages';
import { GetTranslatedValue, showWarningMessage } from '../../utils';

const createLayoutsStageRow = (id, name, selectedStage, sendMessageAt, creator, creationDate, updateDate) => {
  return { id, name, selectedStage, sendMessageAt, creator, creationDate, updateDate };
};

const createLayoutsEmployeeRow = (id, name, creator, creationDate, updateDate) => {
  return { id, name, creator, creationDate, updateDate };
};

const layoutHasEmployees = (layoutId) => {
  return getDBComplex({
    collection: 'employees',
    condition: [{ "layoutSelected.value": layoutId }]
  })
      .then((response) => response.json())
      .then((data) => !isEmpty(data?.response || []))
      .catch((error) => console.log(error));
};

const collections = {
  layoutsEmployees: {
    id: 'idLayoutEmployee',
    modal: 'openLayoutEmployeesModal',
    name: 'settingsLayoutsEmployees'
  },
  layoutsStages: {
    id: 'idLayoutStage',
    modal: 'openLayoutStagesModal',
    name: 'settingsLayoutsStages'
  }
};

const LayoutsPresets = props => {
  const [control, setControl] = useState({
    idLayoutEmployee: null,
    openLayoutEmployeesModal: false,
    layoutEmployeesRows: null,
    layoutEmployeesRowsSelected: [],
    idLayoutStage: null,
    openLayoutStagesModal: false,
    layoutStagesRows: null,
    layoutStagesRowsSelected: [],
  });
  const [tab, setTab] = useState(0);
  const messages = {
    layoutDelete: GetTranslatedValue('NO.DELETE.EMPLOYEE.LAYOUT')
  };

  const layoutsHeadRows = [
    { id: "name", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.NAME') },
    { id: "creator", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATOR') },
    { id: "creationDate", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE') },
    { id: "updateDate", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE') }
  ];

  const stagesLayoutsHeadRows = [
    { id: "name", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.NAME') },
    { id: "selectedStage", numeric: false, disablePadding: false, label: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.CAPTION.STAGE') },
    { id: "sendMessageAt", numeric: false, disablePadding: false, label: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.CAPTION.SEND.MESSAGE') },
    { id: "creator", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATOR') },
    { id: "creationDate", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE') },
    { id: "updateDate", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE') }
  ];

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({ ...control, [collection.id]: null, [collection.modal]: true, [`${collection.name}ReadOnly`]: false })
      },
      onEdit(id) {
        setControl({ ...control, [collection.id]: id, [collection.modal]: true, [`${collection.name}ReadOnly`]: false })
      },
      async onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }
        const copyArray = JSON.parse(JSON.stringify(id));

        if (collection.name === 'settingsLayoutsEmployees') {
          for (let i = 0; i < copyArray.length; i++) {
            const flag = await layoutHasEmployees(copyArray[i]);

            if (flag) {
              const index = id.indexOf(copyArray[i]);
              id.splice(index, 1);
              showWarningMessage(messages.layoutDelete);
            }
          }
        }

        id.forEach(_id => {
          deleteDB(`${collection.name}/`, _id)
            .then(response => {
              loadLayoutsData(collection.name);
            })
            .catch(error => console.log('Error', error));
        });
      },
      onSelect(id) {
        if (collectionName === 'references') {
          // setReferencesSelectedId(id);
        }
      },
      onView(id) {
        setControl({ ...control, [collection.id]: id, [collection.modal]: true, [`${collection.name}ReadOnly`]: true })
      }
    }
  };
  const loadLayoutsData = (collectionNames = ['settingsLayoutsEmployees', 'settingsLayoutsStages']) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach(collectionName => {
      getDB(collectionName)
        .then(response => response.json())
        .then(async (data) => {
          if (collectionName === 'settingsLayoutsEmployees') {
            const rows = data.response.map(row => {
              return createLayoutsEmployeeRow(row._id, row.name, row.creationUserFullName, row.creationDate, row.updateDate);
            });
            setControl(prev => ({ ...prev, layoutEmployeesRows: rows, layoutEmployeesRowsSelected: [] }));
          }
          if (collectionName === 'settingsLayoutsStages') {
            const rows = data.response.map(row => {
              const sendMessage = row.sendMessageAt === 'start' ? 'At the start' : 'At the end';
              return createLayoutsStageRow(row._id, row.name, row.stageName || 'N/A', sendMessage, row.creationUserFullName, row.creationDate, row.updateDate);
            });
            setControl(prev => ({ ...prev, layoutStagesRows: rows, layoutStagesRowsSelected: [] }));
          }
        })
        .catch(error => console.log('error>', error));
    });
  };

  useEffect(() => {
    loadLayoutsData();
  }, []);

  return (
    <div>
      <Portlet>
        <PortletHeader
          toolbar={
            <PortletHeaderToolbar>
              <Tabs
                component="div"
                className="builder-tabs"
                value={tab}
                onChange={(_, nextTab) => {
                  setTab(nextTab);
                }}
              >
                {GenerateSubTabs('settings', 'Layouts & Presets')}
              </Tabs>
            </PortletHeaderToolbar>
          }
        />
        {tab === 0 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <ModalLayoutEmployees
                    showModal={control.openLayoutEmployeesModal}
                    setShowModal={(onOff) => setControl({ ...control, openLayoutEmployeesModal: onOff })}
                    readOnly={control.settingsLayoutsEmployeesReadOnly}
                    reloadTable={() => loadLayoutsData('settingsLayoutsEmployees')}
                    id={control.idLayoutEmployee}
                    employeeProfileRows={[]}
                  />
                  <div className="kt-section__content">
                    <TableComponent
                      title={GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.EMPLOYEE.LIST')}
                      headRows={layoutsHeadRows}
                      rows={control.layoutEmployeesRows}
                      onEdit={tableActions('layoutsEmployees').onEdit}
                      onAdd={tableActions('layoutsEmployees').onAdd}
                      onDelete={tableActions('layoutsEmployees').onDelete}
                      onSelect={tableActions('layoutsEmployees').onSelect}
                      onView={tableActions('layoutsEmployees').onView}
                      permissions={props.permissions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
        {tab === 1 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <div className="kt-section">
                    <ModalLayoutStages
                      showModal={control.openLayoutStagesModal}
                      setShowModal={(onOff) => setControl({ ...control, openLayoutStagesModal: onOff })}
                      readOnly={control.settingsLayoutsStagesReadOnly}
                      reloadTable={() => loadLayoutsData('settingsLayoutsStages')}
                      id={control.idLayoutStage}
                    />
                    <div className="kt-section__content">
                      <TableComponent
                        title={GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.STAGE.LIST')}
                        headRows={stagesLayoutsHeadRows}
                        rows={control.layoutStagesRows}
                        onEdit={tableActions('layoutsStages').onEdit}
                        onAdd={tableActions('layoutsStages').onAdd}
                        onDelete={tableActions('layoutsStages').onDelete}
                        onSelect={tableActions('layoutsStages').onSelect}
                        onView={tableActions('layoutsStages').onView}
                        permissions={props.permissions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
      </Portlet>
    </div>
  );
}

export default LayoutsPresets;
