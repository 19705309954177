/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import {
  makeStyles
} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getOneDB, updateDB, postDB, getDB, getCountDB, updateManyDB } from '../../../../../crud/api';
import { actions } from '../../../../../store/ducks/general.duck';
import CustomRecordModal from '../../../Components/CustomRecordModal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { GetTranslatedValue } from '../../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ModalLayoutEmployees = ({ readOnly, showModal, setShowModal, reloadTable, id, employeeProfileRows }) => {
  const dispatch = useDispatch();
  const { showCustomAlert, showSavedAlert, showUpdatedAlert } = actions;
  const layoutNameMessage = GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.MODAL.ALERT.NAME');
  const layoutBodyMessage = GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.MODAL.ALERT.BODY');
  const classes = useStyles();
  const [values, setValues] = useState({
    name: ""
  });
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [used, setUsed] = useState(0);

  const getNumberOfEmployees = (layoutId) => {
    return getCountDB({
      collection: 'employees',
      condition: [{ "layoutSelected.value": layoutId }]
    })
      .then((response) => response.json())
      .then((data) => data?.response?.count)
      .catch((error) => console.log(error));
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const updateChildrenLayoutName = (layoutId) => {
    return updateManyDB({
      body: { layoutSelected: { value: layoutId, label: values.name } },
      collection: 'employees',
      condition: [{ "layoutSelected.value": layoutId }]
    });
  };

  const handleSave = () => {
    if (!values.name) {
      dispatch(showCustomAlert({
        message: layoutNameMessage,
        open: true,
        type: 'warning'
      }));
      return;
    }

    if (!editor.getCurrentContent().hasText()) {
      dispatch(showCustomAlert({
        message: layoutBodyMessage,
        open: true,
        type: 'warning'
      }));
      return;
    }

    const layout = draftToHtml(convertToRaw(editor.getCurrentContent()));
    const body = { ...values, layout };

    if (!id) {
      postDB('settingsLayoutsEmployees', body)
        .then(data => data.json())
        .then(response => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('settingsLayoutsEmployees', _id);
        })
        .catch(error => console.log('ERROR', error));
    } else {
      updateDB('settingsLayoutsEmployees/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          dispatch(showUpdatedAlert());
          saveAndReload('settingsLayoutsEmployees', id[0]);
          const { response: { value } } = data;

          if (value.name !== body.name) {
            updateChildrenLayoutName(id[0]);
          }
        })
        .catch(error => console.log(error));
    }
    handleCloseModal();
  };

  const saveAndReload = (folderName, id) => {
    reloadTable();
  };

  const handleCloseModal = () => {
    setValues({
      name: ""
    });
    setShowModal(false);
    setEditor(EditorState.createEmpty());
  };

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('settingsLayoutsEmployees/', id[0])
      .then(response => response.json())
      .then(async (data) => {
        const { name, layout = '<p></p>' } = data.response;
        setValues({ ...values, name });
        const contentBlock = htmlToDraft(layout);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const numberOfEmployees = await getNumberOfEmployees(id[0]);
        setUsed(numberOfEmployees);
        setEditor(EditorState.createWithContent(contentState));
      })
      .catch(error => console.log(error));
  }, [id, employeeProfileRows, showModal]);


  const layoutEmployeeVariables = [
    { id: 'employee_id', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.EMPLOYEE.ID') },
    { id: 'employeeName', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.EMPLOYEE.NAME') },
    { id: 'email', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.EMPLOYEE.EMAIL') },
    { id: 'employeeProfile', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.EMPLOYEE.PROFILE') },
    { id: 'employeeAssets', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.EMPLOYEE.ASSETS') },
    { id: 'currentDate', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.CURRENT.DATE') },
    { id: 'currentTime', name: GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.VARIABLE.CURRENT.TIME') },
  ];

  const insertVariable = (varId) => {
    if (readOnly) {
      return;
    }
    const contentState = Modifier.replaceText(
      editor.getCurrentContent(),
      editor.getSelection(),
      `%{${varId}}`,
      editor.getCurrentInlineStyle(),
    );
    setEditor(EditorState.push(editor, contentState, 'insert-characters'))
  };

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Locations-Profile-Modal"
      readOnly={readOnly}
      showModal={showModal}
      title="SETTINGS.LAYOUTS.EMPLOYEES.MODAL.CAPTION"
    >
      <div name="Expansion Panel" style={{ width: '95%', margin: '15px' }}>
        {/* Custom Controls */}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.CAPTION.LAYOUT.DETAILS')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {id && (
              <>
                <Typography variant="subtitle" style={{ fontWeight: 'bold' }}>
                  {`${GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.CAPTION.USED')}:`}
                </Typography>
                <Typography>
                  {used}
                </Typography>
              </>
            )}
            <TextField
              disabled={readOnly}
              id="standard-name"
              label={GetTranslatedValue('RECORD.CAPTION.NAME')}
              value={values.name}
              onChange={handleChange('name')}
              margin="normal"
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* Tab Properties */}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading} disabled={readOnly}>
              {GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.CAPTION.LAYOUT.VARIABLE')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className='custom-controls-wrapper'>
              {layoutEmployeeVariables.map((variable, ix) => {
                return (
                  <div
                    key={`custom-control-${ix}`}
                    className='custom-controls-wrapper__element'
                    onClick={() => insertVariable(variable.id)}
                  >
                    <span style={{ textAlign: 'center', width: '100%' }}>{variable.name}</span>
                  </div>
                )
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* Field Properties */}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>
              {GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.CAPTION.FIELD.PROPERTIES')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="field-properties-wrapper">
              <div style={{ marginTop: '0px', marginBottom: '20px' }}>
                <Editor
                  readOnly={readOnly}
                  editorState={editor}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={ed => setEditor(ed)}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </CustomRecordModal>
  )
};

export default ModalLayoutEmployees;
