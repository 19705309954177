import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { filterObject, sortByArray } from '../../../_metronic/utils/utils';
import { LayoutSplashScreen } from '../../../_metronic';
import { protectedRoutes } from '../../router/protectedRoutes';
import { nonProtectedRoutes } from '../../router/nonProtectedRoutes';
import { modules, hasAnyReportCollectionToGenerate } from './constants';

function HomePage({ user }) {
  const userRoutes = Object.keys(
    filterObject(user?.profilePermissions, (element) =>
      Object.entries(element).find(([key, value]) => {
        const isReportsGeneral = key === 'general';

        if (isReportsGeneral) {
          return hasAnyReportCollectionToGenerate(value);
        } else {
          return !isEmpty(value);
        }
      })
    )
  );
  const sortedUserRoutes = sortByArray(
    userRoutes,
    modules.map(({ key }) => key)
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        {!isEmpty(userRoutes) ? (
          <Redirect exact from="/" to={`/${sortedUserRoutes[0]}`} />
        ) : (
          <Redirect exact from="/" to="/messages" />
        )}
        {protectedRoutes.map(
          ({ name, path, component }) =>
            userRoutes.includes(name) && (
              <Route path={path} component={component} key={`protected-route-${name}`} />
            )
        )}
        {nonProtectedRoutes.map(({ name, path, component }) => (
          <Route path={path} component={component} key={`nonProtected-route-${name}`} />
        ))}
        <Redirect to="/error/error-v1" />
        <Redirect to="/logout" />
      </Switch>
    </Suspense>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(HomePage);
