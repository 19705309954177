/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
  Select,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeItem, TreeView } from '@material-ui/lab';
import { actions } from '../../../../../store/ducks/general.duck';
import { getDB, getOneDB, updateDB, postDB } from '../../../../../crud/api';
import CustomRecordModal from '../../../Components/CustomRecordModal';
import './ModalLayoutStages.scss';
import { GetTranslatedValue } from '../../../utils';

// Example 1 - TextField
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  layoutInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%'
  },
  layoutInput: {
    marginTop: 'unset',
    width: '33%',
    margin: '0 20px'
  },
  customFieldWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    marginBottom: '10px'
  },
  customFieldSelector: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%'
  },
  '@media (max-width: 600px)': {
    layoutInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center'
    },
    layoutInput: {
      marginTop: 'unset',
      width: '80%',
      margin: '20px 20px'
    },
    customFieldWrapper: {
      flexDirection: 'column'
    },
    customFieldSelector: {
      width: '100%'
    }
  }
}));

const ModalLayoutStages = ({
  readOnly,
  showModal,
  setShowModal,
  reloadTable,
  id,
  employeeProfileRows
}) => {
  const dispatch = useDispatch();
  const { showCustomAlert } = actions;
  // Example 1 - TextField
  const classes = useStyles();
  const [values, setValues] = useState({
    name: ''
  });
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [rejectEditor, setRejectEditor] = useState(EditorState.createEmpty());
  const [goBackEditor, setGoBackEditor] = useState(EditorState.createEmpty());
  const [tab, setTab] = useState(0);
  const [stages, setStages] = useState([]);
  const [stageCustomFields, setStageCustomFields] = useState([]);

  const handleChange = (name) => (event) => {
    if (name === 'email') {
      setValues((prev) => ({ ...prev, [name]: event.target.checked }));
    } else {
      setValues((prev) => ({ ...prev, [name]: event.target.value }));
    }

    if (name === 'sendMessageAt' && event.target.value === 'start') {
      setTab(0);
    }
  };

  const handleSave = () => {
    if (!values.name) {
      dispatch(
        showCustomAlert({
          type: 'warning',
          open: true,
          message: `Please add a name`
        })
      );
      return;
    }
    if (!values.selectedStage) {
      dispatch(
        showCustomAlert({
          type: 'warning',
          open: true,
          message: `Please select a stage`
        })
      );
      return;
    }
    if (!values.sendMessageAt) {
      dispatch(
        showCustomAlert({
          type: 'warning',
          open: true,
          message: `Please select when to send the message`
        })
      );
      return;
    }
    const layout = draftToHtml(convertToRaw(editor.getCurrentContent()));
    const body = { ...values, layout };

    if (body.sendMessageAt === 'end') {
      const rejectLayout = draftToHtml(convertToRaw(rejectEditor.getCurrentContent()));
      const goBackLayout = draftToHtml(convertToRaw(goBackEditor.getCurrentContent()));

      body.rejectLayout = rejectLayout;
      body.goBackLayout = goBackLayout;
    }

    if (!id) {
      postDB('settingsLayoutsStages', body)
        .then((data) => data.json())
        .then((response) => {
          const { _id } = response.response[0];
          saveAndReload('settingsLayoutsStages', _id);
        })
        .catch((error) => console.log('ERROR', error));
    } else {
      updateDB('settingsLayoutsStages/', body, id[0])
        .then((response) => {
          saveAndReload('settingsLayoutsStages', id[0]);
        })
        .catch((error) => console.log(error));
    }
    handleCloseModal();
  };

  const saveAndReload = (folderName, id) => {
    reloadTable();
  };

  const handleCloseModal = () => {
    setValues({
      name: ''
    });
    setStageCustomFields([]);
    setShowModal(false);
    setEditor(EditorState.createEmpty());
    setRejectEditor(EditorState.createEmpty());
    setGoBackEditor(EditorState.createEmpty());
    setTab(0);
    setStages([]);
  };

  const initEditor = (layout, setEditor) => {
    const contentBlock = htmlToDraft(layout);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    setEditor(EditorState.createWithContent(contentState));
  };

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('settingsLayoutsStages/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const {
          name,
          selectedType,
          selectedStage,
          layout = '<p></p>',
          rejectLayout,
          goBackLayout,
          sendMessageAt,
          stageName,
          email
        } = data.response;
        setValues({
          ...values,
          name,
          selectedType,
          selectedStage,
          sendMessageAt,
          stageName,
          email
        });
        initEditor(layout, setEditor);
        initEditor(rejectLayout, setRejectEditor);
        initEditor(goBackLayout, setGoBackEditor);
      })
      .catch((error) => console.log(error));
  }, [id, employeeProfileRows, showModal]);

  useEffect(() => {
    getDB('processStages')
      .then((response) => response.json())
      .then((data) => {
        const stages = data.response.map(({ _id, name }) => ({ id: _id, name }));
        setStages(stages);
      })
      .catch((error) => console.log(error));
  }, [showModal]);

  useEffect(() => {
    if (values.selectedStage) {
      getOneDB('processStages/', values.selectedStage)
        .then((response) => response.json())
        .then((data) => {
          const allCustomFields = [];
          Object.values(data.response.customFieldsTab || {}).forEach((tab) => {
            const localCustomFields = [...tab.left, ...tab.right];
            allCustomFields.push(...localCustomFields);
          });
          setStageCustomFields(allCustomFields);
          setValues({ ...values, stageName: data.response.name });
        })
        .catch((error) => console.log(error));
    } else {
      setStageCustomFields([]);
    }
  }, [values.selectedStage]);

  const stageVariables = [
    { id: 'stageName', name: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.VARIABLE.STAGE.NAME') },
    {
      id: 'creationDate',
      name: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.VARIABLE.PROCESS.CREATION.DATE')
    },
    { id: 'creator', name: GetTranslatedValue('RECORD.CAPTION.CREATOR') },
    { id: 'approvals', name: GetTranslatedValue('PROCESSES.CAPTION.APPROVALS') },
    { id: 'notifications', name: GetTranslatedValue('TOPBAR.TOOLTIP.NOTIFICATIONS') },
    {
      id: 'approvalsInfo',
      name: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.VARIABLE.APPROVALS.INFO')
    },
    {
      id: 'assetsInvolved',
      name: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.VARIABLE.ASSETS.INVOLVED')
    },
    { id: 'status', name: GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.VARIABLE.STATUS') },
    { id: 'folio', name: GetTranslatedValue('PROCESSES.CAPTION.FOLIO') }
  ];

  const insertVariable = (varId) => {
    if (readOnly) {
      return;
    }

    switch (tab) {
      case 0:
        insertVariableToEditor(editor, varId, setEditor);
        break;
      case 1:
        insertVariableToEditor(rejectEditor, varId, setRejectEditor);
        break;
      case 2:
        insertVariableToEditor(goBackEditor, varId, setGoBackEditor);
        break;
      default:
        insertVariableToEditor(editor, varId);
    }
  };

  const insertVariableToEditor = (editor, varId, setEditor) => {
    const contentState = Modifier.replaceText(
      editor.getCurrentContent(),
      editor.getSelection(),
      `%{${varId}}`,
      editor.getCurrentInlineStyle()
    );
    setEditor(EditorState.push(editor, contentState, 'insert-characters'));
  };

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Locations-Profile-Modal"
      readOnly={readOnly}
      showModal={showModal}
      title="SETTINGS.LAYOUTS.STAGES.MODAL.CAPTION"
    >
      <div name="Expansion Panel" style={{ width: '95%', margin: '15px' }}>
        {/* Layout Info */}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} disabled={readOnly}>
              {GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.CAPTION.LAYOUT.INFO')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.layoutInfo}>
              <TextField
                className={classes.layoutInput}
                disabled={readOnly}
                id="standard-name"
                label={GetTranslatedValue('RECORD.CAPTION.NAME')}
                value={values.name}
                onChange={handleChange('name')}
                margin="normal"
              />
              <FormControl className={classes.layoutInput}>
                <InputLabel>{`${GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.SEND')}:`}</InputLabel>
                <Select
                  value={values.sendMessageAt || ''}
                  onChange={handleChange('sendMessageAt')}
                  disabled={readOnly}
                >
                  <MenuItem value={'start'}>
                    {GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.AT.START')}
                  </MenuItem>
                  <MenuItem value={'end'}>
                    {GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.AT.END')}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.layoutInput}>
                <InputLabel>
                  {`${GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.CAPTION.STAGE')}:`}
                </InputLabel>
                <Select
                  value={values.selectedStage || ''}
                  onChange={handleChange('selectedStage')}
                  disabled={readOnly}
                >
                  {(stages || []).map(({ id, name }) => (
                    <MenuItem value={id} key={`menuItem-id-${id}`}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.email || false}
                    color="primary"
                    onChange={handleChange('email')}
                    disabled={readOnly}
                  />
                }
                style={{ margin: 0 }}
                label="Send Email"
                labelPlacement={'start'}
                value="start"
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* Layout Variables */}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              {GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.CAPTION.LAYOUT.VARIABLE')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.customFieldWrapper}>
              <div className={classes.customFieldSelector}>
                <h4>{`${GetTranslatedValue('GENERAL.CAPTION.BASE.FIELDS')}:`}</h4>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  <List className="__container-baseandcustom-panel">
                    {stageVariables.map(({ id, name }, ix) => {
                      return (
                        <TreeItem
                          className="baseform-tree-item"
                          key={`tree-item-catalogue-${ix}`}
                          label={name.capitalize()}
                          nodeId={id}
                          style={{ margin: '0 0 10px 0px' }}
                          onClick={() => insertVariable(id)}
                        ></TreeItem>
                      );
                    })}
                  </List>
                </TreeView>
              </div>
              <div className={classes.customFieldSelector}>
                <h4>{`${GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS')}:`}</h4>
                {values.sendMessageAt === 'start' || stageCustomFields.length === 0 ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CloseIcon />
                    <span>
                      {!values.selectedStage
                        ? GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.WARNING.SELECT.STAGE')
                        : values.sendMessageAt === 'start'
                          ? GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.WARNING.DURING.APPROVAL')
                          : GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.WARNING.NO.CUSTOM.FIELDS')}
                    </span>
                  </div>
                ) : (
                  <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                  >
                    <List className="__container-baseandcustom-panel">
                      {stageCustomFields.map(({ id, values: { fieldName } }, ix) => {
                        return (
                          <TreeItem
                            className="baseform-tree-item"
                            key={`tree-item-catalogue-${ix}`}
                            label={fieldName.capitalize()}
                            nodeId={id}
                            style={{ margin: '0 0 10px' }}
                            onClick={() => insertVariable(id)}
                          ></TreeItem>
                        );
                      })}
                    </List>
                  </TreeView>
                )}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* Field Properties */}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>
              {GetTranslatedValue('SETTINGS.LAYOUTS.EMPLOYEES.CAPTION.FIELD.PROPERTIES')}
            </Typography>
          </ExpansionPanelSummary>
          <Tabs
            className="builder-tabs __message-tabs"
            indicatorColor="secondary"
            component="div"
            onChange={(_, nextTab) => setTab(nextTab)}
            value={tab}
            variant="scrollable"
          >
            <Tab label={GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.APPROVED')} />
            <Tab
              label={GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.REJECTED')}
              hidden={values.sendMessageAt !== 'end'}
            />
            <Tab
              label={GetTranslatedValue('PROCESSES.CAPTION.GO.BACK')}
              hidden={values.sendMessageAt !== 'end'}
            />
          </Tabs>
          {tab === 0 && (
            <ExpansionPanelDetails>
              <div className="field-properties-wrapper">
                <div style={{ marginTop: '0px', marginBottom: '20px' }}>
                  <Editor
                    editorState={editor}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(ed) => setEditor(ed)}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          )}
          {tab === 1 && (
            <ExpansionPanelDetails>
              <div className="field-properties-wrapper">
                <div style={{ marginTop: '0px', marginBottom: '20px' }}>
                  <Editor
                    editorState={rejectEditor}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(ed) => setRejectEditor(ed)}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          )}
          {tab === 2 && (
            <ExpansionPanelDetails>
              <div className="field-properties-wrapper">
                <div style={{ marginTop: '0px', marginBottom: '20px' }}>
                  <Editor
                    editorState={goBackEditor}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(ed) => setGoBackEditor(ed)}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      </div>
    </CustomRecordModal>
  );
};

export default ModalLayoutStages;
