import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { PortletBody } from "../../../../partials/content/Portlet";
import {
  deleteManyDB,
  getDBComplex,
} from "../../../../crud/api";
import TableComponent from "../../Components/TableComponent";
import ModalMyTickets from "./modals/ModalMyTickets";
import { GetTranslatedValue, convertToObjectIdArray } from "../../utils";
import { actions } from '../../../../store/ducks/general.duck.js';

const collections = {
  tickets: {
    id: "idTickets",
    modal: "openTicketsModal",
    name: "tickets",
  },
};

const createTicketsRow = (
  id,
  subject,
  selectedType,
  peaceOfMind,
  creator,
  creationDate
) => {
  return { id, subject, selectedType, peaceOfMind, creator, creationDate };
};

const MyTickets = ({ user }) => {
  const dispatch = useDispatch();
  const { showDeletedAlert } = actions;
  const [control, setControl] = useState({
    idTickets: null,
    openTicketsModal: false,
    ticketsRows: null,
    ticketsRowsSelected: [],
  });

  const ticketsHeadRows = [
    {
      disablePadding: false,
      id: "subject",
      label: GetTranslatedValue("HELP.TICKETS.SUBJECT"),
      numeric: false,
    },
    {
      disablePadding: false,
      id: "selectedType",
      label: GetTranslatedValue("HELP.TICKETS.SELECTEDTYPE"),
      numeric: true,
    },
    {
      disablePadding: false,
      id: "peaceOfMind",
      label: GetTranslatedValue("HELP.TICKETS.PEACEOFMIND"),
      numeric: false,
    },
    {
      disablePadding: false,
      id: "creator",
      label: GetTranslatedValue("RECORD.CAPTION.CREATOR"),
      numeric: false,
    },
    {
      disablePadding: false,
      id: "creationDate",
      label: GetTranslatedValue("RECORD.CAPTION.CREATIONDATE"),
      numeric: false,
    },
  ];

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        deleteManyDB({
          collection: collection.name,
          condition: [{ "_id": { "$in": convertToObjectIdArray(id) } }]
        })
          .then((response) => {
            loadInitData(collection.name);
            dispatch(showDeletedAlert());
          })
          .catch((error) => console.log("Error", error));
      },
      onSelect(id) {
        if (collectionName === "references") {
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const loadInitData = (collectionNames = ["tickets"]) => {
    collectionNames = !Array.isArray(collectionNames)
      ? [collectionNames]
      : collectionNames;
    collectionNames.forEach((collectionName) => {
      getDBComplex({
        collection: collectionName,
        condition: [{ "creationUserId": user.id }]
      })
        .then((response) => response.json())
        .then((data) => {

          if (collectionName === "tickets") {
            const rows = data.response.map((row) => {
              const { _id, message, peaceOfMind, selectedType, subject, creationUserFullName, creationDate } = row;

              return createTicketsRow(
                _id,
                subject,
                selectedType,
                peaceOfMind,
                creationUserFullName,
                creationDate
              );
            });
            setControl((prev) => ({
              ...prev,
              ticketsRows: rows,
              ticketsRowsSelected: [],
            }));
          }
        })
        .catch((error) => console.log("error>", error));
    });
  };

  useEffect(() => {
    loadInitData();
  }, []);
  return (
    <PortletBody>
      <div className="kt-section__body">
        <div className="kt-section">
          <ModalMyTickets
            employeeProfileRows={[]}
            id={control.idTickets}
            readOnly={control.ticketsReadOnly}
            reloadTable={loadInitData}
            setShowModal={(onOff) =>
              setControl({ ...control, openTicketsModal: onOff })
            }
            showModal={control.openTicketsModal}
          />
          <div className="kt-section__content">
            <TableComponent
              title={GetTranslatedValue("HELP.TICKETS.TITTLE")}
              headRows={ticketsHeadRows}
              rows={control.ticketsRows}
              onEdit={tableActions("tickets").onEdit}
              onAdd={tableActions("tickets").onAdd}
              onDelete={tableActions("tickets").onDelete}
              onSelect={tableActions("tickets").onSelect}
              onView={tableActions("tickets").onView}
              permissions={['add', 'edit', 'delete', 'view']}
            />
          </div>
        </div>
      </div>
    </PortletBody>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(MyTickets);
