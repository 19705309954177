/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty } from 'lodash';
import {
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@material-ui/core';
import { actions } from '../../../../store/ducks/general.duck';
import { getOneDB, postDB, updateDB } from '../../../../crud/api';
import CustomFields from '../../Components/CustomFields/CustomFields';
import BaseFields from '../../Components/BaseFields/BaseFields';
import ImageUpload from '../../Components/ImageUpload';
import Permissions from '../components/Permissions';
import {
  getFileExtension,
  saveImage,
  getImageURL,
  GetTranslatedValue,
  verifyRepeatedCustomFields,
  updateChildrenProfileName
} from '../../utils';
import {
  executeOnFieldPolicy,
  executePolicies
} from '../../Components/Policies/utils';
import CustomRecordModal from '../../Components/CustomRecordModal';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalUserProfiles = ({
  fields,
  fieldsToValidate,
  readOnly,
  reloadProfiles,
  showModal,
  setShowModal,
  reloadTable,
  id,
  policies
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    showCustomAlert,
    showErrorAlert,
    showFillFieldsAlert,
    showSavedAlert,
    showUpdatedAlert
  } = actions;
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  function handleChangeIndex4(index) {
    setValue4(index);
  }

  const [values, setValues] = useState({
    name: '',
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg'
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSave = () => {
    setFormValidation({ ...formValidation, enabled: true });

    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (verifyRepeatedCustomFields(customFieldsTab)) {
      const message = intl.formatMessage({
        id: 'WARNING.CUSTOM.FIELDS.REPEATED.NAME',
        defaultMessage: 'Text not rendered'
      })

      dispatch(
        showCustomAlert({
          open: true,
          message,
          type: 'warning'
        })
      );
      return;
    }

    const fileExt = getFileExtension(image);
    const body = { ...values, customFieldsTab, profilePermissions, fileExt };
    if (!id) {
      postDB('userProfiles', body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('userProfiles', _id);
          executePolicies(
            'OnAdd',
            'user',
            'references',
            policies,
            response.response[0]
          );
          executeOnFieldPolicy(
            'OnAdd',
            'user',
            'references',
            policies,
            response.response[0]
          );
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      updateDB('userProfiles/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          const userProfile = { _id: id[0], ...body };

          if (value.name !== body.name) {
            updateChildrenProfileName({
              collection: 'user',
              field: 'selectedUserProfile.value',
              newName: body.name,
              profileId: id[0],
              updateField: 'selectedUserProfile'
            });
          }

          dispatch(showUpdatedAlert());
          saveAndReload('userProfiles', id[0]);
          executePolicies(
            'OnEdit',
            'user',
            'references',
            policies,
            userProfile
          );
          executeOnFieldPolicy(
            'OnEdit',
            'user',
            'references',
            policies,
            userProfile,
            value
          );
        })
        .catch((error) => dispatch(showErrorAlert()));
    }
    handleCloseModal();
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }

    reloadTable();
    reloadProfiles();
  };

  const baseFieldsLocalProps = {
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: {
          readOnly
        }
      }
    }
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setProfilePermissions([]);
    setValues({
      name: '',
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg'
    });
    setShowModal(false);
    setValue4(0);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
  };

  useEffect(() => {
    if (!id || !Array.isArray(id)) {
      return;
    }
  }, []);

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('userProfiles/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const {
          name,
          depreciation,
          customFieldsTab,
          profilePermissions,
          fileExt
        } = data.response;
        const obj = {
          name,
          depreciation,
          imageURL: getImageURL(id, 'userProfiles', fileExt)
        };
        setValues(obj);
        setCustomFieldsTab(customFieldsTab);
        setProfilePermissions(profilePermissions);
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [id, showModal]);

  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });
  const [profilePermissions, setProfilePermissions] = useState({});

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Modal-Users-Profiles"
      readOnly={readOnly}
      showModal={showModal}
      tabs={
        <Tabs
          value={value4}
          onChange={handleChange4}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={GetTranslatedValue('RECORD.CAPTION.PROFILE')} />
          <Tab label={GetTranslatedValue('USER.LIST.MODAL.PERMISSIONS.TITLE')} />
          <Tab label={GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS')} />
        </Tabs>
      }
      title="USER.PROFILES.MODAL.CAPTION"
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value4}
        onChangeIndex={handleChangeIndex4}
        slideStyle={{ overflow: 'hidden' }}
      >
        <TabContainer4 dir={theme4.direction}>
          <div className="profile-tab-wrapper">
            <ImageUpload
              disabled={readOnly}
              id="user-profile-image"
              image={values.imageURL}
              setImage={setImage}
              setInitialImage={setInitialImage}
            >
              {GetTranslatedValue('USER.LIST.MODAL.USER.PHOTO')}
            </ImageUpload>
            <div className="profile-tab-wrapper__content">
              <BaseFields
                catalogue={'userReferences'}
                collection={'userReferences'}
                fields={fields}
                fieldsToValidate={fieldsToValidate}
                formState={[formValidation, setFormValidation]}
                localProps={baseFieldsLocalProps}
                values={values}
              />
            </div>
          </div>
        </TabContainer4>
        <TabContainer4 dir={theme4.direction} css={{ paddingTop: '0px' }}>
          <Permissions
            key="modal-profiles-permissions"
            profilePermissions={profilePermissions}
            readOnly={readOnly}
            setProfilePermissions={setProfilePermissions}
          />
        </TabContainer4>
        <TabContainer4 dir={theme4.direction}>
          <CustomFields
            customFieldsTab={customFieldsTab}
            setCustomFieldsTab={setCustomFieldsTab}
            readOnly={readOnly}
          />
        </TabContainer4>
      </SwipeableViews>
    </CustomRecordModal>
  );
};

export default ModalUserProfiles;
