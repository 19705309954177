/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { isEmpty, uniq } from 'lodash';
import { Card, CardContent, Grid, makeStyles, Tabs, Typography } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import LanguageIcon from '@material-ui/icons/Language';
import CheckIcon from '@material-ui/icons/Check';
import BuildIcon from '@material-ui/icons/Build';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import {
  deleteDB,
  getCountDB,
  getDB,
  getDBComplex,
  getOneDB,
  updateDB,
  getKPIData
} from '../../../crud/api';
import * as general from '../../../store/ducks/general.duck';
import { useFieldValidator } from '../Components/FieldValidator/hooks';
import { loadGroups, updateTableGroupingTree } from '../Components/tableGroupingHelpers';
import { TabsTitles } from '../Components/Translations/tabsTitles';
import TableComponent2 from '../Components/TableComponent2';
import ModalAssetCategories from './modals/ModalAssetCategories';
import ModalAssetReferences from './modals/ModalAssetReferences';
import ModalAssetList from './modals/ModalAssetList';
import Policies from '../Components/Policies/Policies';
import { allBaseFields } from '../constants';
import { executePolicies } from '../Components/Policies/utils';
import { usePolicies } from '../Components/Policies/hooks';
import ModalYesNo from '../Components/ModalYesNo';
import { isMozilla } from '../constants';
import { getCurrentModuleName, getCurrentModuleTab, getFieldCaption, GetTranslatedValue, loadUserLocations, profileHasChildren, showWarningMessage } from '../utils';
import './Assets.scss';
import { getBoolValue } from '../../../../_metronic';

const useStyles = makeStyles((theme) => ({
  assetsInfoContainer: {
    flex: 1,
    justifyContent: 'space-evenly'
  },
  assetsInfoCard: {
    display: 'flex',
    margin: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  cardTextContainer: {
    width: '135px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  cardSnapshot: {
    width: '80px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '30%'
    }
  },
  select: {
    width: '350px',
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  selectContainer: {
    marginTop: '30px',
    textAlign: isMozilla ? '-moz-left' : '-webkit-left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: isMozilla ? '-moz-center' : '-webkit-center'
    }
  }
}));

function Assets({ globalSearch, user, setGeneralSearch, showDeletedAlert, showErrorAlert }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(getCurrentModuleTab('assets', user.profilePermissions));
  const [userLocations, setUserLocations] = useState([]);
  const [kpiSelected, setKpiSelected] = useState([]);
  const { policies, setPolicies } = usePolicies();
  const { allFields, fieldsToValidate } = useFieldValidator('assets');

  const executeFieldCaption = (catalogue, { field, key }) => {
    return getFieldCaption(allFields, { catalogue, field, key });
  };

  const policiesBaseFields = {
    list: {
      id: { validationId: 'assetId', component: 'textField', compLabel: 'ID' },
      ...allBaseFields.assets1,
      ...allBaseFields.assets2,
      translationLabel: 'TABS.TITLES.LIST'
    },
    references: {
      id: { validationId: 'referenceId', component: 'textField', compLabel: 'ID' },
      ...allBaseFields.references,
      translationLabel: 'TABS.TITLES.REFERENCES'
    },
    categories: {
      id: { validationId: 'categoryId', component: 'textField', compLabel: 'ID' },
      ...allBaseFields.categories,
      translationLabel: 'TABS.TITLES.CATEGORIES'
    }
  };

  const createAssetCategoryRow = (
    id,
    name,
    depreciation,
    creator,
    creationDate,
    updateDate,
    fileExt
  ) => {
    return { id, name, depreciation, creator, creationDate, updateDate, fileExt };
  };

  const assetCategoriesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('categories', {
        field: 'name',
        key: allBaseFields.categories.name.translation
      })
    },
    {
      id: 'depreciation',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('categories', {
        field: 'depreciation',
        key: allBaseFields.categories.depreciation.translation
      })
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR'),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const createAssetReferenceRow = (
    id,
    name,
    brand,
    model,
    category,
    price,
    creator,
    creationDate,
    updateDate,
    fileExt
  ) => {
    return {
      id,
      name,
      brand,
      model,
      category,
      creator,
      price,
      creationDate,
      updateDate,
      fileExt
    };
  };

  const assetReferencesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: 'name',
        key: allBaseFields.references.name.translation
      })
    },
    {
      id: 'brand',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: 'brand',
        key: allBaseFields.references.brand.translation
      })
    },
    {
      id: 'model',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: 'model',
        key: allBaseFields.references.model.translation
      })
    },
    {
      id: 'price',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: 'price',
        key: allBaseFields.references.price.translation
      })
    },
    {
      id: 'category',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: 'category',
        key: allBaseFields.references.category.translation
      }),
      correction: 'selectedProfile.label',
      searchByDisabled: true
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: '',
        key: 'RECORD.CAPTION.CREATOR'
      }),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: '',
        key: 'RECORD.CAPTION.CREATIONDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('references', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const createAssetListRow = (
    id,
    name,
    brand,
    model,
    category,
    serial,
    EPC,
    status,
    price,
    creator,
    creationDate,
    updateDate,
    location,
    fileExt,
    children
  ) => {
    return {
      id,
      name,
      brand,
      model,
      category: typeof category === 'object' ? category.label || '' : '',
      serial,
      EPC,
      status,
      price,
      creator,
      creationDate,
      updateDate,
      location,
      fileExt,
      children
    };
  };

  const assetListHeadRows = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
      searchByDisabled: true,
      sortByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'name',
        key: allBaseFields.assets1.name.translation
      })
    },
    {
      id: 'brand',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'brand',
        key: allBaseFields.assets1.brand.translation
      })
    },
    {
      id: 'model',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'model',
        key: allBaseFields.assets1.model.translation
      })
    },
    {
      id: 'category',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'category',
        key: allBaseFields.assets1.category.translation
      }),
      correction: 'category.label',
      searchByDisabled: true
    },
    {
      id: 'serial',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'serialNumber',
        key: allBaseFields.assets1.serial.translation
      })
    },
    {
      id: 'EPC',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'EPC',
        key: allBaseFields.assets2.EPC.translation
      }),
      disableTableGrouping: true
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'status',
        key: allBaseFields.assets1.status.translation
      })
    },
    {
      id: 'price',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'price',
        key: allBaseFields.assets2.price.translation
      })
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'creator',
        key: allBaseFields.assets2.creator.translation
      }),
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: 'creationDate',
        key: allBaseFields.assets2.creationDate.translation
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('assets', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const styleMultipleRows = (data, collection) => {
    if (collection === 'assets') {
      const rows = data.map((row) => {
        return createAssetListRow(
          row._id,
          row.name,
          row.brand,
          row.model,
          row.category,
          row.serial,
          row.EPC,
          row.status,
          row.price,
          row.creationUserFullName,
          row.creationDate,
          row.updateDate,
          row.location,
          row.fileExt,
          row.children || []
        );
      });
      return rows;
    } else if (collection === 'references') {
      const rows = data.map((row) => {
        return createAssetReferenceRow(
          row._id,
          row.name,
          row.brand,
          row.model,
          row.selectedProfile?.label || '',
          row.price,
          row.creationUserFullName,
          row.creationDate,
          row.updateDate,
          row.fileExt
        );
      });
      return rows;
    } else if (collection === 'categories') {
      const rows = data.map((row) => {
        return createAssetCategoryRow(
          row._id,
          row.name,
          row.depreciation,
          row.creationUserFullName,
          row.creationDate,
          row.updateDate,
          row.fileExt
        );
      });
      return rows;
    }
  };

  const [assetsKPI, setAssetsKPI] = useState({
    total: {
      color: '#1E1E2D',
      icon: <LanguageIcon style={{ fill: 'white', fontSize: 35 }} />,
      key: 'total',
      number: 0,
      text: 'Total',
      translation: 'ASSETS.KPI.TOTAL',
    },
    available: {
      color: '#427241',
      icon: <CheckIcon style={{ fill: 'white', fontSize: 35 }} />,
      key: 'active',
      number: 0,
      text: 'Available',
      translation: 'ASSETS.KPI.AVALIABLE',
    },
    onProcess: {
      color: '#3e4fa8',
      icon: <TimelineRoundedIcon style={{ fill: 'white', fontSize: 35 }} />,
      key: 'inProcess',
      number: 0,
      text: 'On Process',
      translation: 'ASSETS.KPI.ON.PROCESS',
    },
    maintenance: {
      color: '#f2b200',
      icon: <BuildIcon style={{ fill: 'white', fontSize: 35 }} />,
      key: 'maintenance',
      number: 0,
      text: 'Maintenance',
      translation: 'ASSETS.KPI.MAINTENANCE',
    },
    decommissioned: {
      color: '#ad2222',
      icon: <NotInterestedIcon style={{ fill: 'white', fontSize: 35 }} />,
      key: 'decommissioned',
      number: 0,
      text: 'Decommissioned',
      translation: 'ASSETS.KPI.DECOMISSIONED',
    }
  });

  const getLocationFilter = (collectionName) => {
    if (collectionName === 'assets') {
      return parentLocations;
    } else {
      return null;
    }
  };

  const getCondition = (collectionName, kpi) => {
    const condition = [{ 'parent': { '$in': [null, ''] } }];
    if (!isEmpty(kpi)) {
      condition.push({ 'status': { '$in': kpi.map(({ value }) => value) } })
    }
    return condition;
  };

  const getInjectedQueryLike = () => {
    if (tableControl.assets.searchBy) {
      return [{ 'children': { '$elemMatch': { [tableControl.assets.searchBy]: { "$regex": `(?i).*${tableControl.assets.search}.*` } } } }]
    } else if (tableControl.assets.search) {
      const fieldsToSearch = [
        'brand',
        'creationUserFullName',
        'creationDate',
        'EPC',
        'model',
        'name',
        'notes',
        'price',
        'purchase_price',
        'quantity',
        'status',
        'serial',
        'total_price',
        'updateDate'
      ];

      const elemMatch = fieldsToSearch.map((key) => ({ [key]: { "$regex": `(?i).*${tableControl.assets.search}.*` } }))
      return [{ 'children': { '$elemMatch': { '$or': elemMatch } } }]
    }
  }

  const getTreeViewLocation = (collectionName) => {
    if (collectionName === 'assets') {
      return tableControl.assets.treeViewLocation || '';
    } else {
      return null;
    }
  };

  const loadAssetsData = (collectionNames = ['assets', 'categories', 'references'], searchBy) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      const localSearchBy = searchBy || tableControl[collectionName].searchBy;
      if (!isEmpty(tableControl[collectionName].tableGrouping)) {
        updateTableGroupingTree(
          collectionName,
          tableControl[collectionName].tableGrouping,
          styleMultipleRows,
          tableControl,
          (newTree) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                tableGrouping: newTree
              }
            }));
          },
          tableControl[collectionName].condition
        );
      }
      let queryLike = '';
      if (collectionName === 'assets') {
        queryLike = localSearchBy
          ? [{ key: localSearchBy, value: tableControl.assets.search }]
          : [
            'brand',
            'category.label',
            'creationUserFullName',
            'creationDate',
            'EPC',
            'model',
            'name',
            'notes',
            'price',
            'purchase_price',
            'quantity',
            'status',
            'serial',
            'total_price',
            'updateDate'
          ].map((key) => ({ key, value: tableControl.assets.search }));
      }
      if (collectionName === 'references') {
        queryLike = localSearchBy
          ? [
            {
              key: localSearchBy,
              value: tableControl.references.search
            }
          ]
          : ['creationDate', 'name', 'brand', 'model', 'selectedProfile.label', 'updateDate'].map((key) => ({
            key,
            value: tableControl.references.search
          }));
      }
      if (collectionName === 'categories') {
        queryLike = localSearchBy
          ? [
            {
              key: localSearchBy,
              value: tableControl.categories.search
            }
          ]
          : ['creationDate', 'description', 'depreciation', 'name', 'updateDate'].map((key) => ({
            key,
            value: tableControl.categories.search
          }));
      }

      const kpi = kpiSelected || [];
      const condition = getCondition(collectionName, kpi);
      const locationsFilter = getLocationFilter(collectionName);
      const treeViewLocation = getTreeViewLocation(collectionName);
      const injectedQueryLike = collectionName === 'assets' ? getInjectedQueryLike() : null;

      if (collectionName === 'assets' && isEmpty(locationsFilter)) {
        return;
      }

      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null,
        condition: collectionName === 'assets' ? condition : null,
        locationsFilter,
        locationsFilterParam: 'location',
        treeViewLocation,
        injectedQueryLike,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.response) {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                total: data.response.count
              }
            }));
          }
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          {
            key: tableControl[collectionName].orderBy,
            value: tableControl[collectionName].order
          }
        ],
        queryLike:
          tableControl[collectionName].search || tableControl['assets'].locationsFilter.length
            ? queryLike
            : null,
        condition: collectionName === 'assets' ? condition : null,
        locationsFilter,
        locationsFilterParam: 'location',
        treeViewLocation,
        injectedQueryLike
      })
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'assets') {
            const rows = data.response.map((row) => {
              return createAssetListRow(
                row._id,
                row.name,
                row.brand,
                row.model,
                row.category,
                row.serial,
                row.EPC,
                row.status,
                row.price,
                row.creationUserFullName,
                row.creationDate,
                row.updateDate,
                row.location,
                row.fileExt,
                row.children || []
              );
            });
            Promise.all(data.response.map((row) => {
              if (!isEmpty(row.children)) {
                return getDBComplex({
                  collection: 'assets',
                  limit: 5,
                  skip: 0,
                  sort: [
                    {
                      key: tableControl[collectionName].orderBy,
                      value: tableControl[collectionName].order
                    }
                  ],
                  condition: [{ 'parent': row._id }],
                })
                  .then((response) => response.json())
                  .then((data) => {
                    const allRows = data.response.map((asset) => (
                      createAssetListRow(
                        asset._id,
                        asset.name,
                        asset.brand,
                        asset.model,
                        asset.category,
                        asset.serial,
                        asset.EPC,
                        asset.status,
                        asset.price,
                        asset.creationUserFullName,
                        asset.creationDate,
                        asset.updateDate,
                        asset.location,
                        asset.fileExt,
                        asset.children || []
                      )
                    ))
                    return {
                      id: row._id,
                      active: true,
                      children: allRows,
                      type: 'data',
                      count: row.children.length || 0,
                      query: {
                        order: tableControl[collectionName].order,
                        orderBy: tableControl[collectionName].orderBy,
                        page: 0,
                        rowsPerPage: 5,
                        condition: [{ 'parent': row._id }]
                      }
                    };
                  })
              } else {
                return null;
              }
            })).then((allData) => {
              const filteredData = allData.filter((data) => data);
              setTableControl((prev) => ({
                ...prev,
                assets: {
                  ...prev.assets,
                  childGrouping: filteredData
                }
              }))
            })
            setControl((prev) => ({ ...prev, assetRows: rows, assetRowsSelected: [] }));
          }
          if (collectionName === 'references') {
            const rows = data.response.map((row) => {
              return createAssetReferenceRow(
                row._id,
                row.name,
                row.brand,
                row.model,
                row.selectedProfile?.label || '',
                row.price,
                row.creationUserFullName,
                row.creationDate,
                row.updateDate,
                row.fileExt
              );
            });
            setControl((prev) => ({
              ...prev,
              referenceRows: rows,
              referenceRowsSelected: []
            }));
          }
          if (collectionName === 'categories') {
            const rows = data.response.map((row) => {
              return createAssetCategoryRow(
                row._id,
                row.name,
                row.depreciation,
                row.creationUserFullName,
                row.creationDate,
                row.updateDate,
                row.fileExt
              );
            });
            setControl((prev) => ({
              ...prev,
              categoryRows: rows,
              categoryRowsSelected: []
            }));
          }

          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              condition: condition
            }
          }));
        })
        .catch((error) => dispatch(showErrorAlert()));
    });
  };

  const [control, setControl] = useState({
    idReference: null,
    openReferencesModal: false,
    referenceRows: null,
    referenceRowsSelected: [],

    idCategory: null,
    openCategoriesModal: false,
    openTileView: false,
    categoryRows: null,
    categoryRowsSelected: [],

    idAsset: null,
    openAssetsModal: false,
    openTreeView: false,
    treeViewFiltering: [],
    assetRows: null,
    assetRowsSelected: []
  });

  const [tableControl, setTableControl] = useState({
    references: {
      collection: 'references',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    },
    assets: {
      collection: 'assets',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: '',
      locationsFilter: []
    },
    categories: {
      collection: 'categories',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    }
  });

  const [referencesSelectedId, setReferencesSelectedId] = useState(null);
  const [selectReferenceConfirmation, setSelectReferenceConfirmation] = useState(false);
  const [parentLocations, setParentLocations] = useState([]);

  const collections = {
    references: {
      id: 'idReference',
      modal: 'openReferencesModal',
      name: 'references'
    },
    categories: {
      id: 'idCategory',
      modal: 'openCategoriesModal',
      name: 'categories'
    },
    assets: {
      id: 'idAsset',
      modal: 'openAssetsModal',
      name: 'assets'
    }
  };

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        if (!referencesSelectedId && collectionName === 'assets') {
          setSelectReferenceConfirmation(true);
          return;
        }
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        getDB('policies')
          .then((response) => response.json())
          .then(async (data) => {
            const { response } = data;
            const copyArray = JSON.parse(JSON.stringify(id));

            if (collection.name === 'references') {
              for (let i = 0; i < copyArray.length; i++) {
                const flag = await profileHasChildren('assets', copyArray[i], 'referenceId');

                if (flag) {
                  const index = id.indexOf(copyArray[i]);
                  id.splice(index, 1);
                  showWarningMessage("A reference can't be delete because there are assets using it");
                }
              }
            } else if (collection.name === 'categories') {
              let flag;

              for (let i = 0; i < copyArray.length; i++) {
                flag = await profileHasChildren('references', copyArray[i], 'selectedProfile.value');

                if (flag) {
                  const index = id.indexOf(copyArray[i]);
                  id.splice(index, 1);
                  showWarningMessage("A category can't be delete because there are references using it");
                } else {
                  flag = await profileHasChildren('settingsAssetSpecialists', copyArray[i], 'categorySelected.value');

                  if (flag) {
                    const index = id.indexOf(copyArray[i]);
                    id.splice(index, 1);
                    showWarningMessage("A category can't be delete because there are assets specialists using it");
                  }
                }
              }
            }

            id.forEach((_id) => {
              deleteDB(`${collection.name}/`, _id)
                .then((response) => response.json())
                .then((data) => {
                  const {
                    response: {
                      value,
                      value: { children, parent, assigned }
                    }
                  } = data;

                  dispatch(showDeletedAlert());
                  const currentCollection = collection.name === 'assets' ? 'list' : collection.name;
                  executePolicies('OnDelete', 'assets', currentCollection, response, value);
                  loadAssetsData(collection.name);

                  if (!isEmpty(children)) {
                    children.forEach(({ id: childId }) => {
                      updateDB('assets/', { parent: '' }, childId).catch((error) =>
                        console.log(error)
                      );
                    });
                  }

                  if (parent) {
                    getOneDB('assets/', parent)
                      .then((response) => response.json())
                      .then((data) => {
                        const {
                          response: { children }
                        } = data;
                        const newChildren = children.filter(({ id: childId }) => childId !== _id);
                        updateDB('assets/', { children: newChildren }, parent).catch((error) =>
                          console.log(error)
                        );
                      })
                      .catch((error) => console.log(error));
                  }

                  if (assigned) {
                    if (assigned.length) {
                      getOneDB('employees/', assigned)
                        .then((response) => response.json())
                        .then((data) => {
                          const {
                            response: {
                              value: { assetsAssigned }
                            }
                          } = data;
                          const list = assetsAssigned.filter(
                            ({ id: assignmentId }) => assignmentId !== _id
                          );
                          updateDB(
                            'employees/',
                            { assetsAssigned: list },
                            assigned
                          ).catch((error) => console.log(error));
                        })
                        .catch((error) => console.log(error));
                    }
                  }
                })
                .catch((_) => showErrorAlert());
            });
          })
          .catch((_) => dispatch(showErrorAlert()));
      },
      onSelect(id) {
        if (collectionName === 'references') {
          setReferencesSelectedId(id);
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const loadLocations = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: user?.id
    });
    setUserLocations(userLocations);
  };

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    loadAssetsData('assets');
  }, [
    tableControl.assets.page,
    tableControl.assets.rowsPerPage,
    tableControl.assets.order,
    tableControl.assets.orderBy,
    tableControl.assets.search,
    tableControl.assets.locationsFilter,
    tableControl.assets.treeViewLocation,
    userLocations,
    kpiSelected
  ]);

  useEffect(() => {
    loadAssetsData('references');
  }, [
    tableControl.references.page,
    tableControl.references.rowsPerPage,
    tableControl.references.order,
    tableControl.references.orderBy,
    tableControl.references.search
  ]);

  useEffect(() => {
    loadAssetsData('categories');
  }, [
    tableControl.categories.page,
    tableControl.categories.rowsPerPage,
    tableControl.categories.order,
    tableControl.categories.orderBy,
    tableControl.categories.search
  ]);

  const kpis = [
    {
      kpi: 'total',
      condition: {
        status: { $in: ['active', 'inProcess', 'maintenance', 'decommissioned'] }
      },
      locationsFilter: parentLocations,
    },
    {
      kpi: 'available',
      condition: { status: 'active' },
      locationsFilter: parentLocations,
    },
    {
      kpi: 'onProcess',
      condition: { status: 'inProcess' },
      locationsFilter: parentLocations,
    },
    {
      kpi: 'maintenance',
      condition: { status: 'maintenance' },
      locationsFilter: parentLocations,
    },
    {
      kpi: 'decommissioned',
      condition: { status: 'decommissioned' },
      locationsFilter: parentLocations,
    }
  ];

  const kpisToSelect = [
    { value: 'active', label: 'Available' },
    { value: 'inProcess', label: 'On Process' },
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'decommissioned', label: 'Decommissioned' }
  ];

  const tabIntToText = ['assets', 'references', 'categories'];

  useEffect(() => {
    if (globalSearch.tabIndex >= 0) {
      setTab(globalSearch.tabIndex);
      setTableControl((prev) => ({
        ...prev,
        [tabIntToText[globalSearch.tabIndex]]: {
          ...prev[tabIntToText[globalSearch.tabIndex]],
          search: globalSearch.searchValue
        }
      }));
      setGeneralSearch({});
    }
  }, [globalSearch.tabIndex, globalSearch.searchValue]);

  const handleKpiCardClick = (kpi) => {
    if (kpi.value === 'total') {
      setKpiSelected(kpisToSelect);
      return;
    } else {
      const kpiFound = (kpiSelected || []).findIndex(({ value }) => value === kpi.value);

      if (kpiFound < 0) {
        setKpiSelected((prev) => [...(prev || []), kpi]);
        return;
      } else {
        const kpiCopy = JSON.parse(JSON.stringify(kpiSelected));
        kpiCopy.splice(kpiFound, 1);
        setKpiSelected(kpiCopy);
      }
    }
  };

  const updateKPIS = async () => {
    const getKpisConditions = kpis.map(({ kpi, condition }) => ({ kpi, condition }));

    getKPIData({
      locations: parentLocations,
      kpis: getKpisConditions
    })
      .then((response) => response.json())
      .then((data) => {
        const {
          response: { total, available, onProcess, maintenance, decommissioned }
        } = data;
        setAssetsKPI((prev) => ({
          ...prev,
          total: {
            ...prev.total,
            number: String(total || 0)
          },
          available: {
            ...prev.available,
            number: String(available || 0)
          },
          onProcess: {
            ...prev.onProcess,
            number: String(onProcess || 0)
          },
          maintenance: {
            ...prev.maintenance,
            number: String(maintenance || 0)
          },
          decommissioned: {
            ...prev.decommissioned,
            number: String(decommissioned || 0)
          }
        }));
      })
      .catch((error) => console.log('error:', error));
  };

  const updateChildGrouping = (newNode) => {
    const { query: { order, orderBy, page, condition, rowsPerPage } } = newNode;
    getDBComplex({
      collection: 'assets',
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      sort: [
        {
          key: orderBy,
          value: order
        }
      ],
      condition: condition,
    })
      .then((response) => response.json())
      .then((data) => {
        const allRows = data.response.map((asset) => (
          createAssetListRow(
            asset._id,
            asset.name,
            asset.brand,
            asset.model,
            asset.category,
            asset.serial,
            asset.EPC,
            asset.status,
            asset.price,
            asset.creationUserFullName,
            asset.creationDate,
            asset.updateDate,
            asset.location,
            asset.fileExt,
            asset.children || []
          )
        ))
        let currentNodes = tableControl.assets.childGrouping;
        const nodeToEdit = currentNodes.findIndex(({ id }) => id === newNode.id);
        currentNodes[nodeToEdit] = { ...newNode, children: allRows };
        setTableControl((prev) => ({
          ...prev,
          assets: {
            ...prev.assets,
            childGrouping: currentNodes
          }
        }))
      })
  };

  useEffect(() => {
    updateKPIS();
  }, [userLocations]);

  const currentTabName = getCurrentModuleName('assets', tab);
  const { REACT_APP_EXPERIMENT_SHOW_ADD_DELETE_ASSET_ACTION } = process.env;

  return (
    <>
      <ModalYesNo
        showModal={selectReferenceConfirmation}
        onOK={() => setSelectReferenceConfirmation(false)}
        onCancel={() => setSelectReferenceConfirmation(false)}
        title={'Add New Asset'}
        message={'Please first select a Reference from the next tab'}
      />
      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  component="div"
                  className="builder-tabs"
                  value={tab}
                  onChange={(_, nextTab) => setTab(nextTab)}
                >
                  {TabsTitles('assets', user.profilePermissions['assets'])}
                </Tabs>
              </PortletHeaderToolbar>
            }
          />

          {tab === 0 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    <span className="kt-section__sub">
                      <Grid className={classes.assetsInfoContainer} container direction="row">
                        {Object.entries(assetsKPI).map(([key, val]) => (
                          <Card
                            className={classes.assetsInfoCard}
                            elevation={2}
                            onClick={() => handleKpiCardClick({ value: val.key, label: val.text })}
                            style={{ cursor: 'pointer' }}
                            key={`kpi-card-${val.key}`}
                          >
                            <div
                              className={classes.cardSnapshot}
                              style={{ backgroundColor: val.color }}
                              key={`kpi-icon-div-${val.key}`}
                            >
                              {val.icon}
                            </div>
                            <div
                              className={classes.cardTextContainer}
                              key={`kpi-info-div-${val.key}`}
                            >
                              <CardContent style={{ padding: '12px' }}>
                                <center>
                                  <Typography variant="subtitle">{GetTranslatedValue(val.translation, val.text)}</Typography>
                                  <Typography variant="h4">{val.number}</Typography>
                                </center>
                              </CardContent>
                            </div>
                          </Card>
                        ))}
                      </Grid>
                    </span>
                    <div className={classes.selectContainer}>
                      <Select
                        className={classes.select}
                        classNamePrefix="select"
                        isClearable={true}
                        isMulti
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        name="KPI"
                        onChange={setKpiSelected}
                        value={kpiSelected}
                        options={kpisToSelect}
                      />
                    </div>
                    <ModalAssetList
                      allFields={allFields}
                      assets={control.assetRows}
                      id={control.idAsset}
                      key={control.idAsset}
                      fields={(allFields || {})['assets']}
                      fieldsToValidate={(fieldsToValidate || {})['assets']}
                      policies={policies}
                      readOnly={control.assetsReadOnly}
                      referencesSelectedId={referencesSelectedId}
                      reloadTable={() => loadAssetsData('assets')}
                      setShowModal={(onOff) => setControl({ ...control, openAssetsModal: onOff })}
                      showModal={control.openAssetsModal}
                      parentLocations={parentLocations}
                    />
                    <div className="kt-separator kt-separator--dashed" />
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.assets}
                        userLocations={userLocations}
                        headRows={assetListHeadRows}
                        locationControl={(locationId) => {
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              treeViewLocation: locationId,
                              childGrouping: []
                            }
                          }));
                        }}
                        noAdd={!getBoolValue(REACT_APP_EXPERIMENT_SHOW_ADD_DELETE_ASSET_ACTION)}
                        noDelete={!getBoolValue(REACT_APP_EXPERIMENT_SHOW_ADD_DELETE_ASSET_ACTION)}
                        onAdd={tableActions('assets').onAdd}
                        onDelete={tableActions('assets').onDelete}
                        onEdit={tableActions('assets').onEdit}
                        onSelect={tableActions('assets').onSelect}
                        onView={tableActions('assets').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              rowsPerPage: rowsPerPage,
                              page: page,
                              childGrouping: []
                            }
                          }))
                        }
                        rows={control.assetRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.assets.search === value) {
                            loadAssetsData('assets', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              assets: {
                                ...prev.assets,
                                search: value,
                                searchBy: field,
                                childGrouping: []
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              orderBy: orderBy,
                              order: order,
                              childGrouping: []
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              tableGrouping: tree,
                              childGrouping: []
                            }
                          }))
                        }
                        setChildGrouping={(data) =>
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              childGrouping: data
                            }
                          }))
                        }
                        updateChildGrouping={updateChildGrouping}
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab={currentTabName}
                        title={GetTranslatedValue('ASSETS.TABLE.CAPTION', 'Assets List')}
                        tileView
                        treeView
                        childView
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 1 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>Assets References</code>
                    </span> */}
                    <ModalAssetReferences
                      fields={(allFields || {})['references']}
                      fieldsToValidate={(fieldsToValidate || {})['references']}
                      policies={policies}
                      showModal={control.openReferencesModal}
                      setShowModal={(onOff) =>
                        setControl({ ...control, openReferencesModal: onOff })
                      }
                      readOnly={control.referencesReadOnly}
                      reloadTable={() => loadAssetsData('references')}
                      id={control.idReference}
                      categoryRows={control.categoryRows}
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.references}
                        headRows={assetReferencesHeadRows}
                        onAdd={tableActions('references').onAdd}
                        onDelete={tableActions('references').onDelete}
                        onEdit={tableActions('references').onEdit}
                        onSelect={tableActions('references').onSelect}
                        onView={tableActions('references').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            references: {
                              ...prev.references,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.referenceRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.references.search === value) {
                            loadAssetsData('references', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              references: {
                                ...prev.references,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            references: {
                              ...prev.references,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            references: {
                              ...prev.references,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab={currentTabName}
                        title={GetTranslatedValue('REFERENCES.TABLE.TITLE', 'Assets References')}
                        tileView
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 2 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>Assets Categories</code>
                    </span>
                    <div className='kt-separator kt-separator--dashed' /> */}
                    <ModalAssetCategories
                      fields={(allFields || {})['categories']}
                      fieldsToValidate={(fieldsToValidate || {})['categories']}
                      policies={policies}
                      showModal={control.openCategoriesModal}
                      setShowModal={(onOff) =>
                        setControl({ ...control, openCategoriesModal: onOff })
                      }
                      readOnly={control.categoriesReadOnly}
                      reloadTable={() => loadAssetsData('categories')}
                      id={control.idCategory}
                    />
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.categories}
                        headRows={assetCategoriesHeadRows}
                        onAdd={tableActions('categories').onAdd}
                        onDelete={tableActions('categories').onDelete}
                        onEdit={tableActions('categories').onEdit}
                        onSelect={tableActions('categories').onSelect}
                        onView={tableActions('categories').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            categories: {
                              ...prev.categories,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.categoryRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.categories.search === value) {
                            loadAssetsData('categories', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              categories: {
                                ...prev.categories,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            categories: {
                              ...prev.categories,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            categories: {
                              ...prev.categories,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab={currentTabName}
                        title={GetTranslatedValue('CATEGORIES.TABLE.TITLE', 'Asset List')}
                        tileView
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 3 && (
            <Policies setPolicies={setPolicies} module="assets" baseFields={policiesBaseFields} />
          )}
        </Portlet>
      </div>
    </>
  );
}
const mapStateToProps = ({ general: { globalSearch }, auth: { user } }) => ({
  globalSearch,
  user
});
export default connect(mapStateToProps, general.actions)(Assets);
