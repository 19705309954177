import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const Preview = ({ preview }) => {
 
  return (
    <div style={{overflow: 'auto'}} className='__container-p'>
      {ReactHtmlParser(preview)}
    </div>
  );
};

Preview.propTypes = {
  preview: PropTypes.string.isRequired,
};

export default Preview;
