/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import {
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/ducks/general.duck';
import { getDBComplex, getOneDB, postDB, updateDB, updateManyDB } from '../../../../crud/api';
import { convertToObjectIdArray, getFileExtension, getImageURL, saveImage, showSuccessMessage, updateChildrenProfileName, verifyRepeatedCustomFields } from '../../utils';
import { executeOnFieldPolicy, executePolicies } from '../../Components/Policies/utils';
import BaseFields from '../../Components/BaseFields/BaseFields';
import CustomFields from '../../Components/CustomFields/CustomFields';
import ImageUpload from '../../Components/ImageUpload';
import CustomRecordModal from '../../Components/CustomRecordModal';
import { GetTranslatedValue } from '../../utils';
import { useIntl } from 'react-intl';
import ModalYesNo from '../../Components/ModalYesNo';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalEmployeeProfiles = ({
  fields,
  fieldsToValidate,
  readOnly,
  showModal,
  setShowModal,
  reloadProfiles,
  reloadTable,
  id,
  policies
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    showCustomAlert,
    showFillFieldsAlert,
    setGeneralLoading,
    showErrorAlert,
    showSavedAlert,
    showUpdatedAlert
  } = actions;
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  const [isAssetRepositoryOnInit, setIsAssetRepositoryOnInit] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const messages = {
    warningName: GetTranslatedValue('WARNING.SAME.PROFILE.NAME'),
    gatheredEmployees: GetTranslatedValue('EMPLOYEE.PROFILES.GATHERED.EMPLOYEES'),
    gatheredAssets: GetTranslatedValue('EMPLOYEE.PROFILES.GATHERED.ASSETS'),
    relatedEmployees: GetTranslatedValue('EMPLOYEE.PROFILES.RELATED.EMPLOYEES'),
    assetsInvolved: GetTranslatedValue('EMPLOYEE.PROFILES.ASSETS.INVOLVED')
  };

  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  function handleChangeIndex4(index) {
    setValue4(index);
  }

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });

  const [values, setValues] = useState({
    name: '',
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg',
    imageURL: ''
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const baseFieldsLocalProps = {
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: {
          readOnly
        }
      }
    }
  };

  const validProfileName = () => {
    return getDBComplex({
      collection: 'employeeProfiles',
      condition: [{ name: values.name }]
    })
      .then((response) => response.json())
      .then((data) => data?.response?.length <= 1)
      .catch((error) => dispatch(showErrorAlert()));
  };

  const handleSave = async () => {
    setFormValidation({ ...formValidation, enabled: true });

    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (verifyRepeatedCustomFields(customFieldsTab)) {
      const message = intl.formatMessage({
        id: 'WARNING.CUSTOM.FIELDS.REPEATED.NAME',
        defaultMessage: 'Text not rendered'
      });

      dispatch(
        showCustomAlert({
          open: true,
          message,
          type: 'warning'
        })
      );
      return;
    }

    const validName = await validProfileName();

    if (!validName) {
      dispatch(
        showCustomAlert({
          message: messages.warningName,
          open: true,
          type: 'warning'
        })
      );

      return;
    }

    if (isAssetRepositoryOnInit && !isAssetRepository) {
      setShowWarningMessage(true);
      
      return;
    }

    savingProcess();
  };

  const clearEmployeeAssets = async (profileId) => {
    dispatch(setGeneralLoading({ active: true }));
    const employees = await getDBComplex({
      collection: 'employees',
      condition: [{ "employeeProfile.value": profileId }]
    })
      .then((response) => response.json())
      .then((data) => {
        showSuccessMessage(messages.gatheredEmployees);
        return data?.response;
      });

    const assetsInvolved = employees.reduce(
      (acc, { assetsAssigned }) => {
        return [...acc, ...(assetsAssigned || [])];
      },
      []
    ) || [];
    showSuccessMessage(messages.gatheredAssets);

    await updateManyDB({
      body: { assetsAssigned: [] },
      collection: 'employees',
      condition: [{ "employeeProfile.value": profileId }]
    })
      .catch((error) => console.log(error));
    showSuccessMessage(messages.relatedEmployees);

    await updateManyDB({
      body: { assigned: null, assignedTo: '' },
      collection: 'assets',
      condition: [{ "_id": { "$in": convertToObjectIdArray(assetsInvolved.map(({ id }) => id)) } }]
    })
      .catch((error) => console.log(error));
    showSuccessMessage(messages.assetsInvolved);
    dispatch(setGeneralLoading({ active: false }));
  };

  const savingProcess = () => {
    const fileExt = getFileExtension(image);
    const body = { ...values, customFieldsTab, isAssetRepository, fileExt };

    if (!id) {
      postDB('employeeProfiles', body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('employeeProfiles', _id);
          executePolicies('OnAdd', 'employees', 'references', policies, response.response[0]);
          executeOnFieldPolicy('OnAdd', 'employees', 'references', policies, response.response[0]);
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      updateDB('employeeProfiles/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          const employeeProfile = { _id: id[0], ...body };

          if (value.name !== body.name) {
            updateChildrenProfileName({
              collection: 'employees',
              field: 'employeeProfile.value',
              newName: values.name,
              profileId: id[0],
              updateField: 'employeeProfile'
            });
          }

          dispatch(showUpdatedAlert());
          executePolicies('OnEdit', 'employees', 'references', policies, employeeProfile);
          executeOnFieldPolicy(
            'OnEdit',
            'employees',
            'references',
            policies,
            employeeProfile,
            value
          );
          saveAndReload('employeeProfiles', id[0]);
        })
        .catch((error) => dispatch(showErrorAlert()));
    }

    handleCloseModal();
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }

    reloadTable();
    reloadProfiles();
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setValues({
      name: '',
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg',
      imageURL: ''
    });
    setShowModal(false);
    setValue4(0);
    setIsAssetRepository(false);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
    setIsAssetRepositoryOnInit(false);
    setShowWarningMessage(false);
  };

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('employeeProfiles/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const { name, depreciation, customFieldsTab, isAssetRepository, fileExt } = data.response;
        const imageURL = getImageURL(id, 'employeeProfiles', fileExt);
        const obj = { name, depreciation, imageURL };
        setIsAssetRepositoryOnInit(isAssetRepository);
        setValues(obj);
        setCustomFieldsTab(customFieldsTab);
        setIsAssetRepository(isAssetRepository);
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [id, showModal]);

  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [isAssetRepository, setIsAssetRepository] = useState(false);

  return (
    <>
      <ModalYesNo
        message={GetTranslatedValue('EMPLOYEE.PROFILES.ASSET.REPOSITORY.WARNING')}
        onCancel={() => {
          setShowWarningMessage(false);
          setIsAssetRepository(true);
        }}
        onOK={async () => {
          setShowWarningMessage(false);
          setShowModal(false);
          savingProcess();
          await clearEmployeeAssets(id[0]);
        }}
        showModal={showWarningMessage}
        title={GetTranslatedValue('GENERAL.WARNING')}
      />
      <CustomRecordModal
        id={id}
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
        key="Modal-Employees-Profiles"
        readOnly={readOnly}
        showModal={showModal}
        tabs={
          <Tabs
            value={value4}
            onChange={handleChange4}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label={GetTranslatedValue('RECORD.CAPTION.PROFILE')} />
            <Tab label={GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS')} />
          </Tabs>
        }
        title="EMPLOYEE.PROFILES.MODAL.CAPTION"
      >
        <SwipeableViews
          axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value4}
          onChangeIndex={handleChangeIndex4}
        >
          <TabContainer4 dir={theme4.direction}>
            <div className="profile-tab-wrapper">
              <ImageUpload
                disabled={readOnly}
                id="employee-profile-image"
                image={values.imageURL}
                setImage={setImage}
                setInitialImage={setInitialImage}
              >
                {GetTranslatedValue('EMPLOYEE.MODAL.EMPLOYEE.PROFILEPHOTO')}
              </ImageUpload>
              <div className="profile-tab-wrapper__content">
                <BaseFields
                  catalogue={'employeeReferences'}
                  collection={'employeeReferences'}
                  fields={fields}
                  fieldsToValidate={fieldsToValidate}
                  formState={[formValidation, setFormValidation]}
                  localProps={baseFieldsLocalProps}
                  values={values}
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={isAssetRepository}
                      onChange={(e) => setIsAssetRepository(e.target.checked)}
                    />
                  }
                  label={GetTranslatedValue('EMPLOYEE.PROFILE.ASSETREPOSITORY')}
                  labelPlacement="start"
                />
              </div>
            </div>
          </TabContainer4>
          <TabContainer4 dir={theme4.direction}>
            <CustomFields
              customFieldsTab={customFieldsTab}
              setCustomFieldsTab={setCustomFieldsTab}
              readOnly={readOnly}
            />
          </TabContainer4>
        </SwipeableViews>
      </CustomRecordModal>
    </>
  );
};

export default ModalEmployeeProfiles;
