import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { GetTranslatedValue } from '../../utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    display: 'flex'
  },
  '@media (max-width: 900px)': {
    root: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  '@media (max-width: 500px)': {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  }
}));

const AssetPreviewBox = ({ selectedAsset }) => {

  const previewFields = [
    { id: 'name', label: `${GetTranslatedValue('ASSETS.CAPTION.NAME', 'Name')}:` },
    { id: 'brand', label: `${GetTranslatedValue('ASSETS.CAPTION.BRAND', 'Brand')}:` },
    { id: 'model', label: `${GetTranslatedValue('ASSETS.CAPTION.MODEL', 'Model')}:` }
  ];

  const classes = useStyles();
  return (
    <>
    <Typography color="textPrimary" gutterBottom style={{ textAlign: 'center', width: '100%', marginBottom: '10px'}}>
      {GetTranslatedValue('ASSET.PREVIEW.FINDER.PREVIEW', 'Preview')}
    </Typography>
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <img style={{ width: '90%', height: '150px', margin: '0 auto'}} src={selectedAsset.picUrl} alt='' />
      </div>
      <div className={classes.detailsWrapper}>
        {previewFields.map(({ id, label }) => (
          <div className={classes.detailTextFieldWrapper}>
            <Typography color="textSecondary" gutterBottom style={{ marginTop: '15px' }}>
              {label}
            </Typography>
            <Typography color="textPrimary" gutterBottom >
              {selectedAsset[id]}
            </Typography>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default AssetPreviewBox;
