import React, { Component } from 'react'
import { ErrorPage6 } from './ErrorPage6';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch(error, info) {
    console.log(error) // this is never logged
    this.setState(state => ({ ...state, hasError: true }))
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage6/>
    }

    return this.props.children
  }
}
export default ErrorBoundary
