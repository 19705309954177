/* eslint-disable no-restricted-imports */
import React from 'react';
import { TextField, FormLabel, FormGroup } from '@material-ui/core';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import FieldValidator from '../../Components/FieldValidator/FieldValidator';
import { allBaseFields } from '../../constants';
import { GetTranslatedValue } from '../../utils';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const BaseFields = ({
  fields,
  fieldsToValidate,
  formState,
  localProps,
  values,
  catalogue,
  collection,
  noPassword = false
}) => {
  const [formValidation, setFormValidation] = formState;

  if (noPassword) {
    delete (fields || {})['password'];
  }

  if (
    noPassword &&
    Array.isArray(fieldsToValidate) &&
    (fieldsToValidate || []).includes('password')
  ) {
    fieldsToValidate.splice(fieldsToValidate.indexOf('password'), 1);
  }

  const classes = useStyles();
  const baseFields = allBaseFields[catalogue] || {};

  const completeBaseFields = Object.entries(baseFields).reduce((acu, cur) => {
    const [key, val] = cur;
    return {
      ...acu,
      [key]: {
        ...val,
        ...(localProps[key] ? localProps[key] : {})
      }
    };
  }, {});

  const baseProps = {
    className: classes.textField,
    margin: 'normal'
  };
  const baseComponents = {
    dropSelect: (props) => {
      return (
        <div className={props.className} style={props.style}>
          <FormLabel component="legend">{props.componentProps.label}</FormLabel>
          <FormGroup>
            <Select
              {...props.componentProps}
              placeholder={GetTranslatedValue('GENERAL.CAPTION.SELECT.PLACEHOLDER', 'Select...')}
            />
          </FormGroup>
        </div>
      );
    },
    textField: (props) => {
      const componentProps = { ...props, ...props.componentProps };

      return <TextField {...componentProps} />;
    }
  };

  const renderBaseFields = () => {
    return (
      Object.entries(completeBaseFields).map(([key, val], ix) => {
        const { ownValidFn, component, componentProps, validationId, compLabel, translation, style } = val;
        const label = (fields || {})[key]?.caption || GetTranslatedValue(translation, compLabel);
        const regEx = (fields || {})[key]?.regex || '';
        const localComponentProps = { ...componentProps, label };
        const props = {
          ...baseProps,
          value: values[key],
          componentProps: localComponentProps,
          style
        };
        const isEmail = localComponentProps?.type === 'email';
        const defaultVoidValidation = !!values[validationId];
        const isValidVoid = !(fieldsToValidate || []).includes(key)
          ? isEmail && !isEmpty(values[validationId]) ? ownValidFn() : true
          : ownValidFn
            ? ownValidFn()
            : defaultVoidValidation;
        const isValidRegEx = regEx ? new RegExp(regEx).test(values[validationId]) : true;
  
        if (baseComponents[component]) {
          return (
            <FieldValidator
              formValidationState={[formValidation, setFormValidation]}
              isEmail={isEmail}
              isValidVoid={isValidVoid}
              isValidRegEx={isValidRegEx}
              fieldName={key}
              key={`fieldValidator-${ix}`}
            >
              {baseComponents[component](props)}
            </FieldValidator>
          );
        } else {
          return null;
        }
      })
    );
  }

  return <>{renderBaseFields()}</>;
};

export default BaseFields;
