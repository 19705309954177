import { getDBComplex, getCountDB } from '../../../crud/api';
import { actions } from '../../../store/ducks/general.duck';
import store from '../../../store/store';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const { setTableLoading, setGeneralLoading } = actions;

const getConditions = (query, condition) => {
  if (isEmpty(query) && isEmpty(condition)) {
    return null;
  } else if (!isEmpty(query) && isEmpty(condition)) {
    return query;
  } else if (isEmpty(query) && !isEmpty(condition)) {
    return condition;
  } else if (!isEmpty(query) && !isEmpty(condition)) {
    return query.concat(condition);
  }
  return null;
};

export const loadGroups = async (collection, object = null, condition = null) => {
  if (object && collection) {
    const { query } = object;
    const total = await getCountDB({
      collection: collection,
      // queryLike: tableControl[collection].search ? queryLike : null,
      distinct: object.type === 'node' ? object.field : null,
      condition: getConditions(query.condition, condition)
    })
      .then(response => response.json())
      .then(data => {
        return data.response;
      });

    const data = await getDBComplex({
      collection: collection,
      limit: query.rowsPerPage,
      skip: query.rowsPerPage * query.page,
      sort: object.type === 'node' ? [{ key: '_id', value: 1 }] : [{ key: query.orderBy, value: query.order }],
      // queryLike: tableControl[collection].search /* || tableControl['user'].locationsFilter.length */ ? queryLike : null,
      distinct: object.type === 'node' ? object.field : null,
      condition: getConditions(query.condition, condition)
    })
      .then(response => response.json())
      .then(data => {
        return object.type === 'node' ? data.response.result : data.response;
      })
      .catch(error => console.log('error>', error));

    return { total, data, nodeFetched: object }
  }
};

export const loadGroups2 = (collection, object = null) => {
  const { query } = object;
  const total = getCountDB({
    collection: collection,
    // queryLike: tableControl[collection].search ? queryLike : null,
    distinct: object.type === 'node' ? object.field : null,
    condition: query.condition || null
  });

  const data = getDBComplex({
    collection: collection,
    limit: query.rowsPerPage,
    skip: query.rowsPerPage * query.page,
    sort: object.type === 'node' ? [{ key: '_id', value: 1 }] : [{ key: query.orderBy, value: query.order }],
    // queryLike: tableControl[collection].search /* || tableControl['user'].locationsFilter.length */ ? queryLike : null,
    distinct: object.type === 'node' ? object.field : null,
    condition: query.condition || null
  });

  return [total, data]
};

// THIS WILL BE THE GOOD ONE ONCE FIXED
export const updateTableGroupingTree = async (collectionName, treeData, tableGroupingCreateRows, tableControl, setTableControl, upperCondition) => {

  const tableGroupingValues = store.getState().general.tableGroupingValues;
  store.dispatch(setGeneralLoading(true));
  const rowsPerPage = tableControl[collectionName].rowsPerPage;
  const emptyQuery = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: null };
  let newTree = [];

  const activeNodes = treeData.filter(({parent, active}) => active || parent === 'root').filter(({value}) => value);

  for (const nodeToBeChanged of activeNodes) {
    if (tableGroupingValues[nodeToBeChanged.depth + 1] && nodeToBeChanged.parent === 'root') {

      const fetchResult = await loadGroups(collectionName, nodeToBeChanged, upperCondition);
      const oldQueries = treeData.filter(({parent}) => parent === nodeToBeChanged.id);
  
      const dataToNewNodes = fetchResult.data.map(({ _id, count }, ix) => {
        const newNode = { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query: oldQueries[ix]?.query || emptyQuery , type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
        return newNode;
      });
  
      nodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);
      const newTreeData = [nodeToBeChanged, ...dataToNewNodes];
  
      const treeCopy2 = await Promise.all(
        fetchResult.data.map(({ _id, count }) => {
          const newNode = { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query: emptyQuery, type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
          const condition = findConditionRecursive(newTreeData, newNode, []);
          return loadGroups(collectionName, { ...newNode, field: tableGroupingValues[nodeToBeChanged.depth + 1]?.id || newNode.field, query: { ...newNode.query, condition }}, upperCondition);
        })
      );
      const treeCopy3 = dataToNewNodes.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count, query:{...node.query, condition:treeCopy2[ix].nodeFetched.query.condition} }));
    
      newTree = [nodeToBeChanged, ...treeCopy3];

    } else if (nodeToBeChanged.parent === 'root') {
      const fetchResult = await loadGroups(collectionName, nodeToBeChanged, upperCondition);
      const oldQueries = treeData.filter(({parent}) => parent === nodeToBeChanged.id);
  
      const dataToNewNodes = fetchResult.data.map(({ _id, count }, ix) => {
        const newNode = { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query: oldQueries[ix]?.query || emptyQuery, type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
        return newNode;
      });
  
      nodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);
      newTree = [nodeToBeChanged, ...dataToNewNodes];
    } else {

      const indexToEdit = newTree.findIndex(({ value, field }) =>  value === nodeToBeChanged.value && field === nodeToBeChanged.field);
      if (indexToEdit < 0) {
        break;
      }
      const localNodeToBeChanged = newTree[indexToEdit];
      localNodeToBeChanged.active = nodeToBeChanged.active;
      localNodeToBeChanged.type = nodeToBeChanged.type;
      localNodeToBeChanged.query = nodeToBeChanged.query;

      const fetchResult = await loadGroups(collectionName, localNodeToBeChanged, upperCondition);
      
      if (localNodeToBeChanged.type === 'data' && fetchResult.data) {
        newTree[indexToEdit].children = tableGroupingCreateRows(fetchResult.data, collectionName);
      } else if (localNodeToBeChanged.type === 'node' && fetchResult.data) {

        if (tableGroupingValues[nodeToBeChanged.depth + 1]) {

          const condition = findConditionRecursive(newTree, localNodeToBeChanged, []);
          const field = tableGroupingValues[localNodeToBeChanged.depth].id;
          const query = { rowsPerPage: localNodeToBeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: 0, condition };
          const fakeNode = { field: field, value: 'empty', active: false, query, type: 'node', parent: localNodeToBeChanged.id, children: [] };
          const fetchResult = await loadGroups(collectionName, fakeNode, upperCondition);

          const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
            return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query, type: 'node', parent: localNodeToBeChanged.id, children: [], depth: localNodeToBeChanged.depth + 1 };
          });

          localNodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);
          localNodeToBeChanged.count = fetchResult.total.count;

          
          const newTreeData = [...newTree, localNodeToBeChanged, ...dataToNewNodes];
      
          const treeCopy2 = await Promise.all(
            fetchResult.data.map(({ _id, count }) => {
              const newNode = { id: uuidv4().split('-').pop(), field: tableGroupingValues[localNodeToBeChanged.depth].id || localNodeToBeChanged.field, value: _id, count, active: false, query, type: 'node', parent: localNodeToBeChanged.id, children: [], depth: localNodeToBeChanged.depth + 1 };
              const condition = findConditionRecursive(newTreeData, newNode, []);
              return loadGroups(collectionName, { ...newNode, field: tableGroupingValues[localNodeToBeChanged.depth + 1]?.id || newNode.field, query: { ...newNode.query, condition }}, upperCondition);
            })
          );
          
          const treeCopy3 = dataToNewNodes.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count, query:{...treeCopy2[ix].nodeFetched.query}}));
        
          newTree = [...newTree, ...treeCopy3]

        } else {
          const condition = findConditionRecursive(newTree, localNodeToBeChanged, []);
          const field = tableGroupingValues[localNodeToBeChanged.depth].id;
          const emptyQuery = { rowsPerPage: localNodeToBeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: 0, condition };
          const fakeNode = { field: field, value: 'empty', active: false, query: localNodeToBeChanged.query || emptyQuery , type: 'node', parent: localNodeToBeChanged.id, children: [] };
          const newfetchResult = await loadGroups(collectionName, fakeNode, upperCondition);

          const dataToNewNodes = newfetchResult.data.map(({ _id, count }) => {
            return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query: emptyQuery, type: 'node', parent: localNodeToBeChanged.id, children: [], depth: localNodeToBeChanged.depth + 1 };
          });
          localNodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);
          localNodeToBeChanged.count = newfetchResult.total.count;

          newTree.splice(indexToEdit, 1, localNodeToBeChanged);
          newTree = [...newTree, ...dataToNewNodes]
        }
        
      }
    }
  }
  setTableControl(newTree);
  store.dispatch(setGeneralLoading(false));
};



export const findConditionRecursive = (treeData, currentNode, accu) => {
  if (!currentNode || currentNode.parent === 'root') {
    return accu;
  } else {
    const { field, value } = currentNode;
    accu.push({ [field]: value });
    const nextNode = treeData.find(({ id }) => id === currentNode.parent);
    return findConditionRecursive(treeData, nextNode, accu);
  }
}


//DEPRECATED VERSION -> IT MAY BE USEFUL IN A FUTURE REFACTOR
// export const updateTableGroupingTree = async (collectionName, treeData, tableGroupingCreateRows, tableControl, setTableControl) => {
//   const rootNode = treeData.find(({ parent }) => parent === 'root');
//   // const treeDataCopy = [...treeData];
//   const treeDataCopy = JSON.parse(JSON.stringify(treeData));

//   Promise.all(loadGroups2(collectionName, rootNode))
//     .then(responses => Promise.all(responses.map((response) => response.json())))
//     .then(data => {
//       const dataProcessed = data.map(({response}, ix) => {
//         if(ix === 1) {
//           return rootNode.type === 'node' ? response.result : response;
//         } else if (ix === 0) {
//           return response.count
//         }
//       });
//       treeData[0].count = dataProcessed[0];

//       dataProcessed[1].map(() => {

//       });
//     });

//   console.log('treeDataCopy:', treeData);
//   updateTreeRecursive2(treeDataCopy, rootNode, collectionName, tableGroupingCreateRows, tableControl);
//   console.log('treeDataChanged:', treeDataCopy);
//   setTableControl(treeDataCopy);
// };

//FIRST VERSION OF THE UPDATE ALGORITHM -> IT MAY BE USEFUL FOR A FUTURE REFACTOR
const updateTreeRecursive = async (treeData, currentNode, collectionName, tableGroupingCreateRows, tableControl) => {
  console.log('CurrentNode:', currentNode);
  if (currentNode.type === 'node' && (currentNode.active || currentNode.parent === 'root')) {

    const findOneChild = treeData.find(({ id }) => currentNode.children.includes(id));
    const nodeToFetch = currentNode.parent === 'root' ? currentNode : { ...currentNode, field: findOneChild.field };
    const fetchResult = await loadGroups(collectionName, nodeToFetch);
    console.log('fetchData:', nodeToFetch, fetchResult, treeData);
    // currentNode.total = fetchResult.total.count;
    treeData[treeData.findIndex(({ id }) => id === currentNode.id)].count = fetchResult.total.count;

    fetchResult.data.map(({ _id, count }, ix) => {
      const indexToUpdate = treeData.findIndex(({ id, value }) => value === _id && currentNode.children.includes(id));

      if (indexToUpdate === -1) { //If it founds a new Unique Field 
        const rowsPerPage = tableControl[collectionName].rowsPerPage;
        const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: currentNode.query.condition };

        // generate and ad the missing element
        console.log('EsteFue:', ix)
        const indexToChange = treeData.findIndex(({ id }) => id === currentNode.children[ix]);
        const newID = uuidv4().split('-').pop();
        const newNode = { id: newID, field: nodeToFetch.field, value: _id, count, active: false, query, type: 'node', parent: nodeToFetch.id, children: [] };
        treeData.splice(indexToChange, 0, newNode);

        //Delete the deprecated node
        const idToDelete = currentNode.children.pop();
        const indexToDelete = treeData.findIndex(({ id }) => id === idToDelete);
        treeData.splice(indexToDelete, 1);

        //update Children
        currentNode.children.splice(ix, 0, newID);

        console.log('TreeData after new element:', treeData);
        return;
      }

      //updates existing nodes
      treeData[indexToUpdate].count = count;

      //if the node has children -> update them too
      if (!isEmpty(treeData[indexToUpdate].children)) {
        updateTreeRecursive(treeData, treeData[indexToUpdate], collectionName, tableGroupingCreateRows, tableControl);
      }
    });
    // setTableGroupingControl([{...currentNode}, ...dataToNewNodes]);
  } else if (currentNode.type === 'data') {
    const fetchResult = await loadGroups(collectionName, currentNode);
    console.log('ToStyle:', fetchResult)
    if (fetchResult.data) {
      treeData[treeData.findIndex(({ id }) => id === currentNode.id)].children = tableGroupingCreateRows(fetchResult.data);
    }
    return;
  }
};


//SECOND VERSION OF THE UPDATE ALGORITHM -> IT MAY BE USEFUL FOR A FUTURE REFACTOR
const updateTreeRecursive2 = (treeData, currentNode, collectionName, tableGroupingCreateRows, tableControl, indice) => {
  if (indice === 0) {
    console.log('sm- FIRST')
  };
  if (currentNode.type === 'node' && (currentNode.active || currentNode.parent === 'root')) {
    //Bring New Data
    const { general: { tableGroupingValues } } = store.getState();
    const nodeToFetch = currentNode.parent === 'root' ? currentNode : { ...currentNode, field: tableGroupingValues[currentNode.depth].id };
    console.log('sm-currentNode:', currentNode, indice, collectionName, nodeToFetch);
    return Promise.all(loadGroups2(collectionName, nodeToFetch))
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then(data => {
        console.log('sm-rawData: ', data, indice);
        const dataProcessed = data.map(({ response }) => response).map((element, ix) => ix === 0 ? element.count : nodeToFetch.type === 'node' ? element.result : element);
        console.log('sm-DATAAAProcessed: ', dataProcessed, indice);

        treeData[treeData.findIndex(({ id }) => id === currentNode.id)].count = dataProcessed[0];

        dataProcessed[1].map(({ _id, count }, ix) => {
          const indexToUpdate = treeData.findIndex(({ id, value }) => value === _id && currentNode.children.includes(id));

          if (indexToUpdate === -1) { //If it founds a new Unique Field 
            const rowsPerPage = tableControl[collectionName].rowsPerPage;
            const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: currentNode.query.condition };

            // generate and ad the missing element
            console.log('EsteFue:', ix)
            const indexToChange = treeData.findIndex(({ id }) => id === currentNode.children[ix]);
            const newID = uuidv4().split('-').pop();
            const newNode = { id: newID, field: nodeToFetch.field, value: _id, count, active: false, query, type: 'node', parent: nodeToFetch.id, children: [] };
            treeData.splice(indexToChange, 0, newNode);

            //Delete the deprecated node
            const idToDelete = currentNode.children.pop();
            const indexToDelete = treeData.findIndex(({ id }) => id === idToDelete);
            treeData.splice(indexToDelete, 1);

            //update Children
            currentNode.children.splice(ix, 0, newID);

            console.log('TreeData after new element:', treeData);
          } else {
            //updates existing nodes
            treeData[indexToUpdate].count = count;

            //if the node has children -> update them too
            if (!isEmpty(treeData[indexToUpdate].children)) {
              updateTreeRecursive2(treeData, treeData[indexToUpdate], collectionName, tableGroupingCreateRows, tableControl, indice + 1);
            }
          }
        });

      })
  } else if (currentNode.type === 'data') {
    return Promise.all(loadGroups2(collectionName, currentNode))
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then(data => {
        const dataProcessed = data.map(({ response }) => response).map((element, ix) => ix === 0 ? element.count : currentNode.type === 'node' ? element.result : element);
        console.log('sm-DATAFINAL: ', dataProcessed);
        const index = treeData.findIndex(({ id }) => id === currentNode.id);
        treeData[index].children = tableGroupingCreateRows(dataProcessed[1]);
        treeData[index].count = dataProcessed[0];
      });
  }
};
