import React, { useState, useEffect } from 'react';
import { FormLabel } from "@material-ui/core";
import { getDB } from '../../../../crud/api';
import { getLocationPath, GetTranslatedValue } from '../../utils';
import TreeView from '../TreeViewComponent.js';

const LocationsTreeView = ({ onTreeElementClick = () => {}, userLocations = [], locationSelected}) => {
  const [locationsTree, setLocationsTree] = useState({});
  const [locationsInfo, setLocationsInfo] = useState([]);
  
  const handleClick = async(info) => {
    const selected = locationsInfo.find(({id}) => id === info);
    if (selected) {
      const name = await getLocationPath(selected.id);
      onTreeElementClick({ ...selected, name });
    }
  };

  const getUserHomeLocations = (data, currentLocation, res, validChildren) => {
    if (!validChildren.includes(currentLocation)) {
      validChildren.push(currentLocation);
    }

    const location = data.find((location) => location._id === currentLocation)

    if (res.includes(location) || !location) {
      return;
    }

    if (location.parent === 'root') {
      res.push(location);
      return;
    }
    getUserHomeLocations(data, location.parent, res, validChildren);
  };

  const constructLocationTreeRecursive = (locs, validChildren, locations) => {
    if (!locs || !Array.isArray(locs) || !locs.length) return [];
    let res = [];
    locs.forEach((location) => {
      const locObj = (({ _id: id, name, profileLevel, parent }) => ({ id, name, profileLevel, parent }))(location);
      const children = locations.filter(loc => loc.parent === locObj.id && validChildren.includes(loc._id));
      locObj.children = constructLocationTreeRecursive(children, validChildren, locations);
      res.push(locObj);
    });
    return res;
  };

  const locationsTreeData = {
    id: 'root',
    name: GetTranslatedValue('LOCATIONS.ROOT', 'Locations'),
    profileLevel: -1,
    parent: null
  };

  const loadInitData = () => {
    getDB('locationsReal')
      .then(response => response.json())
      .then(data => {
        const userHomeLocations = [];
        const validChildren = [];
        const locations = data.response.map(res => ({ ...res, id: res._id }));
        userLocations.forEach((location) => getUserHomeLocations(data.response, location, userHomeLocations, validChildren));
        const children = constructLocationTreeRecursive(userHomeLocations, validChildren, locations);
        locationsTreeData.children = children;
        setLocationsTree(locationsTreeData);
        setLocationsInfo(data.response.map(({ _id: id, profileLevel, name }) => ({ id, profileLevel, name })));
      });
  };

  useEffect(() => loadInitData(), []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormLabel style={{marginTop: '0px'}} component="legend">{GetTranslatedValue('ASSET.PREVIEW.SELECT.LOCATION.SECONDARY','Location Finder')}</FormLabel>
      <TreeView data={locationsTree} onClick={handleClick} selected={locationSelected?.id}/>
    </div>
  );
};

export default LocationsTreeView;
