/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { isEmpty, uniq } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Tabs } from '@material-ui/core';
import { actions } from '../../../store/ducks/general.duck';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import * as general from '../../../store/ducks/general.duck';
import { deleteDB, getDBComplex, getCountDB, getDB, getOneDB, updateDB } from '../../../crud/api';
import { loadGroups, updateTableGroupingTree } from '../Components/tableGroupingHelpers';
import ModalYesNo from '../Components/ModalYesNo';
import TableComponent2 from '../Components/TableComponent2';
import { TabsTitles } from '../Components/Translations/tabsTitles';
import Policies from '../Components/Policies/Policies';
import { executePolicies } from '../Components/Policies/utils';
import { usePolicies } from '../Components/Policies/hooks';
import { useFieldValidator } from '../Components/FieldValidator/hooks';
import ModalUserProfiles from './modals/ModalUserProfiles';
import ModalUsers from './modals/ModalUsers';
import { allBaseFields } from '../constants';
import {
  getCurrentModuleName,
  getCurrentModuleTab,
  getFieldCaption,
  GetTranslatedValue,
  loadUserLocations,
  profileHasChildren,
  showWarningMessage
} from '../utils';

function Users({ globalSearch, setGeneralSearch, user }) {
  const dispatch = useDispatch();
  const { showDeletedAlert, showErrorAlert } = actions;
  const [tab, setTab] = useState(getCurrentModuleTab('users', user.profilePermissions));
  const [userLocations, setUserLocations] = useState([]);
  const [allUserProfiles, setAllUserProfiles] = useState([]);
  const [parentLocations, setParentLocations] = useState([]);
  const { allFields, fieldsToValidate } = useFieldValidator('user');

  const { policies, setPolicies } = usePolicies();

  const policiesBaseFields = {
    list: { ...allBaseFields.userList, translationLabel: 'TABS.TITLES.LIST' },
    references: { ...allBaseFields.userReferences, translationLabel: 'TABS.TITLES.PROFILES' }
  };

  const executeFieldCaption = (catalogue, { field, key }) => {
    return getFieldCaption(allFields, { catalogue, field, key });
  };

  const createUserProfilesRow = (id, name, creator, creationDate, updateDate, fileExt) => {
    return { id, name, creator, creationDate, updateDate, fileExt };
  };

  const userProfilesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('userReferences', {
        field: 'name',
        key: allBaseFields.userReferences.name.translation
      })
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('userReferences', {
        field: '',
        key: 'RECORD.CAPTION.CREATOR'
      }),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('userReferences', {
        field: '',
        key: 'RECORD.CAPTION.CREATIONDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('userReferences', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const createUserRow = (
    id,
    name,
    lastName,
    email,
    profile,
    manager,
    creator,
    creationDate,
    updateDate,
    fileExt
  ) => {
    return {
      id,
      name,
      lastName,
      email,
      profile,
      manager,
      creator,
      creationDate,
      updateDate,
      fileExt
    };
  };

  const usersHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: 'name',
        key: allBaseFields.userList.name.translation
      })
    },
    {
      id: 'lastName',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: 'lastName',
        key: allBaseFields.userList.lastName.translation
      })
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: 'email',
        key: allBaseFields.userList.email.translation
      }),
      disableTableGrouping: true
    },
    {
      id: 'profile',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: 'userProfile',
        key: allBaseFields.userList.userProfile.translation
      }),
      correction: 'selectedUserProfile.label'
    },
    {
      id: 'manager',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: 'boss',
        key: allBaseFields.userList.boss.translation
      }),
      correction: 'selectedBoss.name'
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: '',
        key: 'RECORD.CAPTION.CREATOR'
      }),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: '',
        key: 'RECORD.CAPTION.CREATIONDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('user', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const [tableControl, setTableControl] = useState({
    userProfiles: {
      collection: 'userProfiles',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    },
    user: {
      collection: 'user',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: '',
      locationsFilter: []
    }
  });

  const loadUserProfiles = () => {
    getDB('userProfiles')
      .then((response) => response.json())
      .then((data) => {
        const userProfiles =
          data.response.map((row) => {
            return createUserProfilesRow(
              row._id,
              row.name,
              row.creationUserFullName,
              row.creationDate,
              row.updateDate,
              row.fileExt
            );
          }) || [];
        setAllUserProfiles(userProfiles);
      })
      .catch((error) => console.log(error));
  };

  const getLocationFilter = (collectionName) => {
    if (collectionName === 'user') {
      return parentLocations;
    } else {
      return null;
    }
  };

  const getTreeViewLocation = (collectionName) => {
    if (collectionName === 'user') {
      return tableControl.user.treeViewLocation || '';
    } else {
      return null;
    }
  };

  const getLocationFilterParam = (collectionName) => {
    if (collectionName === 'user') {
      return 'locationsTable.parent';
    } else {
      return null;
    }
  };

  const searchColumns = {
    profile: 'selectedUserProfile.label',
    manager: 'selectedBoss.name'
  };

  const loadUsersData = (collectionNames = ['user', 'userProfiles'], searchBy) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      const localSearchBy = searchBy || tableControl[collectionName].searchBy;
      if (!isEmpty(tableControl[collectionName].tableGrouping)) {
        updateTableGroupingTree(
          collectionName,
          tableControl[collectionName].tableGrouping,
          styleMultipleRows,
          tableControl,
          (newTree) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                tableGrouping: newTree
              }
            }));
          },
          tableControl[collectionName].condition
        );
      }
      let queryLike = '';
      if (collectionName === 'userProfiles') {
        queryLike = localSearchBy
          ? [
              {
                key: localSearchBy,
                value: tableControl.userProfiles.search
              }
            ]
          : ['creationDate', 'name', 'updateDate'].map((key) => ({
              key,
              value: tableControl.userProfiles.search
            }));
      }
      if (collectionName === 'user') {
        queryLike = localSearchBy
          ? [
              {
                key: searchColumns[localSearchBy] || localSearchBy,
                value: tableControl.user.search
              }
            ]
          : [
              'creationDate',
              'email',
              'lastName',
              'name',
              'selectedUserProfile.label',
              'updateDate'
            ].map((key) => ({ key, value: tableControl.user.search }));
      }

      const locationsFilter = getLocationFilter(collectionName);
      const treeViewLocation = getTreeViewLocation(collectionName);
      const locationsFilterParam = getLocationFilterParam(collectionName);

      if (collectionName === 'user' && isEmpty(locationsFilter)) {
        return;
      }

      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null,
        locationsFilter,
        locationsFilterParam,
        treeViewLocation
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response.count
            }
          }));
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          {
            key: tableControl[collectionName].orderBy,
            value: tableControl[collectionName].order
          }
        ],
        queryLike: tableControl[collectionName].search ? queryLike : null,
        locationsFilter,
        locationsFilterParam,
        treeViewLocation
      })
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'userProfiles') {
            const rows = data.response.map((row) => {
              return createUserProfilesRow(
                row._id,
                row.name,
                row.creationUserFullName,
                row.creationDate,
                row.updateDate,
                row.fileExt
              );
            });
            setControl((prev) => ({
              ...prev,
              userProfilesRows: rows,
              userProfilesRowsSelected: []
            }));
          }
          if (collectionName === 'user') {
            const rows = data.response.map((row) => {
              const { selectedBoss } = row;
              return createUserRow(
                row._id,
                row.name,
                row.lastName,
                row.email,
                row.selectedUserProfile ? row.selectedUserProfile.label || '' : '',
                selectedBoss ? `${selectedBoss.name} ${selectedBoss.lastName}` : '',
                row.creationUserFullName,
                row.creationDate,
                row.updateDate,
                row.fileExt
              );
            });
            setControl((prev) => ({ ...prev, usersRows: rows, usersRowsSelected: [] }));
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };

  const loadLocations = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: user?.id
    });
    setUserLocations(userLocations);
  };

  useEffect(() => {
    loadLocations();
    loadUserProfiles();
  }, []);

  useEffect(() => {
    loadUsersData('user');
  }, [
    tableControl.user.page,
    tableControl.user.rowsPerPage,
    tableControl.user.order,
    tableControl.user.orderBy,
    tableControl.user.search,
    tableControl.user.locationsFilter,
    tableControl.user.treeViewLocation,
    userLocations
  ]);

  useEffect(() => {
    loadUsersData('userProfiles');
  }, [
    tableControl.userProfiles.page,
    tableControl.userProfiles.rowsPerPage,
    tableControl.userProfiles.order,
    tableControl.userProfiles.orderBy,
    tableControl.userProfiles.search
  ]);

  const tabIntToText = ['user', 'userProfiles'];

  useEffect(() => {
    if (globalSearch.tabIndex >= 0) {
      setTab(globalSearch.tabIndex);
      setTableControl((prev) => ({
        ...prev,
        [tabIntToText[globalSearch.tabIndex]]: {
          ...prev[tabIntToText[globalSearch.tabIndex]],
          search: globalSearch.searchValue
        }
      }));
      setTimeout(() => {
        setGeneralSearch({});
      }, 800);
    }
  }, [globalSearch.tabIndex, globalSearch.searchValue]);

  useEffect(() => {
    const urls = window.location.search.split('=');
    const idUser = urls[1];

    if (idUser) {
      setTab(0);
      setControl((prev) => ({
        ...prev,
        idUser: [idUser],
        openUsersModal: true,
        userReadOnly: !user.profilePermissions['users']['list'].includes('edit')
      }));
    }
  }, [window.location.search]);

  const [control, setControl] = useState({
    idUserProfile: null,
    openUserProfilesModal: false,
    userProfilesRows: null,
    userProfilesRowsSelected: [],

    idUser: null,
    openUsersModal: false,
    usersRows: null,
    usersRowsSelected: []
  });

  const [referencesSelectedId, setReferencesSelectedId] = useState(null);
  const [selectReferenceConfirmation, setSelectReferenceConfirmation] = useState(false);

  const collections = {
    userProfiles: {
      id: 'idUserProfile',
      modal: 'openUserProfilesModal',
      name: 'userProfiles'
    },
    users: {
      id: 'idUser',
      modal: 'openUsersModal',
      name: 'user'
    }
  };

  const specialistsWitnessesValidation = async (userId) => {
    const hasAnySpecialist = await profileHasChildren(
      'settingsAssetSpecialists',
      userId,
      'userSelected.value'
    );

    if (hasAnySpecialist) {
      return true;
    } else {
      const hasAnyWitness = await profileHasChildren(
        'settingsWitnesses',
        userId,
        'userSelected.value'
      );

      return hasAnyWitness;
    }
  };

  const isBoss = (userId) => {
    return getCountDB({
      collection: 'user',
      condition: [{ 'selectedBoss.value': userId }]
    })
      .then((response) => response.json())
      .then((data) => data?.response?.count > 0)
      .catch((error) => console.log(error));
  };

  const isProcessApproval = (userId) => {
    return getCountDB({
      collection: 'processLive',
      condition: [
        {
          'processData.processStatus': 'inProcess',
          'processData.stages': { $elemMatch: { approvals: { $elemMatch: { _id: userId } } } }
        }
      ]
    })
      .then((response) => response.json())
      .then((data) => data?.response?.count > 0)
      .catch((error) => console.log(error));
  };

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        getDB('policies')
          .then((response) => response.json())
          .then(async (data) => {
            const { response } = data;
            const copyArray = JSON.parse(JSON.stringify(id));

            if (collection.name === 'user') {
              for (let i = 0; i < copyArray.length; i++) {
                const flag = await specialistsWitnessesValidation(copyArray[i]);

                if (flag) {
                  const index = id.indexOf(copyArray[i]);
                  id.splice(index, 1);
                  showWarningMessage(
                    'A user can´t be deleted if is used in the Assets Specialists/Location Witnesses'
                  );
                } else {
                  const secondFlag = await isBoss(copyArray[i]);

                  if (secondFlag) {
                    const index = id.indexOf(copyArray[i]);
                    id.splice(index, 1);
                    showWarningMessage('A user can´t be deleted if it is boss of another user');
                  } else {
                    const thirdFlag = await isProcessApproval(copyArray[i]);

                    if (thirdFlag) {
                      const index = id.indexOf(copyArray[i]);
                      id.splice(index, 1);
                      showWarningMessage(
                        'A user can´t be deleted if it is a process approval, change approvals or finish the remaining folios in order to delete the user'
                      );
                    }
                  }
                }
              }
            } else if (collection.name === 'userProfiles') {
              for (let i = 0; i < copyArray.length; i++) {
                const flag = await profileHasChildren(
                  'user',
                  copyArray[i],
                  'selectedUserProfile.value'
                );

                if (flag) {
                  const index = id.indexOf(copyArray[i]);
                  id.splice(index, 1);
                  showWarningMessage("A profile can't be deleted because there are users using it");
                }
              }
            }

            id.forEach((_id) => {
              deleteDB(`${collection.name}/`, _id)
                .then((response) => response.json())
                .then((userData) => {
                  const {
                    response: { value }
                  } = userData;
                  dispatch(showDeletedAlert());
                  const currentCollection = collection.name === 'user' ? 'list' : 'references';
                  executePolicies('OnDelete', 'user', currentCollection, response, value);
                  loadUsersData(collection.name);

                  if (collection.name === 'userProfiles') {
                    loadUserProfiles();
                  }

                  if (currentCollection === 'list') {
                    const {
                      response: {
                        value: { groups }
                      }
                    } = userData;
                    groups.forEach(({ id: groupId }) => {
                      getOneDB('settingsGroups/', groupId)
                        .then((response) => response.json())
                        .then((groupData) => {
                          const { members } = groupData.response;
                          const membersUpdated =
                            members.filter(({ value: userId }) => userId !== _id) || [];
                          updateDB(
                            'settingsGroups/',
                            {
                              members: membersUpdated,
                              numberOfMembers: membersUpdated.length.toString()
                            },
                            groupId
                          ).catch((error) => console.log(error));
                        })
                        .catch((error) => console.log(error));
                    });
                  }
                })
                .catch((error) => console.log(error));
            });
          })
          .catch((error) => console.log(error));
      },
      onSelect(id) {
        if (collectionName === 'userProfiles') {
          setReferencesSelectedId(id);
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const styleMultipleRows = (data, collection) => {
    if (collection === 'user') {
      const rows = data.map((row) => {
        const { selectedBoss } = row;
        return createUserRow(
          row._id,
          row.name,
          row.lastName,
          row.email,
          row.selectedUserProfile ? row.selectedUserProfile.label || '' : '',
          selectedBoss ? `${selectedBoss.name} ${selectedBoss.lastName}` : '',
          row.creationUserFullName,
          row.creationDate,
          row.updateDate,
          row.fileExt
        );
      });
      return rows;
    } else if (collection === 'userProfiles') {
      const rows = data.map((row) => {
        return createUserProfilesRow(
          row._id,
          row.name,
          row.creationUserFullName,
          row.creationDate,
          row.updateDate,
          row.fileExt
        );
      });
      return rows;
    }
  };

  const currentTabName = getCurrentModuleName('users', tab);

  return (
    <>
      <ModalYesNo
        showModal={selectReferenceConfirmation}
        onOK={() => setSelectReferenceConfirmation(false)}
        onCancel={() => setSelectReferenceConfirmation(false)}
        title={'Add New User'}
        message={'Please first select a Reference from the next tab'}
      />
      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  component="div"
                  className="builder-tabs"
                  value={tab}
                  onChange={(_, nextTab) => setTab(nextTab)}
                >
                  {TabsTitles('users', user.profilePermissions['users'])}
                </Tabs>
              </PortletHeaderToolbar>
            }
          />

          {tab === 0 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>Users List</code>
                    </span> */}
                    <ModalUsers
                      fields={(allFields || {})['user']}
                      fieldsToValidate={(fieldsToValidate || {})['user']}
                      policies={policies}
                      showModal={control.openUsersModal}
                      setShowModal={(onOff) => setControl({ ...control, openUsersModal: onOff })}
                      readOnly={control.userReadOnly}
                      reloadTable={() => loadUsersData('user')}
                      id={control.idUser}
                      userProfileRows={allUserProfiles}
                      userLocations={userLocations}
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.user}
                        headRows={usersHeadRows}
                        locationControl={(locationId) => {
                          setTableControl((prev) => ({
                            ...prev,
                            user: {
                              ...prev.user,
                              treeViewLocation: locationId
                            }
                          }));
                        }}
                        onAdd={tableActions('users').onAdd}
                        onDelete={tableActions('users').onDelete}
                        onEdit={tableActions('users').onEdit}
                        onSelect={tableActions('users').onSelect}
                        onView={tableActions('users').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            user: {
                              ...prev.user,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.usersRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.user.search === value) {
                            loadUsersData('user', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              user: {
                                ...prev.user,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            user: {
                              ...prev.user,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            user: {
                              ...prev.user,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab={currentTabName}
                        title={GetTranslatedValue('USER.LIST.TITLE')}
                        tileView
                        treeView
                        userLocations={userLocations}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 1 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>User Profiles</code>
                    </span> */}
                    <ModalUserProfiles
                      fields={(allFields || {})['userReferences']}
                      fieldsToValidate={(fieldsToValidate || {})['userReferences']}
                      policies={policies}
                      showModal={control.openUserProfilesModal}
                      setShowModal={(onOff) =>
                        setControl({ ...control, openUserProfilesModal: onOff })
                      }
                      readOnly={control.userProfilesReadOnly}
                      reloadProfiles={() => loadUserProfiles()}
                      reloadTable={() => loadUsersData('userProfiles')}
                      id={control.idUserProfile}
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.userProfiles}
                        headRows={userProfilesHeadRows}
                        onAdd={tableActions('userProfiles').onAdd}
                        onDelete={tableActions('userProfiles').onDelete}
                        onEdit={tableActions('userProfiles').onEdit}
                        onSelect={tableActions('userProfiles').onSelect}
                        onView={tableActions('userProfiles').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            userProfiles: {
                              ...prev.userProfiles,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.userProfilesRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.userProfiles.search === value) {
                            loadUsersData('userProfiles', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              userProfiles: {
                                ...prev.userProfiles,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            userProfiles: {
                              ...prev.userProfiles,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            userProfiles: {
                              ...prev.userProfiles,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab={getCurrentModuleName('users', tab)}
                        title={GetTranslatedValue(`TABS.TITLES.${currentTabName}`.toUpperCase())}
                        tileView
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 2 && (
            <Policies
              setPolicies={setPolicies}
              module="user"
              customModule="users"
              baseFields={policiesBaseFields}
            />
          )}
        </Portlet>
      </div>
    </>
  );
}

const mapStateToProps = ({ general: { globalSearch }, auth: { user } }) => ({
  globalSearch,
  user
});
export default connect(mapStateToProps, general.actions)(Users);
