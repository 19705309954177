/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { omit, isEmpty } from 'lodash';
import clsx from 'clsx';
import {
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  TextField
} from '@material-ui/core';
import TextFields from '@material-ui/icons/TextFields';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/ducks/general.duck';
import { getDB, postDB, updateDB } from '../../../../crud/api';
import { getFirstDocCollection, GetTranslatedValue } from '../../utils';
import SaveButton from '../settings-tabs/components/SaveButton';
import { defaultValues, settings } from './constants';

const useStyle = makeStyles((theme) => ({
  mainRoot: {
    display: 'flex'
  },
  selectorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'flex-start'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  listItem: {
    display: 'flex',
    border: '1px solid #dbdbdb',
    borderRadius: '16px',
    padding: '20px',
    marginBottom: '20px'
  },
  listItemWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  textFieldWrapper: {
    width: '80%'
  },
  '@media (max-width: 1000px)': {
    mainRoot: {
      display: 'flex',
      flexDirection: 'column'
    },
    selectorWrapper: {
      width: '100%'
    },
    listItem: {
      marginBottom: '20px',
      display: 'flex'
    }
  }
}));

const Fields = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { showErrorAlert, showSavedAlert, showUpdatedAlert } = actions;
  const [values, setValues] = useState(defaultValues);
  const [selectedModule, setSelectedModule] = useState('');

  const handleChange = (name) => (event) => {
    if (name === 'selectedModule') {
      setSelectedModule(values.modules[event.target.value].id);
    }
    setValues({ ...values, [name]: event.target.value });
  };

  const handleUpdateCaptionLists = (selectedModule, id) => (event) => {
    const fields = values.fields;
    fields[selectedModule][id].caption = event.target.value;
    setValues((prev) => ({ ...prev, fields }));
  };

  const handleUpdateRegExLists = (selectedModule, id) => (event) => {
    const fields = values.fields;
    fields[selectedModule][id].regex = event.target.value;
    setValues((prev) => ({ ...prev, fields }));
  };

  const handleUpdateCaptionListsCheck = (selectedModule, id) => (event) => {
    const fields = values.fields;
    fields[selectedModule][id].mandatory = event.target.checked;
    setValues((prev) => ({ ...prev, fields }));
  };

  const handleSave = () => {
    const body = { ...values };
    getFirstDocCollection('settingsFields')
      .then((id) => {
        if (!id) {
          postDB('settingsFields', body)
            .then((data) => data.json())
            .then((response) => {
              dispatch(showSavedAlert());
            })
            .catch((error) => {
              dispatch(showErrorAlert());
            });
        } else {
          updateDB('settingsFields/', body, id)
            .then((response) => {
              dispatch(showUpdatedAlert());
            })
            .catch((error) => {
              dispatch(showErrorAlert());
            });
        }
      })
      .catch((ex) => {
        dispatch(showErrorAlert());
      });
  };

  const loadInitData = (collectionName = 'settingsFields') => {
    getDB(collectionName)
      .then((response) => response.json())
      .then((data) => {
        const _values = data.response[0] || {};
        if (!isEmpty(_values)) {
          setValues(omit(_values, '_id'));
        }
      })
      .catch((error) => console.log('error>', error));
  };

  useEffect(() => {
    loadInitData();
  }, []);

  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        {props.permissions.includes('edit') && <SaveButton handleOnClick={handleSave} />}
      </div>
      <div className={classes.mainRoot}>
        <div className={clsx(classes.selectorWrapper, 'profile-tab-wrapper')}>
          {settings.map(({ id, name, selected }, ix) => (
            <FormControl key={`caption-field-${ix}`} className={classes.textField}>
              <InputLabel>{GetTranslatedValue(name)}</InputLabel>
              <Select
                // value={values[selected]}
                onChange={handleChange(selected)}
                style={{ marginBottom: '20px' }}
              >
                {(values[id] || []).map((opt, ix) => (
                  <MenuItem key={`opt-${ix}`} value={ix}>
                    {/* {GetTranslatedValue(opt.name)} */}
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </div>
        <div className="profile-tab-wrapper__content">
          <List
            dense={false}
            subheader={
              <ListSubheader style={{ position: 'relative' }}>
                {GetTranslatedValue('SETTINGS.FIELDS.CAPTION.SELECT.CAPTIONS')}
              </ListSubheader>
            }
          >
            {(Object.keys(values.fields[selectedModule] || {}) || []).map((key, ix) => {
              const { id, name, caption, mandatory, regex, noEditable } =
                values.fields[selectedModule][key] || {};
              return (
                <ListItem className={classes.listItem}>
                  <div className={classes.listItemWrapper}>
                    <div style={{ display: 'flex' }}>
                      <ListItemIcon style={{ minWidth: '0px' }}>
                        <TextFields />
                      </ListItemIcon>
                      <ListItemText style={{ marginLeft: '10px' }} primary={name || 'Field Name'} />
                    </div>
                    <div className={classes.textFieldWrapper}>
                      <TextField
                        disabled={!props.permissions.includes('edit')}
                        label={GetTranslatedValue('SETTINGS.FIELDS.CAPTION.CUSTOM.CAPTION')}
                        value={caption}
                        onChange={handleUpdateCaptionLists(selectedModule, id)}
                        margin="normal"
                      />
                      <TextField
                        disabled={!props.permissions.includes('edit')}
                        label="RegEx"
                        value={regex}
                        onChange={handleUpdateRegExLists(selectedModule, id)}
                        margin="normal"
                      />
                    </div>
                  </div>
                  <ListItemSecondaryAction>
                    {!noEditable && (
                      <Switch
                        disabled={!props.permissions.includes('edit')}
                        edge="end"
                        onChange={handleUpdateCaptionListsCheck(selectedModule, id)}
                        checked={mandatory}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </>
  );
};

export default Fields;
