import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Editor } from 'react-draft-wysiwyg';
import { FormControlLabel, makeStyles, Switch, TextField } from '@material-ui/core';
import { PortletBody } from '../../../../../partials/content/Portlet';
import useWindowDimensions from '../../../hooks';
import '../modals/ModalPolicies.scss';
import { GetTranslatedValue } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '200px'
    }
  }
}));

const MessageTemplate = ({
  fromValue,
  fromOnChange,
  fromOptions,
  toValue,
  toOnChange,
  toOptions,
  subjectValue,
  subjectOnChange,
  subjectOnClick,
  subjectName,
  disablesOnChange,
  disabledValue,
  mailOnChange,
  mailValue,
  internalOnChange,
  internalValue,
  setSelectedControl,
  editor,
  editorStateChange,
  readOnly
}) => {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  return (
    <PortletBody>
      <div className="__container-sendmessage-panel">
        <div className="__container-form-checkbox">
          <div className="__container-form">
            <Autocomplete
              className={classes.textField}
              defaultValue={fromValue}
              disabled={readOnly}
              id="tags-message-from"
              getOptionLabel={(option) => option.email}
              multiple
              onChange={(event, values) => fromOnChange(event, values)}
              options={fromOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={GetTranslatedValue('TOPBAR.CAPTION.NOTIFICATIONS.MODAL.FROM')}
                  variant="standard"
                />
              )}
              value={fromValue}
            />
            <Autocomplete
              className={classes.textField}
              defaultValue={toValue}
              disabled={readOnly}
              getOptionLabel={(option) => option.email}
              id="tags-message-to"
              multiple
              onChange={(event, values) => toOnChange(event, values)}
              options={toOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={GetTranslatedValue('GENERAL.MAIL.TO')}
                  variant="standard"
                />
              )}
              value={toValue}
            />
            <TextField
              className={classes.textField}
              id="standard-subjectMessage"
              label={GetTranslatedValue('TOPBAR.CAPTION.NOTIFICATIONS.MODAL.SUBJECT')}
              margin="normal"
              name={subjectName}
              onChange={(event) => subjectOnChange(event)}
              onClick={subjectOnClick}
              value={subjectValue}
              inputProps={{ readOnly }}
            />
          </div>
          <div className="__container-checkbox">
            <FormControlLabel
              control={
                <Switch
                  checked={disabledValue}
                  color="primary"
                  onChange={(event) => disablesOnChange(event)}
                  disabled={readOnly}
                />
              }
              label={GetTranslatedValue('GENERAL.DISABLED')}
              labelPlacement={width < 600 ? 'top' : 'start'}
              value="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={mailValue}
                  color="primary"
                  onChange={(event) => mailOnChange(event)}
                  disabled={readOnly}
                />
              }
              label={GetTranslatedValue('GENERAL.MAIL')}
              labelPlacement={width < 600 ? 'top' : 'start'}
              value="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={internalValue}
                  color="primary"
                  onChange={(event) => internalOnChange(event)}
                  disabled={readOnly}
                />
              }
              label={GetTranslatedValue('GENERAL.INTERNAL')}
              labelPlacement={width < 600 ? 'top' : 'start'}
              value="start"
            />
          </div>
        </div>
        <div className="__container-policies-message" onClick={setSelectedControl}>
          <Editor
            editorClassName="editorClassName"
            editorState={editor}
            onEditorStateChange={editorStateChange}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            readOnly={readOnly}
          />
        </div>
      </div>
    </PortletBody>
  );
};

export default MessageTemplate;
