/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { omit, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import clsx from "clsx";
import {
  TextField,
  IconButton,
  FormControlLabel,
  makeStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import AddCircle from '@material-ui/icons/AddCircle';
import Notification from '@material-ui/icons/NotificationImportant';
import HelpIcon from '@material-ui/icons/Help';
import DeleteIcon from '@material-ui/icons/Delete';
import { ColorPicker } from 'material-ui-color';
import { actions } from '../../../../store/ducks/general.duck';
import { getDB, postDB, updateDB } from '../../../../crud/api';
import { getFirstDocCollection, GetTranslatedValue } from '../../utils';
import CustomizedToolTip from '../../Components/CustomizedToolTip';
import SaveButton from '../settings-tabs/components/SaveButton';

const useStyle = makeStyles((theme) => ({
  mainRoot: {
    display: 'flex',
  },
  colorAlertsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'flex-start'
  },
  '@media (max-width: 700px)': {
    mainRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    colorAlertsWrapper: {
      width: '100%',
    }
  },
}));

const Processes = props => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { showCustomAlert, showErrorAlert, showSavedAlert, showUpdatedAlert } = actions;
  const [values, setValues] = useState({ alerts: [], maxAssetsQuantity: '' });
  const [color, setColor] = useState('');
  const [days, setDays] = useState(0);
  const invalidMessage = GetTranslatedValue('SETTINGS.PROCESSES.ALERT.INVALID.VALUES');
  const existingMessage = GetTranslatedValue('SETTINGS.PROCESSES.ALERT.EXISTING.VALUES');
  const maxQuantityOutOfRange = GetTranslatedValue('GENERAL.PROCESSES.MAX.QUANTITY.EXCEEDED');

  const handleChangeColor = async newValue => {
    setColor(newValue);
  };
  const handleAddAlert = () => {
    if (!color || !days || days < 1) {
      dispatch(
        showCustomAlert({
          open: true,
          message: invalidMessage,
          type: 'warning'
        })
      );
      return;
    }
    const found = (values.alerts || []).find(x => x.days === days);
    if (found) {
      dispatch(
        showCustomAlert({
          open: true,
          message: existingMessage,
          type: 'warning'
        })
      );
      return;
    }
    const newAlert = { days, color: `#${color.hex}` };
    const alerts = values.alerts || [];
    alerts.push(newAlert);
    setValues(prev => ({ ...prev, alerts }));
    setColor('');
    setDays(0);
  };
  const handleDeleteAlert = (days) => {
    const alerts = (values.alerts || []).filter(x => Number(x.days) !== Number(days));
    setValues(prev => ({ ...prev, alerts }));
  };
  const handleSave = () => {
    if (parseInt(values.maxAssetsQuantity) > 300 || parseInt(values.maxAssetsQuantity) <= 0) {
      dispatch(
        showCustomAlert({
          open: true,
          message: maxQuantityOutOfRange,
          type: 'warning'
        })
      );
      return;
    }
    const body = { ...values };
    getFirstDocCollection('settingsProcesses')
      .then(id => {
        if (!id) {
          postDB('settingsProcesses', body)
            .then(data => data.json())
            .then(response => {
              dispatch(showSavedAlert());
            })
            .catch(error => {
              console.log(error)
              dispatch(showErrorAlert());
            });
        } else {
          updateDB('settingsProcesses/', body, id)
            .then(response => {
              dispatch(showUpdatedAlert());
            })
            .catch(error => {
              dispatch(showErrorAlert());
            });
        }
      })
      .catch(ex => {
        dispatch(showErrorAlert());
      });
  };
  const loadInitData = (collectionName = 'settingsProcesses') => {
    getDB(collectionName)
      .then(response => response.json())
      .then(data => {
        const _values = data.response[0] || {};
        if (!isEmpty(_values)) {
          setValues(omit(_values, '_id'));
        }
      })
      .catch(error => console.log('error>', error));
  };

  useEffect(() => {
    loadInitData();
  }, []);

  const daysLabel = GetTranslatedValue('GENERAL.CAPTION.DAYS');
  const maxQuantityLabel = GetTranslatedValue('SETTINGS.PROCESSES.CAPTION.MAX.QUANTITY');
  const colorLabel = GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.COLOR');

  return (
    <div>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        {props.permissions.includes('edit') && <SaveButton handleOnClick={handleSave} />}
      </div>
      <div className={classes.mainRoot}>
        <div className={clsx(classes.colorAlertsWrapper, "profile-tab-wrapper")}>
          <h3>{GetTranslatedValue('SETTINGS.PROCESSES.CAPTION.COLOR.ALERTS')}</h3>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <TextField
              disabled={!props.permissions.includes('edit')}
              label={`${GetTranslatedValue('SETTINGS.PROCESSES.CAPTION.AFTER')} ${daysLabel}`}
              style={{ width: '200px', margin: '-25px 0 0 30px' }}
              type={'number'}
              value={days}
              onChange={e => setDays(e.target.value)}
              margin="normal"
            />
            <IconButton disabled={!props.permissions.includes('edit')} onClick={handleAddAlert} edge="end" style={{ margin: '-15px 0 0 5px' }}>
              <AddCircle />
            </IconButton>
          </div>
          <FormControlLabel
            style={{ marginTop: '20px', marginLeft: '-50px' }}
            value="2"
            control={(
              <ColorPicker value={color} onChange={handleChangeColor} disableAlpha />
            )}
            label={colorLabel}
            labelPlacement="start"
          />
          <h3 style={{ marginTop: '40px' }}>{maxQuantityLabel}</h3>
          <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
            <FormControl>
              <TextField
                disabled={!props.permissions.includes('edit')}
                label={maxQuantityLabel}
                style={{ width: '200px', margin: '-30px, 0px, 0px, 0px' }}
                InputProps={{ inputProps: { min: 1, max: 300 } }}
                type={'number'}
                value={values.maxAssetsQuantity}
                onChange={e => setValues({...values, maxAssetsQuantity: e.target.value})}
                margin="normal"
              />
              {
                (parseInt(values.maxAssetsQuantity) > 300 || parseInt(values.maxAssetsQuantity) <= 0) && (
                  <FormHelperText style={{ color: 'red' }}>{GetTranslatedValue("GENERAL.MAX.VALUE.EXCEEDED")}</FormHelperText>
                )
              }
            </FormControl>
            <CustomizedToolTip
              tooltipContent={
                <p style={{ padding: '5px' }}>{GetTranslatedValue('SETTINGS.PROCESSES.MAX.QUANTITY.DESCRIPTION')}</p>
              }
              content={
                <HelpIcon style={{ margin: '15px', cursor: 'pointer', fill: '#8e8e8e' }} fontSize='medium' />
              }
            />
          </div>
        </div>
        <div className={clsx(classes.colorAlertsWrapper, "profile-tab-wrapper")}>
          <List dense={false} style={{ marginTop: '25px' }}>
            {((values.alerts || []).sort((a, b) => a.days - b.days) || []).map(({ days, color }, ix) => (
              <ListItem key={`listItem-index-${ix}`}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: color }}>
                    <Notification />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<strong>{`${daysLabel}: ${days}`}</strong>}
                  secondary={`${colorLabel}: ${color}`}
                />
                <ListItemSecondaryAction>
                  <IconButton disabled={!props.permissions.includes('edit')} onClick={() => handleDeleteAlert(days)} edge="end" aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
}

export default Processes;
