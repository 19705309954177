import JSZip from 'jszip';
import mime from 'mime';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
// import { ObjectId } from 'mongodb';
import { requiredHeaders, userProfilesPermissions } from './layouts';
import { getCurrentDateTime, saveImage } from '../../utils';
import { getCountDB, getDB, getDBComplex, getOneDB, postDB, postDBEncryptPassword, postFILE, updateDB } from '../../../../crud/api';
import store from '../../../../store/store';
import { actions } from '../../../../store/ducks/general.duck';

const { showErrorAlert } = actions;

export const userDefaultPermissions = {
  assets: {
    list: ['view'],
    references: ['view'],
    categories: ['view'],
    policies: ['view']
  },
  processes: {
    live: ['view'],
    list: ['view'],
    stages: ['view']
  },
  users: {
    list: ['view'],
    references: ['view'],
    policies: ['view']
  },
  employees: {
    list: ['view'],
    references: ['view'],
    policies: ['view']
  },
  locations: {
    list: ['view'],
    profiles: ['view'],
    policies: ['view']
  },
  reports: {
    general: [
      "employees",
      "locations",
      "locationsReal",
      "fieldValuesRepeated",
      "user",
      "categories",
      "references",
      "assets",
      "depreciation",
      "processLive",
      "inventories",
      "logBook"
    ],
    saved: ['view']
  },
  settings: {
    general: ['view'],
    design: ['view'],
    fields: ['view'],
    processes: ['view'],
    "layouts & presets": ['view'],
    custom: ['view'],
    users: ['view'],
    groups: ['view'],
    import: ['view']
  },
  app: {
    Inventory: true,
    Tagging: true
  }
}

export const csvJSON = (csv) => {
  const lines = csv.split("\n");
  const result = [];

  const headers = lines[0].split("|");

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split("|");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return [headers, result];
};

export const handleZip = async (file, setFiles) => {
  if (!file) {
    setFiles([]);
  }

  const jszip = new JSZip();
  const files = await jszip.loadAsync(file)
    .then((zip) => {
      return Object.entries(zip.files).map(([key, values]) => {
        return zip.files[key].async('blob').then(async (data) => {
          const fileTemp = new File([data], key);
          const type = mime.getType(fileTemp.name);
          return new File([data], key, { type });
        })
      })
    });

  Promise.all(files).then(values => setFiles(values));
};

export const handleRar = (file) => {
  const reader = new FileReader();
};

export const validateRequiredValues = ({ baseFieldsValues, isExistingRecord, layout, row, showWarningMessage }) => {
  if (isExistingRecord) {
    return true;
  }

  let valid = true;
  baseFieldsValues.forEach((baseField) => {
    if (requiredHeaders[layout].includes(baseField?.targetField) && !row[baseField?.value]) {
      valid = false;
      showWarningMessage("Record import process skipped because it doesn't fill required values");
    }
  });

  return valid;
};

export const isValidMongoId = (id, collection) => {
  const regex = /^[0-9a-fA-F]{24}$/;

  if (!id) {
    return { validMongoId: false, record: null };
  }

  if (!regex.test(id)) {
    return { validMongoId: false, record: null };
  }

  // if (ObjectId(id).toString() !== id) {
  //   return { validMongoId: false, record: null };
  // }

  return getOneDB(`${collection}/`, id)
    .then((response) => response.json())
    .then((data) => ({ validMongoId: !isEmpty(data.response), record: data.response }));
};

export const isValidEmail = (email, collection, id, isExistingRecord) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  if (collection === 'employees' && !email) {
    return true;
  }

  if (!email && isExistingRecord) {
    return true;
  }

  if ((email && isExistingRecord) || !isExistingRecord) {
    if (!regex.test(email)) {
      return false;
    }
  }

  return getDBComplex({
    collection,
    condition: [{ "email": email }]
  })
    .then((response) => response.json())
    .then((data) => {
      if (!isEmpty(data.response)) {
        return data.response[0]['_id'] === id;
      }

      return true;
    });
};

export const getBaseFieldHeader = (baseFieldsValues, header) => {
  return baseFieldsValues.find((baseField) => baseField?.targetField === header)?.value || '';
}

const getPermissions = (row, value) => {
  let cataloguePermissions = [];

  if (row[value]) {
    cataloguePermissions = row[value].split(',').map((permission) => {
      return permission.trim();
    });

    if (!cataloguePermissions.includes('view')) {
      cataloguePermissions.push('view');
    }
  } else {
    cataloguePermissions.push('view')
  }

  return cataloguePermissions;
}

// export const manageUserProfilePermissions = ({ baseFieldsValues, record, row, rowValues, validMongoId }) => {
//   const permissionsObj = {};

//   baseFieldsValues.forEach((baseField) => {
//     if (!userProfilesPermissions.includes(baseField?.targetField)) {
//       return;
//     }

//     const isPoliciesPermisisons = baseField.targetField?.includes('policies');
//     const module = baseField.targetField?.replace(!isPoliciesPermisisons ? '_permissions' : '_policies_permissions', '');
//     const permissions = getPermissions(row, baseField.value);
//     const permissionName = !isPoliciesPermisisons ? 'list' : 'policies'

//     switch (module) {
//       case "assets":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['assets'] = { ...permissionsObj['assets'], [permissionName]: (record.users || {})[permissionName] || ['view'] }
//         } else {
//           permissionsObj['assets'] = { ...permissionsObj['assets'], [permissionName]: permissions }
//         }
//         break;
//       case "references":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['assets'] = { ...permissionsObj['assets'], 'references': (record.assets || {}).references || ['view'] }
//         } else {
//           permissionsObj['assets'] = { ...permissionsObj['assets'], 'references': permissions }
//         }
//         break;
//       case "categories":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['assets'] = { ...permissionsObj['assets'], 'categories': (record.assets || {}).categories || ['view'] }
//         } else {
//           permissionsObj['assets'] = { ...permissionsObj['assets'], 'categories': permissions }
//         }
//         break;
//       case "processes_live":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['processes'] = { ...permissionsObj['processes'], 'live': (record.processes || {}).live || ['view'] }
//         } else {
//           permissionsObj['processes'] = { ...permissionsObj['processes'], 'live': permissions }
//         }
//         break;
//       case "processes_list":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['processes'] = { ...permissionsObj['processes'], 'list': (record.processes || {}).list || ['view'] }
//         } else {
//           permissionsObj['processes'] = { ...permissionsObj['processes'], 'list': permissions }
//         }
//         break;
//       case "processes_stages":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['processes'] = { ...permissionsObj['processes'], 'stages': (record.processes || {}).stages || ['view'] }
//         } else {
//           permissionsObj['processes'] = { ...permissionsObj['processes'], 'stages': permissions }
//         }
//         break;
//       case "users":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['users'] = { ...permissionsObj['users'], [permissionName]: (record.users || {})[permissionName] || ['view'] }
//         } else {
//           permissionsObj['users'] = { ...permissionsObj['users'], [permissionName]: permissions }
//         }
//         break;
//       case "user_profiles":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['users'] = { ...permissionsObj['users'], 'references': (record.users || {}).references || ['view'] }
//         } else {
//           permissionsObj['users'] = { ...permissionsObj['users'], 'references': permissions }
//         }
//         break;
//       case "employees":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['employees'] = { ...permissionsObj['employees'], [permissionName]: (record.employees || {})[permissionName] || ['view'] }
//         } else {
//           permissionsObj['employees'] = { ...permissionsObj['employees'], [permissionName]: permissions }
//         }
//         break;
//       case "employee_profiles":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['employees'] = { ...permissionsObj['employees'], 'references': (record.employees || {}).references || ['view'] }
//         } else {
//           permissionsObj['employees'] = { ...permissionsObj['employees'], 'references': permissions }
//         }
//         break;
//       case "locations":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['locations'] = { ...permissionsObj['locations'], [permissionName]: (record.locations || {})[permissionName] || ['view'] }
//         } else {
//           permissionsObj['locations'] = { ...permissionsObj['locations'], [permissionName]: permissions }
//         }
//         break;
//       case "location_profiles":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['locations'] = { ...permissionsObj['locations'], 'profiles': (record.locations || {}).profiles || ['view'] }
//         } else {
//           permissionsObj['locations'] = { ...permissionsObj['locations'], 'profiles': permissions }
//         }
//         break;
//       case "saved_reports":
//         if (validMongoId && !row[baseField.value]) {
//           permissionsObj['reports'] = { ...permissionsObj['reports'], 'saved': (record.reports || {}).saved || ['view'] }
//         } else {
//           permissionsObj['reports'] = { ...permissionsObj['reports'], 'saved': permissions }
//         }
//         break;
//       default:
//         break;
//     }
//   });

//   permissionsObj['app'] = {
//     "Tagging": true,
//     "Inventory": true
//   }

//   return permissionsObj;
// }

export const isValidEmployeeId = (employeeId, record) => {
  if (isEmpty(employeeId)) {
    return true;
  }

  return getDBComplex({
    collection: 'employees',
    condition: [{ "employee_id": employeeId }]
  })
    .then((response) => response.json())
    .then((data) => {
      if (!record) {
        return isEmpty(data.response);
      }

      return data.response?._id === record?._id;
    });
};

export const getEmployeeLayout = (layoutName, record) => {
  if (!layoutName) {
    return null;
  }

  return getDBComplex({
    collection: 'settingsLayoutsEmployees',
    condition: [{ "name": layoutName }]
  })
    .then((response) => response.json())
    .then((data) => {
      if (!record?.layouSelected) {
        return !isEmpty(data.response) ? { label: data.response[0].name, value: data.response[0]._id } : null;
      }

      return isEmpty(data.response) ? record?.layoutSelected : { label: data.response[0].name, value: data.response[0]._id };
    });
};

export const getProfileByImportId = (importId, profileCollection) => {
  if (!importId) {
    return null;
  }

  return getDBComplex({
    collection: profileCollection,
    condition: [{ "importId": `${importId}` }, { "name": `${importId}` }],
    enableOrQuery: true
  })
    .then((response) => response.json())
    .then((data) => {
      if (isEmpty(data.response)) {
        return null;
      }

      return data.response[0];
    });
};

export const importIdHandler = (importId, collection) => {
  return getCountDB({
    collection,
    condition: [{ "importId": `${importId}` }]
  })
    .then((response) => response.json())
    .then((data) => data?.response?.count > 0);
};

export const getRecordCustomFields = (collection, id) => {
  return getOneDB(`${collection}/`, id)
    .then((response) => response.json())
    .then((data) => data.response.customFieldsTab);
};

export const childCustomFieldsAssignation = async ({ customFields, files, profileCollection, profileCustomFields, profileId, row, usedProfiles }) => {
  if (!profileId) {
    return [{}, profileCustomFields];
  }

  const profileCustomFieldsTab = profileCustomFields;
  const childCustomFieldsTab = { ...profileCustomFields };

  for (let i = 0; i < customFields.length; i++) {
    const { category, dataType: { value: type }, field: fieldName, header: { value: customFieldHeader }, id: customFieldId } = customFields[i];

    const profileCustomFieldObj = {
      id: customFieldId,
      content: type,
      values: {
        fieldName,
        initialValue: ''
      }
    };

    const childCustomFieldObj = {
      id: customFieldId,
      content: type,
      values: {
        fieldName,
        initialValue: `${row[customFieldHeader]}` || ''
      }
    };

    const [repeatedProfileCustomField, tab] = verifyRepeatedCustomFields(profileCustomFieldsTab, profileCustomFieldObj);

    if (repeatedProfileCustomField) {
      const left = [...childCustomFieldsTab[`tab-${tab}`].left];
      const right = [...childCustomFieldsTab[`tab-${tab}`].right];
      const indexLeft = left.findIndex(({ id }) => id === repeatedProfileCustomField.id);
      const indexRight = right.findIndex(({ id }) => id === repeatedProfileCustomField.id);

      if (indexLeft !== -1) {
        childCustomFieldObj.id = childCustomFieldsTab[`tab-${tab}`].left[indexLeft].id;
        await manageFileAndImageCustomField({ customFieldHeader, customFieldObj: childCustomFieldObj, files, row, type });
        childCustomFieldsTab[`tab-${tab}`].left[indexLeft] = childCustomFieldObj;
      } else if (indexRight !== -1) {
        childCustomFieldObj.id = childCustomFieldsTab[`tab-${tab}`].right[indexRight];
        await manageFileAndImageCustomField({ customFieldHeader, customFieldObj: childCustomFieldObj, files, row, type });
        childCustomFieldsTab[`tab-${tab}`].right[indexRight] = childCustomFieldObj;
      }
    } else {
      if (!usedProfiles.includes(profileId)) {
        profileCustomFieldsTab[`tab-0`] = {
          left: [
            ...((profileCustomFieldsTab['tab-0'] || {}).left || []),
            profileCustomFieldObj
          ],
          right: [...((profileCustomFieldsTab['tab-0'] || {}).right || [])],
          info: (profileCustomFieldsTab['tab-0'] || {}).info ? ({ ...(profileCustomFieldsTab['tab-0'] || {}).info }) : { name: 'Import Tab', columns: 1 }
        };
      }

      await manageFileAndImageCustomField({ customFieldHeader, customFieldObj: childCustomFieldObj, files, row, type });
      childCustomFieldsTab[`tab-0`].left.push(childCustomFieldObj);
    }
  }

  usedProfiles.push(profileId);
  return [childCustomFieldsTab, profileCustomFieldsTab];
};

export const getRecordCustomFieldsObject = async ({ collection, customFields, id, files, putValues, row, validMongoId }) => {
  let customFieldsTab = validMongoId ? await getRecordCustomFields(collection, id) || {} : {};

  for (let i = 0; i < customFields.length; i++) {
    const { category, dataType: { value: type }, field: fieldName, header: { value: customFieldHeader }, id: customFieldId } = customFields[i];

    const customFieldObj = {
      id: customFieldId,
      content: type,
      values: {
        fieldName,
        initialValue: putValues ? `${row[customFieldHeader]}` || '' : ''
      }
    };

    const [omit, tab] = verifyRepeatedCustomFields(customFieldsTab, customFieldObj);

    if (!omit) {
      await manageFileAndImageCustomField({ customFieldHeader, customFieldObj, files, row, type });

      customFieldsTab = {
        ...customFieldsTab,
        'tab-0': {
          ...(customFieldsTab['tab-0'] || {}),
          left: [
            ...((customFieldsTab['tab-0'] || {}).left || []),
            customFieldObj
          ],
          right: [...((customFieldsTab['tab-0'] || {}).right || [])],
          info: validMongoId ? customFieldsTab['tab-0'].info : { name: 'Import Tab', columns: 1 }
        }
      };
    }
  }

  return customFieldsTab;
};

export const manageFileAndImageCustomField = ({ customFieldHeader, customFieldObj, files, type, row }) => {
  if (type === 'imageUpload') {
    const image = files.find(({ name }) => name === row[customFieldHeader]);

    if (image) {
      const type = image?.type.split('/')[1];

      customFieldObj.values = {
        ...customFieldObj.values,
        fileName: uuidv4().split('-').pop(),
        initialValue: type
      };

      return saveImage(image, 'customFields', customFieldObj.values.fileName);
    }
  } else if (type === 'fileUpload') {
    const file = files.find(({ name }) => name === row[customFieldHeader]);

    if (file) {
      const fileExt = file?.type.split('/')[1];

      customFieldObj.values = {
        ...customFieldObj.values,
        fileExt,
        fileId: uuidv4().split('-').pop(),
        fileName: file.name,
        initialValue: ''
      };

      return postFILE('customFields', customFieldObj.values.fileId, file);
    }
  }
};

export const updateImportRecord = (values, collection, record) => {
  const { _id, customFieldsTab = {}, image, ...otherValues } = values;
  const type = image?.type.split('/')[1] || '';
  const body = { ...otherValues, customFieldsTab, ...(image ? { fileExt: type } : {}) };

  if (collection === 'assets' && record) {
    const { dateFormatted, timeFormatted } = getCurrentDateTime();
    body.history = [
      ...record.history,
      {
        processId: "Import",
        processName: "Asset Updated in General Import Module",
        processType: "update",
        label: `Asset Updated at: ${body.locationPath}`,
        date: `${dateFormatted} ${timeFormatted}`
      }
    ];
  }

  return updateDB(`${collection}/`, body, _id)
    .then(async (data) => {
      if (image) {
        const { response: { value } } = data;
        saveImage(image, collection, _id);

        return value;
      }
    })
    .catch((error) => console.log(error));
};

export const postImportRecord = (values, collection, isUser, newRootLocations = []) => {
  const { customFieldsTab = {}, image, ...otherValues } = values;
  const type = image?.type.split('/')[1] || '';
  const body = { ...otherValues, customFieldsTab, fileExt: type };

  if (collection === 'assets') {
    const { dateFormatted, timeFormatted } = getCurrentDateTime();

    body.history = [{
      processId: "Import",
      processName: "Asset Created in General Import Module",
      processType: "creation",
      label: `Asset Created at: ${body.locationPath}`,
      date: `${dateFormatted} ${timeFormatted}`
    }];
  }

  if (!isUser) {
    return postDB(collection, body)
      .then((response) => response.json())
      .then(async (data) => {
        const { _id, name, profileLevel, parent } = data.response[0];
        saveImage(image, collection, _id);

        if (collection === 'locationsReal' && profileLevel === '0') {
          newRootLocations.push({
            name,
            level: profileLevel,
            parent: _id,
            realParent: parent
          });
        }

        return data.response[0];
      })
      .catch((error) => console.log(error));
  } else {
    return postDBEncryptPassword(collection, { ...body, password: 'DummyPassword#56' })
      .then((response) => response.json())
      .then((data) => {
        const { _id } = data.response[0];
        saveImage(image, collection, _id)

        return data.response[0];
      })
      .catch((error) => console.log(error));
  }
};

export const isRepeatedName = (name, collection) => {
  return getCountDB({
    collection,
    condition: [{ name }]
  })
    .then((response) => response.json())
    .then((data) => data?.response.count > 0)
    .catch((error) => console.log(error));
};

const verifyRepeatedCustomFields = (customFields, customFieldObj) => {
  if (isEmpty(customFields)) {
    return [null, null];
  }

  for (let i = 0; i < Object.keys(customFields).length; i++) {
    const res = [...customFields[`tab-${i}`].left, ...customFields[`tab-${i}`].right];
    const found = res.find(({ content, values: { fieldName } }) => customFieldObj.content === content && customFieldObj.values.fieldName === fieldName);

    if (found) {
      return [found, i];
    }
  }

  return [null, null];
};

export const employeeAssignationProcess = (employeeId, asset) => {
  return getOneDB('employees/', employeeId)
    .then((response) => response.json())
    .then((data) => {
      const { assetsAssigned = [] } = data.response;

      const isAlreadyAssigned = assetsAssigned.some(({ EPC }) => EPC === asset.EPC);

      if (!isAlreadyAssigned) {
        const assetBody = {
          id: asset._id,
          name: asset.name,
          brand: asset.brand,
          model: asset.model,
          EPC: asset.EPC,
          serial: asset.serial,
          creationDate: asset.creationDate
        };
        const employeeAssets = [...assetsAssigned, assetBody];

        return updateDB('employees/', { assetsAssigned: employeeAssets }, employeeId)
          .catch((error) => console.log(error));
      }
    })
    .catch((error) => console.log(error));
};

export const employeeReassignationProcess = (employeeId, asset) => {
  return getOneDB('employees/', employeeId)
    .then((response) => response.json())
    .then((data) => {
      const { assetsAssigned = [] } = data.response;

      const index = assetsAssigned.findIndex(({ id }) => id === asset._id);
      assetsAssigned.splice(index);

      return updateDB('employees/', { assetsAssigned }, employeeId)
        .catch((error) => console.log(error));
    })
    .catch((error) => console.log(error));
};
