import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Icon,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';
import { actions } from '../../../../store/ducks/general.duck';
import AssetFinderPreview from '../../Components/AssetFinderPreview';
import ModalOneField from '../../Components/ModalOneField';
import ModalYesNo from '../../Components/ModalYesNo';
import { GetTranslatedValue } from '../../utils';
import LiveProcessInfo from './LiveProcessInfo';
import { getDBComplex } from '../../../../crud/api';

const useStyles = makeStyles((theme) => ({
  changeApprovalsGrid: {
    display: 'grid',
    gap: '20px',
    marginBottom: '25px',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    }
  },
  changeApprovalStageName: {
    textAlign: 'center',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    }
  }
}));

const LiveProcessTab = ({
  onSelectionChange,
  goBackLogic,
  processInfo,
  processType,
  setCartRows,
  onSetRows,
  user,
  rows,
  setProcessCartInfo,
  readOnly = false,
  userLocations = [],
  setPreventScroll,
  handleApprovalsChange,
  modifiedStages,
  setModifiedStages
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showCustomAlert } = actions;
  const [tabIndex, setTabIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selection, setSelection] = useState([]);
  const [localCartRows, setLocalCartRows] = useState([]);
  const [currentStage, setCurrentStage] = useState([]);
  const [goBackModal, setGoBackModal] = useState(false);
  const [preventInnerScroll, setPreventInnerScroll] = useState(false);
  const [approvals, setApprovals] = useState([]);

  useEffect(() => {
    if (Object.keys(processInfo).length) {
      const _currentStage =
        processInfo.processData.stages[processInfo.processData.currentStage - 1];
      setCurrentStage(_currentStage);
    }
  }, [processInfo]);

  useEffect(() => {
    const allApproved =
      !localCartRows || localCartRows.length === 0
        ? false
        : localCartRows.map(({ status }) => status).every((eachStatus) => eachStatus);
    if (allApproved) {
      dispatch(
        showCustomAlert({
          type: 'success',
          open: true,
          message: `All assets are validated`
        })
      );
    }
  }, [localCartRows]);

  useEffect(() => {
    setLocalCartRows(rows);
  }, [rows]);

  useEffect(() => {
    const loadApprovals = () => {
      return getDBComplex({
        collection: 'user',
        condition: [{ 'profilePermissions.processes.live': { $in: ['edit'] } }],
        fields: [
          { key: '_id', value: 1 },
          { key: 'email', value: 1 },
          { key: 'name', value: 1 },
          { key: 'lastName', value: 1 }
        ]
      })
        .then((response) => response.json())
        .then((data) => {
          return data.response;
        })
        .catch((error) => console.log(error));
    };

    const getUsers = async () => {
      const users = await loadApprovals();
      setApprovals(users);
    };

    getUsers();
  }, []);

  const handleChangeAssetValues = (newCartRows) => {
    setProcessCartInfo(newCartRows);
  };

  const handleRejectionClick = () => {
    if (selection.length) {
      setOpenModal(true);
    }
  };

  const handleRejectionOkClick = (message) => {
    setOpenModal(false);
    const cartTmp = localCartRows.reduce((acu, cur) => {
      return selection.findIndex((row) => row.id === cur.id) >= 0
        ? [...acu, { ...cur, status: 'Rejected', message }]
        : [...acu, cur];
    }, []);
    setLocalCartRows(cartTmp);
    onSetRows(cartTmp);
  };
  const handleApproveOkClick = (message) => {
    const cartTmp = localCartRows.reduce((acu, cur) => {
      return selection.findIndex((row) => row.id === cur.id) >= 0
        ? [...acu, { ...cur, status: 'Approved', message: '' }]
        : [...acu, cur];
    }, []);
    setLocalCartRows(cartTmp);
    onSetRows(cartTmp);
  };
  const handleSelection = ({ rows }) => {
    setSelection(rows);
  };

  const showButtons = (isCreation = false) => {
    const selfApprove =
      processInfo?.processData?.stages[0]?.isSelfApprove ||
      processInfo?.processData?.selectedProcessType === 'short';
    if (isCreation) {
      return true;
    }
    if (!currentStage || !Object.keys(currentStage).length > 0 || selfApprove) {
      return false;
    }

    const stageApprovals = currentStage.approvals.map(({ _id, id }) => _id || id);
    const thisApproval = currentStage.approvals.find(
      ({ _id, id, fulfilled }) => user.id === (_id || id) && !fulfilled
    );
    if (!stageApprovals.includes(user.id) || !thisApproval || thisApproval.fulfilled) {
      return false;
    }

    return true;
  };

  const showAssetEdition = () => {
    if (
      processInfo?.processData?.currentStage === 0 ||
      processInfo?.processData?.processStatus !== 'inProcess'
    ) {
      return true;
    }

    return false;
  };

  const assetEditionDisabled = () => {
    if (processInfo?.processData?.processStatus !== 'inProcess') {
      return true;
    }

    if (!currentStage || !Object.keys(currentStage).length > 0) {
      return true;
    }

    const stageApprovals = currentStage.approvals.map(({ _id, id }) => _id || id);
    const thisApproval = currentStage.approvals.find(
      ({ _id, id, fulfilled }) => user.id === (_id || id) && !fulfilled
    );

    if (!stageApprovals.includes(user.id) || !thisApproval || thisApproval.fulfilled) {
      return true;
    }

    if (currentStage.isAssetEdition) {
      return false;
    }

    return true;
  };

  const showGoBack = () => {
    if (!Object.keys(currentStage).length > 0) {
      return false;
    }

    if (!currentStage.goBackEnabled || !currentStage.goBackTo || currentStage.goBackTo === 'none') {
      return false;
    }
    return true;
  };

  const goBackConfirmationMessage = () => {
    if (!Object.keys(processInfo).length) {
      return;
    }
    var stageName;
    var stageNumber;
    processInfo.processData.stages.map((stage, ix) => {
      if (stage.stageId === currentStage?.goBackTo) {
        stageName = stage.stageName;
        stageNumber = ix + 1;
      }
    });

    return (
      <>
        <h6>
          Doing so will send this process back to the Stage number: <strong>{stageNumber}</strong>{' '}
          called: <strong>{stageName}</strong>
        </h6>
        <h6>All changes made from that stage to the current one will be deleted.</h6>
      </>
    );
  };

  const goBackConfirmation = () => {
    goBackLogic(currentStage);
    setGoBackModal(false);
  };

  const handlePreventScroll = (bool) => {
    setPreventScroll(bool);
    setPreventInnerScroll(bool);
  };

  const handleApprovalChange = (newApproval, stageIndex, approvalIndex) => {
    const newStages = [...modifiedStages];
    newStages[stageIndex].approvals[approvalIndex] = {
      ...newApproval,
      fulfillDate: '',
      fulfilled: false
    };

    setModifiedStages(newStages);
  };

  const isProcessComplete = () => {
    const { processData } = processInfo;

    if (processData) {
      const stageFulfilled = (processData.stages || []).map(({ stageFulfilled }) => ({
        stageFulfilled
      }));

      if (processData.selectedProcessType === 'short') {
        stageFulfilled.push({ stageFulfilled: true });
      }

      const isProcessComplete = stageFulfilled.every(
        ({ stageFulfilled }) => stageFulfilled === true
      );

      return isProcessComplete && stageFulfilled.length !== 0;
    }
  };

  return (
    <div>
      <ModalYesNo
        showModal={goBackModal}
        onOK={() => goBackConfirmation()}
        onCancel={() => setGoBackModal(false)}
        title={'Are you sure you want to send back this Process?'}
        message={goBackConfirmationMessage()}
      />
      <ModalOneField
        open={openModal}
        title="Rejection Message"
        message="Please share a rejection message for the selected assets"
        fieldName="Rejection Message"
        onOk={handleRejectionOkClick}
        onCancel={() => setOpenModal(false)}
      />
      <Paper>
        <Tabs
          onChange={(_, newValue) => setTabIndex(newValue)}
          value={tabIndex}
          variant="fullWidth"
        >
          <Tab
            label={GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.PROCESS.INFO', 'Process Info')}
          />
          <Tab
            label={GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.PROCESS.ASSETS', 'Process Assets')}
          />
          {!readOnly &&
            !isProcessComplete() &&
            user?.profilePermissions?.processes?.others?.includes('changeApprovals') && (
              <Tab
                label={GetTranslatedValue('GENERAL.CAPTION.CHANGEAPPROVALS', 'Change Approvals')}
              />
            )}
        </Tabs>
      </Paper>
      <SwipeableViews index={tabIndex} disabled={preventInnerScroll}>
        <TabContainer>
          <LiveProcessInfo processInfo={processInfo} />
        </TabContainer>
        <TabContainer>
          {showButtons() && (
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                  marginBottom: '25px'
                }}
              >
                {showGoBack() && (
                  <Button
                    color="secondary"
                    startIcon={<Icon>arrow_back</Icon>}
                    style={{ backgroundColor: '#1b39d1' }}
                    onClick={() => setGoBackModal(true)}
                    variant="contained"
                  >
                    Go Back
                  </Button>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                  marginBottom: '25px'
                }}
              >
                <Button
                  color="secondary"
                  disabled={readOnly}
                  endIcon={<Icon>thumb_down</Icon>}
                  style={{ backgroundColor: '#FD397A', marginLeft: '20px' }}
                  onClick={handleRejectionClick}
                  variant="contained"
                >
                  Reject
                </Button>
                <Button
                  color="secondary"
                  disabled={readOnly}
                  endIcon={<Icon>thumb_up</Icon>}
                  style={{ backgroundColor: 'green', marginLeft: '20px' }}
                  onClick={handleApproveOkClick}
                  variant="contained"
                >
                  Approve
                </Button>
              </div>
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <AssetFinderPreview
              isAssetReference={false}
              isSelectionTable={false}
              isPreviewTable={true}
              showSearchBar={false}
              onSelectionChange={handleSelection}
              rows={localCartRows}
              onSetRows={setCartRows}
              processType={processType}
              processInfo={processInfo}
              updateAssetValues={(newCartRows) => handleChangeAssetValues(newCartRows)}
              showAssetEdition={() => showAssetEdition()}
              assetEditionDisabled={() => assetEditionDisabled()}
              userLocations={userLocations}
              isLinkedToProcess={!!processInfo?.updateProcess}
              setPreventScroll={(bool) => handlePreventScroll(bool)}
            />
          </div>
        </TabContainer>
        <TabContainer>
          {modifiedStages?.map((stage, stageIndex) => {
            if (!stage.stageFulfilled) {
              return (
                <>
                  <Typography variant="h6" className={classes.changeApprovalStageName}>
                    {`${GetTranslatedValue('PROCESSES.CAPTION.STAGE', 'Stage')} ${stageIndex +
                      1} - ${stage.stageName}`}
                  </Typography>
                  <div className={classes.changeApprovalsGrid}>
                    {stage.approvals.map((approval, approvalIndex) => {
                      if (!approval.fulfilled) {
                        return (
                          <Autocomplete
                            getOptionLabel={(option) =>
                              `${option.name} ${option.lastName} <${option.email}>`
                            }
                            id={approvalIndex}
                            onChange={(event, newValue) => {
                              handleApprovalChange(newValue, stageIndex, approvalIndex);
                            }}
                            options={approvals}
                            renderInput={(params) => <TextField {...params} label="Approval" />}
                            value={approval}
                            disableClearable
                          />
                        );
                      }
                    })}
                  </div>
                </>
              );
            } else {
              return null;
            }
          })}
          <Button onClick={handleApprovalsChange} color="secondary" variant="contained">
            Guardar Nuevos Aprobadores
          </Button>
        </TabContainer>
      </SwipeableViews>
    </div>
  );
};

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3, height: 'max-content' }}>
      {children}
    </Typography>
  );
};

export default LiveProcessTab;
