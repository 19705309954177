import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { isEmpty, uniq } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { actions } from '../../../../../store/ducks/general.duck';
import { getDB, getOneDB, updateDB, postDB, getDBComplex } from '../../../../../crud/api';
import { convertToObjectIdArray, copyObject, GetTranslatedValue } from '../../../utils';

// Example 5 - Modal
const styles5 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle5 = withStyles(styles5)(({ children, classes, onClose }) => {
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContent5 = withStyles(() => ({
  root: {
    padding: '0px 16px 30px 16px'
  }
}))(DialogContent);

const DialogActions5 = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(DialogActions);

// Example 1 - TextField
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
}));

export default function ModalGroups({
  readOnly,
  showModal,
  setShowModal,
  reloadTable,
  id,
  employeeProfileRows,
  groups
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    showCustomAlert,
    showErrorAlert,
    showSavedAlert,
    showUpdatedAlert,
    showFillFieldsAlert
  } = actions;
  const [users, setUsers] = useState([]);
  const [initialMembers, setInitialMembers] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);
  const [values, setValues] = useState({
    members: [],
    name: ''
  });
  const nameGroupMessage = GetTranslatedValue('SETTINGS.GROUPS.ALERT.NAME.THE.GROUP');
  const notEnoughMessage = GetTranslatedValue('SETTINGS.GROUPS.ALERT.NOT.ENOUGH.MEMBERS');
  const alreadyExistsMessage = GetTranslatedValue('SETTINGS.GROUPS.ALERT.NAME.ALREADY.EXISTS');

  const displayWarningError = (message) => {
    dispatch(
      showCustomAlert({
        message,
        open: true,
        type: 'warning'
      })
    );
  };

  const getMembersInformation = (membersIds) => {
    return getDBComplex({
      collection: 'user',
      condition: { _id: { $in: convertToObjectIdArray(membersIds) } },
      disableAndQuery: true
    })
      .then((response) => response.json())
      .then((data) => data?.response || [])
      .catch((error) => console.log(error));
  };

  const updateGroupNameToUsers = async (groupId, groupName, membersIds) => {
    const members = await getMembersInformation(membersIds);

    return Promise.all(
      members.map(({ _id: userId, groups }) => {
        const index = groups.findIndex(({ id, value }) => (id || value) === groupId);

        if (index !== -1) {
          const copy = copyObject(groups);

          copy[index].value = groupId;
          copy[index].name = groupName;

          if (copy.numberOfMembers) {
            delete copy.numberOfMembers;
          }

          return updateDB('user/', { groups: copy }, userId).catch((error) => console.log(error));
        }

        return null;
      })
    );
  };

  const updateRelatedProcesses = (groupId, groupName, members) => {
    const group = {
      name: groupName,
      members,
      email: `members: ${members.length}`
    };

    getDBComplex({
      collection: 'processStages',
      condition: [
        {
          $or: [
            { approvals: { $elemMatch: { _id: groupId } } },
            { notifications: { $elemMatch: { _id: groupId } } }
          ]
        }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          data.response.forEach((stage) => {
            const { notifications, approvals } = stage;

            const notificationsIndex = notifications?.findIndex(({ _id }) => _id === groupId);
            const approvalsIndex = approvals?.findIndex(({ _id }) => _id === groupId);

            if (notificationsIndex !== -1) {
              notifications[notificationsIndex] = {
                ...notifications[notificationsIndex],
                ...group
              };
            }

            if (approvalsIndex !== -1) {
              approvals[approvalsIndex] = { ...approvals[approvalsIndex], ...group };
            }

            updateDB('processStages/', { notifications, approvals }, stage._id)
              .catch((error) => console.log(error));
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSave = () => {
    const { name, members } = values;

    if ((!name.length || !name.trim()) && members.length < 1) {
      dispatch(showFillFieldsAlert());
    }

    if (!name.length || !name.trim()) {
      displayWarningError(nameGroupMessage);

      return;
    }

    if (members.length < 1) {
      displayWarningError(notEnoughMessage);

      return;
    }

    let valid = true;

    groups.forEach((group) => {
      if (!id) {
        if (group.name.toLowerCase() === name.toLowerCase()) {
          valid = false;
        }
      } else {
        if (group.id !== id[0] && group.name.toLowerCase() === name.toLowerCase()) {
          valid = false;
        }
      }
    });

    if (!valid) {
      displayWarningError(alreadyExistsMessage);

      return;
    }

    const body = { ...values };

    if (!id) {
      postDB('settingsGroups', body)
        .then((response) => response.json())
        .then((data) => {
          const { response } = data;
          dispatch(showSavedAlert());
          saveAndReload();
          addedUsers.forEach(({ value: userId }) => {
            const { _id: groupId, name } = response[0];
            const userGroup = { value: groupId, name, numberOfMembers: body.numberOfMembers };
            getOneDB('user/', userId)
              .then((response) => response.json())
              .then((data) => {
                const {
                  response: { groups }
                } = data;
                const userBody = groups && !isEmpty(groups) ? [...groups, userGroup] : [userGroup];
                updateDB('user/', { groups: userBody }, userId).catch((error) =>
                  console.log(error)
                );
              })
              .catch((error) => console.log(error));
          });
        })
        .catch((error) => console.log(error));
    } else {
      updateDB('settingsGroups/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          dispatch(showUpdatedAlert());
          saveAndReload();

          if (value?.name !== body.name) {
            const membersIds =
              body.members
                .filter(({ value: userId }) => !addedUsers.some(({ value }) => userId === value))
                .map(({ value }) => value) || [];

            updateGroupNameToUsers(id[0], body.name, membersIds);
          }

          if (value?.members !== body.members) {
            updateRelatedProcesses(id[0], body.name, body.members);
          }

          addedUsers.forEach(({ value: userId }) => {
            const { name } = body;
            const userGroup = { value: id[0], name };
            getOneDB('user/', userId)
              .then((response) => response.json())
              .then((data) => {
                const {
                  response: { groups }
                } = data;
                let userBody = [];

                userBody = [...groups, userGroup];

                updateDB('user/', { groups: userBody }, userId).catch((error) =>
                  console.log(error)
                );
              })
              .catch((error) => console.log(error));
          });
          removedUsers.forEach((userId) => {
            getOneDB('user/', userId)
              .then((response) => response.json())
              .then((data) => {
                const {
                  response: { groups }
                } = data;
                const userBody =
                  (groups || []).filter(
                    (userGroup) => (userGroup.id || userGroup.value) !== id[0]
                  ) || [];
                updateDB('user/', { groups: userBody }, userId).catch((error) =>
                  console.log(error)
                );
              })
              .catch((error) => console.log(error));
          });
        })
        .catch((error) => console.log(error));
    }
    handleCloseModal();
  };

  const saveAndReload = (folderName, id) => {
    reloadTable();
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };

  const handleOnChangeValue = (event) => {
    const {
      target: { value }
    } = event;
    setValues((prev) => ({ ...prev, name: value }));
  };

  const reset = () => {
    setValues({
      name: '',
      members: []
    });
    setRemovedUsers([]);
    setAddedUsers([]);
  };

  const loadInitData = () => {
    getDB('user')
      .then((response) => response.json())
      .then((data) => {
        const usersTemp = data.response.map(({ _id: value, email: label, name, lastName }) => ({
          value,
          label,
          name,
          lastName
        }));
        setUsers(usersTemp);
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  useEffect(() => {
    if (!id || !Array.isArray(id)) {
      reset();
      loadInitData();
      return;
    }

    if (!showModal) {
      return;
    }

    getOneDB('settingsGroups/', id[0])
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          const { name, members } = data.response;
          setValues((prev) => ({ ...prev, members, name }));
          setInitialMembers(members);
        }
      })
      .catch((error) => console.log(error));
  }, [id, employeeProfileRows, showModal]);

  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" onClose={handleCloseModal} open={showModal}>
        <DialogTitle5 id="customized-dialog-title" onClose={handleCloseModal}>
          {`${id
            ? readOnly
              ? GetTranslatedValue('GENERAL.CAPTION.VIEW')
              : GetTranslatedValue('GENERAL.CAPTION.EDIT')
            : GetTranslatedValue('GENERAL.CAPTION.ADD')
            } ${GetTranslatedValue('SETTINGS.USERS.GROUPS.MODAL.CAPTION')}`}
        </DialogTitle5>
        <DialogContent5 dividers>
          <div className="kt-section__content">
            <div className={classes.textField}>
              <TextField
                disabled={readOnly}
                id="standard-name"
                label={GetTranslatedValue('RECORD.CAPTION.NAME')}
                margin="normal"
                name="name"
                onChange={handleOnChangeValue}
                value={values.name}
              />
            </div>
            <div style={{ marginTop: '30px' }} className={classes.textField}>
              <FormLabel style={{ marginTop: '0px' }} component="legend">
                {GetTranslatedValue('SETTINGS.GROUPS.CAPTION.MEMBERS')}
              </FormLabel>
              <FormGroup>
                <Select
                  isDisabled={readOnly}
                  classNamePrefix="select"
                  isClearable
                  isMulti
                  menuPlacement="bottom"
                  menuPosition="fixed"
                  name="color"
                  onChange={(members) => {
                    const membersUpdated = members || [];
                    if (values.members.length > membersUpdated.length) {
                      const userRemoved = values.members.find(
                        (member) => !membersUpdated.includes(member)
                      );
                      setRemovedUsers((prev) => [...prev, userRemoved.value]);
                    } else {
                      const userAssigned = membersUpdated.find(
                        (member) => !values.members.includes(member)
                      );
                      setRemovedUsers((prev) =>
                        prev.filter((member) => member !== userAssigned.value)
                      );
                    }

                    const userAssigned = membersUpdated.find(
                      (member) => !values.members.includes(member)
                    );

                    if (
                      userAssigned &&
                      initialMembers.findIndex(({ value }) => userAssigned?.value === value) === -1
                    ) {
                      setAddedUsers((prev) => uniq([...prev, userAssigned]));
                    }

                    setValues((prev) => ({ ...prev, members: membersUpdated }));
                  }}
                  options={users}
                  value={values.members}
                />
              </FormGroup>
            </div>
          </div>
        </DialogContent5>
        {!readOnly && (
          <DialogActions5>
            <Button onClick={handleSave} color="primary">
              Save changes
            </Button>
          </DialogActions5>
        )}
      </Dialog>
    </div>
  );
}
