/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  makeStyles,
  lighten,
} from '@material-ui/core/styles';
import {
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import QueueIcon from '@material-ui/icons/Queue';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import ViewModuleRoundedIcon from '@material-ui/icons/ViewModuleRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import TileView from './TileView';
import TreeView from './TreeViewComponent';
import CircularProgressCustom from './CircularProgressCustom';
import OverflowTip from './OverflowTip';
import { collections } from '../constants';
import TableGroupingSelector from './TableGroupingSelector';
import TableGrouping from './TableGrouping';
import { findConditionRecursive } from './tableGroupingHelpers';
import { actions } from '../../../store/ducks/general.duck';
import ModalRemoveElement from './ModalRemoveElement';
import IndentedTable from './IndentedTable';
import { GetTranslatedValue, loadLocationsData } from '../utils';

const useToolbarStyles = makeStyles(theme => ({
  actionsContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'end'
    }
  },
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingTop: '0px'
    }
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
      },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'end',
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tableActionsIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '10px 0px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      margin: '0px'
    }
  },
  title: {
    alignSelf: 'center',
    flex: 1,
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 auto',
      marginBottom: '0px'
    }
  },
  search: {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    border: '1px #ffffff00 solid',
    borderRadius: '12px',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
      width: 'auto'
    }
  },
  searchIcon: {
    position: 'relative',
    marginRight: '1px',
    marginLeft: '12px'
  },
  inputInput: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#FFFFFF00'
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  inputOrderByHidden: {
    width: '40.25px',
    height: '40.25px'
  },
  paper: {
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  inputSearchBy: {
    backgroundColor: '#fafafa',
    border: 'none',
    borderRadius: '12px',
    marginTop: '16px',
    outline: 'none',
    padding: '5px 10px 5px 0px',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&::placeholder': {
      paddingLeft: '5px'
    }
  },
  inputSearchByDisabled: {
    marginTop: '16px',
    width: '100%',
    height: '29px'
  },
  popover: {
    width: '800px',
  },
  grid: {
    padding: '20px'
  },
  listItemText: {
    marginRight: '20px',
  },
  list: {
    maxHeight: '450px',
    minHeight: '200px',
    minWidth: '120px',
  }
}));

const TableComponentTile = props => {
  const {
    controlValues,
    headRows,
    locationControl,
    noEdit = false,
    noAdd = false,
    noDelete = false,
    onAdd,
    onSelect,
    paginationControl,
    rows = null,
    searchControl,
    style = {},
    sortByControl,
    tab = '',
    treeView = false,
    tileView = false,
    childView = false,
    disableSearchBy = false,
    user,
    userLocations = [],
    disableActions = false,
    justTreeView = false,
    returnObjectOnSelect = false,
    selectedObjects,
    tableGrouping = false,
    tableGroupingCreateRows,
    fetchTableGroupingData,
    setTableGroupingControl,
    customModule = null,
    tableGroupingValues,
    updateChildGrouping,
    noSearch = null,
    noRowsPerPage,
    setTableRowsInner,
    disableGeneralCheckbox
  } = props;
  const dispatch = useDispatch();
  const { setTableGroupingValues } = actions;
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectedObject, setSelectedObject] = useState(selectedObjects);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const isSelected = name => {
    return selected.indexOf(name) !== -1;
  };
  const [order, setOrder] = useState(controlValues.order === 1 ? 'asc' : 'desc');
  const [orderBy, setOrderBy] = useState(controlValues.orderBy);
  const [rowsPerPage, setRowsPerPage] = useState(controlValues.rowsPerPage);
  const [page, setPage] = useState(controlValues.page);
  const [currentPageVisual, setCurrentPageVisual] = useState(controlValues.page + 1);
  const [columnPicker, setColumnPicker] = useState([]);
  const [openColumnSelector, setOpenColumnSelector] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [windowCoords, setWindowCoords] = useState({ left: 0, top: 0 });
  const [columnInputValue, setColumnInputValue] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [findColumn, setFindColumn] = useState('');
  const [locationsTree, setLocationsTree] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [openedParents, setOpenedParents] = useState([])
  const [display, setDisplay] = useState({ bool: false, id: null });
  const [loadingIndentedTable, setLoadingIndentedTable] = useState(null);
  const [isPaginationInputFocused, setIsPaginationInputFocused] = useState(false);
  const module = collections[controlValues.collection]?.module;
  const permissions = user.profilePermissions[customModule || module][tab] || [];


  const columnPickerControl = (headRows) => {
    const columns = headRows.map((column) => ({ ...column, visible: true }));

    if (columns !== columnPicker) {
      setColumnPicker(columns);
    }
  };

  useEffect(() => {
    setLoadingIndentedTable(null);
  }, [controlValues]);

  useEffect(() => {
    if (!paginationControl) return;
    paginationControl({ rowsPerPage, page });
    setOpenedParents([])
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (!isEmpty(controlValues.tableGrouping)) {
      setLoading(false);
      setLoadingIndentedTable(null);
    }
  }, [controlValues.tableGrouping])

  useEffect(() => {
    if (returnObjectOnSelect || selectedObject) {
      onSelect(selectedObject);
    }
  }, [selectedObject]);

  useEffect(() => {
    sortByControl({ orderBy: orderBy, order: order === 'asc' ? 1 : -1 });
  }, [order, orderBy]);

  useEffect(() => {
    columnPickerControl(headRows);
  }, [headRows]);

  useEffect(() => {
    dispatch(setTableGroupingValues([]));
  }, [controlValues.collection]);

  useEffect(() => {
    if (!isEmpty(userLocations)) {
      loadLocations();
    }
  }, [userLocations])

  useEffect(() => {
    if (!rows) {
      setLoading(true);

      return;
    }

    if (rows || controlValues.search.length) {
      setLoading(false);
      setLoadingIndentedTable(null);
    }

    if (!rows.length && page > 0) {
      setPage(page - 1);
    }
  }, [rows]);

  const loadLocations = async () => {
    const locationsTreeData = await loadLocationsData(userLocations);
    setLocationsTree(locationsTreeData);
  };

  const onLocationSelected = (locationId, level, parent, locationName, children) => {
    if (locationId !== selectedLocation) {
      setLoading(true);
      setSelectedLocation(locationId);
      if (locationId !== 'root') {
        locationControl(locationId);
      } else {
        locationControl('');
      }
    }
  };

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenColumnSelector(true);
    setWindowCoords({ left: event.pageX - 60, top: event.pageY + 24 });
  };

  const debouncer = (value, field) => {
    setLoading(true);
    setColumnInputValue(value);
    setSearchBy(field);
    searchControl({ value, field });
  };

  const pageDebouncer = (value, currentPage, total) => {
    if (!isNaN(value)) {
      const number = Number(value);
      const pageValue = number > total || number < 1 ? currentPage : number - 1;
      setPage(pageValue);
      setCurrentPageVisual(pageValue + 1);
    } else {
      setCurrentPageVisual(currentPage + 1);
    }
  };

  const inputChangeDebounced = useRef(debounce(debouncer, 1000));
  const pageChangeDebounced = useRef(debounce(pageDebouncer, 450));

  const handleParents = (idToEdit) => {
    let temporalOpenedParents = JSON.parse(JSON.stringify(openedParents));
    const indexToEdit = temporalOpenedParents.findIndex((id) => id === idToEdit);

    if (indexToEdit >= 0) {
      temporalOpenedParents.splice(indexToEdit, 1);
    } else {
      temporalOpenedParents = [...temporalOpenedParents, idToEdit];
    }
    setOpenedParents(temporalOpenedParents)
  };

  const isParentOpened = (idToCheck) => {
    return openedParents.find((id) => id === idToCheck)
  }

  const handleInputChangeDebounced = (event, field) => {
    if (event && !(searchBy === field && columnInputValue === event.target.value)) {
      const { target: { value } } = event;
      inputChangeDebounced.current(value, field);
    }
  };

  const handleInputPressDebounced = (event, field) => {
    if (event.key === 'Enter') {
      const { target: { value } } = event;
      // inputChangeDebounced.current(value, field);
      debouncer(value, field);
    }
  };

  const handleInputChange = (e, field) => {
    if (e.key === 'Enter') {
      paginationControl({ rowsPerPage, page: 0 });
      setPage(0);
      setCurrentPageVisual(1);
      setLoading(true);
      setSearchValue(e.target.value)
      setSearchBy(field);
      searchControl({ value: e.target.value, field });
      setOpenedParents([]);
    }
  };

  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { selected, onAdd, noEdit, noAdd, noDelete } = props;
    const numSelected = selected.length;

    const onDelete = () => {
      props.onDelete();
    }

    useEffect(() => {
      if (!props.onSelect || returnObjectOnSelect) {
        return;
      };
      const selectedIdToSend = numSelected ? selectedId : null;
      props.onSelect(selectedIdToSend);
    }, [numSelected]);

    const HeaderTools = () => {
      if (disableActions) {
        return (
          <div></div>
        );
      }

      if (numSelected > 0) {
        return (
          <div style={{ display: 'flex' }}>
            {numSelected === 1 && !noEdit && permissions.includes('view') && (
              <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.VIEW')}>
                <IconButton aria-label='View' onClick={props.onView}>
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
            )
            }
            {numSelected === 1 && !noEdit && permissions.includes('edit') && (
              <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.EDIT')}>
                <IconButton aria-label='Edit' onClick={props.onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )
            }
            {
              permissions.includes('delete') && !noDelete && (
                <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.DELETE')}>
                  <IconButton aria-label='Delete' onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          </div>
        )
      }

      return (
        <Grid className={classes.actionsContainer} container item>
          {!noSearch && (
            <div aria-label='Search Box' className={classes.search} key='SearchDiv'>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <input
                autoFocus={!controlValues.searchBy && !isPaginationInputFocused}
                className={classes.inputInput}
                key='search-input'
                onKeyPress={(event) => handleInputChange(event, null)}
                placeholder={`${GetTranslatedValue('GENERAL.CAPTION.SEARCH')}...`}
                defaultValue={searchValue}
                onMouseLeave={(e) => {
                  if (e.target.value !== searchValue) {
                    setSearchValue(e.target.value)
                  }
                }}
              />
              {
                (controlValues.search.length > 0 && !controlValues.searchBy) && (
                  <div>
                    <IconButton
                      onClick={() => {
                        setSearchValue('');
                        searchControl({ value: '' });
                        setLoading(true);
                      }}
                      size="small"
                      style={{ marginRight: '10px' }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                )
              }
              {
                (!controlValues.search.length || controlValues.searchBy) && (
                  <div style={{ width: '25px' }} />
                )
              }
            </div>
          )}
          <div className={classes.tableActionsIcons}>
            <Tooltip title={GetTranslatedValue('TABLE.ACTION.CAPTION.TABLE.VIEW')}>
              <IconButton aria-label='Table View' onClick={showTableView}>
                <ListRoundedIcon />
              </IconButton>
            </Tooltip>
            {
              tileView && (
                <Tooltip title={GetTranslatedValue('TABLE.ACTION.CAPTION.TILE.VIEW')}>
                  <IconButton aria-label='Tile view' onClick={showTileView}>
                    <ViewModuleRoundedIcon />
                  </IconButton>
                </Tooltip>
              )
            }
            {
              treeView && (
                <Tooltip title={GetTranslatedValue('TABLE.ACTION.CAPTION.TREE.VIEW')}>
                  <IconButton aria-label='Tree view' onClick={showTreeView}>
                    <AccountTreeRoundedIcon />
                  </IconButton>
                </Tooltip>
              )
            }
            <Tooltip title={GetTranslatedValue('TABLE.ACTION.CAPTION.COLUMN.PICKER')}>
              <IconButton aria-label='Column Picker' onClick={recordButtonPosition}>
                <ViewColumnRoundedIcon />
              </IconButton>
            </Tooltip>
            {
              typeof onAdd == 'function' && permissions.includes('add') && !noAdd && (
                <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.ADD')}>
                  <IconButton aria-label='Add' onClick={onAdd}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          </div>
        </Grid>
      );
    }

    return (
      <Toolbar
        className={numSelected === 0 ? classes.root : classes.highlight}
      >
        <div className={classes.title} style={{ marginBottom: numSelected > 0 ? '0px' : null }}>
          {numSelected > 0 ? (
            <Typography color='inherit' style={{ marginBottom: '0px' }} variant='subtitle1'>
              {`${numSelected} ${GetTranslatedValue('GENERAL.CAPTION.SELECTED')}`}
            </Typography>
          ) : (
            <Typography variant='h6'>
              {props.title}
            </Typography>
          )}
        </div>
        <Grid container direction="row" style={{ flex: 1 }}>
          <div className={classes.actions}>
            <HeaderTools />
          </div>
        </Grid>
      </Toolbar>
    );
  };

  const handleRequestSort = (property) => {
    setLoading(true);
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map(({ id }) => id);
      setSelected(newSelecteds);
      setSelectedId(newSelecteds);

      if (setTableRowsInner) {
        setTableRowsInner(newSelecteds);
      }

      return;
    }

    if (setTableRowsInner) {
      setTableRowsInner([]);
    }

    setSelected([]);
  }

  const handleClick = (event, row) => {
    const { id } = row;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [], newSelectedId = [], newSelectedObject = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedId = newSelectedId.concat(selectedId, id);
      if (returnObjectOnSelect) {
        newSelectedObject = newSelectedObject.concat(selectedObject, row);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedId = newSelectedId.concat(selectedId.slice(1));
      if (returnObjectOnSelect) {
        newSelectedObject = newSelectedObject.concat(selectedObject.slice(1));
      }
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedId = newSelectedId.concat(selectedId.slice(0, -1));
      if (returnObjectOnSelect) {
        newSelectedObject = newSelectedObject.concat(selectedObject.slice(0, -1));
      }
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedId = newSelectedId.concat(
        selectedId.slice(0, selectedIndex),
        selectedId.slice(selectedIndex + 1)
      );
      if (returnObjectOnSelect) {
        newSelectedObject = newSelectedObject.concat(
          selectedObject.slice(0, selectedIndex),
          selectedObject.slice(selectedIndex + 1)
        );
      }
    }

    if (returnObjectOnSelect) {
      setSelectedObject(newSelectedObject);
    }

    if (setTableRowsInner) {
      setTableRowsInner(id)
    }

    setSelected(newSelected);
    setSelectedId(newSelectedId);
  }

  const handleChangePage = async (event, newPage) => {
    setLoading(true);
    if (isEmpty(controlValues.tableGrouping)) {
      if (setTableRowsInner) {
        setSelected([]);
        setSelectedId([]);
        setTableRowsInner([]);
      }

      setPage(newPage);
      setCurrentPageVisual(newPage + 1);
    } else {
      const nodeToBeChanged = controlValues.tableGrouping.find(({ parent }) => parent === 'root');
      const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: null };

      if (tableGroupingValues[nodeToBeChanged.depth + 1]) {
        nodeToBeChanged.query.page = newPage;

        const fetchResult = await fetchTableGroupingData(controlValues.collection, nodeToBeChanged, controlValues.condition);

        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          const newNode = { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query, type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
          return newNode;
        });

        const treeCopy2 = await Promise.all(
          fetchResult.data.map(({ _id, count }) => {
            const newNode = { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query, type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
            const condition = findConditionRecursive(controlValues.tableGrouping, newNode, []);
            return fetchTableGroupingData(controlValues.collection, { ...newNode, field: tableGroupingValues[nodeToBeChanged.depth + 1]?.id || newNode.field, query: { ...newNode.query, condition } }, controlValues.condition);
          })
        );
        const treeCopy3 = dataToNewNodes.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count }));

        nodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);

        setTableGroupingControl([nodeToBeChanged, ...treeCopy3]);
      } else {
        nodeToBeChanged.query.page = newPage;

        const fetchResult = await fetchTableGroupingData(controlValues.collection, nodeToBeChanged, controlValues.condition);

        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          const newNode = { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query, type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
          return newNode;
        });

        nodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);
        setTableGroupingControl([nodeToBeChanged, ...dataToNewNodes]);
      }

    }
  }

  const handleChangeRowsPerPage = async (event) => {
    setLoading(true);
    if (isEmpty(controlValues.tableGrouping)) {
      setRowsPerPage(+event.target.value);
    } else {
      const nodeToBeChanged = controlValues.tableGrouping.find(({ parent }) => parent === 'root');
      const query = { rowsPerPage: 5, orderBy: '_id', order: 1, page: 0, condition: null };

      nodeToBeChanged.query.rowsPerPage = event.target.value;
      const fetchResult = await fetchTableGroupingData(controlValues.collection, nodeToBeChanged, controlValues.condition);

      const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
        return { id: uuidv4().split('-').pop(), field: nodeToBeChanged.field, value: _id, count, active: false, query, type: 'node', parent: nodeToBeChanged.id, children: [], depth: nodeToBeChanged.depth + 1 };
      });
      nodeToBeChanged.children = dataToNewNodes.map(({ id }) => id);

      setTableGroupingControl([{ ...nodeToBeChanged }, ...dataToNewNodes]);
    }
  }

  const addTableGrouping = async (row) => {
    if (tableGroupingValues.find(({ id }) => id === row.id)) {
      return;
    }
    setLoading(true);
    if (isEmpty(tableGroupingValues)) {
      const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: null };
      const rootId = uuidv4().split('-').pop();
      const newNode = { id: rootId, field: row.correction || row.id, value: row.label, active: false, query, type: 'node', parent: 'root', children: [], depth: 0 };
      const fetchResult = await fetchTableGroupingData(controlValues.collection, newNode, controlValues.condition);
      newNode.count = fetchResult.total.count;
      const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
        return { id: uuidv4().split('-').pop(), field: row.correction || row.id, value: _id, count, active: false, query, type: 'node', parent: rootId, children: [], depth: newNode.depth + 1 };
      });
      newNode.children = dataToNewNodes.map(({ id }) => id);
      setTableGroupingControl([{ ...newNode }, ...dataToNewNodes]);
    } else {
      const rootNode = controlValues.tableGrouping.find(({ parent }) => parent === 'root');

      const treeCopy = controlValues.tableGrouping.filter(({ parent }) => parent === rootNode.id);
      const LocalTGValues = [...tableGroupingValues, { id: row.id, name: row.label, active: false, correction: row.correction || null }];
      const treeCopy2 = await Promise.all(
        treeCopy.map((node) => {
          const condition = findConditionRecursive(controlValues.tableGrouping, node, []);
          return fetchTableGroupingData(controlValues.collection, { ...node, field: LocalTGValues[rootNode.depth + 1]?.correction || LocalTGValues[rootNode.depth + 1].id, query: { ...node.query, condition } }, controlValues.condition);
        })
      );
      const treeCopy3 = treeCopy.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count }));
      setTableGroupingControl([rootNode, ...treeCopy3]);
    }
    dispatch(setTableGroupingValues([...tableGroupingValues, { id: row.id, name: row.label, active: false.valueOf, correction: row.correction || null }]));
    setLoading(false);
    // tableGroupingControl([...tableGroupingValues, {id: row.id, name: row.label, active: false}]);
  }

  const EnhancedTableHead = (props) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort
    } = props;
    const createSortHandler = property => {
      onRequestSort(property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell>
            {!disableGeneralCheckbox && (
              !tableGroupingValues.length && (
                <Checkbox
                  checked={numSelected === rowCount}
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  inputProps={{ 'aria-label': 'Select all desserts' }}
                  onChange={onSelectAllClick}
                />
              )
            )}
          </TableCell>
          {columnPicker.filter((column) => column.visible).map(row => (
            <TableCell
              align="left"
              key={row.id}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
              onMouseEnter={() => setDisplay({ bool: true, id: row.id })}
              onMouseLeave={() => setDisplay({ bool: false, id: undefined })}
            >
              <TableSortLabel
                hideSortIcon={true}
                style={{ width: '100%' }}
              >
                {row.label}
                {(!row.sortByDisabled && (orderBy === row.id || (display.bool && display.id === row.id))) ? (
                  order === 'asc' ? (
                    <IconButton aria-label='View' style={{ marginLeft: '2px' }} onClick={() => createSortHandler(row.id)}>
                      <ArrowUpwardIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton aria-label='View' onClick={() => createSortHandler(row.id)}>
                      <ArrowDownwardIcon fontSize="small" />
                    </IconButton>
                  )
                ) : (
                  <div className={classes.inputOrderByHidden} />
                )
                }
                {tableGrouping && (display.bool && display.id === row.id) && !row.disableTableGrouping ? (
                  <IconButton aria-label='View' onClick={() => addTableGrouping(row)}>
                    <QueueIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <div className={classes.inputOrderByHidden} />
                )
                }
              </TableSortLabel>
              {
                !disableSearchBy && !row.searchByDisabled && (
                  <input
                    autoFocus={row.id === searchBy}
                    className={classes.inputSearchBy}
                    onKeyPress={(event) => handleInputPressDebounced(event, row.id)}
                    placeholder={`${GetTranslatedValue('TABLE.CAPTION.SEARCH BY')}...`}
                    defaultValue={row.id === searchBy ? columnInputValue : ''}
                    onFocus={() => {
                      if (searchBy && searchBy !== row.id) {
                        setColumnInputValue('');
                      }
                      setSearchBy(row.id);
                    }}
                    onMouseLeave={(e) => {
                      if (searchBy === row.id && e.target.value !== columnInputValue) {
                        setColumnInputValue(e.target.value)
                      }
                    }}
                    { ...(row.id !== searchBy ? { value: '' } : {})  }
                  />
                )
              }
              {
                !disableSearchBy && row.searchByDisabled && (
                  <div
                    className={classes.inputSearchByDisabled}
                  />
                )
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  const [viewControl, setViewControl] = useState({
    table: true,
    tile: false,
    tree: false,
  });

  const showTableView = () => {
    if (typeof locationControl === 'function') {
      locationControl([]);
    }
    setViewControl({ table: true, tile: false, tree: false, });
  };
  const showTileView = () => {
    if (typeof locationControl === 'function') {
      locationControl([]);
    }
    setViewControl({ table: false, tile: true, tree: false, });
  };
  const showTreeView = () => setViewControl({ table: false, tile: false, tree: true, });


  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const onDeleteLocal = () => {
    props.onDelete(selectedId);
    setSelected([]);
    setSelectedId([]);
    setOpenYesNoModal(false);
  };

  const onEditLocal = () => {
    props.onEdit(selectedId);
    setSelected([]);
    setSelectedId([]);
  };

  const onViewLocal = () => {
    props.onView(selectedId);
    setSelected([]);
    setSelectedId([]);
  };

  const getRowsShowed = () => {
    const isTableGrouping = !isEmpty(controlValues.tableGrouping);
    const thisNode = controlValues.tableGrouping?.find(({ parent }) => parent === 'root');

    const currentPage = isTableGrouping ? thisNode.query.page + 1 : page + 1;
    const localRowsPerPage = isTableGrouping ? thisNode.query.rowsPerPage : rowsPerPage;
    const totalRows = isTableGrouping ? thisNode.count : controlValues.total;

    const showing = GetTranslatedValue('TABLE.PAGINATION.CAPTION.SHOWING');
    const ofMsg = GetTranslatedValue('TABLE.PAGINATION.CAPTION.OF');

    if (loading) {
      return <i> Loading... </i>
    } else {
      return `${showing}  ${!isEmpty(rows) ? (currentPage * localRowsPerPage) - (localRowsPerPage - 1) : 0} / ${(currentPage - 1) * (localRowsPerPage) + rows?.length}  ${ofMsg}  ${totalRows}`;
    }
  }

  const handleRemoveTableGrouping = async (element) => {
    const indexRemoved = tableGroupingValues.findIndex(st => st.id === element.id);
    const temporalValues = tableGroupingValues;
    temporalValues.splice(indexRemoved);
    dispatch(setTableGroupingValues(temporalValues));
    setLoading(true);
    if (isEmpty(temporalValues)) {
      setTableGroupingControl([]);
    } else {
      const rootNode = controlValues.tableGrouping.find(({ parent }) => parent === 'root');

      if (tableGroupingValues[rootNode.depth + 1]) {
        const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: null };
        const fetchResult = await fetchTableGroupingData(controlValues.collection, rootNode, controlValues.condition);
        rootNode.count = fetchResult.total.count;
        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: rootNode.field, value: _id, count, active: false, query, type: 'node', parent: rootNode.id, children: [], depth: rootNode.depth + 1 };
        });

        const treeCopy2 = await Promise.all(
          fetchResult.data.map(({ _id, count }) => {
            const newNode = { id: uuidv4().split('-').pop(), field: rootNode.field, value: _id, count, active: false, query, type: 'node', parent: rootNode.id, children: [], depth: rootNode.depth + 1 };
            const condition = findConditionRecursive(controlValues.tableGrouping, newNode, []);
            return fetchTableGroupingData(controlValues.collection, { ...newNode, field: tableGroupingValues[rootNode.depth + 1]?.id || newNode.field, query: { ...newNode.query, condition } }, controlValues.condition);
          })
        );
        const treeCopy3 = dataToNewNodes.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count }));

        rootNode.children = dataToNewNodes.map(({ id }) => id);

        setTableGroupingControl([rootNode, ...treeCopy3]);

      } else {
        const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: null };
        const fetchResult = await fetchTableGroupingData(controlValues.collection, rootNode, controlValues.condition);
        rootNode.count = fetchResult.total.count;
        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: rootNode.field, value: _id, count, active: false, query, type: 'node', parent: rootNode.id, children: [], depth: rootNode.depth + 1 };
        });

        rootNode.children = dataToNewNodes.map(({ id }) => id);
        setTableGroupingControl([rootNode, ...dataToNewNodes]);
      }
    }

    setLoading(false);
  };

  const updateChildNode = (value, node, key) => {
    let thisNode = (controlValues.childGrouping || []).find(({ id }) => id === node.id);
    if (key === 'orderBy' && value === thisNode.query.orderBy) {
      updateChildGrouping({ ...thisNode, query: { ...thisNode.query, [key]: value, 'order': (thisNode.query.order * -1) } });
    } else {
      updateChildGrouping({ ...thisNode, query: { ...thisNode.query, [key]: value } });
    }
    setLoadingIndentedTable(node.id);
  };

  const loadRows = () => {
    return rows?.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-\${index}`;
      const thisNode = (controlValues.childGrouping || []).find(({ id }) => id === row.id);

      return (
        <>
          <TableRow
            aria-checked={isItemSelected}
            hover
            key={`key-row-${row.id}`}
            onClick={event => !childView && handleClick(event, row)}
            role='checkbox'
            selected={isItemSelected}
            tabIndex={-1}
          >
            {
              <TableCell style={{ display: 'flex' }}>
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onChange={(event) => handleClick(event, row)}
                />
                {
                  childView && (
                    isParentOpened(row.id) && thisNode ? (
                      <IconButton aria-label='View' onClick={() => handleParents(row.id)}>
                        <IndeterminateCheckBoxRoundedIcon />
                      </IconButton>
                    ) : (
                      <IconButton aria-label='View' disabled={!thisNode} onClick={() => handleParents(row.id)}>
                        <AddBoxRoundedIcon />
                      </IconButton>
                    )
                  )
                }
              </TableCell>
            }
            {columnPicker.filter((column) => column.visible).map((header, ix) => (
              <TableCell
                align="left"
                component={header.renderCell
                  ? tab === 'dataExtraction'
                    ? () => header.renderCell(row)
                    : () => header.renderCell(row[header.id])
                  : 'th'
                }
                key={`cell-row${index}-${ix}`}
                padding={'default'}
                scope='row'
                style={{
                  ...!header.renderCell && {
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '300px',
                    whiteSpace: 'nowrap'
                  }
                }}
              >
                <OverflowTip>
                  {row[header.id]}
                </OverflowTip>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan='100%'>
              <Collapse in={isParentOpened(row.id)} timeout="auto" unmountOnExit>
                {
                  thisNode && (
                    loadingIndentedTable && loadingIndentedTable === thisNode.id ? (
                      <div style={{
                        width: '100%',
                        height: 49 * (5 + 2.5),
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center'
                      }}>
                        <CircularProgressCustom size={40} />
                      </div>
                    ) : (
                      <IndentedTable
                        style={{ width: '100%' }}
                        headRows={columnPicker.filter((column) => column.visible)}
                        thisNode={thisNode}
                        rows={thisNode.children}
                        handleChangeOrderBy={(value, node) => updateChildNode(value, node, 'orderBy')}
                        handleChangePage={(value, node) => updateChildNode(value, node, 'page')}
                        handleChangeRowsPerPage={(value, node) => updateChildNode(value, node, 'rowsPerPage')}
                        permissions={permissions}
                        onEdit={props.onEdit}
                        onDelete={props.onDelete}
                        onView={props.onView}
                      />
                    )
                  )
                }
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      );
    })
  };

  const renderColumnPicker = () => {
    const { left, top } = windowCoords;
    const regex = new RegExp(`.*${findColumn}.*`, 'gmi');

    return (
      <Popover
        anchorEl={anchorEl}
        aria-label='Column Picker Selector'
        anchorPosition={{ left, top }}
        anchorReference='anchorPosition'
        className={classes.popover}
        keepMounted
        onClose={() => setOpenColumnSelector(false)}
        open={openColumnSelector}
      >
        <div className={classes.grid}>
          <Typography color='inherit' variant='subtitle1'>
            {GetTranslatedValue('TABLE.CAPTION.COLUMNS', 'Find Columns')}
          </Typography>
          <TextField
            label={`${GetTranslatedValue('TABLE.CAPTION.COLUMNS', 'Find Columns')}...`}
            value={findColumn}
            onChange={(event) => setFindColumn(event.target.value)}
          />
          <Typography color='inherit' style={{ marginTop: '10px' }} variant='subtitle1'>
            {GetTranslatedValue('TABLE.CAPTION.COLUMNS', 'Columns')}
          </Typography>
          <List className={classes.list}>
            {
              columnPicker.filter((column) => column.label.match(regex)).map(({ label, id, visible }, index) => {
                return (
                  <ListItem key={`list-item-${id}`}>
                    <ListItemText className={classes.listItemText} key={id} primary={label} />
                    <ListItemSecondaryAction>
                      <Switch
                        checked={visible}
                        edge='end'
                        onChange={(event) => {
                          const array = JSON.parse(JSON.stringify(columnPicker));;
                          const clickedIndex = (array || []).findIndex(({ id: colId }) => colId === id);

                          array[clickedIndex].visible = event.target.checked;
                          setColumnPicker(array);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </div>
      </Popover>
    )
  };

  const Loader = () => (
    <div style={{
      width: '100%',
      height: 49 * (rowsPerPage + 2.5),
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    }}>
      <CircularProgressCustom size={40} />
    </div>
  );

  const renderTable = () => (
    <div className={classes.root} style={{ padding: '0px' }}>
      <ModalRemoveElement
        onOK={onDeleteLocal}
        onCancel={() => setOpenYesNoModal(false)}
        showModal={openYesNoModal}
      />
      {renderColumnPicker()}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          key="enhanced-tool-bar"
          noEdit={noEdit}
          onAdd={onAdd}
          onDelete={() => setOpenYesNoModal(true)}
          onEdit={onEditLocal}
          onSelect={onSelect}
          onView={onViewLocal}
          selected={selected}
          title={props.title}
          noAdd={noAdd}
          noDelete={noDelete}
        />
        {
          tableGrouping && tableGroupingValues.length > 0 && (
            <>
              <TableGroupingSelector setElements={async (elements) => {
                dispatch(setTableGroupingValues(elements));
                setLoading(true);
                const rootId = uuidv4().split('-').pop();
                const { id, name: label, correction } = elements[0];
                const query = { rowsPerPage, orderBy: '_id', order: 1, page: 0, condition: null };

                const rootNode = { id: rootId, field: correction || id, value: label, active: false, query, type: 'node', parent: 'root', children: [], depth: 0 };
                if (elements[rootNode.depth + 1]) {
                  const fetchResult = await fetchTableGroupingData(controlValues.collection, rootNode, controlValues.condition);
                  rootNode.count = fetchResult.total.count;
                  const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
                    return { id: uuidv4().split('-').pop(), field: rootNode.field, value: _id, count, active: false, query, type: 'node', parent: rootNode.id, children: [], depth: rootNode.depth + 1 };
                  });

                  rootNode.children = dataToNewNodes.map(({ id }) => id);
                  const treeData = [rootNode, ...dataToNewNodes];

                  const treeCopy2 = await Promise.all(
                    fetchResult.data.map(({ _id, count }) => {
                      const newNode = { id: uuidv4().split('-').pop(), field: rootNode.field, value: _id, count, active: false, query, type: 'node', parent: rootNode.id, children: [], depth: rootNode.depth + 1 };
                      const condition = findConditionRecursive(treeData, newNode, []);
                      return fetchTableGroupingData(controlValues.collection, { ...newNode, field: elements[rootNode.depth + 1]?.correction || elements[rootNode.depth + 1]?.id || newNode.field, query: { ...newNode.query, condition } }, controlValues.condition);
                    })
                  );
                  const treeCopy3 = dataToNewNodes.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count }));
                  setTableGroupingControl([rootNode, ...treeCopy3]);

                } else {
                  const fetchResult = await fetchTableGroupingData(controlValues.collection, rootNode, controlValues.condition);
                  rootNode.count = fetchResult.total.count;
                  const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
                    return { id: uuidv4().split('-').pop(), field: rootNode.field, value: _id, count, active: false, query, type: 'node', parent: rootNode.id, children: [], depth: rootNode.depth + 1 };
                  });

                  rootNode.children = dataToNewNodes.map(({ id }) => id);
                  setTableGroupingControl([rootNode, ...dataToNewNodes]);
                }
              }} handleRemoveElement={handleRemoveTableGrouping} />
            </>
          )
        }
        <div className={classes.tableWrapper}>
          {loading && !viewControl.tree && (
            <Loader />
          )}
          <Grid container>
            {
              viewControl.tree && (
                <Grid conainer style={{ paddingLeft: '16px' }} item sm={12} md={2} lg={2}>
                  <div style={{ overflow: 'auto', maxHeight: (49 * (rowsPerPage + 2.5)) }}>
                    <TreeView data={locationsTree} onClick={onLocationSelected} />
                  </div>
                </Grid>
              )
            }
            {
              justTreeView && (
                <Grid conainer style={{ paddingLeft: '16px' }} item sm={12} md={2} lg={3}>
                  <div style={{ overflow: 'auto', maxHeight: (49 * (rowsPerPage + 2.5)) }}>
                    <TreeView data={locationsTree} onClick={onLocationSelected} />
                  </div>
                </Grid>
              )
            }
            <Grid item sm={12} md={12} lg={viewControl.tree ? 10 : justTreeView ? 9 : 12} >
              <Table
                aria-labelledby='tableTitle'
                className={classes.table}
                stickyHeader
              >
                {
                  (viewControl.table || viewControl.tree) && (
                    <>
                      {loading && viewControl.tree && (
                        <Loader />
                      )}
                      {
                        !loading && (
                          <>
                            {
                              <EnhancedTableHead
                                noEdit={noEdit}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={rows?.length}
                              />
                            }
                            <TableBody>
                              {
                                !isEmpty(controlValues.tableGrouping) && !isEmpty(tableGroupingValues) ? (
                                  <TableGrouping
                                    treeData={controlValues.tableGrouping || []}
                                    thisNode={controlValues.tableGrouping.find(({ parent }) => parent === 'root')}
                                    fetchData={(object) => fetchTableGroupingData(controlValues.collection, object, controlValues.condition)}
                                    setTableGroupingControl={setTableGroupingControl}
                                    styleRows={(data) => tableGroupingCreateRows(data, controlValues.collection)}
                                    tableProps={{ ...props, headRows: columnPicker.filter((column) => column.visible) }}
                                    permissions={permissions}
                                    onEdit={props.onEdit}
                                    onDelete={props.onDelete}
                                    onView={props.onView}
                                    tableGroupingValues={tableGroupingValues}
                                    isFirst={true}
                                  />
                                ) : (
                                  <>
                                    {
                                      rows?.length <= 0 && (
                                        <TableRow
                                          hover
                                          key={`No info`}
                                          role='checkbox'
                                          tabIndex={-1}
                                        >
                                          <TableCell
                                            align={'center'}
                                            colSpan={100}
                                            component='th'
                                            key={`NoData`}
                                            padding={'default'}
                                            scope='row'
                                          >
                                            <Typography variant='h5'>
                                              {GetTranslatedValue('TABLE.CAPTION.NO.RECORDS.FOUND')}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    }
                                    {
                                      loadRows()
                                    }
                                  </>
                                )
                              }

                            </TableBody>
                          </>
                        )
                      }
                    </>
                  )
                }
                {
                  viewControl.tile && !loading && (
                    <TableBody>
                      <TileView
                        collection={controlValues.collection}
                        onDelete={props.onDelete}
                        onEdit={props.onEdit}
                        onReload={props.onReload}
                        showTileView={true}
                        tiles={rows}
                      />
                    </TableBody>
                  )
                }
              </Table>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableCell style={{ border: 'none', marginLeft: '10px' }}>
            {getRowsShowed()}
          </TableCell>
          <TablePagination
            style={{ marginRight: '10px' }}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            component='div'
            count={isEmpty(controlValues.tableGrouping) ? controlValues.total : controlValues.tableGrouping.find(({ parent }) => parent === 'root').count}
            labelDisplayedRows={({ from, to, count }) => {
              const isTableGrouping = !isEmpty(controlValues.tableGrouping);
              const thisNode = controlValues.tableGrouping?.find(({ parent }) => parent === 'root');

              if (count === 0) {
                return GetTranslatedValue('TABLE.PAGINATION.CAPTION.NO.PAGES');
              }

              const fontSize = '0.875rem';
              const currentPage = isTableGrouping ? thisNode.query.page + 1 : page + 1;
              const totalPages = isTableGrouping ? Math.trunc(count / thisNode.query.rowsPerPage) + (count % thisNode.query.rowsPerPage === 0 ? 0 : 1) : Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1);

              const current = isTableGrouping ? currentPage : currentPageVisual;
              const width = `${(!isEmpty(current.toString()) ? current.toString().length : 1) * 7}px`;

              return (
                <div style={{ alignItems: 'baseline', display: 'flex', flexDirection: 'row' }}>
                  <Typography style={{ fontSize }}>
                    {GetTranslatedValue('TABLE.PAGINATION.CAPTION.PAGE')}
                  </Typography>
                  <TextField
                    inputProps={{ style: { fontSize, padding: '0px' } }}
                    InputProps={{ disableUnderline: true }}
                    onBlur={() => setIsPaginationInputFocused(false)}
                    onChange={(event) => {
                      const input = event.target.value;
                      setCurrentPageVisual(input);
                      pageChangeDebounced.current(input, page, totalPages);
                    }}
                    onFocus={() => setIsPaginationInputFocused(true)}
                    style={{ marginLeft: '4px', width }}
                    value={!isTableGrouping ? currentPageVisual.toString() : currentPage.toString()}
                  />
                  <Typography style={{ fontSize }}>{`/${totalPages}`}</Typography>
                </div>
              );
            }}
            labelRowsPerPage={(
              <Typography style={{ fontSize: '0.875rem' }}>
                {GetTranslatedValue('TABLE.PAGINATION.CAPTION.ROWS.PER.PAGE')}
              </Typography>
            )}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={isEmpty(controlValues.tableGrouping) ? page : controlValues.tableGrouping.find(({ parent }) => parent === 'root').query.page}
            rowsPerPageOptions={!noRowsPerPage ? [5, 10, 25] : [5]}
            rowsPerPage={isEmpty(controlValues.tableGrouping) ? rowsPerPage : controlValues.tableGrouping.find(({ parent }) => parent === 'root').query.rowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );


  return renderTable();
};

const mapStateToProps = ({ auth: { user }, general: { tableLoading, tableGroupingValues } }) => ({
  user,
  tableLoading,
  tableGroupingValues
});

export default connect(mapStateToProps)(TableComponentTile);
