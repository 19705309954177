import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/ducks/general.duck';
import { getDB, deleteDB, updateDB, getDBComplex, deleteManyDB } from '../../../../crud/api';
import TableComponent from '../../Components/TableComponent';
import ModalGroups from './modals/ModalGroups';
import { convertToObjectIdArray, GetTranslatedValue } from '../../utils';
import { isEmpty } from 'lodash';

const Groups = ({ permissions }) => {
  const dispatch = useDispatch();
  const { showErrorAlert, showDeletedAlert } = actions;
  const [control, setControl] = useState({
    idGroup: null,
    rows: null,
    rowsSelected: [],
    showModal: false
  });

  const headRows = [
    { id: "name", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.NAME') },
    { id: "numberOfMembers", numeric: false, disablePadding: false, label: GetTranslatedValue('SETTINGS.GROUPS.CAPTION.NUMBER.OF.MEMBERS') },
    { id: "creator", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATOR') },
    { id: "creationDate", numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE') }
  ];

  const createGroupRow = (id, name, numberOfMembers, creator, creationDate) => {
    return { id, name, numberOfMembers, creator, creationDate };
  };

  const tableActions = () => {
    return {
      onAdd() {
        setControl({ ...control, idGroup: null, showModal: true, readOnly: false });
      },
      onEdit(id) {
        setControl({ ...control, idGroup: id, showModal: true, readOnly: false });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        getDB('user')
          .then((response) => response.json())
          .then((data) => {
            if (data?.response && !isEmpty(data.response)) {
              const users = data.response.filter(({ groups }) => (groups || []).some(({ id: userId, value }) => id.includes(userId || value))) || [];
              console.log({ users })
              users.forEach(({ _id: userId, groups }) => {
                const newUserGroups = groups.filter(({ id: groupId, value }) => !id.includes(groupId || value)) || [];
                updateDB('user/', { groups: newUserGroups }, userId)
                  .catch((error) => console.log(error));
              });
            }
          })
          .catch((error) => console.log(error));

        deleteManyDB({
          collection: 'settingsGroups',
          condition: [{ "_id": { "$in": convertToObjectIdArray(id) } }]
        })
          .then((response) => {
            loadInitData();
            dispatch(showDeletedAlert());
          })
          .catch((error) => console.log("Error", error));
      },
      onselect(id) {},
      onView(id) {
        setControl({ ...control, idGroup: id, showModal: true, readOnly: true })
      }
    };
  };

  const loadInitData = () => {
    getDB('settingsGroups')
      .then((response) => response.json())
      .then((data) => {
        const rows = data.response.map((row) => {
          const { _id, name, members, creationDate, creationUserFullName } = row;

          return createGroupRow(_id, name, (members || []).length, creationUserFullName, creationDate);
        });
        setControl(prev => ({ ...prev, rows, rowsSelected: [] }));
      })
      .catch((error) => dispatch(showErrorAlert()))
  };

  useEffect(() => {
    loadInitData();
  }, []);

  return (
    <div className="kt-section__content">
      <ModalGroups
        employeeProfileRows={[]}
        id={control.idGroup}
        groups={control.rows}
        readOnly={control.readOnly}
        reloadTable={() => loadInitData()}
        setShowModal={(onOff) => setControl({ ...control, showModal: onOff })}
        showModal={control.showModal}
      />
      <TableComponent
        headRows={headRows}
        onAdd={tableActions().onAdd}
        onDelete={tableActions().onDelete}
        onEdit={tableActions().onEdit}
        onSelect={tableActions().onSelect}
        onView={tableActions().onView}
        permissions={permissions}
        rows={control.rows}
        title={GetTranslatedValue('TABS.TITLES.GROUPS')}
      />
    </div>
  )
}

export default Groups;
