import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import ImageMarker from 'react-image-marker';
import SwipeableViews from 'react-swipeable-views';
import {
  Button,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RoomIcon from '@material-ui/icons/Room';
import { actions } from '../../../../store/ducks/general.duck';
import {
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../partials/content/Portlet';
import { getDBComplex, getOneDB, postDB, updateDB, updateManyDB } from '../../../../crud/api';
import { CustomFieldsPreview } from '../../constants';
import {
  getFileExtension,
  getImageURL,
  saveImage,
  verifyCustomFields,
  updateCustomFields,
  GetTranslatedValue
} from '../../utils';
import CustomRecordModal from '../../Components/CustomRecordModal';
import {
  executeOnFieldPolicy,
  executeOnLoadPolicy,
  executePolicies
} from '../../Components/Policies/utils';
import GoogleMaps from '../../Components/GoogleMaps';
import ImageUpload from '../../Components/ImageUpload';
import useMapCoords from '../hooks';
import './ModalLocationList.scss';

const TabContainer4 = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
}));

const ModalLocationList = ({
  dataFromParent,
  editOrNew,
  imageLayout,
  modalId,
  parent,
  parentExt,
  policies,
  profile,
  readOnly,
  realParent,
  reload,
  setParentSelected,
  setShowModal,
  showModal,
  onLocationCreated,
  userID
}) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [modalCoords, setModalCoords] = useState([]);
  const [modalMapZoom, setModalMapZoom] = useState(6);
  const [profileLabel, setProfileLabel] = useState('');
  const [tab, setTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const theme4 = useTheme();
  const {
    showCustomAlert,
    showErrorAlert,
    showSavedAlert,
    showUpdatedAlert,
    showFillFieldsAlert
  } = actions;
  const [value4, setValue4] = useState(0);
  const [values, setValues] = useState({
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg',
    customFieldsTab: {},
    name: '',
    profileName: '',
    profileId: '',
    profileLevel: '',
    parent: ''
  });
  const [customFieldsPathResponse, setCustomFieldsPathResponse] = useState();
  const defaultCoords = useMapCoords();

  const classes = useStyles();

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChange4 = (event, newValue) => {
    setValue4(newValue);
  };

  const handleChangeIndex4 = (index) => {
    setValue4(index);
  };

  const handleCloseModal = () => {
    setValues({
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg',
      imageURL: '',
      name: '',
      profileId: '',
      profileLevel: '',
      profileName: '',
      parent: '',
      customFieldsTab: {}
    });
    setImage(null);
    setMapCenter(null);
    setMarkers([]);
    setModalCoords([]);
    setModalMapZoom(6);
    setValue4(0);
    setShowModal(false);
  };

  const handleSave = () => {
    if (!values.name) {
      dispatch(
        showCustomAlert({
          open: true,
          message: 'Please fill the "Name" field',
          type: 'warning'
        })
      );
      return;
    }

    if (!verifyCustomFields(values.customFieldsTab)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    let newFileExt = values.fileExt;
    if (image) {
      newFileExt = getFileExtension(image);
    }

    const body = {
      ...values,
      fileExt: newFileExt,
      mapInfo:
        !isEmpty(modalCoords)
          ? { lat: modalCoords[0].lat, lng: modalCoords[0].lng, zoom: modalMapZoom }
          : null,
      imageInfo: markers.length ? { top: markers[0].top, left: markers[0].left } : null
    };
    
    if (editOrNew === 'new') {
      body.parent = parent;
      postDB('locationsReal', body)
        .then((response) => response.json())
        .then((data) => {
          dispatch(showSavedAlert());
          const { _id, profileLevel, parent, name } = data.response[0];
          onLocationCreated(_id);
          saveAndReload('locationsReal', _id);
          executePolicies('OnAdd', 'locations', 'list', policies, data.response[0]);
          executeOnFieldPolicy('OnAdd', 'locations', 'list', policies, data.response[0]);

          if (parent === 'root') {
            getOneDB('user/', userID)
              .then((response) => response.json())
              .then((userData) => {
                const { locationsTable } = userData.response;
                const newLocationItem = {
                  name: name,
                  level: profileLevel,
                  parent: _id,
                  realParent: parent
                };
                const newLocations = [...locationsTable, newLocationItem];
                updateDB('user/', { locationsTable: newLocations }, userID)
                  .catch((error) => {
                    console.log('Error:', error);
                    dispatch(showErrorAlert());
                  });
              });
          }
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      body.parent = realParent;
      updateDB('locationsReal/', body, parent)
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          const location = { _id: parent, ...body };
          let updateAssets = false;

          if (value?.name !== body.name) {
            updateAssets = true
            updateUsersLocationName(parent, body.name);
            updateRelatedSettingsUsers(parent, { location: { locationSelected: parent, locationName: body.name } });
            updateRelatedAssets(value?.name, body.name);
          }

          dispatch(showUpdatedAlert());
          saveAndReload('locationsReal', parent, !updateAssets);
          executePolicies('OnEdit', 'locations', 'list', policies, location);
          executeOnFieldPolicy('OnEdit', 'locations', 'list', policies, location, value);
        })
        .catch((error) => {
          console.log(error);
          dispatch(showErrorAlert())
        });
    }
    setModalMapZoom(modalMapZoom);
    handleCloseModal();
  };

  const updateRelatedSettingsUsers = (locationId, body) => {
    updateManyDB({
      body,
      collection: 'settingsAssetSpecialists',
      condition: [{ "location.locationSelected": locationId }]
    })
      .catch((error) => console.log(error));

    updateManyDB({
      body,
      collection: 'settingsWitnesses',
      condition: [{ "location.locationSelected": locationId }]
    })
      .catch((error) => console.log(error));
  };

  const updateRelatedAssets = (oldLocationName, newLocationName) => {
    const queryLike = ['locationPath'].map((key) => ({ key, value: oldLocationName }));

    getDBComplex({
      collection: 'assets',
      queryLike
    })
      .then((response) => response.json())
      .then((data) => {
        const assets = {
          flag: true,
          data: data.response,
          newLocationName
        };
        reload(assets);
      })
      .catch((error) => console.log(error));
  };

  const updateUsersLocationName = (locationId, newLocationName) => {
    getDBComplex({
      collection: 'user',
      condition: [{ "locationsTable": { "$elemMatch": { "parent": locationId } } }]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          data.response.forEach(({ _id: userId, locationsTable }) => {
            const copyLocationsTable = JSON.parse(JSON.stringify(locationsTable));
            const index = copyLocationsTable.findIndex(({ parent }) => parent === locationId);
            copyLocationsTable[index].name = newLocationName

            updateDB('user/', { locationsTable: copyLocationsTable }, userId)
              .catch((error) => console.log(error));
          });
        }
      })
      .catch((error) => {
        dispatch(showErrorAlert());
        console.log(error)
      })
  };

  const handlePinDelete = () => {
    setModalCoords([]);
    setModalMapZoom(null);
  };

  const handleUpdateCustomFields = (tab, id, colIndex, CFValues) => {
    const colValue = ['left', 'right'];
    const customFieldsTabTmp = { ...values.customFieldsTab };
    const field = customFieldsTabTmp[tab][colValue[colIndex]].find((cf) => cf.id === id) || {};
    field.values = CFValues;
  };

  const saveAndReload = (folderName, id, isEdit = false) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }

    if (isEdit) {
      reload();
    }
  };

  useEffect(() => {
    if (!profile || !profile.id || editOrNew === 'edit' || !showModal) {
      return;
    }

    getOneDB('locations/', profile.id)
      .then((response) => response.json())
      .then((data) => {
        const {
          _id: profileId,
          name: profileName,
          level: profileLevel,
          customFieldsTab
        } = data.response;
        setValues((prev) => ({
          ...prev,
          name: '',
          profileName,
          profileId,
          profileLevel,
          customFieldsTab
        }));
        setProfileLabel(profile.name);
        const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
          key,
          info: value.info,
          content: [value.left, value.right]
        }));
        setTabs(tabs);
        setValue4(0);
      })
      .catch((error) => dispatch(showErrorAlert()));
    getOneDB('locationsReal/', parent)
      .then((response) => response.json())
      .then((data) => {
        const fileExt = data.response?.fileExt;
        const imageURL = parent !== 'root' ? getImageURL(parent, 'locationsReal', fileExt) : '';
        setValues((prev) => ({ ...prev, imageURL, fileExt }));
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [showModal]);

  useEffect(() => {
    if (editOrNew !== 'edit' || !showModal) {
      return;
    }

    getOneDB('locationsReal/', parent)
      .then((response) => response.json())
      .then(async (data) => {
        const {
          _id,
          customFieldsTab,
          fileExt,
          imageInfo,
          mapInfo,
          name,
          profileId,
          profileLevel,
          profileName
        } = data.response;
        const imageURL =
          realParent !== 'root' ? getImageURL(realParent, 'locationsReal', parentExt) : '';
        const onLoadResponse = await executeOnLoadPolicy(
          profileId,
          'locations',
          'list',
          policies,
          data.response
        );
        setCustomFieldsPathResponse(onLoadResponse);
        setValues({
          ...values,
          name,
          profileId,
          profileLevel,
          profileName,
          customFieldsTab,
          imageURL,
          fileExt
        });
        setMarkers(
          imageInfo !== null ? (mapInfo ? [{ top: imageInfo.top, left: imageInfo.left }] : []) : []
        );
        setMapCenter({ lat: mapInfo?.lat || defaultCoords.coords.lat, lng: mapInfo?.lng || defaultCoords.coords.lng });
        setModalCoords(mapInfo && mapInfo.lat && mapInfo.lng ? [{ lat: mapInfo.lat, lng: mapInfo.lng }] : []);
        setModalMapZoom(mapInfo?.zoom || defaultCoords.zoom);
        setProfileLabel(profileName);

        getOneDB('locations/', profileId)
          .then((response) => response.json())
          .then((data) => {
            if (!data.response) {
              const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
                key,
                info: value.info,
                content: [value.left, value.right]
              }));
              tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
              setTabs(tabs);
              setValues((prev) => ({ ...prev, customFieldsTab }));
              return;
            }

            const { customFieldsTab: parentCustomFields } = data.response;
            if (parentCustomFields) {
              updateCustomFields(
                JSON.parse(JSON.stringify(customFieldsTab)),
                parentCustomFields,
                (customFields) => {
                  const tabs = Object.entries(customFields).map(([key, value]) => ({
                    key,
                    info: value.info,
                    content: [value.left, value.right]
                  }));
                  tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
                  setTabs(tabs);
                  setValues((prev) => ({ ...prev, customFieldsTab: customFields }));
                }
              );
            }
          });

        setValue4(0);
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [showModal]);

  const MapGoogle = (centerCoords) => (
    <GoogleMaps
      style={{ width: '100%', height: '500px', position: 'relative' }}
      center={centerCoords}
      coords={modalCoords}
      edit
      setCoords={(coords) => (readOnly ? null : setModalCoords(coords))}
      setZoom={setModalMapZoom}
      styleMap={{ height: '63%', marginRight: '20px', position: 'relative', width: '90%' }}
      zoom={modalMapZoom}
    />
  );

  return (
    <CustomRecordModal
      id={editOrNew}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Locations-Modal"
      readOnly={readOnly}
      showModal={showModal}
      tabs={
        <Tabs
          indicatorColor="primary"
          onChange={handleChange4}
          textColor="primary"
          value={value4}
          variant={tabs.length > 5 ? 'scrollable' : 'fullWidth'}
        >
          <Tab label={profileLabel} />
          {tabs.map((tab, tabIndex) => (
            <Tab label={tab.info.name} key={`tab-index-${tabIndex}`} />
          ))}
        </Tabs>
      }
      title="LOCATIONS.MODAL.CAPTION"
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value4}
        onChangeIndex={handleChangeIndex4}
        style={{ overflowY: 'hidden' }}
      >
        <TabContainer4 dir={theme4.direction}>
          <div className="profile-tab-wrapper">
            <div className="profile-tab-wrapper__content">
              <Typography variant="h5">
                {`${GetTranslatedValue('LOCATIONS.CAPTION.SELECTED.LEVEL')}: ${values.profileLevel
                  }`}
              </Typography>
              <TextField
                className={classes.textField}
                id="standard-name"
                label={GetTranslatedValue('RECORD.CAPTION.NAME')}
                margin="normal"
                onChange={handleChange('name')}
                value={values.name}
                inputProps={{
                  readOnly
                }}
              />
            </div>
          </div>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  className="builder-tabs"
                  component="div"
                  indicatorColor="primary"
                  onChange={(_, nextTab) => setTab(nextTab)}
                  textColor="primary"
                  value={tab}
                  variant="fullWidth"
                >
                  <Tab label={GetTranslatedValue('LOCATIONS.CAPTION.PIN.MAP')} />
                  <Tab label={GetTranslatedValue('LOCATIONS.CAPTION.PIN.LAYOUT')} />
                  <Tab label={GetTranslatedValue('LOCATIONS.CAPTION.LAYOUT')} />
                </Tabs>
              </PortletHeaderToolbar>
            }
          />
          {
            <Typography component="div" hidden={tab !== 0}>
              <PortletBody>
                <div className="container-map-delete-modal-location-list">
                  <div className="container-delete-modal-location-list">
                    <Button
                      className={classes.button}
                      color="secondary"
                      onClick={() => handlePinDelete()}
                      variant="contained"
                      disabled={readOnly}
                    >
                      {GetTranslatedValue('LOCATIONS.LIST.ACTION.DELETE.PIN')}
                      <DeleteIcon className={classes.rightIcon} />
                    </Button>
                  </div>
                  <div className="container-map-view-modal-location-list">
                    {mapCenter && editOrNew === 'edit' && MapGoogle(mapCenter)}
                    {editOrNew === 'new' && MapGoogle(defaultCoords.coords)}
                  </div>
                </div>
              </PortletBody>
            </Typography>
          }
          {
            <Typography component="div" hidden={tab !== 1}>
              <PortletBody style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ paddingTop: '20px', width: '100%' }}>
                  <ImageMarker
                    markerComponent={() => <RoomIcon style={{ color: 'red' }} />}
                    markers={markers}
                    onAddMarker={(marker) => (readOnly ? null : setMarkers([marker]))}
                    src={
                      values.imageURL === '' || !values.imageURL
                        ? values.categoryPicDefault
                        : values.imageURL
                    }
                  />
                </div>
              </PortletBody>
            </Typography>
          }
          {
            <Typography component="div" hidden={tab !== 2}>
              <PortletBody style={{ paddingTop: '20px' }}>
                <div className="profile-tab-wrapper">
                  <ImageUpload
                    disabled={readOnly}
                    id="location-image"
                    image={editOrNew === 'edit' ? imageLayout : ''}
                    handleUpdate={setImage}
                    setInitialImage={setInitialImage}
                  >
                    {GetTranslatedValue('LOCATIONS.CAPTION.SKETCH.LAYOUT')}
                  </ImageUpload>
                </div>
              </PortletBody>
            </Typography>
          }
        </TabContainer4>
        {tabs.map((tab, tabIndex) => (
          <TabContainer4 dir={theme4.direction} key={`locations-tabIndex-${tabIndex}`}>
            <div className="modal-location">
              {Array(tab.content[1].length === 0 ? 1 : 2)
                .fill(0)
                .map((col, colIndex) => (
                  <div
                    className="modal-location__list-field"
                    key={`locations-tab-${tabIndex}-col${colIndex}`}
                  >
                    {tab.content[colIndex].map((customField, customFieldIndex) => (
                      <CustomFieldsPreview
                        columnIndex={colIndex}
                        customFieldsPathResponse={customFieldsPathResponse}
                        data={tab.content[colIndex]}
                        from="form"
                        id={customField.id}
                        key={`locations-tab-${tabIndex}-col${colIndex}-field-${customFieldIndex}`}
                        onClick={() => alert(customField.content)}
                        onDelete={() => {}}
                        onSelect={() => {}}
                        onUpdateCustomField={handleUpdateCustomFields}
                        tab={tab}
                        type={customField.content}
                        values={customField.values}
                        readOnly={readOnly}
                        label={customField.label}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </TabContainer4>
        ))}
      </SwipeableViews>
    </CustomRecordModal>
  );
};

export default ModalLocationList;
