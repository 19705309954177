
  const defaultAppColors = {
    topNavigationBar: '#FFFFFF',
    generalBackGround: '#F2F3F8',
    topNavigationBarIcons: '#5D78FF',
    topNavigationBarText: '#B0B5C9',
    notificationsBadge: '#FD397A',
    notificationsModal: '#4258BB',
    messagesModal: '#4258BB',
    sideNavBarBackground: '#1E1E2D',
    sideNavBarTitle: '#7D8080',
    sideNavBarTabTitles: '#a2a3b7',
    sideNavBarTabTitlesHover: '#ffffff',
    sideNavBarTabIcons: '#494b74',
    sideNavBarTabIconsHover: '#5D78DC',
    sideNavBarTabSelected: '#1B1B28',
}

export const actionTypes = {
    AppColors: '[AppColors] Action',
  };
  
  const initialGeneralState = {
    appColors: defaultAppColors,
  };
  
  export const reducer = (state = initialGeneralState, action) => {
    switch (action.type) {
      case actionTypes.AppColors: {
        return { ...state, appColors: action.payload };
      }
      default:
        return state;
    }
  }
  
  export const actions = {
    setAppColors: (newColors) => ({ type: actionTypes.AppColors, payload: newColors }),
  };
