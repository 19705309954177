import React from 'react';
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { GetTranslatedValue } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  }
}));

const CompressedFileButton = ({ filename, tooltipText, onFileChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.button}>
      <input
        accept=".zip, .rar, .7zip"
        id="compressed-file-input"
        onChange={({ target: { files } }) => onFileChange(files[0])}
        style={{ display: 'none' }}
        type="file"
      />
      <label htmlFor="compressed-file-input">
        <Tooltip arrow placement="top" title={<Typography>{tooltipText}</Typography>}>
          <Button
            color="primary"
            component="span"
            startIcon={<FolderIcon />}
            style={{ height: '40px' }}
            variant="contained"
          >
            {filename || GetTranslatedValue('IMPORT.HEADERS.COMPRESSED.FILE', 'Compressed file')}
          </Button>
        </Tooltip>
      </label>
    </div>
  );
};

export default CompressedFileButton;
