import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgressCustom from './CircularProgressCustom';
import { GetTranslatedValue } from '../utils';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const contentStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  dialog: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%'
    }
  }
}));

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(DialogActions);

const CustomRecordModal = ({
  children,
  handleCloseModal,
  handleSave,
  id,
  readOnly,
  showModal,
  tabs,
  title,
  loading,
  extraTitle = '',
}) => {
  const classes = contentStyles();
  const actionsLabels = {
    view: GetTranslatedValue('GENERAL.CAPTION.VIEW'),
    add: GetTranslatedValue('GENERAL.CAPTION.ADD'),
    edit: GetTranslatedValue('GENERAL.CAPTION.EDIT')
  };

  const getActionLabel = () => {
    const readOrEdit = readOnly ? actionsLabels.view : actionsLabels.edit;

    switch (typeof id) {
      case 'boolean':
        if (id) {
          return actionsLabels.add;
        }

        return readOrEdit;
      case 'string':
        if (id === 'new') {
          return actionsLabels.add;
        }

        return readOrEdit;
      default:
        return id ? readOrEdit : actionsLabels.add;
    }
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      classes={{
        paper: classes.dialog
      }}
      onClose={handleCloseModal}
      open={showModal}
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={handleCloseModal}
      >
        {`${getActionLabel()} ${GetTranslatedValue(title)} ${extraTitle}`}
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <div className="kt-section__content" style={{ margin: '-16px' }}>
          <div className={classes.root}>
            <Paper className={classes.root}>{tabs}</Paper>
            {children}
          </div>
        </div>
      </CustomDialogContent>
      {!readOnly && (
        <CustomDialogActions>
          {loading &&
            <CircularProgressCustom size={20} />
          }
          <Button onClick={handleSave} color="primary">
            {GetTranslatedValue('GENERAL.CAPTION.SAVE.CHANGES')}
          </Button>
        </CustomDialogActions>
      )}
    </Dialog>
  );
};

CustomRecordModal.defaultProps = {
  actionTitle: '',
  loading: false,
  id: undefined,
  readOnly: true
};

CustomRecordModal.propTypes = {
  actionTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.bool
  ]),
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  tabs: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default CustomRecordModal;
