import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import * as XLSX from 'xlsx';
import { Grid, makeStyles, MenuItem, Select, Tab, Tabs, Typography } from '@material-ui/core';
import { importModules, importModulesCatalogues, importLayouts } from './constants';
import {
  childCustomFieldsAssignation,
  csvJSON,
  employeeAssignationProcess,
  getBaseFieldHeader,
  getEmployeeLayout,
  getProfileByImportId,
  getRecordCustomFieldsObject,
  handleRar,
  handleZip,
  importIdHandler,
  isRepeatedName,
  isValidEmail,
  isValidEmployeeId,
  isValidMongoId,
  postImportRecord,
  updateImportRecord,
  userDefaultPermissions,
  validateRequiredValues
} from './helpers';
import {
  layoutHeaders,
  requiredHeaders,
  userProfilesPermissions,
  verifyRequiredFields
} from './layouts';
import DownloadButton from './components/DownloadButton';
import ImportButton from './components/ImportButton';
import UploadButton from './components/UploadButton';
import Headers from './components/Headers';
import CustomFields from './components/CustomFields';
import Preview from './components/Preview';
import useWindowDimensions from '../../hooks';
import {
  convertToObjectIdArray,
  GetTranslatedValue,
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage
} from '../../utils';
import ModalSkippedRecords from './modals/ModalSkippedRecords'
import { findAndModifyDB, getDB, getDBComplex, getOneDB, postDB, updateDB } from '../../../../crud/api';
import { actions } from '../../../../store/ducks/general.duck';
import { getBoolValue } from '../../../../../_metronic';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  buttons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
    width: '100%'
  },
  wrapper: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap'
  },
  importSection: {
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '20px'
    }
  },
  layoutSelect: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '25px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  }
}));

const moduleTabs = [
  { key: 'headers', label: 'IMPORT.HEADERS.TAB' },
  { key: 'customFields', label: 'IMPORT.CUSTOM.FIELDS.TAB' },
  { key: 'preview', label: 'IMPORT.PREVIEW.TAB' }
];
const moduleReferences = {
  assets: 'references',
  references: 'categories',
  user: 'userProfiles',
  employees: 'employeeProfiles',
  locationsReal: 'locations'
};
const defaultTabValues = {
  loading: false,
  baseFieldsValues: [],
  customFields: [],
  headers: [],
  file: '',
  rows: [],
  selectedRows: [],
  subTab: 0
};

const additionalValues = {
  customFieldsTab: {
    file: null,
    files: []
  },
  headerTab: {
    file: null,
    files: []
  }
};

const Import = ({ permissions, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { setGeneralLoading, showErrorAlert } = actions;

  const getIntlValue = (id, defaultMessage) => {
    return intl.formatMessage({ id: id || 'INCORRECT_KEY', defaultMessage: defaultMessage || 'Text Not Rendered' });
  };

  const getTabOnInit = () => {
    for (let i = 0; i < importModules.length; i++) {
      const { id, collections } = importModules[i];

      for (let j = 0; j < collections.length; j++) {
        if (user?.profilePermissions?.settings?.import?.includes(collections[j])) {
          return { id, tabIndex: i, tabCatalogueIndex: j };
        }
      }
    }
  };

  const constructValuesObject = (index) => {
    const obj = {};

    importModules[index].collections.forEach((catalogue) => {
      obj[catalogue] = {
        ...defaultTabValues,
        ...additionalValues,
        layout: {
          options: importLayouts[catalogue],
          selected: importLayouts[catalogue][0].value
        }
      }
    });

    return obj;
  };

  const { id, tabIndex, tabCatalogueIndex } = getTabOnInit();

  const [selectedModule, setSelectedModule] = useState({
    module: id,
    catalogue: { index: tabCatalogueIndex, value: importModules[tabIndex].collections[tabCatalogueIndex] }
  });
  const [tab, setTab] = useState(tabIndex);
  const [usedHeaders, setUsedHeaders] = useState([]);
  const [values, setValues] = useState(constructValuesObject(tabIndex));
  const [showSkippedModal, setShowSkippedModal] = useState(false);
  const [skippedRows, setSkipepdRows] = useState([]);
  const dimensions = useWindowDimensions();

  const getImportMainValues = async ({ baseFieldsValues, collection, layout, row }) => {
    const { validMongoId, record } = await isValidMongoId(
      row[getBaseFieldHeader(baseFieldsValues, '_id')],
      collection
    );
    const rowValues = {};
    const validRecord = validateRequiredValues({
      baseFieldsValues,
      isExistingRecord: validMongoId,
      layout,
      row,
      showWarningMessage
    });

    return [{ validMongoId, record }, rowValues, validRecord];
  };

  const finishRecordProcess = async ({
    collection,
    customFieldsTab,
    headerTab,
    rowValues,
    validMongoId,
    updateProfile,
    profileCollection,
    profileValues,
    isUser = false,
    newRootLocations = [],
    record = {}
  }) => {
    if (validMongoId) {
      const { photo, ...otherValues } = rowValues;
      const image = headerTab.files.find(({ name }) => name === photo && photo);
      const values = { ...otherValues, customFieldsTab, image };

      if (!updateProfile) {
        return await updateImportRecord(values, collection, record);
      } else {
        return Promise.all([
          await updateImportRecord(values, collection, record),
          await updateImportRecord(profileValues, profileCollection, { reassignation: {} })
        ]);
      }
    } else {
      const { _id, photo, ...otherValues } = rowValues;
      const image = headerTab.files.find(({ name }) => name === photo && photo);
      const values = { ...otherValues, customFieldsTab, image };
      if (!updateProfile) {
        return await postImportRecord(values, collection, isUser, newRootLocations);
      } else {
        return Promise.all([
          await postImportRecord(values, collection, isUser, newRootLocations),
          await updateImportRecord(profileValues, profileCollection, { reassignation: {} })
        ]);
      }
    }
  };

  const showSkippedRecords = (rows, data) => {
    const skippedData = rows.filter((row, index) => !data[index]);

    setSkipepdRows(skippedData);
  };

  const getAllLocations = async () => {
    return await getDB('locationsReal/')
      .then(response => response.json())
      .then(data => {
        const filtered = data.response.map(({ _id: id, name, parent }) => ({ id, name, parent }))
        return filtered;
      })
      .catch(error => console.log(error));
  };

  const getLocationPath = async (allLocations, locationId) => {
    const firstLocation = allLocations.find(({ id }) => id === locationId);
    const response = recursiveLocationPath(firstLocation, allLocations);

    return response;
  };

  const recursiveLocationPath = (currentLocation, allLocations) => {
    const { name, parent } = currentLocation;

    if (parent === 'root') {
      return name;
    }

    else {
      const newLocation = allLocations.find(({ id }) => id === parent);
      
      return recursiveLocationPath(newLocation, allLocations).concat('/', name);
    }
  };

  // TODO
  const handleImport = async () => {
    const catalogue = values[selectedModule.catalogue.value];
    const layoutSelected = catalogue.layout.selected;
    const collection = selectedModule.catalogue.value;
    const allLocations = await getAllLocations();
    let message = '';

    if (
      !verifyRequiredFields(
        catalogue.baseFieldsValues.map((baseField) => (baseField ? baseField.targetField : '')),
        layoutSelected
      )
    ) {
      message = getIntlValue('IMPORT.WARNING.FILL.FIELDS', 'Please fill all required fields to begin the import process');
      showWarningMessage(message);

      return;
    }

    const {
      baseFieldsValues,
      customFields,
      customFieldsTab: { files: customFieldsFiles },
      headerTab,
      selectedRows: rows
    } = catalogue;
    const usedProfiles = [];
    const employees = [];
    const locations = [];

    if (isEmpty(rows)) {
      message = getIntlValue('IMPORT.WARNING.NO.SELECTED.ROWS', 'You have to select at least one row in the table found in the preview tab');
      showWarningMessage(message);

      return;
    }

    dispatch(setGeneralLoading({ active: true }));

    switch (layoutSelected) {
      case 'assets':
        const fields = ['reference', 'employee_id', 'location_id'];
        const reassignation = { flag: false, prevEmployeeId: '', newEmployeeId: '' };

        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            const assignReference = (reference) => {
              rowValues['name'] = reference.name;
              rowValues['brand'] = reference.brand;
              rowValues['model'] = reference.model;
              rowValues['referenceId'] = reference._id;
              rowValues['category'] = reference.selectedProfile;
            };

            const assignLocation = async (locationId, mapCoords) => {
              rowValues['location'] = locationId;
              rowValues['locationPath'] = await getLocationPath(allLocations, locationId);
              rowValues['mapCoords'] = mapCoords || null;
            };

            const assignStatus = (num) => {
              switch (num) {
                case 0 || '0':
                  return 'active';
                case 1 || '1':
                  return 'inProcess';
                case 2 || '2':
                  return 'maintenance';
                case 3 || '3':
                  return 'decommissioned';
                default:
                  return 'active';
              }
            };

            const handleAssetEPC = (EPC, record) => {
              if (isEmpty(EPC)) {
                return 'EMPTY';
              } else {
                return getDBComplex({
                  collection: 'assets',
                  condition: [{ EPC: EPC }]
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (isEmpty(data?.response)) {
                      return 'NEW';
                    } else {
                      if (record && record.EPC === EPC) {
                        return 'SAME';
                      } else {
                        return 'EXISTING';
                      }
                    }
                  })
                  .catch((error) => dispatch(showErrorAlert()));
              }
            };

            const getLocation = (locationId) => {
              const found = locations.find(({ _id }) => locationId === _id);
              if (!locationId) {
                return null;
              } else if (found) {
                return found;
              }

              const regex = /^[0-9a-fA-F]{24}$/;

              if (regex.test(locationId)) {
                return getOneDB('locationsReal/', locationId)
                  .then((response) => response.json())
                  .then((data) => {
                    if (data?.response) {
                      locations.push(data.response);
                    }

                    return data?.response;
                  })
                  .catch((error) => showErrorMessage());
              } else {
                return getDBComplex({
                  collection: 'locationsReal',
                  condition: [{ "importId": locationId }]
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data?.response && !isEmpty(data.response)) {
                      locations.push(data.response[0]);
                      return data.response[0];
                    } else {
                      return null;
                    }
                  })
                  .catch((error) => console.log(error));
              }
            };

            const createAssetEPC = async () => {
              return findAndModifyDB({
                collection: 'helpers',
                query: [{ name: 'assetsVirtualEPC' }],
                update: { $inc: { value: 1 } }
              })
                .then((response) => response.json())
                .then(async (data) => {
                  if (!data.response || isEmpty(data.response)) {
                    const body = {
                      name: 'assetsVirtualEPC',
                      value: 1
                    };
                    await postDB('helpers', body);
                  }

                  let biggestEPC = data?.response?.value || 0;
                  const whiteFolio = [0, 0, 0, 0, 0, 0, 0, 0];
                  const prefix = whiteFolio.slice(0, 8 - String(biggestEPC + 1).length);
                  const newEPC = prefix.join('').concat(String(biggestEPC + 1));

                  return `VIRTUAL_EPC_${newEPC}`;
                })
                .catch((error) => showErrorMessage());
            };

            const getEmployee = async (employee_id) => {
              const found = employees.find(({ employee_id: id }) => employee_id === id);
              if (!employee_id) {
                return null;
              } else if (found) {
                return found;
              }

              return getDBComplex({
                collection: 'employees',
                condition: [{ employee_id: employee_id }]
              })
                .then((response) => response.json())
                .then((data) => {
                  if (!isEmpty(data?.response)) {
                    employees.push(data.response[0]);
                  }

                  return !isEmpty(data?.response) ? data?.response[0] : null;
                })
                .catch((error) => showErrorMessage());
            };

            if (validRecord) {
              // IMPORT ID VALIDATION
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = await importIdHandler(row[importIdHeader], collection);

              if (!existingImportId && filledImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.REPEATED.IMPORT.ID' },
                  { importId: row[importIdHeader] }
                );
                showWarningMessage(message);

                return;
              }

              // REFERENCE ASSIGNMENT
              const referenceHeader = getBaseFieldHeader(
                baseFieldsValues,
                'reference'
              );
              const referenceValue = row[referenceHeader];
              const reference = await getProfileByImportId(
                referenceValue,
                'references'
              );

              const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');

              if (reference) {
                usedProfiles.push(reference._id);
                assignReference(reference);
              } else if (!reference && validMongoId) {
                assignReference(record);
                usedProfiles.push(record._id);
              } else {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.ASSETS.NO.REFERENCE' },
                  { name: row[nameHeader] }
                );
                showWarningMessage(message);
                return;
              }

              // LOCATION ASSIGNMENT
              const locationIdHeader = getBaseFieldHeader(baseFieldsValues, 'location_id');
              const location = await getLocation(row[locationIdHeader]);

              if (!validMongoId) {
                if (location) {
                  assignLocation(location._id);
                } else {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.ASSETS.INVALID.LOCATION' },
                    { name: row[nameHeader] }
                  );
                  showWarningMessage(message);
                  return;
                }
              } else {
                if (location) {
                  assignLocation(location._id, location.mapInfo);
                } else {
                  assignLocation(record['location'], record['mapInfo']);
                }
              }

              // EPC ASSIGNMENT
              const EPCHeader = getBaseFieldHeader(baseFieldsValues, 'EPC');
              const EPCMessage = await handleAssetEPC(row[EPCHeader], record);

              if (!validMongoId) {
                if (EPCMessage === 'EMPTY') {
                  rowValues['EPC'] = await createAssetEPC();
                } else if (EPCMessage === 'EXISTING') {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.EPC.ALREADY.ASSIGNED' },
                    { EPC: row[EPCHeader] }
                  );
                  showWarningMessage(message);

                  return;
                } else if (EPCMessage === 'NEW') {
                  rowValues['EPC'] = row[EPCHeader];
                }
              } else {
                if (['EMPTY', 'SAME'].includes(EPCMessage)) {
                  const value = row[EPCHeader];
                  rowValues['EPC'] = value;
                } else if (EPCMessage === 'EXISTING') {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.EPC.ALREADY.ASSIGNED' },
                    { EPC: row[EPCHeader] }
                  );
                  showWarningMessage(message);

                  return;
                }
              }

              // EMPLOYEE ASSIGNMENT
              const employeeIdHeader = getBaseFieldHeader(baseFieldsValues, 'employee_id');
              const employee = await getEmployee(row[employeeIdHeader]);

              if (!validMongoId) {
                rowValues['assigned'] = employee?._id || '';
                reassignation.newEmployeeId = rowValues['assigned'];
              } else {
                if (employee && record['assigned'] && employee._id !== record['assigned']) {
                  reassignation.flag = true;
                  reassignation.prevEmployeeId = record['assigned'];
                  reassignation.newEmployeeId = employee._id
                } else {
                  reassignation.flag = false;
                  reassignation.prevEmployeeId = '';
                  reassignation.newEmployeeId = '';
                }

                rowValues['assigned'] = employee?._id || record['assigned'] || '';
              }

              // VALUES ASSIGNATION
              baseFieldsValues.forEach((baseField) => {
                if (
                  !rowValues.hasOwnProperty(baseField?.targetField) &&
                  validMongoId &&
                  !fields.includes(baseField?.targetField)
                ) {
                  if (baseField.targetField === 'status') {
                    rowValues[baseField?.targetField] = assignStatus(
                      row[baseField?.value] || record[baseField?.targetField]
                    );
                  } else {
                    rowValues[baseField?.targetField] =
                      row[baseField?.value] || record[baseField?.targetField]?.toString() || '';
                  }
                } else if (
                  !rowValues.hasOwnProperty(baseField?.targetField) &&
                  !fields.includes(baseField?.targetField)
                ) {
                  if (baseField.targetField === 'status') {
                    rowValues[baseField?.targetField] = assignStatus(row[baseField?.value]);
                  } else {
                    rowValues[baseField?.targetField] = row[baseField?.value]?.toString();
                  }
                }
              });

              //CUSTOM FIELDS ASSIGNATION
              const [customFieldsTab, profileCustomFieldsTab] = await childCustomFieldsAssignation({
                customFields,
                files: customFieldsFiles,
                profileCollection: 'references',
                profileCustomFields: reference?.customFieldsTab || {},
                profileId: rowValues['referenceId'],
                row,
                usedProfiles
              });

              return await finishRecordProcess({
                collection,
                customFieldsTab,
                headerTab,
                profileCollection: 'references',
                profileValues: { customFieldsTab: profileCustomFieldsTab },
                rowValues,
                updateProfile: false,
                validMongoId,
                record
              });
            }
          })
        )
          .then(async (data) => {
            showSkippedRecords(rows, data);

            for (let index = 0; index < data.length; index++) {
              const asset = data[index];

              if (asset && asset.assigned) {
                await employeeAssignationProcess(asset.assigned, asset);
              }
            }
          });
        break;
      case 'references':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });
            const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');
            const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
            const filledImportId = !isEmpty(row[importIdHeader].toString());
            const existingImportId = filledImportId
              ? await importIdHandler(row[importIdHeader].toString(), collection)
              : false;

            if (filledImportId && !existingImportId) {
              rowValues['importId'] = row[importIdHeader].toString();
            } else {
              rowValues['importId'] = '';
              message = intl.formatMessage(
                { id: 'IMPORT.WARNING.INVALID.IMPORT.ID' },
                { name: row[nameHeader] }
              );
              showWarningMessage(message);
              return;
            }

            const categoryHeader = getBaseFieldHeader(baseFieldsValues, 'category');

            if (validRecord) {
              const category = await getProfileByImportId(
                row[categoryHeader],
                'categories'
              );
              rowValues['category'] = category
                ? { label: category.name, value: category._id }
                : null;
              rowValues['selectedProfile'] = rowValues['category'];
              rowValues['depreciation'] = category ? category.depreciation : '0';

              if (category) {
                usedProfiles.push(category.value);
              } else if (!category && validMongoId) {
                rowValues['category'] = record['category'] || record['selectedProfile'];
                rowValues['selectedProfile'] = rowValues['category'];
                rowValues['depreciation'] = record['depreciation'];
                usedProfiles.push(rowValues['category']?.value);
              } else {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.REFERENCES.NO.CATEGORY' },
                  { name: row[nameHeader] }
                );
                showWarningMessage(message);
                return;
              }

              const skippedColumns = ['category'];
              baseFieldsValues.forEach((baseField) => {
                if (skippedColumns.includes(baseField?.targetField)) {
                  return;
                } else if (!rowValues.hasOwnProperty(baseField?.targetField) && validMongoId && !row[baseField?.value]) {
                  if (baseField?.targetField === 'price') {
                    rowValues[baseField?.targetField] = row[baseField?.value] === '' ? record[baseField?.targetField]?.toString() : '0';
                  } else {
                    rowValues[baseField?.targetField] = record[baseField?.targetField]?.toString() || '';
                  }

                } else if (!rowValues.hasOwnProperty(baseField?.targetField)) {
                  if (baseField?.targetField === 'price') {
                    rowValues[baseField?.targetField] = row[baseField?.value] !== '' ? row[baseField?.value]?.toString() : '0';
                  } else {
                    rowValues[baseField?.targetField] = row[baseField?.value]?.toString() || '';
                  }
                }
              });

              const [customFieldsTab, profileCustomFieldsTab] = await childCustomFieldsAssignation({
                customFields,
                files: customFieldsFiles,
                profileCollection: 'categories',
                profileCustomFields: category?.customFieldsTab || {},
                profileId: rowValues['category']?.value,
                row,
                usedProfiles
              });

              return finishRecordProcess({
                collection,
                customFieldsTab,
                headerTab,
                profileCollection: 'categories',
                profileValues: { customFieldsTab: profileCustomFieldsTab },
                rowValues,
                updateProfile: false,
                validMongoId
              });
            }
          })
        ).then((data) => {
          showSkippedRecords(rows, data);
        });
        break;
      case 'categories':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');

            if (validRecord) {
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = filledImportId
                ? await importIdHandler(row[importIdHeader].toString(), collection)
                : false;

              if (filledImportId && !existingImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                rowValues['importId'] = '';
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.IMPORT.ID' },
                  { name: row[nameHeader] }
                );
                showWarningMessage(message);

                return;
              }
            }

            baseFieldsValues.forEach((baseField) => {
              if (!rowValues.hasOwnProperty(baseField?.targetField) && validMongoId && !row[baseField?.value]) {
                if (baseField?.targetField === 'depreciation') {
                  rowValues[baseField?.targetField] = row[baseField?.value] === '' ? record[baseField?.targetField]?.toString() : '0';
                } else {
                  rowValues[baseField?.targetField] = record[baseField?.targetField]?.toString() || '';
                }
              } else if (!rowValues.hasOwnProperty(baseField?.targetField)) {
                if (baseField?.targetField === 'depreciation') {
                  rowValues[baseField?.targetField] = row[baseField?.value] !== '' ? row[baseField?.value]?.toString() : '0';
                } else {
                  rowValues[baseField?.targetField] = row[baseField?.value]?.toString() || '';
                }
              }
            });

            const customFieldsTab =
              (await getRecordCustomFieldsObject({
                collection: selectedModule.catalogue.value,
                customFields,
                id: rowValues['_id'],
                files: customFieldsFiles,
                putValues: true,
                row,
                validMongoId
              })) || {};

            return finishRecordProcess({
              collection,
              customFieldsTab,
              headerTab,
              rowValues,
              validMongoId
            });
          })
        )
          .then((data) => {
            showSkippedRecords(rows, data);
          });
        break;
      case 'user':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            const assignProfile = (profile) => {
              rowValues['selectedUserProfile'] = {
                value: profile?._id,
                label: profile?.name
              } || null;
              rowValues['idUserProfile'] = profile?._id || '';
              rowValues['profilePermissions'] = profile?.profilePermissions || [];
            };

            const getLocationsTable = (locationIds, validMongoId, record) => {
              if (!locationIds || isEmpty(locationIds)) {
                return null;
              } else {
                return getDBComplex({
                  collection: 'locationsReal',
                  condition: { "_id": { "$in": convertToObjectIdArray(locationIds) } },
                  disableAndQuery: true
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data?.response && validMongoId) {
                      const locations = [...data.response];
                      const alreadyAssignedLocations = record.locationsTable.map(({ parent }) => parent);
                      const filteredLocations = locations.filter(({ _id: locationId }) => !alreadyAssignedLocations.includes(locationId));

                      return [...record.locationsTable, ...constructLocationsTable(filteredLocations)];
                    } else if (data?.response) {
                      return constructLocationsTable(data.response);
                    } else {
                      return [];
                    }
                  })
                  .catch((error) => showErrorMessage());
              }
            };

            const constructLocationsTable = (locations) => {
              if (!locations || isEmpty(locations)) {
                return [];
              } else {
                return locations.map((location) => ({
                  name: location.name,
                  level: location.profileLevel,
                  parent: location._id,
                  realParent: location.parent
                })) || [];
              }
            };

            const getBoss = (bossEmail) => {
              const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

              if (!bossEmail || !regex.test(bossEmail)) {
                return null;
              } else {
                return getDBComplex({
                  collection: 'user',
                  condition: [{ "email": bossEmail }]
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data?.response || !isEmpty(data.response)) {
                      const boss = data.response[0];

                      return {
                        value: boss._id,
                        label: boss.email,
                        name: boss.name,
                        lastName: boss.lastName
                      };
                    } else {
                      return null
                    }
                  });
              }
            };

            if (validRecord) {
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = await importIdHandler(row[importIdHeader], collection);

              if (!existingImportId && filledImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.REPEATED.IMPORT.ID' },
                  { importId: row[importIdHeader] }
                );
                showWarningMessage(message);
                return;
              }

              // EMAIL VALIDATION AND ASSIGNATION

              const emailHeader = getBaseFieldHeader(baseFieldsValues, 'email');
              const idHeader = getBaseFieldHeader(baseFieldsValues, '_id');
              const validEmail = await isValidEmail(
                row[emailHeader],
                collection,
                row[idHeader],
                validMongoId
              );

              if (!validEmail) {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.EMAIL' },
                  { email: row[emailHeader] }
                );
                showWarningMessage(message);
                return;
              } else {
                // PROFILE ASSIGNATION

                rowValues['email'] = row[emailHeader] || record.email;
                const profileHeader = getBaseFieldHeader(baseFieldsValues, 'profile');

                const profile = await getProfileByImportId(
                  row[profileHeader],
                  'userProfiles'
                );


                assignProfile(profile);

                if (profile) {
                  usedProfiles.push(profile.value);
                } else if (!profile && validMongoId) {
                  assignProfile(record['selectedUserProfile']);
                  rowValues['selectedUserProfile'] = record['selectedUserProfile'];
                  rowValues["idUserProfile"] = record['idUserProfile'];
                  rowValues["profilePermissions"] = record['profilePermissions'];
                  usedProfiles.push(rowValues['selectedUserProfile']?.value);
                } else {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.USERS.NO.PROFILE' },
                    { email: rowValues['email'] }
                  );
                  showWarningMessage(message);
                  return;
                }

                // LOCATIONS ASSIGNMENT

                const locationsHeader = getBaseFieldHeader(baseFieldsValues, 'assignedLocations');

                if (!row[locationsHeader]) {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.USERS.NO.LOCATION.ASSIGNED' },
                    { email: rowValues['email'] }
                  );
                  showWarningMessage(message);
                  return;
                }

                const locations = row[locationsHeader].replace(/\s+/g, '').split(",") || [];
                rowValues['locationsTable'] = await getLocationsTable(locations, validMongoId, record);

                // BOSS ASSIGNMENT

                const bossHeader = getBaseFieldHeader(baseFieldsValues, 'boss_email');
                const boss = await getBoss(row[bossHeader]);

                if (!boss && validMongoId) {
                  rowValues['selectedBoss'] = record['selectedBoss'];
                } else {
                  rowValues['selectedBoss'] = boss;
                }

                // ASSIGN VALUES

                const skippedColumns = ['profile', 'boss_email', 'assignedLocations']
                baseFieldsValues.forEach((baseField) => {
                  if (baseField) {
                    if (
                      !rowValues.hasOwnProperty(baseField.targetField) &&
                      !skippedColumns.includes(baseField.targetField)
                    ) {
                      rowValues[baseField.targetField] = row[baseField.value]?.toString();
                    }
                  }
                });

                // CUSTOM FIELDS ASSIGNATION

                const [
                  customFieldsTab,
                  profileCustomFieldsTab
                ] = await childCustomFieldsAssignation({
                  customFields,
                  files: customFieldsFiles,
                  profileCollection: 'userProfiles',
                  profileCustomFields: profile?.customFieldsTab || {},
                  profileId: rowValues['selectedUserProfile']?.value,
                  row,
                  usedProfiles
                });

                return finishRecordProcess({
                  collection,
                  customFieldsTab,
                  headerTab,
                  profileCollection: 'userProfiles',
                  profileValues: { customFieldsTab: profileCustomFieldsTab },
                  rowValues,
                  updateProfile: profile ? true : false,
                  validMongoId,
                  isUser: true
                });
              }
            }
          })
        ).then((data) => {
          showSkippedRecords(rows, data);
        });
        break;
      case 'userProfiles':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            if (validRecord) {
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = filledImportId
                ? await importIdHandler(row[importIdHeader].toString(), collection)
                : false;

              if (filledImportId && !existingImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                rowValues['importId'] = '';
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.IMPORT.ID' },
                  { name: row[nameHeader] }
                );
                showWarningMessage(message);

                return;
              }

              rowValues['profilePermissions'] = userDefaultPermissions;

              baseFieldsValues
                .filter((baseField) => !userProfilesPermissions.includes(baseField?.targetField))
                .forEach((baseField) => {
                  if (
                    !rowValues.hasOwnProperty(baseField?.targetField) &&
                    validMongoId &&
                    !row[baseField?.value]
                  ) {
                    rowValues[baseField?.targetField] = record[baseField?.targetField] || '';
                  } else if (!rowValues.hasOwnProperty(baseField?.targetField)) {
                    rowValues[baseField?.targetField] = row[baseField?.value]?.toString();
                  }
                });

              const customFieldsTab =
                (await getRecordCustomFieldsObject({
                  collection: selectedModule.catalogue.value,
                  customFields,
                  id: rowValues['_id'],
                  files: customFieldsFiles,
                  putValues: true,
                  row,
                  validMongoId
                })) || {};

              return finishRecordProcess({
                collection,
                customFieldsTab,
                headerTab,
                rowValues,
                validMongoId
              });
            }
          })
        ).then((data) => {
          showSkippedRecords(rows, data);
        });
        break;
      case 'employees':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            if (validRecord) {
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = await importIdHandler(row[importIdHeader], collection);

              if (!existingImportId && filledImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.REPEATED.IMPORT.ID' },
                  { importId: row[importIdHeader] }
                );
                showWarningMessage(message);

                return;
              }

              const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');
              const emailHeader = getBaseFieldHeader(baseFieldsValues, 'email');
              const idHeader = getBaseFieldHeader(baseFieldsValues, '_id');
              const validEmail = await isValidEmail(
                row[emailHeader],
                collection,
                row[idHeader],
                validMongoId
              );

              if (!validEmail) {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.EMAIL' },
                  { email: row[emailHeader] }
                );
                showWarningMessage(message);
                return;
              } else {
                rowValues['email'] = row[emailHeader] || record?.email || '';
                const employeeIdHeader = getBaseFieldHeader(baseFieldsValues, 'employee_id');
                const validEmployeeId = await isValidEmployeeId(row[employeeIdHeader], record);

                if (!validEmployeeId && !validMongoId) {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.EMPLOYEES.INVALID.EMPLOYEE.ID' },
                    { employee_id: row[employeeIdHeader] }
                  );
                  showWarningMessage(message);
                  return;
                } else {
                  rowValues['employee_id'] = row[employeeIdHeader];
                  const profileHeader = getBaseFieldHeader(baseFieldsValues, 'profile');

                  const profile = await getProfileByImportId(
                    row[profileHeader],
                    'employeeProfiles'
                  );
                  rowValues['employeeProfile'] = profile
                    ? { label: profile.name, value: profile._id }
                    : null;

                  if (profile) {
                    usedProfiles.push(profile.value);
                  } else if (!profile && validMongoId) {
                    rowValues['employeeProfile'] = record['employeeProfile'];
                    usedProfiles.push(rowValues['employeeProfile']?.value);
                  } else {
                    message = intl.formatMessage(
                      { id: 'IMPORT.WARNING.USERS.NO.PROFILE' },
                      { name: row[nameHeader] }
                    );
                    showWarningMessage(message);
                    return;
                  }

                  const layoutNameHeader = getBaseFieldHeader(baseFieldsValues, 'layoutSelected');
                  const employeeLayout = await getEmployeeLayout(row[layoutNameHeader], record);
                  rowValues['layoutSelected'] = employeeLayout;

                  baseFieldsValues.forEach((baseField) => {
                    if (baseField) {
                      if (
                        !rowValues.hasOwnProperty(baseField.targetField) &&
                        baseField.targetField !== 'profile'
                      ) {
                        rowValues[baseField.targetField] = row[baseField.value]?.toString();
                      }
                    }
                  });

                  const [
                    customFieldsTab,
                    profileCustomFieldsTab
                  ] = await childCustomFieldsAssignation({
                    customFields,
                    files: customFieldsFiles,
                    profileCollection: 'employeeProfiles',
                    profileCustomFields: profile?.customFieldsTab || {},
                    profileId: rowValues['employeeProfile']?.value,
                    row,
                    usedProfiles
                  });

                  return finishRecordProcess({
                    collection,
                    customFieldsTab,
                    headerTab,
                    profileCollection: 'employeeProfiles',
                    profileValues: { customFieldsTab: profileCustomFieldsTab },
                    rowValues,
                    updateProfile: false,
                    validMongoId
                  });
                }
              }
            }
          })
        ).then((data) => {
          showSkippedRecords(rows, data);
        });
        break;
      case 'employeeProfiles':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            if (validRecord) {
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = filledImportId
                ? await importIdHandler(row[importIdHeader].toString(), collection)
                : false;

              if (filledImportId && !existingImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                rowValues['importId'] = '';
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.IMPORT.ID' },
                  { name: row[nameHeader] }
                );
                showWarningMessage(message);
                return;
              }

              baseFieldsValues.forEach((baseField) => {
                if (baseField && !rowValues.hasOwnProperty(baseField?.targetField)) {
                  if (
                    validMongoId &&
                    requiredHeaders[layoutSelected].includes(baseField.targetField) &&
                    !row[baseField.value]
                  ) {
                    return;
                  }

                  const value =
                    baseField.targetField === 'isAssetRepository'
                      ? Boolean(Number(row[baseField.value]))
                      : row[baseField.value]?.toString();

                  rowValues[baseField.targetField] = value;
                }
              });

              const customFieldsTab =
                (await getRecordCustomFieldsObject({
                  collection: selectedModule.catalogue.value,
                  customFields,
                  id: rowValues['_id'],
                  files: customFieldsFiles,
                  putValues: true,
                  row,
                  validMongoId
                })) || {};

              return finishRecordProcess({
                collection,
                customFieldsTab,
                headerTab,
                rowValues,
                validMongoId
              });
            }
          })
        ).then((data) => {
          showSkippedRecords(rows, data);
        });
        break;
      case 'locationsReal':
        const newRootLocations = [];
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: selectedModule.catalogue.value,
              row
            });

            const assignProfileValues = ({ id, name, level }) => {
              rowValues['profileId'] = id || '';
              rowValues['profileName'] = name || '';
              rowValues['profileLevel'] = level || '';
            };

            const getParent = (parentId) => {
              const regex = /^[0-9a-fA-F]{24}$/;

              if (regex.test(parentId)) {
                return getOneDB('locationsReal/', parentId)
                  .then((response) => response.json())
                  .then((data) => data?.response)
                  .catch((error) => console.log(error));
              } else {
                return getDBComplex({
                  collection: 'locationsReal',
                  condition: [{ "importId": parentId }]
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data?.response && !isEmpty(data.response)) {
                      return data.response[0];
                    } else {
                      return null;
                    }
                  })
                  .catch((error) => console.log(error));
              }
            };

            const isParentLevelValid = (parentLevel, childLevel) => {
              return Number(parentLevel) === Number(childLevel) - 1;
            };

            if (validRecord) {
              // IMPORT ID ASSIGNATION

              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');

              if (!row[importIdHeader].toString()) {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.NO.IMPORT.ID' }
                );
                showWarningMessage(message);
                return;
              } else {
                const existingImportId = await importIdHandler(row[importIdHeader], collection);
                const filledImportId = !isEmpty(row[importIdHeader].toString());

                if (!existingImportId && filledImportId) {
                  rowValues['importId'] = row[importIdHeader].toString();
                } else {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.INVALID.REPEATED.IMPORT.ID' },
                    { importId: row[importIdHeader] }
                  );
                  showWarningMessage(message);
                  return;
                }
              }

              // PROFILE ASSIGNATION

              const profileHeader = getBaseFieldHeader(baseFieldsValues, 'profile');
              const profile = await getProfileByImportId(
                row[profileHeader],
                'locations'
              );

              assignProfileValues({
                id: profile?._id,
                name: profile?.name,
                level: profile?.level
              });

              if (profile) {
                usedProfiles.push(profile.value);
              } else if (!profile && validMongoId) {
                assignProfileValues({
                  id: record?.profileId,
                  name: record?.profileName,
                  level: record?.profileLevel
                });
                usedProfiles.push(record?.profileId);
              } else {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.USERS.NO.PROFILE' },
                  { name: rowValues['name'] }
                );
                showWarningMessage(message);
                return;
              }

              // PARENT VALIDATION AND ASSIGNATION

              if (rowValues['profileLevel'].toString() === '0') {
                rowValues['parent'] = 'root';
              } else {
                const parentHeader = getBaseFieldHeader(baseFieldsValues, 'parent');
                const parent = await getParent(row[parentHeader]);

                if (parent) {
                  if (isParentLevelValid(parent?.profileLevel, rowValues.profileLevel)) {
                    rowValues['parent'] = parent?._id;
                  } else {
                    message = intl.formatMessage(
                      { id: 'IMPORT.WARNING.LOCATIONS.INVALID.LEVEL' },
                      { name: rowValues['name'] }
                    );
                    showWarningMessage(message);
                    return;
                  }
                } else if (!validMongoId) {
                  message = intl.formatMessage(
                    { id: 'IMPORT.WARNING.LOCATIONS.NO.PARENT' },
                    { name: rowValues['name'] }
                  );
                  showWarningMessage(message);
                  return;
                }
              }

              // GOOGLE MAPS COORDINATES ASSIGNATION

              const latitudeHeader = getBaseFieldHeader(baseFieldsValues, 'latitude');
              const longitudeHeader = getBaseFieldHeader(baseFieldsValues, 'longitude');
              const zoomHeader = getBaseFieldHeader(baseFieldsValues, 'zoom');

              if (row[latitudeHeader] && row[longitudeHeader] && row[zoomHeader]) {
                rowValues['mapInfo'] = {
                  lat: Number(row[latitudeHeader]),
                  lng: Number(row[longitudeHeader]),
                  zoom: Number(row[zoomHeader])
                };
              } else {
                rowValues['mapInfo'] = null;
              }

              rowValues['imageInfo'] = null;

              const skippedColumns = ['profile', 'importId', 'parent', 'latitude', 'longitude', 'zoom'];
              baseFieldsValues.forEach((baseField) => {
                if (baseField) {
                  if (!rowValues.hasOwnProperty(baseField.targetField) && !skippedColumns.includes(baseField.targetField)) {
                    rowValues[baseField.targetField] = row[baseField.value]?.toString();
                  }
                }
              });

              const [
                customFieldsTab = {},
                profileCustomFieldsTab
              ] = await childCustomFieldsAssignation({
                customFields,
                files: customFieldsFiles,
                profileCollection: 'locations',
                profileCustomFields: profile?.customFieldsTab || {},
                profileId: rowValues['locations']?.value,
                row,
                usedProfiles
              });

              return finishRecordProcess({
                collection,
                customFieldsTab,
                headerTab,
                profileCollection: 'locations',
                profileValues: { customFieldsTab: profileCustomFieldsTab },
                rowValues,
                updateProfile: false,
                validMongoId,
                newRootLocations
              });
            }
          })
        )
          .then((data) => {
            if (!isEmpty(newRootLocations)) {
              getOneDB('user/', user?.id)
                .then((response) => response.json())
                .then((data) => {
                  const { locationsTable } = data.response;
                  const newLocationsTable = [...locationsTable, ...newRootLocations];

                  updateDB('user/', { locationsTable: newLocationsTable }, user.id)
                    .catch((error) => console.log(error))
                })
                .catch((error) => console.log(error))
            }

            showSkippedRecords(rows, data);
          });
        break;
      case 'locationProfiles':
        await Promise.all(
          rows.map(async (row) => {
            const [{ validMongoId, record }, rowValues, validRecord] = await getImportMainValues({
              baseFieldsValues,
              collection,
              layout: layoutSelected,
              row
            });

            if (validRecord) {
              const importIdHeader = getBaseFieldHeader(baseFieldsValues, 'importId');
              const filledImportId = !isEmpty(row[importIdHeader].toString());
              const existingImportId = filledImportId
                ? await importIdHandler(row[importIdHeader].toString(), collection)
                : false;

              if (filledImportId && !existingImportId) {
                rowValues['importId'] = row[importIdHeader].toString();
              } else {
                rowValues['importId'] = '';
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.INVALID.REPEATED.IMPORT.ID' },
                  { importId: row[importIdHeader] }
                );
                showWarningMessage(message);

                return;
              }

              const nameHeader = getBaseFieldHeader(baseFieldsValues, 'name');
              const nameRepeated = await isRepeatedName(row[nameHeader]);

              if (nameRepeated) {
                message = intl.formatMessage(
                  { id: 'IMPORT.WARNING.LOCATIONS.PROFILES.REPEATED.NAME' },
                  { name: row[nameHeader] }
                );
                showWarningMessage(message);
                return;
              }

              baseFieldsValues.forEach((baseField) => {
                if (baseField && !rowValues.hasOwnProperty(baseField?.targetField)) {
                  if (
                    validMongoId &&
                    requiredHeaders[layoutSelected].includes(baseField.targetField) &&
                    !row[baseField.value]
                  ) {
                    return;
                  }

                  const value =
                    ['isAssetRepository', 'isLocationControl'].includes(baseField.targetField)
                      ? Boolean(Number(row[baseField.value]))
                      : row[baseField.value]?.toString();

                  rowValues[baseField.targetField] = value;
                }
              });

              const customFieldsTab =
                (await getRecordCustomFieldsObject({
                  collection: selectedModule.catalogue.value,
                  customFields,
                  id: rowValues['_id'],
                  files: customFieldsFiles,
                  putValues: true,
                  row,
                  validMongoId
                })) || {};

              return finishRecordProcess({
                collection,
                customFieldsTab,
                headerTab,
                rowValues,
                validMongoId
              });
            }
          })
        )
          .then((data) => {
            showSkippedRecords(rows, data);
          });
        break;
      default:
        break;
    }

    message = getIntlValue('IMPORT.PROCESS.DONE', 'Import process done');
    showSuccessMessage(message);
    dispatch(setGeneralLoading({ active: false }));
  };

  const handleChangeTab = (event, index) => {
    const module = importModules[index].id;
    let catalogueIndex = 0;

    for (let i = 0; i < importModules[index].collections.length; i++) {
      if (user?.profilePermissions?.settings?.import?.includes(importModules[index].collections[i])) {
        catalogueIndex = i;
        break;
      }
    }

    const tab = importModulesCatalogues[module][catalogueIndex].id;

    setTab(index);
    setSelectedModule({ module, catalogue: { index: catalogueIndex, value: tab } });
    handleCurrentModuleCatalogues(importModulesCatalogues[module]);
  };

  const handleCurrentModuleCatalogues = (catalogues) => {
    const newValues = {};
    catalogues.forEach(({ id }) => {
      newValues[id] = {
        ...defaultTabValues,
        ...additionalValues,
        layout: {
          options: importLayouts[id],
          selected: importLayouts[id][0].value
        }
      };
    });

    setValues(newValues);
  };

  const handleCatalogueChange = (event, index) => {
    const catalogue = importModulesCatalogues[selectedModule.module][index].id;
    setSelectedModule((prev) => ({ ...prev, catalogue: { index, value: catalogue } }));
  };

  const handleFileChange = (file) => {
    if (!file) {
      return;
    }

    getRows(file);
  };

  const handleOnLayoutDownload = () => {
    if (typeof XLSX == 'undefined') {
      XLSX = require('xlsx');
    }

    const layoutSelected = values[selectedModule.catalogue.value].layout.selected;

    const worksheet = XLSX.utils.json_to_sheet([], {
      header: layoutHeaders[layoutSelected].map(({ id }) => id)
    });

    const cols = [];

    //* Set 30 xlsx columns width [A, B , C ...]
    for (let i = 0; i < 30; i++) {
      cols.push({ width: 19 });
    }

    worksheet['!cols'] = cols;

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, layoutSelected);
    XLSX.writeFile(workbook, `${layoutSelected}.xlsx`); //* Write xlsx and download
  };

  const handleSubTabFileChange = (tab) => (file) => {
    if (!file) {
      return;
    }

    const catalogue = selectedModule.catalogue.value;
    setValues((prev) => ({
      ...prev,
      [catalogue]: {
        ...prev[catalogue],
        [tab]: {
          ...prev[catalogue][tab],
          file
        }
      }
    }));

    const extension = file.name.split('.')[1];

    if (extension === 'zip') {
      handleZip(file, getFiles(tab));
    } else if (extension === 'rar') {
      handleRar(file);
    }
  };

  const handleOnChangeHeaderField = (id) => (baseField) => {
    const catalogue = selectedModule.catalogue.value;
    const newBaseFieldsValues = [...values[catalogue].baseFieldsValues];
    const index = (layoutHeaders[values[catalogue].layout.selected] || [])
      .map(({ id: headerId, translation }) => ({ label: headerId, targetField: headerId, value: headerId, translation }))
      .findIndex(({ value }) => value === id);
    newBaseFieldsValues[index] = baseField
      ? {
        label: baseField.label,
        targetField: layoutHeaders[values[catalogue].layout.selected][index].id,
        value: baseField.value,
        translation: layoutHeaders[values[catalogue].layout.selected][index].translation
      }
      : null;
    setValues((prev) => ({
      ...prev,
      [catalogue]: {
        ...prev[catalogue],
        baseFieldsValues: newBaseFieldsValues
      }
    }));
  };

  const getFiles = (tab) => (files) => {
    const catalogue = selectedModule.catalogue.value;
    setValues((prev) => ({
      ...prev,
      [catalogue]: {
        ...prev[catalogue],
        [tab]: {
          ...prev[catalogue][tab],
          files
        }
      }
    }));
  };

  const getRows = (file) => {
    const currentCatalogue = selectedModule.catalogue.value;
    setValues((prev) => ({
      ...prev,
      [currentCatalogue]: { ...values[currentCatalogue], loading: true }
    }));
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = reader.result;

      if (['xlsx', 'xls'].includes(file.name.split('.')[1])) {
        const workbook = XLSX.read(data, {
          cellText: false,
          cellDates: true,
          type:'binary'
        });

        workbook.SheetNames.forEach((sheetName) => {
          const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            defval: "",
            raw: false,
            rawNumbers: true,
            dateNF: 'yyyy"-"mm"-"dd' 
          });

          const headers = Object.entries(rows[0] || []).map(([key, value]) => key);
          setValues((prev) => ({
            ...prev,
            [currentCatalogue]: { ...values[currentCatalogue], loading: false, file, headers, rows }
          }));
        });
      } else {
        const [headers, rows] = csvJSON(data);
        setValues((prev) => ({
          ...prev,
          [currentCatalogue]: { ...values[currentCatalogue], loading: false, file, headers, rows }
        }));
      }
    };

    return reader.readAsBinaryString(file);
  };

  const handleRowsChange = () => {
    const catalogue = selectedModule.catalogue.value;

    if (isEmpty(values[catalogue].rows)) {
      return;
    }

    const baseFields = (layoutHeaders[values[catalogue].layout.selected] || []).map(({ id: headerId, translation }) => ({
      label: headerId,
      targetField: headerId,
      value: headerId,
      translation
    }));
    const newBaseFieldsValues = [...baseFields];
    baseFields.forEach(({ value }, index) => {
      if (values[catalogue].headers.includes(value)) {
        const header = values[catalogue].headers.find((header) => header === value);
        newBaseFieldsValues[index].label = header;
        newBaseFieldsValues[index].value = header;
      } else {
        newBaseFieldsValues[index] = null;
      }
    });

    setValues((prev) => ({
      ...prev,
      [catalogue]: {
        ...prev[catalogue],
        baseFieldsValues: newBaseFieldsValues
      }
    }));
  };

  useEffect(() => {
    if (!isEmpty(skippedRows)) {
      setShowSkippedModal(true);
    }
  }, [skippedRows]);

  useEffect(() => {
    handleRowsChange();
  }, [values[selectedModule.catalogue.value].rows]);

  useEffect(() => {
    const usedHeaders = [];
    values[selectedModule.catalogue.value].baseFieldsValues.forEach((baseField) => {
      if (baseField) {
        usedHeaders.push(baseField.value);
      }
    });
    setUsedHeaders(usedHeaders);
  }, [values[selectedModule.catalogue.value].baseFieldsValues]);

  return (
    <div className={classes.root}>
      <ModalSkippedRecords 
        handleCloseModal={() => {
          setShowSkippedModal(false);
          setSkipepdRows([]);
        }}
        skippedRows={skippedRows}
        showModal={showSkippedModal}
      />
      <div style={{ marginRight: '40px' }}>
        <Tabs
          component="div"
          className="builder-tabs"
          indicatorColor="primary"
          onChange={handleChangeTab}
          orientation={dimensions.width > 600 ? 'vertical' : 'horizontal'}
          textColor="primary"
          value={tab}
          variant={dimensions.width > 600 ? 'fullWidth' : 'scrollable'}
        >
          {importModules.map(({ id, label, collections }, index) => (
            user?.profilePermissions?.settings?.import?.some((collection) => collections.includes(collection)) && (
              <Tab
                classes={{
                  wrapper: classes.wrapper
                }}
                key={`import-${id}`}
                label={GetTranslatedValue(label)}
                value={index}
              />
            )
          ))}
        </Tabs>
      </div>
      <div className={classes.importSection}>
        <Tabs
          component="div"
          className="builder-tabs"
          indicatorColor="primary"
          onChange={handleCatalogueChange}
          style={{ marginBottom: '10px' }}
          value={selectedModule.catalogue.index}
        >
          {importModulesCatalogues[selectedModule.module].map(({ id, label }, index) => (
            user?.profilePermissions?.settings?.import?.includes(id) && (
              <Tab
                key={id}
                label={GetTranslatedValue(label)}
                value={index}
              />
            )
          ))}
        </Tabs>
        <div style={{ paddingRight: '30px', width: '100%' }}>
          <Grid container className={classes.buttons}>
            <UploadButton
              label={values[selectedModule.catalogue.value].file?.name}
              onFileChange={handleFileChange}
            />
            <ImportButton
              disabled={!values[selectedModule.catalogue.value].file}
              onClick={handleImport}
            />
          </Grid>
          <Grid container className={classes.layoutSelect}>
            <Select
              onChange={(event) => {
                const catalogue = selectedModule.catalogue.value;
                setValues((prev) => ({
                  ...prev,
                  [catalogue]: {
                    ...prev[catalogue],
                    layout: {
                      ...prev[catalogue].layout,
                      selected: event.target.value
                    }
                  }
                }));
              }}
              value={values[selectedModule.catalogue.value].layout.selected}
              style={{
                minWidth: '130px',
                marginBottom: dimensions.width > 600 ? '0px' : '20px',
                marginRight: dimensions.width > 600 ? '30px' : '0px'
              }}
            >
              {(values[selectedModule.catalogue.value].layout.options || []).map(
                ({ label, value }) => (
                  <MenuItem key={`${value}-menu-item`} value={value}>
                    {GetTranslatedValue(label)}
                  </MenuItem>
                )
              )}
            </Select>
            <DownloadButton
              disabled={!values[selectedModule.catalogue.value].layout.selected}
              onClick={handleOnLayoutDownload}
            />
          </Grid>
          {importModulesCatalogues[selectedModule.module].map(({ id, label }) => {
            const catalogue = selectedModule.catalogue.value;
            const headers = values[id].headers;
            const rows = values[id].rows;
            const subTab = values[id].subTab;

            return (
              <Typography
                component="div"
                key={`${id}-panel`}
                hidden={!values[id].file || catalogue !== id}
              >
                <div style={{ marginLeft: '20px', paddingRight: '25px', width: '100%' }}>
                  <Tabs
                    component="div"
                    className="builder-tabs"
                    key={`${id}-tab`}
                    onChange={(event, nextTab) =>
                      setValues((prev) => ({ ...prev, [id]: { ...values[id], subTab: nextTab } }))
                    }
                    style={{ marginBottom: '20px' }}
                    value={subTab}
                    variant="scrollable"
                  >
                    {moduleTabs.map(({ key, label: tabLabel }) => (
                      <Tab key={key} label={GetTranslatedValue(tabLabel)} />
                    ))}
                  </Tabs>
                  {subTab === 0 && catalogue === id && (
                    <Headers
                      baseFields={(
                        layoutHeaders[values[catalogue].layout.selected] || []
                      ).map(({ id: headerId, translation }) => ({ label: headerId, targetField: headerId, value: headerId, translation }))}
                      baseFieldsValues={values[catalogue].baseFieldsValues}
                      collection={selectedModule.catalogue.value}
                      loading={values[id].loading}
                      handleOnChangeHeaderField={handleOnChangeHeaderField}
                      headers={headers}
                      file={values[id]['headerTab'].file || {}}
                      onFileChange={handleSubTabFileChange}
                      rows={rows}
                      show={subTab === 0 && catalogue === id}
                    />
                  )}
                  <Typography
                    component="div"
                    hidden={subTab !== 1 || catalogue !== id}
                    key={`${id}-custom-fields`}
                  >
                    <CustomFields
                      headers={headers}
                      file={values[id]['customFieldsTab'].file || {}}
                      onCustomFieldsChange={(customFields) =>
                        setValues((prev) => ({ ...prev, [id]: { ...values[id], customFields } }))
                      }
                      onFileChange={handleSubTabFileChange}
                      referenceCollection={moduleReferences[catalogue]}
                      show={subTab === 1 && catalogue === id}
                      usedHeaders={usedHeaders}
                    />
                  </Typography>
                  <Typography
                    component="div"
                    hidden={subTab !== 2 || catalogue !== id}
                    key={`${id}-preview`}
                  >
                    <Preview
                      data={rows}
                      headers={headers}
                      onSelectedRowsChange={(selectedRows) => {
                        if (values[id].selectedRows !== selectedRows) {
                          setValues(prev => ({ ...prev, [id]: { ...values[id], selectedRows } }));
                        }
                      }}
                    />
                  </Typography>
                </div>
              </Typography>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Import);
