/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, isEqual, omit, pick, uniq, uniqBy } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import HelpIcon from '@material-ui/icons/Help';
import { PortletHeader, PortletHeaderToolbar } from '../../../../partials/content/Portlet';
import { actions } from '../../../../store/ducks/general.duck';
import {
  postDB,
  getDB,
  getOneDB,
  updateDB,
  deleteDB,
  postFILE,
  getDBComplex,
  getCountDB,
  findAndModifyDB,
  updateManyDB
} from '../../../../crud/api';
import CustomizedToolTip from '../../Components/CustomizedToolTip';
import { CustomFieldsPreview } from '../../constants';
import {
  getCurrentDateTime,
  simplePost,
  getLocationPath,
  getVariables,
  setNewValue,
  loadUserLocations
} from '../../utils';
import { sendEmail } from '../../../../crud/api';
import AssetFinderPreview from '../../Components/AssetFinderPreview';
import TableComponent2 from '../../Components/TableComponent2';
import { collections } from '../../constants';
import LiveProcessTab from '../components/LiveProcessTab.jsx';
import { usePolicies } from '../../Components/Policies/hooks';
import { transformProcess } from './utils';
import { extractCustomFieldValues } from '../../Reports/reportsHelpers';
import { verifyCustomFields } from '../../utils';
import CustomRecordModal from '../../Components/CustomRecordModal';
import { useIsMount, GetTranslatedValue, CapitalizeFirstLetter } from '../../utils';
import CircularProgressCustom from '../../Components/CircularProgressCustom';
import { executeOnFieldPolicy, executePolicies } from '../../Components/Policies/utils';

const { REACT_APP_ASSETS_LIMIT } = process.env;

// Example 5 - Modal
const styles5 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const howManyAssets = (count, limit, toAdd) => {
  const accepted = limit == -1 ? toAdd : limit - count;
  const rejected = limit == -1 ? 0 : toAdd - accepted;
  return { accepted, rejected };
};

// Example 4 - Tabs
function TabContainer4({ children, dir, paddingTop = true }) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{ padding: 8 * 3, paddingTop: paddingTop ? 8 * 3 : '10px' }}
    >
      {children}
    </Typography>
  );
}
const TabContainerCustom = ({ children, dir, padding = false }) => {
  return (
    <Typography component="div" dir={dir} style={{ paddingTop: 8 * 2 }}>
      {children}
    </Typography>
  );
};
const useStyles4 = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 1000
  },
  subTab: {
    backgroundColor: theme.palette.background.paper,
    width: '80%'
  }
}));

// Example 1 - TextField
const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    width: '200px'
  },
  selectButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  select: {
    minWidth: '250px',
    margin: '10px',
    marginLeft: '45px'
  },
  menu: {
    width: '100%',
    marginRight: '200px'
  },
  secondaryText: {
    color: 'grey'
  },
  '@media (max-width: 700px)': {
    selectButton: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
      flexDirection: 'column'
    },
    textField: {
      marginLeft: '0px',
      marginRight: '0px',
      width: '100%',
      marginBottom: '10px'
    }
  },
  '@media (max-width: 690px)': {
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px',
      alignItems: 'center'
    },
    select: {
      width: '100%',
      margin: '10px'
    }
  }
}));

const ModalProcessLive = (props) => {
  const { showModal, setShowModal, reloadTable, id, user } = props;
  const dispatch = useDispatch();
  const isMount = useIsMount();
  const { showCustomAlert, showErrorAlert, showFillFieldsAlert } = actions;
  // Example 4 - Tabs
  const [loading, setLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [preventScroll, setPreventScroll] = useState(false);
  const classes4 = useStyles4();
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  const [stageTabSelected, setStageTabSelected] = useState(0);
  const [customTabSelected, setCustomTabSelected] = useState(0);
  const [assetFinderTab, setAssetFinderTab] = useState(0);
  const [locationsAssetRepository, setLocationsAssetRepository] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [dueDate, setDueDate] = useState(undefined);
  const [toggleButtonClicked, setToggleButtonClicked] = useState('1');
  const [decommissionAssets, setDecommissionAssets] = useState(true);
  const { policies, setPolicies } = usePolicies();

  const pendingApprovalMessage = GetTranslatedValue('PROCESSES.MESSAGE.PENDING.APPROVAL');
  const stageRejectedMessage = GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.REJECTED');
  const stageApprovedMessage = GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.APPROVED');
  const neitherLabel = GetTranslatedValue('GENERAL.CAPTION.NEITHER', 'Neither');
  const assetsLabel = GetTranslatedValue('GENERAL.CAPTION.ASSETS', 'Assets');
  const wasLabel = GetTranslatedValue('GENERAL.CAPTION.WAS', 'Was');
  const wereLabel = GetTranslatedValue('GENERAL.CAPTION.WERE', 'Were');
  const addAssetsAlert = GetTranslatedValue(
    'PROCESSES.ALERT.FIRST.ADD.ASSET',
    'First, please add assets'
  );
  const sameLocationAlert = GetTranslatedValue(
    'PROCESSES.ALERT.SAME.LOCATION',
    'In Creation and Movement processes, all assets should have the same location selected'
  );
  const asserRepositoryAlert = GetTranslatedValue(
    'PROCESSES.ALERT.REPOSITORY.LOCATION',
    'In Creation and Movement processes, the location selected should be Asset Repository'
  );
  const bossAlert = GetTranslatedValue(
    'PROCESSES.ALERT.USER.BOSS',
    'Current user should have a boss assigned in order to create the process'
  );
  const processInitiatorAlert = GetTranslatedValue(
    'PROCESSES.ALERT.USER.INITIATOR',
    "Couldn't calculate the Process Initiator"
  );
  const thisLocationAlert = GetTranslatedValue('GENERAL.CAPTION.THIS.LOCATION', 'This Location');
  const locationManagerAlert = GetTranslatedValue(
    'PROCESSES.ALERT.LOCATION.MANAGER',
    "doesn't have a Location Manager"
  );
  const locationWitnessAlert = GetTranslatedValue(
    'PROCESSES.ALERT.LOCATION.WITNESS',
    'nor any of its parents have a Location Witness'
  );
  const assetSpecialistAlert = GetTranslatedValue(
    'PROCESSES.ALERT.ASSET.SPECIALIST',
    'nor any of its parents have an Asset Specialist for the asset'
  );
  const dueDateAlert = GetTranslatedValue(
    'PROCESSES.ALERT.DUE.DATE',
    'To start this process please specify a Due Date.'
  );
  const validateAllAssets = GetTranslatedValue(
    'PROCESSES.ALERT.VALIDATE.ALL.ASSETS',
    'You have to validate all assets'
  );
  const assetLimits = GetTranslatedValue(
    'PROCESSES.ALERT.ASSETS.LIMIT',
    `You have reached the limit of assets`
  );
  const limitReachedAlert = GetTranslatedValue(
    'PROCESSES.ALERT.ASSETS.LIMIT.REACHED',
    "not because you've reached the Asset limit"
  );
  const assetsCreatedAlert = GetTranslatedValue('PROCESSES.ALERT.ASSETS.CREATED', 'Assets Created');
  const decomissionedAlert = GetTranslatedValue(
    'PROCESSES.ALERT.ASSETS.DECOMMISSIONED',
    'Assets Decommissioned'
  );
  const transferedAlert = GetTranslatedValue(
    'PROCESSES.ALERT.ASSETS.TRANSFERED',
    'Assets Transferred'
  );
  const finishedMaintenance = GetTranslatedValue(
    'PROCESSES.ALERT.ASSETS.FINISHED.MAINTENANCE',
    'Assets Finished Maintenance'
  );
  const assetsModified = GetTranslatedValue('PROCESSES.ALERT.ASSETS.MODIFIED', 'Assets Modified');
  const statusAlert = GetTranslatedValue(
    'PROCESSES.ALERT.ACTIVE.STATUS',
    "Some assets were ignored because they don't have an 'active' status"
  );
  const deletedAlert = GetTranslatedValue(
    'PROCESSES.ALERT.DELETED.STATUS',
    "Some assets were ignored because they were deleted of the system"
  );
  const alreadyAddedAlert = GetTranslatedValue(
    'PROCESSES.ALERT.ALREADY.ADDED',
    'was already added.'
  );
  const noAddedAlert = GetTranslatedValue('PROCESSES.ALERT.NO.ADDED', 'No assets were added');
  const addedAlert = GetTranslatedValue('PROCESSES.ALERT.ADDED', 'Asset(s) added');

  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  const handleChangeStageTab = (event, newValue) => {
    setCustomTabSelected(0);
    setStageTabSelected(newValue);
  };
  const handleChangeCustomFieldTab = (event, newValue) => {
    setCustomTabSelected(newValue);
  };
  const handleChangeAssetFinder = (newValue) => {
    if (isAssetReference) {
      return;
    }
    setAssetFinderTab(newValue);
  };
  function handleChangeIndex4(index) {
    setValue4(index);
  }

  const classes = useStyles();
  const [values, setValues] = useState({
    groupedFolios: []
  });

  const handleUpdateCustomFields = (tab, id, colIndex, CFValues) => {
    if (!Object.keys(customFieldsTab).length) {
      return;
    }
    const colValue = ['left', 'right'];
    const customFieldsTabTmp = { ...customFieldsTab };

    const field =
      customFieldsTabTmp[stageTabSelected][tab][colValue[colIndex]].find((cf) => cf.id === id) ||
      {};
    field.values = CFValues;
  };

  const checkValidLocations = (processType) => {
    if (values.updateProcess) {
      return true;
    }
    if (['creation', 'movement', 'short'].includes(processType)) {
      if (!cartRows.length) {
        dispatch(
          showCustomAlert({
            type: 'warning',
            open: true,
            message: addAssetsAlert
          })
        );
        return false;
      }

      const locationsSet = cartRows.every((row) => row.locationId);
      const firstLocationId = cartRows[0].locationId;
      const sameLocation = cartRows.every((row) => row.locationId === firstLocationId);

      if (!locationsSet || !sameLocation) {
        dispatch(
          showCustomAlert({
            type: 'warning',
            open: true,
            message: sameLocationAlert
          })
        );
        return false;
      }
      const { profileId: selectedLocationProfile } = allLocations.find(
        ({ id }) => firstLocationId === id
      );
      const selectedLocationIsAssetRepository = locationsAssetRepository.find(
        ({ _id }) => _id === selectedLocationProfile
      );

      if (!selectedLocationIsAssetRepository) {
        dispatch(
          showCustomAlert({
            type: 'warning',
            open: true,
            message: asserRepositoryAlert
          })
        );
        return false;
      }

      return true;
    } else {
      return true;
    }
  };

  const checkValidDirectBoss = async (_processData) => {
    const boss = await getBossInfo();
    if (!boss) {
      dispatch(
        showCustomAlert({
          type: 'warning',
          open: true,
          message: bossAlert
        })
      );
      return false;
    }

    _processData.stages.map((stage, index) => {
      stage.approvals[stage.approvals.findIndex((e) => e._id === 'boss')] = {
        ...boss,
        fulfillDate: '',
        fulfilled: false,
        virtualUser: 'boss'
      };
      stage.notifications[stage.notifications.findIndex((e) => e._id === 'boss')] = {
        ...boss,
        fulfillDate: '',
        fulfilled: false,
        virtualUser: 'boss'
      };
    });

    return true;
  };

  const checkValidInitiator = async (_processData) => {
    const { id: _id, name, lastName, email } = user;
    const initiator = { _id, name, lastName, email };

    if (!initiator) {
      dispatch(
        showCustomAlert({
          type: 'warning',
          open: true,
          message: processInitiatorAlert
        })
      );
      return false;
    }

    _processData.stages.map((stage, index) => {
      stage.approvals[stage.approvals.findIndex((e) => e._id === 'initiator')] = {
        ...initiator,
        fulfillDate: '',
        fulfilled: false,
        virtualUser: 'initiator'
      };
      stage.notifications[stage.notifications.findIndex((e) => e._id === 'initiator')] = {
        ...initiator,
        fulfillDate: '',
        fulfilled: false,
        virtualUser: 'initiator'
      };
    });

    return true;
  };

  const getAllLocations = (_cartRows, processType) => {
    const result = _cartRows.map((asset) => {
      if (values.updateProcess || processType === 'maintenance' || processType === 'decommission') {
        const { originalLocation, location } = asset;
        return { path: originalLocation, id: location };
      }
      const { locationName, locationId } = asset;
      return { path: locationName, id: locationId };
    });
    return uniqBy(result, 'id');
  };

  const getAssetLocation = (asset, processType) => {
    if (values.updateProcess || processType === 'maintenance' || processType === 'decommission') {
      const { originalLocation, location } = asset;
      return { path: originalLocation, id: location };
    }
    const { locationName, locationId } = asset;
    return { path: locationName, id: locationId };
  };

  const checkValidLocationManager = async (_cartRows, _body) => {
    const { processData } = _body;
    const allLocations = getAllLocations(_cartRows, processData.selectedProcessType);

    const allManagers = await Promise.all(
      allLocations.map(async ({ path, id }) => {
        const manager = await getLocationManagerInfo(id);
        if (!manager) {
          dispatch(
            showCustomAlert({
              type: 'warning',
              open: true,
              message: `${path || thisLocationAlert} ${locationManagerAlert}`
            })
          );
          return undefined;
        }
        return manager;
      })
    );

    if (allManagers.includes(undefined)) {
      return false;
    }

    processData.stages.map((stage, index) => {
      const approvalIndexToRemove = stage.approvals.findIndex((e) => e._id === 'locationManager');
      if (approvalIndexToRemove > -1) {
        //change approvals
        const processedManagers = allManagers.map((manager) => ({
          ...manager,
          fulfillDate: '',
          fulfilled: false,
          virtualUser: 'locationManager'
        }));
        stage.approvals.splice(approvalIndexToRemove, 1);
        const newApprovals = [...stage.approvals, ...processedManagers];
        stage.approvals = newApprovals;
      }

      const notificationIndexToRemove = stage.notifications.findIndex(
        (e) => e._id === 'locationManager'
      );
      if (notificationIndexToRemove > -1) {
        //change approvals
        const processedManagers = allManagers.map((manager) => ({
          ...manager,
          sentDate: '',
          sent: false,
          virtualUser: 'locationManager'
        }));
        stage.notifications.splice(notificationIndexToRemove, 1);
        const newNotifications = [...stage.notifications, ...processedManagers];
        stage.notifications = newNotifications;
      }
    });

    return true;
  };

  const recursiveFindWitnessByLocation = (allLocations, allWitnesses, currentLocation) => {
    const witness = allWitnesses.find(
      ({ location }) => location.locationSelected === currentLocation.id
    );
    if (witness) {
      const { value: _id, label: email, name, lastName } = witness.userSelected;
      return { _id, email, name, lastName };
    } else if (!witness && currentLocation.parent !== 'root') {
      currentLocation = allLocations.find(({ id }) => currentLocation.parent === id);
      return recursiveFindWitnessByLocation(allLocations, allWitnesses, currentLocation);
    }
    return;
  };

  const recursiveFindSpecialist = (allLocations, allSpecialists, currentLocation, asset) => {
    const specialist = allSpecialists.find(
      ({ location, categorySelected }) =>
        location.locationSelected === currentLocation.id &&
        asset.selectedProfile.value === categorySelected.value
    );
    if (specialist) {
      return specialist;
    } else if (!specialist && currentLocation.parent !== 'root') {
      currentLocation = allLocations.find(({ id }) => currentLocation.parent === id);
      return recursiveFindSpecialist(allLocations, allSpecialists, currentLocation, asset);
    }
    return;
  };

  const checkValidLocationWitness = async (_cartRows, body) => {
    const { processData } = body;
    const allcartRowLocations = getAllLocations(_cartRows, processData.selectedProcessType);
    const allWitnesses = await getWitnesses();

    const allWitnesssesProcessed = await Promise.all(
      allcartRowLocations.map(async ({ path, id }) => {
        var currentLocation = allLocations.find(({ id: locationId }) => locationId === id);
        var witness = await recursiveFindWitnessByLocation(
          allLocations,
          allWitnesses,
          currentLocation
        );
        if (!witness) {
          dispatch(
            showCustomAlert({
              type: 'warning',
              open: true,
              message: `${neitherLabel} "${path}" ${locationWitnessAlert}`
            })
          );
          return undefined;
        }
        return witness;
      })
    );

    if (allWitnesssesProcessed.includes(undefined)) {
      return false;
    }

    processData.stages.map((stage, index) => {
      const approvalIndexToRemove = stage.approvals.findIndex((e) => e._id === 'locationWitness');
      if (approvalIndexToRemove > -1) {
        //change approvals
        const witnesses = allWitnesssesProcessed.map((witness) => ({
          ...witness,
          fulfillDate: '',
          fulfilled: false,
          virtualUser: 'locationWitness'
        }));
        stage.approvals.splice(approvalIndexToRemove, 1);
        const newApprovals = [...stage.approvals, ...witnesses];
        stage.approvals = newApprovals;
      }

      const notificationIndexToRemove = stage.notifications.findIndex(
        (e) => e._id === 'locationWitness'
      );
      if (notificationIndexToRemove > -1) {
        //change approvals
        const witnesses = allWitnesssesProcessed.map((witness) => ({
          ...witness,
          sentDate: '',
          sent: false,
          virtualUser: 'locationWitness'
        }));
        stage.notifications.splice(notificationIndexToRemove, 1);
        const newNotifications = [...stage.notifications, ...witnesses];
        stage.notifications = newNotifications;
      }
    });

    return true;
  };

  const checkValidAssetSpecialist = async (_cartRows, _processData) => {
    const allSpecialists = await getAssetSpecialists();

    const allSpecialistsProcessed = _cartRows.map((asset) => {
      const { path, id } = getAssetLocation(asset, _processData.selectedProcessType);
      var currentLocation = allLocations.find(({ id: locationId }) => locationId === id);
      const specialist = recursiveFindSpecialist(
        allLocations,
        allSpecialists,
        currentLocation,
        asset
      );

      if (!specialist) {
        dispatch(
          showCustomAlert({
            type: 'warning',
            open: true,
            message: `${neitherLabel} "${path}" ${assetSpecialistAlert}: "${asset.name}"`
          })
        );
        return undefined;
      }
      return specialist.userSelected;
    });

    if (allSpecialistsProcessed.includes(undefined)) {
      return false;
    }

    const specialistsReady = uniqBy(
      allSpecialistsProcessed,
      'value'
    ).map(({ value: _id, label: email, name, lastName }) => ({ _id, email, name, lastName }));

    _processData.stages.map((stage, index) => {
      const approvalIndexToRemove = stage.approvals.findIndex((e) => e._id === 'assetSpecialist');
      if (approvalIndexToRemove > -1) {
        //change approvals
        const specialists = specialistsReady.map((specialist) => ({
          ...specialist,
          fulfillDate: '',
          fulfilled: false,
          virtualUser: 'assetSpecialist'
        }));
        stage.approvals.splice(approvalIndexToRemove, 1);
        const newApprovals = [...stage.approvals, ...specialists];
        stage.approvals = newApprovals;
      }

      const notificationIndexToRemove = stage.notifications.findIndex(
        (e) => e._id === 'assetSpecialist'
      );
      if (notificationIndexToRemove > -1) {
        //change approvals
        const specialists = specialistsReady.map((specialist) => ({
          ...specialist,
          sentDate: '',
          sent: false,
          virtualUser: 'assetSpecialist'
        }));
        stage.notifications.splice(notificationIndexToRemove, 1);
        const newNotifications = [...stage.notifications, ...specialists];
        stage.notifications = newNotifications;
      }
    });

    return true;
  };

  const updateGroupingFolios = (thisFolio) => {
    const condition = [{ folio: { $in: values.groupedFolios } }];
    getDBComplex({ collection: 'processLive/', condition })
      .then((response) => response.json())
      .then((data) => {
        data.response.map(({ _id: processId, groupedFolios: oldGroupedFolios }) => {
          const newGroupedFolios = Array.isArray(oldGroupedFolios)
            ? [...oldGroupedFolios, thisFolio]
            : [thisFolio];
          updateDB('processLive/', { groupedFolios: newGroupedFolios }, processId)
            .then(() => {})
            .catch((error) => console.log(error));
        });
      })
      .catch((error) => console.log('error>', error));
  };

  const handleSave = async () => {
    if (loading) {
      return;
    }

    const customFieldsObj = {};

    (Array.isArray(customFieldsTab) ? customFieldsTab : []).forEach((customField, index) => {
      customFieldsObj[index] = verifyCustomFields(customField);
    });

    if (Object.values(customFieldsObj).includes(false)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    setLoading(true);
    const body = {
      ...values,
      cartRows,
      requestUser: pick(user, ['email', 'id', 'lastName', 'name']),
      dueDate
    };
    if (!id) {
      if (
        processes.find(({ id }) => id === values.selectedProcess)?.processStages[0]
          ?.isControlDueDate &&
        !dueDate
      ) {
        dispatch(
          showCustomAlert({
            type: 'error',
            open: true,
            message: dueDateAlert
          })
        );
        return;
      }

      body.processData = transformProcess(processes, values.selectedProcess);

      const allApprovals = body.processData.stages
        .map((stage) => stage.approvals)
        .flat()
        .map((e) => e._id);
      const allNotifications = body.processData.stages
        .map((stage) => stage.notifications)
        .flat()
        .map((e) => e._id);

      if (allApprovals.includes('boss') || allNotifications.includes('boss')) {
        if (!(await checkValidDirectBoss(body.processData))) {
          return;
        }
      }
      if (allApprovals.includes('initiator') || allNotifications.includes('initiator')) {
        if (!(await checkValidInitiator(body.processData))) {
          return;
        }
      }
      if (!checkValidLocations(body.processData.selectedProcessType)) {
        return;
      }
      if (
        allApprovals.includes('locationManager') ||
        allNotifications.includes('locationManager')
      ) {
        if (!(await checkValidLocationManager(body.cartRows, body))) {
          return;
        }
      }
      if (
        allApprovals.includes('locationWitness') ||
        allNotifications.includes('locationWitness')
      ) {
        if (!(await checkValidLocationWitness(body.cartRows, body))) {
          return;
        }
      }
      if (
        allApprovals.includes('assetSpecialist') ||
        allNotifications.includes('assetSpecialist')
      ) {
        if (!(await checkValidAssetSpecialist(body.cartRows, body.processData))) {
          return;
        }
      }

      const helperDoc = 'processFolio';
      findAndModifyDB({
        collection: 'helpers',
        query: [{ name: helperDoc }],
        update: { $inc: { value: 1 } }
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.response || isEmpty(data.response)) {
            const body = {
              name: helperDoc,
              value: 1
            };
            postDB('helpers', body);
          }

          let biggestFolio = data?.response?.value || 0;
          const whiteFolio = [0, 0, 0, 0, 0, 0];

          const prefix = whiteFolio.slice(0, 6 - String(biggestFolio + 1).length);
          const newFolio = prefix.join('').concat(String(biggestFolio + 1));

          body.processData.processStatus = 'inProcess';
          body.folio = newFolio;
          if (selectedProcessType === 'maintenance') {
            const { dateFormatted, timeFormatted } = getCurrentDateTime();
            body.cartRows = cartRows.map((asset) => ({
              ...asset,
              history: [
                ...asset.history,
                {
                  processId: newFolio,
                  processName: body.processData.name,
                  processType: body.processData.selectedProcessType,
                  label: 'Went down for Maintenance',
                  date: `${dateFormatted} ${timeFormatted}`
                }
              ]
            }));
          }
          postDB('processLive', body)
            .then((data) => data.json())
            .then(async (response) => {
              const processLiveResponse = response.response[0];
              setProcessInfo(processLiveResponse);
              const { _id } = processLiveResponse;
              groomProcess(processLiveResponse);
              const {
                processData: { selectedProcessType }
              } = processLiveResponse;
              processLiveResponse.processLiveId = _id;
              updateDB('processLive/', omit(processLiveResponse, '_id'), _id)
                .then(() => saveAndReload('processLive', processLiveResponse._id))
                .catch((error) => console.log(error));
              setAssetsStatus(processLiveResponse, selectedProcessType);

              if (!isEmpty(values.groupedFolios)) {
                updateGroupingFolios(newFolio);
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => ('Error Process Folio:', error));
    } else {
      const isApprovalComplete = checkApprovalComplete();
      if (!isApprovalComplete) {
        dispatch(
          showCustomAlert({
            type: 'warning',
            open: true,
            message: validateAllAssets
          })
        );
        return;
      }
      const processData = applyApproval();
      processInfo.processData = processData;
      groomProcess(processInfo);
      setTimeout(() => {
        updateDB('processLive/', { processData: processInfo.processData }, id[0])
          .then((data) => data.json())
          .then((response) => {
            const queryExact = [
              { key: 'userId', value: user.id },
              { key: 'processId', value: id[0] },
              { key: 'fulfilled', value: false }
            ];
            getDBComplex({ collection: 'processApprovals', queryExact, operator: '$and' })
              .then((response) => response.json())
              .then((data) => {
                const { _id } = data.response[0];
                const { dateFormatted, timeFormatted } = getCurrentDateTime();
                updateDB(
                  'processApprovals/',
                  { fulfilled: true, fulfillDate: `${dateFormatted} ${timeFormatted}` },
                  _id
                )
                  .then((data) => data.json())
                  .then((response) => {
                    reloadTable();
                  })
                  .catch((error) => console.log(error));
              })
              .catch((error) => console.log('error>', error));
          })
          .catch((error) => console.log(error));
      }, 1000);
    }

    handleCloseModal();
  };

  const setAssetsStatus = (processLiveResponse, selectedProcessType) => {
    const { cartRows = [], processData } = processLiveResponse;

    if (selectedProcessType === 'short' || processData.stages[0]?.isSelfApprove) {
      return;
    }

    const status = selectedProcessType === 'maintenance' ? 'maintenance' : 'inProcess';
    if (status === 'maintenance') {
      cartRows.forEach(({ id, history }) => {
        updateDB('assets/', { status, history }, id).catch((error) => console.log(error));
      });
    } else {
      cartRows.forEach(({ id }) => {
        updateDB('assets/', { status }, id).catch((error) => console.log(error));
      });
    }
  };

  const checkApprovalComplete = () => {
    const totalAssets = processInfo.cartRows?.length || 0;
    const validatedAssets = cartRows.filter(({ status }) => status).length;

    return totalAssets === validatedAssets;
  };

  const applyApproval = () => {
    const { dateFormatted, timeFormatted } = getCurrentDateTime();
    const { processData, requestUser, _id: liveProcessId } = processInfo;
    const { currentStage } = processData;
    if (currentStage === 0) {
      return initializeStage(process);
    }
    const currentStageData = getCurrentStageData(currentStage, processData);
    const { approvals } = currentStageData;
    currentStageData.cartRows = cartRows;
    const currentUserApproval = approvals.find(
      ({ _id, fulfilled }) => _id === user.id && !fulfilled
    );
    if (!currentUserApproval) {
      return processData;
    }
    currentUserApproval.cartRows = cartRows;
    currentUserApproval.fulfilled = true;
    currentUserApproval.fulfillDate = `${dateFormatted} ${timeFormatted}`;
    const isLastApproval = !approvals.map(({ fulfilled }) => fulfilled).includes(false);
    if (isLastApproval) {
      sendMessages(
        currentStageData,
        requestUser,
        liveProcessId,
        processData.id,
        processInfo,
        'end'
      ); // Notifications
    }

    return processData;
  };

  const earlyFinish = (process) => {
    const { processData, requestUser, _id: liveProcessId } = process;
    const { stages } = processData;
    Object.keys(stages).forEach((stage) => {
      sendMessages(
        stages[stage],
        requestUser,
        liveProcessId,
        processData.id,
        process,
        'start',
        'skipped'
      );
      sendMessages(
        stages[stage],
        requestUser,
        liveProcessId,
        processData.id,
        process,
        'end',
        'skipped'
      );
    });
    finishProcess(process);
  };

  const groomProcess = (process) => {
    const { processData } = process;
    const { currentStage, totalStages, selectedProcessType } = processData;
    if (currentStage === 0) {
      return selectedProcessType === 'short' || processData.stages[0]?.isSelfApprove
        ? earlyFinish(process)
        : initializeStage(process);
    }
    const currentStageData = getCurrentStageData(currentStage, processData);
    const isStageFulfilled = getIsStageFulfilled(currentStageData);
    if (
      !isStageFulfilled &&
      !(currentStageData.isSelfApproveContinue || currentStageData.approvals.length === 0)
    ) {
      return;
    }
    const isLastStage = currentStage === totalStages;
    currentStageData.stageFulfilled = true;
    currentStageData.cartRows = cartRows;
    if (!isLastStage) {
      initializeStage(process);
    } else {
      finishProcess(process, (status) => (processData.processStatus = status));
      return;
    }
    const currentStageDataUpdated = getCurrentStageData(
      process.processData.currentStage,
      process.processData
    );
    const skipNextStage =
      currentStageDataUpdated.isSelfApproveContinue ||
      currentStageDataUpdated.approvals.length === 0;
    if (skipNextStage && !isLastStage) {
      groomProcess(process);
    }
  };

  const getWitnesses = () => {
    return getDB('settingsWitnesses/')
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.response.map(({ _id, location, userSelected }) => ({
          _id,
          location,
          userSelected
        }));
        return filtered;
      })
      .catch((error) => console.log(error));
  };

  const getAssetSpecialists = () => {
    return getDB('settingsAssetSpecialists/')
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.response.map(({ _id, location, userSelected, categorySelected }) => ({
          _id,
          location,
          userSelected,
          categorySelected
        }));
        return filtered;
      })
      .catch((error) => console.log(error));
  };

  const updateDecommissionedAssetsInfo = (assetId, decommissionAssets) => {
    // Deassign decommissioned asset to employee
    getDBComplex({
      collection: 'employees',
      condition: [{ "assetsAssigned": { "$elemMatch": { "id":  assetId } } }]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response && !isEmpty(data.response)) {
          const { _id: employeeId, assetsAssigned } = data.response[0];

          const index = assetsAssigned.findIndex(({ id }) => id === assetId);
          assetsAssigned.splice(index, 1);

          updateDB('employees/', { assetsAssigned }, employeeId)
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));

    if (decommissionAssets) {
      updateManyDB({
        body: { status: 'decommissioned' },
        collection: 'assets',
        condition: [{ "parent": assetId }]
      });
    } else {
      // Deassign Assets
      updateManyDB({
        body: { parent: '' },
        collection: 'assets',
        condition: [{ "parent": assetId }]
      });
    }
  };

  const getBossInfo = () => {
    return getOneDB('user/', user?.id)
      .then((response) => response.json())
      .then((data) => {
        const { selectedBoss = {} } = data.response;
        if (!selectedBoss || Object.keys(selectedBoss).length <= 0) {
          return undefined;
        }
        const { value: _id, label: email, name, lastName } = selectedBoss;

        return { _id, email, name, lastName };
      })
      .catch((error) => console.log(error));
  };

  const getLocationManagerInfo = (locationId) => {
    return getOneDB('locationsReal/', locationId)
      .then((response) => response.json())
      .then((data) => {
        const { assignedTo = {} } = data.response;
        if (!assignedTo || Object.keys(assignedTo).length <= 0) {
          return undefined;
        }
        const { userId: _id, email, name, lastName } = assignedTo;
        return { _id, email, name, lastName };
      })
      .catch((error) => console.log(error));
  };

  const sendMessages = (
    stageData,
    requestUser,
    liveProcessId,
    processId,
    localProcessInfo,
    stageMoment,
    stageEdgeCase = null
  ) => {
    // Notifications are simple messages, Approvals are simple messages + ProcessApprovals DB posting
    const { dateFormatted, rawDate: formatDate, timeFormatted } = getCurrentDateTime();
    const {
      stageId,
      stageName,
      notifications: stageNotifications,
      approvals: stageApprovals,
      cartRows: stageCartRows
    } = stageData;
    const thisProcessData = processes.find(({ _id }) => _id === processId);
    const {
      validMessages: { notifications, approvals }
    } = thisProcessData;
    const processNotifications = notifications[stageId] || [];
    const processApprovals = approvals[stageId] || [];

    const isStageRejected = (stageCartRows || []).some(({ status }) =>
      ['Rejected', 'rejected'].includes(status)
    );

    const getVariableValues = {
      stageName: () => {
        return stageName || 'N/A';
      },
      creator: () => {
        const { name, lastName, email } = requestUser;
        return `${name} ${lastName} (${email})` || 'N/A';
      },
      creationDate: () => {
        return localProcessInfo.creationDate?.split('T')[0] || 'N/A';
      },
      approvals: () => {
        const approvalsFormated = stageApprovals.map(
          ({ name, lastName, email }) => `${name} ${lastName} (${email})`
        );
        return !isEmpty(approvalsFormated) ? approvalsFormated.join(', ') : 'N/A';
      },
      notifications: () => {
        const notificationsFormated = stageNotifications.map(
          ({ name, lastName, email }) => `${name} ${lastName} (${email})`
        );
        return !isEmpty(notificationsFormated) ? notificationsFormated.join(', ') : 'N/A';
      },
      status: () => {
        if (stageMoment === 'start') {
          return pendingApprovalMessage;
        } else {
          if (isStageRejected) {
            return stageRejectedMessage;
          } else {
            return stageApprovedMessage;
          }
        }
      },
      folio: () => {
        return localProcessInfo?.folio;
      },
      approvalsInfo: () => {
        const titles = [
          { label: 'User', value: 'user' },
          { label: 'Email', value: 'email' },
          { label: 'Fulfilled', value: 'fulfilled' },
          { label: 'FulFilled Date', value: 'fulfillDate' }
        ];
        const header = titles.map(
          ({ label }) =>
            `<th style="padding: 10px; text-align: center; border: 1px solid black;">${label}</th>`
        );
        const rows = stageApprovals.map((approval) => {
          const row = titles.map(
            ({ value }) =>
              `<td style="padding: 10px; border: 1px solid black;"> ${value === 'user'
                ? `${approval?.name} ${approval?.lastName}`
                : approval[value] || 'N/A'
              } </td>`
          );
          return `<tr style="text-align: center;">${row.join('')}</tr>`;
        });

        const defaultNoApprovalMessage = `<tr><td colspan=${titles.length} style="padding: 10px; border: 1px solid black; text-align:center;" >This stage has no approvals </td></tr>`;

        const table = `<table><tr style="text-align: center;">${header.join('')}</tr> ${!isEmpty(rows) ? rows.join('') : defaultNoApprovalMessage
          }</table>`;

        return table;
      },
      assetsInvolved: () => {
        const headTitles = {
          creation: [
            { label: 'Name', value: 'name' },
            { label: 'Brand', value: 'brand' },
            { label: 'Model', value: 'model' },
            { label: 'Final Location', value: 'locationName' },
            { label: 'Purchase Date', value: 'purchase_date' },
            { label: 'Purchase Price', value: 'purchase_price' },
            { label: 'Serial Number', value: 'serial' },
            { label: 'Notes', value: 'notes' },
            { label: 'Quantity', value: 'quantity' }
          ],
          movement: [
            { label: 'Name', value: 'name' },
            { label: 'Brand', value: 'brand' },
            { label: 'Model', value: 'model' },
            { label: 'EPC', value: 'EPC' },
            { label: 'Original Location', value: 'originalLocation' },
            { label: 'Final Location', value: 'locationName' },
            { label: 'Serial Number', value: 'serial' }
          ],
          short: [
            { label: 'Name', value: 'name' },
            { label: 'Brand', value: 'brand' },
            { label: 'Model', value: 'model' },
            { label: 'EPC', value: 'EPC' },
            { label: 'Original Location', value: 'originalLocation' },
            { label: 'Final Location', value: 'locationName' },
            { label: 'Serial Number', value: 'serial' }
          ],
          asset: [
            { label: 'Name', value: 'name' },
            { label: 'EPC', value: 'EPC' },
            { label: 'Brand', value: 'brand' },
            { label: 'Model', value: 'model' },
            { label: 'Location', value: 'locationPath' },
            { label: 'Serial Number', value: 'serial' }
          ]
        };

        if (isEmpty(processInfo)) {
          const processType = ['creation', 'movement', 'short'].includes(selectedProcessType)
            ? selectedProcessType
            : 'asset';
          const header = headTitles[processType].map(
            ({ label }) =>
              `<th style="padding: 10px; text-align: center; border: 1px solid black;">${label}</th>`
          );
          const rows = cartRows.map((asset) => {
            const row = headTitles[processType].map(
              ({ value }) =>
                `<td style="padding: 10px; border: 1px solid black;"> ${asset[value] ||
                'N/A'} </td>`
            );
            return `<tr style="text-align: center;">${row.join('')}</tr>`;
          });
          const table = `<table><tr style="text-align: center;">${header.join('')}</tr> ${rows.join(
            ''
          )}</table>`;
          return table;
        } else {
          const processType = ['creation', 'movement'].includes(
            processInfo.processData.selectedProcessType
          )
            ? processInfo.processData.selectedProcessType
            : 'asset';

          const header = headTitles[processType].map(
            ({ label }) =>
              `<th style="padding: 10px; text-align: center; border: 1px solid black;">${label}</th>`
          );
          const rows = (!isEmpty(stageCartRows) ? stageCartRows : cartRows).map((asset) => {
            const row = headTitles[processType].map(
              ({ value }) =>
                `<td style="padding: 10px; border: 1px solid black;"> ${asset[value] ||
                'N/A'} </td>`
            );
            return `<tr style="text-align: center;">${row.join('')}</tr>`;
          });
          const table = `<table><tr style="text-align: center;">${header.join('')}</tr> ${rows.join(
            ''
          )}</table>`;
          return table;
        }
      }
    };

    const getCustomFieldValues = () => {
      let filteredCustomFields = {};
      Object.values(stageData.customFieldsTab || {}).forEach((tab) => {
        const allCustomFields = [...tab.left, ...tab.right];
        allCustomFields.map((field) => {
          filteredCustomFields = { ...filteredCustomFields, ...extractCustomFieldValues(field) };
        });
      });

      return filteredCustomFields;
    };

    const formatHTML = (html) => {
      const variables = getVariables(html);
      let offsetVar = 0;
      const allStageCustomFields = getCustomFieldValues();

      variables.forEach(({ varName, start, end }) => {
        let htmlArr = html.split('');
        let variableContent;

        if (getVariableValues[varName]) {
          variableContent = getVariableValues[varName]();
        } else {
          variableContent = allStageCustomFields[varName] || 'N/A';
        }

        if (variableContent) {
          htmlArr.splice(start - offsetVar, end - start + 1, variableContent);
          offsetVar += varName.length - variableContent.length + 3;
        }
        html = htmlArr.join('');
      });

      return html;
    };

    const filteredProcessMessages = (message) => {
      let filteredMessages = [];
      Object.entries(message).map(([userId, val]) => {
        val.reduce((acu, cur) => {
          const { checked, id: layoutId, name: layoutName, sendMessageAt } = cur;
          const notificationObj = { layoutId, layoutName, sendMessageAt };
          if (checked) {
            filteredMessages.push({ ...notificationObj, userId });
          }
        }, []);
      });
      return filteredMessages;
    };

    const sendStageMessages = (messages, type) => {
      const isNotification = type === 'notification';
      const messagesType = isNotification ? stageNotifications : stageApprovals;

      const getLayoutInfo = (thisLayoutInfo) => {
        if (thisLayoutInfo) {
          const { layout = '', rejectLayout = '', goBackLayout = '' } = thisLayoutInfo;

          if (stageEdgeCase === 'goBack') {
            return goBackLayout;
          }

          if (isStageRejected) {
            return rejectLayout;
          } else {
            return layout;
          }
        } else {
          return null;
        }
      };

      messagesType.forEach(async (message) => {
        const foundMessages = [];

        if (message.virtualUser) {
          foundMessages.push(...messages.filter(({ userId }) => userId === message.virtualUser));
        } else if (message.isUserGroup) {
          message.members.forEach((member) => {
            const messagesFound = messages.filter(({ userId }) => userId === message._id);
            messagesFound.forEach((messageFound) => {
              foundMessages.push({ ...messageFound, userId: member.value });
            });
          });
        } else {
          foundMessages.push(...messages.filter(({ userId }) => userId === message._id));
        }

        const timeStamp = `${dateFormatted} ${timeFormatted}`;
        let targetUserInfo = {
          email: message.email,
          id: message._id || message.id,
          lastName: message.lastName,
          name: message.name
        };
        foundMessages.map((foundMessage) => {
          if (message.isUserGroup) {
            const groupUser = message.members.find(({ value }) => value === foundMessage.userId);
            targetUserInfo = {
              email: groupUser.label,
              id: groupUser.value,
              lastName: groupUser.lastName,
              name: groupUser.name
            };
          }
          const layoutId = foundMessage ? foundMessage.layoutId : null;
          const fromObj = pick(requestUser, ['email', 'name', 'lastName']);
          const from = [{ _id: requestUser.id, ...fromObj }];
          const thisLayoutInfo = processLayouts.find(({ id }) => id === layoutId) || null;
          const html = getLayoutInfo(thisLayoutInfo);
          const sendMessageAt = thisLayoutInfo ? thisLayoutInfo.sendMessageAt || '' : null;
          const isSendEmail = thisLayoutInfo ? thisLayoutInfo.email || false : null;
          const newHtml = formatHTML(html);
          const subject = isNotification
            ? `New notification from Stage: ${stageName}`
            : stageMoment === 'start'
              ? `New approval request from Stage: ${stageName}`
              : `The following Stage has been finished: ${stageName}`;

          const messageObj = {
            html: newHtml,
            formatDate,
            from,
            read: false,
            status: `new`,
            subject,
            timeStamp,
            to: [
              {
                _id: targetUserInfo.id,
                email: targetUserInfo.email,
                lastName: targetUserInfo.lastName,
                name: targetUserInfo.name
              }
            ]
          };

          if (html && sendMessageAt === stageMoment) {
            simplePost(collections.messages, messageObj);

            if (isSendEmail) {
              const collection = 'ProcessLive';
              const body = {
                to: targetUserInfo.email,
                html: newHtml,
                subject
              };

              sendEmail(collection, body)
                .then((response) => response.json())
                .then((data) => console.log('mensaje enviado :D'))
                .catch((error) => console.log('Email Error:', error));
            }
          }
        });

        if (isNotification) {
          message.sent = true;
          message.sentDate = timeStamp;
        } else {
          if (stageMoment === 'start') {
            const approvalObj = {
              email: targetUserInfo.email,
              fulfilled: false,
              fulfilledData: '',
              processId: liveProcessId,
              userId: targetUserInfo.id,
              stageId
            };
            if (stageEdgeCase !== 'skipped') {
              simplePost(collections.processApprovals, approvalObj);
            }
          }
        }
      });
    };

    const filteredMessages = [
      {
        message: filteredProcessMessages(processNotifications),
        type: 'notification'
      },
      {
        message: filteredProcessMessages(processApprovals),
        type: 'approval'
      }
    ];
    filteredMessages.forEach(({ message, type }) => sendStageMessages(message, type));
  };

  const inheritCustomFields = (processData, assetCustomFields) => {
    const allCustomFieldsFromStages = [];
    const RightAndLeft = ['right', 'left'];

    processData.stages.map((stage) => {
      Object.keys(stage.customFieldsTab).map((tabName) => {
        RightAndLeft.map((side) => {
          if (stage.customFieldsTab[tabName][side].length) {
            stage.customFieldsTab[tabName][side].map((customField) =>
              allCustomFieldsFromStages.push(customField)
            );
          }
        });
      });
    });

    Object.keys(assetCustomFields).map((tabName) => {
      RightAndLeft.map((side) => {
        assetCustomFields[tabName][side].map((field, ix) => {
          allCustomFieldsFromStages.map((stageField) => {
            if (
              field.content === stageField.content &&
              field.values.fieldName === stageField.values.fieldName
            ) {
              const { values: newValues } = stageField;
              if (
                ['dropDown', 'radioButtons', 'checkboxes', 'decisionBox'].includes(field.content) &&
                !isEqual(field.values.options, newValues.options)
              ) {
                return;
              }

              if (['richText'].includes(field.content) && newValues.initialValue === '<p></p>\n') {
                return;
              }

              if (
                newValues.initialValue ||
                newValues.selectedItem >= 0 ||
                !isEmpty(newValues.selectedOptions) ||
                newValues.fileExt
              ) {
                const newValue = setNewValue(assetCustomFields[tabName][side][ix], stageField);
                assetCustomFields[tabName][side][ix] = newValue;
              }
            }
          });
        });
      });
    });
    return assetCustomFields;
  };

  const getCategory = (referenceID) => {
    return getOneDB('references/', referenceID)
      .then((response) => response.json())
      .then((data) => {
        if (!data.response) {
          return '';
        } else {
          const { selectedProfile } = data.response;
          return selectedProfile || '';
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  const getAllAssets = (validAssets) => {
    const resultValue = validAssets.reduce((accu, asset) => {
      const multipleInstances = Array(parseInt(asset.quantity || 1))
        .fill(0)
        .map(() => asset);
      return [...accu, ...multipleInstances];
    }, []);
    return resultValue;
  };

  const executeAllPolicies = (data) => {
    data.map((asset) => {
      executePolicies('OnAdd', 'assets', 'list', policies, asset);
      executeOnFieldPolicy('OnAdd', 'assets', 'list', policies, asset);
    });
  };

  const finishProcess = (process, updateProcessStatus) => {
    const { processData, decommissionAssets, requestUser } = process;
    const { selectedProcessType } = processData;
    const selfApprove = processData.stages[0]?.isSelfApprove || selectedProcessType === 'short';
    var validAssets = [];
    var validAssetsID = [];
    var invalidAssets = [];
    if (!selfApprove) {
      validAssets = extractValidAssets(cartRows, processData);
      validAssetsID = validAssets.map(({ id }) => id);
      invalidAssets = cartRows.filter(({ id }) => !validAssetsID.includes(id)) || [];
      if (invalidAssets.length <= 0 && validAssets.length > 0) {
        updateProcessStatus('approved');
      } else if (invalidAssets.length > 0 && validAssets.length <= 0) {
        updateProcessStatus('rejected');
      } else if (invalidAssets.length > 0 && validAssets.length > 0) {
        updateProcessStatus('partiallyApproved');
      }
    } else {
      processData.currentStage = processData.totalStages;
      processData.processStatus = 'approved';
      //Fulfill all stages
      processData.stages.map((stage) => {
        const currentStage = stage;
        currentStage.stageFulfilled = true;
        currentStage.stageInitialized = true;
        currentStage.cartRows = cartRows;
        currentStage.approvals = currentStage.approvals.map((approval) => ({
          ...approval,
          fulfilled: 'skipped'
        }));
      });
    }

    const customCreationUserId = requestUser?.id;
    const customCreationUserFullName = `${requestUser?.name} ${requestUser?.lastName}`;

    const assetsToProcess = selfApprove ? getAllAssets(cartRows) : getAllAssets(validAssets);
    const { dateFormatted, timeFormatted } = getCurrentDateTime();

    const finishActions = {
      creation: async () => {
        const helperDoc = 'assetsVirtualEPC';
        const assetsToCreate = assetsToProcess.length || 0;

        getCountDB({ collection: 'assets' })
          .then((response) => response.json())
          .then(({ response }) => {
            const { count } = response;
            const { accepted, rejected } = howManyAssets(count, -1, assetsToCreate);

            if (accepted <= 0 && assetsToCreate > 0) {
              dispatch(
                showCustomAlert({
                  open: true,
                  message: assetLimits,
                  type: 'warning'
                })
              );
              const rejectedMessage = [
                {
                  user: 'System',
                  message:
                    'This asset was not created because the limit of Assets has been reached.'
                }
              ];
              const notCreated = uniqBy(
                assetsToProcess.map((asset) => ({
                  ...asset,
                  status: 'rejected',
                  message: [{ stageName: 'Creation', messages: [...rejectedMessage] }]
                })),
                'id'
              );
              updateDB('processLive/', { created: [], notCreated }, process._id)
                .then((response) => response.json())
                .then((data) => {})
                .catch((error) => console.log('errorUpdating:', error));

              return null;
            } else {
              const finalAssets = assetsToProcess.splice(0, accepted);
              const valueToIncrement = finalAssets.length || 0;
              findAndModifyDB({
                collection: 'helpers',
                query: [{ name: helperDoc }],
                update: { $inc: { value: valueToIncrement } }
              })
                .then((response) => response.json())
                .then((data) => {
                  if (!data.response || isEmpty(data.response)) {
                    const body = {
                      name: helperDoc,
                      value: valueToIncrement
                    };
                    postDB('helpers', body);
                  }

                  let biggestEPC = data?.response?.value || 0;
                  const whiteFolio = [0, 0, 0, 0, 0, 0, 0, 0];

                  Promise.all(
                    finalAssets.map(
                      async (
                        {
                          id: creationAuxId,
                          name,
                          brand,
                          model,
                          locationId: location,
                          referenceId,
                          history = [],
                          customFieldsTab,
                          serial,
                          notes,
                          quantity,
                          purchase_date,
                          purchase_price,
                          price
                        },
                        ix
                      ) => {
                        const locationPath = await getLocationPath(location);
                        const category = await getCategory(referenceId);
                        const customFieldsTabInherited = inheritCustomFields(
                          processData,
                          customFieldsTab
                        );
                        const prefix = whiteFolio.slice(0, 8 - String(biggestEPC + ix + 1).length);
                        const newEPC = prefix.join('').concat(String(biggestEPC + ix + 1));
                        const assetObj = {
                          creationAuxId,
                          name,
                          brand,
                          model,
                          location,
                          locationPath,
                          category,
                          EPC: `VIRTUAL_EPC_${newEPC}`,
                          referenceId,
                          status: 'active',
                          history: [
                            ...history,
                            {
                              processId: process.folio,
                              processName: processData.name,
                              processType: processData.selectedProcessType,
                              label: `Asset Created at: ${locationPath}`,
                              date: `${dateFormatted} ${timeFormatted}`
                            }
                          ],
                          customFieldsTab: customFieldsTabInherited,
                          serial,
                          notes,
                          quantity: 1,
                          purchase_date,
                          purchase_price,
                          price,
                          parent: ''
                        };
                        return assetObj;
                      }
                    )
                  ).then((allObjectsToPost) => {
                    postDB(
                      'assets',
                      allObjectsToPost,
                      customCreationUserId,
                      customCreationUserFullName
                    )
                      .then((response) => response.json())
                      .then((rawData) => {
                        const data = rawData.response;
                        executeAllPolicies(data);
                        const rejectedMessage = [
                          {
                            user: 'System',
                            message:
                              'This asset was not created because the limit of Assets has been reached.'
                          }
                        ];
                        const assetsCreated = data.map((asset) => ({ ...asset, id: asset._id }));
                        const notCreated = uniqBy(
                          assetsToProcess.map((asset) => ({
                            ...asset,
                            status: 'rejected',
                            message: [{ stageName: 'Creation', messages: [...rejectedMessage] }],
                            quantity:
                              asset.quantity -
                              assetsCreated.filter(
                                ({ creationAuxId }) => creationAuxId === asset.id
                              ).length
                          })),
                          'id'
                        );
                        updateDB(
                          'processLive/',
                          { created: assetsCreated, notCreated: [...invalidAssets, ...notCreated] },
                          process._id
                        )
                          .then((response) => response.json())
                          .then((data) => {})
                          .catch((error) => console.log('errorUpdating:', error));

                        if (rejected > 0) {
                          dispatch(
                            showCustomAlert({
                              type: 'warning',
                              open: true,
                              message: `${assetsCreated.length} ${assetsCreated.length > 1
                                  ? `${CapitalizeFirstLetter(assetsLabel)} ${wereLabel}`
                                  : `${CapitalizeFirstLetter(assetsLabel)} ${wasLabel}`
                                } created but ${rejected} ${rejected > 1 ? wereLabel : wasLabel
                                } ${limitReachedAlert}`,
                              duration: 10000
                            })
                          );
                        } else {
                          dispatch(
                            showCustomAlert({
                              type: 'info',
                              open: true,
                              message: `${assetsCreated.length} ${assetsCreatedAlert}`
                            })
                          );
                        }

                        assetsCreated.map(({ _id }) => {
                          updateDB('assets/', { id: _id }, _id)
                            .then((response) => response.json())
                            .then((data) => {})
                            .catch((error) => console.log('errorUpdatingAssets:', error));
                        });
                      })
                      .catch((error) => console.log('AssetCreation:', error));
                  });
                })
                .catch((error) => console.log('F&M Error:', error));
            }
          })
          .catch((error) => console.log('Error:', error));
      },
      decommission: () => {
        console.log({ decommissionAssets })
        if (invalidAssets.length) {
          invalidAssets.forEach(({ id }) => {
            updateDB('assets/', { status: 'active' }, id)
              .then(() => {})
              .catch((error) => console.log(error));
          });
        }
        assetsToProcess.forEach(({ id, history = [] }) => {
          updateDB(
            'assets/',
            {
              status: 'decommissioned',
              history: [
                ...history,
                {
                  processId: process.folio,
                  processName: processData.name,
                  processType: processData.selectedProcessType,
                  label: 'Decommissioned',
                  date: `${dateFormatted} ${timeFormatted}`
                }
              ]
            },
            id
          )
            .then((response) => response.json())
            .then((data) => {
              const asset = data?.response?.value;
              asset.status = 'decommissioned';

              // Begin Children Deassignation/Decommission Process
              updateDecommissionedAssetsInfo(id, decommissionAssets);

              executePolicies(
                'OnDelete',
                'assets',
                'list',
                policies,
                asset
              );
              executeOnFieldPolicy(
                'OnDelete',
                'assets',
                'list',
                policies,
                asset,
                data?.response?.value
              );
            })
            .catch((error) => console.log(error));
        });
        dispatch(
          showCustomAlert({
            type: 'info',
            open: true,
            message: `${assetsToProcess.length} ${decomissionedAlert}`
          })
        );
      },
      movement: async () => {
        if (invalidAssets.length) {
          invalidAssets.forEach(({ id }) => {
            updateDB('assets/', { status: 'active' }, id)
              .then(() => {})
              .catch((error) => console.log(error));
          });
        }
        assetsToProcess.forEach(
          async ({ id, locationId: location, originalLocation, history = [] }) => {
            const locationPath = await getLocationPath(location);
            updateDB(
              'assets/',
              {
                location,
                locationPath,
                status: 'active',
                history: [
                  ...history,
                  {
                    processId: process.folio,
                    processName: processData.name,
                    processType: processData.selectedProcessType,
                    label: `Moved from: ${originalLocation} to: ${locationPath}`,
                    date: `${dateFormatted} ${timeFormatted}`
                  }
                ]
              },
              id
            )
              .then(() => {})
              .catch((error) => console.log(error));
          }
        );
        dispatch(
          showCustomAlert({
            type: 'info',
            open: true,
            message: `${assetsToProcess.length} ${transferedAlert}`
          })
        );
      },
      short: async () => {
        assetsToProcess.forEach(
          async ({ id, locationId: location, originalLocation, history = [] }) => {
            const locationPath = await getLocationPath(location);
            updateDB(
              'assets/',
              {
                location,
                locationPath,
                status: 'active',
                history: [
                  ...history,
                  {
                    processId: process.folio,
                    processName: processData.name,
                    processType: processData.selectedProcessType,
                    label: `Moved from: ${originalLocation} to: ${locationPath}`,
                    date: `${dateFormatted} ${timeFormatted}`
                  }
                ]
              },
              id
            )
              .then(() => {})
              .catch((error) => console.log(error));
          }
        );
        dispatch(
          showCustomAlert({
            type: 'info',
            open: true,
            message: `${assetsToProcess.length} ${transferedAlert}`
          })
        );
      },
      maintenance: () => {
        if (invalidAssets.length) {
          invalidAssets.forEach(({ id, history }) => {
            updateDB(
              'assets/',
              {
                status: 'active',
                history: [
                  ...history,
                  {
                    processId: process.folio,
                    processName: processData.name,
                    processType: processData.selectedProcessType,
                    label: 'Maintenance was cancelled',
                    date: `${dateFormatted} ${timeFormatted}`
                  }
                ]
              },
              id
            )
              .then(() => {})
              .catch((error) => console.log(error));
          });
        }
        assetsToProcess.forEach(({ id, history }) => {
          updateDB(
            'assets/',
            {
              status: 'active',
              history: [
                ...history,
                {
                  processId: process.folio,
                  processName: processData.name,
                  processType: processData.selectedProcessType,
                  label: 'Finished Maintenance',
                  date: `${dateFormatted} ${timeFormatted}`
                }
              ]
            },
            id
          )
            .then(() => {})
            .catch((error) => console.log(error));
        });
        dispatch(
          showCustomAlert({
            type: 'info',
            open: true,
            message: `${assetsToProcess.length} ${finishedMaintenance}`
          })
        );
      },
      updateProcess: () => {
        if (invalidAssets.length) {
          invalidAssets.forEach(({ id }) => {
            updateDB('assets/', { status: 'active' }, id)
              .then(() => {})
              .catch((error) => console.log(error));
          });
        }
        Promise.all(
          assetsToProcess.map((asset) => {
            const assetData = omit(asset, ['_id', 'id']);
            const customFieldsTabInherited = inheritCustomFields(
              processData,
              asset.customFieldsTab
            );
            return updateDB(
              'assets/',
              {
                ...assetData,
                customFieldsTab: customFieldsTabInherited,
                status: 'active',
                history: [
                  ...assetData.history,
                  {
                    processId: process.folio,
                    processName: processData.name,
                    processType: processData.selectedProcessType,
                    label: 'Values Were Updated',
                    date: `${dateFormatted} ${timeFormatted}`
                  }
                ]
              },
              asset.id
            );
          })
        )
          .then((responses) => Promise.all(responses.map((response) => response.json())))
          .then((data) => {
            const assetsUpdated = assetsToProcess.map((asset) => {
              const customFieldsTabInherited = inheritCustomFields(
                processData,
                asset.customFieldsTab
              );
              return {
                ...asset,
                customFieldsTab: customFieldsTabInherited,
                status: 'active',
                history: [
                  ...asset.history,
                  {
                    processId: process.folio,
                    processName: processData.name,
                    processType: processData.selectedProcessType,
                    label: 'Values Were Updated',
                    date: `${dateFormatted} ${timeFormatted}`
                  }
                ]
              };
            });
            updateDB('processLive/', { created: assetsUpdated }, process._id)
              .then((response) => response.json())
              .then((data) => {})
              .catch((error) => console.log('errorUpdating:', error));
          });
        dispatch(
          showCustomAlert({
            type: 'info',
            open: true,
            message: `${assetsToProcess.length} ${assetsModified}`
          })
        );
      },
      default: () => {}
    };

    finishActions[process.updateProcess ? 'updateProcess' : selectedProcessType || 'default']();
  };

  const extractValidAssets = (cartRows, { stages }) => {
    const requestedAssetsIds = cartRows.map(({ id }) => id);
    Object.entries(stages).forEach(([key, { approvals }]) => {
      (approvals || []).forEach(({ cartRows }) => {
        (cartRows || []).forEach(({ id, status }) => {
          if (status !== 'Approved' && requestedAssetsIds.includes(id)) {
            const index = requestedAssetsIds.indexOf(id);
            requestedAssetsIds.splice(index, 1);
          }
        });
      });
    });

    return requestedAssetsIds.map((reqId) => cartRows.find(({ id }) => reqId === id));
  };

  const handleChangeAssetValues = (newCartRows) => {
    setCartRows(newCartRows);
  };

  const stageGoBack = (currentStage) => {
    const { goBackTo } = currentStage;
    var temporalProcessData = { ...processInfo.processData };
    var temporalStages = [...processInfo.processData.stages];
    const stagesToReset = [];
    const goBackIndex = temporalStages.findIndex(({ stageId }) => stageId === goBackTo);
    const { processData, requestUser, _id: liveProcessId } = processInfo;
    const { currentStage: currentStageTemp } = processData;
    const currentStageData = getCurrentStageData(currentStageTemp, processData);

    temporalStages.map((stage, index) => {
      if (processInfo.processData.currentStage - 1 >= index && index >= goBackIndex) {
        stagesToReset.push(stage.stageId);

        if (stage.stageId === goBackTo) {
          temporalProcessData.currentStage = index + 1;
        }

        if (Object.keys(stage.customFieldsTab).length) {
          const thisStageCustomFields = allStages.find(({ id }) => id === stage.stageId);
          stage.customFieldsTab = thisStageCustomFields.customFieldsTab;
        }

        stage.stageFulfilled = false;
        stage.stageInitialized = stage.stageId === goBackTo;
        stage.approvals.map((object) => {
          object.fulfillDate = '';
          object.fulfilled = false;
          delete object.cartRows;
        });
      }
    });

    const condition = [{ processId: processInfo._id }, { stageId: { $in: stagesToReset } }];
    getDBComplex({ collection: 'processApprovals/', condition })
      .then((response) => response.json())
      .then((data) => {
        const approvalsModified = data.response.map(({ _id, stageId }) => {
          if (stageId === goBackTo) {
            return updateDB('processApprovals/', { fulfilled: false }, _id)
              .then(() => {})
              .catch((error) => console.log(error));
          } else {
            return deleteDB('processApprovals/', _id)
              .then((response) => console.log('Deleted', response))
              .catch((error) => console.log('Error', error));
          }
        });

        Promise.all(approvalsModified).then(() => {
          reloadTable();
        });
      })
      .catch((error) => console.log('error>', error));

    temporalProcessData.stages = temporalStages;

    updateDB('processLive/', { processData: temporalProcessData }, id[0])
      .then((data) => data.json())
      .then((response) => {
        sendMessages(
          currentStageData,
          requestUser,
          liveProcessId,
          processData.id,
          processInfo,
          'end',
          'goBack'
        );
        handleCloseModal();
      })
      .catch((error) => showErrorAlert());

    handleCloseModal();
  };

  const handleApprovalsChange = () => {
    const {
      processData: { stages: initialStages }
    } = initialProcessInfo;
    const { processData: initialProcessData } = initialProcessInfo;

    if (initialStages !== modifiedStages) {
      setLoading(true);
      const tempProcessData = {
        ...initialProcessData,
        stages: modifiedStages
      };

      const stageData = [];

      modifiedStages.forEach((stage, index) => {
        stage.approvals.forEach((approval, approvalIndex) => {
          if (approval._id !== initialStages[index].approvals[approvalIndex]._id) {
            console.log(approval._id, initialStages[index].approvals[approvalIndex]._id);
            stageData.push({
              stageId: stage.stageId,
              newApproval: approval._id,
              oldApproval: initialStages[index].approvals[approvalIndex]._id,
              email: approval.email
            });
          }
        });
      });
      console.log(stageData);

      return updateDB('processLive/', { processData: tempProcessData }, id[0])
        .then((response) => response.json())
        .then(() => {
          return Promise.all(
            stageData.map(async (stage, index) => {
              const getStageApprovalId = (processId, stageId, userId) => {
                return getDBComplex({
                  collection: 'processApprovals',
                  condition: [{ processId, stageId, userId, fulfilled: false }]
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data && !isEmpty(data.response)) {
                      return data.response[0]._id;
                    }
                  })
                  .catch((error) => console.log(error));
              };

              const stageApprovalId = await getStageApprovalId(
                id[0],
                stage.stageId,
                stage.oldApproval
              );

              if (!stageApprovalId) {
                return;
              }

              return await updateDB(
                'processApprovals/',
                { email: stage.email, userId: stage.newApproval },
                stageApprovalId
              );
            })
          );
        })
        .catch((error) => console.log(error));
    } else {
      dispatch(
        showCustomAlert({
          type: 'warning',
          open: true,
          message: 'Tiene que cambiar aprobadores'
        })
      );
    }
  };

  const initializeStage = (process) => {
    const { processData, requestUser, _id: liveProcessId } = process;
    const { currentStage, totalStages } = processData;
    const currentStageData = getCurrentStageData(currentStage, processData);
    const skipStage =
      currentStageData?.isSelfApproveContinue || currentStageData?.approvals?.length === 0;
    if (skipStage) {
      sendMessages(currentStageData, requestUser, liveProcessId, processData.id, process, 'end'); // Approvals
      currentStageData.approvals.map((approval) => (approval.fulfilled = 'skipped'));
      reloadTable();
    }
    const nextStage = processData.currentStage + 1;
    processData.currentStage = nextStage;
    const nextStageData = getCurrentStageData(nextStage, processData);
    if (nextStageData.isSelfApproveContinue || nextStageData.approvals.length === 0) {
      sendMessages(
        nextStageData,
        requestUser,
        liveProcessId,
        processData.id,
        process,
        'start',
        'skipped'
      );
      nextStageData.stageInitialized = true;
      nextStageData.stageFulfilled = true;
      const isLastStage = processData.currentStage === totalStages;
      if (isLastStage) {
        sendMessages(nextStageData, requestUser, liveProcessId, processData.id, processInfo, 'end'); // Notifications
      }
      groomProcess(process);
    } else {
      sendMessages(nextStageData, requestUser, liveProcessId, processData.id, process, 'start'); // Approvals
      nextStageData.stageInitialized = true;
    }
  };

  const getIsStageFulfilled = (currentStageData) => {
    return !currentStageData.approvals.some(({ fulfilled }) => !fulfilled);
  };

  const getCurrentStageData = (currentStage, processData) =>
    processData.stages[currentStage - 1] || {};

  const getFileExtension = (file) => {
    if (!file) return '';
    const { type } = file;
    return type.split('/')[1];
  };

  const saveAndReload = (folderName, id) => {
    saveImage(folderName, id);
    reloadTable();
  };

  const getAssetReference = async (referenceId) => {
    return getOneDB('references/', referenceId)
      .then((response) => response.json())
      .then((data) => data.response)
      .catch((error) => dispatch(showErrorAlert()));
  };

  const saveImage = (folderName, id) => {
    if (image) {
      postFILE(folderName, id, image)
        .then((response) => {
          console.log('FIlE uploaded!', response);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleCloseModal = () => {
    setStageTabSelected(0);
    setAssetFinderTab(0);
    setDueDate(undefined);
    setCartRows([]);
    setProcessInfo({});
    setModifiedStages([]);
    setInitialProcessInfo({});
    setCustomFieldsTab({});
    // setProfilePermissions([]);
    setValues({
      selectedProcess: '',
      groupedFolios: []
    });
    setShowModal(false);
    setValue4(0);
    setIsAssetReference(null);
    setCustomTabs([]);
    setUpdateProcessFolios([]);
    setProcessOptions([]);
    // setIsAssetRepository(false);
  };

  const [processes, setProcesses] = useState([]);
  const [processInfo, setProcessInfo] = useState({});
  const [initialProcessInfo, setInitialProcessInfo] = useState({});
  const [modifiedStages, setModifiedStages] = useState([]);
  const [processLayouts, setProcessLayouts] = useState([]);
  const [customFieldsTab, setCustomFieldsTab] = useState([]);
  const [customTabs, setCustomTabs] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [parentLocations, setParentLocations] = useState([]);
  const [updateProcessFolios, setUpdateProcessFolios] = useState([]);
  const [allFolios, setAllFolios] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);

  const isFirstRun = useRef(true);
  const [tableControl, setTableControl] = useState({
    assets: {
      collection: 'assets',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: '',
      locationsFilter: []
    }
  });

  const [control, setControl] = useState({
    idAsset: null,
    openAssetsModal: false,
    openTreeView: false,
    treeViewFiltering: [],
    assetRows: [],
    assetRowsSelected: []
  });

  const loadLocations = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: user?.id,
      flag: false,
      setAllLocations
    });
    setUserLocations(userLocations);
  };

  const createAssetListRow = (
    id,
    name,
    brand,
    model,
    category,
    EPC,
    serial,
    originalLocation,
    history,
    fileExt,
    location,
    referenceId,
    selectedProfile,
    creationDate
  ) => {
    return {
      id,
      name,
      brand,
      model,
      category,
      EPC,
      serial,
      originalLocation,
      history,
      fileExt,
      location,
      referenceId,
      selectedProfile,
      creationDate
    };
  };

  const getLocationsFilter = (collectionName) => {
    if (collectionName === 'assets') {
      return parentLocations;
    } else {
      return null;
    }
  };

  const getTreeViewLocation = (collectionName) => {
    if (collectionName === 'assets') {
      return tableControl.assets.treeViewLocation;
    } else {
      return null;
    }
  };

  const assetListHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.NAME', 'Name')
    },
    {
      id: 'brand',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.BRAND', 'Brand')
    },
    {
      id: 'model',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.MODEL', 'Model')
    },
    {
      id: 'assigned',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.ASSIGNED', 'Assigned')
    },
    {
      id: 'EPC',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.EPC', 'EPC')
    },
    {
      id: 'serial',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.SERIAL', 'Serial Number')
    },
    {
      id: 'originalLocation',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSET.PREVIEW.FINDER.ORIGINAL.LOCATION', 'Original Location')
    },
    {
      id: 'creationDate',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.CREATION.DATE', 'Creation Date')
    }
  ];

  const loadAssetsData = (collectionNames = ['assets']) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      let queryLike = '';
      if (collectionName === 'assets') {
        queryLike = tableControl.assets.searchBy
          ? [{ key: tableControl.assets.searchBy, value: tableControl.assets.search }]
          : ['name', 'brand', 'model'].map((key) => ({ key, value: tableControl.assets.search }));
      }

      const treeViewLocation = getTreeViewLocation(collectionName);

      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null,
        condition: collectionName === 'assets' ? [{ status: 'active' }] : null,
        locationsFilter: getLocationsFilter(collectionName),
        locationsFilterParam: 'location',
        treeViewLocation
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response.count
            }
          }));
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          { key: tableControl[collectionName].orderBy, value: tableControl[collectionName].order }
        ],
        queryLike: tableControl[collectionName].search ? queryLike : null,
        condition: collectionName === 'assets' ? [{ status: 'active' }] : null,
        locationsFilter: getLocationsFilter(collectionName),
        locationsFilterParam: 'location',
        treeViewLocation
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (collectionName === 'assets') {
            const rows = await Promise.all(
              data.response.map(async (row) => {
                const locationPath = await getLocationPath(row.location);
                const reference = await getAssetReference(row.referenceId);

                return createAssetListRow(
                  row._id,
                  row.name,
                  row.brand,
                  row.model,
                  row.assigned,
                  row.EPC,
                  row.serial,
                  locationPath,
                  row.history,
                  row.fileExt,
                  row.location,
                  row.referenceId,
                  reference?.selectedProfile || 'N/A',
                  row.creationDate
                );
              })
            );
            setControl((prev) => ({ ...prev, assetRows: rows, assetRowsSelected: [] }));
          }
        })
        .catch((error) => console.log('error: ', error));
    });
  };

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (values.updateProcess) {
      setIsAssetReference(false);
      getOneDB('processLive/', values.updateProcess)
        .then((response) => response.json())
        .then((data) => {
          //Set CustomFields
          const stages = data.response.processData.stages.filter(
            (stage, index) => index + 1 <= data.response.processData.currentStage
          );
          var customtabs = [];
          var allCustomFields = [];

          stages.map((stage, ix) => {
            const { stageName, customFieldsTab, isCustomLockedStage } = stage;
            const tabs = Object.keys(stage.customFieldsTab).map((key) => ({
              key,
              info: customFieldsTab[key].info,
              content: [customFieldsTab[key].left, customFieldsTab[key].right]
            }));
            tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());

            allCustomFields.push(customFieldsTab);
            customtabs.push({ stage: stageName, tabs, index: ix, isCustomLockedStage });
          });

          setCustomTabs(customtabs);
          setCustomFieldsTab(allCustomFields);
          //Set CartRows
          Promise.all(
            data.response.created.map(async ({ id }) => {
              const assetUpdated = await getUpdatedCartRows(id);

              if (!assetUpdated) {
                dispatch(
                  showCustomAlert({
                    type: 'warning',
                    open: true,
                    message: deletedAlert
                  })
                );

                return null;
              }

              if (assetUpdated.status === 'active') {
                return assetUpdated;
              } else {
                dispatch(
                  showCustomAlert({
                    type: 'warning',
                    open: true,
                    message: statusAlert
                  })
                );
                return null;
              }
            })
          ).then((data) => {
            const filteredData = data.filter((data) => data);
            setCartRows(filteredData || []);
          });
        })
        .catch((error) => console.log(error));
    } else {
      setIsAssetReference(true);
    }
  }, [values.updateProcess]);

  const getUpdatedCartRows = (assetId) => {
    return getOneDB('assets/', assetId)
      .then((response) => response.json())
      .then(async (data) => {
        const locationPath = await getLocationPath(data.response.location);
        const { selectedProfile } = await getAssetReference(data.response.referenceId);
        return {
          ...data.response,
          originalLocation: locationPath,
          id: data.response._id,
          selectedProfile
        };
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {}, [userLocations]);

  useEffect(() => {
    if (!isMount) {
      loadAssetsData('assets');
    }
  }, [
    tableControl.assets.page,
    tableControl.assets.rowsPerPage,
    tableControl.assets.treeViewLocation,
    tableControl.assets.order,
    tableControl.assets.orderBy,
    tableControl.assets.search,
    tableControl.assets.locationsFilter
  ]);

  useEffect(() => {
    if (!showModal) {
      return;
    }
    setGeneralLoading(true);

    const queryLike = [{ key: 'isAssetRepository', value: true }];
    const fields = [{ key: '_id', value: 1 }];

    getDBComplex({ collection: 'locations', queryLike, fields })
      .then((response) => response.json())
      .then((data) => {
        setLocationsAssetRepository(data.response);
      })
      .catch((error) => console.log(error));

    getDB('processes')
      .then((response) => response.json())
      .then((data) => {
        const processes = data.response.map((process) => ({
          ...pick(process, [
            'name',
            'processStages',
            'validMessages',
            'selectedProcessType',
            '_id'
          ]),
          id: process._id
        }));
        setSelectorLoading(false);
        setProcesses(processes);

        //This Formats the options displayed in the select
        // const processOptionsFormated = (processes || []).map(({ id, name, selectedProcessType }, ix) => (
        //   {
        //     value: id,
        //     label: `${name}. Type: ${selectedProcessType}`,
        //     name,
        //     selectedProcessType
        //   }
        // )).map((item) => {
        //   item.label = (
        //     <div className="label">
        //       <Typography>
        //         {`${item.name}.`}
        //       </Typography>
        //       <GreyTypography>
        //         {`Type: ${item.selectedProcessType}`}
        //       </GreyTypography>
        //     </div>
        //   )
        //   return item;
        // });

        const processOptionsFormated = (processes || []).map(
          ({ id, name, selectedProcessType }) => ({
            value: id,
            label: `${name} (${selectedProcessType})`
          })
        );

        setProcessOptions(processOptionsFormated);
      })
      .catch((error) => console.log(error));

    getDB('settingsLayoutsStages')
      .then((response) => response.json())
      .then((data) => {
        const layouts = data.response.map(({ _id, ...rest }) => ({ id: _id, ...rest }));
        setProcessLayouts(layouts);
      })
      .catch((error) => console.log(error));

    if (!id || !Array.isArray(id)) {
      setGeneralLoading(false);
      return;
    }

    getDB('processStages')
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.response.map(({ _id: id, customFieldsTab }) => ({
          id,
          customFieldsTab
        }));
        setAllStages(filtered);
      })
      .catch((error) => console.log(error));

    getOneDB('processLive/', id[0])
      .then((response) => response.json())
      .then((data) => {
        setProcessInfo(data.response);
        setModifiedStages([...data.response?.processData?.stages]);
        setInitialProcessInfo(JSON.parse(JSON.stringify(data.response)));
        const stages = data.response.processData.stages.filter(
          (stage, index) => index + 1 <= data.response.processData.currentStage
        );
        var customtabs = [];
        var allCustomFields = [];

        stages.map((stage, ix) => {
          const { stageName, customFieldsTab, isCustomLockedStage } = stage;

          if (!customFieldsTab) {
            return;
          }

          const tabs = Object.keys(customFieldsTab).map((key) => ({
            key,
            info: customFieldsTab[key].info,
            content: [customFieldsTab[key].left, customFieldsTab[key].right]
          }));
          tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());

          allCustomFields.push(customFieldsTab);
          customtabs.push({ stage: stageName, tabs, index: ix, isCustomLockedStage });
        });

        const currentStageIndex = data.response.processData.currentStage;
        if (currentStageIndex - 1 <= 0) {
          if (data.response.updateProcess) {
            const cartRowsProcessed = data.response.cartRows.map((element) =>
              omit({ ...element, id: element._id }, 'status')
            );
            setCartRows(cartRowsProcessed);
          } else {
            setCartRows(data.response.cartRows);
          }
        } else if (currentStageIndex - 1 > 0) {
          const stageCartRows = getCurrentStageData(
            currentStageIndex - 1,
            data.response.processData
          ).cartRows?.map((asset) => omit(asset, 'status'));
          setCartRows(stageCartRows || data.response.cartRows);
        }
        setCustomTabs(customtabs);
        setCustomFieldsTab(allCustomFields);
        setGeneralLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id, showModal]);

  const [image, setImage] = useState(null);
  const [assetsSelected, setAssetsSelected] = useState([]);
  const [cartRows, setCartRows] = useState([]);
  const [isAssetReference, setIsAssetReference] = useState(null);
  const [selectedProcessType, setSelectedProcessType] = useState('');
  const [selectorLoading, setSelectorLoading] = useState(true);

  const onSelectionChange = (selection) => {
    if (!selection) {
      return;
    }
    setAssetsSelected(selection.rows || []);
  };

  const onAddAssetToCart = () => {
    const toBeAdded = assetsSelected.map((assetSelected) => {
      const found = cartRows.find(({ id }) => id === assetSelected.id);
      if (found) {
        dispatch(
          showCustomAlert({
            type: 'warning',
            open: true,
            message: `"${found.name}" ${alreadyAddedAlert}`
          })
        );
      } else {
        return assetSelected;
      }
    });

    if (toBeAdded.includes(undefined)) {
      return;
    }

    if (isEmpty(assetsSelected)) {
      dispatch(
        showCustomAlert({
          type: 'info',
          open: true,
          message: noAddedAlert
        })
      );
    } else {
      dispatch(
        showCustomAlert({
          type: 'info',
          open: true,
          message: `${assetsSelected.length} ${addedAlert}`
        })
      );
    }
    setCartRows([...cartRows, ...assetsSelected]);
  };

  const renderTabs = () => {
    const tabs = (children) => (
      <Tabs
        value={value4}
        onChange={handleChange4}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {children}
      </Tabs>
    );
    const generateTabs = (tabs) =>
      tabs.map((tab, ix) => <Tab label={tab} key={`tab-key-${tab}-${ix}`} />);

    if (!id) {
      return tabs(
        generateTabs([
          GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.GENERAL', 'General'),
          GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.TABLE', 'Table')
        ])
      );
    } else {
      return tabs(
        generateTabs(
          customTabs.length > 0
            ? [
              GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.LIVE.PROCESS', 'Live Process'),
              GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS', 'Custom Fields')
            ]
            : [GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.LIVE.PROCESS', 'Live Process')]
        )
      );
    }
  };

  const disableCustomField = () => {
    const disable =
      customTabs[stageTabSelected].isCustomLockedStage &&
      processInfo.processData.currentStage - 1 !== customTabs[stageTabSelected].index;
    return disable;
  };

  //This component will be used to fix how the Selector options are rendered
  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>{props.children}</components.Option>
      </>
    );
  };

  const renderTabsContent = () => {
    const handleSelectProcessChange = (objectSelected) => {
      setValues((prev) => ({ ...prev, selectedProcess: objectSelected.value }));
      const { selectedProcessType } = processes.find(({ id }) => id === objectSelected.value);
      setSelectedProcessType(selectedProcessType);
      setIsAssetReference(selectedProcessType === 'creation' ? true : false);
      if (selectedProcessType !== 'creation') {
        loadAssetsData('assets');
      }
    };
    const handleFoliosFunctionality = async (objectSelected) => {
      if (toggleButtonClicked === '1') {
        setValues((prev) => ({ ...prev, updateProcess: objectSelected.value }));
      } else if (toggleButtonClicked === '2') {
        if (!objectSelected) {
          setValues((prev) => ({ ...prev, groupedFolios: [] }));
          return;
        }

        const objectProcessed = objectSelected.map(({ label }) => label);
        setValues((prev) => ({ ...prev, groupedFolios: objectProcessed }));

        const condition = [{ folio: { $in: objectProcessed } }];
        const fields = [{ cartRows: 1 }];
        getDBComplex({ collection: 'processLive/', condition, fields })
          .then((response) => response.json())
          .then(async (data) => {
            const referencesProcessed = [];
            const result = await Promise.all(
              data.response.map(async ({ cartRows: processCartrows }) => {
                const test = await Promise.all(
                  processCartrows.map(async ({ referenceId }) => {
                    if (!referencesProcessed.find(({ id }) => referenceId === id)) {
                      const reference = await getAssetReference(referenceId);
                      if (reference) {
                        const {
                          name,
                          brand,
                          model,
                          _id: id,
                          fileExt,
                          customFieldsTab,
                          selectedProfile
                        } = reference;
                        const addToCartRow = {
                          name,
                          brand,
                          model,
                          id: uuidv4()
                            .split('-')
                            .pop(),
                          referenceId: id,
                          fileExt,
                          selectedProfile,
                          customFieldsTab,
                          serial: '',
                          notes: '',
                          quantity: '1',
                          purchase_date: '',
                          purchase_price: '0',
                          price: '0',
                          location: ''
                        };
                        return addToCartRow;
                      }
                    }
                  })
                );
                return test;
              })
            );
            const finalResult = result.flat();
            const referencesFiltered = uniqBy(finalResult || [], 'id');
            setCartRows(referencesFiltered);
          })
          .catch((error) => console.log('error>', error));
      }
    };

    const handleToggleButtonClicked = (event) => {
      setCartRows([]);
      if (event.target.value === '1') {
        setValues((prev) => ({ ...prev, groupedFolios: [] }));
      } else if (event.target.value === '2') {
        setValues((prev) => ({ ...prev, updateProcess: null }));
      }
      setToggleButtonClicked(event.target.value);
    };

    const getValue = () => {
      if (toggleButtonClicked === '1') {
        return updateProcessFolios.find(({ value }) => value === values.updateProcess) || null;
      } else if (toggleButtonClicked === '2') {
        return allFolios.filter(({ label }) => values.groupedFolios.includes(label)) || null;
      }
    };

    const loadOptions = async (searchQuery, prevOptions, { page }) => {
      const queryLike = ['folio'].map((key) => ({ key, value: searchQuery }));
      const numberOfOptions = 10;

      const count = await getCountDB({
        collection: 'processLive',
        condition:
          toggleButtonClicked === '1'
            ? [
              { 'processData.selectedProcessType': 'creation' },
              { 'processData.processStatus': 'approved' }
            ]
            : [{ 'processData.selectedProcessType': 'creation' }],
        queryLike: searchQuery || !isEmpty(searchQuery) ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => data?.response?.count);

      console.log(prevOptions, count);

      const data = await getDBComplex({
        collection: 'processLive',
        condition: [{ 'processData.selectedProcessType': 'creation' }],
        fields: [
          { key: 'processData.name', value: 1 },
          { key: '_id', value: 1 },
          { key: 'folio', value: 1 },
          { key: 'processData.processStatus', value: 1 }
        ],
        limit: numberOfOptions,
        queryLike: searchQuery || !isEmpty(searchQuery) ? queryLike : null,
        sort: [{ key: 'folio', value: 1 }],
        skip: (page - 1) * numberOfOptions
      })
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.response.map(({ processData, folio, _id: id }) => ({
            name: processData.name,
            folio,
            id,
            status: processData.processStatus
          }));

          if (toggleButtonClicked === '1') {
            const folios = filteredData
              .filter(({ status }) => status === 'approved')
              .map(({ folio, id }) => ({ label: folio, value: id }));
            setUpdateProcessFolios((prev) => uniqBy([...prev, ...folios], 'value'));

            return folios;
          } else if (toggleButtonClicked === '2') {
            const folios = filteredData.map(({ folio, id }) => ({ label: folio, value: id }));
            setAllFolios((prev) => uniqBy([...prev, ...folios], 'value'));

            return folios;
          }
        })
        .catch((error) => {
          dispatch(showErrorAlert());
          console.log(error);
        });

      return {
        options: data,
        hasMore: prevOptions.length < count,
        additional: {
          page: searchQuery ? 2 : page + 1
        }
      };
    };

    const defaultGroupingDescription =
      'If the **Group** option is active, selecting one or multiple Folios will bring all of the assets references involved in those processes, linking the processes together and generating new assets.';
    const defaultUpdateDescription =
      'If the **Update** option is active, selecting a Folio will bring all of the assets created in that process and their info will be updated at the end of the current process.';

    if (!id) {
      return (
        <SwipeableViews
          axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value4}
          onChangeIndex={handleChangeIndex4}
          disabled={preventScroll}
        >
          <TabContainer4 dir={theme4.direction}>
            <div
              id="wrapper1"
              style={{ display: 'flex', flexDirection: 'column', minHeight: '300px' }}
            >
              <div id="wrapper2" className={classes.selectButton}>
                <FormControl className={classes.textField}>
                  <div
                    style={{
                      width: '100%',
                      marginRight: '5%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography style={{ marginBottom: '10px' }} htmlFor="age-simple">
                      {GetTranslatedValue(
                        'PROCESSES.LIVE.MODAL.CAPTION.SELECT.PROCESS',
                        'Select Process'
                      )}
                    </Typography>
                    {/* 
                      // This select will be used in the future to render more info in the Select Options
                      <Select
                        className={classes.select}
                        onChange={handleSelectProcessChange}
                        value={selectedProcessValue}
                        options = {processOptions}
                        isLoading={selectorLoading}
                        onInputChange = {(value) => {
                          console.log('Value:', value, allProcessOptions, allProcessOptions.filter(({name}) => name.includes(value)));
                          setProcessOptions(allProcessOptions.filter(({name}) => name.includes(value)))
                        }}
                        maxMenuHeight='250px'
                        components={{ Option }}
                      /> */}
                    <Select
                      className={classes.processSelect}
                      isLoading={selectorLoading}
                      isDisabled={values.selectedProcess ? true : false}
                      maxMenuHeight="250px"
                      onChange={handleSelectProcessChange}
                      options={processOptions || []}
                      value={processOptions.find(({ value }) => value === values.selectedProcess)}
                      placeholder={GetTranslatedValue(
                        'GENERAL.CAPTION.SELECT.PLACEHOLDER',
                        'Select...'
                      )}
                    />
                  </div>
                </FormControl>
                {values.selectedProcess &&
                  processes.find(({ id }) => id === values.selectedProcess)?.processStages[0]
                    ?.isControlDueDate && (
                    <TextField
                      label={'Due Date'}
                      style={{
                        width: '200px',
                        marginBottom: '10px',
                        alignSelf: 'center'
                      }}
                      type="date"
                      inputProps={{
                        min: new Date().toISOString().split('T')[0]
                      }}
                      value={dueDate}
                      onChange={(event) => setDueDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                <button
                  type="button"
                  onClick={onAddAssetToCart}
                  disabled={values.updateProcess}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  <i className="la la-plus" />{' '}
                  {GetTranslatedValue('PROCESSES.LIVE.MODAL.CAPTION.ADD.ASSET', 'Add Assets')}
                </button>
              </div>
              {values.selectedProcess &&
                processes.find(({ id }) => id === values.selectedProcess)?.selectedProcessType ===
                'creation' && (
                  <div className={classes.buttonWrapper}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '10px',
                        alignItems: 'center'
                      }}
                    >
                      <CustomizedToolTip
                        tooltipContent={
                          <ul style={{ marginTop: '15px', marginRight: '20px' }}>
                            <li style={{ marginTop: '10px' }}>{`${GetTranslatedValue(
                              'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.UPDATE.TITLE',
                              'Update Process'
                            )} (${GetTranslatedValue(
                              'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.UPDATE',
                              '(Update)'
                            )}):`}</li>
                            <p style={{ marginTop: '5px' }}>
                              {GetTranslatedValue(
                                'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.UPDATE.DESCRIPTION',
                                defaultUpdateDescription
                              )}
                            </p>

                            <li>{`${GetTranslatedValue(
                              'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.GROUPING.TITLE',
                              'Grouping Folios'
                            )} (${GetTranslatedValue(
                              'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.GROUPING',
                              '(Grouping)'
                            )}):`}</li>
                            <p style={{ marginTop: '5px' }}>
                              {GetTranslatedValue(
                                'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.GROUPING.DESCRIPTION',
                                defaultGroupingDescription
                              )}
                            </p>
                          </ul>
                        }
                        content={
                          <HelpIcon
                            style={{
                              marginRight: '10px',
                              marginTop: '15px',
                              cursor: 'pointer',
                              fill: '#8e8e8e'
                            }}
                            fontSize="medium"
                          />
                        }
                      />

                      <ButtonGroup
                        toggle
                        onClick={handleToggleButtonClicked}
                        style={{ width: '220px', maxHeight: '60px', marginTop: '15px' }}
                      >
                        <ToggleButton
                          style={{ maxWidth: '110px' }}
                          type="radio"
                          name="radio"
                          checked={toggleButtonClicked === '1'}
                          value="1"
                        >
                          {GetTranslatedValue(
                            'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.UPDATE',
                            'Update'
                          )}
                        </ToggleButton>
                        <ToggleButton
                          style={{ maxWidth: '110px' }}
                          type="radio"
                          name="radio"
                          checked={toggleButtonClicked === '2'}
                          value="2"
                        >
                          {GetTranslatedValue(
                            'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.GROUPING',
                            'Grouping'
                          )}
                        </ToggleButton>
                      </ButtonGroup>
                    </div>

                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      className={classes.select}
                    >
                      <InputLabel htmlFor="age-simple">
                        {toggleButtonClicked === '1'
                          ? GetTranslatedValue(
                            'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.UPDATE.TITLE',
                            'Update Process'
                          )
                          : GetTranslatedValue(
                            'PROCESSES.LIVE.MODAL.CAPTION.FOLIO.GROUPING.TITLE',
                            'Grouping Folio'
                          )}
                      </InputLabel>
                      <AsyncPaginate
                        key={`async-paginate-${toggleButtonClicked}`}
                        additional={{
                          page: 1
                        }}
                        placeholder={GetTranslatedValue(
                          'GENERAL.CAPTION.SELECT.PLACEHOLDER',
                          'Select...'
                        )}
                        onChange={handleFoliosFunctionality}
                        value={getValue()}
                        maxMenuHeight="250px"
                        isMulti={toggleButtonClicked === '2'}
                        loadOptions={loadOptions}
                      />
                    </div>
                    {/* 
                    // This select will be used in the future to render more info in the Select Options
                    <InputLabel htmlFor="age-simple">Grouping Folios</InputLabel>
                    <Select
                      className={classes.select}
                      onChange={handleSelectGroupingFolios}
                      value={allFolios.find(({value}) => value === values.updateProcess)}
                      options = {allFolios}
                      onInputChange = {(value) => console.log('Que es: ', value )}
                      isLoading={selectorLoading}
                      isMulti
                      maxMenuHeight='250px'
                      // components={{ Option }}
                    /> */}
                  </div>
                )}
              {values.updateProcess && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '60px'
                  }}
                >
                  <Typography variant="h5">
                    When a process is selected to be updated, you can't add more assets
                  </Typography>
                </div>
              )}
              {isAssetReference !== null && !values.updateProcess && (
                <div style={{ width: '100%' }}>
                  <Paper className={classes4.subTab} style={{ width: '100%' }}>
                    <Tabs
                      value={assetFinderTab}
                      onChange={(_, value) => handleChangeAssetFinder(value)}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      <Tab
                        label={GetTranslatedValue(
                          'PROCESSES.LIVE.MODAL.TAB.FIND.BY.NAME',
                          'Find By Name'
                        )}
                      />
                      {!isAssetReference && (
                        <Tab
                          label={GetTranslatedValue(
                            'PROCESSES.LIVE.MODAL.TAB.FIND.BY.LOCATION',
                            'Find By Location'
                          )}
                        />
                      )}
                    </Tabs>
                  </Paper>
                  <SwipeableViews
                    axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={assetFinderTab}
                    onChangeIndex={handleChangeAssetFinder}
                    disabled={isAssetReference || preventScroll}
                  >
                    <Typography component="div" dir={theme4.direction} style={{ padding: 8 * 3 }}>
                      <AssetFinderPreview
                        paginated
                        setPreventScroll={setPreventScroll}
                        isAssetReference={isAssetReference}
                        onSelectionChange={onSelectionChange}
                        userLocations={userLocations}
                        parentLocations={parentLocations}
                      />
                    </Typography>
                    <Typography component="div" dir={theme4.direction} style={{ padding: 8 * 3 }}>
                      <TableComponent2
                        controlValues={tableControl.assets}
                        customModule={'processes'}
                        disableActions
                        disableSearchBy
                        justTreeView
                        userLocations={userLocations}
                        headRows={assetListHeadRows}
                        locationControl={(locationId) => {
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              treeViewLocation: locationId
                            }
                          }));
                        }}
                        onAdd={() => {}}
                        onDelete={() => {}}
                        onEdit={() => {}}
                        onSelect={(object) => onSelectionChange({ rows: object })}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.assetRows}
                        returnObjectOnSelect
                        selectedObjects={assetsSelected.rows || []}
                        searchControl={({ value, field }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              search: value,
                              searchBy: field
                            }
                          }));
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            assets: {
                              ...prev.assets,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        title={GetTranslatedValue(
                          'ASSET.PREVIEW.FINDER.PREVIEW.FIND.BY.LOCATION',
                          'Assets Filter By Location'
                        )}
                        treeView
                      />
                    </Typography>
                  </SwipeableViews>
                </div>
              )}
            </div>
          </TabContainer4>
          <TabContainer4 dir={theme4.direction}>
            {isAssetReference !== null && (
              <AssetFinderPreview
                setPreventScroll={setPreventScroll}
                isAssetReference={isAssetReference}
                isSelectionTable={true}
                onSelectionChange={onSelectionChange}
                rows={cartRows}
                onSetRows={setCartRows}
                processType={selectedProcessType}
                updateAssetValues={(newCartRows) => handleChangeAssetValues(newCartRows)}
                isLinkedToProcess={!!values.updateProcess}
                userLocations={userLocations}
              />
            )}
          </TabContainer4>
        </SwipeableViews>
      );
    } else if (!generalLoading) {
      return (
        <SwipeableViews
          axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value4}
          onChangeIndex={handleChangeIndex4}
          disabled={preventScroll}
        >
          <TabContainer4 dir={theme4.direction}>
            <LiveProcessTab
              goBackLogic={stageGoBack}
              onSelectionChange={onSelectionChange}
              onSetRows={setCartRows}
              processInfo={processInfo}
              processType={
                processInfo.processData
                  ? processInfo.processData.selectedProcessType
                  : selectedProcessType
              }
              rows={cartRows}
              user={user}
              setProcessCartInfo={handleChangeAssetValues}
              readOnly={props.readOnly}
              userLocations={userLocations}
              setPreventScroll={setPreventScroll}
              handleApprovalsChange={() => {
                handleApprovalsChange().finally(() => {
                  setLoading(false);
                  reloadTable();
                  handleCloseModal();
                });
              }}
              modifiedStages={modifiedStages}
              setModifiedStages={setModifiedStages}
            />
          </TabContainer4>
          <TabContainerCustom dir={theme4.direction} padding>
            <PortletHeader
              toolbar={
                <PortletHeaderToolbar>
                  <Tabs
                    className="builder-tabs"
                    component="div"
                    indicatorColor="primary"
                    onChange={handleChangeStageTab}
                    style={{ marginLeft: '20px' }}
                    value={stageTabSelected}
                    variant="scrollable"
                  >
                    {customTabs?.map(({ stage, tabs }, ix) => (
                      <Tab label={stage} key={`custom-tab-${stage}`} />
                    ))}
                  </Tabs>
                </PortletHeaderToolbar>
              }
            />
            <TabContainerCustom dir={theme4.direction} padding>
              {customTabs[stageTabSelected]?.tabs.length > 0 && (
                <PortletHeader
                  toolbar={
                    <PortletHeaderToolbar>
                      <Tabs
                        className="builder-tabs"
                        component="div"
                        onChange={handleChangeCustomFieldTab}
                        style={{ marginLeft: '40px' }}
                        value={customTabSelected}
                        variant="scrollable"
                      >
                        {customTabs[stageTabSelected]?.tabs.map((tab, index) => (
                          <Tab key={`tab-reference-${index}`} label={tab.info.name} />
                        ))}
                      </Tabs>
                    </PortletHeaderToolbar>
                  }
                />
              )}
              <SwipeableViews
                axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={customTabSelected}
                onChangeIndex={handleChangeCustomFieldTab}
              >
                {customTabs[stageTabSelected]?.tabs.map((tab, ix) => (
                  <TabContainer4
                    dir={theme4.direction}
                    paddingTop={false}
                    key={`tabContainer-${ix}`}
                  >
                    <div className="modal-asset-reference">
                      {Array(tab.content[1].length === 0 ? 1 : 2)
                        .fill(0)
                        .map((col, colIndex) => (
                          <div
                            key={`tab-${ix}-col-${colIndex}`}
                            className="modal-asset-reference__list-field"
                            style={{ marginTop: '0px', paddingLeft: '0px', paddingTop: '0px' }}
                          >
                            {tab.content[colIndex].map((customField) => (
                              <CustomFieldsPreview
                                id={customField.id}
                                key={`customField-${customField.id}`}
                                type={customField.content}
                                values={customField.values}
                                onDelete={() => {}}
                                onSelect={() => {}}
                                columnIndex={colIndex}
                                from="form"
                                tab={tab}
                                onUpdateCustomField={handleUpdateCustomFields}
                                // customFieldIndex={props.customFieldIndex}
                                onClick={() => alert(customField.content)}
                                data={tab.content[colIndex]}
                                disabled={
                                  disableCustomField() ||
                                  processInfo.processData?.processStatus !== 'inProcess'
                                }
                                readOnly={props.readOnly}
                                label={customField.label}
                              />
                            ))}
                          </div>
                        ))}
                    </div>
                  </TabContainer4>
                ))}
              </SwipeableViews>
            </TabContainerCustom>
          </TabContainerCustom>
        </SwipeableViews>
      );
    } else {
      return (
        <div
          style={{
            width: '100%',
            minHeight: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <CircularProgressCustom size={40} />
        </div>
      );
    }
  };

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={() =>
        handleSave()
          .then(() => setLoading(false))
          .catch(() => setLoading(false))
      }
      key="Locations-Profile-Modal"
      readOnly={props.readOnly}
      showModal={showModal}
      tabs={renderTabs()}
      title="PROCESSES.LIVE.MODAL.CAPTION"
      extraTitle={id && processInfo?.folio ? `(Folio: ${processInfo.folio})` : ''}
    >
      {renderTabsContent()}
    </CustomRecordModal>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(ModalProcessLive);
