import React from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';

import { GetTranslatedValue } from '../../utils';
import AssetTable from './AssetTable';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%'
  }
}));

export default function OtherModalChildrenTab({
  assetRows,
  assetOpened,
  locationsTreeView,
  onAssetFinderSubmit,
  onDeleteAssetAssigned,
  parentLocations,
  isAssetDecommissioned
}) {
  const classes = useStyles(); console.log(isAssetDecommissioned)

  return (
    <div className={classes.root}>
      {
        !assetOpened.parent ? (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {isAssetDecommissioned && (
            <Typography color="error" variant="h6">
              {GetTranslatedValue('ASSETS.MODAL.TAB.OTHER.CHILDREN.NO.ADD')}
            </Typography>
          )}
          <AssetTable
              assetRows={assetRows}
              assetOpened={assetOpened}
              isAssetDecommissioned={isAssetDecommissioned}
              locationsTreeView={locationsTreeView}
              onAssetFinderSubmit={onAssetFinderSubmit}
              onDeleteAssetAssigned={onDeleteAssetAssigned}
              parentLocations={parentLocations}
            />
          </div>
        ) : (
          <Grid style={{ flex: 1 }} container item direction="column" alignItems="center" justify="center">
            <h6 style={{ alignSelf: 'center' }}>
              {GetTranslatedValue("ASSET.MODAL.ASSIGNMENTS.CANNOT.ADD.CHILDREN", "This asset is a child, it cannot have children")}
            </h6>
          </Grid>
        )
      }
    </div>
  );
}
