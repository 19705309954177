/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { Suspense, useEffect, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { LastLocationProvider } from 'react-router-last-location';
import { useIdleTimer } from 'react-idle-timer';
import { useInterval } from 'react-interval-hook';
import { Routes } from './app/router/Routes';
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from './_metronic';
import { getDB, getCountDB, getOneDB } from './app/crud/api';
import { actions } from './app/store/ducks/general.duck';
import * as designDuck from './app/store/ducks/design.duck';
import * as generalDuck from './app/store/ducks/general.duck';
import { notLogoutRoutes } from './app/pages/home/jsConstants';
import ErrorBoundary from './app/pages/errors/ErrorBounday';

const { REACT_APP_API_SERVER, REACT_APP_API_PORT, REACT_APP_SSL } = process.env;
const trueHost = REACT_APP_SSL ? REACT_APP_SSL : `${REACT_APP_API_SERVER}:${REACT_APP_API_PORT}`;
const App = ({
  appColors,
  store,
  persistor,
  basename,
  user,
  totalMessages,
  totalNotifications
}) => {
  const { setAppColors } = designDuck.actions;
  const { setProcessAlerts } = generalDuck.actions;
  useInterval(
    () => {
      handleTopNavBarCount();
      handleSessionToken();
    },
    10000,
    {
      autoStart: true,
      immediate: true,
      selfCorrecting: true
    }
  );

  const { setUpdateNotifications, setUpdateMessages } = actions;

  const [logoutPreferences, setlogoutPreferences] = useState({
    inactivityPeriod: 600000,
    selectedInactivity: 0
  });

  const handleSessionToken = async () => {
    if (user) {
      const token = localStorage.getItem('accessToken');

      if (token) {
        const decode = parseJwt(token);
        const userTimeStamp = await getOneDB('user/', decode?.id)
          .then((response) => response.json())
          .then((data) => data?.response?.passwordChange)
          .catch((error) => console.log(error));

        if (new Date(userTimeStamp) > new Date(decode.iat*1000)) {
          localStorage.removeItem('accessToken');
          window.location.replace('/logout');
        }
      }
    }
  };

  const parseJwt = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
  };

  const handleIdle = () => {
    const hrefSplitted = window.location.href.split('/');
    if (
      !notLogoutRoutes.includes(hrefSplitted[hrefSplitted.length - 1]) &&
      logoutPreferences.selectedInactivity === 1
    ) {
      window.location.replace('/logout');
    }
  };

  const isLogoutRoute = () => {
    const hrefSplitted = window.location.href.split('/');

    if (!notLogoutRoutes.includes(hrefSplitted[hrefSplitted.length - 1])) {
      return true;
    }

    return false;
  };

  const handleLocalStorageToken = () => {
    const token = localStorage.getItem('accessToken');

    if (!token && isLogoutRoute()) {
      window.location.replace('/logout');
    }
  };

  const handleTopNavBarCount = () => {
    if (user) {
      getCountDB({
        collection: 'messages',
        condition: [{ to: { $elemMatch: { _id: user.id } } }, { status: 'new' }, { read: false }]
      })
        .then((response) => response.json())
        .then(({ response }) => {
          if (response?.count > totalMessages) {
            store.dispatch(setUpdateMessages(true));
          }
        })
        .catch((error) => console.log(error));

      getCountDB({
        collection: 'notifications',
        condition: [{ to: { $elemMatch: { _id: user.id } } }, { read: false }]
      })
        .then((response) => response.json())
        .then(({ response }) => {
          if (response?.count > totalNotifications) {
            store.dispatch(setUpdateNotifications(true));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const verifyUser = async () => {
    if (isLogoutRoute() && user) {
      const userId = user?.id || '';
      const response = await getOneDB('user/', userId)
        .then((response) => response.json())
        .then((data) => {
          return data?.response;
        })
        .catch((error) => console.log(error));

      if (!response || Array.isArray(response)) {
        window.location.replace('/logout');
      }
    }
  };

  const loadSettingsData = () => {
    getDB('settingsGeneral')
      .then((response) => response.json())
      .then((data) => {
        const { inactivityPeriod, selectedInactivity } = data.response[0];
        setlogoutPreferences({ inactivityPeriod, selectedInactivity });
      })
      .catch((error) => console.log('error>', error));

    getDB('settingsDesign')
      .then((response) => response.json())
      .then((data) => {
        const { appName, appColors: dbAppColors, favIconExt } = data.response[0];
        const newAppColors = { ...appColors, ...dbAppColors };
        store.dispatch(setAppColors(newAppColors));

        if (favIconExt) {
          const favIconElement = document.getElementById('favIcon');
          favIconElement.href = `${trueHost}/uploads/settingsDesign/favIcon.${favIconExt}`;
        }

        if (appName) {
          document.title = appName;
        }
      })
      .catch((error) => console.log('error>', error));

    getDB('settingsProcesses')
      .then((response) => response.json())
      .then((data) => {
        store.dispatch(setProcessAlerts(data.response[0].alerts || []));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadSettingsData();
  }, []);

  useEffect(() => {
    verifyUser();
    handleSessionToken();
  }, [user]);

  useIdleTimer({
    timeout: logoutPreferences.inactivityPeriod,
    onActive: () => {},
    onIdle: handleIdle
  });

  useIdleTimer({
    timeout: 300000,
    onActive: () => {},
    onIdle: handleLocalStorageToken
  });

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <ErrorBoundary>
                    <Routes />
                  </ErrorBoundary>
                </I18nProvider>
              </ThemeProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

const mapStateToProps = ({
  auth: { user },
  general: { totalMessages, totalNotifications },
  design: { appColors }
}) => ({
  appColors,
  user,
  totalMessages,
  totalNotifications
});

export default connect(mapStateToProps)(App);
