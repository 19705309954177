import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TreeView from '../../Components/TreeViewComponent';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import SelectIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { actions } from '../../../../store/ducks/general.duck'
import { getDB } from '../../../../crud/api';
import './LocationAssignment.scss';
import { GetTranslatedValue, loadLocationsData } from '../../utils'

const useStyles = makeStyles((theme) => ({
  selectButton: {
    alignSelf: 'flex-start',
    height: '40px',
    marginTop: '20px',
    marginLeft: '10px',
    width: '100px',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'flex-end',
      margin: '0px'
    }
  }
}));

const LocationAssignment = ({ locationsTable, setLocationsTable, userLocations, userID }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showCustomAlert } = actions;
  const [locationsTree, setLocationsTree] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);

  const messages = {
    noAccess: GetTranslatedValue('LOCATIONS.WARNING.NO.ACCESS')
  };

  const handleSelectLocation = (parent, level, realParent, name) => {
    setSelectedLocation({ name, level, parent, realParent });
  };

  const handleLoadLocations = async () => {
    setLocationsTree({});
    const locationsTreeData = await loadLocationsData(userLocations);
    setLocationsTree(locationsTreeData);
  };

  const handleAddSelectedLocation = () => {
    if (!selectedLocation || selectedLocation.level < 0) {
      return;
    } else if (!userLocations.includes(selectedLocation.parent)) {
      dispatch(showCustomAlert({
        message: messages.noAccess,
        open: true,
        type: 'warning'
      }));

      return;
    }

    const found = locationsTable.find((row) => row.parent === selectedLocation.parent);

    if (found) {
      return;
    }

    setLocationsTable([...locationsTable, selectedLocation]);
  };

  const handleDeleteLocationTable = (id) => () => {
    const newLocations = locationsTable.filter((row) => row.parent !== id);
    setLocationsTable(newLocations);
  };

  useEffect(() => {
    handleLoadLocations();
  }, [userID]);

  return (
    <div>
      <h2 className="title">{GetTranslatedValue('USER.LIST.MODAL.LOCATIONS.SUBTITLE')}</h2>
      <div className="location-assignment">
        <div className="location-assignment__tree-section">
          <Button
            aria-label="Select"
            className="location-assignment__select-button"
            color="secondary"
            onClick={handleAddSelectedLocation}
            variant="contained"
            endIcon={<SelectIcon />}
          >
            {GetTranslatedValue('RECORD.BUTTON.SELECT')}
          </Button>
          <TreeView data={locationsTree} onClick={handleSelectLocation} />
        </div>
        <div className="location-assignment__table-section">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{GetTranslatedValue('LOCATIONS.MODAL.CAPTION')}</TableCell>
                <TableCell align="right">{GetTranslatedValue('LOCATIONS.CAPTION.LEVEL')}</TableCell>
                <TableCell align="right">{GetTranslatedValue('POLICIES.CAPTION.ACTION')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationsTable.map((row, ix) => (
                <TableRow key={`row-id-${ix}`}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.level}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDeleteLocationTable(row.parent)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default LocationAssignment;
