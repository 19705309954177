/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty } from 'lodash';
import { Grid, Typography, Tab, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { actions } from '../../../../store/ducks/general.duck';
import { postDB, getOneDB, updateDB, updateManyDB } from '../../../../crud/api';
import CustomFields from '../../Components/CustomFields/CustomFields';
import BaseFields from '../../Components/BaseFields/BaseFields';
import ImageUpload from '../../Components/ImageUpload';
import { GetTranslatedValue } from '../../utils';
import { getFileExtension, saveImage, getImageURL, verifyRepeatedCustomFields } from '../../utils';
import { executeOnFieldPolicy, executePolicies } from '../../Components/Policies/utils';
import CustomRecordModal from '../../Components/CustomRecordModal';
import './ModalAssetCategories.scss';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalAssetCategories = ({
  fields,
  fieldsToValidate,
  readOnly,
  showModal,
  setShowModal,
  reloadTable,
  id,
  policies
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    showCustomAlert,
    showErrorAlert,
    showFillFieldsAlert,
    showSavedAlert,
    showUpdatedAlert
  } = actions;
  const theme4 = useTheme();
  const [tab, setTab] = useState(0);

  const handleChange4 = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex4 = (index) => {
    setTab(index);
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });

  const baseFieldsLocalProps = {
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: {
          readOnly
        }
      }
    },
    depreciation: {
      ownValidFn: () => !!values.depreciation || values.depreciation === 0,
      componentProps: {
        onChange: handleChange('depreciation'),
        type: 'number',
        inputProps: {
          readOnly
        }
      }
    }
  };
  const [values, setValues] = useState({
    name: '',
    depreciation: '0',
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg'
  });

  const updateCategoryNameDB = ({ collection, field, searchField, categoryId, categoryName }) => {
    updateManyDB({
      body: { [field]: { value: categoryId, label: categoryName } },
      collection,
      condition: [{ [searchField]: categoryId }]
    });
  };

  const handleSave = () => {
    setFormValidation({ ...formValidation, enabled: true });
    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (verifyRepeatedCustomFields(customFieldsTab)) {
      const message = intl.formatMessage({
        id: 'WARNING.CUSTOM.FIELDS.REPEATED.NAME',
        defaultMessage: 'Text not rendered'
      });

      dispatch(
        showCustomAlert({
          open: true,
          message,
          type: 'warning'
        })
      );
      return;
    }

    const fileExt = getFileExtension(image);
    const body = { ...values, customFieldsTab, fileExt };
    if (isNew) {
      postDB('categories', body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('categories', _id);
          executePolicies('OnAdd', 'assets', 'categories', policies, response.response[0]);
          executeOnFieldPolicy('OnAdd', 'assets', 'categories', policies, response.response[0]);
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      updateDB('categories/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          const category = { _id: id[0], ...body };

          if (value?.name !== body.name) {
            updateCategoryNameDB({
              collection: 'references',
              field: 'selectedProfile',
              searchField: 'selectedProfile.value',
              categoryId: id[0],
              categoryName: body.name
            });
            updateCategoryNameDB({
              collection: 'assets',
              field: 'category',
              searchField: 'category.value',
              categoryId: id[0],
              categoryName: body.name
            });
            updateCategoryNameDB({
              collection: 'settingsAssetSpecialists',
              field: 'categorySelected',
              searchField: 'categorySelected.value',
              categoryId: id[0],
              categoryName: body.name
            });
          }

          dispatch(showUpdatedAlert());
          saveAndReload('categories', id[0]);
          executePolicies('OnEdit', 'assets', 'categories', policies, category);
          executeOnFieldPolicy('OnEdit', 'assets', 'categories', policies, category, value);
        })
        .catch((error) => dispatch(showErrorAlert()));
    }
    handleCloseModal();
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }
    reloadTable();
  };

  const handleCloseModal = () => {
    setImage(null);
    setCustomFieldsTab({});
    setValues({
      name: '',
      depreciation: '0',
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg'
    });
    setShowModal(false);
    setTab(0);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
  };

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      setIsNew(true);
      return;
    }

    getOneDB('categories/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const { name, depreciation, customFieldsTab, fileExt } = data.response;
        const imageURL = getImageURL(id, 'categories', fileExt);
        const category = { name, depreciation, imageURL };
        setValues(category);
        setCustomFieldsTab(customFieldsTab);
        setIsNew(false);
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [id, showModal]);

  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [isNew, setIsNew] = useState(true);

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Categories-Modal"
      readOnly={readOnly}
      showModal={showModal}
      title="CATEGORIES.MODAL.CAPTION"
      tabs={
        <Tabs
          value={tab}
          onChange={handleChange4}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={GetTranslatedValue('CATEGORIES.MODAL.CAPTION', 'Category')} />
          <Tab label={GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS', 'Custom Fields')} />
        </Tabs>
      }
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tab}
        onChangeIndex={handleChangeIndex4}
        slideStyle={{ overflow: 'hidden' }}
      >
        <TabContainer4 dir={theme4.direction}>
          <Grid className="profile-tab-wrapper">
            <ImageUpload
              disabled={readOnly}
              id="asset-category-image"
              image={values.imageURL}
              setImage={setImage}
              setInitialImage={setInitialImage}
            >
              {GetTranslatedValue('CATEGORIES.MODAL.PHOTO', 'Asset Category Photo')}
            </ImageUpload>
            <div className="profile-tab-wrapper__content">
              <BaseFields
                catalogue={'categories'}
                collection={'categories'}
                fields={fields}
                fieldsToValidate={fieldsToValidate}
                formState={[formValidation, setFormValidation]}
                localProps={baseFieldsLocalProps}
                values={values}
              />
            </div>
          </Grid>
        </TabContainer4>
        <TabContainer4 dir={theme4.direction}>
          <CustomFields
            customFieldsTab={customFieldsTab}
            setCustomFieldsTab={setCustomFieldsTab}
            readOnly={readOnly}
          />
        </TabContainer4>
      </SwipeableViews>
    </CustomRecordModal>
  );
};

export default ModalAssetCategories;
