/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { actions } from '../../../../store/ducks/general.duck';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../partials/content/Portlet';
import { deleteDB, getDB, getDBComplex } from '../../../../crud/api';
import { useStyles } from './styles';
import { GenerateSubTabs } from '../../Components/Translations/tabsTitles';
import TableComponent from '../../Components/TableComponent';
import ModalAssetsSpecialists from './modals/ModalAssetsSpecialists';
import ModalWitnesses from './modals/ModalWitnesses';
import { GetTranslatedValue, loadLocationsData, loadUserLocations} from '../../utils';
import { isEmpty } from 'lodash';

const collections = {
  settingsAssetSpecialists: {
    id: 'idAssetSpecialist',
    modal: 'openAssetSpecialistModal',
    name: 'settingsAssetSpecialists'
  },
  settingsWitnesses: {
    id: 'idWitnesses',
    modal: 'openWitnessesModal',
    name: 'settingsWitnesses'
  }
};
const createAssetSpecialistRow = (id, category, user, location, creator, creationDate) => {
  return { id, category, user, location, creator, creationDate };
};
const createWitnessesRow = (id, description, user, location, creator, creationDate) => {
  return { id, description, user, location, creator, creationDate };
};

const Users = (props) => {
  const { showDeletedAlert } = actions;
  const dispatch = useDispatch();
  const [control, setControl] = useState({
    idAssetSpecialist: null,
    openAssetSpecialistModal: false,
    assetSpecialistRows: null,
    assetSpecialistRowsSelected: [],
    //
    idWitnesses: null,
    openWitnessesModal: false,
    witnessesRows: null,
    witnessesRowsSelected: []
  });
  const [tab, setTab] = useState(0);
  const [locationsTree, setLocationsTree] = useState({});
  const [parentLocations, setParentLocations] = useState([]);
  const [userLocations, setUserLocations] = useState([]);

  const witnessesHeadRows = [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME')
    },
    {
      id: 'user',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.USER')
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.LOCATION')
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR')
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    }
  ];
  const specialistsHeadRows = [
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.CATEGORY')
    },
    {
      id: 'user',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.USER')
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.LOCATION')
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR')
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    }
  ];

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];

    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        id.forEach((_id) => {
          deleteDB(`${collection.name}/`, _id)
            .then((response) => {
              loadInitData(collection.name);
              dispatch(showDeletedAlert());
            })
            .catch((error) => console.log('Error', error));
        });
      },
      onSelect(id) {
        if (collectionName === 'references') {
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const loadInitData = (
    collectionNames = ['settingsAssetSpecialists', 'settingsWitnesses', 'locationsReal']
  ) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      getDBComplex({
        collection: collectionName,
        locationsFilter: collectionName !== 'locationsReal' ? parentLocations : null,
        locationsFilterParam: collectionName !== 'locationsReal' ? 'location.locationSelected' : null,
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (collectionName === 'settingsAssetSpecialists') {
            const rows = data.response.map((row) => {
              const {
                _id,
                categorySelected: { label: category },
                userSelected: { label: user },
                location: { locationName }
              } = row;

              return createAssetSpecialistRow(
                _id,
                category,
                user,
                locationName,
                row.creationUserFullName,
                row.creationDate
              );
            });
            setControl((prev) => ({
              ...prev,
              assetSpecialistRows: rows,
              assetSpecialistRowsSelected: []
            }));
          } else if (collectionName === 'settingsWitnesses') {
            const rows = data.response.map((row) => {
              const {
                _id,
                description,
                userSelected: { label: user },
                location: { locationName }
              } = row;

              return createWitnessesRow(
                _id,
                description,
                user,
                locationName,
                row.creationUserFullName,
                row.creationDate
              );
            });
            setControl((prev) => ({ ...prev, witnessesRows: rows, witnessesRowsSelected: [] }));
          } else if (collectionName === 'locationsReal') {
            const userLocations = await loadUserLocations({
              setParentLocations,
              userId: props.currentUser?.id,
            });

            const locationsTreeData = await loadLocationsData(userLocations || []);
          
            setLocationsTree(locationsTreeData);
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };

  const loadLocations = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: props.currentUser?.id,
    });
    setUserLocations(userLocations);
  };

  useEffect(() => {
    loadLocations();
  }, [])

  useEffect(() => {
    if (!isEmpty(userLocations)) {
      loadInitData();
    }
  }, [userLocations]);

  return (
    <div>
      <Portlet>
        <PortletHeader
          toolbar={
            <PortletHeaderToolbar>
              <Tabs
                component="div"
                className="builder-tabs"
                value={tab}
                onChange={(_, nextTab) => {
                  setTab(nextTab);
                }}
              >
                {GenerateSubTabs('settings', 'Users')}
              </Tabs>
            </PortletHeaderToolbar>
          }
        />
        {tab === 0 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <ModalAssetsSpecialists
                    employeeProfileRows={[]}
                    id={control.idAssetSpecialist}
                    locationsTree={locationsTree}
                    readOnly={control.settingsAssetSpecialistsReadOnly}
                    reloadTable={() => loadInitData('settingsAssetSpecialists')}
                    showModal={control.openAssetSpecialistModal}
                    setShowModal={(onOff) =>
                      setControl({ ...control, openAssetSpecialistModal: onOff })
                    }
                  />
                  <div className="kt-section__content">
                    <TableComponent
                      title={GetTranslatedValue('TABS.SUBTITLES.SPECIALISTS', 'Specialists')}
                      headRows={specialistsHeadRows}
                      rows={control.assetSpecialistRows}
                      onEdit={tableActions('settingsAssetSpecialists').onEdit}
                      onAdd={tableActions('settingsAssetSpecialists').onAdd}
                      onDelete={tableActions('settingsAssetSpecialists').onDelete}
                      onSelect={tableActions('settingsAssetSpecialists').onSelect}
                      onView={tableActions('settingsAssetSpecialists').onView}
                      permissions={props.permissions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
        {tab === 1 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <ModalWitnesses
                    employeeProfileRows={[]}
                    id={control.idWitnesses}
                    locationsTree={locationsTree}
                    readOnly={control.settingsWitnessesReadOnly}
                    reloadTable={() => loadInitData('settingsWitnesses')}
                    showModal={control.openWitnessesModal}
                    setShowModal={(onOff) => setControl({ ...control, openWitnessesModal: onOff })}
                  />
                  <div className="kt-section__content">
                    <TableComponent
                      title={GetTranslatedValue('TABS.SUBTITLES.WITNESSES', 'Witnesses')}
                      headRows={witnessesHeadRows}
                      rows={control.witnessesRows}
                      onEdit={tableActions('settingsWitnesses').onEdit}
                      onAdd={tableActions('settingsWitnesses').onAdd}
                      onDelete={tableActions('settingsWitnesses').onDelete}
                      onSelect={tableActions('settingsWitnesses').onSelect}
                      onView={tableActions('settingsWitnesses').onView}
                      permissions={props.permissions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
      </Portlet>
    </div>
  );
};

export default Users;
