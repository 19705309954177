import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';
import { GetTranslatedValue } from '../../utils';

const Table = ({
  columns,
  loading,
  pageSize,
  paginated,
  rows,
  setTableRowsInner,
  totalRows,
  updatePagination,
  updateSort,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <div style={{ height: 400, width: '100%' }}>
      {paginated ? (
        <DataGrid
          checkboxSelection
          columns={columns}
          loading={loading}
          onPageChange={(info) => {
            const { page } = info;
            setCurrentPage(page);
            updatePagination(info);
          }}
          onSelectionChange={(newSelection) => setTableRowsInner(newSelection)}
          pagination
          paginationMode="server"
          pageSize={pageSize}
          rowCount={totalRows}
          rows={rows}
          sortingMode="server"
          onSortModelChange={(info) => updateSort(info, currentPage)}
          components={{
            NoRowsOverlay: () => (
              <div height="100%" alignItems="center" justifyContent="center">
                SIN FILAs
              </div>
            ),
            NoResultsOverlay: () => (
              <div height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </div>
            )
          }}
        />
      ) : (
        <DataGrid
          checkboxSelection
          columns={columns}
          loading={loading}
          onSelectionChange={(newSelection) => setTableRowsInner(newSelection)}
          pageSize={pageSize}
          rows={rows}
          components={{
            NoRowsOverlay: () => (
              <div height="100%" alignItems="center" justifyContent="center">
                SIN FILAs
              </div>
            ),
            NoResultsOverlay: () => (
              <div height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </div>
            )
          }}
        />
      )
      }
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  paginated: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  setTableRowsInner: PropTypes.func.isRequired,
  totalRows: PropTypes.number,
  updatePagination: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
};

Table.defaultProps = {
  loading: false,
  pageSize: 5,
  paginated: false,
  totalRows: 0
};

export default Table;
