import React from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import CompressedFileButton from './CompressedFileButton';
import { getFieldCaption, GetTranslatedValue } from '../../../utils';
import { useFieldValidator } from '../../../Components/FieldValidator/hooks';
import CircularProgressCustom from '../../../Components/CircularProgressCustom';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 100%',
    height: '100%',
    marginTop: '24px',
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      maxHeight: '450px'
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '300px'
    }
  },
  field: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: '30px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '33%'
    }
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));

const Headers = ({
  baseFields,
  baseFieldsValues,
  collection,
  loading,
  handleOnChangeHeaderField,
  headers,
  file,
  onFileChange,
  rows,
  show
}) => {
  const classes = useStyles();

  const { allFields, fields } = useFieldValidator(collection);

  const getFieldLabel = (catalogue, { field, key, defaultMessage }) => {
    let realCollection = '';

    if (catalogue === "userProfiles") {
      realCollection = 'userReferences'
    } else if (catalogue === "employeeProfiles") {
      realCollection = 'employeeReferences'
    } else {
      realCollection = catalogue
    }

    return getFieldCaption(allFields, { catalogue: realCollection, field, key }, defaultMessage);
  };

  const TooltipText = ({ name, id }) => (
    <>
      <Typography style={{ fontSize: '1.1rem' }}>
        <b>{GetTranslatedValue('IMPORT.HEADERS.FIELD.NAME.TOOLTIP')} </b> {name}
      </Typography>
      <Typography style={{ fontSize: '1.1rem' }}>
        <b>{GetTranslatedValue('IMPORT.HEADERS.FIELD.EXCEL.TOOLTIP')} </b> {id}
      </Typography>
    </>
  );

  return (
    <>
      {show && (
        <CompressedFileButton
          filename={file?.name}
          key="compressed-file-button-headers"
          tooltipText={GetTranslatedValue('IMPORT.HEADERS.COMPRESSED.INFO')}
          onFileChange={onFileChange('headerTab')}
        />
      )}
      <Grid className={classes.root} container direction="row">
        {loading && (
          <div className={classes.circularProgress}>
            <CircularProgressCustom />
          </div>
        )}
        {!loading &&
          baseFields.map(({ label, value, translation }, index) => {
            const selectedFields = Object.values(
              baseFieldsValues.filter((baseField) => baseField)
            ).map(({ value }) => value);
            const options = headers
              .filter((header) => !selectedFields.includes(header))
              .map((header) => ({ label: header, targetField: header, value: header }));
            const boderColor = '#3F51B5';

            let fieldLabel = '';
            
            if (collection === 'locationsReal') {
              fieldLabel = GetTranslatedValue(translation, translation);
            } else if (allFields) {
              fieldLabel = getFieldLabel(collection, { field: label, key: translation, defaultMessage: translation });
            }

            return (
              <Grid
                className={classes.field}
                container
                direction="row"
                item
                key={`element-${value}`}
              >
                <Tooltip
                  placement="top"
                  title={<TooltipText name={fieldLabel || label} id={label} />}
                  arrow
                >
                  <Typography noWrap style={{ maxWidth: '30%' }}>
                    {isEmpty(fieldLabel) ? label : fieldLabel}
                  </Typography>
                </Tooltip>
                <Select
                  id={`${value}-field`}
                  isClearable
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  onChange={handleOnChangeHeaderField(value)}
                  options={options}
                  value={baseFieldsValues[index]}
                  styles={{
                    container: (styles) => ({
                      ...styles,
                      marginRight: '20px',
                      width: '60%',
                      flexWrap: 'nowrap'
                    }),
                    control: (base, state) => ({
                      ...base,
                      border: 'none',
                      borderBottom: '1px solid lightgray',
                      borderRadius: null,
                      boxShadow: null,
                      '&:focus': { borderBottomColor: boderColor },
                      '&:hover': { borderBottomColor: boderColor }
                    }),
                    indicatorSeparator: () => {}
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default Headers;
