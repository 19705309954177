/* eslint-disable react/no-typos */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState  } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { getDB, getOneDB } from '../../../crud/api';
import { loadUserLocations } from "../utils";

const useStyles = makeStyles({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 400,
  },
  treeDisabledItem: {
    color: 'red'
  }
});

const RecursiveTreeView = ({ 
  customProps, 
  onNodeToggle, 
  onClick, 
  selected,
  data,
  user
}) => {
  const classes = useStyles();
  const [userLocations, setUserLocations] = useState([]);

  const loadInitData = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations: null,
      userId: user?.id
    });
    setUserLocations(userLocations || []);
  };

  useEffect(() => {
    loadInitData();
  }, []);

  const renderTree = (nodes) => {
    const disabled = !userLocations.includes(nodes.id); 
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.name}
        onClick={() => onClick(nodes.id, nodes.profileLevel, nodes.parent, nodes.name, nodes.children)}
        disabled={disabled}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
  )};

  return (
    <TreeView
      {...{ ...customProps, onNodeToggle }}
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected || null}
    >
      {renderTree(data)}
    </TreeView>
  );
}

RecursiveTreeView.PropTypes ={
  customProps: PropTypes.object,
  onNodeToggle: PropTypes.func,
  onClick: PropTypes.object,
  user: PropTypes.object,
  data: PropTypes.object,
}

RecursiveTreeView.defaultProps = {
  customProps: () => {},
  onNodeToggle:  (event, nodes) => {},
  onClick: () => {},
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(RecursiveTreeView);
