/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { omit, isEmpty } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { metronic } from '../../../../../_metronic';
import { actions } from '../../../../store/ducks/general.duck';
import { getDB, postDB, updateDB } from '../../../../crud/api';
import { getFirstDocCollection, GetTranslatedValue } from '../../utils';
import { languages } from '../../constants';
import SaveButton from '../settings-tabs/components/SaveButton';
import { useStyles } from './styles';
import GoogleMaps from '../../Components/GoogleMaps';

const General = (props) => {
  const defaultValues = {
    languages,
    currencies: [
      { id: 'usd', name: GetTranslatedValue('SETTINGS.GENERAL.CAPTION.CURRNECY.AMERICAN.DOLLAR') },
      { id: 'mxn', name: GetTranslatedValue('SETTINGS.GENERAL.CAPTION.CURRNECY.MEXICAN.PESO') }
    ],
    inactivity: [
      { id: 'in0', name: GetTranslatedValue('SETTINGS.GENERAL.CAPTION.INACTIVITY.DO.NOTHING') },
      { id: 'in1', name: GetTranslatedValue('SETTINGS.GENERAL.CAPTION.INACTIVITY.LOGOUT') }
    ],
    selectedLanguage: props.lang,
    selectedCurrency: 1,
    selectedInactivity: 0,
    inactivityPeriod: 60000,
    mapView: {
      lat: 19.432608,
      lng: -99.133209,
      zoom: 6
    }
  };

  const dispatch = useDispatch();
  const { showErrorAlert, showSavedAlert, showUpdatedAlert } = actions;
  const classes = useStyles();
  const [values, setValues] = useState(defaultValues);

  const fields = [
    {
      id: 'languages',
      name: GetTranslatedValue('SETTINGS.GENERAL.CAPTION.LANGUAGE'),
      selected: 'selectedLanguage'
    },
    {
      id: 'currencies',
      name: GetTranslatedValue('GENERAL.CAPTION.CURRENCY'),
      selected: 'selectedCurrency'
    },
    {
      id: 'inactivity',
      name: GetTranslatedValue('SETTINGS.GENERAL.CAPTION.INACTIVITY'),
      selected: 'selectedInactivity'
    }
  ];

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: name === 'inactivityPeriod' ? Number(event.target.value * 60000) : event.target.value
    });
  };

  const handleSave = () => {
    const body = { ...values };
    getFirstDocCollection('settingsGeneral')
      .then((id) => {
        if (!id) {
          postDB('settingsGeneral', body)
            .then((data) => data.json())
            .then((response) => {
              props.setLanguage(values.selectedLanguage);
              dispatch(showSavedAlert());
            })
            .catch((error) => {
              dispatch(showErrorAlert());
            });
        } else {
          updateDB('settingsGeneral/', body, id)
            .then((response) => {
              props.setLanguage(values.selectedLanguage);
              dispatch(showUpdatedAlert());
            })
            .catch((error) => {
              dispatch(showErrorAlert());
            });
        }
      })
      .catch((ex) => {
        dispatch(showErrorAlert());
      });
  };

  const loadProcessesData = (collectionName = 'settingsGeneral') => {
    getDB(collectionName)
      .then((response) => response.json())
      .then((data) => {
        const _values = data.response[0] || {};
        if (!isEmpty(_values)) {
          setValues({
            ...omit(_values, '_id'),
            selectedLanguage: _values.selectedLanguage || props.lang,
            mapView: {
              lat: _values.mapView?.lat || defaultValues.mapView.lat,
              lng: _values.mapView?.lng || defaultValues.mapView.lng,
              zoom: _values.mapView?.zoom || defaultValues.mapView.zoom
            }
          });
        }
      })
      .catch((error) => console.log('error>', error));
  };

  const handleCoordChange = (name) => (event) => {
    const value = Number(event.target.value);

    setValues((prev) => ({
      ...prev,
      mapView: {
        ...prev?.mapView,
        [name]: value
      }
    }));
  };

  const handleZoomChange = (zoom) => {
    setValues((prev) => ({
      ...prev,
      mapView: {
        ...prev.mapView,
        zoom
      }
    }));
  };

  const onCoordsChange = (coords) => {
    let lat = '';
    let lng = '';

    if (Array.isArray(coords)) {
      lat = coords[0].lat;
      lng = coords[0].lng;
    } else {
      lat = coords.lat;
      lng = coords.lng;
    }

    setValues((prev) => ({
      ...prev,
      mapView: {
        ...prev.mapView,
        lat,
        lng
      }
    }));
  };

  useEffect(() => {
    loadProcessesData();
  }, []);

  const readOnly = !props.permissions.includes('edit');
  const center = {
    lat: values.mapView?.lat || defaultValues.mapView.lat,
    lng: values.mapView?.lng || defaultValues.mapView.lng
  };

  return (
    <>
      <div>
        {!readOnly && (
          <div style={{ textAlign: 'end', marginBottom: '15px' }}>
            <SaveButton handleOnClick={handleSave} />
          </div>
        )}
        {fields.map(({ id, name, selected }, ix) => (
          <FormControl key={`base-field-${ix}`} className={classes.textField}>
            <InputLabel htmlFor="age-simple">{name}</InputLabel>
            <Select
              value={values[selected]}
              onChange={handleChange(selected)}
              disabled={!props.permissions.includes('edit')}
            >
              {(values[id] || []).map((opt, ix) => (
                <MenuItem
                  key={`opt-${ix}`}
                  value={selected === 'selectedLanguage' ? languages[ix].lang : ix}
                >
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        {values.selectedInactivity === 1 && (
          <FormControl className={classes.textField}>
            <TextField
              disabled={readOnly}
              inputProps={{
                min: 1
              }}
              label={GetTranslatedValue('SETTINGS.GENERAL.CAPTION.LOGOUT.IDLE')}
              onChange={handleChange('inactivityPeriod')}
              type="number"
              value={Math.trunc(values.inactivityPeriod / 60000)}
            />
          </FormControl>
        )}
      </div>
      <div className={classes.mapContainer}>
        <GoogleMaps
          center={center}
          customProps={{
            center,
            containerStyle: {
              display: 'flex',
              flex: 1,
              height: '500px',
              position: 'relative'
            }
          }}
          edit={!readOnly}
          setZoom={handleZoomChange}
          styleMap={{ width: '100%', height: '500px' }}
          zoom={values.mapView?.zoom || defaultValues.mapView.zoom}
          onCenterChange={onCoordsChange}
          setCoords={onCoordsChange}
        />
        <div className={classes.mapViewInputs}>
          <TextField
            className={classes.mapTextField}
            disabled={readOnly}
            inputProps={{
              max: 85,
              min: -85
            }}
            label={GetTranslatedValue('SETTINGS.GENERAL.CAPTION.LATITUDE', 'Latitude')}
            name="lat"
            onChange={handleCoordChange('lat')}
            type="number"
            value={values.mapView?.lat || defaultValues.mapView.lat}
          />
          <TextField
            className={classes.mapTextField}
            disabled={readOnly}
            inputProps={{
              max: 180,
              min: -180,
            }}
            label={GetTranslatedValue('SETTINGS.GENERAL.CAPTION.LONGITUDE', 'Longitude')}
            name="lng"
            onChange={handleCoordChange('lng')}
            type="number"
            value={values.mapView?.lng || defaultValues.mapView.lng}
          />
          <TextField
            className={classes.mapTextField}
            disabled={readOnly}
            inputProps={{
              max: 22,
              min: 1,
            }}
            label={GetTranslatedValue('SETTINGS.GENERAL.CAPTION.ZOOM', 'Zoom')}
            name="zoom"
            onChange={handleCoordChange('zoom')}
            type="number"
            value={values.mapView?.zoom || defaultValues.mapView.zoom}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });
export default connect(mapStateToProps, metronic.i18n.actions)(General);
