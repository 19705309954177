import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { isEmpty, pick } from 'lodash';
import clsx from 'clsx';
import sanitize from 'sanitize-html';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import {
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../../partials/content/Portlet';
import { actions } from '../../../../../store/ducks/general.duck';
import { getDB, getDBComplex, getOneDB, updateDB, postDB } from '../../../../../crud/api';
import { extractCustomFieldId } from '../../../Reports/reportsHelpers';
import MessageTemplate from '../components/MessageTemplate';
import NotificationTemplate from '../components/NotificationTemplate';
import SendApiTemplate from '../components/SendApiTemplate';
import BaseFieldAccordion from '../components/BaseFieldsAccordion';
import CustomFieldAccordion from '../components/CustomFieldsAccordion';
import { rules } from '../../../constants';
import CustomRecordModal from '../../CustomRecordModal';
import './ModalPolicies.scss';
import { GetTranslatedValue } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  customField: {
    marginLeft: '15px',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      marginTop: '0px',
      width: '100%'
    }
  },
  customFieldTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'justify',
    width: '80px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      width: 'auto'
    }
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formControlLabel: {
    marginLeft: '10px'
  },
  menu: {
    width: 200
  },
  textField: {
    margin: '0px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '90%'
    }
  },
  selectedRuleMargin: {
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      margin: '15px 0px 20px 20px'
    }
  },
  pathLabel: {
    margin: '20px 0px',
    [theme.breakpoints.up('sm')]: {
      margin: '15px 0px 20px 20px'
    }
  },
  ruleTwoField: {
    marginBottom: '0px',
    paddingRight: '20px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      width: '240px'
    }
  },
  ruleThreeField: {
    marginBottom: '0px',
    paddingRight: '20px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '240px'
    }
  }
}));

const ModalPolicies = ({
  id,
  module,
  readOnly,
  reloadTable,
  setPolicies,
  setShowModal,
  showModal,
  baseFields
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const {
    showErrorAlert,
    showCustomAlert,
    showFillFieldsAlert,
    showSavedAlert,
    showSelectValuesAlert,
    showUpdatedAlert
  } = actions;
  const intl = useIntl();
  const [alignment, setAlignment] = useState('');
  const [onFieldAlignment, setOnFieldAlignment] = useState('');
  const [customFields, setCustomFields] = useState([]);
  const [onlyDateCustomFields, setOnlyDateCustomFields] = useState([]);
  const [onlyTextCustomFields, setOnlyTextCustomFields] = useState([]);
  const [selectedCustomFieldTab, setSelectedCustomFieldTab] = useState();
  const [ruleOnePath, setRuleOnePath] = useState('');
  const [ruleThreePath, setRuleThreePath] = useState('');
  const [ruleTwoPath, setRuleTwoPath] = useState('');
  const actionsReader = [
    { value: 'OnAdd', label: 'POLICIES.ACTION.ON.ADD' },
    { value: 'OnEdit', label: 'POLICIES.ACTION.ON.EDIT' },
    { value: 'OnDelete', label: 'POLICIES.ACTION.ON.DELETE' },
    { value: 'OnLoad', label: 'POLICIES.ACTION.ON.LOAD' },
    { value: 'OnField', label: 'POLICIES.ACTION.ON.FIELD' }
  ];
  const modules = [
    { id: 'user', name: 'Users', custom: 'userProfiles' },
    { id: 'employees', name: 'Employees', custom: 'employeeProfiles' },
    { id: 'locations', name: 'Locations', custom: 'locations' },
    { id: 'categories', name: 'Categories', custom: 'categories' },
    { id: 'references', name: 'References', custom: 'categories' },
    { id: 'assets', name: 'Assets', custom: 'categories' },
    { id: 'depreciation', name: 'Depreciation', custom: '' },
    { id: 'processes', name: 'Processes', custom: '' },
    { id: 'inventories', name: 'Inventories', custom: '' }
  ];
  const classes = useStyles();
  const [cursorPosition, setCursorPosition] = useState([0, 0]);
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [onFieldEditor, setOnFieldEditor] = useState(EditorState.createEmpty());
  const [messageFrom, setMessageFrom] = useState([]);
  const [messageTo, setMessageTo] = useState([]);
  const [notificationFrom, setNotificationFrom] = useState([]);
  const [notificationTo, setNotificationTo] = useState([]);
  const [selectedControl, setSelectedControl] = useState(null);
  const [tab, setTab] = useState(0);
  const [onLoadTab, setOnLoadTab] = useState(0);
  const [onFieldTab, setOnFieldTab] = useState(0);
  const [users, setUsers] = useState([]);
  const [values, setValues] = useState({
    apiDisabled: false,
    bodyAPI: '',
    messageDisabled: false,
    messageInternal: false,
    messageMail: false,
    messageNotification: '',
    notificationDisabled: false,
    onLoadDisabled: true,
    onLoadFields: {},
    policyName: '',
    ruleOne: {},
    ruleThree: {},
    ruleTwo: {},
    selectedAction: '',
    selectedCatalogue: '',
    selectedIcon: '',
    selectedOnLoadCategory: {},
    selectedRule: '',
    subjectMessage: '',
    subjectNotification: '',
    token: '',
    tokenEnabled: false,
    tokenOnLoad: '',
    tokenOnLoadEnabled: false,
    urlAPI: '',
    urlOnLoad: ''
  });

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleFieldAlignment = (event, newAlignment) => {
    setOnFieldAlignment(newAlignment);
  };

  const handleCustomFieldInputChange = (customField) => (event) => {
    const text = event.target.value;
    setValues((prev) => ({
      ...prev,
      onLoadFields: {
        ...prev.onLoadFields,
        [customField.id]: text
      }
    }));
  };

  const handleChangeCheck = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const handleChangeName = (name) => (event) => {
    const text = event.target.value;
    setValues({ ...values, [name]: text });

    setSelectedControlAndIndexes(event);
  };

  const handleRuleOneChanges = (name) => (event) => {
    const value = event.target.value;
    setValues((prev) => ({ ...prev, ruleOne: { ...prev.ruleOne, [name]: value } }));
  };

  const handleRuleOneChecks = (name) => (event) => {
    const value = event.target.checked;
    setValues((prev) => ({ ...prev, ruleOne: { ...prev.ruleOne, [name]: value } }));
  };

  const handleRuleField = (rule) => (event) => {
    const text = event.target.value;
    setValues((prev) => ({ ...prev, [rule]: { ...prev[rule], field: text } }));
  };

  const handleRuleValue = (rule) => (event) => {
    const text =
      rule === 'ruleTwo' && !isEmpty(event.target.value)
        ? new Date(event.target.value).toISOString()
        : event.target.value;
    setValues((prev) => ({ ...prev, [rule]: { ...prev[rule], value: text } }));
  };

  const handleClickIcon = (selectedIcon) => {
    setValues({ ...values, selectedIcon });
  };

  const handleOnFieldClickIcon = (onFieldSelectedIcon) => {
    setValues({ ...values, onFieldSelectedIcon });
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };

  const onlyDateCondition = () => {
    return (
      ['ruleOne', 'ruleTwo'].includes(values.selectedRule) &&
      values.selectedAction === 'OnField' &&
      tab === 4 &&
      ['ruleOne', 'ruleTwo'].includes(selectedControl)
    );
  };

  const onlyTextCondition = () => {
    return (
      values.selectedRule === 'ruleThree' &&
      values.selectedAction === 'OnField' &&
      tab === 4 &&
      selectedControl === 'ruleThree'
    );
  };

  const filterCustomFields = () => {
    return onlyDateCondition()
      ? onlyDateCustomFields
      : onlyTextCondition()
        ? onlyTextCustomFields
        : customFields;
  };

  const displayOtherTabs = ['OnLoad', 'OnField'];

  const handleOnChangeValue = (name) => (event) => {
    const {
      target: { value }
    } = event;

    if (name === 'selectedOnLoadCategory') {
      setOnLoadTab(0);
      const { left, right } = value.rawCF['tab-0'];
      const res = [...left, ...right];
      const onlyTextCustomFields = res.filter((e) =>
        ['singleLine', 'multiLine'].includes(e.content)
      );
      setValues((prev) => {
        let inputs = {};
        onlyTextCustomFields.forEach((customField) => {
          if (inputs[customField.id] === null || inputs[customField.id] === undefined) {
            inputs = { ...inputs, [customField.id]: '' };
          }
        });
        return { ...prev, onLoadFields: inputs };
      });
      setSelectedCustomFieldTab(onlyTextCustomFields);
    }

    if (
      name === 'selectedAction' &&
      displayOtherTabs.includes(values.selectedAction) &&
      !displayOtherTabs.includes(value)
    ) {
      setTab(0);
    }

    if (
      (value === 'OnLoad' && values.selectedCatalogue.length) ||
      (name === 'selectedCatalogue' && values.selectedAction === 'OnLoad')
    ) {
      getDB('policies')
        .then((res) => res.json())
        .then(({ response }) => {
          const valid = response.find(
            (policy) =>
              policy.module === module &&
              policy.selectedAction === 'OnLoad' &&
              policy.selectedCatalogue ===
              (name === 'selectedCatalogue' ? value : values.selectedCatalogue)
          );
          if (valid) {
            showCustomWarningMessage('POLICIES.WARNING.ON.LOAD.TARGET');
          }
        })
        .catch((error) => console.log(error));
    }

    if (value === 'OnLoad') {
      setTab(3);
      const lastModuleCatalogue = module === 'assets' ? 'categories' : 'references';
      if (values.selectedCatalogue === lastModuleCatalogue) {
        setValues({ ...values, selectedCatalogue: Object.keys(baseFields)[0], [name]: value });
        return;
      }
    }

    if (value === 'OnField') {
      setTab(4);
      setOnFieldTab(0);
    }

    setValues({ ...values, [name]: value });
  };

  const handleBodyAPI = () => {
    let jsonBodyAPI = '';

    try {
      jsonBodyAPI = JSON.parse(values.bodyAPI);

      if (typeof jsonBodyAPI !== 'object') {
        jsonBodyAPI = JSON.parse('{}');
      }
    } catch (error) {
      jsonBodyAPI = JSON.parse('{}');
    }

    return jsonBodyAPI;
  };

  const verifyOnField = () => {
    if (values.selectedAction === 'OnField') {
      if (!rules.map(({ value }) => value).includes(values.selectedRule)) {
        showCustomWarningMessage('POLICIES.WARNING.SELECT.A.RULE');

        return false;
      }
      if (['ruleThree', 'ruleTwo'].includes(values.selectedRule)) {
        if (!values[values.selectedRule].field || !values[values.selectedRule].value) {
          dispatch(showSelectValuesAlert());

          return false;
        }
      } else {
        if (
          !values.ruleOne.numberOfDays ||
          !(
            values.ruleOne.isInfinite ||
            (values.ruleOne.timesRepeated && !values.ruleOne.isInfinite)
          )
        ) {
          dispatch(showSelectValuesAlert());

          return false;
        }
      }
    }

    return true;
  };

  const showCustomWarningMessage = (message) => {
    const formattedMsg = intl.formatMessage({ id: message, defaultMessage: 'Text Not Rendered' });
    
    dispatch(
      showCustomAlert({
        message: formattedMsg,
        open: true,
        type: 'warning'
      })
    );
  };

  const validateFields = (type, { disabled, from, message, subject, to }) => {
    if (values.selectedAction === 'OnLoad' || disabled) {
      return null;
    } else if (isEmpty(from) || isEmpty(to)) {
      return `POLICIES.WARNING.${type}.RECIEVER`;
    } else if (isEmpty(subject)) {
      return `POLICIES.WARNING.${type}.SUBJECT`;
    } else if (isEmpty(message)) {
      return `POLICIES.WARNING.${type}.BODY`;
    } else {
      return null;
    }
  };

  const handleSave = () => {
    const { policyName, selectedAction, selectedCatalogue } = values;
    
    if (!selectedAction || !selectedCatalogue || !policyName) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (!verifyOnField()) {
      return;
    }

    

    const layout = draftToHtml(convertToRaw(editor.getCurrentContent()));
    const isOnFieldSelected = values.selectedAction === 'OnField';

    const messagesFulfilled = validateFields('MESSAGE', {
      disabled: isOnFieldSelected ? values.onFieldMessageDisabled : values.messageDisabled,
      from: isOnFieldSelected ? values.onFieldMessageFrom : messageFrom,
      message: sanitize(layout, { allowedAttributes: {}, allowedTags: [] }).trim(),
      subject: isOnFieldSelected ? values.onFieldMessageSubject : values.subjectMessage,
      to: isOnFieldSelected ? values.onFieldMessageTo : messageTo
    });

    const notificationsFulfilled = validateFields('NOTIFICATION', {
      disabled: isOnFieldSelected
        ? values.onFieldNotificationDisabled
        : values.notificationDisabled,
      from: isOnFieldSelected ? values.onFieldNotificationFrom : notificationFrom,
      message: isOnFieldSelected ? values.onFieldMessageNotification : values.messageNotification,
      subject: isOnFieldSelected ? values.onFieldNotificationSubject : values.subjectNotification,
      to: isOnFieldSelected ? values.onFieldNotificationTo : notificationTo
    });

    if (messagesFulfilled) {
      showCustomWarningMessage(messagesFulfilled);

      return;
    }

    if (notificationsFulfilled) {
      showCustomWarningMessage(notificationsFulfilled);

      return;
    }

    const onFieldLayout = draftToHtml(convertToRaw(onFieldEditor.getCurrentContent()));
    const jsonBodyAPI = handleBodyAPI();

    const body = {
      ...values,
      bodyAPI: JSON.stringify(jsonBodyAPI, null, 2),
      messageFrom,
      messageTo,
      layout,
      notificationFrom,
      notificationTo,
      module,
      onFieldLayout
    };

    if (!id) {
      postDB('policies', body)
        .then((data) => data.json())
        .then((response) => {
          const { _id } = response.response[0];
          dispatch(showSavedAlert());
          saveAndReload('policies', _id);
          getDB('policies')
            .then((response) => response.json())
            .then((data) => setPolicies(data.response))
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          console.log('Error:', error)
          dispatch(showErrorAlert())
        });
    } else {
      updateDB('policies/', body, id[0])
        .then((response) => {
          dispatch(showUpdatedAlert());
          saveAndReload('policies', id[0]);
          getDB('policies')
            .then((response) => response.json())
            .then((data) => setPolicies(data.response))
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          console.log('Error:', error)
          dispatch(showErrorAlert())
        });
    }
    handleCloseModal();
  };

  const getDateCustomField = (varId) => {
    let selectedCustomField;

    onlyDateCustomFields.forEach(({ name, rawCF }) => {
      Object.entries(rawCF || {}).forEach((tab) => {
        const values = [...tab[1].left, ...tab[1].right];
        const found = values.find(({ id }) => id === varId);
        if (found) {
          selectedCustomField = { name, found };
        }
      });
    });

    return selectedCustomField;
  };

  const insertVariable = (varId) => {
    if (readOnly) {
      return;
    }
    if (selectedControl === 'htmlMessage') {
      const contentState = Modifier.replaceText(
        editor.getCurrentContent(),
        editor.getSelection(),
        `%{${varId}}`,
        editor.getCurrentInlineStyle()
      );
      setEditor(EditorState.push(editor, contentState, 'insert-characters'));
    } else if (selectedControl === 'onFieldHtmlMessage') {
      const contentState = Modifier.replaceText(
        onFieldEditor.getCurrentContent(),
        onFieldEditor.getSelection(),
        `%{${varId}}`,
        onFieldEditor.getCurrentInlineStyle()
      );
      setOnFieldEditor(EditorState.push(onFieldEditor, contentState, 'insert-characters'));
    } else if (rules.map(({ value }) => value).includes(selectedControl)) {
      var regex = /^[0-9a-f]{12}/i;

      if (['ruleOne', 'ruleTwo'].includes(selectedControl)) {
        if (regex.test(varId)) {
          const selectedCustomField = getDateCustomField(varId);

          if (selectedCustomField?.found?.content !== 'date') {
            showCustomWarningMessage('POLICIES.WARNING.SELECT.DATE.TYPE');
            return;
          } else {
            if (selectedControl === 'ruleOne') {
              setRuleOnePath(
                `${selectedCustomField?.name}/${selectedCustomField?.found.values?.fieldName ||
                selectedCustomField?.found.content}`
              );
            } else {
              setRuleTwoPath(
                `${selectedCustomField?.name}/${selectedCustomField?.found.values?.fieldName ||
                selectedCustomField?.found.content}`
              );
            }
          }
        }
      }

      if (selectedControl === 'ruleThree') {
        let selectedCustomField;

        if (regex.test(varId)) {
          onlyTextCustomFields.forEach(({ name, rawCF }) => {
            Object.entries(rawCF || {}).forEach((tab) => {
              const values = [...tab[1].left, ...tab[1].right];
              const found = values.find(({ id }) => id === varId);
              if (found) {
                selectedCustomField = { name, found };
              }
            });
          });
          if (!textCustomFields.includes(selectedCustomField?.found?.content)) {
            showCustomWarningMessage('POLICIES.WARNING.SELECT.TEXT.TYPE');
            return;
          } else {
            setRuleThreePath(
              `${selectedCustomField?.name}/${selectedCustomField?.found.values?.fieldName ||
              selectedCustomField?.found.content}`
            );
          }
        }
      }

      const text = values[selectedControl].field || '';
      const left = text.substr(0, cursorPosition[0]);
      const right = text.substr(cursorPosition[1], text.length);
      const final = `${left}%{${varId}}${right}`;
      setValues((prev) => ({
        ...prev,
        [selectedControl]: { ...prev[selectedControl], field: final }
      }));
    } else {
      try {
        const text = values[selectedControl];
        const left = text.substr(0, cursorPosition[0]);
        const right = text.substr(cursorPosition[1], text.length);
        const final = `${left}%{${varId}}${right}`;
        setValues({ ...values, [selectedControl]: final });
      } catch (error) {
        showCustomWarningMessage('POLICIES.WARNING.FOCUS');
      }
    }
  };

  const onChangeMessageFromTo = (name) => (event, values) => {
    if (name === 'From') {
      setMessageFrom(values);
    } else if (name === 'To') setMessageTo(values);
  };

  const onChangeNotificationFromTo = (name) => (event, values) => {
    if (name === 'From') {
      setNotificationFrom(values);
    } else if (name === 'To') setNotificationTo(values);
  };

  const onChangeOnFieldFromTo = (name, section) => (event, values) => {
    if (name === 'From') {
      if (section === 'messages') setValues((prev) => ({ ...prev, onFieldMessageFrom: values }));
      if (section === 'notifications')
        setValues((prev) => ({ ...prev, onFieldNotificationFrom: values }));
    } else {
      if (section === 'messages') setValues((prev) => ({ ...prev, onFieldMessageTo: values }));
      if (section === 'notifications')
        setValues((prev) => ({ ...prev, onFieldNotificationTo: values }));
    }
  };

  const reset = () => {
    setValues({
      apiDisabled: false,
      bodyAPI: '',
      messageDisabled: false,
      messageInternal: false,
      messageMail: false,
      messageNotification: '',
      notificationDisabled: false,
      onLoadDisabled: true,
      onLoadFields: {},
      policyName: '',
      ruleOne: {},
      ruleThree: {},
      ruleTwo: {},
      selectedAction: '',
      selectedCatalogue: '',
      selectedIcon: '',
      selectedOnLoadCategory: {},
      selectedRule: '',
      subjectMessage: '',
      subjectNotification: '',
      tokenOnLoad: '',
      tokenOnLoadEnabled: false,
      urlAPI: '',
      urlOnLoad: ''
    });
    setMessageFrom([]);
    setMessageTo([]);
    setNotificationFrom([]);
    setNotificationTo([]);
    setEditor(EditorState.createEmpty());
    setOnFieldEditor(EditorState.createEmpty());
    setTab(0);
    setOnLoadTab(0);
    setOnFieldTab(0);
    setSelectedCustomFieldTab();
    setRuleOnePath('');
    setRuleTwoPath('');
    setRuleThreePath('');
  };

  const saveAndReload = (folderName, id) => {
    reloadTable();
  };

  const setSelectedControlAndIndexes = (event) => {
    const {
      target: { selectionStart, selectionEnd, name }
    } = event;
    setSelectedControl(name);
    setCursorPosition([selectionStart, selectionEnd]);
  };

  const customFieldPathText = () => {
    const noPath = GetTranslatedValue('POLICIES.NO.PATH');

    return values.selectedRule === 'ruleOne'
      ? ruleOnePath
      : values.selectedRule === 'ruleTwo'
        ? ruleTwoPath || noPath
        : values.selectedRule === 'ruleThree'
          ? ruleThreePath || noPath
          : noPath;
  };

  useEffect(() => {
    if (!showModal) {
      return;
    }

    getDB('user')
      .then((response) => response.json())
      .then((data) => {
        const users = data.response.map(({ _id, email, lastName, name }) => ({
          _id,
          email,
          lastName,
          name
        }));
        setUsers(users);
      })
      .catch((error) => {
        console.log('Error:', error)
        dispatch(showErrorAlert())
      });

    if (!id || !Array.isArray(id)) {
      return;
    }

    getOneDB('policies/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const {
          layout,
          messageFrom,
          messageTo,
          notificationFrom,
          notificationTo,
          onFieldLayout
        } = data.response;
        let obj = pick(data.response, [
          'apiDisabled',
          'bodyAPI',
          'messageDisabled',
          'messageInternal',
          'messageMail',
          'messageNotification',
          'notificationDisabled',
          'OnFieldApiDisabled',
          'onFieldBodyAPI',
          'onFieldSelectedIcon',
          'onFieldMessageDisabled',
          'onFieldMessageFrom',
          'onFieldMessageInternal',
          'onFieldMessageMail',
          'onFieldMessageNotification',
          'onFieldMessageSubject',
          'onFieldMessageTo',
          'onFieldNotificationDisabled',
          'onFieldNotificationFrom',
          'onFieldNotificationSubject',
          'onFieldNotificationTo',
          'onFieldToken',
          'onFieldTokenEnabled',
          'onFieldUrlAPI',
          'onLoadDisabled',
          'onLoadFields',
          'policyName',
          'ruleOne',
          'ruleTwo',
          'ruleThree',
          'selectedAction',
          'selectedCatalogue',
          'selectedIcon',
          'subjectMessage',
          'subjectNotification',
          'selectedOnLoadCategory',
          'selectedRule',
          'token',
          'tokenDisabled',
          'tokenEnabled',
          'tokenOnLoad',
          'tokenOnLoadEnabled',
          'urlAPI',
          'urlOnLoad'
        ]);

        obj = !obj.apiDisabled ? { ...obj, apiDisabled: false } : obj;

        obj = !obj.token ? { ...obj, token: '' } : obj;

        obj = !obj.bodyAPI ? { ...obj, bodyAPI: '' } : obj;

        obj = !obj.urlAPI ? { ...obj, urlAPI: '' } : obj;

        if (!obj.tokenEnabled && typeof obj.tokenEnabled !== 'boolean') {
          obj.tokenEnabled = false;
          delete obj.tokenDisabled;
        }

        obj =
          !obj.onLoadDisabled && typeof obj.onLoadDisabled !== 'boolean'
            ? { ...obj, onLoadDisabled: true }
            : obj;

        obj = !obj.onLoadFields ? { ...obj, onLoadFields: {} } : obj;

        obj = !obj.urlOnLoad ? { ...obj, urlOnLoad: '' } : obj;

        obj = !obj.selectedOnLoadCategory ? { ...obj, selectedOnLoadCategory: {} } : obj;

        obj =
          !obj.tokenEnabled && typeof obj.tokenEnabled !== 'boolean'
            ? { ...obj, tokenEnabled: false }
            : obj;

        obj = !obj.tokenOnLoad ? { ...obj, tokenOnLoad: '' } : obj;

        obj =
          !obj.tokenOnLoadEnabled && typeof obj.tokenOnLoadEnabled !== 'boolean'
            ? { ...obj, tokenOnLoadEnabled: false }
            : obj;

        obj = !obj.ruleOne ? { ...obj, ruleOne: {} } : obj;

        obj = !obj.ruleTwo ? { ...obj, ruleTwo: {} } : obj;

        obj = !obj.ruleThree ? { ...obj, ruleThree: {} } : obj;

        if (Object.entries(obj.selectedOnLoadCategory).length > 0) {
          // update custom fields
          const currentCustomFields = customFields.find(
            ({ id }) => id === obj.selectedOnLoadCategory.id
          );
          obj = currentCustomFields ? { ...obj, selectedOnLoadCategory: currentCustomFields } : obj;
          const { left, right } = obj.selectedOnLoadCategory.rawCF[`tab-0`];
          const res = [...left, ...right];
          const onlyTextCustomFields = res.filter((e) =>
            ['singleLine', 'multiLine'].includes(e.content)
          );
          setSelectedCustomFieldTab(onlyTextCustomFields);
        }

        if (obj.selectedRule) {
          onlyTextCustomFields.forEach(({ name, rawCF }) => {
            Object.entries(rawCF || {}).forEach((tab) => {
              const values = [...tab[1].left, ...tab[1].right];
              const found = values.find(
                ({ id }) =>
                  id === obj.ruleThree?.field?.substring(2, obj.ruleThree?.field?.length - 1)
              );

              if (found) {
                setRuleThreePath(`${name}/${found.values?.fieldName || found.content}`);
              }
            });
          });
          onlyDateCustomFields.forEach(({ name, rawCF }) => {
            Object.entries(rawCF || {}).forEach((tab) => {
              const values = [...tab[1].left, ...tab[1].right];
              const foundOne = values.find(
                ({ id }) => id === obj.ruleOne?.field?.substring(2, obj.ruleOne?.field?.length - 1)
              );
              const foundTwo = values.find(
                ({ id }) => id === obj.ruleTwo?.field?.substring(2, obj.ruleTwo?.field?.length - 1)
              );

              if (foundOne) {
                setRuleOnePath(`${name}/${foundOne.values?.fieldName || foundOne.content}`);
              }

              if (foundTwo) {
                setRuleTwoPath(`${name}/${foundTwo.values?.fieldName || foundTwo.content}`);
              }
            });
          });
        }

        if (obj.selectedAction === 'OnLoad') setTab(3);
        if (obj.selectedAction === 'OnField') setTab(4);

        const contentBlock = htmlToDraft(layout);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const onFieldContentBlock = htmlToDraft(onFieldLayout || '');
        const onFieldContentState = ContentState.createFromBlockArray(
          onFieldContentBlock.contentBlocks
        );
        setValues(obj);
        setMessageFrom(messageFrom);
        setMessageTo(messageTo);
        setEditor(EditorState.createWithContent(contentState));
        setOnFieldEditor(EditorState.createWithContent(onFieldContentState));
        setNotificationFrom(notificationFrom);
        setNotificationTo(notificationTo);
      })
      .catch((error) => dispatch(showErrorAlert));
  }, [id, showModal]);

  const textCustomFields = [
    'singleLine',
    'multiLine',
    'currency',
    'percentage',
    'email',
    'decimal',
    'richText',
    'url'
  ];

  useEffect(() => {
    const collection = modules.filter(({ id }) => id === module)[0];
    getDBComplex({
      collection: collection?.custom,
      customQuery: JSON.stringify({ customFieldsTab: { $ne: {} } })
    })
      .then((response) => response.json())
      .then((data) => {
        const { response } = data;
        //Get just the CustomFields
        let customFieldNames = {};
        const rowToObjectsCustom = response.map((row) => {
          let filteredCustomFields = {};
          const { customFieldsTab } = row;
          Object.values(customFieldsTab || {}).forEach((tab) => {
            const allCustomFields = [...tab.left, ...tab.right];
            allCustomFields.map((field) => {
              filteredCustomFields = { ...filteredCustomFields, ...extractCustomFieldId(field) };
            });
          });
          const filtered = { [row.name]: filteredCustomFields };
          customFieldNames = { ...customFieldNames, filtered };
          return {
            name: row.name,
            customFields: filteredCustomFields,
            rawCF: row.customFieldsTab,
            id: row._id
          };
        });
        let onlyDateCustomFields = [];
        let onlyTextCustomFields = [];
        rowToObjectsCustom.forEach((customField) => {
          Object.entries(customField.rawCF || {}).forEach((tab) => {
            const values = [...tab[1].left, ...tab[1].right];
            const found = values.find(({ content }) => content === 'date');
            const foundText = values.find(({ content }) => textCustomFields.includes(content));
            if (found) {
              onlyDateCustomFields.push(customField);
            }
            if (foundText) {
              onlyTextCustomFields.push(customField);
            }
          });
        });
        setOnlyDateCustomFields(onlyDateCustomFields);
        setOnlyTextCustomFields(onlyTextCustomFields);
        setCustomFields(
          rowToObjectsCustom.filter(({ customFields }) => Object.keys(customFields).length)
        );
      })
      .catch((error) => {
        console.log('Error:', error)
        dispatch(showErrorAlert())
      });
  }, [module]);

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key={`Modal-Policies-${module}`}
      readOnly={readOnly}
      showModal={showModal}
      title="POLICIES.MODAL.CAPTION"
    >
      <div className="profile-tab-wrapper" style={{ margin: '0' }}>
        <div name="Expansion Panel" style={{ width: '95%', margin: '15px' }}>
          <div className="__container-policies-tab">
            {/* Action and Catalogue */}
            <div className="__container-policies-general-fields">
              <div className="__container-general-panel">
                <TextField
                  className={classes.textField}
                  id="standard-subjectMessage"
                  label={GetTranslatedValue('RECORD.CAPTION.NAME')}
                  margin="normal"
                  name="policyName"
                  onChange={handleChangeName('policyName')}
                  value={values.policyName}
                  inputProps={{ readOnly }}
                />
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="age-simple">
                    {GetTranslatedValue('POLICIES.CAPTION.ACTION')}
                  </InputLabel>
                  <Select
                    onChange={handleOnChangeValue('selectedAction')}
                    value={values.selectedAction}
                    disabled={readOnly}
                  >
                    {actionsReader.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {GetTranslatedValue(label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="age-simple">
                    {GetTranslatedValue('GENERAL.CAPTION.CATALOGUE')}
                  </InputLabel>
                  <Select
                    onChange={handleOnChangeValue('selectedCatalogue')}
                    value={values.selectedCatalogue}
                    disabled={readOnly}
                  >
                    {Object.entries(baseFields).map(([key, value], index) => {
                      if (
                        values.selectedAction === 'OnLoad' &&
                        index === Object.entries(baseFields).length - 1
                      ) {
                        return null;
                      }

                      return (
                        <MenuItem key={key} value={key}>
                          {GetTranslatedValue(value?.translationLabel)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="__container-policies-base-custom-fields">
                <div className="__container-baseandcustom-panel">
                  <div className="__container-basefield">
                    <h5>{GetTranslatedValue('GENERAL.CAPTION.BASE.FIELDS')}</h5>
                    {values.selectedCatalogue ? (
                      <BaseFieldAccordion
                        data={{
                          [values.selectedCatalogue]: baseFields[values.selectedCatalogue]
                        }}
                        onElementClick={insertVariable}
                      />
                    ) : (
                      <div className="__base-fields-accordion__no-info">
                        {GetTranslatedValue('POLICIES.MESSAGE.SELECT.CATALOGUE')}
                      </div>
                    )}
                  </div>
                  <div className="__container-customfield">
                    <h5>{GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS')}</h5>
                    <CustomFieldAccordion
                      data={filterCustomFields()}
                      onElementClick={insertVariable}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="__container-message-notification-api">
              {/* TABS */}
              <div className="__container-policies-tabs">
                <PortletHeader
                  toolbar={
                    <PortletHeaderToolbar>
                      <Tabs
                        className="builder-tabs"
                        indicatorColor="primary"
                        component="div"
                        onChange={(_, nextTab) => setTab(nextTab)}
                        value={tab}
                        variant="scrollable"
                      >
                        <Tab
                          label={GetTranslatedValue('GENERAL.SEND.MESSAGE')}
                          style={{
                            display: displayOtherTabs.includes(values.selectedAction)
                              ? 'none'
                              : null
                          }}
                        />
                        <Tab
                          label={GetTranslatedValue('GENERAL.SEND.NOTIFICATION')}
                          style={{
                            display: displayOtherTabs.includes(values.selectedAction)
                              ? 'none'
                              : null
                          }}
                        />
                        <Tab
                          label={GetTranslatedValue('GENERAL.SEND.API')}
                          style={{
                            display: displayOtherTabs.includes(values.selectedAction)
                              ? 'none'
                              : null
                          }}
                        />
                        <Tab
                          label={GetTranslatedValue('POLICIES.ACTION.ON.LOAD')}
                          style={{
                            display: values.selectedAction !== 'OnLoad' ? 'none' : null
                          }}
                        />
                        <Tab
                          label={GetTranslatedValue('POLICIES.TAB.RULES')}
                          style={{
                            display: values.selectedAction !== 'OnField' ? 'none' : null
                          }}
                        />
                        <Tab
                          label={GetTranslatedValue('POLICIES.TAB.RESULT')}
                          style={{
                            display: values.selectedAction !== 'OnField' ? 'none' : null
                          }}
                        />
                      </Tabs>
                    </PortletHeaderToolbar>
                  }
                />
              </div>
              {/* Send Messages */}
              {tab === 0 && (
                <MessageTemplate
                  disablesOnChange={handleChangeCheck('messageDisabled')}
                  disabledValue={values.messageDisabled}
                  editor={editor}
                  editorStateChange={setEditor}
                  fromOnChange={onChangeMessageFromTo('From')}
                  fromOptions={users}
                  fromValue={messageFrom}
                  internalOnChange={handleChangeCheck('messageInternal')}
                  internalValue={values.messageInternal}
                  key="send-message"
                  mailOnChange={handleChangeCheck('messageMail')}
                  mailValue={values.messageMail}
                  setSelectedControl={() => setSelectedControl('htmlMessage')}
                  subjectName="subjectMessage"
                  subjectOnChange={handleChangeName('subjectMessage')}
                  subjectOnClick={setSelectedControlAndIndexes}
                  subjectValue={values.subjectMessage}
                  toOnChange={onChangeMessageFromTo('To')}
                  toOptions={users}
                  toValue={messageTo}
                  readOnly={readOnly}
                />
              )}
              {/* Send Notification */}
              {tab === 1 && (
                <NotificationTemplate
                  alignment={alignment}
                  disabledValue={values.notificationDisabled}
                  disablesOnChange={handleChangeCheck('notificationDisabled')}
                  handleAlignment={handleAlignment}
                  handleClickIcon={handleClickIcon}
                  key="send-notification"
                  messageOnChange={handleChangeName('messageNotification')}
                  messageValue={values.messageNotification}
                  notificationFromOnChange={onChangeNotificationFromTo('From')}
                  notificationFromOptions={users}
                  notificationFromValue={notificationFrom}
                  notificationToOnChange={onChangeNotificationFromTo('To')}
                  notificationToOptions={users}
                  notificationToValue={notificationTo}
                  selectedIcon={values.selectedIcon}
                  subjectNotificationName="subjectNotification"
                  subjectNotificationOnChange={handleChangeName('subjectNotification')}
                  subjectNotificationOnClick={setSelectedControlAndIndexes}
                  subjectNotificationValue={values.subjectNotification}
                  setSelectedControl={() => setSelectedControl('messageNotification')}
                  readOnly={readOnly}
                />
              )}
              {/* Send API */}
              {tab === 2 && (
                <SendApiTemplate
                  bodyFieldName="bodyAPI"
                  bodyOnChange={handleChangeName('bodyAPI')}
                  bodyValue={values.bodyAPI}
                  disabled={values.apiDisabled}
                  disabledOnChange={handleChangeCheck('apiDisabled')}
                  key="send-api"
                  setSelectedControlAndIndexes={setSelectedControlAndIndexes}
                  tokenEnabled={values.tokenEnabled}
                  tokenEnabledOnChange={handleChangeCheck('tokenEnabled')}
                  tokenOnChange={handleChangeName('token')}
                  tokenValue={values.token}
                  urlFieldName="urlAPI"
                  urlOnChange={handleChangeName('urlAPI')}
                  urlValue={values.urlAPI}
                  readOnly={readOnly}
                />
              )}
              {/* OnLoad Action */}
              {tab === 3 && (
                <PortletBody>
                  <div className="__container-on-load">
                    <div className="url_textField">
                      <TextField
                        className={classes.textField}
                        id="onLoad-URL"
                        label="URL"
                        margin="normal"
                        name="urlOnLoad"
                        onChange={handleChangeName('urlOnLoad')}
                        onClick={setSelectedControlAndIndexes}
                        style={{ marginBottom: '20px' }}
                        value={values.urlOnLoad}
                        inputProps={{ readOnly }}
                      />
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={values.onLoadDisabled}
                            color="primary"
                            onChange={handleChangeCheck('onLoadDisabled')}
                            disabled={readOnly}
                          />
                        }
                        label={GetTranslatedValue('GENERAL.DISABLED')}
                        labelPlacement="start"
                      />
                    </div>
                    <div className="token_textField">
                      <FormControlLabel
                        value="start"
                        classes={{ labelPlacementStart: classes.formControlLabel }}
                        control={
                          <Switch
                            checked={values.tokenOnLoadEnabled}
                            color="primary"
                            onChange={handleChangeCheck('tokenOnLoadEnabled')}
                            disabled={readOnly}
                          />
                        }
                        label="Web Token"
                        labelPlacement="start"
                      />
                      <TextField
                        className={classes.textField}
                        id="onLoad-tokenURL"
                        label="Web Token"
                        margin="normal"
                        multiline
                        onChange={handleChangeName('tokenOnLoad')}
                        style={{ marginBottom: '20px' }}
                        value={values.tokenOnLoad}
                        inputProps={{ readOnly }}
                      />
                    </div>
                    <FormControl
                      className={classes.textField}
                      style={{ marginBottom: '20px', marginTop: '20px', width: '240px' }}
                    >
                      <InputLabel htmlFor="age-simple">
                        {module === 'assets'
                          ? GetTranslatedValue('TABS.TITLES.CATEGORIES')
                          : GetTranslatedValue('TABS.TITLES.REFERENCES')}
                      </InputLabel>
                      <Select
                        onChange={handleOnChangeValue('selectedOnLoadCategory')}
                        renderValue={(selected) => {
                          if (values.selectedOnLoadCategory.name) {
                            return values.selectedOnLoadCategory.name;
                          }

                          return '';
                        }}
                        disabled={readOnly}
                        value={values.selectedOnLoadCategory || ''}
                      >
                        {customFields.map((customField, index) => (
                          <MenuItem key={`${customField.name}-${index}`} value={customField}>
                            {customField?.name?.capitalize()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div
                      className="__on-load--references-tabs"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {Object.entries(values.selectedOnLoadCategory).length > 0 && (
                        <Tabs
                          className="builder-tabs"
                          component="div"
                          indicatorColor="secondary"
                          onChange={(_, nextTab) => {
                            setOnLoadTab(nextTab);
                            const { left, right } = values.selectedOnLoadCategory.rawCF[
                              `tab-${nextTab}`
                            ];
                            const res = [...left, ...right];
                            const onlyTextCustomFields = res.filter((e) =>
                              ['singleLine', 'multiLine'].includes(e.content)
                            );
                            setValues((prev) => {
                              let inputs = prev.onLoadFields;
                              onlyTextCustomFields.forEach((customField) => {
                                if (
                                  inputs[customField.id] === null ||
                                  inputs[customField.id] === undefined
                                ) {
                                  inputs = { ...inputs, [customField.id]: '' };
                                }
                              });
                              return { ...prev, onLoadFields: inputs };
                            });
                            setSelectedCustomFieldTab(onlyTextCustomFields);
                          }}
                          value={onLoadTab}
                          variant="scrollable"
                        >
                          {Object.values(values.selectedOnLoadCategory.rawCF || {}).map(
                            ({ info: { name } }, index) => (
                              <Tab key={`Tab-${index}`} label={name} />
                            )
                          )}
                        </Tabs>
                      )}
                      <Grid
                        container
                        direction="column"
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        {selectedCustomFieldTab &&
                          (selectedCustomFieldTab?.length ? (
                            selectedCustomFieldTab.map((customField) => (
                              <Grid
                                alignItems="baseline"
                                container
                                direction="row"
                                item
                                justify="flex-start"
                                key={customField.id}
                              >
                                <h6 className={classes.customFieldTitle}>
                                  {customField.values?.fieldName || customField.content}
                                </h6>
                                <TextField
                                  className={classes.customField}
                                  id={`TextField-${customField.id}`}
                                  label={GetTranslatedValue('POLICIES.OBJECT.PATH')}
                                  margin="normal"
                                  onChange={handleCustomFieldInputChange(customField)}
                                  value={values.onLoadFields[customField.id] || ''}
                                />
                              </Grid>
                            ))
                          ) : (
                            <h6 style={{ marginTop: '15px' }}>
                              {GetTranslatedValue('POLICIES.MESSAGE.NO.TEXT.CUSTOM.FIELDS')}
                            </h6>
                          ))}
                      </Grid>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 4 && (
                <div className="__on-field-container">
                  <FormControl className={clsx(classes.textField, classes.selectedRuleMargin)}>
                    <InputLabel htmlFor="age-simple">
                      {GetTranslatedValue('POLICIES.SELECTED.RULE')}
                    </InputLabel>
                    <Select
                      defaultValue={values.selectedRule}
                      onChange={handleOnChangeValue('selectedRule')}
                      value={values.selectedRule}
                      disabled={readOnly}
                    >
                      {rules.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {GetTranslatedValue(label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {values.selectedRule && (
                    <Typography
                      className={classes.pathLabel}
                      style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold'
                      }}
                    >
                      {`${GetTranslatedValue('POLICIES.CUSTOM.FIELD.PATH')}: ${customFieldPathText()}`}
                    </Typography>
                  )}
                  {values.selectedRule === 'ruleOne' && (
                    <div className="__on-field-date-cycle-container">
                      <div className="__rules">
                        <div className="__date-cycle-rule">
                          <Typography style={{ fontSize: '1.2rem', marginRight: '8px' }}>
                            {`${GetTranslatedValue('GENERAL.DATE')}:`}
                          </Typography>
                          <TextField
                            className={classes.textField}
                            id="standard-rule-one-field"
                            margin="normal"
                            onChange={handleRuleOneChanges('field')}
                            onClick={() => setSelectedControl('ruleOne')}
                            style={{ width: '120px', marginBottom: '0px' }}
                            value={values.ruleOne?.field}
                            inputProps={{ readOnly }}
                          />
                        </div>
                        <div className="__date-cycle-rule">
                          <Typography style={{ fontSize: '1.2rem', marginRight: '8px' }}>
                            {`${GetTranslatedValue('POLICIES.NUMBER.OF.DAYS')}:`}
                          </Typography>
                          <TextField
                            className={classes.textField}
                            id="standard-rule-one-number-of-days"
                            InputProps={{ inputProps: { min: 1 } }}
                            margin="normal"
                            onChange={handleRuleOneChanges('numberOfDays')}
                            style={{ width: '60px', marginBottom: '0px' }}
                            type="number"
                            value={values.ruleOne?.numberOfDays}
                            inputProps={{ readOnly }}
                          />
                        </div>
                        <div className="__date-cycle-rule">
                          <Typography style={{ fontSize: '1.2rem', marginRight: '8px' }}>
                            {GetTranslatedValue('POLICIES.REPEAT')}
                          </Typography>
                          <TextField
                            className={classes.textField}
                            disabled={values.ruleOne.isInfinite}
                            id="standard-rule-one-times-repeated"
                            InputProps={{ inputProps: { min: 1 } }}
                            margin="normal"
                            onChange={handleRuleOneChanges('timesRepeated')}
                            style={{ width: '60px', marginBottom: '0px' }}
                            type="number"
                            value={values.ruleOne?.timesRepeated}
                            inputProps={{ readOnly }}
                          />
                          <Typography style={{ fontSize: '1.2rem', marginLeft: '8px' }}>
                            {GetTranslatedValue('POLICIES.TIMES')}
                          </Typography>
                        </div>
                        <div style={{ marginBottom: '15px' }} />
                      </div>
                      <Grid className="__on-field-date-cycle-checkboxes" container>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={values.ruleOne.isInfinite || false}
                              onChange={handleRuleOneChecks('isInfinite')}
                              disabled={readOnly}
                            />
                          }
                          label={GetTranslatedValue('POLICIES.INFINITE')}
                          labelPlacement="start"
                          style={{ marginRight: '0px' }}
                          value="start"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={values.ruleOne.includeOriginalDate || false}
                              onChange={handleRuleOneChecks('includeOriginalDate')}
                              disabled={readOnly}
                            />
                          }
                          label={GetTranslatedValue('POLICIES.INCLUDE.ORIGINAL.DATE')}
                          labelPlacement="start"
                          style={{ marginRight: '0px' }}
                          value="start"
                        />
                      </Grid>
                    </div>
                  )}
                  {values.selectedRule === 'ruleTwo' && (
                    <div className={clsx('__rules', '__rule-three')}>
                      <TextField
                        className={clsx(classes.textField, classes.ruleTwoField)}
                        id="standard-rule-two-field"
                        margin="normal"
                        onClick={() => setSelectedControl('ruleTwo')}
                        onChange={handleRuleField('ruleTwo')}
                        value={values.ruleTwo?.field}
                        inputProps={{ readOnly }}
                      />
                      <Typography>
                        {`${GetTranslatedValue('POLICIES.IS.EQUAL.TO')}:`}
                      </Typography>
                      <TextField
                        className={clsx(classes.textField, classes.ruleThreeField)}
                        id="standard-rule-two-value"
                        margin="normal"
                        onChange={handleRuleValue('ruleTwo')}
                        type="date"
                        value={values.ruleTwo?.value?.substring(0, 10)}
                        inputProps={{ readOnly }}
                      />
                    </div>
                  )}
                  {values.selectedRule === 'ruleThree' && (
                    <div className={clsx('__rules', '__rule-three')}>
                      <TextField
                        className={clsx(classes.textField, classes.ruleTwoField)}
                        id="standard-rule-three-field"
                        margin="normal"
                        onClick={() => setSelectedControl('ruleThree')}
                        onChange={handleRuleField('ruleThree')}
                        value={values.ruleThree?.field}
                        inputProps={{ readOnly }}
                      />
                      <Typography>
                        {`${GetTranslatedValue('POLICIES.IS.EQUAL.TO')}:`}
                      </Typography>
                      <TextField
                        className={clsx(classes.textField, classes.ruleThreeField)}
                        id="standard-rule-three-value"
                        margin="normal"
                        multiline
                        onChange={handleRuleValue('ruleThree')}
                        value={values.ruleThree?.value}
                        inputProps={{ readOnly }}
                      />
                    </div>
                  )}
                </div>
              )}
              {tab === 5 && (
                <div className="__on-field-result-tabs">
                  <Tabs
                    className="builder-tabs"
                    component="div"
                    onChange={(_, nextTab) => setOnFieldTab(nextTab)}
                    value={onFieldTab}
                    variant="scrollable"
                  >
                    <Tab label={GetTranslatedValue('GENERAL.SEND.MESSAGE')} />
                    <Tab label={GetTranslatedValue('GENERAL.SEND.NOTIFICATION')} />
                    <Tab label={GetTranslatedValue('GENERAL.SEND.API')} />
                  </Tabs>
                  {onFieldTab === 0 && (
                    <MessageTemplate
                      disablesOnChange={handleChangeCheck('onFieldMessageDisabled')}
                      disabledValue={values.onFieldMessageDisabled}
                      editor={onFieldEditor}
                      editorStateChange={setOnFieldEditor}
                      fromOnChange={onChangeOnFieldFromTo('From', 'messages')}
                      fromOptions={users}
                      fromValue={values.onFieldMessageFrom || []}
                      internalOnChange={handleChangeCheck('onFieldMessageInternal')}
                      internalValue={values.onFieldMessageInternal}
                      key="onField-message"
                      mailOnChange={handleChangeCheck('onFieldMessageMail')}
                      mailValue={values.onFieldMessageMail}
                      setSelectedControl={() => setSelectedControl('onFieldHtmlMessage')}
                      subjectName="onFieldMessageSubject"
                      subjectOnChange={handleChangeName('onFieldMessageSubject')}
                      subjectOnClick={setSelectedControlAndIndexes}
                      subjectValue={values.onFieldMessageSubject}
                      toOnChange={onChangeOnFieldFromTo('To', 'messages')}
                      toOptions={users}
                      toValue={values.onFieldMessageTo || []}
                      readOnly={readOnly}
                    />
                  )}
                  {onFieldTab === 1 && (
                    <NotificationTemplate
                      alignment={onFieldAlignment}
                      disabledValue={values.onFieldNotificationDisabled || false}
                      disablesOnChange={handleChangeCheck('onFieldNotificationDisabled')}
                      handleAlignment={handleFieldAlignment}
                      handleClickIcon={handleOnFieldClickIcon}
                      key="onField-notification"
                      messageOnChange={handleChangeName('onFieldMessageNotification')}
                      messageValue={values.onFieldMessageNotification}
                      notificationFromOnChange={onChangeOnFieldFromTo('From', 'notifications')}
                      notificationFromOptions={users}
                      notificationFromValue={values.onFieldNotificationFrom || []}
                      notificationToOnChange={onChangeOnFieldFromTo('To', 'notifications')}
                      notificationToOptions={users}
                      notificationToValue={values.onFieldNotificationTo || []}
                      selectedIcon={values.onFieldSelectedIcon}
                      subjectNotificationName="onFieldNotificationSubject"
                      subjectNotificationOnChange={handleChangeName('onFieldNotificationSubject')}
                      subjectNotificationOnClick={setSelectedControlAndIndexes}
                      subjectNotificationValue={values.onFieldNotificationSubject}
                      setSelectedControl={() => setSelectedControl('onFieldMessageNotification')}
                      readOnly={readOnly}
                    />
                  )}
                  {onFieldTab === 2 && (
                    <SendApiTemplate
                      bodyFieldName="onFieldBodyAPI"
                      bodyOnChange={handleChangeName('onFieldBodyAPI')}
                      bodyValue={values.onFieldBodyAPI || ''}
                      disabled={values.OnFieldApiDisabled || false}
                      disabledOnChange={handleChangeCheck('OnFieldApiDisabled')}
                      key="onField-api"
                      setSelectedControlAndIndexes={setSelectedControlAndIndexes}
                      tokenEnabled={values.onFieldTokenEnabled || false}
                      tokenEnabledOnChange={handleChangeCheck('onFieldTokenEnabled')}
                      tokenOnChange={handleChangeName('onFieldToken')}
                      tokenValue={values.onFieldToken}
                      urlFieldName="onFieldUrlAPI"
                      urlOnChange={handleChangeName('onFieldUrlAPI')}
                      urlValue={values.onFieldUrlAPI}
                      readOnly={readOnly}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CustomRecordModal>
  );
};

export default ModalPolicies;
