import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Checkbox,
  IconButton,
  lighten,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';

import ModalYesNo from './ModalYesNo';


const useStyles = makeStyles(theme => ({
  inputSearchBy: {
    marginTop: '16px',
    width: '100%',
    padding: '5px 10px',
    borderRadius: '12px',
    outline: 'none',
    border: 'none',
    backgroundColor: '#fafafa',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  inputSearchByDisabled: {
    marginTop: '16px',
    width: '100%',
    height: '29px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  tableWrapper: {
    overflowX: 'auto'
  },
}));

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: '1 1 10%'
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
  },
  title: {
    flex: '0 0 auto'
  },
  search: {
    backgroundColor: '#fafafa',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    marginRight: '10px',
    width: '230px',
    border: '1px #ffffff00 solid',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIcon: {
    position: 'relative',
    marginRight: '1px',
    marginLeft: '5px'
  },
  inputInput: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#FFFFFF00'
  }
}));

const IndentedTable = (props) => {
  const {
    headRows = [],
    disableSearchBy,
    thisNode,
    rows = [],
    handleChangeOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    permissions,
    onDelete,
    onEdit,
    onView,
    debugging
  } = props;

  const classes = useStyles();

  const columnPickerControl = (headRows) => headRows.map((column) => ({ ...column, visible: true }));
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, row) => {
    const { id } = row;
    const selectedIndex = selected.indexOf(id)

    if (selectedIndex === -1) {
      setSelected([...selected, id]);
    } else {
      const selectedCopy = [...selected];
      selectedCopy.splice(selectedIndex, 1);
      setSelected(selectedCopy);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }


  const handleLocalChangePage = async (event, newPage) => {
    handleChangePage(newPage, thisNode);
  };

  const handleLocalChangeRowsPerPage = (event) => {
    handleChangeRowsPerPage(event.target.value, thisNode);
  };

  const onDeleteLocal = () => {
    onDelete(selected);
    setSelected([]);
    setOpenYesNoModal(false);
  };

  const onEditLocal = () => {
    onEdit(selected);
    setSelected([]);
  };

  const verifyOrderBy = () => {
    const blocked = thisNode.query.condition.map((cond) => {
      return Object.entries(cond).flat()[0];
    });

    const orderBy = headRows.find(({ id }) => !blocked.includes(id));

    handleChangeOrderBy(orderBy, thisNode);
    return orderBy;
  };

  const [columnPicker, setColumnPicker] = useState(columnPickerControl(headRows));
  const [order, setOrder] = useState(thisNode.query.order === 1 ? 'asc' : 'desc');
  const [orderBy, setOrderBy] = useState(thisNode.query.orderBy);
  const [selected, setSelected] = useState([]);
  const [blockedOrder, setBlockedOrder] = useState([]);
  const [openYesNoModal, setOpenYesNoModal] = useState(false);

  useEffect(() => {
    setColumnPicker(columnPickerControl(headRows));
  }, [headRows]);

  useEffect(() => {
    setOrder(thisNode.query.order === 1 ? 'asc' : 'desc')
    setOrderBy(thisNode.query.orderBy);

    const blocked = thisNode.query.condition.map((cond) => {
      return Object.entries(cond).flat()[0];
    });
    setBlockedOrder(blocked);
  }, [thisNode]);

  const EnhancedTableHead = (props) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              checked={numSelected === rowCount}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{ 'aria-label': 'Select all desserts' }}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnPicker.filter((column) => column.visible).map(row => (
            <TableCell
              align="left"
              key={row.id}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={row.sortByDisabled || blockedOrder.includes(row.id) ? () => { } : createSortHandler(row.id)}
                hideSortIcon={row.sortByDisabled || blockedOrder.includes(row.id)}
              >
                {row.label}
              </TableSortLabel>
              {/* {
              !disableSearchBy && !row.searchByDisabled && (
                <input
                  autoFocus={row.id === searchBy}
                  className={classes.inputSearchBy}
                  onChange={(event) => handleInputChangeDebounced(event, row.id)}
                  placeholder={`Search by...`}
                  value={row.id === searchBy ? columnInputValue : null}
                />
              )
            }
            {
              !disableSearchBy && row.searchByDisabled && (
                <div
                  className={classes.inputSearchByDisabled}
                />
              )
            } */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { selected, onAdd, noEdit, noAdd, noDelete } = props;
    const numSelected = selected.length;

    const onDelete = () => {
      props.onDelete();
    }

    useEffect(() => {
      if (!props.onSelect) {
        return;
      };
      const selectedIdToSend = numSelected ? selected : null;
      props.onSelect(selectedIdToSend);
    }, [numSelected]);

    const HeaderTools = () => {
      // if(disableActions){
      //   return (
      //     <div></div>
      //   );
      // }
      if (numSelected > 0) {
        return (
          <div style={{ display: 'flex' }}>
            {numSelected === 1 && !noEdit && permissions.includes('view') && (
              <Tooltip title='View'>
                <IconButton aria-label='View' onClick={() => onView(selected)}>
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
            )}
            {numSelected === 1 && !noEdit && permissions.includes('edit') &&
              <Tooltip title='Edit'>
                <IconButton aria-label='Edit' onClick={props.onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
            {
              permissions.includes('delete') && !noDelete &&
              <Tooltip title='Delete'>
                <IconButton aria-label='Delete' onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            }
          </div>
        )
      }
      return (
        <>
          {/* <div aria-label='Search Box' className={classes.search} key='SearchDiv'>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <input
              autoFocus={controlValues.searchBy === null}
              className={classes.inputInput}
              key='SearchField'
              onChange={event => handleInputChange(event, null)}
              placeholder='Search...'
              value={controlValues.searchBy ? null : controlValues.search}
              onSubmit={event => handleInputChange(event, null)}
            />
            {
              (controlValues.search.length > 0 && !controlValues.searchBy) && (
                <div>
                  <IconButton size="small" onClick={() => searchControl({ value: '' })}>
                    <ClearIcon />
                  </IconButton>
                </div>
              )
            }
            {
              (!controlValues.search.length || controlValues.searchBy) && (
                <div style={{ width: '25px' }} />
              )
            }
          </div> */}
          {/* <Tooltip title='Table View'>
            <IconButton aria-label='Table View' onClick={showTableView}>
              <ListRoundedIcon />
            </IconButton>
          </Tooltip>
          {
            tileView && (
              <Tooltip title='Tile View'>
                <IconButton aria-label='Tile view' onClick={showTileView}>
                  <ViewModuleRoundedIcon />
                </IconButton>
              </Tooltip>
            )
          }
          {
            treeView && (
              <Tooltip title='Tree View'>
                <IconButton aria-label='Tree view' onClick={showTreeView}>
                  <AccountTreeRoundedIcon />
                </IconButton>
              </Tooltip>
            )
          }
          <Tooltip title='Column Picker'>
            <IconButton aria-label='Column Picker' onClick={recordButtonPosition}>
              <ViewColumnRoundedIcon />
            </IconButton>
          </Tooltip>
          {
            typeof onAdd == 'function' && permissions.includes('add') && !noAdd && (
              <Tooltip title='Add'>
                <IconButton aria-label='Add' onClick={onAdd}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )
          } */}
        </>
      );
    }
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color='inherit' variant='subtitle1'>
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant='h6'>
              {props.title}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <HeaderTools />
        </div>
      </Toolbar>
    );
  };

  return (
    <div className={classes.root} style={{ padding: '0px' }}>
      <ModalYesNo
        message={'Are you sure you want to remove this element?'}
        onOK={onDeleteLocal}
        onCancel={() => setOpenYesNoModal(false)}
        showModal={openYesNoModal}
        title={'Remove Element'}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          noEdit={false}
          onAdd={() => console.log('add')}
          onDelete={() => setOpenYesNoModal(true)}
          onEdit={onEditLocal}
          onSelect={() => {}}
          selected={selected}
          title={props.title}
        />
        <div className={classes.tableWrapper} key={`${thisNode.id}_${debugging}`} id={`${thisNode.id}_${debugging}`}>
          <Table stlye={{ width: '100%' }} key={`${thisNode.id}_${debugging}`} id={`${thisNode.id}_${debugging}`}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => handleChangeOrderBy(property, thisNode)}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {
                rows.length <= 0 && (
                  <TableRow
                    hover
                    key={`No info`}
                    role='checkbox'
                    tabIndex={-1}
                  >
                    <TableCell
                      align={'center'}
                      component='th'
                      key={`NoData`}
                      padding={'default'}
                      scope='row'
                      colSpan={100}
                    >
                      <Typography variant='h5'>
                        Sorry, no matching records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              }
              {
                rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-\${index}`;
                  return (
                    <TableRow
                      aria-checked={isItemSelected}
                      hover
                      key={`key-row-${row.id}`}
                      onClick={event => handleClick(event, row)}
                      role='checkbox'
                      selected={isItemSelected}
                      tabIndex={-1}
                    >
                      <TableCell padding='checkbox' component={'th'}>
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>

                      {columnPicker.filter((column) => column.visible).map((header, ix) => {
                        return (
                          <TableCell
                            align={header.renderCell ? 'center' : 'left'}
                            component={header.renderCell ? () => header.renderCell(row[header.id]) : 'th'}
                            key={`cell-row${index}-${ix}`}
                            padding={'default'}
                            scope='row'
                          >
                            {row[header.id]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component='div'
          count={thisNode.count}
          labelDisplayedRows={({ from, to, count }) => {
            if (count === 0) return 'No Pages';
            const currentPage = thisNode.query.page + 1;
            const totalPages = Math.floor(count / thisNode.query.rowsPerPage) + (count % thisNode.query.rowsPerPage === 0 ? 0 : 1);
            return `Page ${currentPage}/${totalPages}`;
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={handleLocalChangePage}
          onChangeRowsPerPage={handleLocalChangeRowsPerPage}
          page={thisNode.query.page}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={thisNode.query.rowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default IndentedTable;
