import React from 'react';
import PropTypes from 'prop-types';
import ModalYesNo from '../../Components/ModalYesNo';
import { GetTranslatedValue } from '../../utils';

const DataExtractionConfitmationModal = ({ onCancel, onOK, showModal }) => (
  <ModalYesNo
    message={GetTranslatedValue("MODAL.DATA.EXTRACTION.CONFIRMATION.MESSAGE")}
    onCancel={onCancel}
    onOK={onOK}
    showModal={showModal}
    title={GetTranslatedValue('MODAL.DATA.EXTRACTION.TITLE')}
  />
);

DataExtractionConfitmationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default DataExtractionConfitmationModal;
