import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  DialogActions,
  Button
} from '@material-ui/core';
import { GetTranslatedValue } from '../../../utils';
import TableComponent from '../../../Components/TableComponent';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1)
  }
}));

const createHeaderRow = (id) => {
  return { id, numeric: false, disablePadding: false, label: id };
};

const ModalSkippedRecords = ({ skippedRows, showModal, handleCloseModal }) => {
  const classes = useStyles();
  const [headerRows, setHeaderRows] = useState([]);

  useEffect(() => {
    if (!isEmpty(skippedRows)) {
      setHeaderRows(Object.keys(skippedRows[0]).map((header) => createHeaderRow(header)));
    }
  }, [skippedRows]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      onClose={handleCloseModal}
      open={showModal}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        {GetTranslatedValue('IMPORT.SKIPPED.TITLE')}
      </DialogTitle>
      <DialogContent dividers style={{ padding: '15px' }}>
        <h6>{GetTranslatedValue('IMPORT.SKIPPED.MESSAGE')}</h6>
        <TableComponent
          headRows={headerRows}
          isImport
          rows={skippedRows}
          title={GetTranslatedValue('IMPORT.SKIPPED.TABLE.TITLE')}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button onClick={handleCloseModal} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalSkippedRecords.defaultProps = {
  skippedRows: []
};

ModalSkippedRecords.propTypes = {
  skippedRows: PropTypes.array,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};

export default ModalSkippedRecords;
