/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { isEmpty } from 'lodash';

import Label from '@material-ui/icons/Label';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SendIcon from '@material-ui/icons/Send';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FeedbackIcon from '@material-ui/icons/Feedback';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { GetTranslatedValue } from '../../utils'; 

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    flexWrap: 'wrap'
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

const useStyle = makeStyles((theme) => ({
  card: {
    width: '50%',
    marginLeft: '0px'
  },
  cardContent: {
    display: 'flex', 
    flexDirection: 'column',
    minHeight: '400px' 
  },
  root: {
    display: 'flex'
  },
  treeView: {
    padding: '40px',
    width: '50%' 
  },
  processInfoTitle: {
    marginTop: '35px',
    marginBottom: '0',
    marginLeft: '30px'
  },
  processInfoLabel: {
    marginLeft: '150px'
  },
  '@media (max-width: 750px)': {
    root: {
      flexDirection: 'column'
    },
    card: {
      width: '100%',
      marginLeft: '0px',
      padding: '10%'
    },
    treeView: {
      padding: '20px',
      width: '100%' 
    },
    processInfoLabel: {
      marginLeft: '10px'
    },
    processInfoTitle: {
      marginTop: '35px',
      marginBottom: '0',
      marginLeft: '10px'
    },
  },
  processesRelated: {
    width: '100%',
    overflowY: 'auto', 
    height: '125px',
    marginTop: '5px',
  },
  processesRelatedTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '5px',
    marginBottom: '10px'
  },
  tableHead: {
    position: 'sticky', 
    top: 0,
    borderLeft: '1px solid rgb(224, 224, 224)',
    borderRight: '1px solid rgb(224, 224, 224)',
    borderBottom: '1px solid rgb(224, 224, 224)',
    borderTop: '1px solid rgb(224, 224, 224)'
  }, 
  tableRow: {
    borderLeft: '1px solid rgb(224, 224, 224)',
    borderRight: '1px solid rgb(224, 224, 224)',
    borderBottom: '1px solid rgb(224, 224, 224)'
  },
  verticalDivider: {
    borderRight: '1px solid rgb(224, 224, 224)',
  },
  th: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    padding: '5px',
  },
  tr: {
    padding: '5px'
  }
}));

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="red" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit" id="este">
            {labelInfo}
          </Typography>
        </div>
      }
      classes={{ root: classes.root }}
      {...other}
    />
  );
};

const getRandomId = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

const LiveProcessInfo = ({ processInfo }) => {
  const classes = useStyle();
  const { processData = {}, requestUser } = processInfo;
  const { email: userEmail, name: userName, lastName: userLastName } = requestUser || {};
  const { name, currentStage, totalStages, selectedProcessType } = processData;
  const selfApprove = processData?.stages ? processData.stages[0]?.isSelfApprove || selectedProcessType === 'short' : selectedProcessType === 'short';
  const processInfoTexts = [
    { label: `${GetTranslatedValue('PROCESSES.INFO.NAME','Process Name')}:`, value: name },
    { label:  `${GetTranslatedValue('PROCESSES.INFO.CREATOR','Process Creator')}:`, value: `${userName} ${userLastName} (${userEmail})` },
    { label: `${GetTranslatedValue('PROCESSES.INFO.CURRENT.STAGE','Current Stage:')}:`, value: currentStage },
    { label: `${GetTranslatedValue('PROCESSES.INFO.TOTAL.STAGES','Total Stages:')}:`, value: totalStages },
  ];
  const stageFulfilled = (processData.stages || []).map(({ stageFulfilled }) => ({ stageFulfilled }));
  if (selectedProcessType === 'short') { // If process is 'short' then simulate a single stage fulfilled so the Process shows as completed
    stageFulfilled.push({ stageFulfilled: true });
  }
  const isProcessComplete = stageFulfilled.every(({ stageFulfilled }) => stageFulfilled === true);

  const getVirtualUserLabel = (virtualUser) => {
    switch (virtualUser) {
      case 'boss':
        return `[${GetTranslatedValue('VIRTUAL.USER.DIRECT.BOSS.LETTERS', 'DB')}]`;
      
      case 'locationManager':
        return `[${GetTranslatedValue('VIRTUAL.USER.LOCATION.MANAGER.LETTERS', 'LM')}]`;

      case 'locationWitness':
        return `[${GetTranslatedValue('VIRTUAL.USER.LOCATION.WITNESS.LETTERS', 'LW')}]`;

      case 'assetSpecialist':
          return `[${GetTranslatedValue('VIRTUAL.USER.ASSET.SPECIALIST.LETTERS', 'AS')}]`;

      case 'initiator':
        return `[${GetTranslatedValue('VIRTUAL.USER.PROCESS.INITIATOR.LETTERS', 'PI')}]`;
    
      default:
        break;
    }
  };

  const getAssetInfo = (name, brand, model) => {
    const translatedName = GetTranslatedValue('ASSETS.CAPTION.NAME', 'Name'); 
    const translatedBrand = GetTranslatedValue('ASSETS.CAPTION.BRAND', 'Brand'); 
    const translatedModel = GetTranslatedValue('ASSETS.CAPTION.MODEL', 'Model');
    return `${translatedName}: ${name} | ${translatedBrand}: ${brand} | ${translatedModel}: ${model}`;
  };

  const getApprovedRejectedAssets = (cartRows = [], selfApprove = false) => {
    const approved = selfApprove ? cartRows : cartRows.filter(({ status }) => status === 'Approved');
    const rejected = cartRows.filter(({ status }) => status === 'Rejected');
    return (
      <>
        {approved.length !== 0 &&
          <StyledTreeItem
            nodeId={getRandomId()}
            labelText={GetTranslatedValue('PROCESSES.INFO.ASSETS.APPROVED', `Approved Assets`)}
            labelIcon={ThumbUpIcon}
            labelInfo={approved.length}
          >
          {approved.map(({ name, brand, model }) => (
            <StyledTreeItem
              nodeId={getRandomId()}
              labelText={getAssetInfo(name, brand, model)}
              labelIcon={TabletMacIcon}
            />
          ))}
          </StyledTreeItem>
        }
        {rejected.length !== 0 &&
          <StyledTreeItem
            nodeId={getRandomId()}
            labelText={GetTranslatedValue('PROCESSES.INFO.ASSETS.REJECTED', `Rejected Assets`)}
            labelIcon={ThumbDownIcon}
            labelInfo={rejected.length}
          >
          {rejected.map(({ name, brand, model, message }) => (
            <StyledTreeItem
              nodeId={getRandomId()}
              labelText={getAssetInfo(name, brand, model)}
              labelIcon={TabletMacIcon}
            >
              <StyledTreeItem
                nodeId={getRandomId()}
                labelText={`Reason: ${message}`}
                labelIcon={FeedbackIcon}
              />
            </StyledTreeItem>
          ))}
          </StyledTreeItem>
        }
      </>
    );
  };
  
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {isProcessComplete && stageFulfilled.length !== 0 &&
            <div style={{ display: 'flex', marginTop: '35px', marginBottom: '0', justifyContent: 'center' }}>
              <h4 style={{textAlign: 'center'}}>{GetTranslatedValue('PROCESSES.LIVE.MODAL.FINISHED.PROCESS', 'Process Completed')}</h4>
              <CheckCircleIcon style={{ color: 'green', marginLeft: '20px', alignSelf: 'center' }}/>
            </div>
          }
          {processInfoTexts.map(({ label, value }) => (
            <>
              <h6 className={classes.processInfoTitle}>{label}</h6>
              <span className={classes.processInfoLabel} >{value}</span>    
            </>
          ))}
          {
            !isEmpty(processInfo.groupedFolios) && (
              <div>
                <h4 style={{textAlign: 'center', marginTop: '20px'}}>{GetTranslatedValue('PROCESSES.CAPTION.RELATED.FOLIOS', 'Related Folios')}</h4>
                <div className={classes.processesRelated}>
                  <table className={classes.processesRelatedTable}>
                    <thead className={classes.tableHead}>
                      <tr >
                        <th className={classes.th + ' ' + classes.verticalDivider} >Number</th>
                        <th className={classes.th} >Folio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        processInfo.groupedFolios.map((folio, ix) => (
                          <tr className={classes.tableRow}>
                            <td className={classes.tr + ' ' + classes.verticalDivider}>{ix + 1 }</td>
                            <td className={classes.tr}>{folio}</td>
                          </tr>
                        ))  
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }
        </CardContent>
      </Card>
      <TreeView
        className={classes.treeView}
        defaultExpanded={['5']}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem nodeId='1' labelText={GetTranslatedValue('PROCESSES.INFO.ALL.STAGES', 'All Stages')} labelIcon={Label}>
          {(processData.stages || []).map((stage, index) => (
            <StyledTreeItem
              nodeId={`stage_${index + 1}`}
              labelText={`${GetTranslatedValue('PROCESSES.CAPTION.STAGE', 'Stage')} ${index + 1} - ${stage.stageName}`}
              labelIcon={stage.stageFulfilled ? CheckCircleIcon : SubdirectoryArrowRightIcon}
              labelInfo={`${stage.stageFulfilled ? GetTranslatedValue('PROCESSES.INFO.COMPLETED', 'Completed') : GetTranslatedValue('PROCESSES.INFO.IN.PROGRESS', 'In Progress')}`}
            >
              <StyledTreeItem
                nodeId={getRandomId()}
                labelText={GetTranslatedValue('PROCESSES.CAPTION.APPROVALS', 'Approvals')}
                labelIcon={CheckBoxIcon}
                labelInfo={`(${!selfApprove ? (stage.approvals.length || 0) : 1})`}
              >
                {stage.approvals.length === 0 && selfApprove ? (
                    <StyledTreeItem
                        nodeId={getRandomId()}
                        labelText={GetTranslatedValue('PROCESSES.CAPTION.SELF.APPROVE', `SelfApprove`)}
                        labelIcon={AccountCircleIcon}
                        labelInfo={GetTranslatedValue('PROCESSES.INFO.FULFILLED', `Fulfilled`)}
                    >
                      <StyledTreeItem
                        nodeId={getRandomId()}
                        labelText={processInfo.updateDate}
                        labelIcon={EventAvailableIcon}
                      />
                      {getApprovedRejectedAssets(stage.cartRows, true)}
                    </StyledTreeItem>
                  ) : (
                    (stage.approvals || []).map(({ cartRows, email, fulfilled, fulfillDate, name, lastName, virtualUser }) => (
                      <StyledTreeItem
                        nodeId={getRandomId()}
                        labelText={`${virtualUser ? getVirtualUserLabel(virtualUser) : ''}${name} ${lastName} (${email})`}
                        labelIcon={!fulfillDate ? HourglassEmptyIcon : AccountCircleIcon}
                        labelInfo={selfApprove || fulfilled === 'skipped' ? GetTranslatedValue('PROCESSES.INFO.SKIPPED', 'Skipped') : !fulfillDate ? GetTranslatedValue('PROCESSES.INFO.PENDING', 'Pending') : GetTranslatedValue('PROCESSES.INFO.FULFILLED', 'Fulfilled')}
                      >
                        {fulfillDate &&
                          <>
                            <StyledTreeItem
                              nodeId={getRandomId()}
                              labelText={fulfillDate}
                              labelIcon={fulfilled ? EventAvailableIcon : EventBusyIcon}
                            />
                            {getApprovedRejectedAssets(cartRows)}
                          </>
                        }
                      </StyledTreeItem>
                    ))
                  )}
              </StyledTreeItem>
              <StyledTreeItem
                nodeId={getRandomId()}
                labelText={GetTranslatedValue('PROCESSES.CAPTION.NOTIFICATIONS', 'Notifications')}
                labelIcon={NotificationsIcon}
                labelInfo={`(${stage.notifications.length || 0})`}
              >
                {(stage.notifications || []).map(({ email, sent, sentDate, name, lastName, virtualUser }) => (
                  <StyledTreeItem
                    nodeId={getRandomId()}
                    labelText={`${virtualUser ? getVirtualUserLabel(virtualUser) : ''}${name} ${lastName} (${email})`}
                    labelIcon={!sentDate ? HourglassEmptyIcon : sent ? SendIcon : ThumbDownIcon}
                    labelInfo={selfApprove ? GetTranslatedValue('PROCESSES.INFO.SKIPPED', 'Skipped') : !sentDate ? GetTranslatedValue('PROCESSES.INFO.PENDING', 'Pending') : sent ? GetTranslatedValue('MESSAGES.TRASH.SENT', 'Sent') : GetTranslatedValue('MESSAGES.TRASH.NOT.SENT', 'Not Sent')}
                  >
                    {sentDate &&
                      <StyledTreeItem
                        nodeId={getRandomId()}
                        labelText={sentDate}
                        labelIcon={EventNoteIcon}
                      />
                    }
                  </StyledTreeItem>
                ))}
              </StyledTreeItem>
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      </TreeView>
    </div>
  );
};

export default LiveProcessInfo;
