import React, { useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import ImageMarker from 'react-image-marker';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty, merge } from 'lodash';
import { Formik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import {
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import RoomIcon from '@material-ui/icons/Room';
import { actions } from '../../../store/ducks/general.duck';
import { metronic, initLayoutConfig, LayoutConfig } from '../../../../_metronic';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import * as general from '../../../store/ducks/general.duck';
import { deleteDB, getCountDB, getDB, getDBComplex, getOneDB, updateDB } from '../../../crud/api';
import { useFieldValidator } from '../Components/FieldValidator/hooks';
import { tabsConfig } from '../Components/Translations/constants';
import { TabsTitles } from '../Components/Translations/tabsTitles';
import { loadGroups, updateTableGroupingTree } from '../Components/tableGroupingHelpers';
import GoogleMaps from '../Components/GoogleMaps';
import TableComponent2 from '../Components/TableComponent2';
import TreeView from '../Components/TreeViewComponent';
import ModalLocationList from './modals/ModalLocationList';
import ModalLocationProfiles from './modals/ModalLocationProfiles';
import './Locations.scss';
import ModalYesNo from '../Components/ModalYesNo';
import Policies from '../Components/Policies/Policies';
import { executePolicies } from '../Components/Policies/utils';
import { usePolicies } from '../Components/Policies/hooks';
import {
  getCurrentModuleName,
  getCurrentModuleTab,
  getFieldCaption,
  getImageURL,
  GetTranslatedValue,
  loadLocationsData,
  loadUserLocations,
  profileHasChildren,
  showWarningMessage
} from '../utils';
import { allBaseFields } from '../constants';
import CircularProgressCustom from '../Components/CircularProgressCustom';
import { checkImage } from '../utils';
import useMapCoords from './hooks';

let locations;

const Locations = ({ globalSearch, setGeneralSearch, user }) => {
  const { showCustomAlert, showDeletedAlert, showErrorAlert } = actions;
  const theme4 = useTheme();
  const intl = useIntl();
  const defaultCoords = useMapCoords();
  const [userLocations, setUserLocations] = useState(null);
  const [parentLocations, setParentLocations] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [editOrNew, setEditOrNew] = useState('new');
  const [googleMapsZoom, setGoogleMapsZoom] = useState(defaultCoords.zoom);
  const [id, setId] = useState(null);
  const [imageLayout, setImageLayout] = useState(null);
  const [loadingButtonPreviewStyle, setLoadingButtonPreviewStyle] = useState({
    paddingRight: '2.5rem'
  });
  const [loadingButtonResetStyle, setLoadingButtonResetStyle] = useState({
    paddingRight: '2.5rem'
  });
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [allLocationsProfiles, setAllLocationsProfiles] = useState([]);
  const [locationProfileRows, setLocationProfileRows] = useState(null);
  const [locationsTree, setLocationsTree] = useState({});
  const [mapCenter, setMapCenter] = useState(defaultCoords.coords);
  const [markers, setMarkers] = useState([]);
  const [modalId, setModalId] = useState(null);
  const [parentFileExt, setParentFileExt] = useState(null);
  const [parentSelected, setParentSelected] = useState(null);
  const [profileSelected, setProfileSelected] = useState({});
  const [profileSelectedId, setProfileSelectedId] = useState('');
  const [openListModal, setOpenListModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [listReadOnly, setListReadOnly] = useState(false);
  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const [realParentSelected, setRealParentSelected] = useState(null);
  const [selectedLocationProfileRows, setSelectedLocationProfileRows] = useState([]);
  const [tab, setTab] = useState(getCurrentModuleTab('locations', user.profilePermissions));
  const [value4, setValue4] = useState(0);
  const { policies, setPolicies } = usePolicies();
  const { allFields, fieldsToValidate } = useFieldValidator('locations');

  const messages = {
    validLocationMessage: GetTranslatedValue('LOCATIONS.WARNING.VALID.LOCATION'),
    noAccessMessage: GetTranslatedValue('LOCATIONS.WARNING.NO.ACCESS'),
    cannotDelete: GetTranslatedValue('LOCATIONS.WARNING.NO.DELETE'),
    profileHasChildren: GetTranslatedValue('LOCATIONS.PROFILES.WARNING.NO.DELETE.CHILDREN'),
    cannotDeleteAssetsAssigned: GetTranslatedValue('LOCATIONS.WARNING.CANNOT.DELETE.ASSETS.ASSIGNED'),
    cannotDeleteUsersAssigned: GetTranslatedValue('LOCATIONS.WARNING.CANNOT.DELETE.USERS.ASSIGNED'),
    canntoDeleteSpecialistsAssigned: GetTranslatedValue('LOCATIONS.WARNING.CANNOT.DELETE.SPECIALISTS.ASSIGNED'),
    canntoDeleteWitnessesAssigned: GetTranslatedValue('LOCATIONS.WARNING.CANNOT.DELETE.WITNESSES.ASSIGNED'),
    canntoDeleteHasChildren: GetTranslatedValue('LOCATIONS.WARNING.CANNOT.DELETE.CHILDREN'),
  };

  const executeFieldCaption = (catalogue, { field, key }) => {
    return getFieldCaption(allFields, { catalogue, field, key });
  };

  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );

  const policiesBaseFields = {
    list: {
      id: { validationId: 'locationId', component: 'textField', compLabel: 'ID' },
      ...allBaseFields.locationsList,
      translationLabel: 'TABS.TITLES.LIST'
    },
    profiles: {
      id: { validationId: 'locationProfileId', component: 'textField', compLabel: 'ID' },
      ...allBaseFields.locations,
      translationLabel: 'TABS.TITLES.PROFILES'
    }
  };

  const deleteLocationValidation = async (locationId) => {
    const hasAssets = await profileHasChildren('assets', locationId, 'location');

    if (hasAssets) {
      showWarningMessage(messages.cannotDeleteAssetsAssigned);

      return;
    } else {
      const hasUsers = await locationHasUsers(locationId);

      if (hasUsers) {
        showWarningMessage(messages.cannotDeleteUsersAssigned);

        return false;
      } else {
        const isUsedBySpecialist = await profileHasChildren('settingsAssetSpecialists', locationId, 'location.locationSelected');

        if (isUsedBySpecialist) {
          showWarningMessage(messages.canntoDeleteSpecialistsAssigned);

          return false;
        } else {
          const isUsedByWitness = await profileHasChildren('settingsWitnesses', locationId, 'location.locationSelected');

          if (isUsedByWitness) {
            showWarningMessage(messages.canntoDeleteWitnessesAssigned);

            return false;
          }
        }
      }
    }

    return true;
  };

  const dispatch = useDispatch();
  const initialValues = useMemo(() => merge(LayoutConfig, layoutConfig), [layoutConfig]);

  const locationActions = {
    async openYesNoModal() {
      if (!parentSelected || parentSelected === 'root') {
        return;
      }

      const locationHasChildren = await profileHasChildren('locationsReal', parentSelected, 'parent');

      if (locationHasChildren) {
        dispatch(
          showCustomAlert({
            message: messages.canntoDeleteHasChildren,
            open: true,
            type: 'warning'
          })
        );

        return;
      } else {
        setOpenYesNoModal(true);
      }
    },
    closeYesNoModal() {
      setOpenYesNoModal(false);
    },
    async removeLocation() {
      if (!parentSelected) {
        dispatch(
          showCustomAlert({
            message: messages.validLocationMessage,
            open: true,
            type: 'warning'
          })
        );
        return;
      }

      if (!userLocations.includes(parentSelected)) {
        dispatch(
          showCustomAlert({
            message: messages.noAccessMessage,
            open: true,
            type: 'warning'
          })
        );
        return;
      }

      if (realParentSelected === 'root') {
        getDB('locationsReal')
          .then((response) => response.json())
          .then(async (data) => {
            const selectedLocation = data.response.find(({ _id }) => _id === parentSelected);

            if (selectedLocation) {
              const deleteValidation = await deleteLocationValidation(parentSelected);

              if (!deleteValidation) {
                return;
              }

              getDB('policies')
                .then((response) => response.json())
                .then((data) => {
                  deleteDB('locationsReal/', parentSelected)
                    .then((locationResponse) => locationResponse.json())
                    .then((locationData) => {
                      const {
                        response: { value }
                      } = locationData;

                      const deletedLocationIndex = userLocations.indexOf(parentSelected);

                      if (deletedLocationIndex !== -1) {
                        const newUserLocations = userLocations.filter(
                          (location) => location !== parentSelected
                        );
                        setUserLocations(newUserLocations);

                        getDBComplex({
                          collection: 'user',
                          condition: [{ 'locationsTable.parent': parentSelected }]
                        })
                          .then((response) => response.json())
                          .then((data) => {
                            data.response.map((user) => {
                              const { locationsTable } = user;
                              const bool = locationsTable.some(
                                ({ parent }) => parent === parentSelected
                              );
                              if (bool) {
                                const newLocationsTable = locationsTable.filter(
                                  ({ parent }) => parent !== parentSelected
                                );
                                updateDB(
                                  'user/',
                                  { locationsTable: newLocationsTable },
                                  user._id
                                ).catch((error) => console.log('Update Error:', error));
                              }
                            });
                          });
                      }
                      dispatch(showDeletedAlert());
                      executePolicies('OnDelete', 'locations', 'list', data.response, value);
                    })
                    .catch((error) => dispatch(showErrorAlert()));
                })
                .catch((error) => dispatch(showErrorAlert()));
            } else {
              dispatch(
                showCustomAlert({
                  message: messages.cannotDelete,
                  open: true,
                  type: 'warning'
                })
              );
            }
          });
      } else {
        const deleteValidation = await deleteLocationValidation(parentSelected);

        if (!deleteValidation) {
          return;
        }

        getDB('policies')
          .then((response) => response.json())
          .then((data) => {
            deleteDB('locationsReal/', parentSelected)
              .then((locationResponse) => locationResponse.json())
              .then((locationData) => {
                const {
                  response: { value }
                } = locationData;

                const deletedLocationIndex = userLocations.indexOf(parentSelected);
                if (deletedLocationIndex !== -1) {
                  const newUserLocations = userLocations.filter(
                    (location) => location !== parentSelected
                  );
                  setUserLocations(newUserLocations);

                  getDBComplex({
                    collection: 'user',
                    condition: [{ 'locationsTable.parent': parentSelected }]
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      data.response.map((user) => {
                        const { locationsTable } = user;
                        const bool = locationsTable.some(({ parent }) => parent === parentSelected);
                        if (bool) {
                          const newLocationsTable = locationsTable.filter(
                            ({ parent }) => parent !== parentSelected
                          );
                          updateDB(
                            'user/',
                            { locationsTable: newLocationsTable },
                            user._id
                          ).catch((error) => console.log('Update Error:', error));
                        }
                      });
                    });
                }
                dispatch(showDeletedAlert());
                executePolicies('OnDelete', 'locations', 'list', data.response, value);
              })
              .catch((error) => dispatch(showErrorAlert()));
          })
          .catch((error) => dispatch(showErrorAlert()));
      }
      setOpenYesNoModal(false);
    },
    openProfilesListBox(e) {
      if (!parentSelected) {
        dispatch(
          showCustomAlert({
            message: messages.validLocationMessage,
            open: true,
            type: 'warning'
          })
        );
        return;
      }

      if (!userLocations.includes(parentSelected) && parentSelected !== 'root') {
        dispatch(
          showCustomAlert({
            message: messages.noAccessMessage,
            open: true,
            type: 'warning'
          })
        );

        return;
      } else if (!parentSelected) {
        dispatch(
          showCustomAlert({
            open: true,
            message: messages.validLocationMessage,
            type: 'warning'
          })
        );
        return;
      }
      setEditOrNew('new');
      setListReadOnly(false);
      setAnchorEl(e.currentTarget);
    },
    editLocation() {
      if (!parentSelected) {
        dispatch(
          showCustomAlert({
            message: messages.validLocationMessage,
            open: true,
            type: 'warning'
          })
        );
        return;
      }

      if (!userLocations.includes(parentSelected)) {
        dispatch(
          showCustomAlert({
            message: messages.noAccessMessage,
            open: true,
            type: 'warning'
          })
        );

        return;
      } else if (!parentSelected || parentSelected === 'root') {
        dispatch(
          showCustomAlert({
            open: true,
            message: messages.validLocationMessage,
            type: 'warning'
          })
        );

        return;
      }

      setEditOrNew('edit');
      setOpenListModal(true);
      setListReadOnly(false);
    },
    viewLocation() {
      if (!parentSelected) {
        dispatch(
          showCustomAlert({
            message: messages.validLocationMessage,
            open: true,
            type: 'warning'
          })
        );
        return;
      }

      if (!userLocations.includes(parentSelected)) {
        dispatch(
          showCustomAlert({
            message: messages.noAccessMessage,
            open: true,
            type: 'warning'
          })
        );

        return;
      } else if (!parentSelected || parentSelected === 'root') {
        dispatch(
          showCustomAlert({
            open: true,
            message: messages.validLocationMessage,
            type: 'warning'
          })
        );
        return;
      }
      setEditOrNew('edit');
      setOpenListModal(true);
      setListReadOnly(true);
    }
  };

  const locationHasUsers = (locationId) => {
    return getCountDB({
      collection: 'user',
      condition: [{ "locationsTable": { "$elemMatch": { "parent": locationId } } }]
    })
      .then((response) => response.json())
      .then((data) => data?.response?.count > 0)
      .catch((error) => console.log(error));
  };

  const createLocationProfileRow = (id, level, name, creator, creationDate, updateDate) => {
    return { id, level, name, creator, creationDate, updateDate };
  };

  const CustomMarker = (MarkerComponentProps) => {
    if (imageLayout !== `${window.location.origin}/media/misc/placeholder-image.jpg`) {
      return <RoomIcon style={{ color: 'red' }} />;
    } else {
      return null;
    }
  };

  const enableLoadingPreview = () => {
    setLoadingPreview(true);
    setLoadingButtonPreviewStyle({ paddingRight: '3.5rem' });
  };

  const enableLoadingReset = () => {
    setLoadingReset(true);
    setLoadingButtonResetStyle({ paddingRight: '3.5rem' });
  };

  const updateLayoutConfig = (_config) => {
    dispatch(metronic.builder.actions.setLayoutConfigs(_config));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const locHeadRows = [
    {
      id: 'level',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('locations', {
        field: 'selectedLevel',
        key: allBaseFields.locations.selectedLevel.translation
      })
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('locations', {
        field: 'name',
        key: allBaseFields.locations.name.translation
      })
    },
    {
      id: 'creator',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('locations', {
        field: '',
        key: 'RECORD.CAPTION.CREATOR'
      }),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('locations', {
        field: '',
        key: 'RECORD.CAPTION.CREATIONDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('locations', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const TabContainer4 = ({ children, dir }) => {
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  };

  const handleChange4 = (event, newValue) => {
    setValue4(newValue);
  };
  const handleChangeIndex4 = (index) => {
    setValue4(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLocationListModal = (profile) => {
    handleClose();
    setOpenListModal(true);
    setProfileSelected(profile);
  };

  const onDeleteProfileLocation = async (id) => {
    if (!id || !Array.isArray(id)) {
      return;
    }

    const copyArray = JSON.parse(JSON.stringify(id));

    for (let i = 0; i < copyArray.length; i++) {
      const flag = await profileHasChildren('locationsReal', copyArray[i], 'profileId');

      if (flag) {
        const index = id.indexOf(copyArray[i]);
        id.splice(index, 1);
        showWarningMessage(messages.profileHasChildren);
      }
    }

    getDB('policies')
      .then((response) => response.json())
      .then((data) => {
        id.forEach((_id) => {
          deleteDB('locations/', _id)
            .then((locationResponse) => locationResponse.json())
            .then((locationData) => {
              const {
                response: { value }
              } = locationData;

              dispatch(showDeletedAlert());
              executePolicies('OnDelete', 'locations', 'profiles', data.response, value);
              loadLocationsProfilesData();
              updateGeneralProfileLocations();
            })
            .catch((error) => dispatch(showErrorAlert()));
        });
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const onEditProfileLocation = (_id) => {
    setId(_id);
    setOpenProfileModal(true);
    setReadOnly(false);
  };

  const onViewProfileLocation = (_id) => {
    setId(_id);
    setOpenProfileModal(true);
    setReadOnly(true);
  };

  const onAddProfileLocation = () => {
    setId(null);
    setOpenProfileModal(true);
    setReadOnly(false);
  };

  const getImageLayout = (id) => {
    if (id === 'root') {
      setImageLayout(`${window.location.origin}/media/misc/placeholder-image.jpg`);
    } else {
      const result = locations.filter((location) => location._id === id);
      const image = result.map((coordinate) => coordinate.fileExt);
      if (image[0]) {
        const imageURLLayout = getImageURL(id, 'locationsReal', image[0]);
        checkImage(
          imageURLLayout,
          (url) => setImageLayout(url),
          () => setImageLayout(`${window.location.origin}/media/misc/placeholder-image.jpg`)
        );
      } else {
        setImageLayout(`${window.location.origin}/media/misc/placeholder-image.jpg`);
      }
    }
  };

  const getChildren = (id) => {
    if (id === 'root') {
      setCoordinates([]);
    } else {
      const result = locations.filter((location) => location.parent === id);
      const latLng = result
        .map((coordinate) => coordinate.mapInfo)
        .filter((elem) => elem !== null && elem.lat && elem.lng && elem.zoom);
      const pinMarker = result.map((pin) => pin.imageInfo).filter((elem) => elem);

      setCoordinates(latLng);
      setMarkers(pinMarker);
    }
  };

  const getFirstChildCoord = (locationId) => {
    const child = locations.find(
      (location) =>
        location.parent === locationId &&
        location.mapInfo &&
        location.mapInfo.lat &&
        location.mapInfo.lng &&
        location.mapInfo.zoom
    );

    return {
      coords: child?.mapInfo
        ? {
          lat: child?.mapInfo.lat,
          lng: child?.mapInfo.lng
        }
        : null,
      zoom: child?.mapInfo?.zoom
    };
  };

  const getSelfCenter = (id) => {
    if (id === 'root') {
      setCoordinates([]);
    } else {
      const firstChildMapInfo = getFirstChildCoord(id);

      setGoogleMapsZoom(firstChildMapInfo?.zoom || defaultCoords.zoom);
      setMapCenter(firstChildMapInfo?.coords || defaultCoords.coords);
    }
  };

  const getParentExt = (idParent) => {
    if (idParent === 'root' || !idParent) {
      return;
    }

    const result = locations.filter((location) => location._id === idParent);

    if (result[0].fileExt !== '') {
      const getExt = result[0].fileExt;
      setParentFileExt(getExt);
    } else {
      return;
    }
  };

  const handleLoadLocations = async (assets = { flag: false, data: [], newLocationName: '' }) => {
    if (!Object.entries(locationsTree).length) {
      updateGeneralProfileLocations();
    }

    setLocationsTree({});
    const { locationsTreeData, locations: data } = await loadLocationsData(
      userLocations || [],
      setModalData
    );

    locations = data;
    setLocationsTree(locationsTreeData);
    const selectedProfileTmp = profileSelected;
    setProfileSelected(selectedProfileTmp);

    if (assets.flag) {
      for (let i = 0; i < assets.data?.length; i++) {
        const { _id: assetId, location } = assets.data[i];

        const newLocationPath = await getLocationPathLocal(location, locations);

        if (newLocationPath?.split('/')?.includes(assets.newLocationName)) {
          updateDB('assets/', { locationPath: newLocationPath }, assetId)
            .catch((error) => console.log(error))
        }
      }
    }
  };

  const getLocationPathLocal = async (locationId, data) => {
    const firstLocation = data.find(({ id }) => id === locationId);
    const response = recursiveLocationPathLocal(firstLocation, data);

    return response;
  };

  const recursiveLocationPathLocal = (currentLocation, allLocations) => {
    const { name, parent } = currentLocation;
    if (parent === 'root') {
      return name;
    } else {
      const newLocation = allLocations.find(({ id }) => id === parent);

      return recursiveLocationPathLocal(newLocation, allLocations).concat('/', name);
    }
  };

  const [tableControl, setTableControl] = useState({
    locations: {
      collection: 'locations',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    }
  });

  const updateGeneralProfileLocations = () => {
    getDB('locations')
      .then((response) => response.json())
      .then((data) => {
        const profileRows = data.response.map((row) => {
          const { _id, level, name, creationUserFullName, creationDate } = row;

          return createLocationProfileRow(_id, level, name, creationUserFullName, creationDate);
        });
        setAllLocationsProfiles(profileRows);
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const styleMultipleRows = (data, collection) => {
    if (collection === 'locations') {
      const rows = data.map((row) => {
        const { _id, level, name, creationUserFullName, creationDate, updateDate } = row;
        return createLocationProfileRow(
          _id,
          level,
          name,
          creationUserFullName,
          creationDate,
          updateDate
        );
      });
      return rows;
    }
  };

  const loadLocationsProfilesData = (collectionNames = ['locations'], searchBy) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      const localSearchBy = searchBy || tableControl.locations.searchBy;
      if (!isEmpty(tableControl[collectionName].tableGrouping)) {
        updateTableGroupingTree(
          collectionName,
          tableControl[collectionName].tableGrouping,
          styleMultipleRows,
          tableControl,
          (newTree) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                tableGrouping: newTree
              }
            }));
          },
          tableControl[collectionName].condition
        );
      }
      let queryLike = '';
      let searchByFiltered = localSearchBy;

      if (searchByFiltered && searchByFiltered === 'id') {
        searchByFiltered = '_id';
      }

      if (collectionName === 'locations') {
        queryLike = searchByFiltered
          ? [{ key: searchByFiltered, value: tableControl.locations.search }]
          : ['creationDate', 'level', 'name', 'updateDate'].map((key) => ({
            key,
            value: tableControl.locations.search
          }));
      }
      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response.count
            }
          }));
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          { key: tableControl[collectionName].orderBy, value: tableControl[collectionName].order }
        ],
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'locations') {
            const profileRows = data.response.map((row) => {
              const { _id, level, name, creationUserFullName, creationDate, updateDate } = row;
              return createLocationProfileRow(
                _id,
                level,
                name,
                creationUserFullName,
                creationDate,
                updateDate
              );
            });
            setLocationProfileRows(profileRows);
            setSelectedLocationProfileRows([]);
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };

  const handleSetProfileLocationFilter = (parent, level, realParent) => {
    const lvl = Number(level) + 1;
    setModalId(parent);
    getChildren(parent);
    getImageLayout(parent);
    getParentExt(realParent);
    getSelfCenter(parent);
    setParentSelected(parent);
    setRealParentSelected(realParent);
    let locationProf = allLocationsProfiles.filter(
      (row) => row.level === lvl || row.level === lvl?.toString()
    );
    setSelectedLocationProfileRows(locationProf);
  };

  const loadUserLocationsLocal = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: user?.id,
      allData: true
    });
    setUserLocations(userLocations);
  };

  useEffect(() => {
    loadUserLocationsLocal();
  }, []);

  useEffect(() => {
    handleLoadLocations();
  }, [userLocations]);

  useEffect(() => {
    if (anchorEl && !selectedLocationProfileRows.length) {
      const message = intl.formatMessage({
        id: 'LOCATIONS.WARNING.NO.PROFILES',
        defaultMessage: 'Text not rendered'
      });

      dispatch(
        showCustomAlert({
          open: true,
          message,
          type: 'warning'
        })
      );
      setAnchorEl(null);
    }
  }, [anchorEl]);

  useEffect(() => {
    loadLocationsProfilesData('locations');
  }, [
    tableControl.locations.page,
    tableControl.locations.rowsPerPage,
    tableControl.locations.order,
    tableControl.locations.orderBy,
    tableControl.locations.search,
    tableControl.locations.locationsFilter
  ]);

  const tabIntToText = ['', 'locations'];

  useEffect(() => {
    if (globalSearch.tabIndex >= 0) {
      setTab(globalSearch.tabIndex);
      setTableControl((prev) => ({
        ...prev,
        [tabIntToText[globalSearch.tabIndex]]: {
          ...prev[tabIntToText[globalSearch.tabIndex]],
          search: globalSearch.searchValue
        }
      }));
      setTimeout(() => {
        setGeneralSearch({});
      }, 800);
    }
  }, [globalSearch.tabIndex, globalSearch.searchValue]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          enableLoadingPreview();
          updateLayoutConfig(values);
        }}
        onReset={() => {
          enableLoadingReset();
          updateLayoutConfig(initLayoutConfig);
        }}
      >
        {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
          <div className="kt-form kt-form--label-right">
            <Portlet>
              <PortletHeader
                toolbar={
                  <PortletHeaderToolbar>
                    <Tabs
                      className="builder-tabs"
                      component="div"
                      onChange={(_, nextTab) => setTab(nextTab)}
                      value={tab}
                    >
                      {TabsTitles('locations', user.profilePermissions['locations'])}
                    </Tabs>
                  </PortletHeaderToolbar>
                }
              />
              {tab === 0 && (
                <PortletBody>
                  {/* <span className='kt-section__sub'>
                          This section will integrate <code>Locations Section</code>
                        </span> */}
                  {!userLocations ? (
                    <div className="circular-progress-indicator">
                      <CircularProgressCustom size={40} />
                    </div>
                  ) : isEmpty(userLocations) ? (
                    <Typography className="locations-list__no-locations" variant="h5">
                      You have no Locations Assigned
                    </Typography>
                  ) : (
                    <div className="kt-section kt-margin-t-0 kt-padding-b-25">
                      <div className="kt-section">
                        <ModalLocationList
                          editOrNew={editOrNew}
                          imageLayout={imageLayout}
                          modalId={modalId}
                          dataFromParent={modalData}
                          parent={parentSelected}
                          parentExt={parentFileExt}
                          policies={policies}
                          profile={profileSelected}
                          readOnly={listReadOnly}
                          realParent={realParentSelected}
                          reload={handleLoadLocations}
                          setParentSelected={setParentSelected}
                          setShowModal={setOpenListModal}
                          showModal={openListModal}
                          onLocationCreated={(locationId) => {
                            const locations = [...userLocations, locationId];
                            setUserLocations(locations);
                          }}
                          userID={user.id}
                        />
                        <ModalYesNo
                          message={GetTranslatedValue('LOCATIONS.CAPTION.DELETE.MESSAGE')}
                          onCancel={locationActions.closeYesNoModal}
                          onOK={locationActions.removeLocation}
                          showModal={openYesNoModal}
                          title={GetTranslatedValue('LOCATIONS.CAPTION.DELETE.TITLE')}
                        />
                        {/* Insert Tree here */}
                        <div className="locations-list__top">
                          <div className="locations-list_top-add">
                            <Menu
                              anchorEl={anchorEl}
                              id="simple-menu"
                              keepMounted
                              onClose={handleClose}
                              open={Boolean(anchorEl)}
                            >
                              {selectedLocationProfileRows.map((locProfile, ix) => (
                                <MenuItem
                                  onClick={() => handleOpenLocationListModal(locProfile)}
                                  key={`location-menuItem-${ix}`}
                                >
                                  {locProfile.name}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </div>
                        <div className="locations-list">
                          <div className="locations-list__left-content">
                            <div>
                              {(user.profilePermissions.locations['list'] || []).includes(
                                'add'
                              ) && (
                                  <Tooltip
                                    placement="top"
                                    title={GetTranslatedValue('LOCATIONS.LIST.ACTION.ADD')}
                                  >
                                    <IconButton
                                      aria-label="Filter list"
                                      onClick={locationActions.openProfilesListBox}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {(user.profilePermissions.locations['list'] || []).includes(
                                'view'
                              ) && (
                                  <Tooltip
                                    placement="top"
                                    title={GetTranslatedValue('LOCATIONS.LIST.ACTION.VIEW')}
                                  >
                                    <IconButton
                                      aria-label="Filter list"
                                      onClick={locationActions.viewLocation}
                                    >
                                      <RemoveRedEye />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {(user.profilePermissions.locations['list'] || []).includes(
                                'edit'
                              ) && (
                                  <Tooltip
                                    placement="top"
                                    title={GetTranslatedValue('LOCATIONS.LIST.ACTION.EDIT')}
                                  >
                                    <IconButton
                                      aria-label="Filter list"
                                      onClick={locationActions.editLocation}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {(user.profilePermissions.locations['list'] || []).includes(
                                'delete'
                              ) && (
                                  <Tooltip
                                    placement="top"
                                    title={GetTranslatedValue('LOCATIONS.LIST.ACTION.REMOVE')}
                                  >
                                    <IconButton
                                      aria-label="Filter list"
                                      onClick={locationActions.openYesNoModal}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </div>
                            <div className="locations">
                              <TreeView
                                data={locationsTree}
                                onClick={handleSetProfileLocationFilter}
                                selected={parentSelected}
                              />
                            </div>
                          </div>
                          <div className="locations-list__right-content">
                            <Paper>
                              <Tabs
                                indicatorColor="primary"
                                onChange={handleChange4}
                                textColor="primary"
                                value={value4}
                                variant="fullWidth"
                              >
                                <Tab label={GetTranslatedValue('LOCATIONS.CAPTION.MAP.VIEW')} />
                                <Tab label={GetTranslatedValue('LOCATIONS.CAPTION.LAYOUT.VIEW')} />
                              </Tabs>
                            </Paper>
                            <SwipeableViews
                              axis={'x'}
                              index={value4}
                              onChangeIndex={handleChangeIndex4}
                            >
                              <TabContainer4 dir={theme4.direction}>
                                {!isEmpty(coordinates) ? (
                                  <div className="locations-list__map-view">
                                    <GoogleMaps
                                      center={mapCenter}
                                      coords={coordinates}
                                      setCoords={({ lat, lng }) => setCoordinates({ lat, lng })}
                                      setZoom={setGoogleMapsZoom}
                                      style={{
                                        width: '100%',
                                        height: '500px',
                                        position: 'relative'
                                      }}
                                      zoom={googleMapsZoom}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      paddingTop: '50px'
                                    }}
                                  >
                                    <h6 style={{ alignSelf: 'center' }}>
                                      {GetTranslatedValue(
                                        'ASSETS.MODAL.TAB.OTHER.NO.MAP.INFO',
                                        'No Map Information Found'
                                      )}
                                    </h6>
                                  </div>
                                )}
                              </TabContainer4>
                              <TabContainer4 dir={theme4.direction}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <div style={{ height: '480px', width: '600px' }}>
                                    <ImageMarker
                                      src={
                                        imageLayout
                                          ? `${imageLayout}?${new Date()}`
                                          : `${window.location.origin}/media/misc/placeholder-image.jpg`
                                      }
                                      markers={markers}
                                      markerComponent={CustomMarker}
                                    />
                                  </div>
                                </div>
                              </TabContainer4>
                            </SwipeableViews>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </PortletBody>
              )}
              {tab === 1 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        {/* <span className='kt-section__sub'>
                          This section will integrate <code>Locations/Profile Table</code>
                        </span> */}
                        <ModalLocationProfiles
                          id={id}
                          fields={(allFields || {})['locations']}
                          fieldsToValidate={(fieldsToValidate || {})['locations']}
                          policies={policies}
                          readOnly={readOnly}
                          reloadTable={loadLocationsProfilesData}
                          setShowModal={setOpenProfileModal}
                          showModal={openProfileModal}
                          updateGeneralProfileLocations={updateGeneralProfileLocations}
                        />
                        {/* <div className='kt-separator kt-separator--dashed' /> */}
                        <div className="kt-section__content">
                          <TableComponent2
                            controlValues={tableControl.locations}
                            headRows={locHeadRows}
                            onAdd={onAddProfileLocation}
                            onDelete={onDeleteProfileLocation}
                            onEdit={onEditProfileLocation}
                            onSelect={setProfileSelectedId}
                            onView={onViewProfileLocation}
                            paginationControl={({ rowsPerPage, page }) =>
                              setTableControl((prev) => ({
                                ...prev,
                                locations: {
                                  ...prev.locations,
                                  rowsPerPage: rowsPerPage,
                                  page: page
                                }
                              }))
                            }
                            rows={locationProfileRows}
                            searchControl={({ value, field }) => {
                              if (tableControl.locations.search === value) {
                                loadLocationsProfilesData('locations', field);
                              } else {
                                setTableControl((prev) => ({
                                  ...prev,
                                  locations: {
                                    ...prev.locations,
                                    search: value,
                                    searchBy: field
                                  }
                                }));
                              }
                            }}
                            sortByControl={({ orderBy, order }) => {
                              setTableControl((prev) => ({
                                ...prev,
                                locations: {
                                  ...prev.locations,
                                  orderBy: orderBy,
                                  order: order
                                }
                              }));
                            }}
                            fetchTableGroupingData={loadGroups}
                            setTableGroupingControl={(tree) =>
                              setTableControl((prev) => ({
                                ...prev,
                                locations: {
                                  ...prev.locations,
                                  tableGrouping: tree
                                }
                              }))
                            }
                            tableGroupingCreateRows={styleMultipleRows}
                            tableGrouping
                            tab={getCurrentModuleName('locations', tab)}
                            title={GetTranslatedValue('LOCATION.PROFILES.TABLE.CAPTION')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 2 && (
                <Policies
                  setPolicies={setPolicies}
                  module="locations"
                  baseFields={policiesBaseFields}
                />
              )}
            </Portlet>
          </div>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = ({ general: { globalSearch }, auth: { user } }) => ({
  globalSearch,
  user
});
export default connect(mapStateToProps, general.actions)(Locations);
