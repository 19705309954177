export const data = [
    {
        type: 0,
        text: 'Assets',
        translation: 'MENU.ASSETS'
    },
    {
        type: 1,
        text: 'Search in Asset List...',
        module: 'assets',
        tabIndex: 0,
        translation: 'TOPBAR.SEARCH.IN.ASSETS.LIST'
    },
    {
        type: 1,
        text: 'Search in Asset References...',
        module: 'assets',
        tabIndex: 1,
        translation: 'TOPBAR.SEARCH.IN.ASSETS.REFERENCES'
    },
    {
        type: 1,
        text: 'Search in Asset Categories...',
        module: 'assets',
        tabIndex: 2,
        translation: 'TOPBAR.SEARCH.IN.ASSETS.CATEGORIES'
    },
    {
        type: 0,
        text: 'Users',
        translation: 'MENU.USERS'
    },
    {
        type: 1,
        text: 'Search in User List...',
        module: 'users',
        tabIndex: 0,
        translation: 'TOPBAR.SEARCH.IN.USERS.LIST'
    },
    {
        type: 1,
        text: 'Search in User References...',
        module: 'users',
        tabIndex: 1,
        translation: 'TOPBAR.SEARCH.IN.USERS.PROFILES'
    },
    {
        type: 0,
        text: 'Employees',
        translation: 'MENU.EMPLOYEES'
    },
    {
        type: 1,
        text: 'Search in Employee List...',
        module: 'employees',
        tabIndex: 0,
        translation: 'TOPBAR.SEARCH.IN.EMPLOYEES.LIST'
    },
    {
        type: 1,
        text: 'Search in Employee References...',
        module: 'employees',
        tabIndex: 1,
        translation: 'TOPBAR.SEARCH.IN.EMPLOYEES.PROFILES'
    },
    {
        type: 0,
        text: 'Locations',
        translation: 'MENU.LOCATIONS'
    },
    {
        type: 1,
        text: 'Search in Location Profiles...',
        module: 'locations',
        tabIndex: 1,
        translation: 'TOPBAR.SEARCH.IN.LOCATIONS.PROFILES'
    },
];
