/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { actions } from '../../../../../store/ducks/general.duck';
import { getOneDB, updateDB, postDB, getDBComplex } from '../../../../../crud/api';
import CustomRecordModal from '../../../Components/CustomRecordModal';
import { GetTranslatedValue } from '../../../utils';
const {
  showCustomAlert,
} = actions;


// Example 5 - Modal
const styles5 = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle5 = withStyles(styles5)(({ children, classes, onClose }) => {
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContent5 = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(DialogContent);

const DialogActions5 = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(DialogActions);

const ModalConstants = ({ readOnly, showModal, setShowModal, reloadTable, id, employeeProfileRows }) => {
  const dispatch = useDispatch();
  const { showErrorAlert, showSavedAlert, showSelectValuesAlert, showUpdatedAlert } = actions;
  const intl = useIntl();
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSave = () => {
    const { name, value } = values;
    if (!name.trim() || !value.trim()) {
      dispatch(showSelectValuesAlert());
      return;
    }
    const body = { ...values };
    const message = intl.formatMessage({
      id: 'SETTINGS.CUSTOM.CONSTANTS.MODAL.WARNING.NAME',
      defaultMessage: 'Text not rendered'
    });
    if (!id) {
      getDBComplex({
        collection: 'settingsConstants',
        condition: [{ name: values.name }]
      }).then((response) => response.json())
        .then((data) => {
          const { response } = data;
          if (!isEmpty(response)) {
            dispatch(
              showCustomAlert({
                open: true,
                message,
                type: 'warning'
              })
            );
            return;
          } else {
            postDB('settingsConstants', body)
            .then(data => data.json())
            .then(response => {
              dispatch(showSavedAlert());
              const { _id } = response.response[0];
              saveAndReload('settingsConstants', _id);
              handleCloseModal();
            })
            .catch(error => dispatch(showErrorAlert()));
          }
        })
        .catch((error) => {console.log('error:', error)})

    } else {
      getDBComplex({
        collection: 'settingsConstants',
        condition: [{ name: values.name }]
      }).then((response) => response.json())
        .then((data) => {
          const response = data.response.filter(({ _id }) => _id !== id[0]);
          if (!isEmpty(response)) {
            dispatch(
              showCustomAlert({
                open: true,
                message,
                type: 'warning'
              })
            );
            return;
          } else {
            updateDB('settingsConstants/', body, id[0])
            .then(response => {
              dispatch(showUpdatedAlert());
              saveAndReload('settingsConstants', id[0]);
              handleCloseModal();
            })
            .catch(error => dispatch(showErrorAlert()));
          }
        })
        .catch((error) => {console.log('error:', error)})
    }
    
  };

  const saveAndReload = (folderName, id) => {
    reloadTable();
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };
  const reset = () => {
    setValues({
      name: '',
      value: ''
    });
  };

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      reset();
      // loadInitData();
      return;
    }

    getOneDB('settingsConstants/', id[0])
      .then(response => response.json())
      .then(data => {
        const values = omit(data.response, '_id');
        setValues(values);
      })
      .catch(error => dispatch(showErrorAlert()));
  }, [id, employeeProfileRows, showModal]);

  const [values, setValues] = useState({
    name: '',
    value: ''
  });

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Locations-Profile-Modal"
      readOnly={readOnly}
      showModal={showModal}
      title="SETTINGS.CUSTOM.CONSTANTS.MODAL.CAPTION"
    >
      <div className="kt-section__content">
        <div style={{ minHeight: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} className="profile-tab-wrapper">
          <div style={{ margin: '0px 8px', display: 'flex', flexDirection: 'column', padding:'20px' }}>
            <TextField
              disabled={readOnly}
              label={GetTranslatedValue('RECORD.CAPTION.NAME')}
              style={{ marginTop: '-20px', width: '100%' }}
              value={values.name}
              onChange={handleChange('name')}
              margin="normal"
            />
            <TextField
              disabled={readOnly}
              label={GetTranslatedValue('SETTINGS.CUSTOM.CAPTION.CONSTANTS.VALUE')}
              style={{ marginTop: '20px', width: '100%' }}
              value={values.value}
              onChange={handleChange('value')}
              margin="normal"
            />
          </div>
        </div>
      </div>
    </CustomRecordModal>
  )
};

export default ModalConstants;
