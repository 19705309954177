import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { reportsModules } from '../../constants';
import { GetTranslatedValue } from '../../utils';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const Permission = ({
  id,
  items,
  module,
  originalChecked,
  permissions,
  readOnly,
  setPermissions,
  title
}) => {
  const [checked, setChecked] = useState((permissions[module] || {})[id] || []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);

      if (id !== 'others') {
        if (
          (items || []).map(({ key }) => key).includes('view') &&
          value !== 'view' &&
          !newChecked.includes('view')
        ) {
          newChecked.push('view');
        }
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setPermissions(module, id, newChecked);
  };

  const numberOfChecked = (items) => {
    const itemsTmp = items[0].key ? items.map((i) => i.key) : items;

    return intersection(checked, itemsTmp).length;
  };

  const handleToggleAll = (items) => () => {
    const itemsTmp = !Array.isArray(items) ? Object.keys(items).map((i) => i) : items.map((i) => i.key);
    if (numberOfChecked(itemsTmp) === itemsTmp.length) {
      const array = not(checked, itemsTmp);
      setChecked(array);
      setPermissions(module, id, array);
    } else {
      const array = union(checked, itemsTmp);
      setChecked(array);
      setPermissions(module, id, array);
    }
  };

  const getPermissionsLength = () => {
    const currentPermission = (permissions[module] || {})[id] || [];
    const isReportsGeneral = module === 'reports' && id === 'general';

    if (isReportsGeneral) {
      return reportsModules.filter(({ id }) => currentPermission.includes(id))?.length;
    } else {
      return currentPermission?.length;
    }
  };

  useEffect(() => {
    if (!originalChecked || !originalChecked[id]) {
      return;
    }
    setChecked((permissions[module] || {})[id] || []);
  }, [originalChecked]);

  const length = getPermissionsLength();

  return (
    <div style={{ padding: '15px 15px 15px 0px' }}>
      <Card
        style={{
          height: isEmpty(items) ? '75px' : '280px',
          paddingRight: '20px',
          width: 'fit-content'
        }}
      >
        <CardHeader
          avatar={
            isEmpty(items) ? (
              <Checkbox
                checked={(permissions[module] || {})[id]}
                disabled={readOnly}
                onClick={() =>
                  setPermissions(module, id, !(permissions[module] || {})[id] || false)
                }
                readOnly={readOnly}
              />
            ) : (
              <Checkbox
                checked={!Array.isArray(items) ? length === Object.keys(items)?.length : length === items.length}
                disabled={readOnly}
                indeterminate={(!Array.isArray(items) ? length !== Object.keys(items)?.length : length !== items.length) && length !== 0}
                inputProps={{ 'aria-label': 'all items selected' }}
                onClick={handleToggleAll(items)}
                readOnly={readOnly}
              />
            )
          }
          subheader={
            isEmpty(items)
              ? ''
              : `${length}/${(Array.isArray(items) ? items : Object.keys(items)).length} ${GetTranslatedValue('GENERAL.CAPTION.SELECTED')}`
          }
          title={GetTranslatedValue(
            `TABS.TITLES.${title === 'Layouts & Presets' ? 'Layouts' : title}`.toUpperCase()
          )}
        />
        {!isEmpty(items) && (
          <>
            <Divider style={{ marginLeft: '20px' }} />
            <List
              component="div"
              dense
              role="list"
              style={{ maxHeight: items.length > 4 ? '182px' : 'auto', overflow: 'auto' }}
            >
              {typeof items === 'object' && !Array.isArray(items) ? (
                Object.entries(items).map(([key, value]) => {
                  const labelId = `transfer-list-all-item-${value.name}-label`;

                  return (
                    <ListItem
                      key={key}
                      role="listitem"
                      button
                      onClick={handleToggle(key)}
                      disabled={readOnly}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={((permissions[module] || {})[id] || []).includes(key)}
                          disabled={readOnly}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          readOnly={readOnly}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={GetTranslatedValue(`GENERAL.CAPTION.${key}`.toUpperCase())}
                      />
                    </ListItem>
                  );
                })
              ) : (
                items.map((value) => {
                  const labelId = `transfer-list-all-item-${value.name}-label`;
  
                  return (
                    <ListItem
                      key={value.key}
                      role="listitem"
                      button
                      onClick={handleToggle(value.key)}
                      disabled={readOnly}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={((permissions[module] || {})[id] || []).indexOf(value.key) !== -1}
                          disabled={readOnly}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          readOnly={readOnly}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={GetTranslatedValue(`GENERAL.CAPTION.${value.key}`.toUpperCase())}
                      />
                    </ListItem>
                  );
                })
              )}
              <ListItem />
            </List>
          </>
        )}
      </Card>
    </div>
  );
};

export default Permission;
