import React, { useEffect, useState } from 'react';
import { omit } from 'lodash';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  withStyles,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { GetTranslatedValue } from '../../../../pages/home/utils';
import { getOneDB } from '../../../../crud/api';
import AssetFinder from '../../Components/AssetFinder';

const styles5 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle5 = withStyles(styles5)(({ children, classes, onClose }) => {
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContent5 = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(DialogContent);

const DialogActions5 = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(DialogActions);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 600
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 600
  },
  dialog: {
    maxWidth: '900px !important',
    width: '100%'
  }
}));

const ModalAssetFinder = ({
  employeeProfileRows,
  id,
  onAssetFinderSubmit,
  reloadTable,
  showModal,
  setShowModal,
  userAssets,
  parentLocations,
  locationsTreeView
}) => {
  const classes = useStyles();
  const [tableRowsInner, setTableRowsInner] = useState({ rows: [] });
  const [values, setValues] = useState({
    name: '',
    value: ''
  });

  const handleSave = () => {
    onAssetFinderSubmit(tableRowsInner);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
    setTableRowsInner({ rows: [] });
  };

  const reset = () => {
    setValues({
      name: '',
      value: ''
    });
  };

  useEffect(() => {
    if (!id || !Array.isArray(id)) {
      reset();
      return;
    }

    getOneDB('settingsConstants/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const values = omit(data.response, '_id');
        setValues(values);
      })
      .catch((error) => console.log(error));
  }, [id, employeeProfileRows]);

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={handleCloseModal}
        open={showModal}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle5 id="customized-dialog-title" onClose={handleCloseModal}>
          {GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENTS.CAPTION.TITLE')}
        </DialogTitle5>
        <DialogContent5 dividers>
          <div className="kt-section__content">
            <div
              className="profile-tab-wrapper"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                minHeight: '100px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 8px' }}>
                <AssetFinder
                  locationsTreeView={locationsTreeView}
                  setTableRowsInner={setTableRowsInner}
                  userAssets={userAssets}
                  parentLocations={parentLocations}
                />
              </div>
            </div>
          </div>
        </DialogContent5>
        <DialogActions5>
          <Button color="primary" onClick={handleSave}>
            {GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENTS.CAPTION.BUTTON')}
          </Button>
        </DialogActions5>
      </Dialog>
    </div>
  );
};

export default ModalAssetFinder;
