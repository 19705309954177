/* eslint-disable no-restricted-imports */
import React, { useMemo, useState } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik, } from "formik";
import { isEmpty, merge } from "lodash";
import { makeStyles, Tabs } from "@material-ui/core";
import { metronic, initLayoutConfig, LayoutConfig } from "../../../../_metronic";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { tabsConfig } from "../Components/Translations/constants";
import { TabsTitles } from '../Components/Translations/tabsTitles';
import ModalYesNo from '../Components/ModalYesNo';
import {
  General,
  Groups,
  Design,
  LayoutsPresets,
  Fields,
  Custom,
  Users,
  Processes,
  Import
} from './settings-tabs';
import { getCurrentModuleTab } from "../utils";

const useStyles = makeStyles(() => ({
  flexContainer: {
    width: 'max-content'
  }
}));

const Settings = ({ user }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(getCurrentModuleTab('settings', user.profilePermissions));
  const dispatch = useDispatch();
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );

  const permissions = user.profilePermissions.settings || [];

  const updateLayoutConfig = _config => {
    dispatch(metronic.builder.actions.setLayoutConfigs(_config));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const initialValues = useMemo(
    () =>
      merge(
        LayoutConfig,
        layoutConfig
      ),
    [layoutConfig]
  );

  const [selectReferenceConfirmation, setSelectReferenceConfirmation] = useState(false);

  return (
    <>
      <ModalYesNo
        showModal={selectReferenceConfirmation}
        onOK={() => setSelectReferenceConfirmation(false)}
        onCancel={() => setSelectReferenceConfirmation(false)}
        title={'Add New Asset'}
        message={'Please first select a Reference from the next tab'}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          updateLayoutConfig(values);
        }}
        onReset={() => {
          updateLayoutConfig(initLayoutConfig);
        }}
      >
        {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
          <div className="kt-form kt-form--label-right">
            <Portlet>
              <PortletHeader
                toolbar={
                  <PortletHeaderToolbar>
                    <Tabs
                      classes={{
                        flexContainer: classes.flexContainer
                      }}
                      component="div"
                      className="builder-tabs"
                      onChange={(_, nextTab) => setTab(nextTab)}
                      value={tab}
                      variant="scrollable"
                    >
                      {TabsTitles('settings', user.profilePermissions['settings'])}
                    </Tabs>
                  </PortletHeaderToolbar>
                }
              />
              {tab === 0 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <General permissions={permissions['general']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 1 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Design permissions={permissions['design']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 2 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <LayoutsPresets permissions={permissions['layouts & presets']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 3 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Fields permissions={permissions['fields']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 4 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Custom permissions={permissions['custom']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 5 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Users permissions={permissions['users']} currentUser={user}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 6 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Processes permissions={permissions['processes']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 7 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                    <div className="kt-section__body">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <Groups permissions={permissions['groups']} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              )}
              {tab === 8 && (
                <PortletBody>
                  <div className="kt-section kt-margin-t-0">
                    <div className="kt-section__body">
                      <Import permissions={permissions['import']} />
                    </div>
                  </div>
                </PortletBody>
              )}
            </Portlet>
          </div>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});
export default connect(mapStateToProps)(Settings);
