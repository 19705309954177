/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, useRef } from 'react';
import { omit } from 'lodash';
import { useFormikContext } from 'formik';
import { ColorPicker } from 'material-ui-color';
import { FormControlLabel, makeStyles, Tabs, Tab, TextField, Switch } from '@material-ui/core';
import { useDispatch, connect } from 'react-redux';
import * as designStore from '../../../../store/ducks/design.duck';
import { actions } from '../../../../store/ducks/general.duck';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../partials/content/Portlet';
import { getDB, postDB, updateDB } from '../../../../crud/api';
import ImageUpload from '../../Components/ImageUpload';
import {
  hosts,
  getFileExtension,
  saveImage,
  getFirstDocCollection,
  GetTranslatedValue,
  LightenDarkenColor
} from '../../utils';
import { GenerateSubTabs } from '../../Components/Translations/tabsTitles';
import Builder from '../../Builder';
import SaveButton from '../settings-tabs/components/SaveButton';
import './settings-tabs.scss';
import clsx from 'clsx';

const { apiHost, localHost } = hosts;

const useStyles = makeStyles((theme) => ({
  buttons: {
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '15px 0px 0px 15px',
    textAlign: 'end',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      margin: '15px 15px 0px 0px'
    }
  },
  header: {
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px'
    }
  },
  resetButton: {
    marginLeft: '10px',
    marginTop: '0px',
    paddingRight: '2.5rem'
  }
}));

const Design = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorAlert, showSavedAlert, showUpdatedAlert } = actions;
  const { setAppColors } = designStore.actions;
  const [tab, setTab] = useState(0);
  const [values, setValues] = useState({
    appName: 'AssetsApp',
    appColors: props.appColors,
    logoTitle: '',
    logoMessage: '',
    logoWatermark: '',
    isPrivacy: '',
    privacyTitle: '',
    privacyURL: '',
    isLegal: '',
    legalTitle: '',
    legalURL: '',
    isContact: '',
    contactTitle: '',
    contactURL: '',
    loginBackgroundColor: '#000',
    sideBarTitle: ''
  });
  const [imageURLS, setImagesURLS] = useState({
    logoLogin: `${localHost}/media/misc/placeholder-image.jpg`,
    logoBackground: `${localHost}/media/misc/placeholder-image.jpg`,
    logoSideBar: `${localHost}/media/misc/placeholder-image.jpg`
  });

  const handleChangeAppColors = (name, newValue)  => {
    if (typeof newValue === 'string') {
      return;
    }
    if (name === 'sideNavBarBackground') {
      const appColorsCopy = JSON.parse(JSON.stringify(values.appColors));

      const newColor = LightenDarkenColor(`#${newValue.hex}`, -40);
      appColorsCopy['sideNavBarTabSelected'] = newColor;
      appColorsCopy['sideNavBarBackground'] = `#${newValue.hex}`;

      setValues({ ...values, appColors: appColorsCopy });
      return;
    } else if (name === 'sideNavBarTabIcons') {
      const appColorsCopy = JSON.parse(JSON.stringify(values.appColors));

      const newColor = LightenDarkenColor(`#${newValue.hex}`, 60);
      appColorsCopy['sideNavBarTabIconsHover'] = newColor;
      appColorsCopy['sideNavBarTabIcons'] = `#${newValue.hex}`;
      setValues({ ...values, appColors: appColorsCopy });
      return;
    } else if (name === 'sideNavBarTabTitles') {
      const appColorsCopy = JSON.parse(JSON.stringify(values.appColors));

      const newColor = LightenDarkenColor(`#${newValue.hex}`, 60);
      appColorsCopy['sideNavBarTabTitlesHover'] = newColor;
      appColorsCopy['sideNavBarTabTitles'] = `#${newValue.hex}`;
      setValues({ ...values, appColors: appColorsCopy });
      return;
    }

    setValues({
      ...values,
      appColors: {
        ...values.appColors,
        [name]: `#${newValue.hex}`
      }
    });
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangeCheck = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };
  const handleImageChange = (name, value) => {
    setImagesURLS((prev) => ({ ...prev, [name]: value }));
  };

  const [layoutValues, setLayoutValues] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    const imagesInfo = [];
    const logoLoginExt = !logoLogin
      ? null
      : typeof logoLogin !== 'object'
        ? values.logoLoginExt
        : getFileExtension(logoLogin);

    if (logoLogin) {
      imagesInfo.push({ image: logoLogin, folderName: 'settingsDesign', id: 'logoLogin' });
    }

    const logoBackgroundExt = !logoBackground
      ? null
      : typeof logoBackground !== 'object'
        ? values.logoBackgroundExt
        : getFileExtension(logoBackground);

    if (logoBackground) {
      imagesInfo.push({
        image: logoBackground,
        folderName: 'settingsDesign',
        id: 'logoBackground'
      });
    }

    const logoSideBarExt = !logoSideBar
      ? null
      : typeof logoSideBar !== 'object'
        ? values.logoSideBarExt
        : getFileExtension(logoSideBar);

    if (logoSideBar) {
      imagesInfo.push({ image: logoSideBar, folderName: 'settingsDesign', id: 'logoSideBar' });
    }

    const favIconExt = !favIcon
      ? null
      : typeof favIcon !== 'object'
        ? values.favIconExt
        : getFileExtension(favIcon);

    if (favIcon) {
      imagesInfo.push({ image: favIcon, folderName: 'settingsDesign', id: 'favIcon' });
    }

    const body = { ...values, logoLoginExt, logoBackgroundExt, logoSideBarExt, favIconExt };
    getFirstDocCollection('settingsDesign')
      .then((id) => {
        if (!id) {
          postDB('settingsDesign', body)
            .then((data) => data.json())
            .then((response) => {
              saveImages(imagesInfo);
              dispatch(showSavedAlert());
            })
            .catch((error) => {
              console.log(error);
              dispatch(showErrorAlert());
            });
          setLoading(false);
        } else {
          updateDB('settingsDesign/', body, id)
            .then((response) => {
              saveImages(imagesInfo);
              dispatch(showUpdatedAlert());
            })
            .catch((error) => {
              console.log(error);
              dispatch(showErrorAlert());
            });
          setLoading(false);
        }
      })
      .catch((ex) => {
        dispatch(showErrorAlert());
      });
  };

  const [initialImages, setInitialImages] = useState({
    logoBackground: null,
    logoLogin: null,
    logoSideBar: null
  });
  const [logoLogin, setLogoLogin] = useState(null);
  const [logoBackground, setLogoBackground] = useState(null);
  const [favIcon, setFavIcon] = useState(null);
  const [logoSideBar, setLogoSideBar] = useState(null);
  const [loginColor, setloginColor] = useState(null);
  const Formik = useFormikContext();
  const saveImages = (info = []) => {
    info.forEach(({ image, folderName, id }) => {
      if (typeof image !== 'object' || initialImages[id] === image) {
        return;
      }
      saveImage(image, folderName, id);
    });
  };

  const loadProcessesData = (collectionName = 'settingsDesign') => {
    getDB(collectionName)
      .then((response) => response.json())
      .then((data) => {
        const _values = data.response[0] || {};
        _values.appColors = { ...props.appColors, ..._values.appColors };
        dispatch(setAppColors(_values.appColors));
        setValues({ ...values, ...omit(_values, '_id') });
        const {
          logoLoginExt,
          logoBackgroundExt,
          loginBackgroundColor,
          logoSideBarExt,
          favIconExt
        } = _values;
        if (logoLoginExt) {
          handleImageChange(
            'logoLogin',
            `${apiHost}/uploads/settingsDesign/logoLogin.${logoLoginExt}`
          );
          setLogoLogin(true);
          setInitialImages((prev) => ({ ...prev, logoLogin: true }));
        }
        if (logoBackgroundExt) {
          handleImageChange(
            'logoBackground',
            `${apiHost}/uploads/settingsDesign/logoBackground.${logoBackgroundExt}`
          );
          setLogoBackground(true);
          setInitialImages((prev) => ({ ...prev, logoBackground: true }));
        }
        if (logoSideBarExt) {
          handleImageChange(
            'logoSideBar',
            `${apiHost}/uploads/settingsDesign/logoSideBar.${logoSideBarExt}`
          );
          setLogoSideBar(true);
          setInitialImages((prev) => ({ ...prev, logoSideBar: true }));
        }
        if (favIconExt) {
          handleImageChange('favIcon', `${apiHost}/uploads/settingsDesign/favIcon.${favIconExt}`);
          setFavIcon(true);
          setInitialImages((prev) => ({ ...prev, favIcon: true }));
        }
        if (loginBackgroundColor) {
          handleChangeColor(loginBackgroundColor);
        }
      })
      .catch((error) => console.log('error>', error));
  };

  useEffect(() => {
    loadProcessesData();
  }, []);

  const handleChangeColor = async (newValue) => {
    if (typeof newValue === 'string') {
      return;
    }
    setloginColor(newValue);
    setValues((prev) => ({ ...prev, loginBackgroundColor: `#${newValue.hex}` }));
  };

  const builderRef = useRef();
  const readOnly = !props.permissions.includes('edit');

  return (
    <div>
      <Portlet>
        <PortletHeader
          toolbar={
            <PortletHeaderToolbar
              className={clsx(classes.header, 'PortletHeaderToolbar-Settings-Design-MainApp')}
            >
              <div>
                <Tabs
                  component="div"
                  className="builder-tabs"
                  value={tab}
                  onChange={(_, nextTab) => {
                    setTab(nextTab);
                  }}
                >
                  {GenerateSubTabs('settings', 'Design')}
                </Tabs>
              </div>
            </PortletHeaderToolbar>
          }
        />
        {!readOnly && (
          <div className={classes.buttons}>
            <SaveButton
              loading={loading}
              handleOnClick={() => {
                setLoading(true);
                handleSave();
                if (tab === 1) {
                  builderRef.current.update(layoutValues);
                }
              }}
            />
            {
              // This button has no purpose
              /* {tab === 1 && (
              <button
                className={clsx(
                  classes.resetButton,
                  'btn btn-secondary btn-elevate kt-login__btn-primary'
                )}
                onClick={() => Formik.handleReset()}
                type="button"
              >
                <i className='la la-recycle' /> {GetTranslatedValue('SETTINGS.DESIGN.APP.GENERAL.CAPTION.RESET')}
              </button>
            )} */
            }
          </div>
        )}
        {tab === 0 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <div style={{ display: 'flex', marginTop: '20px' }} className="settings-design">
                    <div className="settings-design__content__col">
                      <div className="settings-design__content__col__image-upload">
                        <ImageUpload
                          disabled={!props.permissions.includes('edit')}
                          id="logo-login-image"
                          image={imageURLS.logoLogin}
                          setImage={setLogoLogin}
                          setInitialImage={(image) =>
                            setInitialImages((prev) => ({ ...prev, logoLogin: image }))
                          }
                        >
                          {GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LOGO.LOGIN')}
                        </ImageUpload>
                      </div>
                      <div className="settings-design__content__col__fields">
                        <TextField
                          disabled={readOnly}
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LOGO.TITLE')}
                          style={{ width: '200px' }}
                          value={values.logoTitle}
                          onChange={handleChange('logoTitle')}
                          margin="normal"
                        />
                        <TextField
                          disabled={readOnly}
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LOGO.MESSAGE')}
                          style={{ width: '200px' }}
                          value={values.logoMessage}
                          onChange={handleChange('logoMessage')}
                          margin="normal"
                        />
                        <TextField
                          disabled={readOnly}
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LOGO.WATERMARK')}
                          style={{ width: '200px' }}
                          value={values.logoWatermark}
                          onChange={handleChange('logoWatermark')}
                          margin="normal"
                        />
                      </div>
                    </div>
                    <div className="settings-design__content__fields">
                      <div className="settings-design__content__fields__col">
                        <FormControlLabel
                          disabled={readOnly}
                          value="start"
                          control={
                            <Switch
                              color="primary"
                              checked={values.isPrivacy}
                              onChange={handleChangeCheck('isPrivacy')}
                            />
                          }
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.PRIVACY.LINK')}
                          labelPlacement="start"
                        />
                        <TextField
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.PRIVACY.TITLE')}
                          style={{ width: '200px' }}
                          value={values.privacyTitle}
                          onChange={handleChange('privacyTitle')}
                          margin="normal"
                          disabled={readOnly || !values.isPrivacy}
                        />
                        <TextField
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.PRIVACY.URL')}
                          style={{ width: '200px' }}
                          value={values.privacyURL}
                          onChange={handleChange('privacyURL')}
                          margin="normal"
                          disabled={readOnly || !values.isPrivacy}
                        />
                      </div>
                      <div className="settings-design__content__fields__col">
                        <FormControlLabel
                          disabled={readOnly}
                          value="start"
                          control={
                            <Switch
                              color="primary"
                              checked={values.isLegal}
                              onChange={handleChangeCheck('isLegal')}
                            />
                          }
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LEGAL.LINK')}
                          labelPlacement="start"
                        />
                        <TextField
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LEGAL.TITLE')}
                          style={{ width: '200px' }}
                          value={values.legalTitle}
                          onChange={handleChange('legalTitle')}
                          margin="normal"
                          disabled={readOnly || !values.isLegal}
                        />
                        <TextField
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LEGAL.URL')}
                          style={{ width: '200px' }}
                          value={values.legalURL}
                          onChange={handleChange('legalURL')}
                          margin="normal"
                          disabled={readOnly || !values.isLegal}
                        />
                      </div>
                      <div className="settings-design__content__fields__col">
                        <FormControlLabel
                          disabled={readOnly}
                          value="start"
                          control={
                            <Switch
                              color="primary"
                              checked={values.isContact}
                              onChange={handleChangeCheck('isContact')}
                            />
                          }
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.CONTACT.LINK')}
                          labelPlacement="start"
                        />
                        <TextField
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.CONTACT.TITLE')}
                          style={{ width: '200px' }}
                          value={values.contactTitle}
                          onChange={handleChange('contactTitle')}
                          margin="normal"
                          disabled={readOnly || !values.isContact}
                        />
                        <TextField
                          label={GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.CONTACT.URL')}
                          style={{ width: '200px', marginbottom: '20px' }}
                          value={values.contactURL}
                          onChange={handleChange('contactURL')}
                          margin="normal"
                          disabled={readOnly || !values.isContact}
                        />
                      </div>
                    </div>
                    <div className="settings-design__content__col">
                      <div className="settings-design__content__col__image-upload">
                        <ImageUpload
                          disabled={!props.permissions.includes('edit')}
                          id="logo-background-image"
                          image={imageURLS.logoBackground}
                          setImage={setLogoBackground}
                          setInitialImage={(image) =>
                            setInitialImages((prev) => ({ ...prev, logoBackground: image }))
                          }
                        >
                          {GetTranslatedValue('SETTINGS.DESIGN.LOGIN.CAPTION.LOGO.BACKGROUND')}
                        </ImageUpload>
                      </div>
                      <div className="settings-design__content__col__fields">
                        <FormControlLabel
                          disabled={readOnly}
                          style={{ marginTop: '20px' }}
                          value="2"
                          control={
                            <ColorPicker
                              value={loginColor}
                              onChange={handleChangeColor}
                              disableAlpha
                            />
                          }
                          label={GetTranslatedValue(
                            'SETTINGS.DESIGN.LOGIN.CAPTION.LOGIN.BACKGROUND.COLOR'
                          )}
                          labelPlacement="top"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="kt-separator kt-separator--dashed" />
                  <div className="kt-section__content"></div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}

        {tab === 1 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <Builder
                    appName={values.appName}
                    colorValues={values.appColors || props.appColors}
                    logoImage={imageURLS.logoSideBar}
                    onChange={handleChange}
                    onChangeColors={handleChangeAppColors}
                    readOnly={!props.permissions.includes('edit')}
                    ref={builderRef}
                    sideTitleValue={values.sideBarTitle}
                    setInitialLogoImage={(image) =>
                      setInitialImages((prev) => ({ ...prev, logoSideBar: image }))
                    }
                    setLogoImage={setLogoSideBar}
                    updateValues={(values) => {
                      setLayoutValues(values);
                    }}
                    favIcon={imageURLS.favIcon}
                    setFavIcon={setFavIcon}
                    setInitialFavIconImage={(image) =>
                      setInitialImages((prev) => ({ ...prev, favIcon: image }))
                    }
                  />
                  <div className="kt-separator kt-separator--dashed" />
                  <div className="kt-section__content"></div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
      </Portlet>
    </div>
  );
};

const mapStateToProps = ({ design: { appColors } }) => ({
  appColors
});
export default connect(mapStateToProps, designStore)(Design);
