import React, { useRef, useState, useEffect } from 'react';
import { makeStyles, Tooltip, Typography, IconButton } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles(() => ({
  title: {
    display: ' flex',
    flexDirection: 'column'
  }
}));

const OverflowTip = ({ children }) => {
  const classes = useStyles();
  const [isOverflowed, setIsOverflow] = useState(false);
  const [copied, setCopied] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);

  const handleCopyText = () => {
    if (typeof children === 'string') {
      navigator.clipboard.writeText(children);
      setCopied(true);
    }
  };

  const handleOnLeave = () => {
    setCopied(false);
  };

  return (
    <Tooltip
      onClose={handleOnLeave}
      disableHoverListener={!isOverflowed}
      interactive
      placement="top"
      title={
        <div className={classes.title}>
          <IconButton onClick={handleCopyText} style={{ width: '32px', height: '32px' }}>
            <FileCopyOutlinedIcon fontSize="small" htmlColor={copied ? '#00FF00' : '#FFFFFF'} />
          </IconButton>
          <Typography>{children}</Typography>
        </div>
      }
    >
      <div
        ref={textElementRef}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
