import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { pick } from "lodash";
import {
  InputAdornment,
} from "@material-ui/core";
import { actions } from '../../../../store/ducks/general.duck';
import BaseFields from '../../Components/BaseFields/BaseFields';
import { GetTranslatedValue } from '../../utils';

const AssetEditon = ({
  assetEditionValues,
  setAssetEditionValues,
  isDisabled,
  maxQuantity
}) => {
  const dispatch = useDispatch();
  const { showCustomAlert } = actions;
  const maxQuantityReached = GetTranslatedValue('GENERAL.MAX.VALUE.EXCEEDED');

  const [values, setValues] = useState({
    serial: '',
    notes: '',
    quantity: '1',
    purchase_date: '',
    purchase_price: '0',
    price: '0',
  });

  useEffect(() => {
    setValues(pick(assetEditionValues, ['id', 'serial', 'notes', 'quantity', 'purchase_date', 'purchase_price', 'price']))
  }, [assetEditionValues])

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });

  const handleChange = name => event => {
    let maxFlag = false;
    if (parseInt(event.target.value) > (maxQuantity || 300) && name === 'quantity') {
      dispatch(showCustomAlert({
        type: 'warning',
        open: true,
        message: maxQuantityReached
      }));
      maxFlag = true;
    }
    setValues({ ...values, [name]: maxFlag ? ( maxQuantity || '300') : event.target.value });
    setAssetEditionValues({ ...values, [name]: maxFlag ? ( maxQuantity || '300') : event.target.value });
  };


  const baseFieldsLocalProps = {
    serial: {
      componentProps: {
        onChange: handleChange('serial'),
        value: values.serial,
        inputProps: { readOnly: isDisabled }
      }
    },
    notes: {
      componentProps: {
        onChange: handleChange('notes'),
        value: values.notes,
        multiline: true,
        rows: 4,
        inputProps: { readOnly: isDisabled }
      }
    },
    quantity: {
      componentProps: {
        onChange: handleChange('quantity'),
        value: Number(values.quantity),
        type: "number",
        inputProps: {
          min: 1,
          max: maxQuantity || 300,
          readOnly: isDisabled
        }
      }
    },
    purchaseDate: {
      componentProps: {
        onChange: handleChange('purchase_date'),
        value: values.purchase_date,
        type: "date",
        InputLabelProps: {
          shrink: true
        },
        inputProps: { readOnly: isDisabled }
      }
    },
    purchasePrice: {
      ownValidFn: () => !!values.purchase_price || values.purchase_price === '0',
      componentProps: {
        onChange: handleChange('purchase_price'),
        value: Number(values.purchase_price),
        type: "number",
        min: 0,
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        },
        inputProps: {
          min: 0,
          readOnly: isDisabled
        }
      }
    },
    price: {
      ownValidFn: () => !!values.price || values.price === '0',
      componentProps: {
        onChange: handleChange('price'),
        value: Number(values.price),
        type: "number",
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        },
        inputProps: {
          min: 0,
          readOnly: isDisabled
        }
      }
    },
  };

  return (
    <div>
      {
        values.id ? (
          <BaseFields
            catalogue={'assetEdition'}
            collection={'assets'}
            formState={[formValidation, setFormValidation]}
            localProps={baseFieldsLocalProps}
            values={values}
          />
        ) : (
          <h6>{GetTranslatedValue('ASSET.EDITION.CAPTION.DEFAULT', 'First choose an Asset')}</h6>
        )
      }
    </div>
  )
};

export default AssetEditon;
