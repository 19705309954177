/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Chip, Tabs, Typography } from '@material-ui/core';
import { actions } from '../../../store/ducks/general.duck';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import { deleteDB, getDBComplex, getCountDB, deleteManyDB } from '../../../crud/api';
import { getCurrentModuleTab, getFieldCaption, GetTranslatedValue, showWarningMessage } from '../utils';
import { loadGroups, updateTableGroupingTree } from '../Components/tableGroupingHelpers';
import { TabsTitles } from '../Components/Translations/tabsTitles';
import TableComponent2 from '../Components/TableComponent2';
import CustomizedToolTip from '../Components/CustomizedToolTip';
import ModalYesNo from '../Components/ModalYesNo';
import { useIsMount } from '../utils';
import LiveProcesses from './components/LiveProcesses';
import ModalProcessStages from './modals/ModalProcessStages';
import UsersPerStageCell from './components/UsersPerStageCell';
import ModalProcesses from './modals/ModalProcesses';
import { useFieldValidator } from '../Components/FieldValidator/hooks';

const orderByCorrection = { creator: 'creationUserFullName' };

const StagesCell = (stages = []) => {
  return (
    <div
      style={{
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
        padding: '16px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      {!isEmpty(stages) && (
        <CustomizedToolTip
          tooltipContent={
            <ol style={{ marginTop: '15px', marginRight: '20px' }}>
              {stages.map(({ name, goBackEnabled, goBackTo }, ix) => (
                <li style={{ marginTop: '10px' }} key={`stage-${ix}-${name}`}>
                  <h6>{` ${name}`}</h6>
                  {goBackEnabled && (
                    <ul style={{ paddingLeft: '5px' }}>
                      <li style={{ marginBottom: '5px', fontWeight: 'normal', color: '#b2b2b2' }}>
                        <p>
                          Go Back To:{' '}
                          <i>
                            {stages.find(({ id }) => id === goBackTo)?.name ||
                              "Coulnd't found the stage"}
                          </i>
                        </p>
                      </li>
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          }
          content={
            <Chip
              label={`Stages: ${stages.length}`}
              style={{ backgroundColor: '#8e8e8e', height: '28px' }}
              color="secondary"
            />
          }
        />
      )}
      {stages.length === 0 && (
        <Chip
          label={`No Stages`}
          style={{ backgroundColor: '#8e8e8e', height: '28px' }}
          color="secondary"
        />
      )}
    </div>
  );
};

const NotificationsAndApprovalsCell = (rawUsers) => {
  const users = rawUsers.map(({ name, lastName, email }) => `${name} ${lastName} (${email})`);
  return (
    <div
      style={{
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
        padding: '16px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      {users.length > 0 && (
        <CustomizedToolTip
          tooltipContent={
            <ul style={{ marginTop: '10px' }}>
              {users.map((user, ix) => (
                <li style={{ marginRight: '10px' }} key={`user-${ix}-${user}`}>
                  {user}
                </li>
              ))}
            </ul>
          }
          content={
            <Chip
              label={`Users: ${users.length}`}
              style={{ backgroundColor: '#8e8e8e', height: '28px' }}
              color="secondary"
              onClick={() => {}}
            />
          }
        />
      )}
      {isEmpty(users) && (
        <il>
          <Typography style={{ fontSize: '0.875rem' }}>N/A</Typography>
        </il>
      )}
    </div>
  );
};

const Processes = (props) => {
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const { showDeletedAlert, showErrorAlert } = actions;
  const [tab, setTab] = useState(getCurrentModuleTab('processes', props.user.profilePermissions));
  const { allFields, fields } = useFieldValidator('processes');

  const goBackToLabel = GetTranslatedValue('PROCESSES.CAPTION.GO.BACK.TO', 'Go Back To');
  const usersLabel = GetTranslatedValue('TABS.TITLES.USERS', 'Users');
  const stagesLabel = GetTranslatedValue('TABS.TITLES.STAGES', 'Stages');

  const trueLabel = GetTranslatedValue('GENERAL.CAPTION.TRUE', 'True');
  const falseLabel = GetTranslatedValue('GENERAL.CAPTION.FALSE', 'False');

  const creationTypeLabel = GetTranslatedValue('PROCESSES.TYPE.CREATION', 'Creation');
  const movementTypeLabel = GetTranslatedValue('PROCESSES.TYPE.MOVEMENT', 'Movement');
  const shortMovementTypeLabel = GetTranslatedValue('PROCESSES.TYPE.SHORT.MOVEMENT', 'Short Movement');
  const decommisionLabel = GetTranslatedValue('PROCESSES.TYPE.DECOMISSION', 'Decommission');
  const maintenanceLabel = GetTranslatedValue('PROCESSES.TYPE.MAINTENANCE', 'Maintenance');

  const messages = {
    usedStage: GetTranslatedValue('PROCESSES.WARNING.NO.DELETE.STAGE.USED')
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case 'creation':
        return creationTypeLabel;
      case 'movement':
        return movementTypeLabel;
      case 'short':
        return shortMovementTypeLabel;
      case 'decommission':
        return decommisionLabel;
      case 'maintenance':
        return maintenanceLabel;
      default:
        return 'Error';
    }
  }

  const executeFieldCaption = (catalogue, { field, key }) => {
    return getFieldCaption(allFields, { catalogue, field, key });
  };

  const StagesCell = (stages = []) => {
    return (
      <div
        style={{
          display: 'table-cell',
          verticalAlign: 'middle',
          textAlign: 'center',
          padding: '16px',
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        {!isEmpty(stages) && (
          <CustomizedToolTip
            tooltipContent={
              <ol style={{ marginTop: '15px', marginRight: '20px' }}>
                {stages.map(({ name, goBackEnabled, goBackTo }, ix) => (
                  <li style={{ marginTop: '10px' }} key={`stage-${ix}-${name}`}>
                    <h6>{` ${name}`}</h6>
                    {goBackEnabled && (
                      <ul style={{ paddingLeft: '5px' }}>
                        <li style={{ marginBottom: '5px', fontWeight: 'normal', color: '#b2b2b2' }}>
                          <p>
                            {goBackToLabel}{': '}
                            <i>
                              {stages.find(({ id }) => id === goBackTo)?.name ||
                                "Coulnd't found the stage"}
                            </i>
                          </p>
                        </li>
                      </ul>
                    )}
                  </li>
                ))}
              </ol>
            }
            content={
              <Chip
                label={`${stagesLabel}: ${stages.length}`}
                style={{ backgroundColor: '#8e8e8e', height: '28px' }}
                color="secondary"
              />
            }
          />
        )}
        {stages.length === 0 && (
          <Chip
            label={`No Stages`}
            style={{ backgroundColor: '#8e8e8e', height: '28px' }}
            color="secondary"
          />
        )}
      </div>
    );
  };

  const NotificationsAndApprovalsCell = (rawUsers) => {
    const users = rawUsers.map(({ name, lastName, email }) => `${name} ${lastName} (${email})`);
    return (
      <div
        style={{
          display: 'table-cell',
          verticalAlign: 'middle',
          textAlign: 'center',
          padding: '16px',
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        {users.length > 0 && (
          <CustomizedToolTip
            tooltipContent={
              <ul style={{ marginTop: '10px' }}>
                {users.map((user, ix) => (
                  <li style={{ marginRight: '10px' }} key={`user-${ix}-${user}`}>
                    {user}
                  </li>
                ))}
              </ul>
            }
            content={
              <Chip
                label={`${usersLabel}: ${users.length}`}
                style={{ backgroundColor: '#8e8e8e', height: '28px' }}
                color="secondary"
                onClick={() => {}}
              />
            }
          />
        )}
        {isEmpty(users) && (
          <il>
            <Typography style={{ fontSize: '0.875rem' }}>N/A</Typography>
          </il>
        )}
      </div>
    );
  };

  const createProcessStageRow = (
    id,
    name,
    custom,
    notifications,
    approvals,
    creator,
    creationDate,
    updateDate
  ) => {
    return { id, name, custom, notifications, approvals, creator, creationDate, updateDate };
  };

  const createProcessRow = (
    id,
    name,
    numberOfStages,
    selectedProcessType,
    notifications,
    approvals,
    creator,
    creationDate,
    updateDate
  ) => {
    return {
      id,
      name,
      numberOfStages,
      selectedProcessType,
      notifications,
      approvals,
      creator,
      creationDate,
      updateDate
    };
  };

  const processStagesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME')
    },
    {
      id: 'custom',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('PROCESSES.STAGES.CAPTION.CUSTOM'),
      sortByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'notifications',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('TOPBAR.TOOLTIP.NOTIFICATIONS'),
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => NotificationsAndApprovalsCell(value)
    },
    {
      id: 'approvals',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('PROCESSES.CAPTION.APPROVALS'),
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => NotificationsAndApprovalsCell(value)
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      correction: 'creationUserFullName',
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR')
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE'),
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const processesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME')
    },
    {
      id: 'numberOfStages',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('PROCESSES.CAPTION.NUMBER.STAGES'),
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => StagesCell(value)
    },
    {
      id: 'selectedProcessType',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('PROCESSES.CAPTION.TYPE')
    },
    {
      id: 'notifications',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('TOPBAR.TOOLTIP.NOTIFICATIONS'),
      searchByDisabled: true,
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => {
        const users = [].concat(...value.map(({ users }) => users)).length;
        return <UsersPerStageCell number={users} values={value} />;
      }
    },
    {
      id: 'approvals',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('PROCESSES.CAPTION.APPROVALS'),
      searchByDisabled: true,
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => {
        const users = [].concat(...value.map(({ users }) => users)).length;
        return <UsersPerStageCell number={users} values={value} />;
      }
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR'),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const [tableControl, setTableControl] = useState({
    processStages: {
      collection: 'processStages',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    },
    processes: {
      collection: 'processes',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    }
  });

  const styleMultipleRows = (data, collection) => {
    if (collection === 'processStages') {
      const rows = data.map((row) => {
        const {
          customFieldsTab,
          creationUserFullName,
          creationDate,
          updateDate,
          notifications,
          approvals
        } = row;
        const isCustom = !isEmpty(customFieldsTab) ? trueLabel : falseLabel;
        return createProcessStageRow(
          row._id,
          row.name,
          isCustom,
          notifications,
          approvals,
          creationUserFullName,
          creationDate,
          updateDate
        );
      });
      return rows;
    }
    if (collection === 'processes') {
      const rows = data.map((row) => {
        const notificationsPerStage = [];
        const approvalsPerStage = [];
        const numberOfStages = [];
        row.processStages.map(({ id, name, notifications, approvals, goBackEnabled, goBackTo }) => {
          notificationsPerStage.push({
            name,
            users: notifications.map(
              ({ name, lastName, email }) => `${name} ${lastName} (${email})`
            )
          });
          approvalsPerStage.push({
            name,
            users: approvals.map(({ name, lastName, email }) => `${name} ${lastName} (${email})`)
          });
          numberOfStages.push({ id, name, goBackEnabled, goBackTo });
        });
        const processType = getTypeLabel(row.selectedProcessType);
        return createProcessRow(
          row._id,
          row.name,
          numberOfStages,
          processType,
          notificationsPerStage,
          approvalsPerStage,
          row.creationUserFullName,
          row.creationDate,
          row.updateDate
        );
      });
      return rows;
    }
  };

  const loadProcessData = (collectionNames = ['processStages', 'processes'], searchBy) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      const localSearchBy = searchBy || tableControl[collectionName].searchBy;
      if (!isEmpty(tableControl[collectionName].tableGrouping)) {
        updateTableGroupingTree(
          collectionName,
          tableControl[collectionName].tableGrouping,
          styleMultipleRows,
          tableControl,
          (newTree) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                tableGrouping: newTree
              }
            }));
          }
        );
      }
      let queryLike = '';
      if (collectionName === 'processStages') {
        queryLike = localSearchBy
          ? [{ key: localSearchBy, value: tableControl.processStages.search }]
          : ['creationDate', 'name', 'functions', 'types', 'updateDate'].map((key) => ({
            key,
            value: tableControl.processStages.search
          }));
      }
      if (collectionName === 'processes') {
        queryLike = localSearchBy
          ? [{ key: localSearchBy, value: tableControl.processes.search }]
          : ['creationDate', 'name', 'updateDate'].map((key) => ({
            key,
            value: tableControl.processes.search
          }));
      }
      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response.count
            }
          }));
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          { key: tableControl[collectionName].orderBy, value: tableControl[collectionName].order }
        ],
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'processStages') {
            const rows = data.response.map((row) => {
              const {
                customFieldsTab,
                creationUserFullName,
                creationDate,
                updateDate,
                notifications,
                approvals
              } = row;
              const isCustom = !isEmpty(customFieldsTab) ? trueLabel : falseLabel;
              return createProcessStageRow(
                row._id,
                row.name,
                isCustom,
                notifications,
                approvals,
                creationUserFullName,
                creationDate,
                updateDate
              );
            });
            setControl((prev) => ({
              ...prev,
              processStagesRows: rows,
              processStagesRowsSelected: []
            }));
          }
          if (collectionName === 'processes') {
            const rows = data.response.map((row) => {
              const notificationsPerStage = [];
              const approvalsPerStage = [];
              const numberOfStages = [];
              row.processStages.map(
                ({ id, name, notifications, approvals, goBackEnabled, goBackTo }) => {
                  notificationsPerStage.push({
                    name,
                    users: notifications.map(
                      ({ name, lastName, email }) => `${name} ${lastName} (${email})`
                    )
                  });
                  approvalsPerStage.push({
                    name,
                    users: approvals.map(
                      ({ name, lastName, email }) => `${name} ${lastName} (${email})`
                    )
                  });
                  numberOfStages.push({ id, name, goBackEnabled, goBackTo });
                }
              );
              const processType = getTypeLabel(row.selectedProcessType);
              return createProcessRow(
                row._id,
                row.name,
                numberOfStages,
                processType,
                notificationsPerStage,
                approvalsPerStage,
                row.creationUserFullName,
                row.creationDate,
                row.updateDate
              );
            });
            setControl((prev) => ({ ...prev, processRows: rows, processRowsSelected: [] }));
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };

  useEffect(() => {
    loadProcessData();
  }, []);

  useEffect(() => {
    if (!isMount) {
      loadProcessData('processes');
    }
  }, [
    tableControl.processes.page,
    tableControl.processes.rowsPerPage,
    tableControl.processes.order,
    tableControl.processes.orderBy,
    tableControl.processes.search,
    tableControl.processes.locationsFilter
  ]);

  useEffect(() => {
    if (!isMount) {
      loadProcessData('processStages');
    }
  }, [
    tableControl.processStages.page,
    tableControl.processStages.rowsPerPage,
    tableControl.processStages.order,
    tableControl.processStages.orderBy,
    tableControl.processStages.search
  ]);

  const [control, setControl] = useState({
    idProcessStage: null,
    openProcessStagesModal: false,
    processStagesRows: null,
    processStagesRowsSelected: [],
    //
    idProcess: null,
    openProcessModal: false,
    processRows: null,
    processRowsSelected: []
  });

  const [referencesSelectedId, setReferencesSelectedId] = useState(null);
  const [selectReferenceConfirmation, setSelectReferenceConfirmation] = useState(false);
  const collections = {
    processStages: {
      id: 'idProcessStage',
      modal: 'openProcessStagesModal',
      name: 'processStages'
    },
    processes: {
      id: 'idProcess',
      modal: 'openProcessModal',
      name: 'processes'
    }
  };

  const isStageUsedByProcesses = (stageId) => {
    return getCountDB({
      collection: 'processes',
      condition: [{ processStages: { $elemMatch: { id: stageId } } }]
    })
      .then((response) => response.json())
      .then((data) => data?.response?.count > 0)
      .catch((error) => console.log(error));
  };

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];

    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      async onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        const copyArray = JSON.parse(JSON.stringify(id));

        if (collection.name === 'processStages') {
          for (let i = 0; i < copyArray.length; i++) {
            const used = await isStageUsedByProcesses(copyArray[i]);

            if (used) {
              const index = id.indexOf(copyArray[i]);
              id.splice(index, 1);
              showWarningMessage(messages.usedStage);
            }
          }
        }

        id.forEach((_id) => {
          deleteDB(`${collection.name}/`, _id)
            .then((response) => {
              if (collection.name === 'processStages') {
                deleteManyDB({
                  collection: 'settingsLayoutsStages',
                  condition: [{ selectedStage: _id }]
                })
                  .catch((error) => console.log(error));
              }

              dispatch(showDeletedAlert());
              loadProcessData(collection.name);
            })
            .catch((error) => dispatch(showErrorAlert()));
        });
        loadProcessData(collection.name);
      },
      onSelect(id) {
        if (collectionName === 'references') {
          setReferencesSelectedId(id);
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  return (
    <>
      <ModalYesNo
        showModal={selectReferenceConfirmation}
        onOK={() => setSelectReferenceConfirmation(false)}
        onCancel={() => setSelectReferenceConfirmation(false)}
        title={'Add New Asset'}
        message={'Please first select a Reference from the next tab'}
      />
      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  component="div"
                  className="builder-tabs"
                  value={tab}
                  onChange={(_, nextTab) => setTab(nextTab)}
                  variant="scrollable"
                >
                  {TabsTitles('processes', props.user.profilePermissions['processes'])}
                </Tabs>
              </PortletHeaderToolbar>
            }
          />

          {tab === 0 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>Live Processes</code>
                    </span>
                    <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <LiveProcesses />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 1 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>Processes List</code>
                    </span> */}
                    <ModalProcesses
                      id={control.idProcess}
                      readOnly={control.processesReadOnly}
                      reloadTable={() => loadProcessData('processes')}
                      showModal={control.openProcessModal}
                      setShowModal={(onOff) => setControl({ ...control, openProcessModal: onOff })}
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.processes}
                        disableSearchBy
                        headRows={processesHeadRows}
                        onAdd={tableActions('processes').onAdd}
                        onDelete={tableActions('processes').onDelete}
                        onEdit={tableActions('processes').onEdit}
                        onSelect={tableActions('processes').onSelect}
                        onView={tableActions('processes').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            processes: {
                              ...prev.processes,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.processRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.processes.search === value) {
                            loadProcessData('processes', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              processes: {
                                ...prev.processes,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            processes: {
                              ...prev.processes,
                              orderBy: orderByCorrection[orderBy] || orderBy,
                              order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            processes: {
                              ...prev.processes,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab="list"
                        title={GetTranslatedValue('PROCESSES.CAPTION.LIST', 'Processes List')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 2 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>Processes Stages List</code>
                    </span> */}
                    <ModalProcessStages
                      id={control.idProcessStage}
                      readOnly={control.processStagesReadOnly}
                      reloadTable={() => {
                        loadProcessData(['processStages', 'processes']);
                      }}
                      showModal={control.openProcessStagesModal}
                      setShowModal={(onOff) =>
                        setControl({ ...control, openProcessStagesModal: onOff })
                      }
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.processStages}
                        disableSearchBy
                        headRows={processStagesHeadRows}
                        onAdd={tableActions('processStages').onAdd}
                        onDelete={tableActions('processStages').onDelete}
                        onEdit={tableActions('processStages').onEdit}
                        onSelect={tableActions('processStages').onSelect}
                        onView={tableActions('processStages').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            processStages: {
                              ...prev.processStages,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.processStagesRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.processStages.search === value) {
                            loadProcessData('processStages', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              processStages: {
                                ...prev.processStages,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            processStages: {
                              ...prev.processStages,
                              orderBy: orderByCorrection[orderBy] || orderBy,
                              order: order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            processStages: {
                              ...prev.processStages,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab="stages"
                        title={GetTranslatedValue('PROCESSES.CAPTION.STAGES.LIST', 'Processes Stages List')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}
        </Portlet>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Processes);
