import React from 'react';
import { Button, Tooltip, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { GetTranslatedValue } from '../../../utils';

const DownloadButton = ({ disabled, onClick }) => (
  <Tooltip
    arrow
    placement="top"
    title={<Typography>{GetTranslatedValue('IMPORT.DOWNLOAD.LAYOUT.TOOLTIP')}</Typography>}
  >
    <Button
      color="secondary"
      component="span"
      disabled={disabled}
      onClick={onClick}
      startIcon={<GetAppIcon />}
      style={{ height: '35px' }}
      variant="contained"
    >
      {GetTranslatedValue('IMPORT.DOWNLOAD.LAYOUT', 'Download Layout')}
    </Button>
  </Tooltip>
);

export default DownloadButton;
