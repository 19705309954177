import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { PortletBody } from '../../../../../partials/content/Portlet';
import { loadGroups, updateTableGroupingTree } from '../../tableGroupingHelpers';
import { actions } from '../../../../../store/ducks/general.duck';
import { deleteDB, getDBComplex, getCountDB, getDB, deleteManyDB } from '../../../../../crud/api';
import { GetTranslatedValue } from '../../../utils';
import { rules } from '../../../constants';
import TableComponent2 from '../../TableComponent2';
import ModalPolicies from '../modals/ModalPolicies';

const collections = {
  policies: {
    id: 'idPolicies',
    modal: 'openPoliciesModal',
    name: 'policies'
  }
};

const PoliciesTable = ({ module, setPolicies, baseFields, customModule = null }) => {
  const dispatch = useDispatch();
  const { showDeletedAlert } = actions;
  const [control, setControl] = useState({
    idPolicies: null,
    openPoliciesModal: false,
    policiesRows: null,
    policiesRowsSelected: []
  });

  const rulesTranslated = {
    'ruleOne': GetTranslatedValue('POLICIES.DATE.CYCLE.RULE'),
    'ruleTwo': GetTranslatedValue('POLICIES.DATE.EQUALS.RULE'),
    'ruleThree': GetTranslatedValue('POLICIES.TEXT.EQUALS.RULE'),
  };

  const createPoliciesRow = (
    id,
    name,
    target,
    action,
    type,
    rule,
    creator,
    creationDate,
    updateDate
  ) => {
    return {
      id,
      name,
      target,
      action,
      type,
      rule: rulesTranslated[rules.find(({ value }) => value === rule)?.value] || '',
      creator,
      creationDate,
      updateDate
    };
  };

  const policiesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME'),
      searchByDisabled: false,
      correction: 'policyName'
    },
    {
      id: 'target',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('POLICIES.CAPTION.TARGET'),
      searchByDisabled: false,
      correction: 'selectedCatalogue'
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('POLICIES.CAPTION.ACTION'),
      searchByDisabled: false,
      correction: 'selectedAction'
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('POLICIES.CAPTION.TYPE'),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'rule',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('POLICIES.CAPTION.RULE'),
      searchByDisabled: true,
      correction: 'selectedRule'
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR'),
      searchByDisabled: true,
      correction: 'creationUserFullName'
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE'),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        deleteManyDB({
          collection: 'policies',
          condition: [{ "_id": { "$in": id.map((e) => `ObjectId('${e}')`) } }]
        })
          .then(data => {
            dispatch(showDeletedAlert());
            loadPoliciesData(collection.name);

            getDB('policies')
              .then((response) => response.json())
              .then((data) => setPolicies(data));
          })
          .catch((error) => console.log('Error', error));
      },
      onSelect(id) {
        if (collectionName === 'references') {
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const getTypeString = (messageDisabled, notificationDisabled, apiDisabled) => {
    const array = [
      ...(!messageDisabled ? ['Message'] : []),
      ...(!notificationDisabled ? ['Notification'] : []),
      ...(!apiDisabled ? ['API'] : [])
    ];
    return array.join(', ');
  };

  const getOnFieldTypeString = (
    OnFieldApiDisabled,
    onFieldMessageDisabled,
    onFieldNotificationDisabled
  ) => {
    const array = [
      ...(!onFieldMessageDisabled ? ['Message'] : []),
      ...(!onFieldNotificationDisabled ? ['Notification'] : []),
      ...(!OnFieldApiDisabled ? ['API'] : [])
    ];

    return array.join(', ');
  };

  const [tableControl, setTableControl] = useState({
    policies: {
      collection: 'policies',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: '',
      condition: [{ module: { $eq: module } }]
    }
  });

  const styleMultipleRows = (data, collection) => {
    if (!Array.isArray(data)) {
      return [];
    }
    const rows = data.map((row) => {
      const {
        _id,
        apiDisabled,
        messageDisabled,
        notificationDisabled,
        policyName,
        selectedAction,
        selectedCatalogue,
        creationUserFullName,
        creationDate,
        OnFieldApiDisabled,
        onFieldMessageDisabled,
        onFieldNotificationDisabled,
        updateDate,
        selectedRule
      } = row;
      const typeString =
        selectedAction !== 'OnField'
          ? getTypeString(messageDisabled, notificationDisabled, apiDisabled)
          : getOnFieldTypeString(
            OnFieldApiDisabled,
            onFieldMessageDisabled,
            onFieldNotificationDisabled
          );
      return createPoliciesRow(
        _id,
        policyName,
        selectedCatalogue,
        selectedAction,
        typeString,
        selectedRule,
        creationUserFullName,
        creationDate,
        updateDate
      );
    });
    return rows;
  };

  const loadPoliciesData = (collectionNames = ['policies'], searchBy) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      const localSearchBy = searchBy || tableControl.policies.searchBy;
      if (!isEmpty(tableControl[collectionName].tableGrouping)) {
        updateTableGroupingTree(
          collectionName,
          tableControl[collectionName].tableGrouping,
          styleMultipleRows,
          tableControl,
          (newTree) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                tableGrouping: newTree
              }
            }));
          },
          tableControl[collectionName].condition
        );
      }
      let queryLike = '';
      let searchByFiltered = localSearchBy;

      switch (searchByFiltered) {
        case 'name':
          searchByFiltered = 'policyName';
          break;
        case 'target':
          searchByFiltered = 'selectedCatalogue';
          break;
        case 'action':
          searchByFiltered = 'selectedAction';
          break;
        default:
          break;
      }

      if (collectionName === 'policies') {
        queryLike = searchByFiltered
          ? [{ key: searchByFiltered, value: tableControl.policies.search }]
          : ['policyName', 'selectedCatalogue', 'selectedAction'].map((key) => ({
            key,
            value: tableControl.policies.search
          }));
      }
      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null,
        condition: [{ module: { $eq: module } }]
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response.count
            }
          }));
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          { key: tableControl[collectionName].orderBy, value: tableControl[collectionName].order }
        ],
        queryLike: tableControl[collectionName].search ? queryLike : null,
        condition: [{ module: { $eq: module } }]
      })
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'policies') {
            const rows = data.response.map((row) => {
              const {
                _id,
                apiDisabled,
                messageDisabled,
                notificationDisabled,
                policyName,
                selectedAction,
                selectedCatalogue,
                creationUserFullName,
                creationDate,
                OnFieldApiDisabled,
                onFieldMessageDisabled,
                onFieldNotificationDisabled,
                updateDate,
                selectedRule
              } = row;
              const typeString =
                selectedAction !== 'OnField'
                  ? getTypeString(messageDisabled, notificationDisabled, apiDisabled)
                  : getOnFieldTypeString(
                    OnFieldApiDisabled,
                    onFieldMessageDisabled,
                    onFieldNotificationDisabled
                  );
              return createPoliciesRow(
                _id,
                policyName,
                selectedCatalogue,
                selectedAction,
                typeString,
                selectedRule,
                creationUserFullName,
                creationDate,
                updateDate
              );
            });
            setControl((prev) => ({
              ...prev,
              policiesRows: rows,
              policiesRowsSelected: []
            }));
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };

  useEffect(() => {
    loadPoliciesData();
  }, []);

  useEffect(() => {
    loadPoliciesData('policies');
  }, [
    tableControl.policies.page,
    tableControl.policies.rowsPerPage,
    tableControl.policies.order,
    tableControl.policies.orderBy,
    tableControl.policies.search,
    tableControl.policies.searchBy,
    tableControl.policies.locationsFilter
  ]);

  return (
    <PortletBody>
      <div className="kt-section kt-margin-t-0 kt-margin-b-0">
        <div className="kt-section__body">
          <div className="kt-section">
            {/* <span className='kt-section__sub'>
              This section will integrate <code>Policies</code>
            </span> */}
            <ModalPolicies
              id={control.idPolicies}
              key={`${module}-policies`}
              module={module}
              baseFields={baseFields}
              readOnly={control.policiesReadOnly}
              reloadTable={() => loadPoliciesData('policies')}
              setPolicies={setPolicies}
              setShowModal={(onOff) => setControl({ ...control, openPoliciesModal: onOff })}
              showModal={control.openPoliciesModal}
            />
            {/* <div className='kt-separator kt-separator--dashed' /> */}
            <div className="kt-section__content">
              <TableComponent2
                controlValues={tableControl.policies}
                customModule={customModule || module}
                headRows={policiesHeadRows}
                onAdd={tableActions('policies').onAdd}
                onDelete={tableActions('policies').onDelete}
                onEdit={tableActions('policies').onEdit}
                onSelect={tableActions('policies').onSelect}
                onView={tableActions('policies').onView}
                paginationControl={({ rowsPerPage, page }) =>
                  setTableControl((prev) => ({
                    ...prev,
                    policies: {
                      ...prev.policies,
                      rowsPerPage: rowsPerPage,
                      page: page
                    }
                  }))
                }
                rows={control.policiesRows}
                searchControl={({ value, field }) => {
                  if (tableControl.policies.search === value) {
                    loadPoliciesData('policies', field);
                  } else {
                    setTableControl((prev) => ({
                      ...prev,
                      policies: {
                        ...prev.policies,
                        search: value,
                        searchBy: field
                      }
                    }));
                  }
                }}
                sortByControl={({ orderBy, order }) => {
                  var correctOrderBy = orderBy;
                  switch (orderBy) {
                    case 'name':
                      correctOrderBy = 'policyName';
                      break;
                    case 'target':
                      correctOrderBy = 'selectedCatalogue';
                      break;
                    case 'action':
                      correctOrderBy = 'selectedAction';
                      break;
                    default:
                      correctOrderBy = orderBy;
                      break;
                  }
                  setTableControl((prev) => ({
                    ...prev,
                    policies: {
                      ...prev.policies,
                      orderBy: correctOrderBy,
                      order: order
                    }
                  }));
                }}
                fetchTableGroupingData={loadGroups}
                setTableGroupingControl={(tree) =>
                  setTableControl((prev) => ({
                    ...prev,
                    policies: {
                      ...prev.policies,
                      tableGrouping: tree
                    }
                  }))
                }
                tableGroupingCreateRows={styleMultipleRows}
                tableGrouping
                tab="policies"
                title={GetTranslatedValue('POLICIES.TABLE.TITLE', 'Policies List')}
                tileView
              />
            </div>
          </div>
        </div>
      </div>
    </PortletBody>
  );
};

export default PoliciesTable;
