import React from 'react';
import { isEmpty } from 'lodash';
import {
  TableCell,
  TextField,
  TablePagination,
  Typography,
} from '@material-ui/core';
import { GetTranslatedValue } from '../utils';

export default function CustomizedTablePagination({
  controlValues,
  paginationInfo: { page, rowsPerPage },
  loading,
  numberOfRows,
  currentPage,
  setCurrentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  pageChangeDebounced,
}) {

  const getRowsShowed = () => {
    const isTableGrouping = !isEmpty(controlValues?.tableGrouping);
    const thisNode = controlValues?.tableGrouping?.find(({ parent }) => parent === 'root');
    const currentPage = isTableGrouping ? thisNode.query.page + 1 : page + 1;
    const totalRows = isTableGrouping ? thisNode.count : controlValues?.total;
    const localRowsPerPage = isTableGrouping ? thisNode.query.rowsPerPage : rowsPerPage;

    const showing = GetTranslatedValue('TABLE.PAGINATION.CAPTION.SHOWING');
    const ofMsg = GetTranslatedValue('TABLE.PAGINATION.CAPTION.OF');
 
    if (loading) {
      return <i> Loading... </i>
    } else {
      return `${showing} ${(currentPage * localRowsPerPage) - (localRowsPerPage - 1)} / ${(currentPage - 1) * (localRowsPerPage) + numberOfRows} ${ofMsg} ${totalRows}`;
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography style={{ border: 'none', fontSize: '0.87rem', marginLeft: '10px', padding: '16px' }}>
        {getRowsShowed()}
      </Typography>
      <TablePagination
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        component='span'
        count={controlValues.total}
        labelDisplayedRows={({ from, to, count }) => {

          if (count === 0) {
            return GetTranslatedValue('TABLE.PAGINATION.CAPTION.NO.PAGES');
          }

          const totalPages = Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1);
          const fontSize = '0.875rem';

          return (
            <div style={{ alignItems: 'baseline', display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ fontSize }}>
                {GetTranslatedValue('TABLE.PAGINATION.CAPTION.PAGE')}
              </Typography>
              <TextField
                inputProps={{ style: { fontSize, padding: '0px' } }}
                InputProps={{ disableUnderline: true }}
                onChange={(event) => {
                  const input = event.target.value;
                  setCurrentPage(input);
                  pageChangeDebounced.current(input, page, totalPages);
                }}
                style={{ marginLeft: '4px', width: `${(!isEmpty(currentPage.toString()) ? currentPage.toString().length : 1) * 7}px` }}
                value={currentPage.toString()}
              />
              <Typography style={{ fontSize }}>{`/${totalPages}`}</Typography>
            </div>
          );
        }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        labelRowsPerPage={(
          <Typography style={{ fontSize: '0.875rem' }}>
            {GetTranslatedValue('TABLE.PAGINATION.CAPTION.ROWS.PER.PAGE')}
          </Typography>
        )}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPageOptions={[5, 10, 25]}
        rowsPerPage={rowsPerPage}
      />
    </div>
  )
}
