import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dropdown, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTimeAgo from 'react-time-ago'
import { Badge, IconButton, Typography, makeStyles} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused';
import ModalUserNotifications from './modalNotification/ModalUserNotifications'
import HeaderDropdownToggle from '../../content/CustomDropdowns/HeaderDropdownToggle';
import {
  updateDB,
  getDB,
  getCountDB,
  getDBComplex,
} from '../../../crud/api'
import { ReactComponent as CompilingIcon } from '../../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg';
import { actions } from "../../../store/ducks/general.duck";
import './UserNotifications.scss'
import { GetTranslatedValue } from '../../../pages/home/utils';

const useStyles = makeStyles({
  customBadge: {
    backgroundColor: ({badgeColor}) => badgeColor
  },
  icon: {
    color: ({iconColor}) => iconColor
  }
});

const perfectScrollbarOptions = {
  wheelPropagation: false,
  wheelSpeed: 2
};

const UserNotifications = ({
  appColors,
  bgImage,
  click,
  dot,
  icon,
  iconType,
  pulse,
  pulseLight,
  skin,
  type,
  user,
  useSVG,
  updateNotifications
}) => {
  const classes = useStyles({ badgeColor: appColors.notificationsBadge, iconColor: appColors.topNavigationBarIcons })
  const { setTotalNotifications, setUpdateNotifications } = actions;
  const dispatch = useDispatch();
  const [countNotifications, setCountNotifications] = useState(0)
  const [data, setData] = useState([])
  const [getKey, setGetKey] = useState({ key: 'alerts' })
  const iconsList = {
    notificationImportantIcon: <NotificationImportantIcon />,
    notificationsIcon: <NotificationsIcon />,
    notificationsActiveIcon: <NotificationsActiveIcon />,
    notificationsNoneIcon: <NotificationsNoneIcon />,
    notificationsOffIcon: <NotificationsOffIcon />,
    notificationsPausedIcon: <NotificationsPausedIcon />
  };
  const [openModal, setOpenModal] = useState(false)
  const [values, setValues] = useState({
    formatDate: '',
    from: '',
    message: '',
    subject: ''
  })

  /* Functions */

  const loadNotifications = () => {
    getCountDB({
      collection: 'notifications',
      condition: [{ "to": { "$elemMatch": { "_id": user.id } } }]
   })
      .then((response) => response.json())
      .then(data => {
        setControl(prev => ({
          ...prev,
          total: data?.response?.count === 0 ? 1 : data?.response?.count || 0
        }))
      });

      getDBComplex({
        collection: 'notifications',
        condition: [{ "to": { "$elemMatch": { "_id": user.id } } }, { read: false }]
      })
      .then(response => response.json())
      .then((data) => updateCount(data.response))
      .catch((error) => console.log('error>', error));

    getDBComplex({
      collection: 'notifications',
      condition: [{ "to": { "$elemMatch": { "_id": user.id } } }],
      limit: control.rowsPerPage,
      skip: control.rowsPerPage * control.page,
      sort: [{ "key": "creationDate", "value": -1 }],
    })
      .then(response => response.json())
      .then((data) => setData(data.response))
      .catch((error) => console.log('error>', error));
  };

  const handleNotificationStatus = (id, read) => {
    if (!read) {
      const body = { read: true };
      updateDB('notifications/', body, id)
        .then(response => loadNotifications())
        .catch(error => console.log('Error', error));
    }
  }

  const backGroundStyle = () => {
    if (!bgImage) {
      return 'none';
    }
    return 'url(' + bgImage + ')';
  };

  const changeModal = (read, _id, subject, message, formatDate, from) => {
    setOpenModal(true)
    setValues({
      subject,
      message,
      formatDate,
      from: from.length ? from[0].email : ''
    })
    handleNotificationStatus(_id, read);
  }

  const changeColor = (read) => read ? ('firstColor') : ('secondColor');

  const changeBarColor = (read) => read ? ('') : ('blue-bar');

  const getHetBackGroundCssClassList = () => {
    let result = 'kt-head ';
    if (skin) {
      result += `kt-head--skin-${skin} `;
    }
    result += 'kt-head--fit-x kt-head--fit-b';
    return result;
  };

  const getHeaderTopBarCssClassList = () => {
    let result = 'kt-header__topbar-icon ';
    if (pulse) {
      result += 'kt-pulse kt-pulse--brand ';
    }
    if (iconType) {
      result += `kt-header__topbar-icon--${iconType}`;
    }
    return result;
  };

  const getSvgCssClassList = () => {
    let result = 'kt-svg-icon ';
    if (iconType) {
      result += `kt-svg-icon--${iconType}`;
    }
    return result;
  };


  const ulTabsClassList = () => {
    let result = 'nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ';
    if (type) {
      result += `nav-tabs-line-${type} `;
    }
    result += 'kt-notification-item-padding-x';
    return result;
  };

  const updateCount = (dataNotif) => {
    setCountNotifications(dataNotif?.length);
    dispatch(setTotalNotifications(dataNotif?.length));
  }

  const userNotificationsButtonCssClassList = () => {
    let result = 'btn ';
    if (type) {
      result += `btn-${type} `;
    }
    result += 'btn-sm btn-bold btn-font-md';
    return result;
  };

  const [control, setControl] = useState({
    search: '',
    rowsPerPage: 5,
    page: 0,
    total: 1,
  });

  const handlePageChange = (action) => {
    if (action === 'add' && (control.page + 1) < Math.ceil(control.total / control.rowsPerPage)) {
      setControl(prev => ({ ...prev, page: prev.page + 1 }))
    }
    else if (action === 'reduce' && (control.page + 1) > 1) {
      setControl(prev => ({ ...prev, page: prev.page - 1 }))
    }
  }

  useEffect(() => {
    loadNotifications();
  }, [control.search, control.page]);

  useEffect(() => {
    if (updateNotifications) {
      loadNotifications();
      dispatch(setUpdateNotifications(false));
    }
  }, [updateNotifications]);

  const notificationTitle = GetTranslatedValue("TOPBAR.CAPTION.NOTIFICATIONS.TITLE");
  const notificationList = GetTranslatedValue("TOPBAR.CAPTION.NOTIFICATIONS.LIST");
  const noNotificationsLabel = GetTranslatedValue('GENERAL.NO.NOTIFICATIONS');

  return (
    <FormattedMessage id="TOPBAR.TOOLTIP.NOTIFICATIONS" defaultMessage="Notifications">
      {
        (msg) => (
          <Dropdown className='kt-header__topbar-item' drop='down' alignRight>
            <Dropdown.Toggle
              as={HeaderDropdownToggle}
              id='dropdown-toggle-user-notifications'
            >
              <span className={getHeaderTopBarCssClassList()}>
                <Badge badgeContent={countNotifications} color='error' stlye={{backgroundColor: 'blue'}} classes={{badge: classes.customBadge}}>
                  {!useSVG && <NotificationsIcon className={classes.icon} />}
                  {useSVG && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip id='quick-panel-tooltip' style={{ marginTop: '20px' }}>{msg}</Tooltip>}
                    >
                      <span className={getSvgCssClassList()}>
                        <NotificationsIcon className={classes.icon} />
                      </span>
                    </OverlayTrigger>
                  )}
                </Badge>
                <span className='kt-pulse__ring' hidden={!pulse} />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg'>
              <form>
                <div
                  className={getHetBackGroundCssClassList()}
                  style={{ backgroundColor: appColors.notificationsModal }}
                >
                  <h3 className='kt-head__title'>
                    <span style={{ paddingRight: '10px' }}>
                      {notificationTitle}
                    </span>
                    <span className={userNotificationsButtonCssClassList()} style={{ backgroundColor: appColors.notificationsBadge }}>
                      {countNotifications}
                    </span>
                  </h3>

                  <Tab.Container
                    defaultActiveKey='List'
                    className={ulTabsClassList()}
                  >
                    <Nav
                      className={ulTabsClassList()}
                      onSelect={_key => setGetKey({ key: _key })}
                    >
                      <Nav.Item className='nav-item'>
                        <Nav.Link eventKey='List' className='nav-link show' style={{ borderBottom: `1px solid ${appColors.notificationsBadge}` }}>
                          {notificationList}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content>
                      <Tab.Pane eventKey='List'>
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          style={{ maxHeight: '100vh', position: 'relative' }}
                        >
                          <div
                            className='kt-notification kt-margin-t-10 kt-margin-b-10'
                            style={{ maxHeight: '40vh', position: 'relative' }}
                          >
                            <div
                              className='kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll'
                              data-scroll='true'
                              data-height='300'
                              data-mobile-height='200'
                            >
                              <div style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                              }}>
                                Page: {control.page + 1}/{Math.ceil(control.total / control.rowsPerPage)}
                                <IconButton
                                  style={{ marginLeft: '5px' }}
                                  onClick={() => handlePageChange('reduce')}
                                >
                                  <ChevronLeftIcon />
                                </IconButton>
                                <IconButton
                                  style={{ marginRight: '5px' }}
                                  onClick={() => handlePageChange('add')}
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </div>
                              {
                                data?.length ? (
                                  data.map(({ formatDate, icon, _id, message, subject, read, status, from }, ix) => {
                                    return (
                                      <div style={{ display: 'flex' }} key={`userNotification-${ix}`}>
                                        <div style={{ alignSelf:'center', borderLeft: `4px solid ${read ? 'white' : appColors.notificationsModal}`, height: '85px' }}  />
                                        <div
                                          style={{ padding: '20px', color: appColors.notificationsModal}}
                                          className={changeColor(read)}
                                          onClick={() => changeModal(read, _id, subject, message, formatDate, from)}
                                        >
                                          <div className='kt-notification__item-icon' style={{ display: 'flex' }}>
                                            <div className='notification-icon'>
                                              {Object.keys(iconsList).map((key) => {
                                                return (key === icon) ? iconsList[key] : ''
                                              })}
                                            </div>
                                            <div
                                              className='kt-notification__item-title'
                                              style={{ padding: '0 10px 5px', textTransform: 'upperCase' }}
                                            >
                                              {subject ? ((subject.length > 20) ? subject.substring(0, 25) + '...' : subject) : ''}
                                            </div>
                                          </div>
                                          <div>
                                            {message ? ((message.length > 25) ? message.substring(0, 25) + '...' : message) : ''}
                                          </div>
                                          <div className='kt-notification__item-time'>
                                            {formatDate ?
                                              (<ReactTimeAgo
                                                date={formatDate}
                                                locale='en-EN'
                                                timeStyle='round' />)
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })) : (
                                  <Typography align='center' style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}>
                                    {noNotificationsLabel}
                                  </Typography>
                                )
                              }
                            </div>
                            <ModalUserNotifications
                              from={values.from}
                              subject={values.subject}
                              message={values.message}
                              formatDate={values.formatDate}
                              showModal={openModal}
                              setShowModal={(onOff) => setOpenModal(onOff)}
                            />
                          </div>
                        </PerfectScrollbar>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </form>
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    </FormattedMessage>
  );
}

const mapStateToProps = ({ auth: { user }, general: { updateNotifications }, design: { appColors } }) => ({
  updateNotifications,
  appColors,
  user
});

export default connect(mapStateToProps)(UserNotifications);
