export const defaultValues = {
  modules: [
    { index: 0, id: 'none', name: 'SETTINGS.FIELDS.CAPTION.NONE' }, 
    { index: 1, id: 'user', name: 'MENU.USERS' },
    { index: 2, id: 'userReferences', name: 'GENERAL.CAPTION.USER.PROFILES' },
    { index: 3, id: 'employees', name: 'MENU.EMPLOYEES' },
    { index: 4, id: 'employeeReferences', name: 'GENERAL.CAPTION.EMPLOYEE.PROFILES' },
    { index: 5, id: 'locations', name: 'MENU.LOCATION' },
    { index: 6, id: 'categories', name: 'TABS.TITLES.CATEGORIES' },
    { index: 7, id: 'references', name: 'TABS.TITLES.REFERENCES' },
    { index: 8, id: 'assets', name: 'MENU.ASSETS' }
  ],
  selectedLanguage: '',
  selectedModule: '',
  fields: {
    user: {
      userProfile: { id: 'selectedUserProfile', name: 'RECORD.CAPTION.PROFILE', caption: '', mandatory: false, regex: '' },
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' },
      lastName: { id: 'lastName', name: 'RECORD.CAPTION.LASTNAME', caption: '', mandatory: false, regex: '' },
      email: { id: 'email', name: 'RECORD.CAPTION.EMAIL', caption: '', mandatory: false, regex: '' },
      password: { id: 'password', name: 'USERS.CAPTION.PASSWORD', caption: '', mandatory: false, regex: '' },
      boss: { id: 'boss', name: 'USERS.CAPTION.BOSS', caption: '', mandatory: false, regex: '' },
      groups: { id: 'groups', name: 'TABS.TITLES.GROUPS', caption: '', mandatory: false, regex: '' }
    },
    userReferences: {
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' }
    },
    employees: {
      employeeProfile: { id: 'employeeProfile', name: 'RECORD.CAPTION.PROFILE', caption: '', mandatory: false, regex: '' },
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' },
      lastName: { id: 'lastName', name: 'RECORD.CAPTION.LASTNAME', caption: '', mandatory: false, regex: '' },
      email: { id: 'email', name: 'RECORD.CAPTION.EMAIL', caption: '', mandatory: false, regex: '' },
      employee_id: { id: 'employee_id', name: 'EMPLOYEE.CAPTION.ID', caption: '', mandatory: false, regex: '' },
      responsibilityLayout: { id: 'layoutSelected', name: 'EMPLOYEE.CAPTION.LAYOUT', caption: '', mandatory: false, regex: '' }
    },
    employeeReferences: {
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' }
    },
    locations: {
      selectedLevel: { id: 'selectedLevel', name: 'LOCATIONS.CAPTION.SELECTED.LEVEL', caption: '', mandatory: false, regex: '' },
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' }
    },
    categories: {
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' },
      depreciation: { id: 'depreciation', name: 'CATEGORIES.CAPTION.DEPRECIATION', caption: '', mandatory: false, regex: '' }
    },
    references: {
      category: { id: 'selectedProfile', name: 'GENERAL.CAPTION.CATEGORY', caption: '', mandatory: false, regex: '' },
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' },
      brand: { id: 'brand', name: 'ASSETS.CAPTION.BRAND', caption: '', mandatory: false, regex: '' },
      model: { id: 'model', name: 'ASSETS.CAPTION.MODEL', caption: '', mandatory: false, regex: '' },
      price: { id: 'price', name: 'GENERAL.CAPTION.PRICE', caption: '', mandatory: false, regex: '' },
      depreciation: { id: 'depreciation', name: 'CATEGORIES.CAPTION.DEPRECIATION', caption: '', mandatory: false, regex: '' }
    },
    assets: {
      name: { id: 'name', name: 'RECORD.CAPTION.NAME', caption: '', mandatory: false, regex: '' },
      brand: { id: 'brand', name: 'ASSETS.CAPTION.BRAND', caption: '', mandatory: false, regex: '' },
      model: { id: 'model', name: 'ASSETS.CAPTION.MODEL', caption: '', mandatory: false, regex: '' },
      category: { id: 'selectedProfile', name: 'GENERAL.CAPTION.CATEGORY', caption: '', mandatory: false, regex: '' },
      status: { id: 'status', name: 'GENERAL.CAPTION.STATUS', caption: '', mandatory: false, regex: '' },
      serial: { id: 'serial', name: 'GENERAL.CAPTION.SERIALNUMBER', caption: '', mandatory: false, regex: '' },
      responsible: { id: 'responsible', name: 'ASSETS.CAPTION.RESPONSIBLE', caption: '', mandatory: false, regex: '' },
      notes: { id: 'notes', name: 'ASSETS.CAPTION.NOTES', caption: '', mandatory: false, regex: '' },
      quantity: { id: 'quantity', name: 'ASSETS.CAPTION.QUANTITY', caption: '', mandatory: false, regex: '' },
      purchaseDate: { id: 'purchaseDate', name: 'ASSETS.CAPTION.PURCHASEDATE', caption: '', mandatory: false, regex: '' },
      purchasePrice: { id: 'purchasePrice', name: 'ASSETS.CAPTION.PURCHASEPRICE', caption: '', mandatory: false, regex: '' },
      price: { id: 'price', name: 'GENERAL.CAPTION.PRICE', caption: '', mandatory: false, regex: '' },
      totalPrice: { id: 'totalPrice', name: 'ASSETS.CAPTION.TOTALPRICE', caption: '', mandatory: false, regex: '' },
      EPC: { id: 'EPC', name: 'ASSETS.CAPTION.EPC', caption: '', mandatory: false, regex: '' },
      location: { id: 'locations', name: 'GENERAL.CAPTION.LOCATION', caption: '', mandatory: false, regex: '' },
      creator: { id: 'creator', name: 'RECORD.CAPTION.CREATOR', caption: '', mandatory: false, regex: '' },
      creationDate: { id: 'creationDate', name: 'RECORD.CAPTION.CREATIONDATE', caption: '', mandatory: false, regex: '' },
      labelingUser: { id: 'labelingUser', name: 'ASSETS.CAPTION.LABELINGUSER', caption: '', mandatory: false, regex: '' },
      labelingDate: { id: 'labelingDate', name: 'ASSETS.CAPTION.LABELINGDATE', caption: '', mandatory: false, regex: '' }
    },
  }
};

export const mainThemeColors = {
  topNavigationBar: '#FFFFFF',
  generalBackGround: '#F2F3F8',
  topNavigationBarIcons: '#5D78FF',
  topNavigationBarText: '#B0B5C9',
  notificationsBadge: '#FD397A',
  notificationsModal: '#4258BB',
  messagesModal: '#4258BB',
  sideNavBarBackground: '#1E1E2D',
  sideNavBarTitle: '#7D8080',
  sideNavBarTabTitles: '#a2a3b7',
  sideNavBarTabTitlesHover: '#ffffff',
  sideNavBarTabIcons: '#494b74',
  sideNavBarTabIconsHover: '#5D78DC',
  sideNavBarTabSelected: '#1B1B28',
};

export const settings = [
  { id: 'modules', name: 'SETTINGS.FIELDS.CAPTION.MODULES', selected: 'selectedModule' }
];

export const importModules = [
  { id: 'assets', label: 'MENU.ASSETS', collections: ['assets', 'references', 'categories'] },
  { id: 'user', label: 'MENU.USERS', collections: ['user', 'userProfiles'] },
  { id: 'employees', label: 'MENU.EMPLOYEES', collections: ['employees', 'employeeProfiles'] },
  { id: 'locations', label: 'MENU.LOCATIONS', collections: ['locationsReal', 'locations'] }
];

export const importModulesCatalogues = {
  assets: [
    { id: 'assets', label: 'TABS.TITLES.LIST' },
    { id: 'references', label: 'TABS.TITLES.REFERENCES' },
    { id: 'categories', label: 'TABS.TITLES.CATEGORIES' }
  ],
  user: [
    { id: 'user', label: 'TABS.TITLES.LIST' },
    { id: 'userProfiles', label: 'TABS.TITLES.PROFILES' }
  ],
  employees: [
    { id: 'employees', label: 'TABS.TITLES.LIST' },
    { id: 'employeeProfiles', label: 'TABS.TITLES.PROFILES' }
  ],
  locations: [
    { id: 'locationsReal', label: 'TABS.TITLES.LIST' },
    { id: 'locations', label: 'TABS.TITLES.PROFILES' }
  ]
};

export const modulesCatalogues = {
  assets: ['assets1', 'assets2'],
  references: 'references',
  categories: 'categories',
  user: 'userList',
  userProfiles: 'userReferences',
  employees: 'employees',
  employeeProfiles: 'employeeReferences',
  locationsReal: 'locationsList',
  locations: 'locations'
};

export const customFieldTypes = [
  { label: 'Single Line', value: 'singleLine' },
  { label: 'Multi Line', value: 'multiLine' },
  { label: 'Currency', value: 'currency' },
  { label: 'Percentage', value: 'percentage' },
  { label: 'Email', value: 'email' },
  { label: 'Decimal', value: 'decimal' },
  { label: 'URL', value: 'url' },
  { label: 'HTML', value: 'richText' },
  { label: 'Date', value: 'date' },
  { label: 'Date Time', value: 'dateTime' },
  { label: 'Image', value: 'imageUpload' },
  { label: 'File', value: 'fileUpload' }
];

export const importLayouts = {
  assets: [
    { label: 'MENU.ASSETS', value: 'assets' },
    // { label: 'IMPORT.LAYOUTS.ASSETS.REFERENCES', value: 'assetsReferences' },
    // { label: 'IMPORT.LAYOUTS.ASSETS.REFERENCES.CATEGORIES', value: 'assetsReferencesCategories' }
  ],
  references: [
    { label: 'TABS.TITLES.REFERENCES', value: 'references' },
    // { label: 'IMPORT.LAYOUTS.REFERENCES.CATEGORIES', value: 'referencesCategories' }
  ],
  categories: [
    { label: 'TABS.TITLES.CATEGORIES', value: 'categories' }
  ],
  user: [
    { label: 'MENU.USERS', value: 'user' },
    // { label: 'IMPORT.LAYOUTS.USERS.PROFILES', value: 'usersAndProfiles' }
  ],
  userProfiles: [
    { label: 'GENERAL.CAPTION.USERPROFILES', value: 'userProfiles' }
  ],
  employees: [
    { label: 'MENU.EMPLOYEES', value: 'employees' },
    // { label: 'IMPORT.LAYOUTS.EMPLOYEES.PROFILES', value: 'employeesAndProfiles' }
  ],
  employeeProfiles: [
    { label: 'GENERAL.CAPTION.EMPLOYEE.PROFILES', value: 'employeeProfiles' }
  ],
  locationsReal: [
    { label: 'MENU.LOCATIONS', value: 'locationsReal' },
    // { label: 'IMPORT.LAYOUTS.LOCATIONS.PROFILES', value: 'locationsAndProfiles' }
  ],
  locations: [
    { label: 'LOCATION.PROFILES.TABLE.CAPTION', value: 'locationProfiles' }
  ]
};
