import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty, uniq } from 'lodash';
import { Tabs, Typography } from '@material-ui/core';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import {
  deleteDB,
  deleteReportFile,
  getDBComplex,
  getCountDB,
  getDB,
  getOneDB
} from '../../../crud/api';
import { actions } from '../../../store/ducks/general.duck';
import {
  getCurrentModuleName,
  getCurrentModuleTab,
  GetTranslatedValue,
  loadLocationsData,
  loadUserLocations
} from '../utils';
import { TabsTitles } from '../Components/Translations/tabsTitles';
import TableComponent2 from '../Components/TableComponent2';
import ModalYesNo from '../Components/ModalYesNo';
import ModalReportsSaved from './modals/ModalReportsSaved';
import { hasAnyReportCollectionToGenerate } from '../constants';
import TabGeneral from './TabGeneral';
import DataExtraction from './components/DataExtraction';

const Reports = ({ user }) => {
  const dispatch = useDispatch();
  const { showCustomAlert, showErrorAlert, showDeletedAlert } = actions;
  const [control, setControl] = useState({
    idReports: null,
    openReportsModal: false,
    reportsRows: null,
    reportsRowsSelected: [],

    // Large Reports
    largeReportsRows: null
  });
  const [data, setData] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const [reportToGenerate, setReportToGenerate] = useState([]);
  const [selectReferenceConfirmation, setSelectReferenceConfirmation] = useState(false);
  const [tab, setTab] = useState(getCurrentModuleTab('reports', user.profilePermissions));
  const [userLocations, setUserLocations] = useState([]);
  const [parentLocations, setParentLocations] = useState([]);
  const [locationsFilterProps, setLocationsFilterProps] = useState({
    loading: false,
    data: {}
  });
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );

  const assetSavedHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME')
    },
    {
      id: 'selectedReport',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.REPORT')
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATOR'),
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE'),
      searchByDisabled: true
    },
    {
      id: 'autoMessage',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.AUTO.MESSAGE')
    }
  ];

  const collections = {
    reports: {
      id: 'idReports',
      modal: 'openReportsModal',
      name: 'reports'
    }
  };

  const createReportSavedRow = (id, name, selectedReport, creator, creationDate, autoMessage) => {
    return { id, name, selectedReport, creator, creationDate, autoMessage };
  };

  const [tableControl, setTableControl] = useState({
    reports: {
      collection: 'reports',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    }
  });

  const loadLocations = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: user?.id
    });
    setUserLocations(userLocations);
  };

  const filterLocations = async () => {
    setLocationsFilterProps((prev) => ({ ...prev, loading: true }));
    const locationsTreeData = await loadLocationsData(userLocations);
    setLocationsFilterProps({ loading: false, data: locationsTreeData });
  };

  const loadReportsData = (collectionNames = ['reports']) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      let queryLike = '';
      let searchByFiltered = tableControl.reports.searchBy;
      let valueFiltered = tableControl.reports.search;
      let trueValues = ['y', 'ye', 'yes'];
      let falseValues = ['n', 'no'];
      if (trueValues.includes(valueFiltered)) {
        valueFiltered = true;
      } else if (falseValues.includes(valueFiltered)) {
        valueFiltered = false;
      }
      switch (tableControl.reports.searchBy) {
        case 'name':
          searchByFiltered = 'reportName';
          break;
        case 'autoMessage':
          searchByFiltered = 'enabled';
          break;
        default:
          break;
      }
      if (collectionName === 'reports') {
        queryLike = tableControl.reports.searchBy
          ? [{ key: searchByFiltered, value: valueFiltered }]
          : ['reportName'].map((key) => ({ key, value: valueFiltered }));
      }

      getCountDB({
        collection: collectionName,
        condition: [{ selectedReport: { $in: user.profilePermissions['reports']['general'] } }],
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response.count
            }
          }));
        });

      let orderByFiltered = tableControl[collectionName].orderBy;
      switch (tableControl.reports.orderBy) {
        case 'name':
          orderByFiltered = 'reportName';
          break;
        case 'autoMessage':
          orderByFiltered = 'enabled';
          break;
        default:
          orderByFiltered = orderByFiltered;
          break;
      }

      getDBComplex({
        collection: 'reports',
        condition: [{ selectedReport: { $in: user.profilePermissions['reports']['general'] } }]
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data.response);
        })
        .catch((error) => console.log('error>', error));

      getDBComplex({
        collection: collectionName,
        condition: [
          {
            selectedReport: {
              $in: (user.profilePermissions['reports'] || {})['general'] || []
            }
          }
        ],
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [{ key: orderByFiltered, value: tableControl[collectionName].order }],
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          const rows = data.response.map((row) => {
            const {
              _id,
              selectedReport,
              reportName,
              enabled,
              creationUserFullName,
              creationDate
            } = row;
            const cast = enabled ? 'Yes' : 'No';

            return createReportSavedRow(
              _id,
              reportName,
              selectedReport,
              creationUserFullName,
              creationDate,
              cast
            );
          });
          setControl((prev) => ({ ...prev, reportsRows: rows, reportsRowsSelected: [] }));
        })
        .catch((error) => console.log('error>', error));
    });
  };

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onEdit(id) {
        setDataModal(data.filter((ele) => ele._id === id[0])[0]);
        setControl({
          ...control,
          [collection.idReports]: id[0],
          [collection.modal]: true
        });
      },
      onGenerateReport(id) {
        if (!isEmpty(user.profilePermissions['reports']['general'])) {
          setReportToGenerate(id[0]);
          setTab(0);
        } else {
          dispatch(
            showCustomAlert({
              message: 'You have no access to see or generate reports',
              open: true,
              type: 'warning'
            })
          );
        }
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }
        id.forEach((_id) => {
          deleteDB(`${collection.name}/`, _id)
            .then((response) => {
              dispatch(showDeletedAlert());
              loadReportsData(collection.name);
            })
            .catch((error) => console.log('Error', error));
        });
      }
    };
  };

  const createLargeReportObject = ({
    id,
    creationDate,
    collection,
    location,
    reportFile,
    status,
    statusFile
  }) => {
    return { id, creationDate, collection, location, reportFile, status, statusFile };
  };

  const loadGeneratedReportsData = () => {
    if (control.largeReportsRows) {
      setControl((prev) => ({ ...prev, largeReportsRows: null }));
    }

    getDBComplex({
      collection: `reports/${user?.id}`,
      generateReport: true
    })
      .then((response) => response.json())
      .then((data) => {
        const largeReports = data?.response.map(
          ({ creationDate, collection, location, reportFile, status, statusFile }) => {
            return createLargeReportObject({
              id: reportFile,
              creationDate,
              collection,
              location,
              reportFile,
              status,
              statusFile
            });
          }
        );
        setControl((prev) => ({ ...prev, largeReportsRows: largeReports }));
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const onGeneratedReportsDelete = (ids) => {
    Promise.all(
      (ids || []).map((id) => {
        return deleteReportFile(id);
      })
    ).then(() => loadGeneratedReportsData());
  };

  useEffect(() => {
    loadReportsData();
    loadGeneratedReportsData();
    loadLocations();
  }, []);

  useEffect(() => {
    filterLocations();
  }, [userLocations]);

  useEffect(() => {
    loadReportsData();
  }, [
    tableControl.reports.page,
    tableControl.reports.rowsPerPage,
    tableControl.reports.order,
    tableControl.reports.orderBy,
    tableControl.reports.search,
    tableControl.reports.locationsFilter
  ]);

  const reportPremissions = user.profilePermissions['reports'] || {};
  const hasAnyReportsToGenerate = hasAnyReportCollectionToGenerate(reportPremissions['general']);

  return (
    <>
      <ModalYesNo
        message={'Please first select a Reference from the next tab'}
        onCancel={() => setSelectReferenceConfirmation(false)}
        onOK={() => setSelectReferenceConfirmation(false)}
        showModal={selectReferenceConfirmation}
        title={'Add New Report'}
      />
      <ModalReportsSaved
        data={dataModal}
        module={module}
        reloadTable={() => loadReportsData('reports')}
        setShowModal={(onOff) => setControl({ ...control, openReportsModal: onOff })}
        showModal={control.openReportsModal}
      />
      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  className="builder-tabs"
                  component="div"
                  onChange={(_, nextTab) => setTab(nextTab)}
                  value={tab}
                >
                  {TabsTitles('reports', user.profilePermissions['reports'])}
                </Tabs>
              </PortletHeaderToolbar>
            }
          />
          <Typography component="div" hidden={tab !== 0}>
            {hasAnyReportsToGenerate && (
              <PortletBody>
                <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                  <div className="kt-section__body">
                    <TabGeneral
                      id={reportToGenerate}
                      filteredLocationsProps={locationsFilterProps}
                      savedReports={data}
                      setId={setReportToGenerate || ''}
                      reloadData={loadReportsData}
                      setTab={setTab}
                      userLocations={userLocations}
                      parentLocations={parentLocations}
                    />
                  </div>
                </div>
              </PortletBody>
            )}
          </Typography>
          {tab === 1 && !isEmpty(reportPremissions['saved']) && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className="kt-section__sub">
                      This section will integrate <code>Reports</code>
                    </span>
                    <div className="kt-separator kt-separator--dashed" /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.reports}
                        headRows={assetSavedHeadRows}
                        onDelete={tableActions('reports').onDelete}
                        onEdit={tableActions('reports').onEdit}
                        onView={tableActions('reports').onGenerateReport}
                        onSelect={tableActions('reports').onSelect}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            reports: {
                              ...prev.reports,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.reportsRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.reports.search === value && !field) {
                            loadReportsData();
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              reports: {
                                ...prev.reports,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            reports: {
                              ...prev.reports,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        tab={getCurrentModuleName('reports', tab)}
                        title={GetTranslatedValue('MENU.REPORTS')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}
          {tab === 2 && !isEmpty(reportPremissions['dataExtraction']) && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    <DataExtraction
                      loadData={loadGeneratedReportsData}
                      onDelete={onGeneratedReportsDelete}
                      rows={control?.largeReportsRows}
                    />
                  </div>
                </div>
              </div>
            </PortletBody>
          )}
        </Portlet>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Reports);
