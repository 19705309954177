import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { getDB } from '../../../crud/api';

const useMapCoords = () => {
  const [coords, setCoords] = useState({
    lat: 19.432608,
    lng: -99.133209
  });
  const [zoom, setZoom] = useState(6);

  useEffect(() => {
    getDB('settingsGeneral')
      .then((response) => response.json())
      .then((data) => {
        const _values = data?.response[0] || {};
        if (!isEmpty(_values) && _values.hasOwnProperty("mapView")) {
          const { mapView: { lat, lng, zoom } } = _values;
          setCoords({ lat, lng });
          setZoom(zoom);
        }
      })
      .catch((error) => console.log('error>', error));
  }, []);

  return { coords, zoom };
}

export default useMapCoords;
