import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ColorPicker } from 'material-ui-color';
import { Formik } from 'formik';
import { merge } from 'lodash';
import { FormControlLabel, makeStyles, Typography, Tabs, TextField } from '@material-ui/core';
import { GenerateSubTabs } from './Components/Translations/tabsTitles';
import ImageUpload from '../home/Components/ImageUpload';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../partials/content/Portlet';
import './Settings/settings-tabs/settings-tabs.scss';
import { metronic, initLayoutConfig, LayoutConfig } from '../../../_metronic';
import { GetTranslatedValue } from './utils';

const useStyles = makeStyles({
  label: {
    width: '100%',
    paddingLeft: '35px'
  }
});

const Builder = forwardRef(
  (
    {
      colorValues,
      appName,
      onChange,
      onChangeColors,
      logoImage,
      readOnly,
      setInitialLogoImage,
      setLogoImage,
      sideTitleValue,
      updateValues,
      favIcon,
      setFavIcon,
      setInitialFavIconImage
    },
    ref
  ) => {
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();
    const classes = useStyles();

    const [localColorValues, setlocalColorValues] = useState(colorValues);

    const handleColorchange = (name) => (newValue) => {
      setlocalColorValues((prev) => ({...prev, [name]: newValue}));
      onChangeColors(name, newValue);
    };

    const { layoutConfig } = useSelector(
      ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
      shallowEqual
    );
    const updateLayoutConfig = (_config) => {
      dispatch(metronic.builder.actions.setLayoutConfigs(_config));
      setTimeout(() => {
        window.location.reload();
      }, 900);
    };

    const initialValues = useMemo(
      () =>
        merge(
          // Fulfill changeable fields.
          LayoutConfig,
          layoutConfig
        ),
      [layoutConfig]
    );

    const [layoutValues, setLayoutValues] = useState(initialValues);

    useEffect(() => {
      updateValues(layoutValues);
    }, [layoutValues]);

    const formRef = useRef();

    useImperativeHandle(ref, () => ({
      values: formRef.current.values,
      update: (values) => updateLayoutConfig(values)
    }));

    return (
      <>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={(values) => {
            updateLayoutConfig(values);
          }}
          onReset={() => {
            updateLayoutConfig(initLayoutConfig);
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <div className="kt-form kt-form--label-right">
              <Portlet>
                <PortletHeader
                  toolbar={
                    <PortletHeaderToolbar className="PortletHeaderToolbar-Settings-Design-MainApp">
                      <div>
                        <Tabs
                          component="div"
                          className="builder-tabs"
                          value={tab}
                          onChange={(_, nextTab) => setTab(nextTab)}
                        >
                          {GenerateSubTabs('settings', 'Design', 'Main App')}
                        </Tabs>
                      </div>
                    </PortletHeaderToolbar>
                  }
                />

                {tab === 0 && (
                  <PortletBody>
                    <div className="kt-section kt-margin-t-30 mainSettingsDiv">
                      <div className="settingDivider">
                        <TextField
                          disabled={readOnly}
                          style={{ width: '200px', alignSelf: 'flex', marginBottom: '30px' }}
                          value={appName}
                          onChange={onChange('appName')}
                          margin="normal"
                          llabel={GetTranslatedValue(
                            'SETTINGS.DESIGN.APP.GENERAL.CAPTION.APP.NAME'
                          )}
                        />
                        <ImageUpload
                          disabled={readOnly}
                          id="fav-icon-image"
                          image={favIcon}
                          handleUpdate={setFavIcon}
                          setImage={() => {}}
                          setInitialImage={setInitialFavIconImage}
                        >
                          {GetTranslatedValue('SETTINGS.DESIGN.APP.GENERAL.CAPTION.FAV.ICON')}
                        </ImageUpload>
                      </div>
                      <div className="settingDivider">
                        <div className="colorPickerDivider">
                          <div className="colorPickerTitle">
                            <Typography>
                              {GetTranslatedValue(
                                'SETTINGS.DESIGN.APP.GENERAL.CAPTION.GENERAL.SETTINGS'
                              )}
                            </Typography>
                          </div>
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['topNavigationBar']}
                                onChange={handleColorchange('topNavigationBar')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.TOP.NAV.COLOR'
                            )}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['generalBackGround']}
                                onChange={handleColorchange('generalBackGround')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.GENERAL.BG.COLOR'
                            )}
                            labelPlacement="top"
                          />
                        </div>
                        <div className="colorPickerDivider">
                          <div className="colorPickerTitle">
                            <Typography>
                              {GetTranslatedValue('SETTINGS.DESIGN.APP.GENERAL.CAPTION.TOP.NAV')}
                            </Typography>
                          </div>
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['topNavigationBarIcons']}
                                onChange={handleColorchange('topNavigationBarIcons')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.ICONS.COLOR'
                            )}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['topNavigationBarText']}
                                onChange={handleColorchange('topNavigationBarText')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.TEXT.COLOR'
                            )}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['notificationsBadge']}
                                onChange={handleColorchange('notificationsBadge')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.NOTIFICATIONS.BADGE'
                            )}
                            labelPlacement="top"
                          />
                        </div>
                        <div className="colorPickerDivider">
                          <div className="colorPickerTitle">
                            <Typography>
                              {GetTranslatedValue(
                                'SETTINGS.DESIGN.APP.GENERAL.CAPTION.MODALS.SETTINGS'
                              )}
                            </Typography>
                          </div>
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['notificationsModal']}
                                onChange={handleColorchange('notificationsModal')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.NOTIFICATIONS.MODAL.COLOR'
                            )}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['messagesModal']}
                                onChange={handleColorchange('messagesModal')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.GENERAL.CAPTION.MESSAGES.MODAL.COLOR'
                            )}
                            labelPlacement="top"
                          />
                        </div>
                      </div>
                    </div>
                  </PortletBody>
                )}

                {tab === 1 && (
                  <PortletBody>
                    <div className="kt-section kt-margin-t-30 mainSettingsDiv">
                      <div className="settingDivider">
                        <TextField
                          disabled={readOnly}
                          style={{ width: '200px', alignSelf: 'flex', marginBottom: '30px' }}
                          value={sideTitleValue}
                          onChange={onChange('sideBarTitle')}
                          margin="normal"
                          label={GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.SIDE.TITLE')}
                        />
                        <ImageUpload
                          disabled={readOnly}
                          id="logo-sidebar-image"
                          image={logoImage}
                          handleUpdate={setLogoImage}
                          setImage={() => {}}
                          setInitialImage={setInitialLogoImage}
                        >
                          {GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.LOGO.SIDEBAR')}
                        </ImageUpload>
                      </div>
                      <div className="settingDivider">
                        <div className="colorPickerDivider">
                          <div className="colorPickerTitle">
                            <Typography>
                              {GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.SIDE.NAV')}
                            </Typography>
                          </div>
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarBackground']}
                                onChange={handleColorchange('sideNavBarBackground')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.BG.COLOR')}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarTitle']}
                                onChange={handleColorchange('sideNavBarTitle')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.TITLE.COLOR')}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarTabSelected']}
                                onChange={handleColorchange('sideNavBarTabSelected')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue(
                              'SETTINGS.DESIGN.APP.ASIDE.TAB.SELECTED.COLOR'
                            )}
                            labelPlacement="top"
                          />
                        </div>
                        <div className="colorPickerDivider">
                          <div className="colorPickerTitle">
                            <Typography>
                              {GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.SIDE.NAV')}:{' '}
                              <b> {GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.TAB.TITLES')} </b>
                            </Typography>
                          </div>
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarTabTitles']}
                                onChange={handleColorchange('sideNavBarTabTitles')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.COLOR')}
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarTabTitlesHover']}
                                onChange={handleColorchange('sideNavBarTabTitlesHover')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.HOVER.OPTIONAL')}
                            labelPlacement="top"
                          />
                        </div>
                        <div className="colorPickerDivider">
                          <div className="colorPickerTitle">
                            <Typography>
                              {GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.SIDE.NAV')}:{' '}
                              <b> {GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.TAB.ICONS')} </b>
                            </Typography>
                          </div>
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarTabIcons']}
                                onChange={handleColorchange('sideNavBarTabIcons')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Color"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            style={{ marginTop: '40px' }}
                            control={
                              <ColorPicker
                                value={localColorValues['sideNavBarTabIconsHover']}
                                onChange={handleColorchange('sideNavBarTabIconsHover')}
                                disableAlpha
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label={GetTranslatedValue('SETTINGS.DESIGN.APP.ASIDE.HOVER.OPTIONAL')}
                            labelPlacement="top"
                          />
                        </div>
                      </div>
                    </div>
                  </PortletBody>
                )}
              </Portlet>
            </div>
          )}
        </Formik>
      </>
    );
  }
);

export default Builder;
