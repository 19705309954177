import React from 'react';
import clsx from 'clsx';
import { FormControlLabel, makeStyles, Switch, TextField } from '@material-ui/core';
import { PortletBody } from '../../../../../partials/content/Portlet';
import '../modals/ModalPolicies.scss';
import { GetTranslatedValue } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginLeft: '10px'
  },
  textField: {
    margin: '0px 0px 15px 0px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
      width: '90%'
    }
  },
  tokenField: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '20px'
    }
  },
  bodyField: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '15px'
    }
  }
}));

const SendApiTemplate = ({
  urlOnChange,
  urlFieldName,
  urlValue,
  disabled,
  disabledOnChange,
  tokenEnabled,
  tokenEnabledOnChange,
  tokenValue,
  tokenOnChange,
  bodyFieldName,
  bodyOnChange,
  bodyValue,
  setSelectedControlAndIndexes,
  readOnly
}) => {
  const classes = useStyles();

  return (
    <PortletBody>
      <div className="__container-send-api">
        <div className="__container-post">
          <div className="url_textField">
            <TextField
              className={classes.textField}
              id="standard-url"
              label={GetTranslatedValue('GENERAL.URL')}
              margin="normal"
              name={urlFieldName}
              onChange={(event) => urlOnChange(event)}
              onClick={setSelectedControlAndIndexes}
              value={urlValue}
              inputProps={{ readOnly }}
            />
            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={disabled}
                  color="primary"
                  onChange={(event) => disabledOnChange(event)}
                  disabled={readOnly}
                />
              }
              label={GetTranslatedValue('GENERAL.DISABLED')}
              labelPlacement="start"
            />
          </div>
        </div>
        <div className="__container-post">
          <div className="token_textField">
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.formControlLabel
              }}
              control={
                <Switch
                  checked={tokenEnabled}
                  color="primary"
                  onChange={(event) => tokenEnabledOnChange(event)}
                  disabled={readOnly}
                />
              }
              label="Web Token"
              labelPlacement="start"
              value="start"
            />
            <TextField
              className={clsx(classes.textField, classes.tokenField)}
              id="Token-TextField"
              label="Web Token"
              margin="normal"
              multiline
              onChange={(event) => tokenOnChange(event)}
              value={tokenValue}
              inputProps={{ readOnly }}
            />
          </div>
        </div>
        <div className="__container-post">
          <TextField
            className={clsx(classes.textField, classes.bodyField)}
            id="outlined-multiline-static"
            label={GetTranslatedValue('GENERAL.BODY')}
            margin="normal"
            multiline
            name={bodyFieldName}
            onChange={(event) => bodyOnChange(event)}
            onClick={setSelectedControlAndIndexes}
            rows="4"
            style={{ width: '90%' }}
            value={bodyValue}
            inputProps={{ readOnly }}
          />
        </div>
      </div>
    </PortletBody>
  );
};

export default SendApiTemplate;
