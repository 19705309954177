import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { IconButton, InputBase, makeStyles, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { getCountDB, getDBComplex } from '../../../../crud/api';
import TreeViewComponent from '../../Components/TreeViewComponent';
import { GetTranslatedValue } from '../../utils';
import TableComponent2 from '../../Components/TableComponent2';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 850,
    marginTop: '-30px',
    marginBottom: '30px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    marginRight: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  table: {
    display: 'flex',
    maxHeight: 650,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flex: 4,
      width: '100px'
    }
  },
  label: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  treeView: {
    display: 'flex',
    marginBottom: '30px',
    maxHeight: '200px',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      flex: 2,
      marginBottom: '0px',
      marginRight: '30px',
      maxHeight: '500px'
    }
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const AssetFinder = ({
  locationsTreeView,
  setTableRowsInner = () => {},
  userAssets,
  parentLocations
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [locationsFilterProp, setLocationsFilterProp] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [disableGeneralCheckbox, setDisableGeneralCheckbox] = useState(true);
  const [tableControl, setTableControl] = useState({
    assetRows: null,
    collection: 'assets',
    order: -1,
    orderBy: 'creationDate',
    page: 0,
    rowsPerPage: 5,
    search: '',
    selected: [],
    total: 0
  });

  const onAssetSelected = (id) => {
    if (!Array.isArray(id)) {
      const index = tableControl.selected.findIndex(({ id: assetId }) => assetId === id);

      if (index === -1) {
        const asset = tableControl.assetRows?.find(({ id: assetId }) => assetId === id);
        const newSelected = [...tableControl.selected, asset];

        setTableControl((prev) => ({ ...prev, selected: newSelected }));
        setTableRowsInner({ rows: newSelected });
      } else {
        const newSelected = tableControl.selected.filter(({ id: assetId }) => assetId !== id);

        setTableControl((prev) => ({ ...prev, selected: newSelected }));
        setTableRowsInner({ rows: newSelected });
      }
    } else {
      if (isEmpty(id)) {
        setTableControl((prev) => ({ ...prev, selected: [] }));
        setTableRowsInner({ rows: [] });
      } else {
        setTableControl((prev) => ({ ...prev, selected: prev.assetRows }));
        setTableRowsInner({ rows: tableControl.assetRows });
      }
    }
  };

  const getColumns = (isAssetReference = false) => {
    const assetReference = [
      {
        id: 'name',
        label: GetTranslatedValue('RECORD.CAPTION.NAME'),
        disablePadding: false,
        searchByDisabled: true,
        numeric: false
      },
      {
        id: 'brand',
        label: GetTranslatedValue('ASSETS.CAPTION.BRAND'),
        disablePadding: false,
        searchByDisabled: true,
        numeric: true
      },
      {
        id: 'model',
        label: GetTranslatedValue('ASSETS.CAPTION.MODEL'),
        disablePadding: false,
        searchByDisabled: true,
        numeric: true
      },
      {
        id: 'locationPath',
        label: GetTranslatedValue('GENERAL.CAPTION.LOCATIONPATH'),
        disablePadding: false,
        searchByDisabled: true,
        numeric: true
      }
    ];

    if (isAssetReference) {
      return assetReference;
    } else {
      return [
        ...assetReference,
        {
          id: 'parent',
          label: GetTranslatedValue('GENERAL.CAPTION.PARENT'),
          disablePadding: false,
          searchByDisabled: true,
          numeric: false
        },
        { id: 'EPC', label: 'EPC', disablePadding: false, searchByDisabled: true, numeric: true },
        {
          id: 'serial',
          label: GetTranslatedValue('GENERAL.CAPTION.SERIALNUMBER'),
          disablePadding: false,
          searchByDisabled: true,
          numeric: true
        },
        {
          id: 'creationDate',
          label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE'),
          disablePadding: false,
          searchByDisabled: true,
          numeric: false
        }
      ];
    }
  };

  const handleOnSearchClick = () => {
    const queryLike = ['name', 'brand', 'model', 'EPC', 'serial', 'creationDate'].map((key) => ({
      key,
      value
    }));
    const condition = [{ id: { $nin: userAssets } }];
    const locationsFilter = parentLocations;
    const locationsFilterParam = 'location';
    const treeViewLocation = locationsFilterProp;

    getCountDB({
      collection: 'assets',
      queryLike,
      condition,
      locationsFilter,
      locationsFilterParam,
      treeViewLocation
    })
      .then((response) => response.json())
      .then((data) => {
        setTableControl((prev) => ({
          ...prev,
          total: data?.response?.count || 0
        }));
      });

    getDBComplex({
      collection: 'assets',
      queryLike,
      condition,
      locationsFilter,
      locationsFilterParam,
      treeViewLocation,
      limit: tableControl.rowsPerPage,
      skip: tableControl.rowsPerPage * tableControl.page,
      sort: [{ key: tableControl.orderBy, value: tableControl.order }]
    })
      .then((response) => response.json())
      .then((data) => {
        const rows = data.response.map((row) => {
          const { name, brand, model, EPC, _id: id, serial, creationDate } = row;
          const parent = !!row.parent;
          const locationPath = row.locationPath || 'No Location Found';
          return { id, name, brand, model, locationPath, parent, EPC, serial, creationDate };
        });

        setTableControl((prev) => ({ ...prev, assetRows: rows }));
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    const {
      target: { value: text }
    } = event;
    setValue(text);
  };

  const onLocationSelected = (locationId) => {
    if (locationId === 'root') {
      setLocationsFilterProp('');
    } else {
      setLocationsFilterProp(locationId);
    }

    setSelectedLocation(locationId);
  };

  const setRowsToNull = () => {
    setTableControl((prev) => ({ ...prev, assetRows: null }));
  };

  const search = () => {
    setRowsToNull();
    handleOnSearchClick();
  };

  useEffect(() => {
    if (selectedLocation) {
      handleOnSearchClick();
    }
  }, [
    locationsFilterProp,
    tableControl.page,
    tableControl.order,
    tableControl.orderBy,
    tableControl.rowsPerPage
  ]);

  useEffect(() => {
    if (selectedLocation === 'root') {
      setDisableGeneralCheckbox(true);
    } else {
      setDisableGeneralCheckbox(false);
    }

    setRowsToNull();
  }, [selectedLocation]);

  return (
    <div>
      <Paper className={classes.root}>
        <InputBase
          className={classes.input}
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={handleChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              search();
            }
          }}
          placeholder="Search Assets"
          value={value}
        />
        <IconButton onClick={search}>
          <SearchIcon />
        </IconButton>
      </Paper>
      <div className={classes.container}>
        <div className={classes.treeView}>
          <TreeViewComponent
            data={locationsTreeView}
            onClick={onLocationSelected}
            selected={selectedLocation}
          />
        </div>
        {selectedLocation ? (
          <div className={classes.table}>
            <TableComponent2
              controlValues={tableControl}
              disableGeneralCheckbox={disableGeneralCheckbox}
              headRows={getColumns()}
              noSearch
              paginationControl={({ rowsPerPage, page }) => {
                if (page !== tableControl.page) {
                  setTableControl((prev) => ({
                    ...prev,
                    rowsPerPage,
                    page,
                    selected: []
                  }));
                } else {
                  setTableControl((prev) => ({
                    ...prev,
                    rowsPerPage,
                    page
                  }));
                }
              }}
              rows={tableControl.assetRows}
              searchControl={({ value, field }) => {
                if (tableControl.search === value && !field) {
                  handleOnSearchClick();
                } else {
                  setTableControl((prev) => ({
                    ...prev,
                    search: value,
                    searchBy: field
                  }));
                }
              }}
              sortByControl={({ orderBy, order }) => {
                setTableControl((prev) => ({
                  ...prev,
                  orderBy,
                  order
                }));
              }}
              title={GetTranslatedValue('MENU.ASSETS')}
              setTableRowsInner={onAssetSelected}
            />
          </div>
        ) : (
          <div className={clsx(classes.table, classes.label)}>
            <Typography variant="h5">
              {GetTranslatedValue('ASSETS.PLEASE.SELECT.LOCATION')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetFinder;
