import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../../app/partials/content/Portlet';

import { GetTranslatedValue, loadLocationsData, loadUserLocations, checkImage} from '../../utils';
import { getImageURL } from '../../utils';
import { getDB } from '../../../../crud/api';
import OtherModalLayoutTab from './OtherModalLayoutTab';
import OtherModalMapTab from './OtherModalMapTab';
import OtherModalChildrenTab from './OtherModalChildrenTab';

const OtherModalTabs = ({
  locationReal,
  layoutMarker,
  setLayoutMarker,
  mapMarker,
  setMapMarker,
  assetRows,
  assetOpened,
  onAssetFinderSubmit,
  onDeleteAssetAssigned,
  parentLocations,
  user,
  isAssetDecommissioned
}) => {
  /* States */

  const [tab, setTab] = useState(0);

  // Layout
  const [locations, setLocations] = useState([]);
  const [realImageURL, setRealImageURL] = useState();
  const [userLocations, setUserLocations] = useState([]);
  const [locationsTreeView, setLocationsTreeView] = useState({});

  /* Functions */

  const localLoadUserLocations = async () => {
    const userLocations = await loadUserLocations({
      userId: user?.id
    });
    setUserLocations(userLocations);
  };

  const filterLocations = async () => {
    const locationsTreeData = await loadLocationsData(userLocations);
    setLocationsTreeView(locationsTreeData);
  };

  const loadLocations = () => {
    getDB('locationsReal')
      .then((response) => response.json())
      .then((data) => setLocations(data.response))
      .catch((error) => console.log(error));
  };

  const getImageUrl = (id) => {
    if (id === 'root') {
      setRealImageURL();
    } else {
      const result = locations.filter((location) => location._id === id);
      const image = result.map((coordinate) => coordinate.fileExt);
      
      if (image[0]) {        
        const imageURLLayout = getImageURL(id, 'locationsReal', image[0]);
        checkImage(imageURLLayout, (url) => setRealImageURL(url), () => setRealImageURL())
      } else {
        setRealImageURL();
      }
    }
  };

  /* Component Mounts */

  useEffect(() => {
    loadLocations();
    localLoadUserLocations();
  }, []);

  useEffect(() => {
    filterLocations();
  }, [userLocations]);

  useEffect(() => {
    getImageUrl(locationReal._id);
  }, [locations]);

  /* Render */

  return (
    <Portlet>
      <PortletHeader
        toolbar={
          <PortletHeaderToolbar>
            <Tabs
              className="builder-tabs"
              component="div"
              onChange={(_, nextTab) => setTab(nextTab)}
              value={tab}
            >
              <Tab label={GetTranslatedValue('ASSETS.MODAL.TAB.OTHER.MAP', 'Map')} />
              <Tab label={GetTranslatedValue('ASSETS.MODAL.TAB.OTHER.LAYOUT', 'Layout')} />
              <Tab label={GetTranslatedValue('ASSETS.MODAL.TAB.OTHER.CHILDREN', 'Children')} />
            </Tabs>
          </PortletHeaderToolbar>
        }
      />
      {tab === 0 && (
        <PortletBody>
          <OtherModalMapTab
            mapInfo={locationReal ? locationReal.mapInfo : null}
            mapMarker={mapMarker}
            setMapMarker={setMapMarker}
          />
        </PortletBody>
      )}

      {tab === 1 && (
        <PortletBody>
          <OtherModalLayoutTab
            realImageURL={realImageURL}
            layoutMarker={layoutMarker}
            setLayoutMarker={setLayoutMarker}
          />
        </PortletBody>
      )}

      {tab === 2 && (
        <PortletBody>
          <OtherModalChildrenTab
            assetRows={assetRows}
            assetOpened={assetOpened}
            isAssetDecommissioned={isAssetDecommissioned}
            locationsTreeView={locationsTreeView}
            onAssetFinderSubmit={onAssetFinderSubmit}
            onDeleteAssetAssigned={onDeleteAssetAssigned}
            parentLocations={parentLocations}
          />
        </PortletBody>
      )}
    </Portlet>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(OtherModalTabs);
