import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
const { REACT_APP_GOOGLE_API_KEY } = process.env;

const GoogleMaps = ({
  center,
  coords,
  edit = false,
  google,
  setCoords,
  setZoom,
  styleMap,
  zoom,
  onCenterChange,
  customProps = {}
}) => {

  const handleClick = (mapProps, map, { latLng }) => {
    if (edit) {
      const lat = latLng.lat();
      const lng = latLng.lng();
      setCoords([{ lat, lng }]);
    }
  };

  const handleDragEnd = (mapProps, map, { latLng }) => {
    if (edit) {
      const lat = latLng.lat();
      const lng = latLng.lng();
      setCoords([{ lat, lng }]);
    }
  };

  const handleZoomChanged = (mapProps, { zoom }, clickEvent) => {
    if (edit) {
      setZoom(zoom);
    }
  };

  const handleCenterChange = (_, map) => {
    if (onCenterChange) {
      onCenterChange({ lat: map.center.lat(), lng: map.center.lng() });
    }
  };

  return (
    <Map
      { ...customProps }
      google={google}
      initialCenter={center}
      onClick={handleClick}
      onZoomChanged={handleZoomChanged}
      style={styleMap}
      zoom={zoom}
      onDragend={handleCenterChange}
    >
      {coords?.map((coord, ix) => (
        <Marker key={`cord-${ix}`} draggable={edit} onDragend={handleDragEnd} position={coord} />
      ))}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY
})(GoogleMaps);
