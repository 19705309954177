import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../../store/ducks/general.duck';
import { getOneDB, updateDB, postDB } from '../../../../../crud/api';
import TicketRequest from '../TicketRequest';
import CustomRecordModal from '../../../Components/CustomRecordModal';

const ModalMyTickets = ({
  showModal,
  setShowModal,
  readOnly,
  reloadTable,
  id,
  employeeProfileRows
}) => {
  const dispatch = useDispatch();
  const { showErrorAlert, showSavedAlert, showSelectValuesAlert, showUpdatedAlert } = actions;

  const handleSave = () => {
    const { subject, message, selectedType, peaceOfMind } = values;

    if (!subject || !message || !selectedType || !peaceOfMind) {
      dispatch(showSelectValuesAlert());

      return;
    }

    const body = { ...values };

    if (!id) {
      postDB('tickets', body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          reloadTable();
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      updateDB('tickets/', body, id[0])
        .then((response) => {
          dispatch(showUpdatedAlert());
          reloadTable();
        })
        .catch((error) => dispatch(showErrorAlert()));
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };
  const reset = () => {
    setValues({
      message: '',
      peaceOfMind: 100,
      selectedType: 0,
      subject: ''
    });
  };

  useEffect(() => {
    if (!id || !Array.isArray(id)) {
      reset();
      return;
    }

    getOneDB('tickets/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const { _id, ...values } = data.response;
        setValues(values);
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [id, employeeProfileRows]);

  const [values, setValues] = useState({
    message: '',
    peaceOfMind: 100,
    selectedType: 0,
    subject: 'Hola'
  });

  return (
    <CustomRecordModal
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      id={id}
      key="Modal-Tickets"
      readOnly={readOnly}
      showModal={showModal}
      title="HELP.MODAL.CAPTION"
    >
      <div className="kt-section__content">
        <div className="profile-tab-wrapper">
          <TicketRequest readOnly={readOnly} setValues={setValues} values={values} />
        </div>
      </div>
    </CustomRecordModal>
  );
};

export default ModalMyTickets;
