/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { getDB, deleteDB } from '../../../../crud/api';
import TableComponent from '../../Components/TableComponent';
import ModalLists from './modals/ModalLists';
import ModalConstants from './modals/ModalConstants';
import { makeStyles } from '@material-ui/core';
import { GetTranslatedValue } from '../../utils';

const createListRow = (id, name, elements, creator, creationDate) => {
  return { id, name, elements, creator, creationDate };
};

const createConstantRow = (id, name, value, creator, creationDate) => {
  return { id, name, value, creator, creationDate };
};

const collections = {
  settingsLists: {
    id: 'idList',
    modal: 'openListModal',
    name: 'settingsLists'
  },
  settingsConstants: {
    id: 'idConstant',
    modal: 'openConstantModal',
    name: 'settingsConstants'
  }
};

const useStyles = makeStyles((theme) => ({
  tables: {
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    width: '50%',
  },
  leftTable: {
    paddingRight: '20px'
  },
  rightTable: {
    paddingLeft: '20px'
  },
  '@media (max-width: 1500px)': {
    leftTable: {
      paddingRight: '0px'
    },
    rightTable: {
      paddingLeft: '0px'
    },
    tables: {
      display: 'flex',
      flexDirection: 'column',
    },
    table: {
      width: '100%',
    },
  },
}));

const Custom = (props) => {
  const classes = useStyles();
  const [control, setControl] = useState({
    idList: null,
    openListModal: false,
    listRows: null,
    listRowsSelected: [],
    idConstant: null,
    openConstantModal: false,
    constantRows: null,
    constantRowsSelected: []
  });

  const listsHeadRows = [
    { id: 'name', numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.NAME') },
    { id: 'elements', numeric: true, disablePadding: false, label: GetTranslatedValue('SETTINGS.CUSTOM.CAPTION.LISTS.ELEMENTS') },
    { id: 'creator', numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATOR') },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    }
  ];

  const constantsHeadRows = [
    { id: 'name', numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.NAME') },
    { id: 'value', numeric: true, disablePadding: false, label: GetTranslatedValue('SETTINGS.CUSTOM.CAPTION.CONSTANTS.VALUE') },
    { id: 'creator', numeric: false, disablePadding: false, label: GetTranslatedValue('RECORD.CAPTION.CREATOR') },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    }
  ];

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) return;
        id.forEach((_id) => {
          deleteDB(`${collection.name}/`, _id)
            .then((response) => loadInitData(collection.name))
            .catch((error) => console.log('Error', error));
        });
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };
  const loadInitData = (
    collectionNames = ['settingsLists', 'settingsConstants']
  ) => {
    collectionNames = !Array.isArray(collectionNames)
      ? [collectionNames]
      : collectionNames;
    collectionNames.forEach((collectionName) => {
      getDB(collectionName)
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'settingsLists') {
            const rows = data.response.map((row) => {
              const {
                _id,
                name,
                options,
                creationUserFullName,
                creationDate
              } = row;

              return createListRow(
                _id,
                name,
                options.length,
                creationUserFullName,
                creationDate
              );
            });
            setControl((prev) => ({
              ...prev,
              listRows: rows,
              listRowsSelected: []
            }));
          } else if (collectionName === 'settingsConstants') {
            const rows = data.response.map((row) => {
              const {
                _id,
                name,
                value,
                creationUserFullName,
                creationDate
              } = row;

              return createConstantRow(
                _id,
                name,
                value,
                creationUserFullName,
                creationDate
              );
            });
            setControl((prev) => ({
              ...prev,
              constantRows: rows,
              constantRowsSelected: []
            }));
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };
  useEffect(() => {
    loadInitData();
  }, []);

  return (
    <div className={classes.tables}>
      <div className={clsx(classes.table, classes.leftTable)}>
        <ModalLists
          id={control.idList}
          readOnly={control.settingsListsReadOnly}
          reloadTable={() => loadInitData('settingsLists')}
          showModal={control.openListModal}
          setShowModal={(onOff) =>
            setControl({ ...control, openListModal: onOff })
          }
        />
        <TableComponent
          title={GetTranslatedValue('SETTINGS.CUSTOM.CAPTION.LIST.MANAGEMENT')}
          headRows={listsHeadRows}
          rows={control.listRows}
          onEdit={tableActions('settingsLists').onEdit}
          onAdd={tableActions('settingsLists').onAdd}
          onDelete={tableActions('settingsLists').onDelete}
          onSelect={tableActions('settingsLists').onSelect}
          onView={tableActions('settingsLists').onView}
          permissions={props.permissions}
        />
      </div>
      <div className={clsx(classes.table, classes.rightTable)}>
        <ModalConstants
          id={control.idConstant}
          readOnly={control.settingsConstantsReadOnly}
          reloadTable={() => loadInitData('settingsConstants')}
          showModal={control.openConstantModal}
          setShowModal={(onOff) =>
            setControl({ ...control, openConstantModal: onOff })
          }
        />
        <TableComponent
          title={GetTranslatedValue('SETTINGS.CUSTOM.CAPTION.CONSTANTS.MANAGEMENT')}
          headRows={constantsHeadRows}
          rows={control.constantRows}
          onEdit={tableActions('settingsConstants').onEdit}
          onAdd={tableActions('settingsConstants').onAdd}
          onDelete={tableActions('settingsConstants').onDelete}
          onSelect={tableActions('settingsConstants').onSelect}
          onView={tableActions('settingsConstants').onView}
          permissions={props.permissions}
        />
      </div>
    </div>
  );
};

export default Custom;
