import React from 'react';
import { Button } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { GetTranslatedValue } from '../../../utils';

const ImportButton = ({ disabled, onClick }) => (
  <Button
    color="default"
    component="span"
    disabled={disabled}
    onClick={onClick}
    startIcon={<SystemUpdateAltIcon />}
    style={{ height: '35px' }}
    variant="contained"
  >
    {GetTranslatedValue('IMPORT.IMPORT.BUTTON', 'Import')}
  </Button>
);

export default ImportButton;
