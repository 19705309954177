import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { actions } from '../../../../store/ducks/general.duck';
import { customFieldTypes } from '../settings-tabs/constants';
import { GetTranslatedValue } from '../../utils';

const styles5 = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle5 = withStyles(styles5)(({ children, classes, onClose }) => {
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContent5 = withStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px 30px 16px'
  }
}))(DialogContent);

const DialogActions5 = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(DialogActions);


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  check: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const boderColor = '#3F51B5';
const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    borderBottom: '1px solid gray',
    boxShadow: null,
    borderRadius: null,
    '&:focus, &:hover': { borderBottomColor: boderColor }
  }),
  indicatorSeparator: () => { },
  placeholder: (base) => ({ ...base, margin: '0px' }),
  valueContainer: (base) => ({ ...base, padding: '0px' }),
  input: (base) => ({ ...base, margin: '0px' })
};

const ModalCustomFields = ({ id, headers, categories, customFields, readOnly, onAdd, onEdit, referenceCollection, setShowModal, showModal, usedHeaders }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showCustomAlert, showFillFieldsAlert } = actions;
  const [values, setValues] = useState({
    header: null,
    category: null,
    dataType: null,
    field: ''
  });

  const handleCloseModal = () => {
    setValues({
      header: null,
      category: null,
      dataType: null,
      field: ''
    });
  };

  const handleSave = () => {
    if (Object.entries(values).find(([key, value]) => !value && key !== 'category')) {
      dispatch(showFillFieldsAlert());

      return;
    }

    if (!id) {
      const body = { id: uuidv4().split('-').pop(), ...values };
      onAdd(body);
    } else {
      const index = customFields.findIndex(({ id: customFieldId }) => customFieldId === id[0]);
      const body = { id: customFields[index].id, ...values };
      onEdit(index, body);
    }

    handleCloseModal();
  };

  const handleOnChange = (event) => {
    const field = event.target.value;
    setValues(prev => ({ ...prev, field }))
  };

  useEffect(() => {
    if (!showModal || !id) {
      return;
    }

    if (id) {
      const selectedCustomField = customFields.find(({ id: customFieldId }) => customFieldId === id[0]);
      const { category, dataType, header, field } = selectedCustomField;
      setValues({
        category,
        dataType,
        header,
        field
      });
    }
  }, [id, showModal]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      onClose={() => {
        handleCloseModal();
        setShowModal(false);
      }}
      open={showModal}
    >
      <DialogTitle5>
        {`${id ? (readOnly ? 'View' : 'Edit') : 'Add'} Custom Field`}
      </DialogTitle5>
      <DialogContent5 dividers>
        <TextField
          classes={{
            root: classes.textFieldClasses
          }}
          className={classes.textField}
          label={GetTranslatedValue('IMPORT.CUSTOM.FIELD', 'Field')}
          name={GetTranslatedValue('IMPORT.CUSTOM.FIELD', 'Field')}
          onChange={handleOnChange}
          value={values.field}
        />
        <Select
          className={classes.textField}
          hideSelectedOptions
          isClearable
          maxMenuHeight={200}
          menuPosition="fixed"
          onChange={(dataType) => setValues(prev => ({ ...prev, dataType }))}
          options={customFieldTypes}
          placeholder={GetTranslatedValue('IMPORT.CUSTOM.DATA.TYPE', 'Data Type')}
          styles={selectStyle}
          value={values.dataType}
        />
        <Select
          className={classes.textField}
          hideSelectedOptions
          isClearable
          maxMenuHeight={200}
          menuPosition="fixed"
          onChange={(header) => setValues(prev => ({ ...prev, header }))}
          options={headers.filter(({ value }) => !usedHeaders.includes(value))}
          placeholder={GetTranslatedValue('IMPORT.CUSTOM.HEADER', 'Header')}
          styles={selectStyle}
          value={values.header}
        />
        {/* {referenceCollection && (
          <Select
            className={classes.textField}
            hideSelectedOptions
            isClearable
            isMulti
            maxMenuHeight={200}
            menuPosition="fixed"
            onChange={(category) => {
              if ((category || []).find(({ value }) => value === 'all')) {
                setValues(prev => ({ ...prev, category: values.category?.length === categories.length ? null : categories }));

                return;
              }

              setValues(prev => ({ ...prev, category }));
            }}
            options={[{ label: values.category?.length === categories.length ? '-- Unselect All --' : '-- Select All --', value: 'all' }, ...categories]}
            placeholder="Category"
            styles={{ ...selectStyle, container: (base) => ({ ...base, marginBottom: '0px', maxHeight: '125px', overflowY: 'auto' }) }}
            value={values.category}
          />
        )} */}
      </DialogContent5>
      {!readOnly && (
        <DialogActions5>
          <Button onClick={handleSave} color='primary'>
            {GetTranslatedValue('GENERAL.CAPTION.SAVE.CHANGES', 'Save Changes')}
          </Button>
        </DialogActions5>
      )}
    </Dialog>
  )
}

export default ModalCustomFields
