import React from 'react';
import PropTypes from 'prop-types';
import { GetTranslatedValue } from '../utils';
import ModalYesNo from './ModalYesNo';

const ModalRemoveElement = ({ onCancel, onOK, showModal }) => (
  <ModalYesNo
    message={GetTranslatedValue('TABLE.MODAL.REMOVE.ELEMENT')}
    onCancel={onCancel}
    onOK={onOK}
    showModal={showModal}
    title={GetTranslatedValue('TABLE.MODAL.REMOVE.ELEMENT.TITLE')}
  />
);

ModalRemoveElement.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default ModalRemoveElement;
