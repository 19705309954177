import React from 'react';
import { MenuItem, Slider, TextField, Typography } from '@material-ui/core';
import './TicketRequest.scss';
import { GetTranslatedValue } from '../../utils';

const marks = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 25,
    label: '25%'
  },
  {
    value: 50,
    label: '50%'
  },
  {
    value: 75,
    label: '75%'
  },
  {
    value: 100,
    label: '100%'
  }
];

const TicketRequest = ({ readOnly, values, setValues }) => {
  const { subject, message, peaceOfMind, selectedType } = values;
  const [subject2, setSubject] = React.useState('');
  const [type, setType] = React.useState('');
  const [message2, setMessage] = React.useState('');
  const [mindLevel, setMindLevel] = React.useState(50);

  const types = [
    {
      value: 'bug',
      label: 'Bug'
    },
    {
      value: 'question',
      label: GetTranslatedValue('HELP.TICKETS.CAPTION.QUESTION')
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    setSubject('');
    setType('');
    setMessage('');
    setMindLevel(50);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <form className="__container-tr" onSubmit={handleSubmit}>
      <div className="__container-tr-left">
        <TextField
          disabled={readOnly}
          id="outlined-email-input"
          label={GetTranslatedValue('HELP.TICKETS.SUBJECT')}
          name="subject"
          margin="normal"
          onChange={handleOnChange}
          style={{ width: '80%' }}
          type="text"
          value={subject}
        />
        <TextField
          disabled={readOnly}
          id="outlined-select-currency-native"
          label={GetTranslatedValue('HELP.TICKETS.SELECTEDTYPE')}
          name="selectedType"
          onChange={handleOnChange}
          style={{ width: '80%' }}
          select
          value={selectedType}
        >
          {types.map((option) => (
            <MenuItem disabled={readOnly} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled={readOnly}
          id="outlined-multiline-static"
          label={GetTranslatedValue('HELP.TICKETS.CAPTION.MESSAGE')}
          multiline
          name="message"
          onChange={handleOnChange}
          rows={6}
          style={{ width: '80%' }}
          value={message}
        />
      </div>
      <div className="__container-tr-right">
        <Typography gutterBottom id="vertical-slider" style={{ textAlign: 'center' }}>
          {GetTranslatedValue('HELP.TICKETS.CAPTION.PEACEOFMINDFLVL')}
        </Typography>
        <p> {peaceOfMind}% </p>
        <Slider
          className="__slider"
          aria-labelledby="continuous-slider"
          marks={marks}
          disabled={readOnly}
          onChange={(_, value) => setValues({ ...values, peaceOfMind: value })}
          orientation={window.innerWidth >= 800 ? 'vertical' : 'horizontal'}
          value={peaceOfMind}
        />
      </div>
    </form>
  );
};

export default TicketRequest;
