import React, { useState } from 'react';
import TableComponent from '../../Components/TableComponent';
import ModalAssetFinder from '../modals/ModalAssetFinder';
import { GetTranslatedValue } from '../../../../pages/home/utils';

const collections = {
  settingsLists: {
    id: 'idAsset',
    modal: 'openAssetModal',
    name: 'employees'
  }
};

const AssetTable = ({
  assetRows = [],
  deleteOnly = false,
  onAssetFinderSubmit,
  onDeleteAssetAssigned,
  parentLocations,
  readOnly,
  locationsTreeView
}) => {
  const [control, setControl] = useState({
    idAsset: null,
    openAssetModal: false
  });

  const assetsHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME')
    },
    {
      id: 'brand',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.BRAND')
    },
    {
      id: 'model',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.MODEL')
    },
    {
      id: 'EPC',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.EPC')
    },
    {
      id: 'serial',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.SERIALNUMBER')
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    }
  ];
  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({ ...control, [collection.id]: null, [collection.modal]: true });
      },
      onDelete(id) {
        onDeleteAssetAssigned([...id]);
      }
    };
  };

  return (
    <>
      <ModalAssetFinder
        id={control.idAsset}
        onAssetFinderSubmit={onAssetFinderSubmit}
        setShowModal={(onOff) => setControl({ ...control, openAssetModal: onOff })}
        showModal={control.openAssetModal}
        userAssets={assetRows.map(({ id }) => id) || []}
        parentLocations={parentLocations}
        locationsTreeView={locationsTreeView}
      />
      <TableComponent
        defaultOrder="asc"
        defaultOrderBy="name"
        deleteOnly={deleteOnly}
        headRows={assetsHeadRows}
        noEdit={true}
        onAdd={tableActions('settingsLists').onAdd}
        onDelete={tableActions('settingsLists').onDelete}
        rows={assetRows}
        permissions={['add', 'delete']}
        title={GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENTS.CHILDREN')}
        readOnly={readOnly}
      />
    </>
  );
};

export default AssetTable;
