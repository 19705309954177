import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { GetTranslatedValue } from '../../../utils';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '60%',
    overflow: 'hidden'
  },
  label: {
    whiteSpace: 'nowrap'
  }
}));

const UploadButton = ({ label, onFileChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        accept=".xlsx, .xls, .csv"
        id="contained-button-file"
        key={`input-${label || ''}`}
        onChange={({ target: { files } }) => onFileChange(files[0])}
        style={{ display: 'none' }}
        type="file"
      />
      <label htmlFor="contained-button-file" style={{ marginBottom: '0px' }}>
        <Button
          classes={{
            label: classes.label
          }}
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
          style={{ height: '40px', margin: '10px 0px' }}
          variant="contained"
        >
          {label || GetTranslatedValue('IMPORT.UPLOAD.FILE', 'Upload file')}
        </Button>
      </label>
    </div>
  );
};

export default UploadButton;
