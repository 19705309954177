import { differenceInDays } from 'date-fns';
import { isEmpty, difference, uniq } from 'lodash';
import { copyObject, hosts } from '../utils';

const { apiHost } = hosts;
const {
  REACT_APP_API_SERVER,
  REACT_APP_API_PORT
} = process.env;

const _types = {
  simpleType: [
    'singleLine',
    'multiLine',
    'date',
    'dateTime',
    'currency',
    'percentage',
    'email',
    'decimal',
    'url',
    'formula',
    'richText'
  ],
  dropType: ['dropDown'],
  radioType: ['radioButtons'],
  checkType: ['checkboxes'],
  fileType: ['fileUpload'],
  imageType: ['imageUpload'],
  decisionType: ['decisionBox'],
};

export const _generalFields = {
  user: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Last Name', id: 'lastName', translation: 'RECORD.CAPTION.LASTNAME' },
    { label: 'Email', id: 'email', translation: 'RECORD.CAPTION.EMAIL' },
    { label: 'Boss', id: 'boss', translation: 'USERS.CAPTION.BOSS' },
    { label: 'Groups', id: 'groups', translation: 'TABS.TITLES.GROUPS' }
  ],
  userProfiles: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' }
  ],
  employees: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Last Name', id: 'lastName', translation: 'RECORD.CAPTION.LASTNAME' },
    { label: 'Email', id: 'email', translation: 'RECORD.CAPTION.EMAIL' }
  ],
  employeeProfiles: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' }
  ],
  locations: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Level', id: 'level', translation: 'LOCATIONS.CAPTION.LEVEL' }
  ],
  locationsReal: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' }
  ],
  categories: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Depreciation', id: 'depreciation', translation: 'GENERAL.CAPTION.DEPRECIATION' }
  ],
  references: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Brand', id: 'brand', translation: 'ASSETS.CAPTION.BRAND' },
    { label: 'Model', id: 'model', translation: 'ASSETS.CAPTION.MODEL' },
    { label: 'Price', id: 'price', translation: 'GENERAL.CAPTION.PRICE' }
  ],
  assets: [
    { label: 'Id', id: '_id' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Brand', id: 'brand', translation: 'ASSETS.CAPTION.BRAND' },
    { label: 'Model', id: 'model', translation: 'ASSETS.CAPTION.MODEL' },
    { label: 'Category', id: 'category', translation: 'GENERAL.CAPTION.CATEGORY' },
    { label: 'Status', id: 'status', translation: 'GENERAL.CAPTION.STATUS' },
    { label: 'Serial Number', id: 'serial', translation: 'ASSETS.CAPTION.SERIAL' },
    { label: 'Reponsible', id: 'assignedTo', translation: 'ASSETS.CAPTION.RESPONSIBLE' },
    { label: 'Notes', id: 'notes', translation: 'ASSETS.CAPTION.NOTES' },
    { label: 'Quantity', id: 'quantity', translation: 'ASSETS.CAPTION.QUANTITY' },
    { label: 'Purchase Date', id: 'purchase_date', translation: 'ASSETS.CAPTION.PURCHASEDATE' },
    { label: 'Purchase Price', id: 'purchase_price', translation: 'ASSETS.CAPTION.PURCHASEPRICE' },
    { label: 'Price', id: 'price', translation: 'GENERAL.CAPTION.PRICE' },
    { label: 'Total Price', id: 'total_price', translation: 'ASSETS.CAPTION.TOTALPRICE' },
    { label: 'EPC', id: 'EPC' },
    { label: 'Location Id', id: 'location', translation: 'LOCATIONS.CAPTION.ID' },
    { label: 'Location Name', id: 'locationName', translation: 'ASSET.REPORT.LOCATION.NAME' },
    { label: 'Location Path', id: 'locationPath', translation: 'ASSET.REPORT.LOCATION.PATH' },
    { label: 'Creator', id: 'creator', translation: 'RECORD.CAPTION.CREATOR' },
    { label: 'Labeling User', id: 'labeling_user', translation: 'ASSETS.CAPTION.LABELINGUSER' },
    { label: 'Labeling Date', id: 'labeling_date', translation: 'ASSETS.CAPTION.LABELINGDATE' },
    { label: 'Creation Date', id: 'creationDate', translation: 'RECORD.CAPTION.CREATIONDATE' },
    { label: 'Update Date', id: 'updateDate', translation: 'RECORD.CAPTION.UPDATEDATE' }
  ],
  depreciation: [],
  processLive: [
    { label: 'Folio', id: 'folio' },
    { label: 'Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Process Status', id: 'processStatus', translation: 'PROCESSES.INFO.STATUS' },
    { label: 'Stages', id: 'stages', translation: 'TABS.TITLES.STAGES' },
    { label: 'Stages Name', id: 'stagesName', translation: 'PROCESSES.INFO.STAGES.NAME' },
    { label: 'Process Type', id: 'selectedProcessType', translation: 'PROCESSES.CAPTION.TYPE' },
    { label: 'Involved Users', id: 'involvedUsers', translation: 'GENERAL.INVOLVED.USERS' },
    { label: 'Notification Users', id: 'notificationUsers', translation: 'PROCESSES.CAPTION.NOTIFICATION.USERS' },
    { label: 'Assets Involved', id: 'assetsInvolved', translation: 'GENERAL.INVOLVED.ASSETS' },
    { label: 'Due Date', id: 'dueDate', translation: 'GENERAL.DUE.DATE' },
    { label: 'Creator', id: 'creationUserFullName', translation: 'RECORD.CAPTION.CREATOR' },
    { label: 'Creation Date', id: 'creationDate', translation: 'RECORD.CAPTION.CREATIONDATE' }
  ],
  inventorySessions: [
    { label: 'Session Id', id: 'sessionId', translation: 'INVENTORIES.SESSION.ID' },
    { label: 'Session Name', id: 'name', translation: 'RECORD.CAPTION.NAME' },
    { label: 'Session Status', id: 'status', translation: 'INVENTORIES.STATUS', },
    { label: 'Session Location', id: 'locationName', translation: 'INVENTORIES.SESSION.LOCATION' },
    { label: 'Asset Id', id: 'assetId', translation: 'INVENTORIES.ASSET.ID' },
    { label: 'Asset EPC', id: 'EPC', translation: 'INVENTORIES.ASSET.EPC' },
    { label: 'Asset Name', id: 'assetName', translation: 'INVENTORIES.ASSET.NAME' },
    { label: 'Asset Serial Number', id: 'serial', translation: 'INVENTORIES.ASSET.SERIAL.NUMBER' },
    { label: 'Asset Model', id: 'model', translation: 'INVENTORIES.ASSET.MODEL' },
    { label: 'Asset Brand', id: 'brand', translation: 'INVENTORIES.ASSET.BRAND' },
    { label: 'Asset Status', id: 'assetStatus', translation: 'INVENTORIES.ASSET.STATUS' },
    { label: 'Inventory User', id: 'appUser', translation: 'INVENTORIES.INVENTORY.USER' },
    { label: 'Session Creation Date', id: 'creation', translation: 'INVENTORIES.SESSION.CREATION.DATE' }
  ],
  logBook: [
    { label: 'Id', id: '_id' },
    { label: 'Collection', id: 'collection', translation: 'GENERAL.COLLECTION' },
    { label: 'Method', id: 'method', translation: 'GENERAL.METHOD' },
    { label: 'Target Id', id: 'fetchedId', translation: 'LOGBOOK.TARGET.ID' },
    { label: 'User Name', id: 'userName', translation: 'LOGBOOK.USER.NAME' },
    { label: 'User Last Name', id: 'userLastName', translation: 'LOGBOOK.USER.LAST.NAME' },
    { label: 'User Email', id: 'userEmail', translation: 'LOGBOOK.USER.EMAIL' },
    { label: 'Payload', id: 'payload', translation: 'LOGBOOK.PAYLOAD' },
    { label: 'Query', id: 'query', translation: 'LOGBOOK.QUERY' },
    { label: 'Creation Date', id: 'timestamp', translation: 'RECORD.CAPTION.CREATIONDATE' }
  ]
};

export const baseFieldsPerModule = {
  assets: ['assets1', 'assets2'],
  references: 'references',
  categories: 'categories',
  user: 'userList',
  userProfiles: 'userReferences',
  employees: 'employees',
  employeeProfiles: 'employeeReferences',
  locations: 'locations',
  locationsReal: 'locationsList'
};

export const formatCollection = (collectionName, completeFields) => {
  let customFieldNames = {};
  const rowToObjects = completeFields.map((row) => {
    // Extract General Fields
    const filteredGeneralFields = extractGeneralField(collectionName, row);
    // Extract Custom Fields with formatting
    let filteredCustomFields = {};
    const { customFieldsTab } = row;
    Object.values(customFieldsTab).forEach((tab) => {
      const allCustomFields = [...tab.left, ...tab.right];
      allCustomFields.forEach((field) => {
        filteredCustomFields = {
          ...filteredCustomFields,
          ...extractCustomField(field)
        };
      });
    });
    customFieldNames = { ...customFieldNames, ...filteredCustomFields };
    return { ...filteredGeneralFields, ...filteredCustomFields };
  });
  // Make all rows homogenous filling missing custom fields
  const normalizedRows = normalizeRows(rowToObjects, customFieldNames);
  // Convert rows to table presentation (Array for headers and every data row in an array)
  return convertRowsToDataTable(normalizedRows);
};

export const formatData = (collectionName, completeFields, customFieldsFilterInfo) => {
  let customFieldNames = {};
  const rowToObjects = completeFields.map((row) => {
    // Extract General Fields
    const filteredGeneralFields = extractGeneralField(collectionName, row);
    // Extract Custom Fields with formatting
    let filteredCustomFields = {};
    const { customFieldsTab } = row;

    if (collectionName === 'processLive' && customFieldsFilterInfo) {
      const stageCustomFields = row.processData.stages.find(({ stageId }) => customFieldsFilterInfo.stageId === stageId)?.customFieldsTab || {};
      if (!isEmpty(stageCustomFields)) {
        const allCustomFields = [...stageCustomFields[customFieldsFilterInfo.tab].left, ...stageCustomFields[customFieldsFilterInfo.tab].right];
        allCustomFields.forEach((field) => {
          filteredCustomFields = {
            ...filteredCustomFields,
            ...extractCustomField(field)
          };
        });
      }
    } else {
      Object.values(customFieldsTab || {}).forEach((tab) => {
        const allCustomFields = [...tab.left, ...tab.right];
        allCustomFields.forEach((field) => {
          filteredCustomFields = {
            ...filteredCustomFields,
            ...extractCustomField(field)
          };
        });
      });
    }
    
    customFieldNames = { ...customFieldNames, ...filteredCustomFields };

    return { ...filteredGeneralFields, ...filteredCustomFields };
  });
  // Make all rows homogenous filling missing custom field
  const normalizedRows = normalizeRows(rowToObjects, customFieldNames);
  // Convert rows to table presentation (Array for headers and every data row in an array)
  return convertRowsToDataTableObjects(normalizedRows);
};

export const extractGeneralField = (collectionName, row) => {
  let filteredGeneralFields = {};
  _generalFields[collectionName].forEach(({ id: field }) => {
    let currentField = field;
    let objectValue;

    if (collectionName === 'assets' && field === 'category') {
      objectValue = row['category'] ? row['category'].label || '' : '';
    } else if (collectionName === 'assets' && field === 'locationName') {
      const locations = row['locationPath']?.split('/') || [];
      objectValue = locations[locations.length - 1]
    } else if (collectionName === 'processLive' && !row[field]) {
      if (field === 'stages') {
        objectValue = Object.keys(row['processData'][`${field}`]).length;
      } else if (field === 'involvedUsers') {
        objectValue = uniq(row['processData']['stages'].reduce((acc, { approvals }) => {
          return [...acc, ...approvals.reduce((accu, { virtualUser, name, lastName }) => {
            return [...accu, (virtualUser ? virtualUser : `${name} ${lastName}`)];
          }, [])];
        }, [])).join('-');
      } else if (field === 'notificationUsers') {
        objectValue = uniq(row['processData']['stages'].reduce((acc, { notifications }) => {
          return [...acc, ...notifications.reduce((accu, { name, lastName }) => {
            return [...accu, `${name} ${lastName}`];
          }, [])];
        }, [])).join('-');
      } else if (field === 'assetsInvolved') {
        const mappedObject = row['cartRows'].reduce((acc, asset) => {
          if (acc[asset.name]) {
            acc[asset.name] += 1;
          } else {
            acc[asset.name] = 1;
          }
          return acc;
        }, {});

        objectValue = Object.entries(mappedObject).map(([key, value]) => `(${key}: ${value})`).join('-');
      } else if (field === 'stagesName') {
        objectValue = uniq(row['processData']['stages'].map(({ stageName }) => stageName)).join('-');
      } else if (field === 'dueDate') {
        const pastDue = differenceInDays(new Date(row['dueDate']), new Date());
        objectValue = pastDue;
      } else {
        objectValue = row['processData'][`${field}`];
      }
    }

    if (collectionName === 'user' && (field === 'boss' || field === 'groups')) {
      if (field === 'boss') {
        objectValue = row['selectedBoss']
          ? `${row['selectedBoss'].name} ${row['selectedBoss'].lastName}`
          : '';
      }

      if (field === 'groups') {
        objectValue = (row[field] || []).map(({ name }) => name).join(', ') || '';
      }
    }

    if (collectionName === 'logBook' && field === 'payload') {
      objectValue = !isEmpty(Object.entries(row.payload || {}))
        ? JSON.stringify(row.payload)
        : '';
    }

    if (collectionName === 'logBook' && field === 'query') {
      objectValue = !isEmpty(Object.entries(row.query || {}))
        ? JSON.stringify(row.query)
        : '';
    }

    
    const label = typeof row[currentField] === 'object' ? '' : row[currentField] || '';
    filteredGeneralFields = {
      ...filteredGeneralFields,
      [currentField]: objectValue ? copyObject(objectValue) : copyObject(label)
    };
  });

  return filteredGeneralFields;
};

export const extractCustomFieldValues = (field) => {
  const { content, values, id } = field;
  if (isEmpty(values)) {
    return { [content]: '' };
  }

  const {
    fieldName,
    initialValue,
    options,
    selectedItem,
    fileName,
    selectedOptions,
    fileId,
    fileExt
  } = values;

  if (_types['simpleType'].includes(content)) {
    return { [id]: initialValue || '' };
  } else if (_types['dropType'].includes(content)) {
    return { [id]: options[Number(selectedItem) - 1] || '' };
  } else if (_types['radioType'].includes(content)) {
    if (!selectedItem) return { [fieldName]: '' };
    const selected = selectedItem.slice(-1);
    return { [id]: options[Number(selected) - 1] || '' };
  } else if (_types['checkType'].includes(content)) {
    return { [id]: selectedOptions ? selectedOptions.join(', ') : '' };
  } else if (_types['fileType'].includes(content)) {
    return { [id]: fileName ? `${apiHost}/uploads/customFields/${fileId}.${fileExt}` : '' };
  } else if (_types['imageType'].includes(content)) {
    return { [id]: fileName ? `${apiHost}/uploads/customFields/${fileName}.${initialValue}` : '' };
  } else if (_types['decisionType'].includes(content)) {
    return { [id]: !isEmpty(values.selectedOptions) ? values.selectedOptions.join(', ') : '' };
  }
};

export const extractCustomField = (field) => {
  const { content, values } = field;
  if (isEmpty(values)) {
    return { [content]: '' };
  }

  const {
    fieldName,
    initialValue,
    options,
    selectedItem,
    fileName,
    selectedOptions,
    fileId,
    fileExt
  } = values;

  if (_types['simpleType'].includes(content)) {
    return { [fieldName]: initialValue || '' };
  } else if (_types['dropType'].includes(content)) {
    return { [fieldName]: options[Number(selectedItem) - 1] || '' };
  } else if (_types['radioType'].includes(content)) {
    if (!selectedItem) return { [fieldName]: '' };
    const selected = selectedItem.split('rad')[1];
    return { [fieldName]: options[Number(selected) - 1] || '' };
  } else if (_types['checkType'].includes(content)) {
    return {
      [fieldName]: !isEmpty(selectedOptions) ? selectedOptions.join() : ''
    };
  } else if (_types['fileType'].includes(content)) {
    return { [fieldName]: fileName ? `${apiHost}/uploads/customFields/${fileId}.${fileExt}` : '' };
  } else if (_types['imageType'].includes(content)) {
    return { [fieldName]: fileName ? `${apiHost}/uploads/customFields/${fileName}.${initialValue}` : '' };
  } else if (_types['decisionType'].includes(content)) {
    const res = options.reduce((acu, cur, ix) => values[`check${ix}`] ? acu += `${cur}|` : acu, '');
    return { [fieldName]: res.length ? res.slice(0, -1) : '' };
  }
};

export const extractCustomFieldId = (field) => {
  const { content, values, id } = field;
  const { fieldName } = values;
  let res;
  Object.entries(_types).forEach(([key, value]) => {
    if (value.includes(content)) {
      res = { [id]: fieldName || content };
    }
  });

  return res;
};

export const normalizeRows = (rows, allCustomFields) => {
  return rows.map((row) => {
    const missingCustomFields = difference(
      Object.keys(allCustomFields),
      Object.keys(row)
    );
    let missingCustomFieldsFormatted = {};
    missingCustomFields.forEach(
      (field) => (missingCustomFieldsFormatted = {
        ...missingCustomFieldsFormatted,
        [field]: ''
      })
    );

    return { ...row, ...missingCustomFieldsFormatted };
  });
};

const convertRowsToDataTable = (rows) => {
  if (!rows || !Array.isArray(rows) || !rows.length) {
    return { header: [], tableRows: [] };
  }

  const header = Object.keys(rows[0]);
  const tableRows = rows.map((row) => header.map((head) => row[head]));
  return { header, tableRows };
};

export const convertRowsToDataTableObjects = (rows) => {
  if (!rows || !Array.isArray(rows) || !rows.length) {
    return { header: [], headerObject: [], tableRows: [], rows: [] };
  }

  const header = Object.keys(rows[0]);
  // eslint-disable-next-line no-labels
  const headerObject = header.map((e) => ({ id: e, label: e }));
  const tableRows = rows.map((row) => header.map((head) => row[head]));
  return { header, tableRows, headerObject, rows };
};

export const getGeneralFieldsHeaders = (collection) => {
  if (!collection) {
    return [];
  }

  const headerObject = _generalFields[collection].map(({ id, label, translation }) => ({
    id,
    label,
    translation
  }));

  return headerObject;
};

export const getUserPermittedModules = (user) => {
  const modulePermissions = ['assets', 'users', 'employees', 'locations', 'processes'];
  let modules = [];
  modulePermissions.forEach((permission) => {
    if (Object.keys(user.profilePermissions).includes(permission)) {
      switch (permission) {
        case 'assets':
          modules.push(
            { id: 'assets', label: 'Assets', translation: 'GENERAL.CAPTION.ASSETS' },
            { id: 'references', label: 'References', translation: 'GENERAL.CAPTION.REFERENCES' },
            { id: 'categories', label: 'Categories', translation: 'GENERAL.CAPTION.CATEGORIES' }
          );
          break;
        case 'users':
          modules.push(
            { id: 'user', label: 'Users', translation: 'GENERAL.CAPTION.USER' },
            { id: 'userProfiles', label: 'User Profiles', translation: 'GENERAL.CAPTION.USER.PROFILES' }
          );
          break;
        case 'employees':
          modules.push(
            { id: 'employees', label: 'Employees', translation: 'GENERAL.CAPTION.EMPLOYEES' },
            { id: 'employeeProfiles', label: 'Employee Profiles', translation: 'GENERAL.CAPTION.EMPLOYEE.PROFILES' }
          );
          break;
        case 'locations':
          modules.push(
            { id: 'locationsReal', label: 'Locations', translation: 'GENERAL.CAPTION.LOCATIONSREAL' },
            { id: 'locations', label: 'Location Profiles', translation: 'GENERAL.CAPTION.LOCATIONS' }
          );
          break;
        case 'processes':
          modules.push({ id: 'processLive', label: 'Processes', translation: 'GENERAL.CAPTION.PROCESSLIVE' });
          break;
        default:
          break;
      }
    }
  });

  return modules;
};
