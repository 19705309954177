import React from 'react';
import { connect } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import DonutLargeRoundedIcon from '@material-ui/icons/DonutLargeRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { host } from '../../../../crud/api';
import { GetTranslatedValue } from '../../utils';
import TableComponent from '../../Components/TableComponent';

const useStyles = makeStyles(() => ({
  errorButton: {
    backgroundColor: '#FD397A'
  }
}));

const statusIcons = {
  COMPLETED: <GetAppRoundedIcon />,
  WIP: <DonutLargeRoundedIcon />,
  ERROR: <ErrorRoundedIcon />
};

const DataExtraction = ({ loadData, onDelete, rows, user }) => {
  const classes = useStyles();

  const headRows = [
    {
      id: 'collection',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.COLLECTION')
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.STATUS'),
      renderCell: (report) => renderReportStatus(report)
    }
  ];

  const renderReportStatus = (report) => {
    const { status } = report;

    return (
      <div
        style={{
          borderBottom: '1px solid rgb(224, 224, 224)',
          display: 'table-cell',
          verticalAlign: 'middle',
          textAlign: 'start',
          padding: '16px'
        }}
      >
        <Button
          className={status === 'ERROR' ? classes.errorButton : {}}
          color={status === 'COMPLETED' ? 'secondary' : status === 'WIP' ? 'primary' : 'default'}
          disabled={status !== 'COMPLETED'}
          onClick={() => downloadReport(report)}
          startIcon={statusIcons[status]}
          style={{ color: '#FFFFFF' }}
          variant="contained"
        >
          {status === 'COMPLETED' ? 'Download' : status === 'WIP' ? 'In Progress' : 'Error'}
        </Button>
      </div>
    );
  };

  const downloadReport = (report) => {
    const { location, reportFile } = report;

    window.open(`${host}uploads/reports/${reportFile}`);
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={loadData}
        startIcon={<RefreshRoundedIcon />}
        variant="contained"
      >
        {GetTranslatedValue('GENERAL.REFRESH')}
      </Button>
      <TableComponent
        rows={rows}
        headRows={headRows}
        noAdd
        noEdit
        onDelete={onDelete}
        permissions={user?.profilePermissions?.reports?.dataExtraction}
        title={GetTranslatedValue('REPORTS.TITLES.DATA.EXTRACTION')}
      />
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(DataExtraction);
