/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  lighten,
  makeStyles
} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import OverflowTip from './OverflowTip';
import CustomizedTablePagination from './customizedTablePagination';
import { GetTranslatedValue } from '../utils';
import ModalRemoveElement from './ModalRemoveElement';
import CircularProgressCustom from './CircularProgressCustom';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const TableComponent = props => {
  const {
    defaultOrder,
    defaultOrderBy,
    deleteOnly,
    headRows,
    noAdd,
    noEdit,
    onAdd,
    onSelect,
    permissions,
    rows,
    readOnly,
    noSelect,
    isImport
  } = props;
  const [dense, setDense] = useState(false);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const isSelected = name => selected.indexOf(name) !== -1;
  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { selected, onAdd, noEdit, noView } = props;
    const numSelected = selected.length;

    const onDelete = () => {
      props.onDelete();
    }

    useEffect(() => {
      if (!props.onSelect) {
        return;
      }

      if (isImport) {
        props.onSelect(selected);
      } else {
        const selectedIdToSend = numSelected === 1 ? selectedId[0] : null;
        props.onSelect(selectedIdToSend);
      }
    }, [numSelected]);

    useEffect(() => {
      setLoading(!rows ? true : false);
    }, [rows]);

    const HeaderTools = () => {
      if (numSelected > 0) {
        return (
          <div style={{ display: 'flex' }}>
            {numSelected === 1 && !noEdit && permissions.includes('view') && (
              <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.VIEW')}>
                <IconButton aria-label='View' onClick={props.onView}>
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
            )
            }
            {numSelected === 1 && !noEdit && permissions.includes('edit') && (
              <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.EDIT')}>
                <IconButton aria-label='Edit' onClick={props.onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )
            }
            {permissions.includes('delete') && (
              <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.DELETE')}>
                <IconButton aria-label='Delete' onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )
      } else {
        return (
          <>
            {numSelected === 0 && !noAdd && permissions.includes('add') &&
              <Tooltip title={GetTranslatedValue('GENERAL.CAPTION.ADD')}>
                <IconButton aria-label='Filter list' onClick={onAdd} disabled={readOnly || deleteOnly}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          </>
        );
      }
    };

    if (isEmpty(props.title)) {
      return <></>;
    }

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color='inherit' variant='subtitle1'>
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant='h6' id='tableTitle'>
              {props.title}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <HeaderTools />
        </div>
      </Toolbar>
    );
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows?.map(n => isImport ? n : n.id);
      setSelected(newSelecteds);
      setSelectedId(newSelecteds);

      return;
    }

    setSelected([]);
  }

  const handleImportClick = (row)  => {
    const isSelected = selected.includes(row);
    const copy = [...selected];

    if (isSelected) {
      copy.splice(selected.indexOf(row), 1);
      setSelected(copy);
    } else {
      setSelected(prev => [...prev, row]);
    }
  };

  function handleClick(event, name, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [], newSelectedId = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedId = newSelectedId.concat(selectedId, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedId = newSelectedId.concat(selectedId.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedId = newSelectedId.concat(selectedId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedId = newSelectedId.concat(
        selectedId.slice(0, selectedIndex),
        selectedId.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedId(newSelectedId);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function EnhancedTableHead(props) {
    const {
      numSelected,
      onRequestSort,
      onSelectAllClick,
      order,
      orderBy,
      rowCount
    } = props;

    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {!noSelect && (
            <TableCell align='left' padding='checkbox'>
              <Checkbox
                checked={rowCount && (numSelected === rowCount)}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                inputProps={{ 'aria-label': 'Select all desserts' }}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {headRows.map(row => (
            <TableCell
              align='left'
              key={row.id}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const onDelete = () => {
    props.onDelete(selectedId);
    setSelected([]);
    setSelectedId([]);
    setOpenYesNoModal(false);
  };

  const onEdit = () => {
    props.onEdit(selectedId);
    setSelected([]);
    setSelectedId([]);
  };

  const onView = () => {
    props.onView(selectedId);
    setSelected([])
    setSelectedId([])
  }

  const pageDebouncer = (value, currentPage, total) => {
    if (!isNaN(value)) {
      const number = Number(value);
      const pageValue = number > total || number < 1 ? currentPage : number - 1;
      setPage(pageValue);
      setCurrentPage(pageValue + 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageChangeDebounced = useRef(debounce(pageDebouncer, 600));
  const numberOfRows = rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className={classes.root} style={{ padding: '0px' }}>
      <ModalRemoveElement
        onCancel={() => setOpenYesNoModal(false)}
        onOK={onDelete}
        showModal={openYesNoModal}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          noEdit={noEdit}
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={() => setOpenYesNoModal(true)}
          onSelect={onSelect}
          onView={onView}
          selected={selected}
          title={props.title}
        />
        <div className={classes.tableWrapper}>
          {loading && (
            <div style={{
              width: '100%',
              height: 49 * (rowsPerPage + 2.5),
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center'
            }}>
              <CircularProgressCustom size={40} />
            </div>
          )}
          <Table
            aria-labelledby='tableTitle'
            className={classes.table}
            size={dense ? 'small' : 'medium'}
          >
            {!loading && (
              <>
                <EnhancedTableHead
                  noEdit={noEdit}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  order={order}
                  orderBy={orderBy}
                  rowCount={rows?.length}
                />
                <TableBody>
                  <>
                    {isEmpty(rows) && (
                      <TableRow
                        hover
                        key={`No info`}
                        role='checkbox'
                        tabIndex={-1}
                      >
                        <TableCell
                          align={'center'}
                          colSpan={100}
                          component='th'
                          key={`NoData`}
                          padding={'default'}
                          scope='row'
                        >
                          <Typography variant='h5'>
                            {GetTranslatedValue('TABLE.CAPTION.NO.RECORDS.FOUND')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {!isEmpty(rows) && (
                      stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(isImport ? row : row.id);
                          const labelId = `enhanced-table-checkbox-\${index}`;
                          return (
                            <TableRow
                              aria-checked={isItemSelected}
                              hover
                              key={`key-row-${row.id || index}`}
                              //key={headRows[0].id}
                              onClick={(event) => {
                                if (noSelect) {
                                  return;
                                } else if (!isImport) {
                                  handleClick(event, row.name, row.id);
                                } else {
                                  handleImportClick(row);
                                }
                              }}
                              role='checkbox'
                              selected={isItemSelected}
                              tabIndex={-1}
                            >
                              {!noSelect && (
                                <TableCell align='left' padding='checkbox'>
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                              )}

                              {headRows.map((header, ix) => (
                                <TableCell
                                  align='left'
                                  component={
                                    header.renderCell
                                      ? () => header.renderCell(row)
                                      : 'th'
                                  }
                                  key={`cell-row${index}-${ix}`}
                                  padding='default'
                                  scope='row'
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  {!header.rendercell && (
                                    <OverflowTip>
                                      {row[header.id]}
                                    </OverflowTip>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })
                    )}
                  </>
                </TableBody>
              </>
            )}
          </Table>
        </div>
        <CustomizedTablePagination
          controlValues={{ total: rows?.length }}
          paginationInfo={{ page, rowsPerPage }}
          numberOfRows={numberOfRows?.length || 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          pageChangeDebounced={pageChangeDebounced}
        />
      </Paper>
    </div>
  );
};

TableComponent.defaultProps = {
  defaultOrder: 'desc',
  defaultOrderBy: 'creationDate',
  headRows: [],
  noAdd: false,
  noEdit: false,
  onAdd: () => {},
  onSelect: () => {},
  permissions: [],
  rows: [],
  readOnly: false,
};

TableComponent.propTypes = {
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  headRows: PropTypes.array,
  noAdd: PropTypes.bool,
  noEdit: PropTypes.bool,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func,
  permissions: PropTypes.array,
  rows: PropTypes.array,
  readOnly: PropTypes.bool,
};

export default TableComponent;
