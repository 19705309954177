/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  alert: {
    "& .MuiAlert-icon": {
      fontSize: 30
    }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px'
    }
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    padding: '10px',
    width: '100%',
    height: 'auto',
    '& > div:first-child': {
      display: 'flex',
      flex: 2
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      height: '500px'
    }
  },
  map: {
    display: 'flex',
    flex: 1,
    height: '500px',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  mapViewInputs: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },
  mapTextField: {
    marginBottom: theme.spacing(2),
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '40%'
    }
  }
}));
