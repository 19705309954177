import { useEffect, useState } from 'react';
import { getDB } from '../../../../crud/api';

export const useFieldValidator = (collectionName) => {
  const [fieldsToValdiate, setFieldsToValidate] = useState({});

  useEffect(() => {
    getDB('settingsFields')
      .then(response => response.json())
      .then(data => {
        const allFields = data?.response[0]?.fields || {};
        const fields = data?.response[0]?.fields[collectionName] || {};
        const fieldsToValidate = {};
        Object.entries(allFields).forEach(([key, value]) => {
          const keyValue = Object.keys(allFields[key]).reduce((acu, fieldKey) => (
            [...acu, ...value[fieldKey].mandatory ? [fieldKey] : []]
          ), []);

          fieldsToValidate[key] = keyValue;
        });

        setFieldsToValidate({ allFields, fields, fieldsToValidate });
      })
      .catch(error => console.log('error>', error));
  }, [collectionName])

  return fieldsToValdiate;
};
