import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import GoogleMaps from '../../Components/GoogleMaps';
import { GetTranslatedValue } from '../../utils';

const PIN_HEIGHT = '500px';

const useStyles = makeStyles(() => ({
  root: {
    height: PIN_HEIGHT,
    maxHeight: PIN_HEIGHT
  },
  pin: {
    position: 'absolute'
  }
}));

export default function OtherModalMapTab({ mapInfo, mapMarker, setMapMarker }) {
  const classes = useStyles();
  const [marker, setMarker] = useState(mapMarker ? [mapMarker] : []);
  const [zoom, setZoom] = useState(mapInfo ? mapInfo.zoom : 6);
  const [width, setWidth] = useState();

  useEffect(() => setMapMarker(marker[0]), [marker]);

  const handleResize = () => {
    const { innerWidth: width } = window;
    setWidth(width);
  };

  useEffect(() => {
    const { innerWidth: width } = window;
    setWidth(width);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (mapInfo) {
      setZoom(mapInfo.zoom);
    }
  }, [mapInfo]);

  const getWidth = () => {
    if (width <= 520) {
      return '78%';
    } else if (width <= 700) {
      return '85%';
    } else {
      return '90%';
    }
  };

  return (
    <Grid className={classes.root} container>
      {mapInfo ? (
        <GoogleMaps
          edit
          styleMap={{
            height: PIN_HEIGHT,
            width: getWidth(),
            position: 'relative'
          }}
          center={mapInfo}
          coords={marker ? marker : []}
          zoom={zoom}
          setCoords={setMarker}
          setZoom={setZoom}
        />
      ) : (
        <Grid style={{ flex: 1 }} item container alignItems="center" justify="center">
          <h6 style={{ alignSelf: 'center' }}>
            {GetTranslatedValue('ASSETS.MODAL.TAB.OTHER.NO.MAP.INFO', 'No Map Information Found')}
          </h6>
        </Grid>
      )}
    </Grid>
  );
}
