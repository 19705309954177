import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { difference, isEmpty, uniqBy } from 'lodash';
import { differenceInDays } from 'date-fns';
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import Notification from '@material-ui/icons/NotificationImportant';
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect, useDispatch } from 'react-redux';
import { actions } from '../../../store/ducks/general.duck';
import {
  getCountDB,
  createReportFile,
  getDBComplex,
  getDB,
  getOneDB,
  postDB
} from '../../../crud/api';
import TableReportsGeneral from '../Components/TableReportsGeneral';
import CircularProgressCustom from '../Components/CircularProgressCustom';
import SaveReportModal from './modals/SaveReportModal';
import { allBaseFields, reportsModules } from '../constants';
import {
  baseFieldsPerModule,
  convertRowsToDataTableObjects,
  extractCustomField,
  formatData,
  getGeneralFieldsHeaders,
  getUserPermittedModules,
  normalizeRows,
  _generalFields
} from './reportsHelpers';
import './TabGeneral.scss';
import DataExtractionConfitmationModal from './modals/DataExtractionConfitmationModal';
import TreeViewComponent from '../Components/TreeViewComponent';
import { getLocationPath, selectLocation, GetTranslatedValue, hosts } from '../utils';

const { apiHost } = hosts;
const { REACT_APP_API_SERVER, REACT_APP_API_PORT } = process.env;

const dataTableDefault = {
  header: [],
  tableRows: null,
  title: '',
  headerObject: []
};

const specificFilters = {
  processLive: [
    {
      id: 'folios',
      label: 'Folios'
    },
    {
      id: 'processName',
      label: 'Process Name',
      translation: 'PROCESSES.INFO.NAME'
    },
    {
      id: 'selectedProcessType',
      label: 'Process Type',
      translation: 'PROCESSES.MODAL.PROCESS.TYPE'
    },
    {
      id: 'processStatus',
      label: 'Process Status',
      translation: 'PROCESSES.INFO.STATUS'
    },
    {
      id: 'stage',
      label: 'Stage',
      translation: 'PROCESSES.CAPTION.STAGE'
    },
    {
      id: 'creationUserId',
      label: 'Creation User',
      translation: 'GENERAL.CAPTION.CREATION.USER'
    },
    {
      id: 'approvalUser',
      label: 'Approval User',
      translation: 'PROCESSES.CAPTION.APPROVAL.USERS'
    }
  ],
  inventorySessions: [
    {
      id: 'sessionId',
      label: 'Session ID',
      translation: 'INVENTORIES.SESSION.ID'
    },
    {
      id: 'name',
      label: 'Session Name',
      translation: 'INVENTORIES.SESSION.NAME'
    },
    {
      id: 'status',
      label: 'Status',
      translation: 'INVENTORIES.STATUS',
      single: true
    },
    {
      id: 'locationId',
      label: 'Location',
      translation: 'INVENTORIES.SESSION.LOCATION'
    }
  ],
  logBook: [
    {
      id: 'collection',
      label: 'Module',
      translation: 'GENERAL.MODULE',
      single: true
    },
    {
      id: 'method',
      label: 'Method',
      translation: 'GENERAL.METHOD'
    },
    {
      id: 'userId',
      label: 'Request User',
      translation: 'GENERAL.REQUEST.USER'
    }
  ],
  fieldValuesRepeated: [
    {
      id: 'collection',
      label: 'Module',
      translation: 'GENERAL.MODULE',
      single: true
    },
    {
      id: 'baseFields',
      label: 'Base Fields',
      translation: 'GENERAL.CAPTION.BASE.FIELDS',
      single: true
    }
  ]
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  filterTitles: {
    marginBottom: '0px',
    fontSize: 16
  },
  textField: {
    margin: '30px 30px 0px 30px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
      width: '200px'
    }
  },
  customFieldsTextField: {
    maxHeight: '200px',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
      width: 'auto'
    }
  }
}));

const TabGeneral = ({
  id,
  filteredLocationsProps,
  savedReports,
  setId,
  setTab,
  reloadData,
  user,
  userLocations,
  parentLocations
}) => {
  const dispatch = useDispatch();
  const {
    setGeneralLoading,
    showErrorAlert,
    showSavedAlert,
    showSelectValuesAlert,
    showCustomAlert
  } = actions;
  const classes = useStyles();
  const [control, setControl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [collectionName, setCollectionName] = useState(null);
  const [dataTable, setDataTable] = useState(dataTableDefault);
  const [locationsFilter, setLocationsFilter] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [treeViewProps, setTreeViewProps] = useState({ expanded: ['root'] });
  const [values, setValues] = useState({
    selectedReport: '',
    startDate: '',
    endDate: '',
    enabled: false,
    reportName: ''
  });
  const [tableConditions, setTableConditions] = useState({});
  const [allCustomFieldsTabs, setAllCustomFieldsTabs] = useState([]);
  //? FIXME
  const [permittedModules, setPermittedModules] = useState(getUserPermittedModules(user));
  const [specificFiltersOptions, setSpecificFiltersOptions] = useState({
    processLive: {
      folios: [],
      processName: [],
      selectedProcessType: [
        { id: 'creation', label: 'Creation', translation: 'PROCESSES.TYPE.CREATION' },
        { id: 'maintenance', label: 'Maintenance', translation: 'PROCESSES.TYPE.MAINTENANCE' },
        { id: 'decommission', label: 'Decommission', translation: 'PROCESSES.TYPE.DECOMISSION' },
        { id: 'movement', label: 'Movement', translation: 'PROCESSES.TYPE.MOVEMENT' },
        { id: 'short', label: 'Short Movement', translation: 'PROCESSES.TYPE.SHORT.MOVEMENT' }
      ],
      processStatus: [
        { id: 'approved', label: 'Approved', translation: 'PROCESSES.INFO.ASSETS.STATE.APPROVED' },
        {
          id: 'partiallyApproved',
          label: 'Partially Approved',
          translation: 'PROCESSES.INFO.PARTIALLY.APPROVED'
        },
        { id: 'rejected', label: 'Rejected', translation: 'PROCESSES.INFO.ASSETS.STATE.REJECTED' },
        { id: 'inProcess', label: 'In Process', translation: 'PROCESSES.INFO.IN.PROCESS' }
      ],
      stage: [],
      creationUserId: [],
      approvalUser: []
    },
    inventorySessions: {
      locationId: [],
      name: [],
      sessionId: [],
      status: [
        { id: 'open', label: 'Open', translation: 'INVENTORIES.OPEN' },
        { id: 'closed', label: 'Closed', translation: 'INVENTORIES.CLOSED' }
      ]
    },
    logBook: {
      method: [
        { id: 'GET', label: 'GET' },
        { id: 'POST', label: 'POST' },
        { id: 'PUT', label: 'PUT' },
        { id: 'DELETE', label: 'DELETE' }
      ],
      collection: permittedModules || [],
      userId: []
    },
    fieldValuesRepeated: {
      collection: permittedModules || [],
      baseFields: []
    }
  });
  const defaultFilterSelected = {
    customFields: {
      base: [],
      category: [],
      all: [],
      selected: []
    },
    processLive: {
      folios: [],
      processName: [],
      selectedProcessType: [],
      processStatus: [],
      stage: [],
      creationUserId: [],
      approvalUser: [],
      daysDelayed: 0
    },
    inventorySessions: {
      locationId: [],
      name: [],
      sessionId: [],
      status: []
    },
    logBook: {
      method: [],
      collection: [],
      userId: []
    },
    fieldValuesRepeated: {
      collection: [],
      baseFields: []
    }
  };
  const messages = {
    jusOneStage: GetTranslatedValue('REPORTS.MESSAGE.ONE.STAGE'),
    selectTab: GetTranslatedValue('REPORTS.MESSAGE.SELECT.TAB')
  };

  const [filtersSelected, setFiltersSelected] = useState(defaultFilterSelected);
  const [processesCustomFieldsFilters, setProcessesCustomFieldsFilters] = useState({
    enabled: false,
    tab: null
  });

  const permissions = user.profilePermissions.reports['saved'] || [];

  const loadInventoriesData = () => {
    getDB('inventorySessions')
      .then((response) => response.json())
      .then((data) => {
        const [inventoryNames, inventorySessionIds, inventoryLocationIds] = data.response?.reduce(
          ([a, b, c], { locationId, locationName, name, sessionId }) => {
            a.push({ label: name, id: name });
            b.push({ label: sessionId, id: sessionId });
            c.push({ label: locationName, id: locationId });

            return [a, b, c];
          },
          [[], [], []]
        );
        setSpecificFiltersOptions((prev) => ({
          ...prev,
          inventorySessions: {
            ...prev.inventorySessions,
            locationId: uniqBy(inventoryLocationIds, 'id'),
            name: uniqBy(inventoryNames, 'id'),
            sessionId: uniqBy(inventorySessionIds, 'id')
          }
        }));
      })
      .catch((error) => console.log(error));
  };

  const loadAllUsers = () => {
    getDBComplex({
      collection: 'user',
      locationsFilter: parentLocations,
      locationsFilterParam: 'locationsTable.parent'
    })
      .then((response) => response.json())
      .then((data) => {
        const users = data.response.map(({ _id: id, name, lastName, email }) => ({
          id,
          label: `${name} ${lastName} <${email.length ? email : 'No email'}>`
        }));
        setSpecificFiltersOptions((prev) => ({
          ...prev,
          logBook: {
            ...prev.logBook,
            userId: users
          }
        }));
      })
      .catch((error) => console.log(error));
  };

  const loadCustomFields = (selectedReport, customSelected, obj = {}) => {
    if (!customSelected) {
      setFiltersSelected((prev) => ({
        ...prev,
        customFields: {
          base: [],
          category: [],
          all: [],
          selected: []
        }
      }));
    }
    const collection = reportsModules.filter(({ id }) => id === selectedReport)[0];
    if (collection && collection.custom.length) {
      setLoading(true);
      getDBComplex({
        collection: collection?.custom,
        customQuery: JSON.stringify({ customFieldsTab: { $ne: {} } })
      })
        .then((response) => response.json())
        .then((data) => {
          const { response } = data;
          //Get just the CustomFields
          let customFieldNames = {};
          const rowToObjectsCustom = response.map((row) => {
            let filteredCustomFields = {};
            const { customFieldsTab } = row;
            Object.values(customFieldsTab || {}).forEach((tab) => {
              const allCustomFields = [...tab.left, ...tab.right];
              allCustomFields.map((field) => {
                if (field.label) {
                  field.values.fieldName = field.label;
                }

                filteredCustomFields = {
                  ...filteredCustomFields,
                  ...extractCustomField(field)
                };
              });
            });
            customFieldNames = { ...customFieldNames, ...filteredCustomFields };
            return filteredCustomFields;
          });
          const customFieldsSimplified = convertRowsToDataTableObjects(
            normalizeRows(rowToObjectsCustom, customFieldNames)
          );
          setFiltersSelected((prev) => ({
            ...prev,
            customFields: {
              ...prev.customFields,
              all: customFieldsSimplified.headerObject || ['There are no Custom Fields yet'],
              selected: customSelected || []
            }
          }));

          if (!isEmpty(customSelected)) {
            loadReportsData(selectedReport, customSelected, obj);
          }

          setLoading(false);
        })
        .catch((error) => {
          console.log('error>', error);
          setLoading(false);
        });
    }
  };

  const getDateFilters = (backup = {}) => {
    const reversedDate = (date) => {
      return date
        .toString()
        .split('-')
        .reverse()
        .join('-');
    };

    const getInventoriesSpecialDateFilters = (startDate, endDate, dateKey) => {
      return {
        $or: [
          {
            [dateKey]: {
              $gte: `${startDate.replaceAll('-', '/')}`,
              $lte: `${endDate.replaceAll('-', '/')}`
            }
          },
          {
            [dateKey]: {
              $gte: `${reversedDate(startDate).replaceAll('-', '/')}`,
              $lte: `${reversedDate(endDate).replaceAll('-', '/')}`
            }
          }
        ]
      };
    };

    const selectedReport =
      tableConditions?.collection ||
      values.selectedReport ||
      collectionName ||
      backup.selectedReport;
    const dateKey =
      selectedReport === 'logBook'
        ? 'timestamp'
        : selectedReport === 'inventorySessions'
          ? 'creation'
          : 'creationDate';
    const endDate = tableConditions?.endDate || values.endDate || backup.endDate;
    const startDate = tableConditions?.startDate || values.startDate || backup.startDate;
    if (startDate && endDate) {
      let condition = [
        selectedReport !== 'inventorySessions'
          ? {
            [dateKey]: {
              $gte: `${startDate.replaceAll('-', '/')}`,
              $lte: `${endDate.replaceAll('-', '/')}`
            }
          }
          : getInventoriesSpecialDateFilters(startDate, endDate, dateKey)
      ];
      return condition;
    } else if (startDate && !endDate) {
      let condition = [{ [dateKey]: { $gte: `${startDate.replaceAll('-', '/')}` } }];
      return condition;
    } else if (!startDate && endDate) {
      let condition = [{ [dateKey]: { $lte: `${endDate.replaceAll('-', '/')}` } }];
      return condition;
    }

    return null;
  };

  const loadProcessData = () => {
    //GET Folios
    getDBComplex({
      collection: 'processLive',
      fields: [
        { key: 'creationUserFullName', value: 1 },
        { key: 'creationUserId', value: 1 },
        { key: 'requestUser', value: 1 },
        { key: 'creationDate', value: 1 },
        { key: '_id', value: 1 },
        { key: 'folio', value: 1 }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        const users = uniqBy(
          data.response.map(({ creationUserId, creationUserFullName, requestUser: { email } }) => ({
            id: creationUserId,
            label: `${creationUserFullName} <${email.length ? email : 'No email'}>`
          })),
          'id'
        );
        const folios = uniqBy(
          data.response.map(({ _id, folio }) => ({ id: _id, label: folio })),
          'id'
        );
        setSpecificFiltersOptions((prev) => ({
          ...prev,
          processLive: {
            ...prev.processLive,
            creationUserId: users || ['There are no creation users yet'],
            folios: folios || ['There are no ProcessLive yet']
          }
        }));
      })
      .catch((error) => console.log('error>', error));

    getDBComplex({
      collection: 'processStages',
      fields: [
        { key: 'name', value: 1 },
        { key: '_id', value: 1 }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        const stages = data.response.map(({ name, _id }) => ({
          id: _id,
          label: name
        }));
        setSpecificFiltersOptions((prev) => ({
          ...prev,
          processLive: {
            ...prev.processLive,
            stage: stages || ['There are no Stages yet']
          }
        }));
      })
      .catch((error) => console.log('error>', error));

    getDBComplex({
      collection: 'processes',
      fields: [
        { key: 'name', value: 1 },
        { key: '_id', value: 1 }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        const processNames = data.response.map(({ name, _id }) => ({
          id: _id,
          label: name
        }));
        setSpecificFiltersOptions((prev) => ({
          ...prev,
          processLive: {
            ...prev.processLive,
            processName: processNames || ['There are no Processes yet']
          }
        }));
      })
      .catch((error) => console.log('error>', error));

    getDBComplex({
      collection: 'processApprovals',
      fields: [
        { key: 'userId', value: 1 },
        { key: 'creationUserFullName', value: 1 },
        { key: 'email', value: 1 }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        getDB('user')
          .then((response) => response.json())
          .then((userData) => {
            const processApprovals = uniqBy(
              data.response.map(({ userId, creationUserFullName, email }) => ({
                id: userId,
                label: `${userData.response.find(({ _id }) => _id === userId)?.name} ${userData.response.find(({ _id }) => _id === userId)?.lastName
                  } <${email?.length ? email : 'No email'}>`
              })),
              'id'
            ).filter((e) => e.id && e.label);
            setSpecificFiltersOptions((prev) => ({
              ...prev,
              processLive: {
                ...prev.processLive,
                approvalUser: processApprovals || ['There are Approval Users yet']
              }
            }));
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log('error>', error));
  };

  const loadProcessStagesCustomFields = () => {
    return getDBComplex({
      collection: 'processStages',
      condition: [{ customFieldsTab: { $ne: {} } }]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          const customFieldsTabNames = [];
          data.response.forEach(({ customFieldsTab }) => {
            Object.entries(customFieldsTab).forEach(([key, value]) => {
              customFieldsTabNames.push({ label: value.info.name, value: key });
            });
          });
          setAllCustomFieldsTabs(customFieldsTabNames);

          return customFieldsTabNames;
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    if (value) {
      setValues({ ...values, [name]: value });
    } else {
      setValues({ ...values, [name]: '' });
    }

    if (name === 'selectedReport') {
      setTableConditions({});
      setLocationsFilter('');
      setSelectedLocation('');
      setTreeViewProps({ expanded: ['root'] });
      setFiltersSelected(defaultFilterSelected);
      setShowTable(false);
      setId('');
      setAllCustomFieldsTabs([]);
      setProcessesCustomFieldsFilters({
        enabled: false,
        tab: ''
      });

      if (value === 'logBook') {
        loadAllUsers();
      } else if (value === 'processLive') {
        getDB('settingsProcesses')
          .then((response) => response.json())
          .then((data) => {
            setAllAlerts(data.response[0].alerts || []);
          })
          .catch((error) => console.log(error));
        loadProcessData();
        loadProcessStagesCustomFields();
      } else if (value === 'inventorySessions') {
        loadInventoriesData();
      } else {
        loadCustomFields(value);
      }
      setValues((prev) => ({ ...prev, endDate: '', startDate: '' }));
      setDataTable(dataTableDefault);
    }
  };

  const handleChangeReportName = () => {
    setControl(true);
  };

  const expandTreeView = async (locationId) => {
    if (locationId && locationId !== 'root') {
      let path = await getLocationPath(locationId, true);

      if (!isEmpty(path)) {
        path.splice(0, 0, 'root');
        path.splice(path.length - 1, 1);
        setTreeViewProps({ expanded: path });
      }
    }
  };

  useEffect(() => {
    if (id) {
      handleGenerateReport(id);
    }
  }, [id]);

  const handleClickGenerateReport = (e) => {
    const { value } = e.target;
    setTableConditions({});
    setTableControl((prev) => ({ ...prev, page: 0 }));
    setId(value);
  };

  const handleGenerateReport = (id) => {
    savedReports.forEach(
      ({
        _id,
        endDate,
        selectedReport,
        startDate,
        customFields,
        processFilters,
        filters,
        locationsFilter,
        selectedLocation
      }) => {
        if (_id === id) {
          if (!user.profilePermissions['reports']['general'].includes(selectedReport)) {
            dispatch(
              showCustomAlert({
                message: 'You have no access to that report',
                open: true,
                type: 'warning'
              })
            );
            setTab(1);
            setId('');

            return;
          }

          setValues((prev) => ({
            ...prev,
            endDate,
            startDate,
            selectedReport
          }));

          if (selectedReport === 'processLive') {
            setFiltersSelected((prev) => ({
              ...prev,
              processLive: processFilters
            }));
            setProcessesCustomFieldsFilters({
              enabled: processFilters.customFieldFilter?.enabled || false,
              tab: processFilters.customFieldFilter?.tab || null
            });
            loadProcessStagesCustomFields();
          } else if (
            ['logBook', 'fieldValuesRepeated', 'inventorySessions'].includes(selectedReport)
          ) {
            setFiltersSelected((prev) => ({
              ...prev,
              [selectedReport]: filters
            }));
          } else if (['assets', 'user'].includes(selectedReport)) {
            setLocationsFilter(selectedLocation);
            expandTreeView(selectedLocation);
            setSelectedLocation(selectedLocation);
          }
          setCollectionName(selectedReport);
          loadCustomFields(selectedReport, customFields || [], {
            endDate,
            selectedReport,
            startDate,
            locationsFilter
          });
        }
      }
    );
  };

  const handleClickCollectionName = () => {
    if (values.selectedReport === 'fieldValuesRepeated') {
      if (
        Array.isArray(filtersSelected.fieldValuesRepeated.baseFields) ||
        Array.isArray(filtersSelected.fieldValuesRepeated.collection) ||
        !filtersSelected.fieldValuesRepeated.baseFields ||
        !filtersSelected.fieldValuesRepeated.collection
      ) {
        dispatch(
          showCustomAlert({
            message: 'Please fill all the specific filters',
            open: true,
            type: 'warning'
          })
        );

        return;
      }
    }

    if (values.selectedReport === 'processLive' && processesCustomFieldsFilters.enabled) {
      if (filtersSelected.processLive.stage.length !== 1) {
        dispatch(
          showCustomAlert({
            message: messages.jusOneStage,
            open: true,
            type: 'warning'
          })
        );

        return;
      } else if (!processesCustomFieldsFilters.tab) {
        dispatch(
          showCustomAlert({
            message: messages.selectTab,
            open: true,
            type: 'warning'
          })
        );

        return;
      }
    }

    const isInventory = values.selectedReport === 'inventorySessions';

    setTableConditions(null);
    setTableControl((prev) => ({
      ...prev,
      collection: '',
      total: 0,
      page: 0,
      orderBy: isInventory ? 'creation' : 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    }));
    setDataTable((prev) => ({ ...prev, rows: null }));
    setCollectionName(values.selectedReport);
    setId(null);
  };

  const handleSelectAll = (event) => {
    const {
      target: { checked }
    } = event;

    setFiltersSelected((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        selected: checked ? filtersSelected.customFields.all : []
      }
    }));
  };

  const orderByCorrection = {
    processLive: {
      name: 'processData.name',
      type: 'processData.selectedProcessType',
      status: 'processData.processStatus',
      creator: 'creationUserFullName',
      date: 'creationDate',
      alert: 'dueDate',
      stages: 'processData.totalStages'
    }
  };

  const onLocationSelected = (locationId, level, parent, locationName, children) => {
    if (locationId === 'root') {
      setLocationsFilter('');
    } else {
      setLocationsFilter(locationId);
    }

    setSelectedLocation(locationId);
  };

  const handleSave = (reportName) => {
    const { selectedReport, startDate, endDate } = values;
    if (!selectedReport) {
      dispatch(showSelectValuesAlert());
      return;
    }

    const body = { ...values, reportName };

    if (selectedReport === 'processLive') {
      body.processFilters = {
        ...filtersSelected.processLive,
        customFieldFilter: {
          enabled: processesCustomFieldsFilters.enabled,
          tab: processesCustomFieldsFilters.tab
        }
      };
    } else if (
      ['logBook', 'fieldValuesRepeated', 'inventorySessions'].includes(values.selectedReport)
    ) {
      body.filters = filtersSelected[values.selectedReport];
    } else if (['assets', 'user'].includes(values.selectedReport)) {
      body.locationsFilter = !isEmpty(locationsFilter) ? locationsFilter : parentLocations;
      body.selectedLocation = selectedLocation;
    } else {
      body.customFields = filtersSelected.customFields.selected;
    }

    postDB('reports', body)
      .then((response) => response.json())
      .then(() => {
        dispatch(showSavedAlert());
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const reset = () => {
    setValues({ ...values, selectedReport: '', startDate: '', endDate: '' });
    reloadData();
    loadReportsData(collectionName);
  };

  const [allAlerts, setAllAlerts] = useState([]);
  const [tableControl, setTableControl] = useState({
    collection: '',
    total: 0,
    page: 0,
    rowsPerPage: 5,
    orderBy: 'creationDate',
    order: -1,
    search: '',
    searchBy: ''
  });

  const constructCustomFieldsFilterQuery = (tabName, value) => {
    return {
      $or: [
        {
          'processData.stages': {
            $elemMatch: {
              [`customFieldsTab.${tabName}.left`]: { $elemMatch: { 'values.initialValue': value } }
            }
          }
        },
        {
          'processData.stages': {
            $elemMatch: {
              [`customFieldsTab.${tabName}.right`]: { $elemMatch: { 'values.initialValue': value } }
            }
          }
        }
      ]
    };
  };

  const handleCSVDownload = async (isLargeFile = false) => {
    if (!collectionName) {
      dispatch(
        showCustomAlert({
          message:
            'You should generate a report of any collection in order to execute the download',
          open: true,
          type: 'warning'
        })
      );
      return;
    }

    if (!isLargeFile) {
      dispatch(setGeneralLoading({ active: true }));
    }

    const collection = tableConditions.collection;
    const condition = tableConditions.condition;
    const body = {
      condition: condition || [{}],
      customFields: tableConditions.customFields || [],
      selectedReport: collection
    };

    if (collection === 'processLive' && processesCustomFieldsFilters.enabled) {
      body.processesCustomFieldsFilters = {
        stageId: filtersSelected.processLive.stage[0].id,
        tab: processesCustomFieldsFilters.tab
      }
    }

    if (collection === 'fieldValuesRepeated') {
      body.module = tableConditions.sideCollection;
      body.field = tableConditions.baseField;

      if (['assets', 'user'].includes(body.module?.id)) {
        body.creationUserId = user.id;
      }
    } else if (['assets', 'user'].includes(collection)) {
      body.creationUserId = user.id;
      body.treeViewLocation = tableConditions.treeViewLocation;
    }

    let path;

    if (!isLargeFile) {
      path = await createReport(body, isLargeFile);
    } else {
      createReport(body, isLargeFile);
    }

    if (path) {
      console.log({apiHost})
      window.open(`${apiHost}${path}`);
    }
  };

  const createReport = (body, isLargeFile) => {
    if (!isLargeFile) {
      return createReportFile(body)
        .then((response) => response.json())
        .then((data) => {
          if (!data?.response?.path && data?.response?.message !== 'creation') {
            setShowConfirmationModal(true);
          } else if (data?.response?.message === 'creation') {
            dispatch(
              showCustomAlert({
                message: 'You have already a report being generated',
                open: true,
                type: 'warning'
              })
            );
          }

          return data.response?.path;
        })
        .catch((error) => {
          dispatch(
            showCustomAlert({
              message: 'Something wrong happened with the download. Please try again later.',
              open: true,
              type: 'error'
            })
          );
        })
        .finally(() => dispatch(setGeneralLoading({ active: false })));
    } else {
      createReportFile(body, isLargeFile);
      dispatch(
        showCustomAlert({
          message: 'Large report generation started',
          open: true,
          type: 'success'
        })
      );
    }
  };

  const manageReportConfirmation = () => {
    return {
      cancel: () => {
        setShowConfirmationModal(false);
      },
      ok: () => {
        setShowConfirmationModal(false);
        handleCSVDownload(true);
      }
    };
  };

  const getFiltersProcess = async (backup = {}) => {
    const result = [];
    const lookById = [];
    const keys = Object.keys(filtersSelected.processLive);
    await Promise.all(
      keys.map(async (key) => {
        if (isEmpty(filtersSelected.processLive[key])) {
          return;
        }

        if (['processName', 'selectedProcessType', 'processStatus'].includes(key)) {
          const extendedKey = 'processData'.concat('.', key !== 'processName' ? key : 'id');
          result.push({
            [extendedKey]: {
              $in: filtersSelected.processLive[key].map(({ id }) => id)
            }
          });
        } else if (key === 'folios') {
          filtersSelected.processLive[key].map(({ id }) => {
            lookById.push(id);
          });
        } else if (key === 'stage' || key === 'approvalUser') {
          const stages = {
            condition: [
              {
                processStages: {
                  $elemMatch: {
                    id: {
                      $in: filtersSelected.processLive[key].map(({ id }) => id)
                    }
                  }
                }
              }
            ],
            fields: [{ key: '_id', value: 1 }]
          };

          const approval = {
            condition: [
              {
                userId: {
                  $in: filtersSelected.processLive[key].map(({ id }) => id)
                }
              }
            ],
            fields: [{ key: 'processId', value: 1 }]
          };
          const data = await getDBComplex({
            collection: key === 'stage' ? 'processes' : 'processApprovals',
            condition: key === 'stage' ? stages.condition : approval.condition,
            fields: key === 'stage' ? stages.fields : approval.fields
          })
            .then((response) => response.json())
            .then((data) => {
              return data.response;
            })
            .catch((error) => console.log('error>', error));
          if (key === 'stage') {
            result.push({
              selectedProcess: { $in: data.map(({ _id }) => _id) }
            });
          } else {
            data.map(({ processId }) => {
              lookById.push(processId);
            });
          }
        } else if (key === 'daysDelayed') {
          const date = new Date();
          date.setDate(date.getDate() - parseInt(filtersSelected.processLive[key].toString()));

          result.push({
            dueDate: { $gte: moment(date).format('YYYY-MM-DD') }
          });
        } else {
          if (key !== 'customFieldFilter') {
            result.push({
              [key]: { $in: filtersSelected.processLive[key].map(({ id }) => id) }
            });
          }
        }
      })
    );

    const dateFilters = getDateFilters(backup);

    if (dateFilters) {
      result.push(...dateFilters);
    }

    if (lookById.length) {
      result.push({ processLiveId: { $in: lookById } });
    }

    return result.length > 0 ? result : null;
  };

  const getSpecificConditions = ({ backup = {}, collectionName }) => {
    const condition = [];
    Object.entries(filtersSelected[collectionName]).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length) {
          condition.push({ [key]: { $in: value.map(({ id }) => id) } });
        }
      } else if (typeof value === 'object' && value) {
        condition.push({ [key]: value.id });
      } else if (!isEmpty(value)) {
        condition.push({ [key]: value });
      }
    });

    const dateFilters = getDateFilters(backup);

    if (dateFilters) {
      condition.push(...dateFilters);
    }

    return !isEmpty(condition) ? condition : null;
  };

  const getFiltersLocations = (collectionName, sideCollection) => {
    if (
      ['assets', 'user'].includes(collectionName) ||
      (collectionName === 'fieldValuesRepeated' &&
        ['assets', 'user'].includes(
          sideCollection || filtersSelected.fieldValuesRepeated.collection.id
        ))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getFiltersLocationsParam = (collectionName, sideCollection) => {
    if (
      collectionName === 'assets' ||
      (collectionName === 'fieldValuesRepeated' &&
        (sideCollection || filtersSelected.fieldValuesRepeated.collection.id) === 'assets')
    ) {
      return 'location';
    } else if (
      collectionName === 'user' ||
      (collectionName === 'fieldValuesRepeated' &&
        (sideCollection || filtersSelected.fieldValuesRepeated.collection.id) === 'user')
    ) {
      return 'locationsTable.parent';
    } else {
      return null;
    }
  };

  const getLocationsRealFilters = async () => {
    const thisUser = await getOneDB('user/', user.id)
      .then((response) => response.json())
      .then(({ response }) => response);
    const locationsArray = thisUser.locationsTable.map(({ parent }) => parent);
    const condition = [{ _id: { $in: locationsArray } }];
    //? TODO: This will return the condition once the backend can process ids as "ObjectID"
  };

  const getRepeatedValuesCount = ({ condition, queryLike, repeatedValues }) => {
    const collection = filtersSelected.fieldValuesRepeated.collection;

    return getCountDB({
      collection: `${(tableConditions?.sideCollection || collection)?.id}/`,
      condition,
      locationsFilter: getFiltersLocations(tableConditions?.sideCollection?.id || collection?.id)
        ? parentLocations
        : null,
      locationsFilterParam: getFiltersLocationsParam(collection?.id) || null,
      queryLike: tableConditions?.queryLike || queryLike,
      repeatedValues: tableConditions?.baseField?.id || repeatedValues?.id,
      treeViewLocation: tableConditions?.locationsFilter || locationsFilter
    })
      .then((response) => response.json())
      .then((data) => data?.response?.count)
      .catch((error) => dispatch(showErrorAlert()));
  };

  const getInventorySessionsCount = ({ condition, queryLike }) => {
    return getCountDB({
      collection: 'inventorySessions',
      condition,
      isInventorySessions: true,
      queryLike
    })
      .then((response) => response.json())
      .then((data) => data?.response?.message)
      .catch((error) => dispatch(showErrorAlert()));
  };

  const getOptionLabelTranslated = (key, defaultMessage) => {
    const label = GetTranslatedValue(key, defaultMessage);

    return label;
  };

  const loadReportsData = async (collectionNames, customSelected, obj = {}) => {
    if (!collectionNames) {
      return;
    }

    setShowTable(true);
    const collection = reportsModules.find(({ id }) => id === collectionNames);
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach(async (collectionName) => {
      let queryLike = '';

      if (collectionName) {
        queryLike = tableControl.searchBy
          ? [{ key: tableControl.searchBy, value: tableControl.search }]
          : ['name', 'lastname', 'email', 'model', 'price', 'brand', 'level'].map((key) => ({
            key,
            value: tableControl.search
          }));
      }

      const condition = tableConditions?.hasOwnProperty('condition')
        ? tableConditions.condition
        : collectionName === 'processLive'
          ? await getFiltersProcess(obj)
          : ['logBook', 'inventorySessions'].includes(collectionName)
            ? getSpecificConditions({ backup: obj, collectionName })
            : collectionName === 'locationsReal'
              ? await getLocationsRealFilters()
              : getDateFilters(obj);
      let fieldValuesRepeatedCount;

      if (collectionName !== 'fieldValuesRepeated') {
        setTableConditions({
          collection: collectionName,
          condition,
          customFields: filtersSelected?.customFields?.selected,
          locationsFilter: ['assets', 'user'].includes(collectionName) ? parentLocations : null,
          locationsFilterParam:
            collectionName === 'assets'
              ? 'location'
              : collectionName === 'user'
                ? 'locationsTable.parent'
                : null,
          treeViewLocation: locationsFilter
        });

        if (collectionName !== 'inventorySessions') {
          getCountDB({
            collection: collectionName,
            condition,
            queryLike: tableControl.search ? queryLike : null,
            locationsFilter: ['assets', 'user'].includes(collectionName) ? parentLocations : null,
            locationsFilterParam:
              collectionName === 'assets'
                ? 'location'
                : collectionName === 'user'
                  ? 'locationsTable.parent'
                  : null,
            treeViewLocation: locationsFilter
          })
            .then((response) => response.json())
            .then((data) => {
              setTableControl((prev) => ({
                ...prev,
                total: data.response.count
              }));
            });
        } else {
          const inventorySessionsCount = await getInventorySessionsCount({
            condition,
            queryLike: tableControl.search ? queryLike : null
          });
          setTableControl((prev) => ({
            ...prev,
            total: inventorySessionsCount
          }));
        }
      } else {
        fieldValuesRepeatedCount = await getRepeatedValuesCount({
          condition,
          queryLike: tableControl.search ? queryLike : null,
          repeatedValues: filtersSelected.fieldValuesRepeated.baseFields
        });

        setTableConditions({
          baseField: tableConditions?.baseField || filtersSelected.fieldValuesRepeated.baseFields,
          collection: 'fieldValuesRepeated',
          sideCollection:
            tableConditions?.sideCollection || filtersSelected.fieldValuesRepeated.collection,
          condition,
          locationsFilter: getFiltersLocations(
            tableConditions?.collection || collectionName,
            tableConditions?.sideCollection
          )
            ? parentLocations
            : null,
          locationsFilterParam:
            getFiltersLocationsParam(
              tableConditions?.collection || collectionName,
              tableConditions?.sideCollection
            ) || null,
          treeViewLocation: locationsFilter,
          queryLike: tableControl.search ? queryLike : null
        });
      }

      getDBComplex({
        collection:
          collectionName === 'fieldValuesRepeated'
            ? tableConditions?.sideCollection?.id ||
            filtersSelected.fieldValuesRepeated.collection.id
            : collectionName,
        condition,
        isInventorySessions: collectionName === 'inventorySessions',
        limit: tableControl.rowsPerPage,
        queryLike: tableControl.search ? queryLike : null,
        repeatedValues:
          collectionName === 'fieldValuesRepeated'
            ? tableConditions?.baseField?.id || filtersSelected.fieldValuesRepeated.baseFields.id
            : null,
        skip: tableControl.rowsPerPage * tableControl.page,
        sort:
          collectionName === 'processLive' && !tableControl.order
            ? [{ key: 'folio', value: 1 }]
            : [{ key: tableControl.orderBy, value: tableControl.order }],
        locationsFilter:
          tableConditions?.locationsFilter || getFiltersLocations(collectionName)
            ? parentLocations
            : null,
        locationsFilterParam:
          tableConditions?.locationsFilterParam || getFiltersLocationsParam(collectionName) || null,
        treeViewLocation: locationsFilter
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (collectionName === 'fieldValuesRepeated') {
            setTableControl((prev) => ({
              ...prev,
              total: fieldValuesRepeatedCount
            }));
          }

          let { response } = data;

          const dataCondition =
            collectionName === 'fieldValuesRepeated'
              ? tableConditions?.sideCollection?.id ||
              filtersSelected.fieldValuesRepeated.collection.id
              : collection.id;
          const baseHeaders = getGeneralFieldsHeaders(dataCondition);
          if (collectionName === 'processLive') {
            const headerIndexToChange = baseHeaders.findIndex(({ id }) => id === 'dueDate');
            baseHeaders[headerIndexToChange] = {
              ...baseHeaders[headerIndexToChange],
              renderCell: (value) => {
                const biggerThan = allAlerts
                  .filter((element) => value * -1 >= Number(element.days))
                  .map(({ days }) => days);
                const alertToApply = Math.max(...biggerThan);
                const alert = allAlerts.find(({ days }) => days === alertToApply.toString());
                return (
                  <div
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      padding: '16px',
                      borderBottom: '1px solid rgba(224, 224, 224, 1)'
                    }}
                  >
                    {typeof value === 'number' && alert && (
                      <Chip
                        icon={value ? <Notification /> : null}
                        label={value ? `${value * -1} days` : 'No DueDate'}
                        style={{
                          backgroundColor: alert?.color || '#B1B1B1',
                          height: '28px'
                        }}
                        color="secondary"
                      />
                    )}
                  </div>
                );
              }
            };
          }
          const selected = customSelected || filtersSelected.customFields.selected;
          const headers = [...baseHeaders, ...selected].map(({ label }) => label);
          const dataTable = formatData(
            dataCondition,
            response,
            processesCustomFieldsFilters.enabled ? { stageId: filtersSelected.processLive.stage[0].id, tab: processesCustomFieldsFilters.tab.value } : null
          );

          const baseFieldsHeaders = dataTable.headerObject.filter(
            (e) => !filtersSelected.customFields.all.some((custom) => custom.id === e.id)
          );
          setFiltersSelected((prev) => ({
            ...prev,
            customFields: {
              ...prev.customFields,

              base: baseFieldsHeaders
            }
          }));

          let headerObject = baseHeaders.concat(selected) || [];

          if (collectionName === 'processLive' && processesCustomFieldsFilters.enabled) {
            difference(Object.values(dataTable.headerObject).map(({ id }) => id), baseHeaders.map(({ id }) => id)).forEach((e) => {
              headerObject.push({ id: e, label: e });
            });
          }

          setDataTable({
            ...dataTable,
            headerObject,
            title: { label: collection.name, translation: collection.label }
          });
        })
        .catch((error) => console.log('error>', error));
    });
  };

  const changeFiltersSelected = (module, filter) => (event, values) => {
    const { id } = values || {};

    if (filter === 'collection' && module === 'fieldValuesRepeated' && id) {
      const baseFieldId = baseFieldsPerModule[id];
      const baseFields = [];

      const pushItem = (id, key, value) => {
        const fields = _generalFields[id].map(({ id }) => id);
        const condition =
          key !== 'id' && fields.includes(value.validationId) && value.component === 'textField';

        if (condition) {
          baseFields.push({
            id: value.validationId,
            label: value.compLabel,
            translation: value.translation
          });
        }
      };

      if (Array.isArray(baseFieldId)) {
        baseFieldId.forEach((key) => {
          Object.entries(allBaseFields[key] || []).forEach(([key, value]) => {
            pushItem(id, key, value);
          });
        });
      } else if (baseFieldId) {
        Object.entries(allBaseFields[baseFieldId] || []).forEach(([key, value]) => {
          pushItem(id, key, value);
        });
      }

      setSpecificFiltersOptions((prev) => ({
        ...prev,
        [module]: {
          ...prev[module],
          baseFields
        }
      }));
      setFiltersSelected((prev) => ({
        ...prev,
        [module]: {
          ...prev[module],
          baseFields: []
        }
      }));
    } else if (filter === 'collection' && module === 'fieldValuesRepeated' && !id) {
      setSpecificFiltersOptions((prev) => ({
        ...prev,
        [module]: {
          ...prev[module],
          baseFields: []
        }
      }));
      setFiltersSelected((prev) => ({
        ...prev,
        [module]: {
          ...prev[module],
          baseFields: []
        }
      }));
    }

    setFiltersSelected((prev) => ({
      ...prev,
      [module]: {
        ...prev[module],
        [filter]: values
      }
    }));
  };

  const changeCustomFieldsSelected = (event, values) => {
    setFiltersSelected((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        selected: values
      }
    }));
  };

  useEffect(() => {
    if (!values.selectedReport) {
      setDataTable(dataTableDefault);
      return;
    }
    const collection = reportsModules.find(({ id }) => id === collectionName);
    if (!collection) {
      setDataTable(dataTableDefault);
      return;
    }
  }, [collectionName]);

  useEffect(() => {
    loadReportsData(collectionName);
  }, [
    tableControl.page,
    tableControl.rowsPerPage,
    tableControl.order,
    tableControl.orderBy,
    tableControl.search,
    tableControl.locationsFilter
  ]);

  useEffect(() => {
    if (!tableConditions) {
      loadReportsData(values.selectedReport);
    }
  }, [tableConditions]);

  return (
    <>
      <DataExtractionConfitmationModal
        onCancel={manageReportConfirmation().cancel}
        onOK={manageReportConfirmation().ok}
        showModal={showConfirmationModal}
      />
      <div>
        <SaveReportModal
          saveData={handleSave}
          setShowModal={setControl}
          setReportNameInValues={(reportName) => {
            setValues({ ...values, reportName });
          }}
          showModal={control}
        />
        <div className="__title">
          <h2>{GetTranslatedValue('REPORTS.TITLES.GENERATE')}</h2>
          <div className="__title__buttons">
            {loading && (
              <div
                style={{
                  width: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  margin: '10px'
                }}
              >
                <CircularProgressCustom size={25} />
              </div>
            )}
            <Button
              className={classes.button}
              color="primary"
              onClick={handleClickCollectionName}
              size="large"
              variant="contained"
            >
              {GetTranslatedValue('REPORTS.TITLES.GENERATE')}
            </Button>
            {permissions.includes('add') && (
              <Button
                className={classes.button}
                color="primary"
                onClick={handleChangeReportName}
                size="large"
                variant="contained"
              >
                {GetTranslatedValue('GENERAL.CAPTION.SAVE')}
              </Button>
            )}
          </div>
        </div>
        <div
          name="Head Part"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        >
          <FormControl className={classes.textField}>
            <InputLabel htmlFor="age-simple">
              {GetTranslatedValue('REPORTS.FILTER.SELECT')}
            </InputLabel>
            <Select onChange={handleChange('selectedReport')} value={values.selectedReport}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {reportsModules
                .filter(({ id }) => user.profilePermissions['reports']['general'].includes(id))
                .map(({ id, name, label }, ix) => (
                  <MenuItem key={`opt-${ix}`} value={id}>
                    {GetTranslatedValue(label, name)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.textField}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              label={GetTranslatedValue('REPORTS.FILTER.STARTDATE')}
              onChange={handleChange('startDate')}
              type="date"
              value={values.startDate}
            />
          </FormControl>
          <FormControl className={classes.textField}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              label={GetTranslatedValue('REPORTS.FILTER.ENDDATE')}
              onChange={handleChange('endDate')}
              type="date"
              value={values.endDate}
            />
          </FormControl>
          <FormControl className={classes.textField}>
            <InputLabel htmlFor="age-simple">
              {GetTranslatedValue('REPORTS.FILTER.SAVED')}
            </InputLabel>
            <Select
              inputProps={{
                name: 'age',
                id: 'age-simple'
              }}
              onChange={handleClickGenerateReport}
              value={id || ''}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {savedReports.map(({ _id, reportName }, ix) => (
                <MenuItem key={`opt-${ix}`} value={_id}>
                  {reportName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(filtersSelected.customFields.all.length > 0 ||
            filtersSelected.customFields.selected.length > 0) && (
              <div className="__custom-fields">
                <Autocomplete
                  defaultValue={filtersSelected.customFields.selected}
                  id="CustomFields"
                  getOptionLabel={(option) => option.label}
                  multiple
                  onChange={changeCustomFieldsSelected}
                  options={filtersSelected.customFields.all}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.customFieldsTextField}
                      label={`${GetTranslatedValue('TABS.TITLES.FIELDS')} ${GetTranslatedValue(
                        'TABS.TITLES.CUSTOM'
                      )}`}
                      variant="standard"
                    />
                  )}
                  value={filtersSelected.customFields.selected}
                />
                <FormControlLabel
                  control={
                    <Checkbox color="primary" defaultChecked={false} onChange={handleSelectAll} />
                  }
                  label={`${GetTranslatedValue('RECORD.BUTTON.SELECT')} ${GetTranslatedValue(
                    'RECORD.BUTTON.ALL'
                  )}`}
                  labelPlacement="start"
                  style={{ alignSelf: 'flex-start', margin: '10px 0px 0px 0px' }}
                  value="start"
                />
              </div>
            )}
          {['user', 'assets'].includes(values.selectedReport) && (
            <div className="__custom-fields __locations-filter">
              <h4>{GetTranslatedValue('REPORTS.FILTER.LOCATION')}</h4>
              {filteredLocationsProps?.loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <CircularProgressCustom size={35} />
                </div>
              ) : (
                <TreeViewComponent
                  customProps={treeViewProps}
                  data={filteredLocationsProps?.data}
                  onClick={onLocationSelected}
                  onNodeToggle={(event, nodes) => setTreeViewProps({ expanded: nodes })}
                  selected={selectedLocation}
                />
              )}
            </div>
          )}
        </div>
        {Object.keys(specificFilters).includes(values.selectedReport) && (
          <>
            <div
              name="SpecificFilter"
              style={{
                margin: '30px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
              }}
            >
              <Typography className={classes.filterTitles} style={{ marginTop: '0px' }}>
                {GetTranslatedValue('REPORTS.SPECIFIC.FILTERS')}
              </Typography>
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {specificFilters[values.selectedReport].map((e, ix) => (
                  <Autocomplete
                    getOptionLabel={(option) => option.translation || option.label}
                    renderOption={(option) => (
                      <Typography>
                        {getOptionLabelTranslated(
                          option.translation || 'INCORRECT.KEY',
                          option.label
                        )}
                      </Typography>
                    )}
                    key={`autoComplete-key-${ix}`}
                    multiple={!e.single}
                    onChange={changeFiltersSelected(values.selectedReport, e.id)}
                    options={specificFiltersOptions[values.selectedReport][e.id]}
                    renderInput={(params) => {
                      const { inputProps } = params;

                      const { value } = inputProps;

                      inputProps.value = isEmpty(value) ? value : GetTranslatedValue(value, value);

                      return (
                        <TextField
                          {...params}
                          label={GetTranslatedValue(e.translation || 'INCORRECT.KEY', e.label)}
                          variant="standard"
                          style={{ width: '200px', margin: '10px 40px 10px 0px' }}
                        />
                      );
                    }}
                    value={filtersSelected[values.selectedReport][e.id]}
                  />
                ))}
                {values.selectedReport === 'processLive' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px'
                    }}
                  >
                    <Typography style={{ color: 'black' }}> {'Delayed >='} </Typography>
                    <TextField
                      variant="outlined"
                      style={{
                        width: '60px',
                        marginLeft: '8px',
                        marginRight: '5px'
                      }}
                      type="number"
                      inputProps={{
                        min: 0,
                        style: {
                          padding: '8px'
                        }
                      }}
                      onChange={(event) => {
                        setFiltersSelected((prev) => ({
                          ...prev,
                          processLive: {
                            ...prev.processLive,
                            daysDelayed: event?.target?.value
                          }
                        }));
                      }}
                      value={filtersSelected[values.selectedReport].daysDelayed}
                    />
                    <Typography style={{ color: 'black' }}> Days </Typography>
                  </div>
                )}
              </div>
              {values.selectedReport === 'processLive' && (
                <>
                  <Typography className={classes.filterTitles} style={{ margin: '20px 0px' }}>
                    {GetTranslatedValue('REPORTS.CUSTOM.FIELDS.FILTERS')}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      width: '100%'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={processesCustomFieldsFilters.enabled}
                          onChange={(event) => {
                            const value = event.target.checked;
                            setProcessesCustomFieldsFilters((prev) => ({
                              ...prev,
                              enabled: value
                            }));
                          }}
                          color="secondary"
                        />
                      }
                      label={GetTranslatedValue('REPORTS.ENABLE.FILTERS')}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%'
                      }}
                    >
                      <Autocomplete
                        disabled={!processesCustomFieldsFilters.enabled}
                        key="autoComplete-custom-fields-filters"
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setProcessesCustomFieldsFilters((prev) => ({
                            ...prev,
                            tab: newValue
                          }));
                        }}
                        options={allCustomFieldsTabs}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={GetTranslatedValue('GENERAL.TAB')}
                            variant="standard"
                            style={{ width: '200px', margin: '10px 40px 10px 0px' }}
                          />
                        )}
                        value={processesCustomFieldsFilters.tab}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <div>
          <Divider style={{ width: '100%', marginTop: '30px' }} />
          <h3 style={{ marginTop: '20px' }}>
            {' '}
            {GetTranslatedValue('PROCESSES.LIVE.MODAL.TAB.TABLE')}{' '}
          </h3>
          {!showTable ? (
            <Typography
              style={{
                fontSize: '1.5rem',
                margin: '20px 0px',
                textAlign: 'center'
              }}
            >
              {GetTranslatedValue('REPORTS.TABLE.LEGEND')}
            </Typography>
          ) : (
            <TableReportsGeneral
              controlValues={tableControl}
              handleCSVDownload={handleCSVDownload}
              headRows={dataTable.headerObject}
              onDelete={() => {}}
              onEdit={() => {}}
              onView={() => {}}
              onSelect={() => {}}
              paginationControl={({ rowsPerPage, page }) =>
                setTableControl((prev) => ({
                  ...prev,
                  rowsPerPage,
                  page
                }))
              }
              rows={dataTable.rows}
              searchControl={({ value, field }) => {
                if (tableControl.search === value && !field) {
                  loadReportsData(values.selectedReport);
                } else {
                  setTableControl((prev) => ({
                    ...prev,
                    search: value,
                    searchBy: field
                  }));
                }
              }}
              sortByControl={({ orderBy, order }) => {
                setTableControl((prev) => ({
                  ...prev,
                  orderBy:
                    orderByCorrection[collectionName] && orderByCorrection[collectionName][orderBy]
                      ? orderByCorrection[collectionName][orderBy]
                      : orderBy,
                  order: order
                }));
              }}
              disableSearchBy
              disableActions
              title={dataTable.title}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(TabGeneral);
