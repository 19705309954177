import React from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SnapshotToggle from './SnapshotToggle';
import { updateDB } from '../../../../crud/api';
import { actions } from '../../../../store/ducks/general.duck';
import { GetTranslatedValue } from '../../utils';

const SnapshotDropdown = ({ trash, loadMessages, id }) => {
  const dispatch = useDispatch()
  const { showCustomAlert } = actions;
  const handleStatus = (newStatus) => {
    const body = { "status": newStatus };
    const message = `moved to ${newStatus === 'new' ? 'inbox' : 'trash'}`
    updateDB('messages/', body, id)
      .then(_ => {
        loadMessages()
        dispatch(showCustomAlert({
          message: `Message sucsessfully ${trash && newStatus === 'deleted' ? newStatus : message}`,
          open: true,
          type: 'success'
        }));
      })
      .catch(error => console.log('Error', error));
  };

  return (
    <div style={{ position: 'absolute', right: '20px' }}>
      <Dropdown as={ButtonGroup} drop="left">
        <Dropdown.Toggle as={SnapshotToggle}>
          <IconButton>
            <ExpandMoreIcon fontSize="small" />
          </IconButton>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => (
            trash ? handleStatus("deleted") : handleStatus("trash")
          )}>
            <Grid
              style={{ flex: 1 }}
              container
              item
              direction="row"
              alignItems="center"
            >
              <DeleteIcon style={{ marginRight: '10px' }} color="black" />
              <Typography>
                {GetTranslatedValue(trash ? "MESSAGES.TRASH.DELETE" : "MESSAGES.INBOX.DELETE")}
              </Typography>
            </Grid>
          </Dropdown.Item>
          {
            trash && (
              <Dropdown.Item onClick={() => handleStatus("new")}>
                <Grid
                  style={{ flex: 1 }}
                  container
                  item
                  direction="row"
                  alignItems="center"
                >
                  <KeyboardBackspaceIcon style={{ marginRight: '10px' }} color="black" />
                  <Typography>
                    {GetTranslatedValue("MESSAGES.TRASH.BACKTOINBOX")}
                </Typography>
                </Grid>
              </Dropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

SnapshotDropdown.propTypes = {
  trash: PropTypes.bool.isRequired,
  loadMessages: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default SnapshotDropdown;
