/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select';
import { differenceInDays, sub } from 'date-fns';
import { isEmpty } from 'lodash';
import { Chip, makeStyles, Tabs, Typography } from '@material-ui/core';
import Notification from '@material-ui/icons/NotificationImportant';
import { GetTranslatedValue } from '../../utils';
import {
  getDB,
  getDBComplex,
  getCountDB,
  getProcessApprovals,
  deleteDB
} from '../../../../crud/api';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../partials/content/Portlet';
import { loadGroups, updateTableGroupingTree } from '../../Components/tableGroupingHelpers';
import { GenerateSubTabs } from '../../Components/Translations/tabsTitles';
import TableComponent2 from '../../Components/TableComponent2';
import ModalProcessesLive from '../modals/ModalProcessLive';
import UsersPerStageCell from '../components/UsersPerStageCell';
import { useIsMount } from '../../utils';

const createLiveProcessesHeadRows = (
  id,
  folio,
  name,
  type,
  notifications,
  approvals,
  status,
  alert,
  creator,
  creationDate,
  updateDate
) => {
  return {
    id,
    folio,
    name,
    type,
    notifications,
    approvals,
    status,
    alert,
    creator,
    creationDate,
    updateDate
  };
};

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: '200px',
    margin: '10px'
  },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    padding: '20px'
  },
  '@media (max-width: 800px)': {
    selectWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
      flexDirection: 'column'
    },
    select: {
      minWidth: '100px',
      margin: '10px'
    }
  }
}));

const orderByCorrection = {
  name: 'processData.name',
  type: 'processData.selectedProcessType',
  status: 'processData.processStatus',
  creator: 'creationUserFullName',
  alert: 'dueDate'
};

const collections = {
  layoutsEmployees: {
    id: 'idLayoutEmployee',
    modal: 'openLayoutEmployeesModal',
    name: 'settingsLayoutsEmployees'
  },
  layoutsStages: {
    id: 'idLayoutStage',
    modal: 'openLayoutStagesModal',
    name: 'settingsLayoutsStages'
  },
  processLive: {
    id: 'idProcessLive',
    modal: 'openProcessLiveModal',
    name: 'processLive'
  }
};

const overdueFilter = [
  { value: { days: 8 }, label: '1 week' },
  { value: { months: 1, days: 1 }, label: '1 month' },
  { value: { months: 6, days: 1 }, label: '6 months' },
  { value: { months: 6, days: 1 }, label: 'more' }
];

const processTypes = [
  { value: 'creation', label: 'Creation' },
  { value: 'movement', label: 'Movement' },
  { value: 'short', label: 'Short Movement' },
  { value: 'decommission', label: 'Decommission' },
  { value: 'maintenance', label: 'Maintenance' }
];

const LiveProcesses = ({ user, allAlerts }) => {
  const isMount = useIsMount();
  const classes = useStyles();
  const loggedUserId = user.id;
  const [control, setControl] = useState({
    idLayoutEmployee: null,
    openLayoutEmployeesModal: false,
    layoutEmployeesRows: [],
    layoutEmployeesRowsSelected: [],
    //
    idLayoutStage: null,
    openLayoutStagesModal: false,
    layoutStagesRows: [],
    layoutStagesRowsSelected: [],
    //
    idProcessLive: null,
    openProcessLiveModal: false,
    processLiveRows: null,
    ProcessLiveRowsSelected: [],
    //
    processLiveApprovalsFulfilledRows: null,
    ProcessLiveApprovalsFulfilledRowsSelected: [],
    //
    processLiveApprovalsNotFulfilledRows: null,
    ProcessLiveApprovalsNotFulfilledRowsSelected: []
  });

  const { processAlerts } = useSelector(({ general: { processAlerts } }) => ({
    processAlerts
  }));

  const [complementaryValues, setComplementaryValues] = useState({});
  const [processFilters, setProcessFilters] = useState({
    processType: null,
    stage: null,
    overdue: null
  });

  const overdueFilter = [
    {
      value: { days: 8 },
      label: GetTranslatedValue('PROCESSES.CAPTION.OVERDUE.FILTER.ONE', '1 week')
    },
    {
      value: { months: 1, days: 1 },
      label: GetTranslatedValue('PROCESSES.CAPTION.OVERDUE.FILTER.TWO', '1 month')
    },
    {
      value: { months: 6, days: 1 },
      label: GetTranslatedValue('PROCESSES.CAPTION.OVERDUE.FILTER.THREE', '6 Months')
    },
    {
      value: { months: 6, days: 1 },
      label: GetTranslatedValue('PROCESSES.CAPTION.OVERDUE.FILTER.FOUR', 'More')
    }
  ];

  const creationTypeLabel = GetTranslatedValue('PROCESSES.TYPE.CREATION', 'Creation');
  const movementTypeLabel = GetTranslatedValue('PROCESSES.TYPE.MOVEMENT', 'Movement');
  const shortMovementTypeLabel = GetTranslatedValue(
    'PROCESSES.TYPE.SHORT.MOVEMENT',
    'Short Movement'
  );
  const decommisionLabel = GetTranslatedValue('PROCESSES.TYPE.DECOMISSION', 'Decommission');
  const maintenanceLabel = GetTranslatedValue('PROCESSES.TYPE.MAINTENANCE', 'Maintenance');

  const approvedStatus = GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.APPROVED', 'Approved');
  const rejectedStatus = GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.REJECTED', 'Rejected');
  const partiallyStatus = GetTranslatedValue(
    'PROCESSES.INFO.PARTIALLY.APPROVED',
    'Partially Approved'
  );

  const inProcessStatus = GetTranslatedValue('PROCESSES.INFO.IN.PROCESS', 'In Process');

  const daysLabel = GetTranslatedValue('GENERAL.CAPTION.DAYS', 'Days');

  const skippedLabel = GetTranslatedValue('PROCESSES.INFO.SKIPPED', 'Skipped');
  const pendingLabel = GetTranslatedValue('PROCESSES.INFO.PENDING', 'Pending');
  const fulfilledLabel = GetTranslatedValue('PROCESSES.INFO.FULFILLED', 'Fulfilled');
  const sentLabel = GetTranslatedValue('MESSAGES.TRASH.SENT', 'Sent');

  const processTypes = [
    { value: 'creation', label: creationTypeLabel },
    { value: 'movement', label: movementTypeLabel },
    { value: 'short', label: shortMovementTypeLabel },
    { value: 'decommission', label: decommisionLabel },
    { value: 'maintenance', label: maintenanceLabel }
  ];

  useEffect(() => {
    const headRows = () => [
      {
        id: 'folio',
        numeric: false,
        disablePadding: false,
        label: labels.folio,
        disableTableGrouping: true
      },
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: labels.name,
        searchBy: 'processData.name',
        correction: 'processData.name'
      },
      {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: labels.type,
        searchBy: 'processData.selectedProcessType',
        correction: 'processData.selectedProcessType'
      },
      {
        id: 'notifications',
        numeric: false,
        disablePadding: false,
        label: labels.notifications,
        searchByDisabled: true,
        sortByDisabled: true,
        disableTableGrouping: true,
        renderCell: (value) => {
          const users = [].concat(...value.map(({ users }) => users)).length;
          return <UsersPerStageCell number={users} values={value} />;
        }
      },
      {
        id: 'approvals',
        numeric: false,
        disablePadding: false,
        label: labels.approvals,
        searchByDisabled: true,
        sortByDisabled: true,
        disableTableGrouping: true,
        renderCell: (value) => {
          const users = [].concat(...value.map(({ users }) => users)).length;
          return <UsersPerStageCell number={users} values={value} />;
        }
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: labels.status,
        searchBy: 'processData.processStatus',
        correction: 'processData.processStatus'
      },
      {
        id: 'alert',
        numeric: false,
        disablePadding: false,
        label: labels.alert,
        searchByDisabled: true,
        searchBy: 'dueDate',
        correction: 'dueDate',
        disableTableGrouping: true,
        renderCell: (value) => {
          const biggerThan = processAlerts
            .filter((element) => value * -1 >= Number(element.days))
            .map(({ days }) => days);
          const alertToApply = Math.max(...biggerThan);
          const alert = processAlerts.find(({ days }) => days === alertToApply.toString());
          return (
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
                padding: '16px',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
              }}
            >
              {typeof value === 'number' && alert && (
                <Chip
                  icon={value ? <Notification /> : null}
                  label={value ? `${value * -1} ${daysLabel}` : 'No DueDate'}
                  style={{ backgroundColor: alert?.color || '#B1B1B1', height: '28px' }}
                  color="secondary"
                />
              )}
            </div>
          );
        }
      },
      {
        id: 'creator',
        numeric: false,
        disablePadding: false,
        label: labels.creator,
        searchBy: 'creationUserFullName',
        correction: 'creationUserFullName'
      },
      {
        id: 'creationDate',
        numeric: false,
        disablePadding: false,
        label: labels.creationDate,
        searchByDisabled: true,
        disableTableGrouping: true
      },
      {
        id: 'updateDate',
        numeric: false,
        disablePadding: false,
        label: labels.updateDate,
        searchByDisabled: true,
        disableTableGrouping: true
      }
    ];
    setLiveProcessesHeadRows(headRows());
  }, [allAlerts, processAlerts]);

  const [tableControl, setTableControl] = useState({
    processLive: {
      collection: 'processLive',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    },
    pendingApprovals: {
      collection: 'processLive',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    },
    fulfilledApprovals: {
      collection: 'processLive',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: ''
    }
  });

  const labels = {
    folio: GetTranslatedValue('PROCESSES.CAPTION.FOLIO', 'Folio'),
    name: GetTranslatedValue('PROCESSES.CAPTION.NAME', 'Name'),
    type: GetTranslatedValue('PROCESSES.CAPTION.TYPE', 'Type'),
    notifications: GetTranslatedValue('PROCESSES.CAPTION.NOTIFICATIONS', 'Notifications'),
    approvals: GetTranslatedValue('PROCESSES.CAPTION.APPROVALS', 'Approvals'),
    status: GetTranslatedValue('PROCESSES.CAPTION.STAGE', 'Stage'),
    alert: GetTranslatedValue('PROCESSES.CAPTION.ALERT', 'Alert'),
    creator: GetTranslatedValue('RECORD.CAPTION.CREATOR', 'Creador'),
    creationDate: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE', 'Creation Date'),
    updateDate: GetTranslatedValue('RECORD.CAPTION.UPDATEDATE', 'Update Date')
  };

  const [liveProcessesHeadRows, setLiveProcessesHeadRows] = useState([
    {
      id: 'folio',
      numeric: false,
      disablePadding: false,
      label: labels.folio,
      disableTableGrouping: true
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: labels.name,
      searchBy: 'processData.name',
      correction: 'processData.name'
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: labels.type,
      searchBy: 'processData.selectedProcessType',
      correction: 'processData.selectedProcessType'
    },
    {
      id: 'notifications',
      numeric: false,
      disablePadding: false,
      label: labels.notifications,
      searchByDisabled: true,
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => {
        const users = [].concat(...value.map(({ users }) => users)).length;
        return <UsersPerStageCell number={users} values={value} />;
      }
    },
    {
      id: 'approvals',
      numeric: false,
      disablePadding: false,
      label: labels.approvals,
      searchByDisabled: true,
      sortByDisabled: true,
      disableTableGrouping: true,
      renderCell: (value) => {
        const users = [].concat(...value.map(({ users }) => users)).length;
        return <UsersPerStageCell number={users} values={value} />;
      }
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: labels.status,
      searchBy: 'processData.processStatus',
      correction: 'processData.processStatus'
    },
    {
      id: 'alert',
      numeric: false,
      disablePadding: false,
      label: labels.alert,
      searchByDisabled: true,
      searchBy: 'dueDate',
      correction: 'dueDate',
      disableTableGrouping: true,
      renderCell: (value) => {
        const biggerThan = allAlerts
          .filter((element) => value * -1 >= Number(element.days))
          .map(({ days }) => days);
        const alertToApply = Math.max(...biggerThan);
        const alert = allAlerts.find(({ days }) => days === alertToApply.toString());
        return (
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'center',
              padding: '16px',
              borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}
          >
            {typeof value === 'number' && alert && (
              <Chip
                icon={value ? <Notification /> : null}
                label={value ? `${value * -1} ${daysLabel}` : 'No DueDate'}
                style={{ backgroundColor: alert?.color || '#B1B1B1', height: '28px' }}
                color="secondary"
              />
            )}
          </div>
        );
      }
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: labels.creator,
      searchBy: 'creationUserFullName',
      correction: 'creationUserFullName'
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: labels.creationDate,
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: labels.updateDate,
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ]);

  const [tab, setTab] = useState(0);
  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) return;
        id.forEach((_id) => {
          deleteDB(`${collection.name}/`, _id)
            .then((response) => loadLayoutsData('processLive'))
            .catch((error) => console.log('Error', error));
        });
      },
      onSelect(id) {
        if (collectionName === 'references') {
        }
      },
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const styleMultipleRows = (data, collection) => {
    const rows = data.map((row) => {
      const {
        _id: id,
        processData: { name, selectedProcessType, processStatus },
        creationUserFullName,
        creationDate,
        dueDate,
        updateDate,
        folio
      } = row;
      const pastDue = differenceInDays(new Date(dueDate), new Date());
      const approvalsPerStage = (row.processData.stages || []).map(({ stageName, approvals }) => ({
        name: stageName,
        users: approvals.map(
          ({ name, lastName, fulfilled }) =>
            `${name} ${lastName} (${fulfilled ? (fulfilled === 'skipped' ? skippedLabel : fulfilledLabel) : pendingLabel
            })`
        )
      }));
      const notificationsPerStage = (row.processData.stages || []).map(
        ({ stageName, notifications }) => ({
          name: stageName,
          users: notifications.map(
            ({ name, lastName, sent }) => `${name} ${lastName} (${sent ? sentLabel : pendingLabel})`
          )
        })
      );
      const processType = getTypeLabel(selectedProcessType);
      const status = getStatusLabel(processStatus);
      return createLiveProcessesHeadRows(
        id,
        folio,
        name,
        processType,
        notificationsPerStage,
        approvalsPerStage,
        status,
        pastDue,
        creationUserFullName,
        creationDate,
        updateDate
      );
    });
    return rows;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'approved':
        return approvedStatus;
      case 'rejected':
        return rejectedStatus;
      case 'partiallyApproved':
        return partiallyStatus;
      case 'inProcess':
        return inProcessStatus;
      default:
        return 'Error';
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case 'creation':
        return creationTypeLabel;
      case 'movement':
        return movementTypeLabel;
      case 'short':
        return shortMovementTypeLabel;
      case 'decommission':
        return decommisionLabel;
      case 'maintenance':
        return maintenanceLabel;
      default:
        return 'Error';
    }
  };

  const loadLayoutsData = (collectionNames = ['processLive', 'processApprovals']) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      if (collectionName === 'processLive') {
        if (!isEmpty(tableControl['processLive'].tableGrouping)) {
          updateTableGroupingTree(
            collectionName,
            tableControl[collectionName].tableGrouping,
            styleMultipleRows,
            tableControl,
            (newTree) => {
              setTableControl((prev) => ({
                ...prev,
                [collectionName]: {
                  ...prev[collectionName],
                  tableGrouping: newTree
                }
              }));
            },
            tableControl[collectionName].condition
          );
        }
        const condition = [{ 'requestUser.id': loggedUserId }];
        let queryLike = '';

        if (processFilters.processType) {
          condition.push({ 'processData.selectedProcessType': processFilters.processType.value });
        }
        if (processFilters.stage) {
          condition.push({ selectedProcess: { $in: complementaryValues.processesWithStage } });
        }
        if (processFilters.overdue) {
          if (processFilters.overdue.label === 'more') {
            condition.push({ dueDate: { $lte: `${complementaryValues.overdue}` } });
          } else {
            condition.push({ dueDate: { $gte: `${complementaryValues.overdue}` } });
          }
        }

        queryLike = tableControl.processLive.searchBy
          ? [
            {
              key:
                liveProcessesHeadRows.find(({ id }) => tableControl.processLive.searchBy === id)
                  .searchBy || tableControl.processLive.searchBy,
              value: tableControl.processLive.search
            }
          ]
          : [
            'creationDate',
            'processData.name',
            'folio',
            'selectedProcessType',
            'updateDate'
          ].map((key) => ({ key, value: tableControl.processLive.search }));

        getCountDB({
          collection: collectionName,
          queryLike: tableControl[collectionName].search ? queryLike : null,
          condition
        })
          .then((response) => response.json())
          .then((data) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                total: data.response.count
              }
            }));
          });

        getDBComplex({
          collection: collectionName,
          limit: tableControl[collectionName].rowsPerPage,
          skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
          sort: [
            { key: tableControl[collectionName].orderBy, value: tableControl[collectionName].order }
          ],
          queryLike: tableControl[collectionName].search ? queryLike : null,
          condition
        })
          .then((response) => response.json())
          .then((data) => {
            const rows = data.response.map((row) => {
              const {
                _id: id,
                processData: { name, selectedProcessType, processStatus },
                creationUserFullName,
                creationDate,
                dueDate,
                updateDate,
                folio
              } = row;
              const pastDue = differenceInDays(new Date(dueDate), new Date());
              const approvalsPerStage = (row.processData.stages || []).map(
                ({ stageName, approvals }) => ({
                  name: stageName,
                  users: approvals.map(
                    ({ name, lastName, fulfilled }) =>
                      `${name} ${lastName} (${fulfilled
                        ? fulfilled === 'skipped'
                          ? skippedLabel
                          : fulfilledLabel
                        : pendingLabel
                      })`
                  )
                })
              );
              const notificationsPerStage = (row.processData.stages || []).map(
                ({ stageName, notifications }) => ({
                  name: stageName,
                  users: notifications.map(
                    ({ name, lastName, sent }) =>
                      `${name} ${lastName} (${sent ? sentLabel : pendingLabel})`
                  )
                })
              );
              const processType = getTypeLabel(selectedProcessType);
              const status = getStatusLabel(processStatus);
              return createLiveProcessesHeadRows(
                id,
                folio,
                name,
                processType,
                notificationsPerStage,
                approvalsPerStage,
                status,
                pastDue,
                creationUserFullName,
                creationDate,
                updateDate
              );
            });
            setControl((prev) => ({ ...prev, processLiveRows: rows, ProcessLiveRowsSelected: [] }));
          })
          .catch((error) => console.log('error>', error));

        setTableControl((prev) => ({
          ...prev,
          [collectionName]: {
            ...prev[collectionName],
            condition: condition
          }
        }));
      }
      if (collectionName === 'processApprovals') {
        const approvals = [
          {
            fulfilled: true,
            controlArray: 'processLiveApprovalsFulfilledRows',
            controlKey: 'fulfilledApprovals'
          },
          {
            fulfilled: false,
            controlArray: 'processLiveApprovalsNotFulfilledRows',
            controlKey: 'pendingApprovals'
          }
        ];

        approvals.forEach(({ fulfilled, controlArray, controlKey }) => {
          const queryNotFulfilled = [
            { key: 'userId', value: loggedUserId },
            { key: 'fulfilled', value: fulfilled }
          ];
          const condition = [];
          if (processFilters.processType) {
            condition.push({ 'processData.selectedProcessType': processFilters.processType.value });
          }
          if (processFilters.stage) {
            condition.push({ selectedProcess: { $in: complementaryValues.processesWithStage } });
          }
          if (processFilters.overdue) {
            if (processFilters.overdue.label === 'more') {
              condition.push({ dueDate: { $lte: `${complementaryValues.overdue}` } });
            } else {
              condition.push({ dueDate: { $gte: `${complementaryValues.overdue}` } });
            }
          }

          let queryLike = '';
          queryLike = tableControl[controlKey].searchBy
            ? [
              {
                key:
                  liveProcessesHeadRows.find(({ id }) => tableControl[controlKey].searchBy === id)
                    .searchBy || tableControl[controlKey].searchBy,
                value: tableControl[controlKey].search
              }
            ]
            : [
              'creationDate',
              'processData.name',
              'folio',
              'selectedProcessType',
              'updateDate'
            ].map((key) => ({ key, value: tableControl[controlKey].search }));

          getProcessApprovals({
            collection: collectionName,
            queryExact: queryNotFulfilled,
            operator: '$and',
            queryLike: tableControl[controlKey].search ? queryLike : null,
            condition: !isEmpty(condition) ? condition : null,
            count: true
          })
            .then((response) => response.json())
            .then((data) => {
              setTableControl((prev) => ({
                ...prev,
                [controlKey]: {
                  ...prev[controlKey],
                  total: data.response.count
                }
              }));
            })
            .catch((error) => console.log(error));

          getProcessApprovals({
            collection: collectionName,
            queryExact: queryNotFulfilled,
            operator: '$and',
            queryLike: tableControl[controlKey].search ? queryLike : null,
            condition: !isEmpty(condition) ? condition : null,
            limit: tableControl[controlKey].rowsPerPage,
            skip: tableControl[controlKey].rowsPerPage * tableControl[controlKey].page,
            sort: [{ key: tableControl[controlKey].orderBy, value: tableControl[controlKey].order }]
          })
            .then((response) => response.json())
            .then((data) => {
              const rows = data.response.map((row) => {
                const {
                  _id: id,
                  processData: { name, selectedProcessType, processStatus },
                  creationUserFullName,
                  creationDate,
                  updateDate,
                  dueDate,
                  folio
                } = row;
                const pastDue = differenceInDays(new Date(dueDate), new Date());
                const approvalsPerStage = (row.processData.stages || []).map(
                  ({ stageName, approvals }) => ({
                    name: stageName,
                    users: approvals.map(
                      ({ name, lastName, fulfilled }) =>
                        `${name} ${lastName} (${fulfilled
                          ? fulfilled === 'skipped'
                            ? skippedLabel
                            : fulfilledLabel
                          : pendingLabel
                        })`
                    )
                  })
                );
                const notificationsPerStage = (row.processData.stages || []).map(
                  ({ stageName, notifications }) => ({
                    name: stageName,
                    users: notifications.map(
                      ({ name, lastName, sent }) =>
                        `${name} ${lastName} (${sent ? sentLabel : pendingLabel})`
                    )
                  })
                );
                return createLiveProcessesHeadRows(
                  id,
                  folio,
                  name,
                  selectedProcessType,
                  notificationsPerStage,
                  approvalsPerStage,
                  processStatus,
                  pastDue,
                  creationUserFullName,
                  creationDate,
                  updateDate
                );
              });
              setControl((prev) => ({
                ...prev,
                [controlArray]: rows,
                ProcessLiveApprovalsRowsSelected: []
              }));
            })
            .then((response) => response.json())
            .then((data) => {
              const rows = data.response.map((row) => {
                const {
                  _id: id,
                  processData: { name, selectedProcessType, processStatus },
                  creationUserFullName,
                  creationDate,
                  updateDate,
                  dueDate,
                  folio
                } = row;
                const pastDue = differenceInDays(new Date(dueDate), new Date());
                const approvalsPerStage = (row.processData.stages || []).map(
                  ({ stageName, approvals }) => ({
                    name: stageName,
                    users: approvals.map(
                      ({ name, lastName, fulfilled }) =>
                        `${name} ${lastName} (${fulfilled
                          ? fulfilled === 'skipped'
                            ? skippedLabel
                            : fulfilledLabel
                          : pendingLabel
                        })`
                    )
                  })
                );
                const notificationsPerStage = (row.processData.stages || []).map(
                  ({ stageName, notifications }) => ({
                    name: stageName,
                    users: notifications.map(
                      ({ name, lastName, sent }) =>
                        `${name} ${lastName} (${sent ? sentLabel : pendingLabel})`
                    )
                  })
                );
                const processType = getTypeLabel(selectedProcessType);
                const status = getStatusLabel(processStatus);
                return createLiveProcessesHeadRows(
                  id,
                  folio,
                  name,
                  processType,
                  notificationsPerStage,
                  approvalsPerStage,
                  status,
                  pastDue,
                  creationUserFullName,
                  creationDate,
                  updateDate
                );
              });
              setControl((prev) => ({
                ...prev,
                [controlArray]: rows,
                ProcessLiveApprovalsRowsSelected: []
              }));
            })
            .catch((error) => console.log(error));
        });
      }
    });
  };

  const handleChangeProcessFilters = (name, value) => {
    setProcessFilters((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    // getDB('settingsProcesses')
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('Alertas:', data.response[0].alerts )
    //     setAllAlerts(data.response[0].alerts || []);
    //   })
    //   .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    if (processFilters.stage) {
      const condition = [{ processStages: { $elemMatch: { id: processFilters.stage.value } } }];
      const fields = [{ key: '_id', value: 1 }];
      getDBComplex({ collection: 'processes', condition, fields })
        .then((response) => response.json())
        .then((data) => {
          const processesWithStage = data.response.map(({ _id }) => _id);
          setComplementaryValues((prev) => ({ ...prev, processesWithStage }));
        })
        .catch((error) => console.log(error));
    } else {
      setComplementaryValues((prev) => ({ ...prev, processesWithStage: [] }));
    }
  }, [processFilters.stage]);

  useEffect(() => {
    if (processFilters.overdue) {
      const fecha = sub(new Date(), processFilters.overdue.value).toISOString();
      setComplementaryValues((prev) => ({ ...prev, overdue: fecha }));
    } else {
      setComplementaryValues((prev) => ({ ...prev, overdue: '' }));
    }
  }, [processFilters.overdue]);

  useEffect(() => {
    if (!isMount) {
      const fields = [
        { key: '_id', value: 1 },
        { key: 'name', value: 1 }
      ];

      getDBComplex({ collection: 'processStages', fields })
        .then((response) => response.json())
        .then((data) => {
          const processedStagesInfo = data.response.map(({ _id, name }) => ({
            value: _id,
            label: name
          }));
          setComplementaryValues((prev) => ({ ...prev, processedStagesInfo }));
        })
        .catch((error) => console.log(error));

      loadLayoutsData();
    }
  }, [
    processFilters.processType,
    complementaryValues.processesWithStage,
    complementaryValues.overdue
  ]);

  useEffect(() => {
    if (!isMount) {
      loadLayoutsData('processLive');
    }
  }, [
    tableControl.processLive.page,
    tableControl.processLive.rowsPerPage,
    tableControl.processLive.order,
    tableControl.processLive.orderBy,
    tableControl.processLive.search
  ]);

  useEffect(() => {
    if (!isMount) {
      loadLayoutsData('processApprovals');
    }
  }, [
    tableControl.pendingApprovals.page,
    tableControl.pendingApprovals.rowsPerPage,
    tableControl.pendingApprovals.order,
    tableControl.pendingApprovals.orderBy,
    tableControl.pendingApprovals.search
  ]);

  useEffect(() => {
    if (!isMount) {
      loadLayoutsData('processApprovals');
    }
  }, [
    tableControl.fulfilledApprovals.page,
    tableControl.fulfilledApprovals.rowsPerPage,
    tableControl.fulfilledApprovals.order,
    tableControl.fulfilledApprovals.orderBy,
    tableControl.fulfilledApprovals.search
  ]);

  return (
    <div>
      <Portlet>
        <div className={classes.selectWrapper}>
          <div>
            <Typography style={{ margin: '5px' }}>{`${GetTranslatedValue(
              'PROCESSES.CAPTION.TYPE',
              'Type'
            )}:`}</Typography>
            <Select
              className={classes.select}
              classNamePrefix="select"
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={'absolute'}
              onChange={(value) => handleChangeProcessFilters('processType', value)}
              options={processTypes}
              placeholder={GetTranslatedValue('GENERAL.CAPTION.SELECT.PLACEHOLDER', 'Select...')}
              value={processFilters.processType}
            />
          </div>
          <div>
            <Typography style={{ margin: '5px' }}>{`${GetTranslatedValue(
              'PROCESSES.CAPTION.STAGE',
              'Stage'
            )}:`}</Typography>
            <Select
              className={classes.select}
              classNamePrefix="select"
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={'absolute'}
              onChange={(value) => handleChangeProcessFilters('stage', value)}
              options={complementaryValues.processedStagesInfo}
              placeholder={GetTranslatedValue('GENERAL.CAPTION.SELECT.PLACEHOLDER', 'Select...')}
              value={processFilters.stage}
            />
          </div>{' '}
          <div>
            <Typography style={{ margin: '5px' }}>{`${GetTranslatedValue(
              'PROCESSES.CAPTION.OVERDUE',
              'Overdue'
            )}:`}</Typography>
            <Select
              className={classes.select}
              classNamePrefix="select"
              isClearable={true}
              menuPortalTarget={document.body}
              menuPosition={'absolute'}
              onChange={(value) => handleChangeProcessFilters('overdue', value)}
              options={overdueFilter}
              placeholder={GetTranslatedValue('GENERAL.CAPTION.SELECT.PLACEHOLDER', 'Select...')}
              value={processFilters.overdue}
            />
          </div>
        </div>
        <PortletHeader
          toolbar={
            <PortletHeaderToolbar>
              <Tabs
                component="div"
                className="builder-tabs"
                value={tab}
                onChange={(_, nextTab) => {
                  setTab(nextTab);
                }}
                variant="scrollable"
              >
                {GenerateSubTabs('processes', 'Live')}
              </Tabs>
            </PortletHeaderToolbar>
          }
        />
        <ModalProcessesLive
          id={control.idProcessLive}
          readOnly={control.processLiveReadOnly}
          reloadTable={() => loadLayoutsData()}
          setShowModal={(onOff) => setControl({ ...control, openProcessLiveModal: onOff })}
          showModal={control.openProcessLiveModal}
        />
        {tab === 0 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <TableComponent2
                      controlValues={tableControl.processLive}
                      headRows={liveProcessesHeadRows}
                      onAdd={tableActions('processLive').onAdd}
                      onDelete={tableActions('processLive').onDelete}
                      onEdit={tableActions('processLive').onEdit}
                      onSelect={tableActions('processLive').onSelect}
                      onView={tableActions('processLive').onView}
                      paginationControl={({ rowsPerPage, page }) =>
                        setTableControl((prev) => ({
                          ...prev,
                          processLive: {
                            ...prev.processLive,
                            rowsPerPage: rowsPerPage,
                            page: page
                          }
                        }))
                      }
                      rows={control.processLiveRows}
                      searchControl={({ value, field }) => {
                        if (tableControl.processLive.search === value && !field) {
                          loadLayoutsData('processLive');
                        } else {
                          setTableControl((prev) => ({
                            ...prev,
                            processLive: {
                              ...prev.processLive,
                              search: value,
                              searchBy: field
                            }
                          }));
                        }
                      }}
                      sortByControl={({ orderBy, order }) => {
                        setTableControl((prev) => ({
                          ...prev,
                          processLive: {
                            ...prev.processLive,
                            orderBy: orderByCorrection[orderBy]
                              ? orderByCorrection[orderBy]
                              : orderBy,
                            order: order
                          }
                        }));
                      }}
                      fetchTableGroupingData={loadGroups}
                      setTableGroupingControl={(tree) =>
                        setTableControl((prev) => ({
                          ...prev,
                          processLive: {
                            ...prev.processLive,
                            tableGrouping: tree
                          }
                        }))
                      }
                      tableGroupingCreateRows={styleMultipleRows}
                      tableGrouping
                      tab="live"
                      title={GetTranslatedValue(
                        'PROCESSES.TABLE.MY.REQUESTS',
                        'My Processes Requests'
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
        {/* Process Stages Layouts */}
        {tab === 1 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <TableComponent2
                      controlValues={tableControl.pendingApprovals}
                      headRows={liveProcessesHeadRows}
                      noAdd
                      noDelete
                      onEdit={tableActions('processLive').onEdit}
                      onSelect={tableActions('processLive').onSelect}
                      onView={tableActions('processLive').onView}
                      paginationControl={({ rowsPerPage, page }) =>
                        setTableControl((prev) => ({
                          ...prev,
                          pendingApprovals: {
                            ...prev.pendingApprovals,
                            rowsPerPage: rowsPerPage,
                            page: page
                          }
                        }))
                      }
                      rows={control.processLiveApprovalsNotFulfilledRows}
                      searchControl={({ value, field }) => {
                        if (tableControl.pendingApprovals.search === value && !field) {
                          loadLayoutsData('processApprovals');
                        } else {
                          setTableControl((prev) => ({
                            ...prev,
                            pendingApprovals: {
                              ...prev.pendingApprovals,
                              search: value,
                              searchBy: field
                            }
                          }));
                        }
                      }}
                      sortByControl={({ orderBy, order }) => {
                        setTableControl((prev) => ({
                          ...prev,
                          pendingApprovals: {
                            ...prev.pendingApprovals,
                            orderBy: orderByCorrection[orderBy]
                              ? orderByCorrection[orderBy]
                              : orderBy,
                            order: order
                          }
                        }));
                      }}
                      fetchTableGroupingData={loadGroups}
                      setTableGroupingControl={(tree) =>
                        setTableControl((prev) => ({
                          ...prev,
                          pendingApprovals: {
                            ...prev.pendingApprovals,
                            tableGrouping: tree
                          }
                        }))
                      }
                      tableGroupingCreateRows={styleMultipleRows}
                      tableGrouping
                      tab="live"
                      title={GetTranslatedValue(
                        'PROCESSES.TABLE.PENDING.REQUESTS',
                        'Process Pending Approval'
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
        {tab === 2 && (
          <PortletBody>
            <div className="kt-section kt-margin-t-0 kt-margin-b-0">
              <div className="kt-section__body">
                <div className="kt-section">
                  <div className="kt-section__content">
                    <TableComponent2
                      controlValues={tableControl.fulfilledApprovals}
                      headRows={liveProcessesHeadRows}
                      noAdd
                      noDelete
                      onEdit={tableActions('processLive').onEdit}
                      onSelect={tableActions('processLive').onSelect}
                      onView={tableActions('processLive').onView}
                      paginationControl={({ rowsPerPage, page }) =>
                        setTableControl((prev) => ({
                          ...prev,
                          fulfilledApprovals: {
                            ...prev.fulfilledApprovals,
                            rowsPerPage: rowsPerPage,
                            page: page
                          }
                        }))
                      }
                      rows={control.processLiveApprovalsFulfilledRows}
                      searchControl={({ value, field }) => {
                        if (tableControl.fulfilledApprovals.search === value && !field) {
                          loadLayoutsData('processApprovals');
                        } else {
                          setTableControl((prev) => ({
                            ...prev,
                            fulfilledApprovals: {
                              ...prev.fulfilledApprovals,
                              search: value,
                              searchBy: field
                            }
                          }));
                        }
                      }}
                      sortByControl={({ orderBy, order }) => {
                        setTableControl((prev) => ({
                          ...prev,
                          fulfilledApprovals: {
                            ...prev.fulfilledApprovals,
                            orderBy: orderByCorrection[orderBy]
                              ? orderByCorrection[orderBy]
                              : orderBy,
                            order: order
                          }
                        }));
                      }}
                      fetchTableGroupingData={loadGroups}
                      setTableGroupingControl={(tree) =>
                        setTableControl((prev) => ({
                          ...prev,
                          fulfilledApprovals: {
                            ...prev.fulfilledApprovals,
                            tableGrouping: tree
                          }
                        }))
                      }
                      tableGroupingCreateRows={styleMultipleRows}
                      tableGrouping
                      tab="live"
                      title={GetTranslatedValue(
                        'PROCESSES.TABLE.FULFILLED.REQUESTS',
                        'Process Fulfilled Approval'
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
        )}
      </Portlet>
    </div>
  );
};

const mapStateToProps = ({ auth: { user }, general: { processAlerts: allAlerts } }) => ({
  user,
  allAlerts
});

export default connect(mapStateToProps)(LiveProcesses);
