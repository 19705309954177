import React from "react";
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PeopleIcon from '@material-ui/icons/People';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import AppsIcon from '@material-ui/icons/Apps';

class MenuItemText extends React.Component {
  render() {
    const { item, parentItem, classes, isActive} = this.props;

    const handleIcons = ({name,  props}) => {
      const Icons = {
        assets: <AppsIcon {...props} />,
        processes: <DeviceHubIcon {...props} />,
        employees: <PeopleIcon {...props} />,
        users: <AccountCircleIcon {...props} />,
        locations: <LocationOnIcon {...props} />,
        reports: <DescriptionIcon {...props} />,
        settings: <SettingsIcon {...props} />,
      }

      return Icons[name]
    }

    return (
      <>
        {item.icon && (handleIcons({
          name: item.page, 
          props: {
            className:clsx(`kt-menu__link-icon ${item.icon}`, {
              [classes.icon]: !isActive,
              [classes.iconSelected]: isActive,
            }),
            style: {fontSize: 20}
          }
        }))}

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        <span className={clsx("kt-menu__link-text", {
          [classes.selectedTextColor]: isActive,
          [classes.textColor]: !isActive,
        })} style={{ whiteSpace: "nowrap" }}>
          {!item.translate ? (
            item.title
          ) : (
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          )}
        </span>

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
      </>
    );
  }
}

const withStylesProps = (styles) =>
  (Component) =>
    (props) => {
      const Comp = withStyles(theme => styles({...props.appColors, theme}))(Component);
      return <Comp {...props} />;
    };

const styles = (props) => {
  return ({
    selectedTextColor: {
      color: `${props.sideNavBarTabTitlesHover} !important`
    },
    textColor: {
      color: `${props.sideNavBarTabTitles} !important`
    },
    icon: {
      fontSize: 20,
      color: `${props.sideNavBarTabIcons} !important`
    },
    iconSelected: {
      color: `${props.sideNavBarTabIconsHover} !important`
    },
  })
};

export default withStylesProps(styles)(
  (props) => (
    <MenuItemText {...props}/>
  )
);
