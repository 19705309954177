import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { Button, Grid, Typography, Tab, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/ducks/general.duck';
import {
  executeOnFieldPolicy,
  executeOnLoadPolicy,
  executePolicies
} from '../../Components/Policies/utils';
import BaseFields from '../../Components/BaseFields/BaseFields';
import { CustomFieldsPreview } from '../../constants';
import ImageUpload from '../../Components/ImageUpload';
import {
  getFileExtension,
  saveImage,
  getImageURL,
  GetTranslatedValue,
  verifyCustomFields,
  updateCustomFields,
  validateEmail,
  loadUserLocations,
  loadLocationsData,
  convertToObjectIdArray
} from '../../utils';
import {
  getDB,
  getDBComplex,
  getOneDB,
  postDB,
  updateDB,
  updateManyDB
} from '../../../../crud/api';
import AssetTable from '../components/AssetTable';
import ModalYesNo from '../../Components/ModalYesNo';
import ModalAssignmentReport from './ModalAssignmentReport';
import CustomRecordModal from '../../Components/CustomRecordModal';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalEmployees = ({
  fields,
  fieldsToValidate,
  id,
  employeeProfileRows,
  policies,
  readOnly,
  reloadTable,
  showModal,
  setShowModal,
  user
}) => {
  const dispatch = useDispatch();
  const {
    showCustomAlert,
    showFillFieldsAlert,
    showErrorAlert,
    showSavedAlert,
    showUpdatedAlert
  } = actions;

  /* Assets */
  const [assetsBeforeSaving, setAssetsBeforeSaving] = useState([]);
  const [assetsToDelete, setAssetsToDelete] = useState([]);
  const [showAssignedConfirmation, setShowAssignedConfirmation] = useState(false);
  const [assetsAssigned, setAssetsAssigned] = useState([]);
  const [confirmationText, setConfirmationText] = useState('');

  /* General */
  const [showModalReports, setShowModalReports] = useState(false);
  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [htmlPreview, setHtmlPreview] = useState([]);
  const [employeeProfilesFiltered, setEmployeeProfilesFiltered] = useState([]);
  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const [layoutOptions, setLayoutOptions] = useState([]);
  const [layoutSelected, setLayoutSelected] = useState(0);
  const [locationsTable, setLocationsTable] = useState([]);
  const [profilePermissions, setProfilePermissions] = useState({});
  const [profileSelected, setProfileSelected] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [isAssetRepository, setIsAssetRepository] = useState(null);
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  const [values, setValues] = useState({
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg',
    email: '',
    employee_id: '',
    lastName: '',
    name: '',
    selectedUserProfile: null
  });
  const [responsibilityLayout, setResponsabilityLayout] = useState({
    added: {},
    initial: {},
    removed: {}
  });
  const [userLocations, setUserLocations] = useState([]);
  const [parentLocations, setParentLocations] = useState([]);
  const [locationsTreeView, setLocationsTreeView] = useState({});

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });
  const [customFieldsPathResponse, setCustomFieldsPathResponse] = useState();

  const messages = {
    sameEmail: GetTranslatedValue('WARNING.SAME.EMPLOYEE.EMAIL'),
    sameId: GetTranslatedValue('WARNING.SAME.EMPLOYEE.ID'),
    cannotChangeProfile: GetTranslatedValue('EMPLOYEE.MODAL.CANNOT.CHANGE.PROFILE')
  };

  const loadLocations = async () => {
    const userLocations = await loadUserLocations({
      setParentLocations,
      userId: user?.id
    });
    setUserLocations(userLocations);
  };

  const filterLocations = async () => {
    const locationsTreeData = await loadLocationsData(userLocations);
    setLocationsTreeView(locationsTreeData);
  };

  const handleAssignmentsOnSaving = (id) => {
    if (!isEmpty(assetsToDelete)) {
      updateManyDB({
        body: { assigned: null, assignedTo: '' },
        collection: 'assets',
        condition: [{ _id: { $in: convertToObjectIdArray(assetsToDelete.map(({ id }) => id)) } }]
      })
        .catch((error) => {
          dispatch(showErrorAlert())
        });
    }

    if (!isEmpty(assetsBeforeSaving)) {
      updateManyDB({
        body: { assigned: id, assignedTo: `${values.name} ${values.lastName} <${values.email}>` },
        collection: 'assets',
        condition: [
          { _id: { $in: convertToObjectIdArray(assetsBeforeSaving.map(({ id }) => id)) } }
        ]
      })
        .catch((error) => {
          dispatch(showErrorAlert())
        });
    }
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChange4 = (event, newValue) => {
    setValue4(newValue);
  };
  const handleChangeIndex4 = (index) => {
    setValue4(index);
  };

  const renderAssignmentTable = () => {
    if (isAssetRepository === null) {
      return (
        <Grid style={{ flex: 1 }} item container alignItems="center" justify="center">
          <h6 style={{ alignSelf: 'center' }}>
            {GetTranslatedValue(
              'EMPLOYEE.MODAL.ASSIGNMENT.SELECT.PROFILE',
              'First, choose a profile'
            )}
          </h6>
        </Grid>
      );
    } else {
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="primary"
              disabled={readOnly || isAssetRepository === false}
              onClick={openModalAssignmentReport}
              size="large"
              style={{ marginBottom: '20px' }}
              variant="contained"
            >
              {GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENTS.REPORT')}
            </Button>
          </div>
          <div className="profile-tab-wrapper">
            <AssetTable
              assetRows={assetsBeforeSaving}
              deleteOnly={isAssetRepository === false}
              onAssetFinderSubmit={handleOnAssetFinderSubmit}
              onDeleteAssetAssigned={handleOnDeleteAssetAssigned}
              parentLocations={parentLocations}
              readOnly={readOnly}
              locationsTreeView={locationsTreeView}
            />
          </div>
        </>
      );
    }
  };

  const onChangeEmployeeProfile = (e) => {
    if (!e) {
      setValues((prev) => ({ ...prev, employeeProfile: e }));
      setCustomFieldsTab({});
      setProfilePermissions({});
      setProfileSelected(e);
      setTabs([]);
      setIsAssetRepository(null);
      return;
    }

    getOneDB('employeeProfiles/', e.value)
      .then((response) => response.json())
      .then((data) => {
        const { customFieldsTab, profilePermissions, isAssetRepository } = data.response;
        if (!isAssetRepository && !isEmpty(assetsBeforeSaving)) {
          dispatch(
            showCustomAlert({
              message: messages.cannotChangeProfile,
              open: true,
              type: 'warning'
            })
          );
          return;
        }
        setProfileSelected(e);
        setIsAssetRepository(isAssetRepository);
        const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
          key,
          info: value.info,
          content: [value.left, value.right]
        }));
        tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
        setTabs(tabs);
        setCustomFieldsTab(customFieldsTab);
        setProfilePermissions(profilePermissions);
        setValues((prev) => ({ ...prev, employeeProfile: e }));
      })
      .catch((error) => {
        dispatch(showErrorAlert())
      });
  };

  const baseFieldsLocalProps = {
    employeeProfile: {
      componentProps: {
        isClearable: true,
        onChange: onChangeEmployeeProfile,
        options: employeeProfilesFiltered,
        value: profileSelected,
        isDisabled: readOnly
      }
    },
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: { readOnly }
      }
    },
    lastName: {
      componentProps: {
        onChange: handleChange('lastName'),
        inputProps: { readOnly }
      }
    },
    email: {
      ownValidFn: () => validateEmail(values.email),
      componentProps: {
        onChange: handleChange('email'),
        inputProps: { readOnly },
        type: 'email'
      }
    },
    employee_id: {
      componentProps: {
        onChange: handleChange('employee_id'),
        inputProps: { readOnly }
      }
    },
    responsibilityLayout: {
      style: {
        marginTop: '15px'
      },
      componentProps: {
        isClearable: true,
        onChange: (layout) => {
          if (layout) {
            if (responsibilityLayout.initial?.value !== layout?.value && id) {
              setResponsabilityLayout((prev) => ({
                ...prev,
                added: layout,
                removed: prev.initial || {}
              }));
            } else if (!id) {
              setResponsabilityLayout((prev) => ({ ...prev, added: layout }));
            } else {
              setResponsabilityLayout((prev) => ({
                ...prev,
                added: {},
                removed: {}
              }));
            }
          } else {
            setResponsabilityLayout((prev) => ({
              ...prev,
              added: {},
              removed: prev.initial
            }));
          }

          setValues((prev) => ({ ...prev, layoutSelected: layout }));
          setLayoutSelected(layout);
        },
        options: layoutOptions,
        value: layoutSelected,
        isDisabled: readOnly
      }
    }
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setProfilePermissions([]);
    setTabs([]);
    setProfileSelected(null);
    setValues({
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg',
      email: '',
      employee_id: '',
      lastName: '',
      name: '',
      password: '',
      selectedUserProfile: null
    });
    setShowModal(false);
    setValue4(0);
    setLayoutSelected(null);
    setAssetsBeforeSaving([]);
    setAssetsToDelete([]);
    setAssetsAssigned([]);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
    setIsAssetRepository(null);
    setResponsabilityLayout({
      added: {},
      initial: {},
      removed: {}
    });
    setShowAssignedConfirmation(false);
    setConfirmationText('');
  };

  const createAssetRow = (id, name, brand, model, assigned, EPC, serial, creationDate) => {
    return { id, name, brand, model, assigned, EPC, serial, creationDate };
  };

  const handleOnAssetFinderSubmit = (filteredRows) => {
    let assetsAlreadyAssigned = [];
    filteredRows.rows.map((rowTR) => {
      if (!assetsBeforeSaving.find((row) => row.id === rowTR.id)) {
        getOneDB('assets/', rowTR.id)
          .then((response) => response.json())
          .then((data) => {
            const { response } = data;
            if (!response.assigned) {
              setAssetsBeforeSaving((prev) => [
                ...prev,
                createAssetRow(
                  response._id,
                  response.name,
                  response.brand,
                  response.model,
                  false,
                  response.EPC,
                  response.serial,
                  response.creationDate
                )
              ]);
              return;
            }

            const asset = createAssetRow(
              response._id,
              response.name,
              response.brand,
              response.model,
              false,
              response.EPC,
              response.serial,
              response.creationDate
            );

            assetsAlreadyAssigned.push({
              ...asset,
              employeeId: response.assigned,
              employeeName: response.assignedTo
            });
          })
          .catch((error) => console.log(error))
          .finally(() => {
            if (assetsAlreadyAssigned.length) {
              setAssetsAssigned(assetsAlreadyAssigned);
              setShowAssignedConfirmation(true);
              const text = assetsAlreadyAssigned.map(({ name, employeeName }) => (
                <span>
                  {' '}
                  {name} - {employeeName || 'No Employee Name Founded'} <br />{' '}
                </span>
              ));
              setConfirmationText(<>{text}</>);
            }
          });
      } else {
        dispatch(
          showCustomAlert({
            message: 'Asset already assigned to this employee',
            open: true,
            type: 'warning'
          })
        );
      }
    });
  };

  const handleOnDeleteAssetAssigned = (id) => {
    const restRows = assetsBeforeSaving.filter((row) => {
      if (!id.includes(row.id)) {
        return row;
      }
      if (!row.employeeId) {
        setAssetsToDelete((prev) => [...prev, row]);
      }
    });
    setAssetsBeforeSaving(restRows);
  };

  const validEmployeeCredentials = () => ({
    email: () => {
      return getDBComplex({
        collection: 'employees',
        condition: [{ email: values.email }]
      })
        .then((response) => response.json())
        .then((data) => isEmpty(data?.response.filter(({ _id }) => _id !== id[0])))
        .catch((error) => dispatch(showErrorAlert()));
    },
    employee_id: () => {
      if (isEmpty(values.employee_id)) {
        return true;
      } else {
        return getDBComplex({
          collection: 'employees',
          condition: [{ employee_id: values.employee_id }]
        })
          .then((response) => response.json())
          .then((data) => isEmpty(data?.response.filter(({ _id }) => _id !== id[0])))
          .catch((error) => dispatch(showErrorAlert()));
      }
    }
  });

  const handleSave = async () => {
    setFormValidation({ ...formValidation, enabled: true });
    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (!verifyCustomFields(customFieldsTab)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    const validEmail = !values.email ? true : await validEmployeeCredentials().email();
    const validEmployeeId = await validEmployeeCredentials().employee_id();

    if (!validEmail) {
      dispatch(
        showCustomAlert({
          message: messages.sameEmail,
          open: true,
          type: 'warning'
        })
      );

      return;
    } else if (!validEmployeeId) {
      dispatch(
        showCustomAlert({
          message: messages.sameId,
          open: true,
          type: 'warning'
        })
      );

      return;
    }

    const fileExt = getFileExtension(image);

    let reassignedAssets = [];

    assetsBeforeSaving.forEach(({ employeeId, id: assetId }) => {
      const index = reassignedAssets.findIndex((element) => element.employeeId === employeeId);
      if (index !== -1) {
        reassignedAssets[index].assets = [...reassignedAssets[index].assets, assetId];
      } else if (employeeId) {
        reassignedAssets.push({ employeeId, assets: [assetId] });
      }
    });

    if (reassignedAssets.length) {
      reassignedAssets.forEach(({ assets, employeeId }) => {
        getOneDB('employees/', employeeId)
          .then((response) => response.json())
          .then((data) => {
            const {
              response: { assetsAssigned }
            } = data;
            const newAssetsAssigned = assetsAssigned.filter(
              ({ id: assetId }) => !assets.includes(assetId)
            );
            updateDB(
              'employees/',
              { assetsAssigned: newAssetsAssigned },
              employeeId
            ).catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      });
    }

    const parseAssetsAssigned = assetsBeforeSaving.map(
      ({ id, name, brand, model, assigned, EPC, serial, creationDate }) =>
        createAssetRow(id, name, brand, model, assigned, EPC, serial, creationDate)
    );

    const body = {
      ...values,
      customFieldsTab,
      profilePermissions,
      locationsTable,
      layoutSelected,
      fileExt,
      assetsAssigned: parseAssetsAssigned
    };

    if (!id) {
      postDB('employees', body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('employees', _id);
          executePolicies('OnAdd', 'employees', 'list', policies, response.response[0]);
          executeOnFieldPolicy('OnAdd', 'employees', 'list', policies, response.response[0]);
          handleAssignmentsOnSaving(_id);

          if (Object.entries(responsibilityLayout.added || {}).length) {
            getOneDB('settingsLayoutsEmployees/', responsibilityLayout.added.value)
              .then((response) => response.json())
              .then((data) => {
                const { used } = data.response;
                const value = (Number(used || 0) + 1).toString();
                updateDB(
                  'settingsLayoutsEmployees/',
                  { used: value },
                  responsibilityLayout.added.value
                ).catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    } else {
      updateDB('employees/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          const employee = { _id: id[0], ...body };

          handleAssignmentsOnSaving(id[0]);
          dispatch(showUpdatedAlert());
          saveAndReload('employees', id[0]);
          executePolicies('OnEdit', 'employees', 'list', policies, employee);
          executeOnFieldPolicy('OnEdit', 'employees', 'list', policies, employee, value);

          if (Object.entries(responsibilityLayout.added || {}).length) {
            getOneDB('settingsLayoutsEmployees/', responsibilityLayout.added.value)
              .then((response) => response.json())
              .then((data) => {
                const { used } = data.response;
                const value = (Number(used || 0) + 1).toString();
                updateDB(
                  'settingsLayoutsEmployees/',
                  { used: value },
                  responsibilityLayout.added.value
                ).catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));
          }

          if (Object.entries(responsibilityLayout.removed || {}).length) {
            getOneDB('settingsLayoutsEmployees/', responsibilityLayout.removed.value)
              .then((response) => response.json())
              .then((data) => {
                const { used } = data.response;
                const value = (Number(used || 1) - 1).toString();
                updateDB(
                  'settingsLayoutsEmployees/',
                  { used: value },
                  responsibilityLayout.removed.value
                ).catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => {
          console.log('Error:', error);
          dispatch(showErrorAlert());
        });
    }
    handleCloseModal();
  };

  const handleUpdateCustomFields = (tab, id, colIndex, CFValues) => {
    if (isEmpty(customFieldsTab)) {
      return;
    }
    const colValue = ['left', 'right'];
    const customFieldsTabTmp = { ...customFieldsTab };
    const field = customFieldsTabTmp[tab][colValue[colIndex]].find((cf) => cf.id === id) || {};

    field.values = CFValues;
  };

  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }

    reloadTable();
  };

  useEffect(() => {
    getDB('settingsLayoutsEmployees')
      .then((response) => response.json())
      .then((data) => {
        const layoutOptions = data.response.map(({ _id: value, name: label }) => ({
          value,
          label
        }));
        if (layoutSelected) {
          const employeeLayoutSelected = data.response.filter(
            ({ _id }) => _id === layoutSelected.value
          );
          setHtmlPreview(employeeLayoutSelected);
        }
        setLayoutOptions(layoutOptions);
      })
      .catch((error) => console.log('error>', error));
  }, [layoutSelected]);

  useEffect(() => {
    const userProfiles = employeeProfileRows.map((profile, ix) => ({
      value: profile.id,
      label: profile.name
    }));
    setEmployeeProfilesFiltered(userProfiles);

    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('employees/', id[0])
      .then((response) => response.json())
      .then(async (data) => {
        const {
          name,
          lastName,
          email,
          employee_id,
          employeeProfile,
          customFieldsTab,
          profilePermissions,
          locationsTable,
          layoutSelected,
          fileExt,
          assetsAssigned = []
        } = data.response;

        const idUserProfile = employeeProfile
          ? employeeProfile.value || employeeProfile[0].value
          : null;

        if (idUserProfile) {
          getOneDB('employeeProfiles/', idUserProfile)
            .then((response) => response.json())
            .then((data) => {
              if (!data.response) {
                const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
                  key,
                  info: value.info,
                  content: [value.left, value.right]
                }));
                tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
                setTabs(tabs);
                setCustomFieldsTab(customFieldsTab);
                return;
              }

              const { customFieldsTab: parentCustomFields, isAssetRepository } = data.response;
              setIsAssetRepository(isAssetRepository);
              if (parentCustomFields) {
                updateCustomFields(
                  JSON.parse(JSON.stringify(customFieldsTab)),
                  parentCustomFields,
                  (customFields) => {
                    const tabs = Object.entries(customFields).map(([key, value]) => ({
                      key,
                      info: value.info,
                      content: [value.left, value.right]
                    }));
                    tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
                    setTabs(tabs);
                    setCustomFieldsTab(customFields);
                  }
                );
              }
            });

          const onLoadResponse = await executeOnLoadPolicy(
            idUserProfile,
            'employees',
            'list',
            policies,
            data.response
          );
          setCustomFieldsPathResponse(onLoadResponse);
        }

        setProfilePermissions(profilePermissions);
        setResponsabilityLayout((prev) => ({
          ...prev,
          initial: typeof layoutSelected === 'object' ? layoutSelected : {}
        }));
        setLayoutSelected(layoutSelected);
        setProfileSelected(
          employeeProfilesFiltered.find((profile) => profile.value === idUserProfile)
        );
        setLocationsTable(locationsTable || []);
        setValues({
          ...values,
          name,
          lastName,
          layoutSelected,
          email,
          employee_id: employee_id || '',
          imageURL: getImageURL(id, 'employees', fileExt),
          employeeProfile: employeeProfilesFiltered.find(
            (profile) => profile.value === idUserProfile
          )
        });
        setAssetsBeforeSaving(assetsAssigned);
      })
      .catch((error) => console.log(error));
  }, [id, employeeProfileRows, showModal]);

  useEffect(() => {
    filterLocations();
  }, [userLocations]);

  useEffect(() => {
    loadLocations();
  }, []);

  const openModalAssignmentReport = () => {
    if (!layoutSelected) {
      dispatch(
        showCustomAlert({
          open: true,
          message: 'Please select a Responsibility Layout first',
          type: 'warning'
        })
      );
    } else {
      setShowModalReports(true);
    }
  };

  return (
    <>
      <ModalYesNo
        message={confirmationText}
        onCancel={() => {
          setShowAssignedConfirmation(false);
          setAssetsAssigned([]);
        }}
        onOK={() => {
          const parseReassignedRows = assetsAssigned.map(
            ({ id, name, brand, model, assigned, EPC, serial, employeeId, creationDate }) => ({
              ...createAssetRow(id, name, brand, model, assigned, EPC, serial, creationDate),
              employeeId
            })
          );
          setAssetsBeforeSaving((prev) => [...prev, ...parseReassignedRows]);
          setShowAssignedConfirmation(false);
          setAssetsAssigned([]);
        }}
        showModal={showAssignedConfirmation}
        title={GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENT.TITLE')}
      />
      <ModalAssignmentReport
        assetRows={assetsBeforeSaving}
        htmlPreview={htmlPreview}
        layoutSelected={layoutSelected}
        setShowModal={setShowModalReports}
        showModal={showModalReports}
        values={values}
        readOnly={readOnly}
      />
      <CustomRecordModal
        id={id}
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
        key="Modal-Employees"
        readOnly={readOnly}
        showModal={showModal}
        tabs={
          <Tabs
            indicatorColor="primary"
            onChange={handleChange4}
            textColor="primary"
            value={value4}
            variant={tabs.length > 4 ? 'scrollable' : 'fullWidth'}
          >
            <Tab label={GetTranslatedValue('EMPLOYEE.MODAL.EMPLOYEE.TITLE')} />
            <Tab label={GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENTS.TITLE')} />
            {tabs.map((tab, index) => (
              <Tab key={`tab-reference-${index}`} label={tab.info.name} />
            ))}
          </Tabs>
        }
        title="EMPLOYEE.MODAL.CAPTION"
      >
        <SwipeableViews
          axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value4}
          onChangeIndex={handleChangeIndex4}
        >
          <TabContainer4 dir={theme4.direction}>
            <div className="profile-tab-wrapper">
              <ImageUpload
                disabled={readOnly}
                id="employee-image"
                image={values.imageURL}
                setImage={setImage}
                setInitialImage={setInitialImage}
              >
                {GetTranslatedValue('EMPLOYEE.MODAL.EMPLOYEE.PROFILEPHOTO')}
              </ImageUpload>
              <div className="profile-tab-wrapper__content">
                <BaseFields
                  catalogue={'employees'}
                  collection={'employees'}
                  fields={fields}
                  fieldsToValidate={fieldsToValidate}
                  formState={[formValidation, setFormValidation]}
                  localProps={baseFieldsLocalProps}
                  values={values}
                />
              </div>
            </div>
          </TabContainer4>
          <TabContainer4 dir={theme4.direction}>{renderAssignmentTable()}</TabContainer4>
          {tabs.map((tab, tabIndex) => (
            <TabContainer4 dir={theme4.direction} key={`employees-tabIndex-${tabIndex}`}>
              <div className="modal-asset-reference">
                {Array(tab.content[1].length === 0 ? 1 : 2)
                  .fill(0)
                  .map((col, colIndex) => (
                    <div
                      className="modal-asset-reference__list-field"
                      key={`employees-tabIndex-${tabIndex}-col-${colIndex}`}
                    >
                      {tab.content[colIndex].map(
                        (customField, customFieldIndex) => (
                          <CustomFieldsPreview
                            columnIndex={colIndex}
                            customFieldsPathResponse={customFieldsPathResponse}
                            key={`employees-tabIndex-${tabIndex}-col-${colIndex}-field-${customFieldIndex}`}
                            data={tab.content[colIndex]}
                            from="form"
                            id={customField.id}
                            onClick={() =>
                              dispatch(
                                showCustomAlert({
                                  open: true,
                                  message: customField.content,
                                  type: 'info'
                                })
                              )
                            }
                            onDelete={() => {}}
                            onSelect={() => {}}
                            onUpdateCustomField={handleUpdateCustomFields}
                            tab={tab}
                            type={customField.content}
                            values={customField.values}
                            readOnly={readOnly}
                            label={customField.label}
                          />
                        )
                      )}
                    </div>
                  ))}
              </div>
            </TabContainer4>
          ))}
        </SwipeableViews>
      </CustomRecordModal>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(ModalEmployees);
