/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty, uniq } from 'lodash';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/ducks/general.duck';
import * as auth from '../../../../store/ducks/auth.duck';
import {
  postDBEncryptPassword,
  getOneDB,
  getDB,
  updateDB,
  getDBComplex,
  updateManyDB
} from '../../../../crud/api';
import ImageUpload from '../../Components/ImageUpload';
import {
  hosts,
  getFileExtension,
  saveImage,
  getImageURL,
  verifyCustomFields,
  updateCustomFields,
  validateEmail,
  copyObject
} from '../../utils';
import { CustomFieldsPreview } from '../../constants';
import BaseFields from '../../Components/BaseFields/BaseFields';
import LocationAssignment from '../components/LocationAssignment';
import Permissions from '../components/Permissions';
import {
  executeOnFieldPolicy,
  executeOnLoadPolicy,
  executePolicies
} from '../../Components/Policies/utils';
import CustomRecordModal from '../../Components/CustomRecordModal';
import { GetTranslatedValue } from '../../utils';

const { apiHost, serverHost } = hosts;

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalUsers = ({
  fields,
  fieldsToValidate,
  showModal,
  setShowModal,
  readOnly,
  reloadTable,
  id,
  userProfileRows,
  user,
  updateUserPic,
  policies,
  userLocations
}) => {
  const dispatch = useDispatch();
  const {
    showCustomAlert,
    showErrorAlert,
    showFillFieldsAlert,
    showSavedAlert,
    showUpdatedAlert
  } = actions;
  const { fulfillUser } = auth.actions;
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  function handleChangeIndex4(index) {
    setValue4(index);
  }

  const [values, setValues] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    users: [],
    selectedBoss: null,
    selectedUserProfile: null,
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg'
  });
  const [customFieldsPathResponse, setCustomFieldsPathResponse] = useState();

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const RepeatedEmail = (email) => {
    return getDBComplex({
      collection: 'user',
      condition: [{ email: email }]
    })
      .then((response) => response.json())
      .then((data) => {
        const repeatedMailUsers = data.response.filter(({ _id }) => _id !== id[0]);
        return !isEmpty(repeatedMailUsers);
      })
      .catch((error) => console.log('error:', error));
  };

  const addNewMemberToGroups = (newMember) => {
    return Promise.all(
      addedGroups.map(({ value }) => {
        const groupFounded = allGroups.find(({ _id: groupId }) => groupId === value);
        const { _id: groupId } = groupFounded;
        const members = [...groupFounded.members, newMember];

        return updateDB(
          'settingsGroups/',
          {
            members
          },
          groupId
        ).catch((error) => console.log(error));
      })
    );
  };

  const updateGroups = (member) => {
    const groupsToUpdate = selectedGroups.filter(
      (group) =>
        addedGroups.findIndex(({ value }) => value === group.value) === -1 &&
        removedGroups.findIndex(({ value }) => value === group.value) === -1
    );

    return Promise.all(
      groupsToUpdate.map(({ value }) => {
        const groupFounded = allGroups.find(({ _id: groupId }) => groupId === value);
        const { _id: groupId } = groupFounded;
        const filteredMembers = groupFounded.members.filter(
          ({ value: userId }) => userId !== id[0]
        );
        const members = [...filteredMembers, member];
        return updateDB('settingsGroups/', { members }, groupId).catch((error) =>
          console.log(error)
        );
      })
    );
  };

  const removeMemberToGroups = () => {
    return Promise.all(
      removedGroups.map(({ value }) => {
        const groupFounded = allGroups.find(({ _id: groupId }) => groupId === value);
        const { _id: groupId } = groupFounded;
        const members = groupFounded.members.filter(({ value: userId }) => userId !== id[0]);

        return updateDB(
          'settingsGroups/',
          {
            members
          },
          groupId
        ).catch((error) => console.log(error));
      })
    );
  };

  const updateProcessesLiveInfo = (userId, body) => {
    return getDBComplex({
      collection: 'processLive',
      condition: [
        {
          "processData.stages": {
            $elemMatch: {
              $or: [
                { approvals: { $elemMatch: { _id: userId } } },
                { notifications: { $elemMatch: { _id: userId } } }
              ]
            }
          }
        },
        { "processData.processStatus": "inProcess" }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          return Promise.all(
            data.response.map((folio) => {
              const { _id: processId, processData } = folio;
              const copy = copyObject(processData);

              const user = {
                email: body.email,
                name: body.name,
                lastName: body.lastName
              };

              copy.stages.forEach(({ approvals, notifications }, index) => {
                const notificationsIndex = notifications?.findIndex(({ _id }) => _id === userId);
                const approvalsIndex = approvals?.findIndex(({ _id }) => _id === userId);

                if (notificationsIndex !== -1) {
                  processData.stages[index].notifications[notificationsIndex] = {
                    ...processData.stages[index].notifications[notificationsIndex],
                    ...user
                  };
                }

                if (approvalsIndex !== -1) {
                  processData.stages[index].approvals[approvalsIndex] = {
                    ...processData.stages[index].approvals[approvalsIndex],
                    ...user
                  };
                }
              });

              return updateDB('processLive/', { processData }, processId)
                .catch((error) => console.log(error));
            })
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const updateProcessesInfo = (userId, body) => {
    return getDBComplex({
      collection: 'processes',
      condition: [
        {
          processStages: {
            $elemMatch: {
              $or: [
                { approvals: { $elemMatch: { _id: userId } } },
                { notifications: { $elemMatch: { _id: userId } } }
              ]
            }
          }
        }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          return Promise.all(
            data.response.map((process) => {
              const { _id: processId, processStages } = process;
              const copy = copyObject(processStages);

              const user = {
                email: body.email,
                name: body.name,
                lastName: body.lastName
              };

              copy.forEach(({ approvals, notifications }, index) => {
                const notificationsIndex = notifications?.findIndex(({ _id }) => _id === userId);
                const approvalsIndex = approvals?.findIndex(({ _id }) => _id === userId);

                if (notificationsIndex !== -1) {
                  processStages[index].notifications[notificationsIndex] = {
                    ...processStages[index].notifications[notificationsIndex],
                    ...user
                  };
                }

                if (approvalsIndex !== -1) {
                  processStages[index].approvals[approvalsIndex] = {
                    ...processStages[index].approvals[approvalsIndex],
                    ...user
                  };
                }
              });

              return updateDB('processes/', { processStages }, processId)
                .catch((error) => console.log(error));
            })
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const updateProcessesStagesInfo = (userId, body) => {
    return getDBComplex({
      collection: 'processStages',
      condition: [
        {
          $or: [
            { approvals: { $elemMatch: { _id: userId } } },
            { notifications: { $elemMatch: { _id: userId } } }
          ]
        }
      ]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          return Promise.all(
            data.response.map((stage) => {
              const { notifications, approvals } = stage;

              const user = {
                email: body.email,
                name: body.name,
                lastName: body.lastName
              };

              const notificationsIndex = notifications?.findIndex(({ _id }) => _id === userId);
              const approvalsIndex = approvals?.findIndex(({ _id }) => _id === userId);

              if (notificationsIndex !== -1) {
                notifications[notificationsIndex] = {
                  ...notifications[notificationsIndex],
                  ...user
                };
              }

              if (approvalsIndex !== -1) {
                approvals[approvalsIndex] = { ...approvals[approvalsIndex], ...user };
              }

              return updateDB(
                'processStages/',
                { notifications, approvals },
                stage._id
              )
                .catch((error) => console.log(error));
            })
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const updateUserRelatedInfo = ({ collection, body, field, userId }) => {
    updateManyDB({
      body: { [field]: body },
      collection,
      condition: [{ [`${field}.value`]: userId }]
    });
  };

  const updateUsersInReports = ({ body, userId }) => {
    getDBComplex({
      collection: 'reports',
      condition: [{ to: { $elemMatch: { _id: userId } } }]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          data.response.forEach(({ _id: reportId, to }) => {
            const index = to.findIndex(({ _id }) => _id === userId);
            to.splice(index, 1, body);

            updateDB('reports/', { to }, reportId).catch((error) => dispatch(showErrorAlert()));
          });
        }
      })
      .catch((error) => dispatch(showErrorAlert()));

    getDBComplex({
      collection: 'reports',
      condition: [{ from: { $elemMatch: { _id: userId } } }]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.response) {
          data.response.forEach(({ _id: reportId, from }) => {
            const index = from.findIndex(({ _id }) => _id === userId);
            from.splice(index, 1, body);

            updateDB('reports/', { from }, reportId).catch((error) => dispatch(showErrorAlert()));
          });
        }
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const handleSave = async () => {
    setFormValidation({ ...formValidation, enabled: true });
    const validForm = { ...formValidation.isValidForm };

    if (id) {
      delete validForm['password'];
    }

    const repeatedEmail = await RepeatedEmail(values.email);

    if (repeatedEmail) {
      dispatch(
        showCustomAlert({
          open: true,
          message: 'This email belongs to another user, please choose a different one',
          type: 'warning'
        })
      );
      return;
    }

    if (isEmpty(locationsTable)) {
      dispatch(
        showCustomAlert({
          open: true,
          message: 'Please assign a location to this user',
          type: 'warning'
        })
      );
      return;
    }

    if (!isEmpty(validForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (!verifyCustomFields(customFieldsTab)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    let userGroups = [];

    selectedGroups.forEach(({ value: id, name }) => {
      if (removedGroups.findIndex(({ value }) => value === id) !== -1) {
        return;
      }

      userGroups.push({ value: id, name });
    });

    if (!isEmpty(values.selectedBoss)) {
      const { name, lastName } = allUsers.find(({ value }) => value === values.selectedBoss.value);
      values.selectedBoss = { ...values.selectedBoss, name, lastName };
    }

    const fileExt = getFileExtension(image);
    const body = {
      ...values,
      customFieldsTab,
      profilePermissions,
      locationsTable,
      fileExt,
      groups: userGroups,
      selectedUserProfile: values.selectedUserProfile,
      idUserProfile
    };

    if (!isEmpty(body.password)) {
      body.passwordChange = new Date().valueOf();
    }

    if (!id) {
      await postDBEncryptPassword('user', body)
        .then((data) => data.json())
        .then(async (response) => {
          dispatch(showSavedAlert());
          const { _id, email, name, lastName } = response.response[0];
          saveAndReload('user', _id);
          updateLocationsAssignments(locationsTable, {
            userId: _id,
            email,
            name,
            lastName
          });
          executePolicies('OnAdd', 'user', 'list', policies, response.response[0]);
          executeOnFieldPolicy('OnAdd', 'user', 'list', policies, response.response[0]);

          if (!isEmpty(addedGroups)) {
            const member = {
              value: _id,
              label: email,
              name,
              lastName
            };
            await addNewMemberToGroups(member);
          }
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      await updateDB('user/', body, id[0])
        .then((response) => response.json())
        .then(async (data) => {
          const {
            response: { value }
          } = data;
          const user = { _id: id[0], ...body };
          const member = {
            value: id[0],
            label: body.email,
            name: body.name,
            lastName: body.lastName
          };

          if (
            value?.email !== body.email ||
            value?.name !== body.name ||
            value?.lastName !== body.lastName
          ) {
            const userBody = {
              value: id[0],
              label: body.email,
              name: body.name,
              lastName: body.lastName
            };

            updateUserRelatedInfo({
              body: userBody,
              collection: 'settingsAssetSpecialists',
              field: 'userSelected',
              userId: id[0]
            });
            updateUserRelatedInfo({
              body: userBody,
              collection: 'settingsWitnesses',
              field: 'userSelected',
              userId: id[0]
            });
            updateUserRelatedInfo({
              body: {
                value: id[0],
                label: body.email,
                name: body.name,
                lastName: body.lastName
              },
              collection: 'user',
              field: 'selectedBoss',
              userId: id[0]
            });
            updateUsersInReports({
              body: {
                _id: id[0],
                email: body.email,
                name: body.name,
                lastName: body.lastName
              },
              userId: id[0]
            });
            updateGroups(member);
            updateProcessesStagesInfo(id[0], body);
            updateProcessesInfo(id[0], body);
            updateProcessesLiveInfo(id[0], body);
          }

          dispatch(showUpdatedAlert());
          updateLocationsAssignments(locationsTable, {
            userId: id[0],
            name: body.name,
            email: body.email,
            lastName: body.lastName
          });
          executePolicies('OnEdit', 'user', 'list', policies, user);
          executeOnFieldPolicy('OnEdit', 'user', 'list', policies, user, value);
          saveAndReload('user', id[0]);

          await addNewMemberToGroups(member);
          await removeMemberToGroups();
        })
        .catch((error) => {});
    }

    handleCloseModal();

    if (id) {
      if (initialProfilePermissions !== body.profilePermissions && id[0] === user.id) {
        dispatch(
          fulfillUser({
            ...user,
            name: body.name,
            lastName: body.lastName,
            email: body.email,
            fullName: `${body.name} ${body.lastName}`,
            profilePermissions: body.profilePermissions
          })
        );

        if (initialImage !== image) {
          updateCurrentUserPic(id[0], fileExt);
        }

        setTimeout(() => window.location.reload(), 1000);
      } else if (id[0] === user.id) {
        dispatch(
          fulfillUser({
            ...user,
            name: body.name,
            lastName: body.lastName,
            fullname: `${body.name} ${body.lastName}`,
            email: body.email
          })
        );
        updateCurrentUserPic(id[0], fileExt);
      }
    }
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }

    reloadTable();
  };
  const updateLocationsAssignments = (locationsTable = [], assignedTo) => {
    locationsTable.forEach(({ parent: locationId }) => {
      updateDB('locationsReal/', { assignedTo }, locationId).catch((error) => console.log(error));
    });
  };
  const updateCurrentUserPic = (editId, fileExt) => {
    if (user.id === editId) {
      let _v = getRandomArbitrary(1, 999);
      const defaultPic = `${serverHost}/media/misc/placeholder-image.jpg?v=${_v}`;
      const pic = fileExt ? `${apiHost}/uploads/user/${editId}.${fileExt}?v=${_v}` : defaultPic;
      setTimeout(() => updateUserPic(defaultPic), 250);
      setTimeout(() => updateUserPic(pic), 1000);
    }
  };

  function getRandomArbitrary(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
  }

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setProfilePermissions({});
    setTabs([]);
    setValues({
      name: '',
      lastName: '',
      email: '',
      password: '',
      selectedUserProfile: null,
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg'
    });
    setShowModal(false);
    setValue4(0);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
    setSelectedGroups([]);
    setRemovedGroups([]);
    setAddedGroups([]);
    setUserInitialGroups([]);
    setInitialProfilePermissions({});
    setLocationsTable([]);
    window.history.replaceState({}, null, `${window.location.origin}/users`);
  };

  const [userProfilesFiltered, setUserProfilesFiltered] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allFormattedGroups, setAllFormattedGroups] = useState([]);
  const [userInitialGroups, setUserInitialGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [removedGroups, setRemovedGroups] = useState([]);
  const [addedGroups, setAddedGroups] = useState([]);

  useEffect(() => {
    if (!id || !Array.isArray(id)) {
      return;
    }
  }, []);

  useEffect(() => {
    const userProfiles = userProfileRows.map((profile, ix) => ({
      value: profile.id,
      label: profile.name
    }));
    setUserProfilesFiltered(userProfiles);

    if (showModal) {
      loadInit();
    }

    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('user/', id[0])
      .then((response) => response.json())
      .then(async (data) => {
        const {
          name,
          lastName,
          email,
          groups,
          customFieldsTab,
          profilePermissions,
          idUserProfile,
          locationsTable,
          fileExt,
          selectedBoss,
          selectedUserProfile
        } = data.response;

        const profileId = selectedUserProfile?.value || idUserProfile;
        const onLoadResponse = await executeOnLoadPolicy(
          profileId,
          'user',
          'list',
          policies,
          data.response
        );
        setCustomFieldsPathResponse(onLoadResponse);

        getOneDB('userProfiles/', profileId)
          .then((response) => response.json())
          .then((data) => {
            if (!data.response) {
              const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
                key,
                info: value.info,
                content: [value.left, value.right]
              }));
              tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
              setTabs(tabs);
              setCustomFieldsTab(customFieldsTab);
              return;
            }

            const { customFieldsTab: parentCustomFields } = data.response;
            if (parentCustomFields) {
              updateCustomFields(
                JSON.parse(JSON.stringify(customFieldsTab)),
                parentCustomFields,
                (customFields) => {
                  const tabs = Object.entries(customFields).map(([key, value]) => ({
                    key,
                    info: value.info,
                    content: [value.left, value.right]
                  }));
                  tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
                  setTabs(tabs);
                  setCustomFieldsTab(customFields);
                }
              );
            }
          });

        setProfilePermissions(profilePermissions);
        setInitialProfilePermissions(profilePermissions);
        const profile = userProfilesFiltered.find((profile) => profile.value === profileId);
        setLocationsTable(locationsTable || []);
        setValues({
          ...values,
          name,
          lastName,
          email,
          groups,
          imageURL: getImageURL(id, 'user', fileExt),
          selectedBoss,
          selectedUserProfile: profile
        });
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [id, showModal, userProfileRows]);

  const loadInit = () => {
    getDB('user')
      .then((response) => response.json())
      .then((data) => {
        const users = data.response.map(({ _id: value, email: label, name, lastName }) => ({
          value,
          label,
          name,
          lastName
        }));
        setAllUsers(users);

        if (Array.isArray(id)) {
          const { groups } = data.response.find(({ _id }) => _id === id[0]);
          const userGroups = groups.map(({ id, value, name: label }) => ({
            value: id || value,
            label
          }));

          setSelectedGroups(userGroups);
          setUserInitialGroups(userGroups);
        }
      })
      .catch((error) => console.log(error));
    getDB('settingsGroups')
      .then((response) => response.json())
      .then((data) => {
        const { response } = data;
        const groups = response.map(({ _id: value, name }) => ({
          value,
          name
        }));
        setAllFormattedGroups(groups);
        setAllGroups(response);
      })
      .catch((error) => console.log(error));
  };

  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [profilePermissions, setProfilePermissions] = useState({});
  const [initialProfilePermissions, setInitialProfilePermissions] = useState({});
  const [tabs, setTabs] = useState([]);
  const [locationsTable, setLocationsTable] = useState([]);
  const [idUserProfile, setIdUserProfile] = useState('');

  const onChangeUserProfile = (e) => {
    if (!e) {
      setCustomFieldsTab({});
      setProfilePermissions({});
      setTabs([]);
      setIdUserProfile(null);
      setValues((prev) => ({ ...prev, selectedUserProfile: null }));

      return;
    }

    getOneDB('userProfiles/', e.value)
      .then((response) => response.json())
      .then((data) => {
        const { customFieldsTab, profilePermissions } = data.response;
        const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
          key,
          info: value.info,
          content: [value.left, value.right]
        }));
        tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
        setCustomFieldsTab(customFieldsTab);
        setProfilePermissions(profilePermissions);
        setTabs(tabs);
        setValues((prev) => ({ ...prev, selectedUserProfile: e }));
        setIdUserProfile(e.value);
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const handleUpdateCustomFields = (tab, id, colIndex, CFValues) => {
    if (isEmpty(customFieldsTab)) {
      return;
    }
    const colValue = ['left', 'right'];
    const customFieldsTabTmp = { ...customFieldsTab };
    const field = customFieldsTabTmp[tab][colValue[colIndex]].find((cf) => cf.id === id) || {};
    field.values = CFValues;
  };

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });

  const handleGroupsChange = (groups) => {
    const groupsUpdated = groups || [];
    if (selectedGroups.length > groupsUpdated.length) {
      const groupRemoved = selectedGroups.find((group) => !groupsUpdated.includes(group));
      const userInitialGroupIndex = userInitialGroups.findIndex(
        ({ value }) => groupRemoved.value === value
      );

      if (userInitialGroupIndex >= 0) {
        setRemovedGroups((prev) => uniq([...prev, groupRemoved]));
      }
    } else {
      const groupAssigned = groupsUpdated.find((group) => !selectedGroups.includes(group));
      const userInitialGroupsIndex = userInitialGroups.findIndex(
        ({ value }) => groupAssigned.value === value
      );

      setRemovedGroups((prev) => prev.filter(({ value }) => value !== groupAssigned.value));

      if (userInitialGroupsIndex === -1) {
        setAddedGroups((prev) => uniq([...prev, groupAssigned]));
      }
    }
    setSelectedGroups(groupsUpdated);
    setValues((prev) => ({
      ...prev,
      groups: groupsUpdated.length ? groupsUpdated : null
    }));
  };

  const baseFieldsLocalProps = {
    userProfile: {
      ownValidFn: () => !!values.selectedUserProfile,
      componentProps: {
        isClearable: true,
        onChange: onChangeUserProfile,
        options: userProfilesFiltered,
        value: values.selectedUserProfile,
        isDisabled: readOnly
      }
    },
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: {
          readOnly
        }
      }
    },
    lastName: {
      componentProps: {
        onChange: handleChange('lastName'),
        inputProps: {
          readOnly
        }
      }
    },
    email: {
      ownValidFn: () => validateEmail(values.email),
      componentProps: {
        onChange: handleChange('email'),
        inputProps: {
          readOnly
        },
        type: 'email'
      }
    },
    password: {
      componentProps: {
        onChange: handleChange('password'),
        hidden:
          !id || !Array.isArray(id)
            ? false
            : !user?.profilePermissions?.users?.others?.includes('passwordChange'),
        inputProps: {
          readOnly
        }
      }
    },
    boss: {
      componentProps: {
        isClearable: true,
        onChange: (selectedBoss) => setValues((prev) => ({ ...prev, selectedBoss })),
        value: values.selectedBoss,
        options: allUsers,
        isDisabled: readOnly
      }
    },
    groups: {
      componentProps: {
        isClearable: true,
        isMulti: true,
        getOptionLabel: (group) => group?.name,
        getOptionValue: (group) => group?.value,
        label: 'Groups',
        onChange: handleGroupsChange,
        options: allFormattedGroups,
        value: values.groups,
        isDisabled: readOnly
      }
    }
  };

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Modal-Users"
      readOnly={readOnly}
      showModal={showModal}
      tabs={
        <Tabs
          value={value4}
          onChange={handleChange4}
          indicatorColor="primary"
          textColor="primary"
          variant={tabs.length > 3 ? 'scrollable' : 'fullWidth'}
        >
          <Tab label={GetTranslatedValue('USER.LIST.MODAL.USER.TITLE')} />
          <Tab label={GetTranslatedValue('USER.LIST.MODAL.PERMISSIONS.TITLE')} />
          <Tab label={GetTranslatedValue('USER.LIST.MODAL.LOCATIONS.TITLE')} />
          {tabs.map((tab, index) => (
            <Tab key={`tab-reference-${index}`} label={tab.info.name} />
          ))}
        </Tabs>
      }
      title="USERS.MODAL.CAPTION"
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value4}
        onChangeIndex={handleChangeIndex4}
      >
        <TabContainer4 dir={theme4.direction}>
          <div className="profile-tab-wrapper">
            <ImageUpload
              disabled={readOnly}
              id="user-image"
              image={values.imageURL}
              setImage={setImage}
              setInitialImage={setInitialImage}
            >
              {GetTranslatedValue('USER.LIST.MODAL.USER.PHOTO')}
            </ImageUpload>
            <div className="profile-tab-wrapper__content">
              <BaseFields
                catalogue={'userList'}
                collection={'user'}
                fields={fields}
                fieldsToValidate={fieldsToValidate}
                formState={[formValidation, setFormValidation]}
                localProps={baseFieldsLocalProps}
                noPassword={id || Array.isArray(id)}
                values={values}
              />
            </div>
          </div>
        </TabContainer4>
        <TabContainer4 dir={theme4.direction}>
          <Permissions
            key="modal-user-permissions"
            profilePermissions={profilePermissions}
            readOnly={readOnly}
            setProfilePermissions={setProfilePermissions}
          />
        </TabContainer4>
        <TabContainer4 dir={theme4.direction}>
          <LocationAssignment
            locationsTable={locationsTable}
            setLocationsTable={setLocationsTable}
            userID={id}
            userLocations={userLocations}
          />
        </TabContainer4>
        {tabs.map((tab) => (
          <TabContainer4 dir={theme4.direction}>
            <div className="modal-asset-reference">
              {Array(tab.content[1].length === 0 ? 1 : 2)
                .fill(0)
                .map((col, colIndex) => (
                  <div className="modal-asset-reference__list-field">
                    {tab.content[colIndex].map((customField) => (
                      <CustomFieldsPreview
                        columnIndex={colIndex}
                        customFieldsPathResponse={customFieldsPathResponse}
                        data={tab.content[colIndex]}
                        from="form"
                        id={customField.id}
                        onClick={() => alert(customField.content)}
                        onDelete={() => {}}
                        onSelect={() => {}}
                        onUpdateCustomField={handleUpdateCustomFields}
                        tab={tab}
                        type={customField.content}
                        values={customField.values}
                        readOnly={readOnly}
                        label={customField.label}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </TabContainer4>
        ))}
      </SwipeableViews>
    </CustomRecordModal>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps, auth.actions)(ModalUsers);
