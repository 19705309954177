import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';
import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';
import { filterObject } from '../../utils/utils';
import { hasAnyReportCollectionToGenerate } from '../../../app/pages/home/constants';

class MenuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Tabs: []
    };
  }

  componentDidMount() {
    if (this.props.user.profilePermissions) {
      const tabs = Object.keys(
        filterObject(this.props.user.profilePermissions, (element) =>
          Object.entries(element).find(([key, value]) => {
            const isReportsGeneral = key === 'general';

            if (isReportsGeneral) {
              return hasAnyReportCollectionToGenerate(value);
            } else {
              return !isEmpty(value);
            }
          })
        )
      );
      this.setState({ Tabs: tabs });
    }
  }

  render() {
    const { currentUrl, menuConfig, layoutConfig } = this.props;

    if (isEmpty(this.state.Tabs)) {
      return (
        <Typography variant="h6" style={{ textAlign: 'center', padding: '0px 20px' }}>
          oops! It seems that you do not have permission to any module. Please contact your
          administrator to solve this issue.
        </Typography>
      );
    }

    return menuConfig.aside.items.map((child, index) => {
      return (
        <React.Fragment key={`menuList${index}`}>
          {child.section && <MenuSection item={child} />}
          {child.separator && <MenuItemSeparator item={child} />}
          {child.title &&
            this.state.Tabs.includes(child.page) && (
              <MenuItem
                item={child}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
                appColors={this.props.appColors}
              />
            )}
        </React.Fragment>
      );
    });
  }
}

const mapStateToProps = ({ auth: { user }, design: { appColors } }) => ({
  user,
  appColors
});

export default connect(mapStateToProps)(MenuList);
