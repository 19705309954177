import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { Tab } from '@material-ui/core';
import { hasAnyReportCollectionToGenerate } from '../../constants';
import { tabsConfig } from './constants';

export const TabsTitles = (module, permissions = []) => {
  const intl = useIntl();
  const [titles, setTitles] = useState([]);
  const userPermissions = permissions || [];

  useEffect(() => {
    const tabs = tabsConfig.modules.find((tab) => tab.name === module);

    if (tabs) {
      const titles = tabs.titles.map(
        ({ label }) => tabsConfig.titles.filter((tabTitle) => tabTitle.title === label)[0]
      );
      setTitles(titles);
    }
  }, [module]);

  return titles.map(({ title, translate }, index) => {
    const isReportsGeneral = module === 'reports' && title === 'General';
    const hasAnyReportPermission = hasAnyReportCollectionToGenerate(userPermissions['general']);

    const tabKey = tabsConfig.modules.find(({ name }) => module === name)?.titles[index]?.value;

    return (
      <Tab
        label={!translate ? title : intl.formatMessage({ id: translate, defaultMessage: title })}
        style={{
          display:
            (!isEmpty(userPermissions[tabKey] || []) && !isReportsGeneral) ||
              (isReportsGeneral && hasAnyReportPermission)
              ? null
              : ' none'
        }}
        key={`kpi-icon-div-${title}`}
      />
    );
  });
};

export const GenerateSubTabs = (module, subTab, isSubTab) => {
  const intl = useIntl();
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    const subtitles = tabsConfig.modules.find((tab) => tab.name === module)?.subtitles || {};
    const tab = isSubTab && subtitles[subTab].find(({ title }) => title === isSubTab);
    const tabs = Object.entries(tab?.subTabs || subtitles[subTab]).map(([key, value]) => value);

    if (tabs) {
      setTitles(tabs);
    }
  }, [module]);

  return titles.map(({ title, translate }) => (
    <Tab
      label={!translate ? title : intl.formatMessage({ id: translate, defaultMessage: title })}
      key={`kpi-icon-div-${title}`}
    />
  ));
};
