import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#2D2D2DF2',
    color: '#ffffff',
    maxWidth: 400,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(Tooltip);

const CustomizedTooltips = ({ tooltipContent, content }) => {
  return (
    <div>
      <HtmlTooltip
        title={
          <>
            {tooltipContent}
          </>
        }
      >
        {content}
      </HtmlTooltip>
    </div>
  );
};

export default CustomizedTooltips;
