/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Typography, Tab, Tabs, InputAdornment } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../store/ducks/general.duck';
import ImageUpload from '../../Components/ImageUpload';
import { GetTranslatedValue, updateAssetInfoToRelated } from '../../utils';
import { postDB, getOneDB, updateManyDB, updateDB, getDB, getDBComplex } from '../../../../crud/api';
import {
  getFileExtension,
  saveImage,
  getImageURL,
  verifyCustomFields,
  updateCustomFields
} from '../../utils';
import { CustomFieldsPreview } from '../../constants';
import {
  executeOnFieldPolicy,
  executeOnLoadPolicy,
  executePolicies
} from '../../Components/Policies/utils';
import './ModalAssetCategories.scss';
import './ModalAssetReferences.scss';
import BaseFields from '../../Components/BaseFields/BaseFields';
import CustomRecordModal from '../../Components/CustomRecordModal';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalAssetReferences = ({
  fields,
  fieldsToValidate,
  readOnly,
  showModal,
  setShowModal,
  reloadTable,
  id,
  policies
}) => {
  const dispatch = useDispatch();
  const { showErrorAlert, showFillFieldsAlert, showSavedAlert, showUpdatedAlert } = actions;
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  const [categories, setCategories] = useState([]);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  function handleChangeIndex4(index) {
    setValue4(index);
  }

  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });

  const [values, setValues] = useState({
    name: '',
    brand: '',
    model: '',
    price: '0',
    depreciation: 0,
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg',
    selectedProfile: ''
  });
  const [customFieldsTab, setCustomFieldsTab] = useState({});
  const [tabs, setTabs] = useState([]);
  const [customFieldsPathResponse, setCustomFieldsPathResponse] = useState();

  const handleChange = (name) => (event) => {
    const value = name === 'selectedProfile' ? event : event.target.value;

    if (name === 'selectedProfile') {
      setValues((prev) => ({ ...prev, [name]: value, category: value }));
    } else {
      setValues((prev) => ({ ...prev, [name]: value }));
    }

    if (name === 'selectedProfile') {
      handleLoadCustomFields(value.value);
      if (!value) {
        setValues((prev) => ({ ...prev, depreciation: 0 }));
        setCustomFieldsTab({});
      }
    }
  };

  const handleLoadCustomFields = (profile) => {
    getOneDB('categories/', profile)
      .then((response) => response.json())
      .then((data) => {
        const { customFieldsTab, depreciation } = data.response;
        const tabs = Object.keys(customFieldsTab).map((key) => ({
          key,
          info: customFieldsTab[key].info,
          content: [customFieldsTab[key].left, customFieldsTab[key].right]
        }));
        tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());

        setCustomFieldsTab(customFieldsTab);
        setValues((prev) => ({ ...prev, depreciation }));
        setTabs(tabs);
      })
      .catch((error) => dispatch(showErrorAlert()));
  };

  const updateReferenceChildrenInfo = (referenceId, body) => {
    getDBComplex({
      collection: 'assets',
      condition: [{ "referenceId": referenceId }]
    })
      .then((response) => response.json())
      .then((data) => {
        updateManyDB({
          body,
          collection: 'assets',
          condition: [{ "referenceId": referenceId }]
        })
          .catch((error) => console.log(error));

        if (data?.response && !isEmpty(data.response)) {
          data.response.forEach(({ _id: assetId, serial, EPC, creationDate }) => {
            updateAssetInfoToRelated({
              collection: 'assets',
              searchField: 'children',
              asset: {
                id: assetId,
                name: body.name,
                brand: body.brand,
                model: body.model,
                EPC,
                serial,
                creationDate
              }
            });
            updateAssetInfoToRelated({
              collection: 'employees',
              searchField: 'assetsAssigned',
              asset: {
                id: assetId,
                name: body.name,
                brand: body.brand,
                model: body.model,
                EPC,
                serial,
                creationDate
              }
            });
          })
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSave = () => {
    setFormValidation({ ...formValidation, enabled: true });
    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    if (!verifyCustomFields(customFieldsTab)) {
      dispatch(showFillFieldsAlert());
      return;
    }

    const fileExt = getFileExtension(image);

    const body = {
      ...values,
      price: values.price.toString(),
      customFieldsTab,
      fileExt
    };

    if (!id) {
      postDB('references', body, 'profiles')
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('references', _id);
          executePolicies('OnAdd', 'assets', 'references', policies, response.response[0]);
          executeOnFieldPolicy('OnAdd', 'assets', 'references', policies, response.response[0]);
        })
        .catch((error) => dispatch(showErrorAlert()));
    } else {
      updateDB('references/', body, id[0])
        .then((response) => response.json())
        .then((data) => {
          const {
            response: { value }
          } = data;
          const reference = { _id: id[0], ...body };

          if (value?.name !== body.name || value?.brand !== body.brand || value?.model !== body.model) {
            updateReferenceChildrenInfo(
              id[0],
              {
                name: body.name,
                brand: body.brand,
                model: body.model
              }
            );
          }

          dispatch(showUpdatedAlert());
          saveAndReload('references', id[0]);
          executePolicies('OnEdit', 'assets', 'references', policies, reference);
          executeOnFieldPolicy('OnEdit', 'assets', 'references', policies, reference, value);
        })
        .catch((error) => console.log(error));
    }
    handleCloseModal();
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }
    reloadTable();
  };

  const baseFieldsLocalProps = {
    category: {
      componentProps: {
        onChange: handleChange('selectedProfile'),
        options: categories,
        value: values.selectedProfile,
        isDisabled: readOnly
      }
    },
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: {
          readOnly
        }
      }
    },
    brand: {
      componentProps: {
        onChange: handleChange('brand'),
        inputProps: {
          readOnly
        }
      }
    },
    model: {
      componentProps: {
        onChange: handleChange('model'),
        inputProps: {
          readOnly
        }
      }
    },
    price: {
      ownValidFn: () => !!values.price || values.price === 0,
      componentProps: {
        onChange: handleChange('price'),
        value: Number(values.price),
        type: 'number',
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        },
        inputProps: {
          readOnly
        }
      }
    },
    depreciation: {
      ownValidFn: () => !!values.depreciation || values.depreciation === 0,
      componentProps: {
        onchange: handleChange('depreciation'),
        type: 'number',
        disabled: true,
        inputProps: {
          readOnly
        }
      }
    }
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setValues({
      name: '',
      brand: '',
      model: '',
      price: '0',
      depreciation: '0',
      categoryPic: '/media/misc/placeholder-image.jpg',
      categoryPicDefault: '/media/misc/placeholder-image.jpg',
      selectedProfile: ''
    });
    setShowModal(false);
    setValue4(0);
    setTabs([]);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
    setImage(null);
    setCategories([]);
  };

  useEffect(() => {
    if (!showModal) {
      return;
    }

    getDB('categories/')
      .then((response) => response.json())
      .then((data) => {
        const profiles = data.response.map(({ _id: value, name: label }) => ({
          value,
          label
        }));
        setCategories(profiles);
      })
      .catch((error) => console.log(error));

    if (!id || !Array.isArray(id)) {
      return;
    }

    getOneDB('references/', id[0])
      .then((response) => response.json())
      .then(async (data) => {
        const {
          name,
          brand,
          model,
          price,
          depreciation,
          customFieldsTab,
          fileExt,
          selectedProfile
        } = data.response;
        const { value } = selectedProfile;
        const onLoadResponse = await executeOnLoadPolicy(
          value,
          'assets',
          'references',
          policies,
          data.response
        );
        setCustomFieldsPathResponse(onLoadResponse);
        setValues({
          ...values,
          name,
          brand,
          model,
          price,
          depreciation,
          imageURL: getImageURL(id, 'references', fileExt),
          selectedProfile
        });

        getOneDB('categories/', selectedProfile.value)
          .then((response) => response.json())
          .then((data) => {
            if (!data.response) {
              const tabs = Object.entries(customFieldsTab).map(([key, value]) => ({
                key,
                info: value.info,
                content: [value.left, value.right]
              }));
              tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
              setTabs(tabs);
              setCustomFieldsTab(customFieldsTab);
              return;
            }
            
            const { customFieldsTab: parentCustomFields } = data.response;
            if (parentCustomFields) {
              updateCustomFields(
                JSON.parse(JSON.stringify(customFieldsTab)),
                parentCustomFields,
                (customFields) => {
                  const tabs = Object.entries(customFields).map(([key, value]) => ({
                    key,
                    info: value.info,
                    content: [value.left, value.right]
                  }));
                  tabs.sort((a, b) => a.key.split('-').pop() - b.key.split('-').pop());
                  setTabs(tabs);
                  setCustomFieldsTab(customFields);
                }
              );
            }
          });
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [showModal]);

  // Function to update customFields
  const handleUpdateCustomFields = (tab, id, colIndex, CFValues) => {
    if (isEmpty(customFieldsTab)) {
      return;
    }
    const colValue = ['left', 'right'];
    const customFieldsTabTmp = { ...customFieldsTab };

    const field = customFieldsTabTmp[tab][colValue[colIndex]].find((cf) => cf.id === id) || {};
    field.values = CFValues;
  };

  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Modal-References"
      readOnly={readOnly}
      showModal={showModal}
      tabs={
        <Tabs
          value={value4}
          onChange={handleChange4}
          indicatorColor="primary"
          textColor="primary"
          variant={tabs.length > 5 ? 'scrollable' : 'fullWidth'}
        >
          <Tab label={GetTranslatedValue('REFERENCES.MODAL.CAPTION', 'Reference')} />
          {tabs.map((tab, index) => (
            <Tab key={`tab-reference-${index}`} label={tab.info.name} />
          ))}
        </Tabs>
      }
      title="REFERENCES.MODAL.CAPTION"
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value4}
        onChangeIndex={handleChangeIndex4}
      >
        <TabContainer4 dir={theme4.direction}>
          <div className="profile-tab-wrapper">
            <ImageUpload
              disabled={readOnly}
              id="asset-reference-image"
              image={values.imageURL}
              setImage={setImage}
              setInitialImage={setInitialImage}
            >
              {GetTranslatedValue('REFERENCES.MODAL.PHOTO', 'Asset Reference Photo')}
            </ImageUpload>
            <div className="profile-tab-wrapper__content">
              <BaseFields
                catalogue={'references'}
                collection={'references'}
                fields={fields}
                fieldsToValidate={fieldsToValidate}
                formState={[formValidation, setFormValidation]}
                localProps={baseFieldsLocalProps}
                values={values}
              />
            </div>
          </div>
        </TabContainer4>
        {tabs.map((tab, tabIndex) => (
          <TabContainer4 dir={theme4.direction} key={`reference-tab-index-${tabIndex}`}>
            <div className="modal-asset-reference">
              {Array(tab.content[1].length === 0 ? 1 : 2)
                .fill(0)
                .map((col, colIndex) => (
                  <div
                    className="modal-asset-reference__list-field"
                    key={`reference-fields-list-tab-${tabIndex}-colIndex-${colIndex}`}
                  >
                    {tab.content[colIndex].map((customField, fieldIndex) => (
                      <CustomFieldsPreview
                        key={`reference-CFpreview-tab-${tabIndex}-col-${colIndex}-field-${fieldIndex}`}
                        columnIndex={colIndex}
                        customFieldsPathResponse={customFieldsPathResponse}
                        data={tab.content[colIndex]}
                        from="form"
                        id={customField.id}
                        onClick={() => alert(customField.content)}
                        onDelete={() => {}}
                        onSelect={() => {}}
                        onUpdateCustomField={handleUpdateCustomFields}
                        tab={tab}
                        type={customField.content}
                        values={customField.values}
                        label={customField.label}
                        readOnly={readOnly}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </TabContainer4>
        ))}
      </SwipeableViews>
    </CustomRecordModal>
  );
};

export default ModalAssetReferences;
