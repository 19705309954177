import React, { useState } from 'react';
import TableComponent from '../../Components/TableComponent';
import { GetTranslatedValue } from '../../utils';
import ModalAssetFinder from '../modals/ModalAssetFinder';

const collections = {
  settingsLists: {
    id: 'idAsset',
    modal: 'openAssetModal',
    name: 'assets'
  }
};

const AssetTable = ({
  assetRows = [],
  assetOpened,
  locationsTreeView,
  onAssetFinderSubmit,
  onDeleteAssetAssigned,
  parentLocations,
  isAssetDecommissioned
}) => {
  const [control, setControl] = useState({
    idAsset: null,
    openAssetModal: false
  });

  const assetsHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.NAME')
    },
    {
      id: 'brand',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.BRAND')
    },
    {
      id: 'model',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.MODEL')
    },
    {
      id: 'EPC',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('ASSETS.CAPTION.EPC')
    },
    {
      id: 'serial',
      numeric: true,
      disablePadding: false,
      label: GetTranslatedValue('GENERAL.CAPTION.SERIALNUMBER')
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('RECORD.CAPTION.CREATIONDATE')
    }
  ];

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({ ...control, [collection.id]: null, [collection.modal]: true });
      },
      onDelete(id) {
        onDeleteAssetAssigned([...id]);
      }
    };
  };

  return (
    <>
      <ModalAssetFinder
        id={control.idAsset}
        locationsTreeView={locationsTreeView}
        onAssetFinderSubmit={onAssetFinderSubmit}
        setShowModal={(onOff) => setControl({ ...control, openAssetModal: onOff })}
        showModal={control.openAssetModal}
        userAssets={assetOpened ? [...assetRows, assetOpened || [] ].map(({ id }) => id) : assetRows.map(({ id }) => id) || []}
        parentLocations={parentLocations}
      />
      <TableComponent
        defaultOrder="asc"
        defaultOrderBy="name"
        headRows={assetsHeadRows}
        noEdit
        onAdd={tableActions('settingsLists').onAdd}
        onDelete={tableActions('settingsLists').onDelete}
        rows={assetRows}
        permissions={isAssetDecommissioned ? ['delete'] :['add', 'delete']}
        title={GetTranslatedValue('EMPLOYEE.MODAL.ASSIGNMENTS.CHILDREN', 'Asset Children')}
      />
    </>
  );
};

export default AssetTable;
