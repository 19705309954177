const moment = require('moment');
const mime = require('mime');
const store = require('../../app/store/store');
const generalDuck = require('../store/ducks/general.duck');
const notLogoutRoutes = require('../pages/home/jsConstants').notLogoutRoutes;
const { actions: { setUpdateNotifications, setUpdateMessages, showCustomAlert } } = generalDuck;

const {
  REACT_APP_SSL ,
  REACT_APP_API_SERVER,
  REACT_APP_API_PORT,
  REACT_APP_API_VERSION,
  REACT_APP_API_DB,
  REACT_APP_API_COLLECTION,
  REACT_APP_API_PUBLIC_REQ,
  REACT_APP_API_COUNT,
  REACT_APP_REFERENCES_LIMIT,
  REACT_APP_CATEGORIES_LIMIT,
  REACT_APP_USER_LIMIT,
  REACT_APP_USER_PROFILES_LIMIT,
  REACT_APP_LOCATIONS_LIMIT,
  REACT_APP_LOCATIONS_REAL_LIMIT,
  REACT_APP_EMPLOYEES_LIMIT,
  REACT_APP_EMPLOYEES_PROFILE_LIMIT
} = process.env;

const limitCollections = {
  references: REACT_APP_REFERENCES_LIMIT,
  categories: REACT_APP_CATEGORIES_LIMIT,
  user: REACT_APP_USER_LIMIT,
  userProfiles: REACT_APP_USER_PROFILES_LIMIT,
  locations: REACT_APP_LOCATIONS_LIMIT,
  locationsReal: REACT_APP_LOCATIONS_REAL_LIMIT,
  employees: REACT_APP_EMPLOYEES_LIMIT,
  employeeProfiles: REACT_APP_EMPLOYEES_PROFILE_LIMIT
};

const trueHost = REACT_APP_SSL ? REACT_APP_SSL : `${REACT_APP_API_SERVER}:${REACT_APP_API_PORT}`;
const host = `${trueHost}/`;
const version = REACT_APP_API_VERSION;
const db = `${REACT_APP_API_DB}/`;
const collection = `${REACT_APP_API_COLLECTION}/`;
const publicReq = `${REACT_APP_API_PUBLIC_REQ}/`;
const count = `${REACT_APP_API_COUNT}/`;
const collation = 'collation/';
const repeatedValues = 'repeatedValues/';
const repeatedValuesCount = 'repeatedValuesCount/';
const generateReport = 'generateReport/';
const generateReportCount = 'generateReportCount/';
const deleteReport = 'deleteReport/';
const passwordRecovery = 'passwordRecovery/';
const findAndModify = 'findAndModify/';
const distinct = `distinct/`;
const distinctCount = `countdistinct/`;
const getKPI = 'getAssetsKPI/';
const generateEmail = 'generateEmail/';
const processApprovals = 'processApprovals/';
const processApprovalsCount = 'processApprovalsCount/';
const inventorySessions = 'generateReportInventories/';
const inventorySessionsCount = 'generateReportInventoriesCount/';
const deleteMany = 'deleteMany/';
const updateMany = 'updateMany/';

const getBaseInfo = (customCreationUserId = null, customCreationUserFullName = null) => {
  const state = store.default.getState();
  const creationUserId = customCreationUserId || state?.auth?.user?.id || '';
  const creationUserFullName = customCreationUserFullName || state?.auth?.user?.fullname || '';
  const date = new Date();
  const format = 'YYYY/MM/DD HH:mm:ss';
  const currentDate = moment(date).format(format);

  return {
    post: {
      creationUserId,
      creationUserFullName,
      creationDate: currentDate,
      updateDate: currentDate
    },
    update: {
      updateDate: currentDate
    }
  };
};

const getAPIPath = ({
  collection = '',
  id = '',
  isEncrypt = false,
  isPublic = false,
  isCount = false,
  isCollation = false,
  isRepeatedValues = false,
  isRepeatedValuesCount = false,
  isReportDeleted = false,
  isGenerateReport = false,
  isGenerateReportCount = false,
  isInventorySessions = false,
  isInventorySessionsCount = false,
  isDistinct = false,
  isDistinctCount = false,
  isFindAndModify = false,
  isPasswordRecovery = false,
  isGetKPI = false,
  isSendEmail = false,
  isGetProcessApprovals = false,
  isGetProcessApprovalsCount = false,
  isDeleteMany,
  isUpdateMany
}) => `${host}${version}${isPublic ? publicReq : ''}${isCount ? count : ''}${isRepeatedValues ? repeatedValues : ''}${isRepeatedValuesCount ? repeatedValuesCount : ''}${isCollation ? collation : ''}${isGenerateReport ? generateReport : ''}${isGenerateReportCount ? generateReportCount : ''}${isReportDeleted ? deleteReport : ''}${isInventorySessions ? inventorySessions : ''}${isInventorySessionsCount ? inventorySessionsCount : ''}${isDistinct ? distinct : ''}${isDistinctCount ? distinctCount : ''}${isFindAndModify ? findAndModify : ''}${isPasswordRecovery ? passwordRecovery : ''}${isGetKPI ? getKPI : ''}${isSendEmail ? generateEmail : ''}${isGetProcessApprovals ? processApprovals : ''}${isGetProcessApprovalsCount ? processApprovalsCount : ''}${isDeleteMany ? deleteMany : ''}${isUpdateMany ? updateMany : ''}${db}${collection}${id}${isEncrypt ? '/encrypt' : ''}`;

const getAPIFilePath = (foldername) => `${host}${version}upload/${foldername}`;

const getHeaders = (isFile = false) => {
  const token = localStorage.getItem('accessToken');
  const hrefSplitted = window.location.href.split('/');
  const hrtefFiltered = hrefSplitted[hrefSplitted.length - 1].split('?');

  if (!token && !notLogoutRoutes.includes(hrtefFiltered[0])) {
    window.location.replace('/logout');

    return;
  }

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${token}`);
  if (!isFile) {
    headers.set('Accept', "application/json");
    headers.set('Content-Type', "application/json");
  }
  return headers;
};

const findAndModifyDB = ({ collection, query, update }) => {
  const text = query.map((condition) => JSON.stringify(condition)).join();
  const queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");

  const updateFormated = JSON.stringify(update);
  const additionalParams = `?query=${queryString}&update=${updateFormated}`
  const reqURL = `${getAPIPath({ collection, isFindAndModify: true })}${additionalParams}`
  return fetch(reqURL, { method: 'GET', headers: getHeaders() });
};

const topNavBarCollections = ['notifications', 'messages'];
const topNavBarDispatches = {
  messages: () => store.default.dispatch(setUpdateMessages(true)),
  notifications: () => store.default.dispatch(setUpdateNotifications(true))
};

const getKPIData = ({ kpis, locations }) => {
  const body = {
    kpis,
    locations
  };

  return fetch(getAPIPath({ collection: 'assets', isGetKPI: true }), { method: 'POST', headers: getHeaders(), body: JSON.stringify(body) });
};

const createReportFile = (body, isLargeFile = false) => {
  const path = !isLargeFile
    ? getAPIPath({
      collection: 'reports',
      isGenerateReport: true
    })
    : `${getAPIPath({
      collection: 'reports',
      isGenerateReport: true
    })}?largeFile=true`;

  return fetch(
    path,
    {
      body: JSON.stringify(body),
      method: 'POST',
      headers: getHeaders()
    }
  );
};

const deleteReportFile = (reportName) => {
  const path = getAPIPath({
    collection: `reports/${reportName}`,
    isReportDeleted: true
  });

  return fetch(path, { method: 'DELETE', headers: getHeaders()});
};

const handleArrayBody = (body, customCreationUserId = null, customCreationUserFullName = null ) => {
  if (Array.isArray(body)) {
    const newBody = body.map((element) => {
      return Object.assign(element, getBaseInfo(customCreationUserId, customCreationUserFullName).post);
    });
    return JSON.stringify(newBody);
  } else {
    return JSON.stringify(Object.assign(body, getBaseInfo(customCreationUserId, customCreationUserFullName).post));
  }
};

const postDB = (collection, body, customCreationUserId = null, customCreationUserFullName = null) => {
  if (topNavBarCollections.includes(collection)) {
    topNavBarDispatches[collection]();
  }
  if (Object.keys(limitCollections).includes(collection) && limitCollections[collection] > 0) {
    return getCountDB({ collection })
      .then((response) => response.json())
      .then(({ response }) => {
        const { count } = response;
        const newCount = Array.isArray(body) ? body.length : 1;
        if ((newCount + count) > limitCollections[collection]) {
          store.default.dispatch(showCustomAlert({
            open: true,
            message: `You have reached the limit of objects for this collection`,
            type: 'warning'
          }))
          return null;
        } else {
          return fetch(getAPIPath({ collection }), { method: 'POST', headers: getHeaders(), body: handleArrayBody(body, customCreationUserId, customCreationUserFullName)});
        }
      })
      .catch((error) => console.log('Error:', error));
  } else {
    return fetch(getAPIPath({ collection }), { method: 'POST', headers: getHeaders(), body: handleArrayBody(body, customCreationUserId, customCreationUserFullName)});
  }
};

const postDBEncryptPassword = (collection, body) => fetch(getAPIPath({ collection, isEncrypt: true }), { method: 'POST', headers: getHeaders(), body: JSON.stringify(Object.assign(body, getBaseInfo().post)) });

const getDB = (collection) => fetch(getAPIPath({ collection }), { method: 'GET', headers: getHeaders() });

const getPublicDB = (collection) => fetch(getAPIPath({ collection, isPublic: true }), { method: 'GET', headers: getHeaders() });

const getOneDB = (collection, id) => fetch(getAPIPath({ collection, id }), { method: 'GET', headers: getHeaders() });

const deleteDB = (collection, id) => fetch(getAPIPath({ collection, id }), { method: 'DELETE', headers: getHeaders() });

const updateDB = (collection, body, id) => fetch(getAPIPath({ collection, id }), { method: 'PUT', headers: getHeaders(), body: JSON.stringify(Object.assign(body, getBaseInfo().update)) });

const deleteManyDB = ({ collection, condition }) => {
  const text = condition.map((e) => JSON.stringify(e)).join();
  const queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
  const additionalParams = `?query=${queryString}`;

  return fetch(
    `${getAPIPath({ collection, isDeleteMany: true })}${additionalParams}`,
    {
      method: 'DELETE',
      headers: getHeaders()
    }
  );
};

const updateManyDB = ({ collection, body, condition }) => {
  const text = condition.map((e) => JSON.stringify(e)).join();
  const queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
  const additionalParams = `?query=${queryString}`;

  return fetch(
    `${getAPIPath({ collection, isUpdateMany: true })}${additionalParams}`,
    {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(Object.assign(body, getBaseInfo().update))
    }
  );
};

const postFILE = (foldername, filename, image) => {
  const { type = '/jpg' } = image;
  const fileExtension = mime.getExtension(type);
  const fileName = `${filename}.${fileExtension}`;

  const formData = new FormData();
  formData.append("file", image, fileName);

  const requestOptions = {
    method: 'POST',
    headers: getHeaders(true),
    body: formData,
    redirect: 'follow'
  };
  
  return fetch(getAPIFilePath(foldername), requestOptions);
};

const urltoFile = (url, filename, type) => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(true)
  };

  return (
    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((blob) => new File([blob], filename, { type }))
      .catch((error) => console.log(error))
  );
};

const passwordRecoveryProcess = (body) => {
  return fetch(getAPIPath({ isPasswordRecovery: true }), { method: 'POST', headers: getHeaders(), body: JSON.stringify(Object.assign(body, getBaseInfo().post)) });
};

const getVerifyPasswordRecovery = (condition) => {
  const text = condition.map((e) => JSON.stringify(e)).join(",");
  const queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
  const additionalParams = `?query=${queryString}`;
  const reqURL = `${getAPIPath({ isPasswordRecovery: true })}${additionalParams}`;
  return fetch(reqURL, { method: 'GET', headers: getHeaders() });
};

const changePassword = ({ id, newPassword, recoveryObjectId }) => {
  const body = {
    password: newPassword,
    idToUpdate: recoveryObjectId
  };

  const reqURL = `${getAPIPath({ collection: 'user/', id, isPasswordRecovery: true })}`;

  return fetch(reqURL, { method: 'PUT', headers: getHeaders(), body: JSON.stringify(Object.assign(body, getBaseInfo().update)) });
};

const getDBComplex = ({
  collection,
  queryExact,
  queryLike,
  sort,
  limit,
  repeatedValues,
  skip,
  fields,
  customQuery,
  condition,
  operator = '$or',
  distinct = null,
  locationsFilter = null,
  locationsFilterParam = null,
  treeViewLocation = null,
  generateReport = false,
  isInventorySessions = false,
  injectedQueryLike = null,
  disableAndQuery = false,
  enableOrQuery = false
}) => {
  let count = 0;
  let additionalParams = '';
  if (queryLike && !condition) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      const isValueBool = typeof value === 'boolean';
      res[key] = isValueBool ? value : { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const str = {};
    str[operator] = injectedQueryLike ? qLike.concat(injectedQueryLike) : qLike;
    const queryString = JSON.stringify(str);
    additionalParams += `query=${queryString}`;
    count++;
  } else if (queryExact) {
    const qExact = queryExact.map(({ key, value }) => {
      const res = {};
      res[key] = value;
      return res;
    });
    const str = {};
    str[operator] = qExact;
    const queryString = JSON.stringify(str);
    additionalParams += `query=${queryString}`;
    count++;
  } else if (queryLike && condition) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      const isValueBool = typeof value === 'boolean';
      res[key] = isValueBool ? value : { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const text = !Array.isArray(condition) ? condition : condition.map((e) => JSON.stringify(e)).join();
    let queryString = '';
    if (disableAndQuery) {
      queryString = JSON.stringify({ "$or": injectedQueryLike ? qLike.concat(injectedQueryLike) : qLike }, text).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    } else if (enableOrQuery) {
      queryString = JSON.stringify({ "$or": [injectedQueryLike ? qLike.concat(injectedQueryLike) : qLike] }, text).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    } else {
      queryString = JSON.stringify({ "$and": [{ "$or": injectedQueryLike ? qLike.concat(injectedQueryLike) : qLike }, text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    }

    additionalParams += `query=${queryString}`;
    count++;
  } else if (!queryLike && condition) {
    const text = !Array.isArray(condition) ? condition : condition.map((e) => JSON.stringify(e)).join();
    let queryString = '';
    if (disableAndQuery) {
      queryString = JSON.stringify(text).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    } else if (enableOrQuery) {
      queryString = JSON.stringify({ "$or": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    } else {
      queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    }
    additionalParams += `query=${queryString}`;
    count++;
  }
  if (customQuery) {
    additionalParams += `query=${queryExact}`;
    count++;
  }
  if (typeof skip === 'number') {
    additionalParams += `${count ? '&' : ''}skip=${skip}`;
    count++;
  };
  if (typeof limit === 'number') {
    additionalParams += `${count ? '&' : ''}limit=${limit}`;
    count++;
  };
  if (Array.isArray(fields)) {
    const res = fields.map(({ key, value }) => `"${key}":${value}`).join();
    additionalParams += `${count ? '&' : ''}fields={${res}}`;
    count++;
  };
  if (Array.isArray(sort)) {
    const res = sort.map(({ key, value }) => `"${key}":${value}`).join();
    additionalParams += `${count ? '&' : ''}sort={${res}}`;
    count++;
  }
  if (Array.isArray(locationsFilter) && typeof locationsFilterParam === 'string') {
    additionalParams += `${count ? '&' : ''}locationsFilter=${locationsFilter}&locationsFilterParam=${locationsFilterParam}`;
    count++;
  }
  if (treeViewLocation) {
    additionalParams += `&treeViewLocation=${treeViewLocation}`;
  }

  additionalParams = additionalParams ? `?${additionalParams}&collation={"locale": "en", "numericOrdering": true}` : '';
  let reqURL = '';
  if (repeatedValues) {
    reqURL = `${getAPIPath({ collection, isRepeatedValues: true })}/${repeatedValues}${additionalParams}`;
  } else if (distinct) {
    reqURL = `${getAPIPath({ collection, isDistinct: true })}/${distinct}${additionalParams}`;
  } else if (generateReport) {
    reqURL = `${getAPIPath({ collection, isGenerateReport: true })}${additionalParams}`;
  } else if (isInventorySessions) {
    reqURL = `${getAPIPath({ collection, isInventorySessions: true })}${additionalParams}`;
  } else {
    reqURL = `${getAPIPath({ collection, isCollation: true })}${additionalParams}`;
  }
  
  return fetch(reqURL, { method: 'GET', headers: getHeaders() });
};

const getCountDB = ({
  collection,
  condition,
  queryLike,
  queryExact,
  distinct = '',
  locationsFilter = null,
  locationsFilterParam = null,
  treeViewLocation = null,
  repeatedValues,
  generateReport = false,
  isInventorySessions = false,
  injectedQueryLike = null
}) => {
  let additionalParams = '';
  let count = 0;
  if (queryLike && !condition) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      res[key] = { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const queryString = JSON.stringify({ "$or": injectedQueryLike ? qLike.concat(injectedQueryLike) : qLike });
    additionalParams += `query=${queryString}`;
    count++;
  } else if (queryLike && condition) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      const isValueBool = typeof value === 'boolean';
      res[key] = isValueBool ? value : { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const text = condition.map((e) => JSON.stringify(e)).join();
    const queryString = JSON.stringify({ "$and": [{ "$or": injectedQueryLike ? qLike.concat(injectedQueryLike) : qLike }, text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    additionalParams += `query=${queryString}`;
    count++;
  } else if (!queryLike && condition) {
    const text = condition.map((e) => JSON.stringify(e)).join();
    const queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    additionalParams += `query=${queryString}`;
    count++;
  }
  if (queryExact) {
    const qExact = queryExact.map(({ key, value }) => {
      const res = {};
      res[key] = value;
      return res;
    });
    const queryString = JSON.stringify({ "$or": qExact });
    additionalParams += `query=${queryString}`;
  }
  if (Array.isArray(locationsFilter) && typeof locationsFilterParam === 'string') {
    additionalParams += `${count ? '&' : ''}locationsFilter=${locationsFilter}&locationsFilterParam=${locationsFilterParam}`;
  }
  if (treeViewLocation) {
    additionalParams += `&treeViewLocation=${treeViewLocation}`;
  }
  additionalParams = additionalParams ? `?${additionalParams}&collation={"locale": "en", "numericOrdering": true}` : '';

  let reqURL = '';
  if (distinct) {
    reqURL = `${getAPIPath({ collection, isDistinctCount: true })}/${distinct}${additionalParams}`;
  } else if (repeatedValues) {
    reqURL = `${getAPIPath({ collection, isRepeatedValuesCount: true })}${repeatedValues}${additionalParams}`;
  } else if (generateReport) {
    reqURL = `${getAPIPath({ collection, isGenerateReportCount: true })}${additionalParams}`;
  } else if (isInventorySessions) {
    reqURL = `${getAPIPath({ collection, isInventorySessionsCount: true })}${additionalParams}`;
  } else {
    reqURL = `${getAPIPath({ collection, isCount: true })}${additionalParams}`;
  }

  return fetch(reqURL, { method: 'GET', headers: getHeaders() });
};

const getMessages = ({
  queryLike,
  sort,
  limit,
  skip,
  fields,
  trash,
  userId
}) => {
  let collection = 'messages';
  let count = 0;
  let additionalParams = '';
  if (queryLike) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      const isValueBool = typeof value === 'boolean';
      res[key] = isValueBool ? value : { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const queryString = JSON.stringify({ "$and": [{ "$or": qLike }, { "to": { "$elemMatch": { "_id": userId } } }, { "status": trash ? "trash" : "new" }] });
    additionalParams += `query=${queryString}`;
    count++;
  } else {
    const queryString = JSON.stringify({ "$and": [{ "to": { "$elemMatch": { "_id": userId } } }, { "status": trash ? "trash" : "new" }] });
    additionalParams += `query=${queryString}`;
    count++;
  }
  if (typeof skip === 'number') {
    additionalParams += `${count ? '&' : ''}skip=${skip}`;
    count++;
  };
  if (typeof limit === 'number') {
    additionalParams += `${count ? '&' : ''}limit=${limit}`;
    count++;
  };
  if (Array.isArray(fields)) {
    const res = fields.map(({ key, value }) => `"${key}":${value}`).join();
    additionalParams += `${count ? '&' : ''}fields={${res}}`;
    count++;
  };
  if (Array.isArray(sort)) {
    const res = sort.map(({ key, value }) => `"${key}":${value}`).join();
    additionalParams += `${count ? '&' : ''}sort={${res}}`;
    count++;
  }

  additionalParams = additionalParams ? `?${additionalParams}` : '';
  const reqURL = `${getAPIPath({ collection })}${additionalParams}`;
  return fetch(reqURL, { method: 'GET', headers: getHeaders() });
};

const getTotalMessages = ({
  queryLike,
  trash,
  userId
}) => {
  let collection = 'messages';
  let additionalParams = '';
  if (queryLike) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      res[key] = { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const queryString = JSON.stringify({ "$and": [{ "$or": qLike }, { "to": { "$elemMatch": { "_id": userId } } }, { "status": trash ? "trash" : "new" }] });
    additionalParams += `query=${queryString}`;
  } else {
    const queryString = JSON.stringify({ "$and": [{ "to": { "$elemMatch": { "_id": userId } } }, { "status": trash ? "trash" : "new" }] });
    additionalParams += `query=${queryString}`;
  }
  additionalParams = additionalParams ? `?${additionalParams}` : '';
  const reqURL = `${getAPIPath({ collection, isCount: true })}${additionalParams}`;
  return fetch(reqURL, { method: 'GET', headers: getHeaders() });
};

const sendEmail = (collection, body) => {
  //The body should have the following props: to, subject, html
  return fetch(
    getAPIPath({ collection, isSendEmail: true }),
    {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(Object.assign(body, getBaseInfo().post))
    }
  );
};

const getProcessApprovals = ({
  collection,
  queryExact,
  queryLike,
  sort,
  limit,
  skip,
  fields,
  condition,
  operator = '$or',
  count = false,
}) => {
  const body = {};
  if (queryLike && !condition) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      res[key] = { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const queryString = JSON.stringify({ "$or": qLike });
    body.query = queryString;
  } else if (queryLike && condition) {
    const qLike = queryLike.map(({ key, value }) => {
      const res = {};
      const isValueBool = typeof value === 'boolean';
      res[key] = isValueBool ? value : { "$regex": `(?i).*${value}.*` };
      return res;
    });
    const text = condition.map((e) => JSON.stringify(e)).join();
    const queryString = JSON.stringify({ "$and": [{ "$or": qLike }, text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    body.query = queryString;

  } else if (!queryLike && condition) {
    const text = condition.map((e) => JSON.stringify(e)).join();
    const queryString = JSON.stringify({ "$and": [text] }).replace(/\\+/g, "").replace(/"{/g, "{").replace(/}"/g, "}");
    body.query = queryString;
  }
  if (queryExact) {
    const qExact = queryExact.map(({ key, value }) => {
      const res = {};
      res[key] = value;
      return res;
    });
    const str = {};
    str[operator] = qExact;
    const queryString = JSON.stringify(str);
    body.queryExact = queryString;
  }
  if (typeof skip === 'number') {
    body.skip = skip;
  };
  if (typeof limit === 'number') {
    body.limit = limit;
  };
  if (Array.isArray(fields)) {
    const res = fields.map(({ key, value }) => `"${key}":${value}`).join();
    body.fields = `{${res}}`;
  };
  if (Array.isArray(sort)) {
    const res = sort.map(({ key, value }) => `"${key}":${value}`).join();
    body.sort = `{${res}}`;
  }

  const additionalParams = `?&collation={"locale": "en", "numericOrdering": true}`;

  if (count) {
    const reqURL = `${getAPIPath({ collection, isGetProcessApprovalsCount: true })}${additionalParams}`;
    return fetch(reqURL, { method: 'POST', headers: getHeaders(), body: JSON.stringify(body) });
  } else {
    const reqURL = `${getAPIPath({ collection, isGetProcessApprovals: true })}${additionalParams}`;
    return fetch(reqURL, { method: 'POST', headers: getHeaders(), body: JSON.stringify(body) });
  }

};

module.exports = {
  changePassword,
  createReportFile,
  deleteDB,
  deleteManyDB,
  deleteReportFile,
  findAndModifyDB,
  getCountDB,
  getDBComplex,
  getDB,
  getKPIData,
  getMessages,
  getPublicDB,
  getOneDB,
  getTotalMessages,
  getVerifyPasswordRecovery,
  getProcessApprovals,
  host,
  passwordRecoveryProcess,
  postDB,
  postDBEncryptPassword,
  postFILE,
  sendEmail,
  updateDB,
  updateManyDB,
  urltoFile
};
