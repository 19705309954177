import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Chip, Paper } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './TableGrouping.scss';

const SingleCustomField = styled.div`
  position: relative;
  ${props =>
    props.inPortal
      ? `
    ::after {
      position: absolute;
      background: lightgreen;
      padding: 8px;
      bottom: 0;
      right: 0;
    }
  `
      : ''} 
`;
const portal = document.createElement('div');
document.body.appendChild(portal);

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    name: `item ${k}`
  }));

const TableGroupingSelector = ({ tableGroupingValues: elements, setElements, handleRemoveElement }) => {

  const PortalAwareItem = (props) => {
    const { provided, snapshot, item, handleRemoveElement, index } = props;

    const usePortal = snapshot.isDragging;

    const child = (
      <SingleCustomField
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        inPortal={usePortal}
      >
        {index === elements.length - 1 ? (
          <Chip
            key={item.name}
            label={item.name}
            onDelete={() => handleRemoveElement(item)}
            style={{ marginLeft: '10px' }}
          />
        ) : (
          <div className='dndItemArrow'>
            <Chip
              key={item.name}
              label={item.name}
              onDelete={() => handleRemoveElement(item)}
              style={{ marginLeft: '10px' }}
            />
            <ArrowForwardIosIcon fontSize='medium' style={{ fill: 'grey', paddingLeft: '10px' }} />
          </div>
        )
        }
      </SingleCustomField>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    const PortalChild = <div>{child}</div>;
    // return ReactDOM.createPortal(child, portal);
    return ReactDOM.createPortal(PortalChild, portal);
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightsteelblue' : 'ghostwhite',

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsLocal = reorder(
      elements,
      result.source.index,
      result.destination.index
    );

    setElements(itemsLocal);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Paper className='drag-drop-process__container'>
          <Droppable droppableId={'dropabble'} direction={'horizontal'}>
            {(provided, snapshot) => (
              <div
                className='drag-drop-process__list-field'
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {elements.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <PortalAwareItem
                        provided={provided}
                        snapshot={snapshot}
                        style={getItemStyle(snapshot.isDraggingOver)}
                        item={item}
                        handleRemoveElement={handleRemoveElement}
                        index={index}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Paper>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = ({ general: { tableGroupingValues } }) => ({
  tableGroupingValues
});

export default connect(mapStateToProps)(TableGroupingSelector);
