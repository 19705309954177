/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Tab, Tabs, FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import HelpIcon from '@material-ui/icons/Help';
import { actions } from '../../../../store/ducks/general.duck';
import { getCountDB, getDBComplex, getOneDB, postDB, updateDB } from '../../../../crud/api';
import { executeOnFieldPolicy, executePolicies } from '../../Components/Policies/utils';
import BaseFields from '../../Components/BaseFields/BaseFields';
import CustomFields from '../../Components/CustomFields/CustomFields';
import ImageUpload from '../../Components/ImageUpload';
import CustomRecordModal from '../../Components/CustomRecordModal';
import {
  getFileExtension,
  saveImage,
  getImageURL,
  verifyRepeatedCustomFields,
  GetTranslatedValue
} from '../../utils';
import './ModalLocationProfiles.scss';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ModalLocationProfiles = ({
  fields,
  fieldsToValidate,
  readOnly,
  showModal,
  setShowModal,
  reloadTable,
  id,
  policies,
  updateGeneralProfileLocations
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    showCustomAlert,
    showFillFieldsAlert,
    showErrorAlert,
    showSavedAlert,
    showUpdatedAlert
  } = actions;
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  function handleChangeIndex4(index) {
    setValue4(index);
  }
  const [values, setValues] = useState({
    name: '',
    level: '0',
    isAssetRepository: false,
    isLocationControl: false
  });
  const [profileHasLocationsWithAssets, setProfileHasLocationsWithAssets] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const [formValidation, setFormValidation] = useState({
    enabled: false,
    isValidForm: {}
  });
  const baseFieldsLocalProps = {
    name: {
      componentProps: {
        onChange: handleChange('name'),
        inputProps: {
          readOnly
        }
      }
    },
    selectedLevel: {
      ownValidFn: () => !!values.level || values.level === 0,
      componentProps: {
        onChange: handleChange('level'),
        type: 'number',
        value: values.level,
        inputProps: {
          readOnly,
          min: '0'
        }
      }
    }
  };

  const handleSave = () => {
    setFormValidation({ ...formValidation, enabled: true });
    if (!isEmpty(formValidation.isValidForm)) {
      dispatch(showFillFieldsAlert());
      return;
    }
    if (verifyRepeatedCustomFields(customFieldsTab)) {
      const message = intl.formatMessage({
        id: 'WARNING.CUSTOM.FIELDS.REPEATED.NAME',
        defaultMessage: 'Text not rendered'
      });

      dispatch(
        showCustomAlert({
          open: true,
          message,
          type: 'warning'
        })
      );
      return;
    }

    const fileExt = getFileExtension(image);
    const body = { ...values, customFieldsTab, fileExt };

    if (isNew) {
      const repeatedNameAddMessage = intl.formatMessage({
        id: 'LOCAITONS.PROFILES.WARNING.REPEATED.NAMES',
        defaultMessage: 'Text not rendered'
      });
      getDBComplex({
        collection: 'locations',
        condition: [{ name: values.name }, { level: values.level }]
      })
        .then((response) => response.json())
        .then(({ response }) => {
          if (!isEmpty(response)) {
            dispatch(
              showCustomAlert({
                open: true,
                message: repeatedNameAddMessage,
                type: 'warning'
              })
            );
            return;
          } else {
            postDB('locations', body)
              .then((data) => data.json())
              .then((response) => {
                dispatch(showSavedAlert());
                const { _id } = response.response[0];
                saveAndReload('locations', _id);
                executePolicies('OnAdd', 'locations', 'profiles', policies, response.response[0]);
                executeOnFieldPolicy(
                  'OnAdd',
                  'locations',
                  'profiles',
                  policies,
                  response.response[0]
                );
                updateGeneralProfileLocations();
                handleCloseModal();
              })
              .catch((error) => dispatch(showErrorAlert()));
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    } else {
      const repeatedNameUpdateMessage = intl.formatMessage({
        id: 'LOCAITONS.PROFILES.WARNING.REPEATED.NAMES',
        defaultMessage: 'Text not rendered'
      });
      getDBComplex({
        collection: 'locations',
        condition: [{ name: values.name }, { level: values.level }]
      })
        .then((response) => response.json())
        .then((data) => {
          const response = data.response.filter(({ _id }) => _id !== id[0]);
          if (!isEmpty(response)) {
            dispatch(
              showCustomAlert({
                open: true,
                message: repeatedNameUpdateMessage,
                type: 'warning'
              })
            );
            return;
          } else {
            updateDB('locations/', body, id[0])
              .then((response) => response.json())
              .then((data) => {
                const {
                  response: { value }
                } = data;
                const locationProfile = { _id: id[0], ...body };

                dispatch(showUpdatedAlert());
                saveAndReload('locations', id[0]);
                executePolicies('OnEdit', 'locations', 'profiles', policies, locationProfile);
                executeOnFieldPolicy(
                  'OnEdit',
                  'locations',
                  'profiles',
                  policies,
                  locationProfile,
                  value
                );
                updateGeneralProfileLocations();
                handleCloseModal();
              })
              .catch((error) => dispatch(showErrorAlert()));
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    }
  };

  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  const saveAndReload = (folderName, id) => {
    if (!id || (id[0] && initialImage !== image)) {
      saveImage(image, folderName, id);
    }
    reloadTable();
  };

  const profileHasChildrenWithAssets = (profileId) => {
    const getAssetsAssigned = (locationsIds) => {
      return getCountDB({
        collection: 'assets',
        condition: [{ location: { $in: locationsIds } }]
      })
        .then((response) => response.json())
        .then((data) => data?.response?.count > 0)
        .catch((error) => console.log(error));
    };

    return getDBComplex({
      collection: 'locationsReal',
      condition: [{ profileId: profileId }]
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data?.response && !isEmpty(data.response)) {
          const locationsIds = data.response.map(({ _id }) => _id);

          return await getAssetsAssigned(locationsIds);
        } else {
          return false;
        }
      })
      .catch((error) => console.log(error));
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setValues({ name: '', level: '0', isAssetRepository: false, isLocationControl: false });
    setShowModal(false);
    setValue4(0);
    setFormValidation({
      enabled: false,
      isValidForm: false
    });
    setProfileHasLocationsWithAssets(false);
  };

  const [customFieldsTab, setCustomFieldsTab] = useState({});

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      setIsNew(true);
      return;
    }

    getOneDB('locations/', id[0])
      .then((response) => response.json())
      .then(async (data) => {
        if (data.response) {
          const {
            name,
            level,
            isAssetRepository,
            isLocationControl,
            customFieldsTab,
            fileExt
          } = data.response;
          const obj = {
            name,
            level,
            isAssetRepository: isAssetRepository || false,
            isLocationControl: isLocationControl || false,
            imageURL: getImageURL(id, 'locations', fileExt)
          };
          setValues(obj);
          setCustomFieldsTab(customFieldsTab);
          setIsNew(false);

          if (!readOnly) {
            const hasChildrenWithAssets = await profileHasChildrenWithAssets(id[0]);
            setProfileHasLocationsWithAssets(hasChildrenWithAssets);

            if (hasChildrenWithAssets) {
              setValues((prev) => ({ ...prev, isAssetRepository: true }));
            }
          }
        }
      })
      .catch((error) => dispatch(showErrorAlert()));
  }, [id, showModal]);

  const AssetRepositorySwitch = () => {
    const handleChange = (e) => {
      setValues({ ...values, isAssetRepository: e.target.checked })
    };
    const disabled = readOnly || profileHasLocationsWithAssets;

    return (
      <Switch
        color="primary"
        disabled={disabled}
        checked={values.isAssetRepository}
        onChange={disabled ? null : handleChange}
      />
    );
  };

  return (
    <CustomRecordModal
      id={isNew}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Locations-Profile-Modal"
      readOnly={readOnly}
      showModal={showModal}
      tabs={
        <Tabs
          value={value4}
          onChange={handleChange4}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={GetTranslatedValue('RECORD.CAPTION.PROFILE')} />
          <Tab label={GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS')} />
        </Tabs>
      }
      title="LOCATION.PROFILES.MODAL.CAPTION"
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value4}
        onChangeIndex={handleChangeIndex4}
        slideStyle={{ overflow: 'hidden' }}
      >
        <TabContainer4 dir={theme4.direction}>
          <div className="profile-tab-wrapper">
            <div className="profile-tab-wrapper__picture">
              <ImageUpload
                disabled={readOnly}
                id="location-profile-image"
                image={values.imageURL}
                setImage={setImage}
                setInitialImage={setInitialImage}
              >
                {GetTranslatedValue('LOCATIONS.PROFILES.PHOTO.CAPTION')}
              </ImageUpload>
            </div>
            <div className="profile-tab-wrapper__content">
              <BaseFields
                catalogue={'locations'}
                collection={'locations'}
                fields={fields}
                fieldsToValidate={fieldsToValidate}
                formState={[formValidation, setFormValidation]}
                localProps={baseFieldsLocalProps}
                values={values}
              />
              <FormControlLabel
                value="start"
                control={
                  profileHasLocationsWithAssets ? (
                    <>
                      <Tooltip
                        title={
                          <Typography>
                            {GetTranslatedValue('LOCATIONS.PROFILES.TOOLTIP.MESSAGE')}
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpIcon fontSize="small" style={{ cursor: 'pointer', fill: '#8e8e8e' }} />
                      </Tooltip>
                      <AssetRepositorySwitch />
                    </>
                  ) : (
                    <AssetRepositorySwitch />
                  )
                }
                label={GetTranslatedValue('EMPLOYEE.PROFILE.ASSETREPOSITORY')}
                labelPlacement="start"
              />
              {/* The following input is hidden because it will be needed in the future */}
              {/* <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    disabled={readOnly}
                    checked={values.isLocationControl}
                    onChange={(e) => setValues({ ...values, isLocationControl: e.target.checked })}
                  />
                }
                label={GetTranslatedValue('LOCATIONS.PROFILES.LOCATION.CONTROL')}
                labelPlacement="start"
              /> */}
            </div>
          </div>
        </TabContainer4>
        <TabContainer4 dir={theme4.direction}>
          <CustomFields
            customFieldsTab={customFieldsTab}
            setCustomFieldsTab={setCustomFieldsTab}
            readOnly={readOnly}
          />
        </TabContainer4>
      </SwipeableViews>
    </CustomRecordModal>
  );
};

export default ModalLocationProfiles;
