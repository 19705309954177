import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty, isEqual, uniq } from 'lodash';
import { tabsConfig } from './Components/Translations/constants';
import { getCountDB, getDB, getDBComplex, getOneDB, postDB, postFILE, updateDB, updateManyDB } from '../../crud/api';
import store from '../../store/store';
import { actions } from '../../store/ducks/general.duck';
const { showCustomAlert } = actions;

const {
  REACT_APP_SSL,
  REACT_APP_API_SERVER,
  REACT_APP_API_PORT,
  REACT_APP_LOCALHOST,
  REACT_APP_LOCALHOST_PORT,
  REACT_APP_SERVER_PORT
} = process.env;

const apiHost = REACT_APP_SSL ? REACT_APP_SSL : `${REACT_APP_API_SERVER}:${REACT_APP_API_PORT}`;
const localHost = `${REACT_APP_LOCALHOST}:${REACT_APP_LOCALHOST_PORT}`;
const serverHost = `${REACT_APP_API_SERVER}:${REACT_APP_SERVER_PORT}`;

export const hosts = { apiHost, localHost, serverHost };

export const getFileExtension = file => {
  if (!file) return '';
  const { type } = file;
  return type.split('/')[1];
};

export const saveImage = (image, folderName, id) => {
  if (image) {
    postFILE(folderName, id, image)
      .then(response => {
      })
      .catch(error => console.log(error));
  }
};

export const getFirstDocCollection = (collectionName) => {
  return getDB(collectionName)
    .then(response => response.json())
    .then(data => {
      if (data.response && Array.isArray(data.response) && data.response.length) {
        return data.response[0]._id;
      }
      return null;
    })
    .catch(error => console.log('error>', error));
};

export const getImageURL = (id, folder, fileExt = '') => {
  return fileExt ?
    `${apiHost}/uploads/${folder}/${id}.${fileExt}` :
    '';
};

const findOccurrences = (strFull, strToSearch) => {
  const regex = new RegExp(strToSearch, 'g');
  let result;
  const foundIndexes = [];
  while ((result = regex.exec(strFull))) {
    foundIndexes.push(result.index);
  }

  return foundIndexes;
};

export const getVariables = (html) => {
  const startVar = findOccurrences(html, '%{');
  return startVar.reduce((acu, curr, ix, all) => {
    const subStr = html.slice(curr, all[ix + 1]);
    const endVar = subStr.indexOf('}');

    if (endVar >= 0) {
      const varName = subStr.slice(2, endVar);
      return [...acu, { varName, start: curr, end: curr + endVar }];
    } else {
      return acu;
    }
  }, []) || [];
};

export const getCurrentDateTime = () => {
  const rawDate = new Date();
  const dateFormatted = `${(`0${rawDate.getDate()}`).slice(-2)}/${(`0${rawDate.getMonth() + 1}`).slice(-2)}/${rawDate.getFullYear()}`;
  const timeFormatted = `${`0${rawDate.getHours()}`.slice(-2)}:${`0${rawDate.getMinutes()}`.slice(-2)}:${`0${rawDate.getSeconds()}`.slice(-2)}`;

  return { dateFormatted, rawDate, timeFormatted };
};

export const simplePost = (collection, object) => {
  postDB(collection, object)
    .catch((error) => console.error('ERROR', error))
};

export const getLocationPath = async (locationId, returnId = false) => {
  const allLocations = await getAllLocations();
  const firstLocation = allLocations.find(({ id }) => id === locationId);
  const response = recursiveLocationPath(firstLocation, allLocations, returnId);

  if (!returnId) {
    return response;
  } else {
    return response.split('/');
  }
};

const getAllLocations = async () => {
  return await getDB('locationsReal/')
    .then(response => response.json())
    .then(data => {
      const filtered = data.response.map(({ _id: id, name, parent }) => ({ id, name, parent }))
      return filtered;
    })
    .catch(error => console.log(error));
}

const recursiveLocationPath = (currentLocation, allLocations, returnId = false) => {
  const { name, parent, id } = currentLocation;
  if (parent === 'root' && !returnId) {
    return name;
  }
  else if (parent === 'root' && returnId) {
    return id;
  }
  else {
    const newLocation = allLocations.find(({ id }) => id === parent);
    if (returnId) {
      return recursiveLocationPath(newLocation, allLocations, returnId).concat('/', id);
    }
    else {
      return recursiveLocationPath(newLocation, allLocations, returnId).concat('/', name);
    }
  }
};

export const verifyCustomFields = (customFieldsTab) => {
  let flag = true;

  if (isEmpty(Object.keys(customFieldsTab))) {
    return true;
  }

  const RightAndLeft = ['right', 'left'];
  const customInitialValue = ['singleLine', 'multiLine', 'date', 'dateTime', 'imageUpload', 'currency', 'percentage', 'email', 'decimal', 'richText', 'url', 'formula', 'dropDown'];
  const customSelectedItem = ['radioButtons'];
  const customSelectedOptions = ['checkboxes', 'decisionBox'];
  const customOther = ['fileUpload'];
  Object.keys(customFieldsTab).map((tabName) => {
    RightAndLeft.map((side) => {
      customFieldsTab[tabName][side].forEach(({ content, values: { mandatory, initialValue, fileExt, selectedItem, selectedOptions } }) => {
        if (!flag || !mandatory) {
          return;
        }

        if (customInitialValue.includes(content)) {
          if (content === 'richText') {
            flag = initialValue.length > 8;
          } else if (!initialValue) {
            flag = false;
          }
        } else if (customSelectedItem.includes(content)) {
          if (!selectedItem) {
            flag = false;
          }
        } else if (customSelectedOptions.includes(content)) {
          if (!selectedOptions || !selectedOptions?.length) {
            flag = false;
          }
        } else if (customOther.includes(content)) {
          if (content === 'fileUpload') {
            if (!fileExt) {
              flag = false;
            }
          }
        }
      });
    })
  });

  return flag;
};

export const verifyRepeatedValues = (customFieldsTab) => {
  let data = [];
  const customInitialValue = ['singleLine', 'multiLine', 'date', 'dateTime', 'currency', 'percentage', 'email', 'decimal', 'url'];
  const RightAndLeft = ['right', 'left'];
  if (!Object.keys(customFieldsTab).length) return true;

  Object.keys(customFieldsTab).map((tabName) => {
    RightAndLeft.map((side) => {
      customFieldsTab[tabName][side].forEach((customField) => {
        if (!customInitialValue.includes(customField.content)) return;
        if (customField.values.repeated) {
          data.push(customField);
        }
      });
    })
  });

  console.log(data);
};

export const verifyRepeatedCustomFields = (customFields) => {
  let flag = false;
  if (isEmpty(customFields)) {
    return flag;
  }

  const allCustomFields = [];

  Object.entries(customFields).map(([tabName, tabValues]) => {
    ['left', 'right'].map((side) => {
      tabValues[side].map((field) => allCustomFields.push(field));
    });
  });


  allCustomFields.map(({ id, content, values: { fieldName } }) => {
    const check = allCustomFields.find((field) => field.content === content && field.values.fieldName === fieldName && id !== field.id);
    if (check) {
      flag = true;
    };
  });

  return flag;
};

export const compareCustomFieldsObjects = (object1, object2) => {
  const object1Ids = []
  Object.entries(object1).map(([tabKey, tabValue]) => {
    ['left', 'right'].map((side) => {
      tabValue[side].map(({ id }) => {
        object1Ids.push(id);
      });
    })
  });

  const object2Ids = []
  Object.entries(object2).map(([tabKey, tabValue]) => {
    ['left', 'right'].map((side) => {
      tabValue[side].map(({ id }) => {
        object2Ids.push(id);
      });
    })
  });

  return isEqual(object1Ids, object2Ids);
};

const getDuplicatedCustomFieldIndex = (obj, field, tabName, side) => {
  return obj[tabName][side].findIndex(({ id, content, values: { fieldName } }) => id === field.id || (content === field.content && fieldName === field.values.fieldName));
};

export const updateCustomFields = (customFields, parentCustomFields, setNewCustomFields) => {
  const parentCustomFieldsTabs = Object.keys(parentCustomFields);
  const customFieldsTab = Object.keys(customFields);
  // const pendingFields = [];

  //Verify Tabs
  parentCustomFieldsTabs.forEach((tabName) => {
    if (!customFields[tabName]) {
      customFields[tabName] = parentCustomFields[tabName];
      return;
    }

    ['left', 'right'].forEach((side) => {
      parentCustomFields[tabName][side].forEach((field, ix) => {

        const indexToEdit = getDuplicatedCustomFieldIndex(customFields, field, tabName, side);
        if (indexToEdit === -1) {
          //Look in the other side
          const otherSide = side === 'left' ? 'right' : side === 'right' ? 'left' : null;
          const otherSideIndex = getDuplicatedCustomFieldIndex(customFields, field, tabName, otherSide);
          if (otherSideIndex === -1) {
            //Look other tabs
            let flag = false;
            customFieldsTab.forEach((otherTabName) => {
              if (!flag) {
                const leftIndex = getDuplicatedCustomFieldIndex(customFields, field, otherTabName, 'left');
                const rightIndex = getDuplicatedCustomFieldIndex(customFields, field, otherTabName, 'right');

                if (leftIndex !== -1) {
                  const oldField = customFields[otherTabName]['left'][leftIndex];
                  customFields[otherTabName]['left'].splice(leftIndex, 1);
                  customFields[tabName][side].splice(ix, 0, setNewValue(field, oldField));
                  flag = true;
                } else if (rightIndex !== -1) {
                  const oldField = customFields[otherTabName]['right'][rightIndex];
                  customFields[otherTabName]['right'].splice(rightIndex, 1);
                  customFields[tabName][side].splice(ix, 0, setNewValue(field, oldField));
                  flag = true;
                }
              }
            });
            if (!flag) {
              customFields[tabName][side].splice(ix, 0, field);
            }
            return;
          }
          const oldField = customFields[tabName][otherSide][otherSideIndex];
          customFields[tabName][otherSide].splice(otherSideIndex, 1);
          customFields[tabName][side].splice(ix, 0, setNewValue(field, oldField));
          return;
        }

        const oldField = customFields[tabName][side][indexToEdit];
        customFields[tabName][side].splice(indexToEdit, 1);
        customFields[tabName][side].splice(ix, 0, setNewValue(field, oldField));

      });
    });
  });

  setNewCustomFields(customFields);
};

export const setNewValue = (field, oldField) => {
  const { content } = field;

  const initialValueType = ['singleLine', 'multiLine', 'date', 'dateTime', 'currency', 'percentage', 'email', 'decimal', 'url', 'formula', 'richText', 'dropDown'];
  const selectedItemType = ['radioButtons'];
  const selectedOptionsType = ['checkboxes', 'decisionBox'];
  const fileIdType = ['fileUpload'];
  const fileNameType = ['imageUpload'];

  if (initialValueType.includes(content)) {
    if (content === 'dropDown') {
      return { ...field, values: { ...field.values, initialValue: oldField.values.initialValue || field.values.initialValue, selectedItem: oldField.values.selectedItem >= 0 ? oldField.values.selectedItem : field.values.selectedItem } };
    }

    return { ...field, values: { ...field.values, initialValue: oldField.values.initialValue || field.values.initialValue } };
  } else if (selectedItemType.includes(content)) {
    return { ...field, values: { ...field.values, selectedItem: oldField.values.selectedItem >= 0 ? oldField.values.selectedItem : field.values.selectedItem } };
  } else if (selectedOptionsType.includes(content)) {
    return { ...field, values: { ...field.values, selectedOptions: oldField.values.selectedOptions || field.values.selectedOptions } };
  } else if (fileIdType.includes(content)) {
    return {
      ...field,
      values: {
        ...field.values,
        fileName: oldField.values.fileName || field.values.fileName,
        fileId: oldField.values.fileId || field.values.fileId,
        fileExt: oldField.values.fileExt || field.values.fileExt
      }
    };
  } else if (fileNameType.includes(content)) {
    return {
      ...field,
      values: {
        ...field.values,
        fileName: oldField.values.fileName || field.values.fileName,
        initialValue: oldField.values.initialValue || field.values.initialValue,
      }
    };
  } else {
    return field;
  }
};

export const LightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);
  let r = (num >> 16) + amt;

  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  };

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  };

  let g = (num & 0x0000FF) + amt;

  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  };

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};


export const getCurrentModuleName = (module, tab) => {
  return tabsConfig.modules.find(({ name }) => name === module).titles.find((_, index) => index === tab)?.value;
}

export const getCurrentModuleTab = (module, permissions) => {
  return tabsConfig.modules.find(({ name }) => name === module).titles.findIndex(({ value }) => Object.keys(permissions[module] || {}).includes(value) && !isEmpty(permissions[module][value] || []));
}

export const GetTranslatedValue = (id, defaultMessage = null) => {
  const intl = useIntl();

  if (!id && !defaultMessage) {
    return null;
  }

  return intl.formatMessage({ id: id || 'INCORRECT_KEY', defaultMessage: defaultMessage || 'Text Not Rendered' });
}

export const getFieldCaption = (fields, { catalogue, field, key }, defaultMessage = null) => {
  if (!fields) {
    return GetTranslatedValue(key, defaultMessage);
  }

  return fields[catalogue][field]?.caption || GetTranslatedValue(key, defaultMessage);
};

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const loadUserLocations = ({
  allData = false,
  setParentLocations,
  userId,
  flag = false,
  setAllLocations
}) => {
  return getOneDB('user/', userId)
    .then((response) => response.json())
    .then(async (data) => {
      const locationsTable = data.response.locationsTable;

      if (flag && setParentLocations && !allData) {
        setParentLocations(locationsTable);
      } else if (setParentLocations && !allData) {
        setParentLocations(locationsTable.map(({ parent }) => parent));
      }

      const userLocations = await getDB('locationsReal')
        .then((response) => response.json())
        .then((locationsData) => {
          let res = [];

          if (allData & setParentLocations) {
            const locationsTableIds = (locationsTable || []).map(({ parent }) => parent) || [];
            const locations = locationsData?.response.filter(({ _id }) => locationsTableIds.includes(_id));
            setParentLocations(locations);
          }

          if (setAllLocations) {
            const filtered = locationsData.response.map(({ _id: id, name, parent, profileId }) => ({ id, name, parent, profileId }));
            setAllLocations(filtered);
          }

          locationsTable.forEach((location) => {
            const currentLoc = locationsData.response.find((e) => e._id === location.parent);

            res.push(currentLoc._id);

            const children = locationsData.response.filter((e) => e.parent === currentLoc._id);

            if (children.length) {
              children.forEach((e) => res.push(e._id));
              children.forEach((e) => locationsRecursive(locationsData, e, res));
            }
          });

          return uniq(res);
        })
        .catch((error) => console.log(error));

      return userLocations;
    })
    .catch((error) => console.log(error));
};

const locationsRecursive = (data, currentLocation, res) => {
  const children = data.response.filter((e) => e.parent === currentLocation._id);

  if (!children.length) {
    return;
  }

  children.forEach((e) => {
    if (!res.includes(e._id)) {
      res.push(e._id);
    }
  });
  children.forEach((e) => locationsRecursive(data, e, res));
};

const locationsTreeData = {
  id: 'root',
  name: 'Locations',
  profileLevel: -1,
  parent: null
};

export const loadLocationsData = (userLocations, setModalData) => {
  let locations;

  return getDB('locationsReal')
    .then(response => response.json())
    .then(data => {
      if (setModalData) {
        setModalData(data);
      }

      let userHomeLocations = [];
      let validChildren = [];
      locations = data.response.map(res => ({ ...res, id: res._id }));
      userLocations.forEach((e) => getUserHomeLocations(data.response, e, userHomeLocations, validChildren));
      const children = constructLocationTreeRecursive(userHomeLocations, validChildren, locations);
      locationsTreeData.children = children;
      const locationsTreeCopy = { ...locationsTreeData };

      if (setModalData) {
        return { locationsTreeData: locationsTreeCopy, locations }
      } else {
        return locationsTreeCopy;
      }
    });
};

const getUserHomeLocations = (data, currentLocation, res, validChildren) => {
  if (!currentLocation) {
    return;
  }

  if (!validChildren.includes(currentLocation)) {
    validChildren.push(currentLocation);
  }

  const location = data.find((e) => e._id === currentLocation)

  if (res.includes(location)) {
    return;
  }

  if (location?.parent === 'root') {
    res.push(location);

    return;
  }

  getUserHomeLocations(data, location?.parent, res, validChildren);
};

const constructLocationTreeRecursive = (locs, validChildren, locations) => {
  if (!locs || !Array.isArray(locs) || !locs.length) return [];
  let res = [];
  locs.forEach((location) => {
    const locObj = (({ _id: id, name, profileLevel, parent }) => ({ id, name, profileLevel, parent }))(location);
    const children = locations.filter(loc => loc.parent === locObj.id && validChildren.includes(loc._id));
    locObj.children = constructLocationTreeRecursive(children, validChildren, locations);
    res.push(locObj);
  });

  return res;
};

export const selectLocation = (locationId, level, parent, locationName, children, userLocations) => {
  let res = [];
  locationsChildren(children, res, userLocations);
  const locationToSend = res.map((locationId) => locationId);

  if (userLocations.includes(locationId)) {
    locationToSend.push(locationId)
  }

  return locationToSend;
};

const locationsChildren = (children, res, userLocations) => {
  if (!children || !Array.isArray(children) || !children.length) return [];
  children.map((child) => {
    locationsChildren(child.children, res, userLocations);
    if (userLocations?.includes(child.id)) {
      res.push(child.id);
    }
  })

  return res;
}

export const validateEmail = (email) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return regex.test(email);
};


export const CapitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isHexValue = (hexValue) => {
  return /^#[0-9A-F]{6}$/i.test(hexValue);
}

function testImage(url, timeoutT) {
  return new Promise(function (resolve, reject) {
    var timeout = timeoutT || 5000;
    var timer, img = new Image();
    img.onerror = img.onabort = function () {
      clearTimeout(timer);
      reject("error");
    };
    img.onload = function () {
      clearTimeout(timer);
      resolve("success");
    };
    timer = setTimeout(function () {
      // reset .src to invalid URL so it stops previous
      // loading, but doens't trigger new load
      img.src = "//!!!!/noexist.jpg";
      reject("timeout");
    }, timeout);
    img.src = url;
  });
}

export const checkImage = (url, onValidImage = () => {}, onInvalidImage = () => {}) => {
  testImage(url)
    .then(() => {
      onValidImage(url);
    })
    .catch((error) => {
      onInvalidImage();
    })

};

export const convertToObjectIdArray = (array) => array.map((id) => `ObjectId('${id}')`);

export const showSuccessMessage = (message) => {
  store.dispatch(showCustomAlert({
    message,
    open: true,
    type: 'success'
  }));
};

export const showWarningMessage = (message) => {
  store.dispatch(showCustomAlert({
    message,
    open: true,
    type: 'warning'
  }));
};

export const showErrorMessage = (message) => {
  store.dispatch(showCustomAlert({
    message,
    open: true,
    type: 'error'
  }));
};

export const copyObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

// H&T Functions

export const profileHasChildren = (collection, profileId, field) => {
  return getCountDB({
    collection,
    condition: [{ [field]: profileId }]
  })
    .then((response) => response.json())
    .then((data) => data?.response?.count > 0)
    .catch((error) => console.log(error));
};

export const updateAssetInfoToRelated = ({ collection, asset, searchField }) => {
  getDBComplex({
    collection,
    condition: [{ [searchField]: { $elemMatch: { "id":  asset.id } } }]
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.response && !isEmpty(data.response)) {
        const { _id: recordId, assetsAssigned, children } = data.response[0];
        if (collection === 'employees') {
          const index = assetsAssigned.findIndex(({ id }) => id === asset.id);
          assetsAssigned.splice(index, 1, asset);
          
          updateDB('employees/', { assetsAssigned }, recordId)
            .catch((error) => console.log(error));
        } else if (collection === 'assets') {
          const index = children.findIndex(({ id }) => id === asset.id);
          children.splice(index, 1, asset);

          updateDB('assets/', { children }, recordId)
            .catch((error) => console.log(error));
        }
      }
    })
    .catch((error) => console.log(error));
};

export const updateChildrenProfileName = ({ collection, profileId, field, newName, updateField }) => {
  return updateManyDB({
    body: { [updateField]: { value: profileId, label: newName } },
    collection,
    condition: [{ [field]: profileId }]
  });
};
