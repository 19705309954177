import React, { useState, useEffect } from 'react';
import { GetTranslatedValue } from '../../../utils';
import TableComponent from '../../../Components/TableComponent';

const createHeaderRow = (id) => {
  return { id, numeric: false, disablePadding: false, label: id };
};

const Preview = ({ headers, data, onSelectedRowsChange }) => {
  const [headerRows, setHeaderRows] = useState([]);

  useEffect(() => setHeaderRows(headers.map((header) => createHeaderRow(header))), [headers])

  return (
    <TableComponent
      headRows={headerRows}
      isImport
      rows={data}
      title={GetTranslatedValue('IMPORT.PREVIEW.TAB')}
      onSelect={onSelectedRowsChange}
    />
  )
}

export default Preview;
