/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { isEmpty } from 'lodash';
import {
  AppBar,
  Card,
  CardContent,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Tab,
  Tabs,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  InputAdornment,
  Select,
  Switch,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import {
  withStyles,
  useTheme,
  makeStyles
} from "@material-ui/core/styles";
import { omit } from "lodash";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@material-ui/icons/Close";
import AccountTree from '@material-ui/icons/AccountTree';
import SearchIcon from '@material-ui/icons/Search';
import { actions } from '../../../../store/ducks/general.duck';
import { getDB, getOneDB, updateDB, postDB } from '../../../../crud/api';
import ProcessFlow from '../components/ProcessFlow';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { GetTranslatedValue } from '../../utils';

const styles5 = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  layoutsListSubheader: {
    padding: '0 8'
  }
});

const DialogTitle5 = withStyles(styles5)(({ children, classes, onClose }) => {
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContent5 = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(DialogContent);

const DialogActions5 = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(DialogActions);

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
const useStyles4 = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  }
}));

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: '100%',
  },
  list: {
    width: '100%'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  subTitle: {
    width: '100%',
    fontStyle: 'italic',
    color: 'grey'
  },
  title: {
    fontWeight: 'bold',
    marginRight: '5px'
  },
  tabWrapper: {
    display: 'flex'
  },
  tabContent: {
    margin: '5px'
  },
  '@media (max-width: 800px)': {
    tabWrapper: {
      flexDirection: 'column'
    },
  },
  '@media (max-width: 500px)': {
    textField: {
      minWidth: '100px'
    }
  }
}));

const ModalProcesses = ({ readOnly, showModal, setShowModal, reloadTable, id, employeeProfileRows }) => {
  const classes4 = useStyles4();
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }

  const dispatch = useDispatch();
  const { showCustomAlert, showSavedAlert, showUpdatedAlert } = actions;

  const processTypes = [
    { id: 'creation', label: 'Creation' },
    { id: 'movement', label: 'Movement' },
    { id: 'short', label: 'Short Movement' },
    { id: 'decommission', label: 'Decommission' },
    { id: 'maintenance', label: 'Maintenance' }
  ];
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    layoutMessageName: '',
    selectedUserNotification: '',
    selectedUserApprovals: '',
    notificationsForUsers: {},
    selectedProcessType: ''
  });

  const [validMessages, setValidMessages] = useState({
    notifications: {},
    approvals: {},
    selectedUserNotifications: 'none',
    selectedUserApprovals: 'none',
    selectedStage: ''
  });

  const [profileSelected, setProfileSelected] = useState(0);

  const [originalStages, setOriginalStages] = useState([]);
  const [stages, setStages] = useState([]);
  const [searchStage, setSearchStage] = useState('');
  const [processStages, setProcessStages] = useState([]);

  //Translated messages
  const toSaveAlert = GetTranslatedValue('PROCESSES.ALERT.TO.SAVE', 'To save, please ad a stage, a processType and a name');
  const selectStageAlert = GetTranslatedValue('PROCESSES.ALERT.SELECT.STAGE', 'First, please select a stage');
  const goBackEnabledAlert = GetTranslatedValue('PROCESSES.ALERT.NO.STAGE.GO.BACK', `has the 'Go Back' functionality enabled but it doesn't have a target selected`);
  const maintenanceAlert = GetTranslatedValue('PROCESSES.ALERT.MAINTENANCE.SELF.APPROVE', 'A maintenance process cannot be Self Approve');
  const deprecatedStageAlert = GetTranslatedValue('PROCESSES.ALERT.DEPRECATED.STAGE', 'This Process has a deprecated stage');
  
  const handleSearchStage = event => {
    const searchStageVal = event.target.value;
    setSearchStage(searchStageVal);
    const stagesOrig = getStagesNotUsed();
    if (!searchStageVal) {
      setStages(stagesOrig);
      return;
    }
    const filteredStages = stagesOrig.filter(st => st.name.toLowerCase().includes(searchStageVal.toLowerCase()));
    setStages(filteredStages);
  };

  const handleChangeGoBack = name => event => {
    if (!Object.keys(selectedStageObject).length) {
      dispatch(showCustomAlert({
        type: 'warning',
        open: true,
        message: selectStageAlert
      }));
      return;
    }

    const processStagesToUpdate = processStages;
    const stageIndex = processStages.findIndex(({ id }) => id === selectedStage);
    if (name === 'goBackEnabled') {
      const checked = event.target.checked;
      processStagesToUpdate[stageIndex][name] = checked;
      setSelectedStageObject(prev => ({
        ...prev,
        [name]: checked,
      }));
    }
    else {
      processStagesToUpdate[stageIndex][name] = event.target.value;
      setSelectedStageObject(prev => ({
        ...prev,
        [name]: event.target.value,
      }));
    }
    setProcessStages(processStagesToUpdate);
  };

  const getStagesNotUsed = () => {
    return originalStages.reduce((acu, cur) => {
      return processStages.findIndex(pStage => pStage.id === cur.id) < 0 ?
        [...acu, cur] : acu;
    }, []);
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [usersProcess, setUsersProcess] = useState({
    selectedUserNotification: '',
    selectedUserApproval: '',
    notificationsDisabled: true,
    approvalsDisabled: true
  });
  const handleChangeUserNotifApp = name => event => {
    const { target: { value: selectedUser } } = event;
    if (!selectedUser || !selectedStage) {
      return;
    }
    setUsersProcess(prev => ({
      ...prev,
      [name]: event.target.value,
      notificationsDisabled: false,
      approvalsDisabled: false
    }));
    let typeInfo;

    if (name === 'selectedUserNotification') {
      typeInfo = {
        name: 'notifications',
        user: 'selectedUserNotifications'
      };
    } else if (name === 'selectedUserApproval') {
      typeInfo = {
        name: 'approvals',
        user: 'selectedUserApprovals'
      };
    }
    const tmpMessages = validMessages[typeInfo.name];
    let selectedLayouts = tmpMessages[selectedStage][selectedUser];
    if (!selectedLayouts) {
      selectedLayouts = selectedStageLayout.map(layout => ({ ...layout, checked: false }));
    }
    tmpMessages[selectedStage][selectedUser] = selectedLayouts;
    setValidMessages(prev => ({
      ...prev,
      [typeInfo.user]: selectedUser || 'none',
      [typeInfo.name]: tmpMessages
    }));
  };

  const handleSave = () => {
    const processedStages = processStages.map((stage) => {
      const processApprovals = [];
      stage.approvals.map((approval) => {
        if (!approval.isUserGroup) {
          processApprovals.push(approval)
          return;
        }
        approval.members.map(({ value, name, lastName, label }) => (
          processApprovals.push({
            _id: value,
            name,
            lastName,
            email: label
          })
        ));
      });
      stage.approvals = processApprovals;
      return stage;
    });
    //verify GoBack functionality
    const flags = processStages.map(({ name, goBackEnabled, goBackTo }) => {
      if (goBackEnabled && (!goBackTo || goBackTo === 'none' )) {
        dispatch(showCustomAlert({
          type: 'warning',
          open: true,
          message: `${name} ${goBackEnabledAlert}`
        }));
        return false;  
      } else {
        return true;
      }
    });

    if (flags.includes(false)) {
      return;
    }
    
    if (isEmpty(processedStages) || !values.selectedProcessType || !values.name) {
      dispatch(showCustomAlert({
        type: 'error',
        open: true,
        message: toSaveAlert
      }));
      return;
    }

    if (processedStages[0].isSelfApprove && values.selectedProcessType === 'maintenance') {
      dispatch(showCustomAlert({
        type: 'warning',
        open: true,
        message: maintenanceAlert
      }));
      return;
    }

    const body = { ...values, processStages: processedStages, usersProcess, validMessages };
    if (!id) {
      body.idUserProfile = idUserProfile;
      postDB('processes', body)
        .then(response => {
          dispatch(showSavedAlert());
          reloadTable();
        })
        .catch(error => console.log(error));
    } else {
      updateDB('processes/', body, id[0])
        .then(response => {
          dispatch(showUpdatedAlert());
          reloadTable();
        })
        .catch(error => console.log(error));
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setProcessStages([]);
    setStages([]);
    setValues({
      name: '',
      layoutMessageName: '',
      selectedUserNotification: '',
      selectedUserApprovals: '',
      notificationsForUsers: {},
      selectedProcessType: ''
    });
    setProfileSelected(null);
    setShowModal(false);
    setValue4(0);
    setOriginalStages([]);
    setUsersProcess({
      selectedUserNotification: '',
      selectedUserApproval: '',
      notificationsDisabled: true,
      approvalsDisabled: true
    });
    setValidMessages({
      notifications: {},
      approvals: {},
      selectedUserNotifications: '',
      selectedUserApprovals: ''
    });
    setNotifications([]);
    setApprovals([]);
    setSelectedStage('');
    setSelectedStageObject({});
    setIdUserProfile('');
  };

  const [notifications, setNotifications] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [stageLayouts, setStageLayouts] = useState([]);
  const [selectedStageLayout, setSelectedStageLayout] = useState([]);
  const [selectedStage, setSelectedStage] = useState('');
  const [selectedStageObject, setSelectedStageObject] = useState('');

  useEffect(() => {
    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('processes/', id[0])
      .then((response) => response.json())
      .then((data) => {
        const { processStages, usersProcess, validMessages, selectedProcessType } = data.response;
        const values = omit(data.response, '_id');

        getDB('processStages')
          .then((response) => response.json())
          .then((data) => {
            const stages = data.response.map(({ _id, name, notifications, approvals, customFieldsTab, isAssetEdition, isCustomLockedStage, isSelfApprove, isSelfApproveContinue, isControlDueDate }) => ({ id: _id, name, notifications, approvals, customFieldsTab, goBackEnabled: false, goBackTo: '', isAssetEdition, isCustomLockedStage, isSelfApprove, isSelfApproveContinue, isControlDueDate }));
            let updateFlag = false;

            const stagesUpdated = processStages.map(({id, goBackEnabled, goBackTo}) => {
              const stageUpdated = stages.find(({ id: stageId }) => stageId === id );

              if (!stageUpdated) {
                updateFlag = true;
                dispatch(showCustomAlert({
                  type: 'warning',
                  open: true,
                  message: deprecatedStageAlert
                }));
                setProcessStages(processStages);
              }
              return {...stageUpdated, goBackEnabled, goBackTo};
            });

            if (!updateFlag) {
              setProcessStages(stagesUpdated);
            }
          })
          .catch(error => console.log(error));

        setValues(values);
        setUsersProcess(usersProcess);
        setValidMessages(validMessages);
      })
      .catch(error => console.log(error));
  }, [id, showModal]);

  useEffect(() => {
    const selectedStages = processStages.map(st => (st.id));
    getDB('processStages')
      .then(response => response.json())
      .then(data => {
        const stages = data.response.map(({ _id, name, notifications, approvals, customFieldsTab, isAssetEdition, isCustomLockedStage, isSelfApprove, isSelfApproveContinue, isControlDueDate }) => ({ id: _id, name, notifications, approvals, customFieldsTab, goBackEnabled: false, goBackTo: '', isAssetEdition, isCustomLockedStage, isSelfApprove, isSelfApproveContinue, isControlDueDate }));
        const filteredStages = stages.filter(st => !selectedStages.includes(st.id));
        setStages(filteredStages);
        setOriginalStages(stages);
      })
      .catch(error => console.log(error));

    getDB('settingsLayoutsStages')
      .then(response => response.json())
      .then(data => {
        const stageLayoutsData = data.response.map(({ _id, name, selectedStage, sendMessageAt }) => ({ id: _id, name, selectedStage, sendMessageAt }));
        setStageLayouts(stageLayoutsData);
      })
      .catch(error => console.log(error));
  }, [id, processStages]);


  const [idUserProfile, setIdUserProfile] = useState('');

  const handleStageClick = (stage) => {
    if (!processStages.includes(stage)) {
      const localProcessStages = processStages;
      localProcessStages.push(stage);
      setProcessStages(localProcessStages);
    }
    const localStages = stages.filter(st => st.id !== stage.id);
    setStages(localStages);
  };

  const handleRemoveProcessStage = (stage) => {
    const localProcessStages = processStages.filter(st => st.id !== stage.id);
    if (selectedStageObject.id === stage.id) {
      setSelectedStageObject({})
    }
    setProcessStages(localProcessStages);
    if (!stages.includes(stage)) {
      const localStages = stages;
      stages.push(stage);
      setStages(localStages);
    }
  };


  //
  const [messagesTabIndex, setMessagesTabIndex] = useState(0);

  const handleToggleChecks = (type, id) => event => {
    const userMap = { notifications: 'selectedUserNotifications', approvals: 'selectedUserApprovals' };
    const user = validMessages[userMap[type]];
    let userFiltered = validMessages[type][selectedStage][user];
    const userFilteredSpecific = userFiltered.filter(msg => msg.id === id)[0];

    if (userFilteredSpecific) {
      userFilteredSpecific.checked = event.target.checked;

      const tmpValidMessages = validMessages[type];
      tmpValidMessages[selectedStage][user] = userFiltered;
      setValidMessages(prev => ({ ...prev, [type]: tmpValidMessages }));
    }
    else {
      const layoutToAdd = stageLayouts.find(stage => stage.id === id);
      const tmpValidMessages = validMessages[type];
      tmpValidMessages[selectedStage][user].push({ ...layoutToAdd, checked: event.target.checked });
      setValidMessages(prev => ({ ...prev, [type]: tmpValidMessages }));
    }
  };

  const getStagesGoBack = () => {
    if (isEmpty(processStages)) {
      return [];
    }
    const lastStageIndex = processStages.findIndex(({ id }) => id === selectedStage);
    const stages = processStages.filter((stage, ix) => ix < lastStageIndex && (stage.approvals.length && !stage.isSelfApproveContinue));
    return stages;
  };

  const handleStageProcessClick = (id) => {
    const currentStageLayout = stageLayouts.filter(layout => layout.selectedStage === id);
    const selectedStage = originalStages.find(stage => stage.id === id);
    if (!selectedStage) {
      return;
    }
    const { notifications, approvals } = selectedStage;
    setNotifications(notifications);
    setApprovals(approvals);
    setSelectedStageLayout(currentStageLayout);
    setSelectedStage(id);
    setSelectedStageObject(processStages.find(({ id: stageid }) => id === stageid));

    const tmpValidMessages = validMessages;
    if (!validMessages.notifications[id]) {
      tmpValidMessages.notifications[id] = {};
    }
    if (!validMessages.approvals[id]) {
      tmpValidMessages.approvals[id] = {};
    }
    tmpValidMessages.selectedStage = id;
    tmpValidMessages.selectedUserNotifications = '';
    tmpValidMessages.selectedUserApprovals = '';
    setValidMessages(tmpValidMessages);

    setUsersProcess(prev => ({ ...prev, selectedUserNotification: '', selectedUserApproval: '' }))
  };

  const handleStagesMovement = (stages) => {
    const goBackReseted = stages.map((stage) => ({ ...stage, goBackEnabled: false, goBackTo: '' }));
    setProcessStages(goBackReseted);
  }

  const notificationsList = selectedStage && validMessages.selectedUserNotifications ?
    (stageLayouts.filter(({ selectedStage: layoutSelectedStage }) => layoutSelectedStage === selectedStage)) : [];

  const approvalsList = selectedStage && validMessages.selectedUserApprovals.length ?
    (stageLayouts.filter(({ selectedStage: layoutSelectedStage }) => layoutSelectedStage === selectedStage)) : [];

  return (
    <div style={{ width: '1000px' }}>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={showModal}
      >
        <DialogTitle5
          id="customized-dialog-title"
          onClose={handleCloseModal}
        >
          {`${id ? (readOnly ? GetTranslatedValue('GENERAL.CAPTION.VIEW') : GetTranslatedValue('GENERAL.CAPTION.EDIT')) : GetTranslatedValue('GENERAL.CAPTION.ADD')} ${GetTranslatedValue('PROCESSES.MODAL.CAPTION')}`}
        </DialogTitle5>
        <DialogContent5 dividers>
          <div className="kt-section__content" style={{ margin: '-16px' }}>
            <div className={classes4.root}>
              <Paper className={classes4.root}>
                <Tabs
                  value={value4}
                  onChange={handleChange4}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Flow" />
                </Tabs>
              </Paper>
              <TabContainer4 dir={theme4.direction}>
                <div className={classes.tabWrapper}>
                  <div className={classes.tabContent}>
                    <TextField
                      id="standard-name"
                      label={GetTranslatedValue('PROCESSES.CAPTION.NAME','Name')}
                      className={classes.textField}
                      value={values.name}
                      onChange={handleChange("name")}
                      margin="normal"
                      inputProps={{
                        readOnly: readOnly
                      }}
                    />
                    <FormControl className={classes.textField} style={{ marginTop: '10px' }}>
                      <InputLabel htmlFor="age-simple">{GetTranslatedValue('PROCESSES.MODAL.PROCESS.TYPE','Process Type')}</InputLabel>
                      <Select
                        value={values.selectedProcessType}
                        onChange={handleChange('selectedProcessType')}
                        disabled={readOnly}
                      >
                        {(processTypes || []).map(({ id, label }, ix) => (
                          <MenuItem key={`opt-${ix}`} value={id}>{label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <List
                      style={{ marginTop: '20px', width: '100%' }}
                      component="nav"
                      className={classes.root}
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <TextField
                          className={classes.list}
                          id="outlined-adornment-password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          label={GetTranslatedValue('PROCESSES.CAPTION.ALL.STAGES', 'All Stages')}
                          onChange={handleSearchStage}
                          placeholder="Search stage..."
                          value={searchStage}
                        />
                      }
                    >
                      <div style={{ maxHeight: '200px', overflow: 'auto', marginTop: '10px' }}>
                        {(stages || []).map((stage, ix) => (
                          <ListItem
                            key={`${stage.name}_${stage.name}`}
                            button
                            disabled={readOnly}
                            onClick={() => handleStageClick(stage)}
                          >
                            <ListItemIcon>
                              <AccountTree />
                            </ListItemIcon>
                            <ListItemText primary={stage.name} />
                          </ListItem>
                        ))}
                      </div>
                    </List>
                  </div>
                  <div style={{ minHeight: '400px' }} className={classes.tabContent}>
                    <ProcessFlow
                      onClick={handleStageProcessClick}
                      processStages={processStages}
                      setProcessStages={(stages) => handleStagesMovement(stages)}
                      handleRemoveProcessStage={handleRemoveProcessStage}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className={classes.tabContent} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '250px' }}>
                      <CardContent style={{ display: 'flex', flexDirection: 'column', minHeight: '400px' }}>
                        <Typography className={classes.title} noWrap color="textPrimary" gutterBottom>
                          {`${GetTranslatedValue('PROCESSES.MODAL.STAGE.PROPERTIES', 'Stage Properties')}:`}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ width: '50px', marginRight: '10px' }} color="textPrimary" gutterBottom>
                          {`${GetTranslatedValue('PROCESSES.MODAL.STAGE.SELECTED', 'Selected')}:`}
                          </Typography>
                          <Typography className={classes.subTitle} noWrap gutterBottom>
                            {selectedStageObject?.name ? `${selectedStageObject?.name}` : GetTranslatedValue('PROCESSES.MODAL.NO.STAGE.SELECTED', 'No stage selected')}
                          </Typography>
                        </div>
                        <FormControlLabel
                          value="end" 
                          control={<Switch color="primary" checked={selectedStageObject?.goBackEnabled || false} onChange={handleChangeGoBack('goBackEnabled')} />}
                          label={GetTranslatedValue('PROCESSES.CAPTION.GO.BACK','Go Back')}
                          labelPlacement="end"
                          disabled={!selectedStageObject.name || readOnly}
                        />
                        <FormControl disabled={selectedStageObject.goBackEnabled ? false : true} >
                          <InputLabel>{`${GetTranslatedValue('PROCESSES.MODAL.TO.STAGE', 'To stage')}:`}</InputLabel>
                          <Select
                            value={selectedStageObject?.goBackTo || 'none'}
                            onChange={handleChangeGoBack('goBackTo')}
                            disabled={readOnly}
                          >
                            <MenuItem value='none'>
                              <em>None</em>
                            </MenuItem>
                            {getStagesGoBack().map(({ id, name }) => (
                              <MenuItem value={id} name={name}>{name}</MenuItem>
                            ))}
                          </Select>
                          {
                            selectedStageObject?.goBackEnabled && (!selectedStageObject?.goBackTo || selectedStageObject?.goBackTo === 'none' ) && (
                              <FormHelperText style={{ color:'red' }}>{GetTranslatedValue("GENERAL.REQUIRED")}</FormHelperText>
                            )
                          }
                        </FormControl>
                        <div style={{ marginTop: '20px' }}>
                          <AppBar position="static" color="default">
                            <Tabs
                              value={messagesTabIndex}
                              onChange={(_, newValue) => setMessagesTabIndex(newValue)}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="standard"
                              aria-label="full width tabs example"
                            >
                              <Tab style={{ width: '20px' }} disabled={!selectedStageObject.name} label={GetTranslatedValue('PROCESSES.CAPTION.NOTIFICATIONS', 'Notifications')} />
                              <Tab label={GetTranslatedValue('PROCESSES.CAPTION.APPROVALS', 'Approvals')} disabled={!selectedStageObject.name} />
                            </Tabs>
                          </AppBar>
                          <SwipeableViews
                            axis={'x'}
                            index={messagesTabIndex}
                            onChangeIndex={(index) => setMessagesTabIndex(index)}
                          >
                            <TabContainer4 dir={theme4.direction}>
                              <div style={{ marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
                                <FormControl>
                                  <InputLabel>{`${GetTranslatedValue('PROCESSES.CAPTION.NOTIFICATION.USERS', 'Notification Users')}:`}</InputLabel>
                                  <Select
                                    value={usersProcess.selectedUserNotification}
                                    onChange={handleChangeUserNotifApp('selectedUserNotification')}
                                    disabled={!selectedStageObject.name}
                                  >
                                    {
                                      !selectedStage ? (
                                        <MenuItem value=''>
                                          <em>{GetTranslatedValue('PROCESSES.MODAL.CAPTION.SELECT.STAGE', 'Please Select a Stage First')}</em>
                                        </MenuItem>
                                      ) : (
                                        <MenuItem value=''>
                                          <em>{GetTranslatedValue('GENERAL.CAPTION.NONE', 'None')}</em>
                                        </MenuItem>
                                      )
                                    }
                                    {notifications.map(({ _id, email, name, lastName }) => (
                                      <MenuItem value={_id} name={email}>{`${name} ${lastName} (${email})`}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <List
                                  dense
                                  className={classes.root}
                                  style={{ marginTop: '15px' }}
                                  subheader={<ListSubheader disableGutters component="div">{`${GetTranslatedValue('PROCESSES.CAPTION.NOTIFICATION.MESSAGES', 'Notification Messages')}:`}</ListSubheader>}
                                >
                                  {notificationsList.map(({ name, sendMessageAt, id }) => {
                                    const labelId = `checkbox-list-secondary-label-${name}`;
                                    return (
                                      <ListItem key={name} button disabled={usersProcess.notificationsDisabled}>
                                        <ListItemText id={labelId} primary={name} secondary={sendMessageAt === 'start' ? GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.AT.START','At the start') : sendMessageAt === 'end' ? GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.AT.END','At the end') : null} />
                                        <ListItemSecondaryAction>
                                          <Checkbox
                                            edge="end"
                                            disabled={readOnly}
                                            checked={validMessages.notifications[validMessages.selectedStage][validMessages.selectedUserNotifications].find(({ id: layoutID }) => layoutID === id)?.checked}
                                            onChange={handleToggleChecks('notifications', id)}
                                          />
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              </div>
                            </TabContainer4>
                            <TabContainer4 dir={theme4.direction}>
                              <div style={{ marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
                                <FormControl>
                                  <InputLabel>{`${GetTranslatedValue('PROCESSES.CAPTION.APPROVAL.USERS', 'Approval Users')}:`}</InputLabel>
                                  <Select
                                    value={usersProcess.selectedUserApproval}
                                    onChange={handleChangeUserNotifApp('selectedUserApproval')}
                                    disabled={!selectedStageObject.name}
                                  >
                                    {
                                      !selectedStage ? (
                                        <MenuItem value=''>
                                          <em>{GetTranslatedValue('PROCESSES.MODAL.CAPTION.SELECT.STAGE', 'Please Select a Stage First')}</em>
                                        </MenuItem>
                                      ) : (
                                        <MenuItem value=''>
                                          <em>{GetTranslatedValue('GENERAL.CAPTION.NONE', 'None')}</em>
                                        </MenuItem>
                                      )
                                    }
                                    {approvals.map(({ _id, email, name, lastName }) => (
                                      <MenuItem value={_id} name={email}>{`${name} ${lastName} (${email})`}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <List
                                  dense
                                  className={classes.root}
                                  style={{ marginTop: '15px' }}
                                  subheader={<ListSubheader disableGutters className={classes.layoutsListSubheader} component="div">{`${GetTranslatedValue('PROCESSES.CAPTION.APPROVAL.MESSAGES', 'Approval Messages')}:`}</ListSubheader>}
                                >
                                  {(approvalsList).map(({ name, sendMessageAt, id }) => {
                                    const labelId = `checkbox-list-secondary-label-approvals-${name}`;
                                    return (
                                      <ListItem key={name} button disabled={usersProcess.approvalsDisabled}>
                                        <ListItemText id={labelId} primary={name} secondary={sendMessageAt === 'start' ? GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.AT.START','At the start') : sendMessageAt === 'end' ? GetTranslatedValue('SETTINGS.LAYOUTS.STAGES.AT.END','At the end') : null} />
                                        <ListItemSecondaryAction>
                                          <Checkbox
                                            edge="end"
                                            disabled={readOnly}
                                            checked={validMessages.approvals[validMessages.selectedStage][validMessages.selectedUserApprovals].find(({ id: layoutID }) => layoutID === id)?.checked}
                                            onChange={handleToggleChecks('approvals', id)}
                                          />
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              </div>
                            </TabContainer4>
                          </SwipeableViews>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </TabContainer4>
            </div>
          </div>
        </DialogContent5>
        {!readOnly && (
          <DialogActions5>
            <Button onClick={handleSave} color='primary'>
              {GetTranslatedValue('GENERAL.CAPTION.SAVE.CHANGES', 'Save changes')}
            </Button>
          </DialogActions5>
        )}
      </Dialog>
    </div>
  )
};

export default ModalProcesses;
