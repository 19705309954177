/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { urltoFile } from '../../../crud/api';
import './ImageUpload.scss';
import { GetTranslatedValue } from '../utils';

const useStyles = makeStyles((theme) => ({
  label: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0px',
    width: '100%'
  },
  root: {
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px'
    } 
  }
}));

const ImageUpload = ({
  id,
  children,
  setImage = () => {},
  handleUpdate = () => {},
  setInitialImage = () => {},
  image = null,
  disabled = false,
  showButton = true,
  showDeleteButton = true
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    categoryPic: '/media/misc/placeholder-image.jpg',
    categoryPicDefault: '/media/misc/placeholder-image.jpg'
  });
  const updateValues = (e) => {
    const file = e.target.files[0];
    setImage(file);
    handleUpdate(file);
    setValues({
      ...values,
      categoryPic: URL.createObjectURL(file)
    });
  };

  const getFile = async () => {
    const filename = image.split('/')[5];
    const urls = image.split('/');
    const url = `${urls[3]}/${urls[4]}/${urls[5]}`;
    urltoFile(url, filename, `image/${filename.split('.')[1]}`)
      .then((res) => {
        setImage(res);
        setInitialImage(res);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (image && image?.length) {
      getFile();
    }

    setValues({
      ...values,
      categoryPic: image ? `${image}?${new Date()}` : values.categoryPicDefault
    });
  }, [image]);

  const handleOnDeleteClick = () => {
    setValues({ ...values, categoryPic: values.categoryPicDefault });
    setImage(null);
    handleUpdate(null);
  };

  return (
    <div className={clsx(classes.root, "image-upload-wrapper__picture")}>
      <h4 className="image-upload-wrapper__picture-title">{children}</h4>
      <div className="image-upload-wrapper__picture-wrapper">
        <Button
          className="image-upload-wrapper__picture-delete"
          onClick={handleOnDeleteClick}
          style={{ display: showDeleteButton ? null : 'none', backgroundColor: '#FD397A', color:'#ffffff' }}
          variant="contained"
          disabled={disabled}
        >
          <DeleteIcon />
        </Button>
        <img
          key={Date.now()}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = '/media/misc/placeholder-image.jpg';
          }}
          alt="categoryPic"
          className="image-upload-wrapper__picture-placeholder"
          src={values.categoryPic || values.categoryPicDefault}
        />
      </div>
      <>
        <input
          accept="image/*"
          disabled={disabled}
          id={id}
          onChange={(event) => updateValues(event)}
          style={{ display: 'none' }}
          type="file"
        />
        <label
          className={classes.label}
          htmlFor={id}
        >
          <Button
            color="secondary"
            component="span"
            disabled={disabled}
            style={{ display: showButton ? null : 'none' }}
            variant="contained"
          >
            {GetTranslatedValue('RECORD.BUTTON.IMAGEUPLOAD')}
          </Button>
        </label>
      </>
    </div>
  );
};

ImageUpload.defaultProps = {
  setImage: () => {},
  handleUpdate: () => {},
  setInitialImage: () => {},
  id: '',
  image: null,
  disabled: false,
  showButton: true,
  showDeleteButton: true
};

ImageUpload.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  setImage: PropTypes.func,
  handleUpdate: PropTypes.func,
  setInitialImage: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  showButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool
};

export default ImageUpload;
