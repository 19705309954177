import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../../app/partials/content/Portlet';
import MessagesContainer from './components/MessagesContainer';
import { GetTranslatedValue } from '../utils'

const Messages = ({ user, updateMessages }) => {
  const [tab, setTab] = useState(0);

  return (
    <div className='kt-form kt-form--label-right'>
      <Portlet>
        <PortletHeader
          toolbar={
            <PortletHeaderToolbar>
              <Tabs
                className='builder-tabs'
                component='div'
                onChange={(_, nextTab) => setTab(nextTab)}
                value={tab}
              >
                <Tab label={GetTranslatedValue("MESSAGES.INBOX.TITLE")} />
                <Tab label={GetTranslatedValue("MESSAGES.TRASH.TITLE")} />
              </Tabs>
            </PortletHeaderToolbar>
          }
        />
        {tab === 0 && (
          <PortletBody className='portlet-body'>
            <MessagesContainer
              setTab={setTab}
              tab={tab}
              user={user}
              updateMessages={updateMessages}
            />
          </PortletBody>
        )}
        {tab === 1 && (
          <PortletBody className='portlet-body'>
            <MessagesContainer
              setTab={setTab}
              tab={tab}
              trash
              user={user}
              updateMessages={updateMessages}
            />
          </PortletBody>
        )}
      </Portlet>
    </div>
  );
}

const mapStateToProps = ({ auth: { user }, general: { updateMessages } }) => ({
  user, updateMessages
});

Messages.propTypes = {
  user: PropTypes.shape.isRequired,
  updateMessages: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Messages);
