import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Permission from './Permission';
import useWindowDimensions from '../../hooks';
import { modules, hasAnyReportCollectionToGenerate } from '../../constants';
import { permissionsPerModule, tabsConfig } from '../../Components/Translations/constants';
import { GetTranslatedValue } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      height: '60vh'
    }
  },
  labelContainer: {
    width: 'auto',
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
    }
  },
  scroller: {
    overflow: 'hidden',
    width: '95%'
  },
  scrollButtons: {
    color: theme.palette.secondary.dark,
    height: 'auto'
  },
  permissionContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '0px 10px',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      padding: '0px'
    }
  }
}));

const Permissions = ({ profilePermissions, readOnly, setProfilePermissions }) => {
  const classes = useStyles();
  const dimensions = useWindowDimensions();
  const [permissionTab, setPermissionTab] = useState({
    module: modules[0].key,
    index: 0
  });
  const [selectedModule, setSelectedModule] = useState(permissionsPerModule[0]);
  
  const handlePermissionTabIcon = (name) => {
    const array = Object.entries(profilePermissions[name] || {}).filter(([key, value]) => {
      const isReportsGeneral = key === 'general' && name === 'reports';
      
      if (isReportsGeneral) {
        return hasAnyReportCollectionToGenerate(value);
      } else {
        return Array.isArray(value) ? !isEmpty(value) : value;
      }
    });


    if (!isEmpty(array)) {
      return <VisibilityIcon fontSize="small" style={{ margin: '0px 2px' }} />;
    } else {
      return <VisibilityOffIcon fontSize="small" style={{ margin: '0px 2px' }} />;
    }
  };

  const handleSetPermissions = (module, tab, checked) => {
    setProfilePermissions((prev) => ({
      ...prev,
      [module]: {
        ...(prev[module] || {}),
        [tab]: checked
      }
    }));
  };

  const getTabOrientation = () => {
    if (dimensions.width < 600) {
      return 'horizontal';
    }

    return 'vertical';
  };

  const handleTabChange = (index) => {
    setPermissionTab({
      module: tabsConfig.modules.map(({ name }) => name)[index] || 'app',
      index
    });
  };

  useEffect(() => {
    setSelectedModule(permissionsPerModule.find(({ key }) => key === permissionTab.module));
  }, [permissionTab]);

  const orientation = getTabOrientation();
  
  return (
    <div className={classes.root}>
      <Tabs
        classes={{
          scroller: classes.scroller,
          scrollButtons: classes.scrollButtons
        }}
        onChange={(event, nextTab) => handleTabChange(nextTab)}
        orientation={orientation}
        style={{ margin: '10px 15px', minWidth: '160px' }}
        value={permissionTab.index}
        variant='scrollable'
        scrollButtons='on'
      >
        {[...modules, { key: 'app', name:'GENERAL.PERMISSIONS.APP' }].map(({ key, name }) => (
          <Tab
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer
            }}
            key={`${name}-permissions`}
            icon={handlePermissionTabIcon(key)}
            label={
              <Typography noWrap style={{ fontSize: '0.8rem' }}>
                {GetTranslatedValue(name)}
              </Typography>
            }
            style={{ alignSelf: 'center' }}
          />
        ))}
      </Tabs>
      <div className={classes.permissionContainer}>
        {Object.entries(selectedModule.permissions).map(([key, value]) => (
          <Permission
            id={key}
            items={value}
            key={`${selectedModule.key}-${key}`}
            module={selectedModule.key}
            originalChecked={profilePermissions}
            permissions={profilePermissions}
            readOnly={readOnly}
            setPermissions={handleSetPermissions}
            title={key.capitalize()}
          />
        ))}
      </div>
    </div>
  );
};

export default Permissions;
