import React, { useEffect, useState } from 'react';
import CompressedFileButton from './CompressedFileButton';
import { GetTranslatedValue } from '../../../utils';
import TableComponent from '../../../Components/TableComponent';
import ModalCustomFields from '../../modals/ModalCustomFields';
import { getDB } from '../../../../../crud/api';

const CustomFields = ({
  headers,
  onCustomFieldsChange,
  file,
  onFileChange,
  referenceCollection,
  show,
  usedHeaders
}) => {
  const [control, setControl] = useState({
    id: null,
    mirror: [],
    rows: [],
    rowsSelected: [],
    readOnly: false,
    showModal: false
  });
  const [references, setReferences] = useState([]);

  const headRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'id' },
    {
      id: 'field',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('IMPORT.CUSTOM.FIELD', 'Field Name')
    },
    {
      id: 'dataType',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('IMPORT.CUSTOM.DATA.TYPE', 'Data Type')
    },
    {
      id: 'header',
      numeric: false,
      disablePadding: false,
      label: GetTranslatedValue('IMPORT.CUSTOM.HEADER', 'Header')
    }
    // { id: "category", numeric: false, disablePadding: false, label: "Category" }
  ];

  const tableActions = () => {
    return {
      onAdd() {
        setControl((prev) => ({ ...prev, id: null, showModal: true }));
      },
      onEdit(id) {
        setControl((prev) => ({ ...prev, id, showModal: true, readOnly: false }));
      },
      onDelete(id) {
        const rows = control.rows.filter(({ id: rowId }) => !id.includes(rowId));
        const mirrorRows = control.mirror.filter(({ id: rowId }) => !id.includes(rowId));
        setControl((prev) => ({ ...prev, mirror: mirrorRows, rows }));
      },
      onSelect(id) {},
      onView(id) {
        setControl((prev) => ({ ...prev, id, showModal: true, readOnly: true }));
      }
    };
  };

  const createRow = (id, field, dataType, category, header) => {
    return { id, field, dataType, category, header };
  };

  const parseCategories = (category) => {
    if (!category) {
      return null;
    }

    return category.map(({ label }) => label).join(', ');
  };

  const handleAdd = (data) => {
    const { id, field, dataType, category, header } = data;
    const row = createRow(id, field, dataType, category, header);
    const rows = [row, ...control.rows];
    const mirrorRow = createRow(id, field, dataType.label, parseCategories(category), header.label);

    onCustomFieldsChange(rows);
    setControl((prev) => ({
      ...prev,
      mirror: [mirrorRow, ...prev.mirror],
      rows,
      showModal: false
    }));
  };

  const handleEdit = (index, data) => {
    const rows = control.rows;
    rows[index] = data;
    const { id, field, dataType, category, header } = rows[index];
    const mirrorRows = control.mirror;
    const mirrorRow = createRow(id, field, dataType.label, parseCategories(category), header.label);
    mirrorRows[index] = mirrorRow;

    onCustomFieldsChange(rows);
    setControl((prev) => ({ ...prev, mirror: mirrorRows, rows, showModal: false }));
  };

  useEffect(() => {
    if (referenceCollection) {
      getDB(referenceCollection)
        .then((response) => response.json())
        .then((data) =>
          setReferences(data.response.map(({ _id, name }) => ({ value: _id, label: name })))
        );
    }
  }, [referenceCollection]);

  return (
    <>
      <ModalCustomFields
        categories={references}
        customFields={control.rows}
        headers={headers.map((header) => ({ label: header, value: header }))}
        id={control.id}
        readOnly={control.readOnly}
        onAdd={handleAdd}
        onEdit={handleEdit}
        referenceCollection={referenceCollection}
        setShowModal={(onOff) => setControl({ ...control, showModal: onOff })}
        showModal={control.showModal}
        usedHeaders={usedHeaders}
      />
      <div className="kt-section__content">
        {show && (
          <CompressedFileButton
            filename={file?.name}
            key="compressed-file-button-custom-fields"
            tooltipText={GetTranslatedValue('IMPORT.CUSTOM.COMPRESSED.INFO')}
            onFileChange={onFileChange('customFieldsTab')}
          />
        )}
        <TableComponent
          headRows={headRows}
          onAdd={tableActions().onAdd}
          onDelete={tableActions().onDelete}
          onEdit={tableActions().onEdit}
          onSelect={tableActions().onSelect}
          onView={tableActions().onView}
          permissions={['add', 'edit', 'delete', 'view']}
          rows={control.mirror}
          title={GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS')}
        />
      </div>
    </>
  );
};

export default CustomFields;
