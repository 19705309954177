export const userProfilesPermissions = ['assets_permissions', 'references_permissions', 'categories_permissions', 'assets_policies_permissions', 'processes_live_permissions',
  'processes_list_permissions', 'processes_stages_permissions', 'users_permissions', 'user_profiles_permissions', 'user_policies_permissions', 'employees_permissions', 'employee_profiles_permissions',
  'employee_policies_permissions', 'locations_permissions', 'location_profiles_permissions', 'locations_policies_permissions', 'saved_reports_permissions'
];

// const importIdCriteria = (header) => header !== 'importId';

const layouts = {
  assets: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'EPC',
      translation: 'EPC'
    },
    {
      id: 'reference',
      translation: 'GENERAL.CAPTION.REFERENCE'
    },
    {
      id: 'employee_id',
      translation: 'EMPLOYEE.CAPTION.ID'
    },
    {
      id: 'location_id',
      translation: 'LOCATIONS.CAPTION.ID'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'status',
      translation: 'GENERAL.CAPTION.STATUS'
    },
    {
      id: 'notes',
      translation: 'ASSETS.CAPTION.NOTES'
    },
    {
      id: 'serial',
      translation: 'GENERAL.CAPTION.SERIALNUMBER'
    },
    {
      id: 'purchase_date',
      translation: 'ASSETS.CAPTION.PURCHASEDATE'
    },
    {
      id: 'purchase_price',
      translation: 'ASSETS.CAPTION.PURCHASEPRICE'
    },
    {
      id: 'price',
      translation: 'GENERAL.CAPTION.PRICE'
    },
    {
      id: 'total_price',
      translation: 'ASSETS.CAPTION.TOTALPRICE'
    },
    {
      id: 'labeling_date',
      translation: 'ASSETS.CAPTION.LABELINGDATE'
    }
  ],
  references: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'category',
      translation: 'GENERAL.CAPTION.CATEGORY'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'brand',
      translation: 'ASSETS.CAPTION.BRAND'
    },
    {
      id: 'model',
      translation: 'ASSETS.CAPTION.MODEL'
    },
    {
      id: 'price',
      translation: 'GENERAL.CAPTION.PRICE'
    }
  ],
  categories: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'depreciation',
      translation: 'CATEGORIES.CAPTION.DEPRECIATION'
    }
  ],
  user: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'profile',
      translation: 'RECORD.CAPTION.PROFILE'
    },
    {
      id: 'boss_email',
      translation: 'GENERAL.BOSS.EMAIL'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'lastName',
      translation: 'RECORD.CAPTION.LASTNAME'
    },
    {
      id: 'email',
      translation: 'RECORD.CAPTION.EMAIL'
    },
    {
      id: 'assignedLocations',
      translation: 'GENERAL.ASSIGNED.LOCATIONS'
    }
  ],
  userProfiles: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    }
  ],
  employees: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'employee_id',
      translation: 'EMPLOYEE.CAPTION.ID'
    },
    {
      id: 'layoutSelected',
      translation: 'EMPLOYEE.CAPTION.LAYOUT'
    },
    {
      id: 'profile',
      translation: 'RECORD.CAPTION.PROFILE'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'lastName',
      translation: 'RECORD.CAPTION.LASTNAME'
    },
    {
      id: 'email',
      translation: 'RECORD.CAPTION.EMAIL'
    }
  ],
  employeeProfiles: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'isAssetRepository',
      translation: 'EMPLOYEE.PROFILE.ASSETREPOSITORY'
    }
  ],
  locationsReal: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'profile',
      translation: 'RECORD.CAPTION.PROFILE'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'latitude',
      translation: 'SETTINGS.GENERAL.CAPTION.LATITUDE'
    },
    {
      id: 'longitude',
      translation: 'SETTINGS.GENERAL.CAPTION.LONGITUDE'
    },
    {
      id: 'zoom',
      translation: 'SETTINGS.GENERAL.CAPTION.ZOOM'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'parent',
      translation: 'GENERAL.CAPTION.PARENT'
    }
  ],
  locationProfiles: [
    {
      id: '_id',
      translation: 'ID'
    },
    {
      id: 'importId',
      translation: 'GENERAL.IMPORT.ID'
    },
    {
      id: 'photo',
      translation: 'GENERAL.PHOTO'
    },
    {
      id: 'name',
      translation: 'RECORD.CAPTION.NAME'
    },
    {
      id: 'level',
      translation: 'LOCATIONS.CAPTION.LEVEL'
    },
    {
      id: 'isAssetRepository',
      translation: 'EMPLOYEE.PROFILE.ASSETREPOSITORY'
    }
  ]
  // assetsReferences: ['reference_id', 'reference_import_id', 'category_id', 'category_import_id', 'reference_photo', 'reference_name', 'reference_brand', 'reference_model', 'reference_price'],
  // assetsReferencesCategories: ['category_id', 'category_import_id', 'category_photo', 'category_name', 'category_depreciation'],
  // referencesCategories: ['category_id', 'category_import_id', 'category_photo', 'category_name', 'category_depreciation'],
  // usersAndProfiles: ['profile_id', 'profile_import_id', 'profile_photo', 'profile_name', ...userProfilesPermissions],
  // userProfiles: ['_id', 'importId', 'photo', 'name', ...userProfilesPermissions],}
  // employeesAndProfiles: ['profile_id', 'profile_import_id', 'profile_photo', 'profile_name', 'profile_is_asset_repository'],
  // locationsAndProfiles: ['profile_id', 'profile_import_id', 'profile_photo', 'profile_name', 'profile_level', 'profile_asset_repository', 'profile_location_control'],
};

export const layoutHeaders = {
  assets: [...layouts.assets],
  // assetsReferences: [...layouts.assets.filter((header) => importIdCriteria(header)), ...layouts.assetsReferences],
  // assetsReferencesCategories: [...layouts.assets.filter((header) => importIdCriteria(header)), ...layouts.assetsReferences.filter((header) => !['category_id', 'category_import_id'].includes(header)), ...layouts.assetsReferencesCategories],
  references: [...layouts.references],
  // referencesCategories: [...layouts.references, ...layouts.referencesCategories],
  categories: [...layouts.categories],
  user: [...layouts.user],
  // usersAndProfiles: [...layouts.user.filter(((header) => importIdCriteria(header))), ...layouts.usersAndProfiles],
  userProfiles: [...layouts.userProfiles],
  employees: [...layouts.employees],
  // employeesAndProfiles: [...layouts.employees.filter(((header) => importIdCriteria(header))), ...layouts.employeesAndProfiles],
  employeeProfiles: [...layouts.employeeProfiles],
  locationsReal: [...layouts.locationsReal],
  // locationsAndProfiles: [...layouts.locationsReal.filter((header) => importIdCriteria(header)), ...layouts.locationsAndProfiles],
  locationProfiles: [...layouts.locationProfiles]
}

export const requiredHeaders = {
  assets: ['reference', 'importId'],
  assetsReferences: ['category_import_id', 'reference_name', 'reference_brand', 'reference_model'],
  assetsReferencesCategories: ['reference_name', 'reference_brand', 'reference_model', 'category_name'],
  referencesCategories: ['name', 'brand', 'model', 'category_name'],
  references: ['importId', 'name', 'brand', 'model', 'category'],
  categories: ['importId', 'name'],
  user: ['importId', 'name', 'lastName', 'email', 'assignedLocations'],
  usersAndProfiles: ['name', 'lastName', 'email', 'profile_name'],
  userProfiles: ['importId', 'name'],
  employees: ['importId', 'name', 'lastName'],
  employeesAndProfiles: ['name', 'lastName', 'email', 'profile_name'],
  employeeProfiles: ['importId', 'name'],
  locationsReal: ['importId', 'name'],
  locationsAndProfiles: ['name', 'level'],
  locationProfiles: ['importId', 'name' /* ,  'parent', 'profile_name', 'profile_level' */]
};

export const verifyRequiredFields = (usedHeaders, selectedLayout) => {
  for (let i = 0; i < requiredHeaders[selectedLayout].length; i++) {
    if (!usedHeaders.includes(requiredHeaders[selectedLayout][i])) {
      return false;
    }
  }

  return true;
};
