import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Tabs } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/ducks/general.duck';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import { deleteDB, getDBComplex, getCountDB, getDB, getOneDB, updateDB, updateManyDB } from '../../../crud/api';
import * as general from '../../../store/ducks/general.duck';
import { useFieldValidator } from '../Components/FieldValidator/hooks';
import { usePolicies } from '../Components/Policies/hooks';
import { executePolicies } from '../Components/Policies/utils';
import TableComponent2 from '../Components/TableComponent2';
import { TabsTitles } from '../Components/Translations/tabsTitles';
import ModalYesNo from '../Components/ModalYesNo';
import Policies from '../Components/Policies/Policies';
import { loadGroups, updateTableGroupingTree } from '../Components/tableGroupingHelpers';
import ModalEmployees from './modals/ModalEmployees';
import ModalEmployeeProfiles from './modals/ModalEmployeeProfiles';
import { allBaseFields } from '../constants';
import { convertToObjectIdArray, getCurrentModuleName, getCurrentModuleTab, getFieldCaption, GetTranslatedValue, profileHasChildren, showWarningMessage } from '../utils';

const Employees = ({ globalSearch, setGeneralSearch, user }) => {
  const dispatch = useDispatch();
  const { showDeletedAlert, showErrorAlert, setGeneralLoading } = actions;
  const [selectReferenceConfirmation, setSelectReferenceConfirmation] = useState(false);
  const [tab, setTab] = useState(getCurrentModuleTab('employees', user.profilePermissions));
  const [allEmployeeProfiles, setAllEmployeeProfiles] = useState([]);
  const policiesBaseFields = {
    list: { ...allBaseFields.employees, translationLabel: 'TABS.TITLES.LIST' },
    references: { ...allBaseFields.employeeReferences, translationLabel: 'TABS.TITLES.PROFILES' }
  };
  const messages = {
    employeeAssignments: GetTranslatedValue('NO.DELETE.EMPLOYEE.ASSETS.ASSIGNED'),
    profileChildren: GetTranslatedValue('NO.DELETE.EMPLOYEE.PROFILE.CHILDREN')
  };

  const { policies, setPolicies } = usePolicies();
  const { allFields, fieldsToValidate } = useFieldValidator('employees');

  const executeFieldCaption = (catalogue, { field, key }) => {
    return getFieldCaption(allFields, { catalogue, field, key });
  };

  const createUserProfilesRow = (id, name, creator, creationDate, updateDate, fileExt) => {
    return { id, name, creator, creationDate, updateDate, fileExt };
  };

  const employeeProfilesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employeeReferences', {
        field: 'name',
        key: allBaseFields.employeeReferences.name.translation
      })
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employeeReferences', {
        field: '',
        key: 'RECORD.CAPTION.CREATOR'
      }),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employeeReferences', {
        field: '',
        key: 'RECORD.CAPTION.CREATIONDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employeeReferences', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const createEmployeeRow = (
    id,
    name,
    lastName,
    email,
    employeeProfile,
    layout,
    employee_id,
    manager,
    creator,
    creationDate,
    updateDate,
    fileExt
  ) => {
    return {
      id,
      name,
      lastName,
      email,
      employeeProfile,
      layout,
      employee_id,
      manager,
      creator,
      creationDate,
      updateDate,
      fileExt
    };
  };

  const employeesHeadRows = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: 'name',
        key: allBaseFields.employees.name.translation
      })
    },
    {
      id: 'lastName',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: 'lastName',
        key: allBaseFields.employees.lastName.translation
      })
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: 'email',
        key: allBaseFields.employees.email.translation
      }),
      disableTableGrouping: true
    },
    {
      id: 'employeeProfile',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: 'employeeProfile',
        key: allBaseFields.employees.employeeProfile.translation
      }),
      correction: 'employeeProfile.label'
    },
    {
      id: 'layout',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: 'responsibilityLayout',
        key: allBaseFields.employees.responsibilityLayout.translation
      }),
      correction: 'layoutSelected.label'
    },
    {
      id: 'employee_id',
      numeric: true,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: 'employee_id',
        key: allBaseFields.employees.employee_id.translation
      }),
      disableTableGrouping: true
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: '',
        key: 'RECORD.CAPTION.CREATOR'
      }),
      correction: 'creationUserFullName',
      searchByDisabled: true
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: '',
        key: 'RECORD.CAPTION.CREATIONDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: false,
      label: executeFieldCaption('employees', {
        field: '',
        key: 'RECORD.CAPTION.UPDATEDATE'
      }),
      searchByDisabled: true,
      disableTableGrouping: true
    }
  ];

  const [tableControl, setTableControl] = useState({
    employees: {
      collection: 'employees',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: '',
      tableGrouping: []
    },
    employeeProfiles: {
      collection: 'employeeProfiles',
      total: 0,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'creationDate',
      order: -1,
      search: '',
      searchBy: '',
      locationsFilter: []
    }
  });

  const loadEmployeeProfiles = () => {
    getDB('employeeProfiles')
      .then((response) => response.json())
      .then((data) => {
        const employeeProfiles =
          data.response.map((row) => {
            return createUserProfilesRow(
              row._id,
              row.name,
              row.creationUserFullName,
              row.creationDate,
              row.updateDate,
              row.fileExt
            );
          }) || [];

        setAllEmployeeProfiles(employeeProfiles);
      })
      .catch((error) => console.log(error));
  };
  const styleMultipleRows = (data, collection) => {
    if (collection === 'employees') {
      const rows = data.map((row) => {
        const {
          _id,
          name,
          lastName,
          email,
          employee_id,
          employeeProfile,
          layoutSelected,
          manager,
          creationUserFullName,
          creationDate,
          updateDate,
          fileExt
        } = row;
        const profile = employeeProfile ? employeeProfile?.label || employeeProfile[0]?.label : '';
        const layout = layoutSelected?.label || '';

        return createEmployeeRow(
          _id,
          name,
          lastName,
          email,
          profile,
          layout,
          employee_id,
          manager,
          creationUserFullName,
          creationDate,
          updateDate,
          fileExt
        );
      });
      return rows;
    } else if (collection === 'employeeProfiles') {
      const rows = data.map((row) => {
        const { _id, name, creationUserFullName, creationDate, updateDate, fileExt } = row;
        return createUserProfilesRow(
          _id,
          name,
          creationUserFullName,
          creationDate,
          updateDate,
          fileExt
        );
      });
      return rows;
    }
  };

  const searchColumns = {
    employeeProfile: 'employeeProfile.label',
    layout: 'layoutSelected.label'
  };

  const loadEmployeesData = (collectionNames = ['employees', 'employeeProfiles'], searchBy) => {
    collectionNames = !Array.isArray(collectionNames) ? [collectionNames] : collectionNames;
    collectionNames.forEach((collectionName) => {
      const localSearchBy = searchBy || tableControl[collectionName].searchBy;
      if (!isEmpty(tableControl[collectionName].tableGrouping)) {
        updateTableGroupingTree(
          collectionName,
          tableControl[collectionName].tableGrouping,
          styleMultipleRows,
          tableControl,
          (newTree) => {
            setTableControl((prev) => ({
              ...prev,
              [collectionName]: {
                ...prev[collectionName],
                tableGrouping: newTree
              }
            }));
          },
          tableControl[collectionName].condition
        );
      }
      let queryLike = '';
      if (collectionName === 'employeeProfiles') {
        queryLike = localSearchBy
          ? [
            {
              key: localSearchBy,
              value: tableControl.employeeProfiles.search
            }
          ]
          : ['creationDate', 'name', 'updateDate'].map((key) => ({
            key,
            value: tableControl.employeeProfiles.search
          }));
      }
      if (collectionName === 'employees') {
        queryLike = localSearchBy
          ? [{ key:  searchColumns[localSearchBy] || localSearchBy, value: tableControl.employees.search }]
          : [
            'creationDate',
            'creator',
            'email',
            'employeeProfile.label',
            'lastName',
            'layoutSelected.label',
            'name',
            'updateDate'
          ].map((key) => ({ key, value: tableControl.employees.search }));
      }
      getCountDB({
        collection: collectionName,
        queryLike: tableControl[collectionName].search ? queryLike : null
      })
        .then((response) => response.json())
        .then((data) => {
          setTableControl((prev) => ({
            ...prev,
            [collectionName]: {
              ...prev[collectionName],
              total: data.response?.count
            }
          }));
        });

      getDBComplex({
        collection: collectionName,
        limit: tableControl[collectionName].rowsPerPage,
        skip: tableControl[collectionName].rowsPerPage * tableControl[collectionName].page,
        sort: [
          { key: tableControl[collectionName].orderBy, value: tableControl[collectionName].order }
        ],
        queryLike: tableControl[collectionName]
          .search /* || tableControl['user'].locationsFilter.length */
          ? queryLike
          : null
      })
        .then((response) => response.json())
        .then((data) => {
          if (collectionName === 'employeeProfiles') {
            const rows = data.response.map((row) => {
              const { _id, name, creationUserFullName, creationDate, updateDate, fileExt } = row;
              return createUserProfilesRow(
                _id,
                name,
                creationUserFullName,
                creationDate,
                updateDate,
                fileExt
              );
            });
            setControl((prev) => ({
              ...prev,
              employeeProfilesRows: rows,
              employeeProfilesRowsSelected: []
            }));
          }
          if (collectionName === 'employees') {
            const rows = data.response.map((row) => {
              const {
                _id,
                name,
                lastName,
                email,
                employee_id,
                employeeProfile,
                layoutSelected,
                manager,
                creationUserFullName,
                creationDate,
                updateDate,
                fileExt
              } = row;
              const profile = employeeProfile?.label || (employeeProfile || [])[0]?.label || '';
              const layout = layoutSelected?.label || '';
              return createEmployeeRow(
                _id,
                name,
                lastName,
                email,
                profile,
                layout,
                employee_id,
                manager,
                creationUserFullName,
                creationDate,
                updateDate,
                fileExt
              );
            });
            setControl((prev) => ({ ...prev, usersRows: rows, usersRowsSelected: [] }));
          }
        })
        .catch((error) => console.log('error>', error));
    });
  };

  useEffect(() => {
    loadEmployeeProfiles();
  }, []);

  useEffect(() => {
    loadEmployeesData('employees');
  }, [
    tableControl.employees.page,
    tableControl.employees.rowsPerPage,
    tableControl.employees.order,
    tableControl.employees.orderBy,
    tableControl.employees.search,
    tableControl.employees.locationsFilter
  ]);

  useEffect(() => {
    loadEmployeesData('employeeProfiles');
  }, [
    tableControl.employeeProfiles.page,
    tableControl.employeeProfiles.rowsPerPage,
    tableControl.employeeProfiles.order,
    tableControl.employeeProfiles.orderBy,
    tableControl.employeeProfiles.search
  ]);

  const tabIntToText = ['employees', 'employeeProfiles'];

  useEffect(() => {
    if (globalSearch.tabIndex >= 0) {
      setTab(globalSearch.tabIndex);
      setTableControl((prev) => ({
        ...prev,
        [tabIntToText[globalSearch.tabIndex]]: {
          ...prev[tabIntToText[globalSearch.tabIndex]],
          search: globalSearch.searchValue
        }
      }));
      setTimeout(() => {
        setGeneralSearch({});
      }, 800);
    }
  }, [globalSearch.tabIndex, globalSearch.searchValue]);

  const [control, setControl] = useState({
    employeeProfilesRows: null,
    employeeProfilesRowsSelected: [],
    idEmployeeProfile: null,
    idUser: null,
    openEmployeeProfilesModal: false,
    openUsersModal: false,
    usersRows: null,
    usersRowsSelected: []
  });

  const collections = {
    employeeProfiles: {
      id: 'idEmployeeProfile',
      modal: 'openEmployeeProfilesModal',
      name: 'employeeProfiles'
    },
    employees: {
      id: 'idEmployee',
      modal: 'openEmployeesModal',
      name: 'employees'
    }
  };

  const hasAnyAsset = (employeeId) => {
    return getOneDB('employees/', employeeId)
      .then((response) => response.json())
      .then((data) => {
        const { response: { assetsAssigned } } = data;

        return !isEmpty(assetsAssigned);
      })
      .catch((error) => console.log(error));
  };

  const tableActions = (collectionName) => {
    const collection = collections[collectionName];
    return {
      onAdd() {
        setControl({
          ...control,
          [collection.id]: null,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onEdit(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: false
        });
      },
      onDelete(id) {
        if (!id || !Array.isArray(id)) {
          return;
        }

        getDB('policies')
          .then((response) => response.json())
          .then(async (data) => {
            const { response } = data;
            const copyArray = JSON.parse(JSON.stringify(id));

            if (collection.name === 'employees') {
              for (let i = 0; i < copyArray.length; i++) {
                const flag = await hasAnyAsset(copyArray[i]);

                if (flag) {
                  const index = id.indexOf(copyArray[i]);
                  id.splice(index, 1);
                  showWarningMessage(messages.employeeAssignments);
                }
              }
            } else if (collection.name === 'employeeProfiles') {
              for (let i = 0; i < copyArray.length; i++) {
                const flag = await profileHasChildren('employees', copyArray[i], 'employeeProfile.value');

                if (flag) {
                  const index = id.indexOf(copyArray[i]);
                  id.splice(index, 1);
                  showWarningMessage(messages.profileChildren);
                }
              }
            }

            id.forEach((_id) => {
              deleteDB(`${collection.name}/`, _id)
                .then((response) => response.json())
                .then((employeeData) => {
                  const {
                    response: {
                      value,
                      value: { layoutSelected, assetsAssigned }
                    }
                  } = employeeData;
                  dispatch(showDeletedAlert());
                  const currentCollection = collection.name === 'employees' ? 'list' : 'references';
                  executePolicies('OnDelete', 'employees', currentCollection, response, value);
                  loadEmployeesData(collection.name);

                  if (collection.name === 'employeeProfiles') {
                    loadEmployeeProfiles();
                  }

                  if (collection.name === 'employees' && !isEmpty(assetsAssigned)) {
                    updateManyDB({
                      body: { assigned: null, assignedTo: '' },
                      collection: 'assets',
                      condition: [{ "_id": convertToObjectIdArray(assetsAssigned.map(({ id }) => id)) }]
                    })
                      .catch((error) => console.log(error));

                    if (layoutSelected) {
                      getOneDB('settingsLayoutsEmployees/', layoutSelected.value)
                        .then((response) => response.json())
                        .then((layoutData) => {
                          const {
                            response: { used }
                          } = layoutData;
                          const value = (Number(used || '1') - 1).toString();
                          updateDB(
                            'settingsLayoutsEmployees/',
                            { used: value },
                            layoutSelected.value
                          ).catch((error) => console.log(error));
                        })
                        .catch((error) => console.log(error));
                    }
                  }
                })
                .catch((error) => console.log(error));
            });
          })
          .catch((error) => console.log(error));

        loadEmployeesData(collection.name);
      },
      onSelect(id) {},
      onView(id) {
        setControl({
          ...control,
          [collection.id]: id,
          [collection.modal]: true,
          [`${collection.name}ReadOnly`]: true
        });
      }
    };
  };

  const currentTabName = getCurrentModuleName('employees', tab);

  return (
    <>
      <ModalYesNo
        onCancel={() => setSelectReferenceConfirmation(false)}
        onOK={() => setSelectReferenceConfirmation(false)}
        message={'Please first select a Reference from the next tab'}
        showModal={selectReferenceConfirmation}
        title={'Add New Asset'}
      />
      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  className="builder-tabs"
                  component="div"
                  onChange={(_, nextTab) => setTab(nextTab)}
                  value={tab}
                >
                  {TabsTitles('employees', user.profilePermissions['employees'])}
                </Tabs>
              </PortletHeaderToolbar>
            }
          />

          {tab === 0 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate{' '}
                      <code>Employees List</code>
                    </span> */}
                    <ModalEmployees
                      employeeProfileRows={allEmployeeProfiles}
                      id={control.idEmployee}
                      fields={(allFields || {})['employees']}
                      fieldsToValidate={(fieldsToValidate || {})['employees']}
                      policies={policies}
                      readOnly={control.employeesReadOnly}
                      reloadTable={() => loadEmployeesData('employees')}
                      setShowModal={(onOff) =>
                        setControl({
                          ...control,
                          openEmployeesModal: onOff
                        })
                      }
                      showModal={control.openEmployeesModal}
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.employees}
                        headRows={employeesHeadRows}
                        onAdd={tableActions('employees').onAdd}
                        onDelete={tableActions('employees').onDelete}
                        onEdit={tableActions('employees').onEdit}
                        onSelect={tableActions('employees').onSelect}
                        onView={tableActions('employees').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            employees: {
                              ...prev.employees,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.usersRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.employees.search === value) {
                            loadEmployeesData('employees', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              employees: {
                                ...prev.employees,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            employees: {
                              ...prev.employees,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        tab={currentTabName}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) => {
                          setTableControl((prev) => ({
                            ...prev,
                            employees: {
                              ...prev.employees,
                              tableGrouping: tree
                            }
                          }))
                        }}
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        title={GetTranslatedValue("EMPLOYEE.LIST.TITLE")}
                        tileView
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 1 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-0 kt-margin-b-0">
                <div className="kt-section__body">
                  <div className="kt-section">
                    {/* <span className='kt-section__sub'>
                      This section will integrate <code>User Profiles</code>
                    </span> */}
                    <ModalEmployeeProfiles
                      fields={(allFields || {})['employeeReferences']}
                      fieldsToValidate={(fieldsToValidate || {})['employeeReferences']}
                      policies={policies}
                      readOnly={control.employeeProfilesReadOnly}
                      reloadProfiles={() => loadEmployeeProfiles()}
                      reloadTable={() => loadEmployeesData('employeeProfiles')}
                      setShowModal={(onOff) =>
                        setControl({
                          ...control,
                          openEmployeeProfilesModal: onOff
                        })
                      }
                      showModal={control.openEmployeeProfilesModal}
                      id={control.idEmployeeProfile}
                    />
                    {/* <div className='kt-separator kt-separator--dashed' /> */}
                    <div className="kt-section__content">
                      <TableComponent2
                        controlValues={tableControl.employeeProfiles}
                        headRows={employeeProfilesHeadRows}
                        onAdd={tableActions('employeeProfiles').onAdd}
                        onDelete={tableActions('employeeProfiles').onDelete}
                        onEdit={tableActions('employeeProfiles').onEdit}
                        onSelect={tableActions('employeeProfiles').onSelect}
                        onView={tableActions('employeeProfiles').onView}
                        paginationControl={({ rowsPerPage, page }) =>
                          setTableControl((prev) => ({
                            ...prev,
                            employeeProfiles: {
                              ...prev.employeeProfiles,
                              rowsPerPage: rowsPerPage,
                              page: page
                            }
                          }))
                        }
                        rows={control.employeeProfilesRows}
                        searchControl={({ value, field }) => {
                          if (tableControl.employeeProfiles.search === value) {
                            loadEmployeesData('employeeProfiles', field);
                          } else {
                            setTableControl((prev) => ({
                              ...prev,
                              employeeProfiles: {
                                ...prev.employeeProfiles,
                                search: value,
                                searchBy: field
                              }
                            }));
                          }
                        }}
                        sortByControl={({ orderBy, order }) => {
                          setTableControl((prev) => ({
                            ...prev,
                            employeeProfiles: {
                              ...prev.employeeProfiles,
                              orderBy: orderBy,
                              order: order
                            }
                          }));
                        }}
                        fetchTableGroupingData={loadGroups}
                        setTableGroupingControl={(tree) =>
                          setTableControl((prev) => ({
                            ...prev,
                            employeeProfiles: {
                              ...prev.employeeProfiles,
                              tableGrouping: tree
                            }
                          }))
                        }
                        tableGroupingCreateRows={styleMultipleRows}
                        tableGrouping
                        tab={currentTabName}
                        title={GetTranslatedValue("EMPLOYEE.PROFILE.TITLE")}
                        tileView
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 2 && (
            <Policies
              setPolicies={setPolicies}
              module="employees"
              baseFields={policiesBaseFields}
            />
          )}
        </Portlet>
      </div>
    </>
  );
};

const mapStateToProps = ({ general: { globalSearch }, auth: { user } }) => ({
  globalSearch,
  user
});
export default connect(mapStateToProps, general.actions)(Employees);
