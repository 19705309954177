/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { isEmpty, omit } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Card,
  CardContent,
  IconButton,
  InputBase,
  Typography,
  Tooltip
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { actions } from '../../../../store/ducks/general.duck';
import { getDBComplex, getCountDB, getOneDB, getDB} from '../../../../crud/api';
import { getImageURL, getLocationPath, GetTranslatedValue} from '../../utils';
import LocationsTreeView from '../LocationsTreeView/LocationsTreeView';
import CustomizedToolTip from '../../Components/CustomizedToolTip';
import Table from './Table';
import AssetEdition from './AssetEditon';
import AssetPreviewBox from './AssetPreviewBox';

const AssetFinder = ({
  isAssetReference,
  isSelectionTable,
  showSearchBar,
  onSelectionChange,
  rows,
  onSetRows,
  isPreviewTable,
  processType,
  processInfo,
  updateAssetValues,
  showAssetEdition,
  assetEditionDisabled,
  isLinkedToProcess,
  paginated,
  userLocations,
  parentLocations = [],
  setPreventScroll = () => {}
}) => {
  const { showCustomAlert, showErrorAlert } = actions;
  const dispatch = useDispatch();
  const classes = useStyles();
  const collection = isAssetReference ? 'references' : 'assets';
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(defaultAsset);
  const [locationSelected, setLocationSelected] = useState();
  const [loading, setLoading] = useState();
  const [maxQuantity, setMaxQuantity] = useState(null);
  const [accordionControled, setAccordionControled] = useState({
    location: false,
    assetEditon: false,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [currentSorting, setCurrentSorting] = useState([{ key: '', value: 1 }]);
  const [isDisabled, setIsDisabled] = useState(assetEditionDisabled());
  
  const extractValidAssets = (cartRows, { stages }) => {
    const requestedAssetsIds = cartRows.map(({ id }) => id);
    Object.entries(stages).forEach(([key, { approvals }]) => {
      (approvals || []).forEach(({ cartRows }) => {
        (cartRows || []).forEach(({ id, status }) => {
          if (status !== 'Approved' && requestedAssetsIds.includes(id)) {
            const index = requestedAssetsIds.indexOf(id);
            requestedAssetsIds.splice(index, 1);
          }
        });
      });
    });

    return requestedAssetsIds.map((reqId) => cartRows.find(({ id }) => reqId === id));
  };

  const isProcessComplete = () => {
    if (!isEmpty(processInfo)) {
      const { processData = {} } = processInfo;
      const { selectedProcessType } = processData;
      const stageFulfilled = (processData.stages || []).map(({ stageFulfilled }) => ({ stageFulfilled }));
      if (selectedProcessType === 'short') { // If process is 'short' then simulate a single stage fulfilled so the Process shows as completed
        stageFulfilled.push({ stageFulfilled: true });
      }
      const isProcessComplete = stageFulfilled.every(({ stageFulfilled }) => stageFulfilled === true);

      return isProcessComplete;
    } else {
      return false;
    }
  };

  const getColumns = (isAssetReference) => {
    const assetReference = [
      { field: 'name', headerName: GetTranslatedValue('ASSETS.CAPTION.NAME', 'Name'), width: 130 },
      { field: 'brand', headerName: GetTranslatedValue('ASSETS.CAPTION.BRAND', 'Brand'), width: 130 },
      { field: 'model', headerName: GetTranslatedValue('ASSETS.CAPTION.MODEL', 'Model'), width: 130 }
    ];

    const realAssetFields = [
      { field: 'id', headerName: GetTranslatedValue('ASSETS.CAPTION.ID', 'ID'), width: 200 },
      { field: 'EPC', headerName: GetTranslatedValue('ASSETS.CAPTION.EPC', 'EPC'), width: 200 },
      { field: 'serial', headerName: GetTranslatedValue('ASSETS.CAPTION.SERIAL', 'Serial Number'), width: 200 }
    ];

    if (isPreviewTable) {
      if (processInfo?.processData?.selectedProcessType === 'creation') {
        if (isProcessComplete()) {
          return [
            { field: 'creationId', headerName: GetTranslatedValue('ASSETS.CAPTION.ID', 'ID'), width: 200 },
            { field: 'EPC', headerName: GetTranslatedValue('ASSETS.CAPTION.EPC', 'EPC'), width: 200 },
            { field: 'serial', headerName: GetTranslatedValue('ASSETS.CAPTION.SERIAL', 'Serial Number'), width: 200 },
            ...assetReference,
          ]
        } else {
          return assetReference;
        }
      } else {
        return [
          ...realAssetFields,
          ...assetReference,
        ]
      }
    } else if (isAssetReference) {
      return assetReference;
    } else {
      return [
        ...realAssetFields,
        ...assetReference,
      ]
    }
  };

  const getSelectedProfile = async (referenceId) => {
    return getOneDB('references/', referenceId)
      .then((response) => response.json())
      .then((data) => data.response?.selectedProfile)
      .catch((error) => dispatch(showErrorAlert()))
  };

  const userSkippedMessage = GetTranslatedValue('PROCESSES.INFO.USER.SKIPPED', 'User Skipped');
  const getMessages = (processData, asset) => {
    const result = (processData.stages || []).map(({ approvals, stageName }) => {
      const messages = [];
      approvals.map(({ name, lastName, email, cartRows }) => {
        if (!cartRows) {
          messages.push({ user: `${name} ${lastName} (${email})`, message: userSkippedMessage });
          return;
        }
        const thisAsset = cartRows.find(({ id }) => id === asset.id);
        if (thisAsset.message) {
          messages.push({ user: `${name} ${lastName} (${email})`, message: thisAsset.message })
        }
      })
      return ({ stageName, messages });
    });
    return result;
  };

  const approvedMessage = GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.APPROVED', 'Approved'); 
  const rejectedMessage = GetTranslatedValue('PROCESSES.INFO.ASSETS.STATE.REJECTED', 'Rejected'); 
  
  const handleAssetRows = async () => {
    const { cartRows, processData, created, notCreated } = processInfo;
    const stageFulfilled = (processData.stages || []).map(({ stageFulfilled }) => ({ stageFulfilled }));
    const isProcessComplete = stageFulfilled.every(({ stageFulfilled }) => stageFulfilled === true);
    const selfApprove = processData?.stages ? processData.stages[0]?.isSelfApprove || processData.selectedProcessType === 'short' : processData.selectedProcessType === 'short';

    if (isProcessComplete && stageFulfilled.length !== 0 && !selfApprove) {
      if (processData.selectedProcessType === 'creation' && !processInfo.updateProcess) {
        const validAssetsID = extractValidAssets(cartRows, processData).map(({ id }) => (id));
        const invalidAssetsID = cartRows.filter(({ id }) => !validAssetsID.includes(id)).map(({ id }) => (id)) || [];

        const tempAssetRows = cartRows.map((asset) => {
          const result = []
          const assetMessages = getMessages(processData, asset);
          if (validAssetsID.includes(asset.id)) {
            const notCreatedAsset = notCreated?.find(({ id }) => id === asset.id );
            const createdAssets = created?.filter(({ referenceId, creationAuxId }) => (referenceId === asset.referenceId && creationAuxId === asset.id));
            const legacyAsset = created?.find(({ referenceId }) => (referenceId === asset.id));
            
            if (notCreatedAsset && Array.isArray(notCreatedAsset.message)) {
              result.push({ ...notCreatedAsset, id: asset._id || asset.id, message: [...assetMessages, ...notCreatedAsset.message], status: 'rejected', creationId: asset._id });
            }
            if (!isEmpty(createdAssets)) {
              const multitpleAssets = createdAssets.map((createdAsset) => (
                ({ ...createdAsset, message: assetMessages, status: 'approved', creationId: createdAsset._id, locationName: asset.locationName })
              ));
              result.push(...multitpleAssets);
            } else if (legacyAsset) {
              result.push({ ...legacyAsset, message: assetMessages, status: 'approved', creationId: legacyAsset._id, locationName: asset.locationName })
            } else {
              const errorMessage = { stageName: 'System', messages: [{ user: 'Error', message: 'Something went wrong creating this asset' }] };
              result.push ({ ...asset, message: [...assetMessages, errorMessage], status: 'rejected', creationId: asset._id });
            }

            return result;
          }
          else if (invalidAssetsID.includes(asset.id)) {
            const notCreatedAsset = notCreated?.find(({ id }) => id === asset.id);
            if (notCreatedAsset && Array.isArray(notCreatedAsset.message)) {
              return ({ ...asset, id: asset._id || asset.id, message: [...assetMessages, ...notCreatedAsset.message], status: 'rejected', creationId: asset._id });
            } else {
              return ({ ...asset, id: asset._id || asset.id, message: assetMessages, status: 'rejected', creationId: asset._id });
            }
          }
          else {
            return ({ ...asset, id: asset._id || asset.id, creationId: asset._id });
          }
        });
        setAssetRows(tempAssetRows.flat());
      } else {
        const validAssetsID = extractValidAssets(cartRows, processData).map(({ id }) => (id));
        const invalidAssetsID = cartRows.filter(({ id }) => !validAssetsID.includes(id)).map(({ id }) => (id)) || [];

        const tempAssetRows = cartRows.map((asset) => {
          const assetMessages = getMessages(processData, asset);
          if (validAssetsID.includes(asset.id)) {
            return ({ ...asset, id: asset._id || asset.id, message: assetMessages, status: 'approved', creationId: asset._id });
          }
          else if (invalidAssetsID.includes(asset.id)) {
            return ({ ...asset, id: asset._id || asset.id, message: assetMessages, status: 'rejected', creationId: asset._id });
          }
          else {
            return ({ ...asset, id: asset._id || asset.id, creationId: asset._id });
          }
        });
        setAssetRows(tempAssetRows);
      }
    }
    else if (selfApprove && processData.selectedProcessType !== 'short') {
      const tempAssetRows = created?.map((asset) => ({ ...asset, id: asset._id, status: 'approved' })) || [];
      const processedCartrows = cartRows.map((asset) => ({ ...asset, status: 'approved' }));
      const dataToProcess = processData.selectedProcessType === 'creation' ? [...tempAssetRows, ...(notCreated || [])] : processedCartrows;
      const rows = await Promise.all(dataToProcess.map(async (asset) => {
        const locationPath = await getLocationPath(asset.location || asset.locationId);
        return { ...asset, locationName: locationPath, creationId: asset._id };
      }));
      console.log({rows})
      setAssetRows(rows);
    } else if (processData.selectedProcessType === 'short') {
        const processedCartrows = cartRows.map((asset) => ({ ...asset, status: 'approved' }));
        setAssetRows(processedCartrows);
    }
  };

  useEffect(() => {
    if (processInfo && Object.keys(processInfo).length) {
      handleAssetRows();
    }
    setIsDisabled(assetEditionDisabled)
  }, [processInfo]);

  useEffect(() => {
    getDB('settingsProcesses')
    .then((response) => response.json())
    .then(({ response }) => {
      
      const maxQuantity = response[0].maxAssetsQuantity;
      setMaxQuantity(maxQuantity)
      
    }).catch((error) => console.log('Error'));
  }, [])

  const [searchText, setSearchText] = useState('');
  const [assetRows, setAssetRows] = useState(rows);

  useEffect(() => {
    if (processInfo && Object.keys(processInfo).length) {
      const { processData } = processInfo;
      const stageFulfilled = processData.stages || [].map(({ stageFulfilled }) => ({ stageFulfilled }));
      const isProcessComplete = stageFulfilled.every(({ stageFulfilled }) => stageFulfilled === true);

      if (isProcessComplete) {
        return;
      }
    }
    if (rows === assetRows || !rows.length || !rows) {
      return;
    }
    setAssetRows(rows);
  }, [rows])

  const handleChangeValues = (values) => {
    const newValues = omit(values, ['id']);
    const idsToChange = selectedRows.map(({ id }) => id);
    const temporalCartRows = [...assetRows];

    const newCartRows = temporalCartRows.map((asset) => {
      if (idsToChange.includes(asset.id)) {
        return { ...asset, ...newValues };
      }
      return asset;
    })
    setAssetRows(newCartRows);
  }

  const getLocationsFilter = (collectionName) => {
    if (collectionName !== 'assets') {
      return null;
    } else {
      return parentLocations;
    }
  };

  const handleOnSearchClick = async (limit = 5, skip = 0, sort = undefined) => {
    if (isEmpty(searchText)) {
      setAssetRows([]);
      setTotalRows(0);
      return;
    }

    setLoading(true);

    if (sort && Array.isArray(sort)) {
      setCurrentSorting(sort);
    }

    const queryLike = ['name', 'brand', 'model', 'EPC', 'locationPath', 'serial'].map(key => ({ key, value: searchText }));
    const condition = !isAssetReference ? [{ "status": "active" }] : null;
    const locationsFilter = !isAssetReference ? parentLocations : null;
    const locationsFilterParam = !isAssetReference ? "location" : null;

    getCountDB({ collection, queryLike, condition, locationsFilter, locationsFilterParam })
      .then(response => response.json())
      .then(data => {
        setTotalRows(data.response.count);
      });

    getDBComplex({ collection, queryLike, condition, limit, skip, sort, locationsFilter, locationsFilterParam })
      .then(response => response.json())
      .then(async data => {
        const rows = await Promise.all(data.response.map(async row => {
          const { name, brand, model, _id: id, serial = 'sn', fileExt, customFieldsTab, location, EPC } = row;
          const assigned = !!row.assigned;
          if (isAssetReference) {
            const { selectedProfile } = row;
            return {
              name,
              brand,
              model,
              id,
              assigned,
              fileExt,
              selectedProfile,
              customFieldsTab,
              serial: '',
              notes: '',
              quantity: '1',
              purchase_date: '',
              purchase_price: '0',
              price: '0',
              location: '',
            };
          }
          const locationPath = await getLocationPath(row.location);
          const selectedProfile = await getSelectedProfile(row.referenceId);
          const history = row.history || [];
          return { name, brand, model, id, serial, assigned, fileExt, originalLocation: locationPath, history, location, selectedProfile, EPC };
        }));
        setAssetRows(rows);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const isAssetEdition = () => {
    return processInfo?.processData?.stages[processInfo.processData.currentStage - 1]?.isAssetEdition;
  }


  const showLocationTree = () => {
    if (isLinkedToProcess || processType === 'decommission' || processType === 'maintenance') {
      return false;
    }

    return true;
  }

  const handleSelectionChange = (selection) => {
    if (!isAssetReference) {
      onSelectionChange(selection);
    } else {
      const selectionModified = selection.rows.map((reference) => ({
        ...reference,
        id: uuidv4().split('-').pop(),
        referenceId: reference.id,
      }));
      onSelectionChange({rows: selectionModified});
    }
    
    if (selection.rows.length) {
      setSelectedRows(selection.rows);
      if (processType === 'creation') {
        const { id, referenceId, creationAuxId} = selection.rows.slice(-1)[0];
        getOneDB('references/', referenceId || (creationAuxId && creationAuxId.length > 12 ? creationAuxId : id))
          .then((response) => response.json())
          .then((data) => {
            const { fileExt, _id } = data.response;
            const picUrl = fileExt ? getImageURL(_id, 'references', fileExt) : defaultAsset.picUrl;
            setSelectedAsset({ ...selection.rows.slice(-1)[0], picUrl});
          })
          .catch((error) => console.log(error));
      } else {
        const { id, fileExt } = selection.rows.slice(-1)[0];
        const innerCollection = isAssetReference ? 'references' : 'assets';
        const picUrl = fileExt ? getImageURL(id, innerCollection, fileExt) : defaultAsset.picUrl;
        setSelectedAsset({ ...selection.rows.slice(-1)[0], picUrl });
      }
    } else {
      setSelectedRows([]);
      setSelectedAsset(defaultAsset);
    }
  };

  const renderContent = () => {
    const handleTreeElement = () => {
      if (!locationSelected) {
        return;
      }

      const { id, profileLevel, name } = locationSelected;
      if (profileLevel < 0) {
        dispatch(showCustomAlert({
          type: 'warning',
          open: true,
          message: 'This is not a valid location'
        }));
        return;
      }

      if (isEmpty(selectedRows)) {
        dispatch(showCustomAlert({
          type: 'warning',
          open: true,
          message: 'First choose an asset'
        }));
        return;
      }

      if (!userLocations.includes(id)) {
        dispatch(showCustomAlert({
          type: 'warning',
          open: true,
          message: `You don't have access to this location`
        }));
        return;
      }

      const cartRows = rows.map((row) => {
        if (selectedRows.find((asset) => asset.id === row.id)) {
          return { ...row, locationName: name, locationId: id };
        } else {
          return row;
        }
      });
      onSetRows(cartRows);
    };

    const handleRemoveElement = () => {
      const cartRows = rows.reduce((acu, cur) => {
        if (!selectedRows.find((asset) => asset.id === cur.id)) {
          return [...acu, cur];
        } else {
          return acu;
        }
      }, []);
      onSetRows(cartRows);
    };

    if (isSelectionTable) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button type="button" style={{ width: '200px', alignSelf: 'flex-end', marginBottom: '15px' }} onClick={handleRemoveElement} className='btn btn-secondary btn-elevate kt-login__btn-secondary'>
            <i className="la la-trash" /> {GetTranslatedValue('ASSET.PREVIEW.FINDER.PREVIEW.REMOVE.ASSETS','Remove Assets')}
          </button>
          <div class={classes.selectedWrapper}>
            <div class={classes.selectedTableWrapper}>
              <div onTouchStart={(e) => setPreventScroll(true)} onTouchEnd={() => setPreventScroll(false)}>
                {
                  processType === 'creation' && !isLinkedToProcess ? (
                    <Table className={classes.table} columns={[...getColumns(isAssetReference), locationColumn]} rows={rows} setTableRowsInner={handleSelectionChange} />
                  ) : processType === 'creation' && isLinkedToProcess ? (
                    <Table className={classes.table} columns={[...getColumns(isAssetReference), originalLocationColumn]} rows={rows} setTableRowsInner={handleSelectionChange} />
                  ) : processType === 'decommission' || processType === 'maintenance' ? (
                    <Table className={classes.table} columns={[...getColumns(isAssetReference), originalLocationColumn]} rows={rows} setTableRowsInner={handleSelectionChange} />
                  ) : processType === 'movement' || processType === 'short' ? (
                    <Table className={classes.table} columns={[...getColumns(isAssetReference), originalLocationColumn, locationColumn]} rows={rows} setTableRowsInner={handleSelectionChange} />
                  ) : (
                    <Table className={classes.table} columns={[...getColumns(isAssetReference)]} rows={rows} setTableRowsInner={handleSelectionChange} />
                  )
                }
              </div>
            </div>
            <Card className={classes.card} >
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Typography>{GetTranslatedValue('ASSET.PREVIEW.FINDER.PREVIEW', 'Preview')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                  <AssetPreviewBox selectedAsset={selectedAsset} />
                </AccordionDetails>
              </Accordion>
              {
                processType === 'creation' &&
                <Accordion expanded={accordionControled.assetEditon}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => setAccordionControled((prev) => ({ ...prev, assetEditon: !prev.assetEditon }))} />} >
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography>{GetTranslatedValue('ASSET.EDITION.CAPTION.LABEL','Asset Edition')}</Typography>
                      {
                        accordionControled.assetEditon && (
                          <Tooltip title={GetTranslatedValue('ASSET.EDITION.CAPTION.TOOLTIP', 'Apply Changes')}>
                            <IconButton onClick={() => updateAssetValues(assetRows)} className={classes.iconButton} aria-label="search" size='small'>
                              <DoneIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }

                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AssetEdition maxQuantity={maxQuantity} assetEditionValues={selectedAsset} setAssetEditionValues={(values) => handleChangeValues(values)} />
                  </AccordionDetails>
                </Accordion>
              }
              {
                showLocationTree() &&
                <Accordion expanded={accordionControled.location}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => setAccordionControled((prev) => ({ ...prev, location: !prev.location }))} />} >
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography>{GetTranslatedValue('ASSET.PREVIEW.SELECT.LOCATION.LABEL', 'Set Location')}</Typography>
                      {
                        accordionControled.location && (
                          <Tooltip title={GetTranslatedValue('ASSET.PREVIEW.SELECT.LOCATION.TOOLTIP','Apply Location Selected')}>
                            <IconButton onClick={() => handleTreeElement()} className={classes.iconButton} aria-label="search" size='small'>
                              <DoneIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ overflow: 'auto', height: '275px', marginBottom: '10px' }}>
                    <LocationsTreeView onTreeElementClick={setLocationSelected} userLocations={userLocations} locationSelected={locationSelected} />
                  </AccordionDetails>
                </Accordion>
              }
            </Card>
          </div>
        </div>
      );
    } else if (isPreviewTable) {
      return (
        <div class={classes.selectedWrapper}>
          <div class={classes.selectedTableWrapper}>
            <div onTouchStart={(e) => setPreventScroll(true)} onTouchEnd={() => setPreventScroll(false)}>
              {
                processType === 'creation' && !isLinkedToProcess ? (
                  <Table className={classes.table} columns={[...processColumns, ...getColumns(isAssetReference), locationColumn]} rows={(assetRows.length > 0 && !isAssetEdition()) || isProcessComplete() ? assetRows : rows} setTableRowsInner={handleSelectionChange} />
                ) : processType === 'creation' && isLinkedToProcess ? (
                  <Table className={classes.table} columns={[...processColumns, ...getColumns(isAssetReference), originalLocationColumn]} rows={(assetRows.length > 0 && !isAssetEdition()) || isProcessComplete() ? assetRows : rows} setTableRowsInner={handleSelectionChange} />
                ) : processType === 'decommission' || processType === 'maintenance' ? (
                  <Table className={classes.table} columns={[...processColumns, ...getColumns(isAssetReference), originalLocationColumn]} rows={assetRows.length > 0 ? assetRows : rows} setTableRowsInner={handleSelectionChange} />
                ) : processType === 'movement' || processType === 'short' ? (
                  <Table className={classes.table} columns={[...processColumns, ...getColumns(isAssetReference), originalLocationColumn, locationColumn]} rows={assetRows.length > 0 ? assetRows : rows} setTableRowsInner={handleSelectionChange} />
                ) : (
                  <Table className={classes.table} columns={[...processColumns, ...getColumns(isAssetReference)]} rows={assetRows.length > 0 ? assetRows : rows} setTableRowsInner={handleSelectionChange} />
                )
              }
            </div>
          </div>
          <Card className={classes.card}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography>{GetTranslatedValue('ASSET.PREVIEW.FINDER.PREVIEW', 'Preview')}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column' }}>
                <AssetPreviewBox selectedAsset={selectedAsset} />
              </AccordionDetails>
            </Accordion>
            {
              processType === 'creation' &&
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography>{GetTranslatedValue('ASSET.EDITION.CAPTION.LABEL','Asset Edition')}</Typography>
                    {
                      !assetEditionDisabled() && (
                        <Tooltip title={GetTranslatedValue('ASSET.EDITION.CAPTION.TOOLTIP', 'Apply Changes')}>
                          <IconButton onClick={() => updateAssetValues(assetRows)} className={classes.iconButton} aria-label="search" size='small'>
                            <DoneIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <AssetEdition maxQuantity={maxQuantity} assetEditionValues={selectedAsset} setAssetEditionValues={(values) => handleChangeValues(values)} isDisabled={isDisabled} />
                </AccordionDetails>
              </Accordion>
            }
          </Card>
        </div>
      );
    } else {
      return (
        <div className={classes.tableWrapper}>
          <div onTouchStart={(e) => setPreventScroll(true)} onTouchEnd={() => setPreventScroll(false)} style={{ width: '100%' }}>
            {
              isAssetReference ? (
                <Table className={classes.table} columns={[...getColumns(isAssetReference)]} rows={assetRows} loading={loading} setTableRowsInner={handleSelectionChange} />
              ) : (
                <Table className={classes.table}
                  columns={[...getColumns(isAssetReference), originalLocationColumn]}
                  paginated={paginated}
                  totalRows={totalRows}
                  rows={assetRows}
                  setTableRowsInner={handleSelectionChange}
                  updatePagination={({ pageSize, page }) => {
                    handleOnSearchClick(pageSize, pageSize * (page - 1), currentSorting[0].key.length ? currentSorting : null)
                  }}
                  updateSort={(recibo, currentPage) => {
                    const { sortModel } = recibo;
                    if (!sortModel.length || !assetRows.length || !searchText.length) {
                      if (!searchText.length && assetRows.length) {
                        setAssetRows([]);
                        setTotalRows(0);
                      }
                      return;
                    }
                    const transformed = [{ key: sortModel[0].field === 'originalLocation' ? 'locationPath' : sortModel[0].field, value: sortModel[0].sort === 'asc' ? 1 : -1 }];
                    if (transformed[0].key !== currentSorting[0].key || transformed[0].value !== currentSorting[0].value) {
                      handleOnSearchClick(5, 5 * (currentPage - 1), transformed);
                    }
                  }}
                  loading={loading}
                />
              )
            }
          </div>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <AssetPreviewBox selectedAsset={selectedAsset} />
            </CardContent>
          </Card>
        </div>
      );
    }
  };

  const processColumns = [
    {
      field: 'status',
      headerName: GetTranslatedValue('GENERAL.CAPTION.STATUS', 'Status'),
      width: 130,
      renderCell: (params) => {
        const { value } = params;
  
        return value ? (
          <Chip
            icon={value.toLowerCase() === 'rejected' ? <ThumbDownIcon /> : <ThumbUpIcon />}
            label={GetTranslatedValue(`PROCESSES.INFO.ASSETS.STATE.${value.toUpperCase()}`)}
            style={{ backgroundColor: value.toLowerCase() === 'rejected' ? '#DC2424' : '#1A9550' }}
            // clickable
            color='secondary'
          />
        ) : <i style={{ color: '#7f7f7f' }}>Approval Pending</i>;
      }
    },
    {
      field: 'message', headerName: GetTranslatedValue('GENERAL.CAPTION.MESSAGE', 'Message'), width: 130, renderCell: (params) => {
        if (Array.isArray(params.data.message)) {
          return (
            <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
              {
                params.data.message.length > 0 && (
                  <CustomizedToolTip
                    tooltipContent={
                      <ol style={{ marginTop: '15px', marginRight: '20px' }}>
                        {
                          params.data.message.map(({ stageName, messages }) => (
                            <li style={{ marginTop: '10px' }}>
                              <h6>{` ${stageName}`}</h6>
                              <ul style={{ paddingLeft: '5px' }}>
                                {
                                  (messages || []).map(({ user, message }) => {
                                    return (
                                      <li style={{ marginBottom: '5px', fontWeight: 'normal', color: '#b2b2b2' }}>
                                        <p>{`${user}: `}<i>{message}</i></p>
                                      </li>
                                    )
                                  })
                                }
                                {
                                  (isEmpty(messages)) &&
                                  <li style={{ marginBottom: '5px', fontWeight: 'normal', color: '#b2b2b2' }}>
                                    <p><i>{GetTranslatedValue('GENERAL.CAPTION.NO.MESSAGES','no messages')}</i></p>
                                  </li>
                                }
                              </ul>
                            </li>
                          ))
                        }
                      </ol>
                    }
                    content={
                      <Chip
                        label={`${GetTranslatedValue('GENERAL.CAPTION.MESSAGES', 'Messages')}: ${params.data.message.length}`}
                        style={{ backgroundColor: '#8e8e8e', height: '28px' }}
                        color='secondary'
                        onClick={() => {}}
                      />
                    }
                  />
                )
              }
              {
                params.data.message.length === 0 && (
                  <Chip
                    label={GetTranslatedValue('GENERAL.CAPTION.NO.MESSAGES','no messages')}
                    style={{ backgroundColor: '#8e8e8e', height: '28px' }}
                    color='secondary'
                    onClick={() => {}}
                  />
                )
              }
            </div>
          );
        }
        else if (params.data.message) {
          return (
            <CustomizedToolTip tooltipContent={params.data.message} content={
              <span style={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.data.message}</span>
            } />
          );
        } else {
          return (
            <i style={{ color: '#7f7f7f' }}>{GetTranslatedValue('GENERAL.CAPTION.NO.MESSAGES','no messages')}</i>
          );
        }
      }
    },
  ];

  const locationColumn = {
    field: 'locationName', headerName: GetTranslatedValue('ASSET.PREVIEW.FINDER.FINAL.LOCATION', 'Final Location'), width: 200, renderCell: (params) => (
      <CustomizedToolTip tooltipContent={params.data.locationName} content={
        <span style={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.data.locationName}</span>
      } />
    ),
  };
  const originalLocationColumn = {
    field: 'originalLocation', headerName: GetTranslatedValue('ASSET.PREVIEW.FINDER.ORIGINAL.LOCATION', 'Original Location'), width: 200, renderCell: (params) => (
      <CustomizedToolTip tooltipContent={params.data.originalLocation} content={
        <span style={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.data.originalLocation}</span>
      } />
    ),
  };

  return (
    <div>
      {(!isSelectionTable && showSearchBar) && (
        <Paper className={classes.root} style={{ marginTop: '10px', width: '100%' }}>
          <InputBase
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleOnSearchClick();
              }
            }}
            className={classes.input}
            placeholder={GetTranslatedValue('ASSET.PREVIEW.FINDER.SEARCH.ASSET', 'Search Assets')}
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton onClick={() => handleOnSearchClick()} className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      )}
      {renderContent()}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '-30px',
    marginBottom: '30px'
  },
  table: {
    width: '60%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tableWrapper: {
    display: 'flex'
  },
  selectedTableWrapper: {
    width: '60%'
  },
  selectedWrapper: {
    display: 'flex'
  },
  card: {
    width: '40%',
    marginLeft: '15px'
  },
  cardContent: {
    minHeight: '400px'
  },
  '@media (max-width: 900px)': {
    tableWrapper: {
      flexDirection: 'column-reverse',
    },
    card: {
      width: '100%',
      marginBottom: '10px',
      marginLeft: '0px'
    },
    cardContent: {
      minHeight: '0px'
    },
    selectedTableWrapper: {
      width: '100%'
    },
    selectedWrapper: {
      flexDirection: 'column-reverse',
    }
  },
}));

const defaultAsset = {
  id: '',
  brand: '',
  model: '',
  name: '',
  picUrl: 'https://icon-library.com/images/photo-placeholder-icon/photo-placeholder-icon-6.jpg'
};


AssetFinder.defaultProps = {
  isAssetReference: false,
  isSelectionTable: false,
  showSearchBar: true,
  onSelectionChange: () => {},
  rows: [],
  onSetRows: () => {},
  isPreviewTable: false,
  processType: 'default',
  updateAssetValues: () => {},
  showAssetEdition: () => false,
  assetEditionDisabled: () => true,
  isLinkedToProcess: false,
  paginated: false,
  userLocations: [],
  parentLocations: []
};

AssetFinder.propTypes = {
  isAssetReference: PropTypes.bool,
  isSelectionTable: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  rows: PropTypes.array,
  onSetRows: PropTypes.func,
  isPreviewTable: PropTypes.bool,
  processType: PropTypes.string,
  processInfo: PropTypes.object,
  updateAssetValues: PropTypes.func,
  showAssetEdition: PropTypes.func,
  assetEditionDisabled: PropTypes.func,
  isLinkedToProcess: PropTypes.bool,
  paginated: PropTypes.bool,
  userLocations: PropTypes.array,
  parentLocations: PropTypes.array,
}



export default AssetFinder;
