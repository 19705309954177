import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import {
  Collapse,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
  Paper
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import IndentedTable from './IndentedTable';
import CircularProgressCustom from './CircularProgressCustom';
import { findConditionRecursive } from './tableGroupingHelpers';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    cursor: 'pointer',
    width: '100%',
  },
  root2: {
    '& > *': {
      borderBottom: 'unset',
    },
    cursor: 'pointer',
    width: '100%',
    display: 'flex'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
}));

const Row = ({ row, retrieveOpenData, tableProps, controlFunctions, permissions, tableGroupingProps, loading, isFirst }) => {
  const [open, setOpen] = useState(row.active);
  const classes = useRowStyles();
  const { id, value, count } = row;
  const { controlValues, headRows } = tableProps;
  const { handleChangeOrderBy, handleChangePage, handleChangeRowsPerPage, onEdit, onDelete, onView } = controlFunctions;

  useEffect(() => {
    setOpen(row.active);
  }, [row]);

  const handleOpen = () => {
    setOpen(!open);
    if (!open === true) {
      retrieveOpenData(id);
    } else {
      controlValues.tableGrouping[controlValues.tableGrouping.findIndex((node) => node.id === id)] = {...row, active: false}
      tableGroupingProps.setTableGroupingControl(controlValues.tableGrouping.filter(({ parent }) => parent !== id))
    }
  };

  const handleLocalChangePage = async (event, newPage) => {
    handleChangePage(newPage, row);
  };

  const handleLocalChangeRowsPerPage = (event) => {
    handleChangeRowsPerPage(event.target.value, row)
  };

  return (
    <>
      <TableRow
        className={clsx({
          [classes.root]: isFirst,
          [classes.root2]: !isFirst
        })}
        onClick={() => handleOpen()}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography>{`${value} (${count})`}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan='100%'>
          <Collapse in={open} timeout="auto" unmountOnExit style={{ width: '100%' }}>
            {
              loading && loading === id ? (
                <div style={{
                  width: '100%',
                  height: 49 * (5 + 2.5),
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}>
                  <CircularProgressCustom size={40} />
                </div>
              ) : (
                row.type === 'data' ? (
                  <IndentedTable
                    style={{ width: '100%' }}
                    headRows={headRows}
                    thisNode={row}
                    rows={row.children}
                    handleChangeOrderBy={handleChangeOrderBy}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    permissions={permissions}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onView={onView}
                  />
                ) : (
                  <div style={{ padding: '0px' }}>
                    <Paper className={classes.paper}>
                      <Table>
                        <TableGrouping
                          treeData={controlValues.tableGrouping || []}
                          thisNode={row}
                          fetchData={tableGroupingProps.fetchData}
                          setTableGroupingControl={tableGroupingProps.setTableGroupingControl}
                          headRows={headRows}
                          styleRows={tableGroupingProps.styleRows}
                          tableProps={tableGroupingProps.tableProps}
                          permissions={tableGroupingProps.permissions}
                          onEdit={tableGroupingProps.onEdit}
                          onDelete={tableGroupingProps.onDelete}
                          onView={tableGroupingProps.onView}
                          tableGroupingValues={tableGroupingProps.tableGroupingValues}
                        />
                        <TablePagination
                          backIconButtonProps={{
                            'aria-label': 'Previous Page'
                          }}
                          component='div'
                          count={row.count}
                          labelDisplayedRows={({ from, to, count }) => {
                            if (count === 0) return 'No Pages';
                            const currentPage = row.query.page + 1;
                            const totalPages = Math.floor(count / row.query.rowsPerPage) + 1;
                            return `Page ${currentPage}/${totalPages}`;
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'Next Page'
                          }}
                          onChangePage={handleLocalChangePage}
                          onChangeRowsPerPage={handleLocalChangeRowsPerPage}
                          page={row.query.page}
                          rowsPerPageOptions={[5, 10, 25]}
                          rowsPerPage={row.query.rowsPerPage}
                        />
                      </Table>
                    </Paper>
                  </div>
                )
              )
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const TableGrouping = (props) => {
  const {
    treeData,
    thisNode,
    setTableGroupingControl,
    fetchData,
    styleRows,
    tableProps,
    permissions,
    onEdit,
    onDelete,
    onView,
    tableGroupingValues,
    isFirst = false
  } = props;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([])

  const handleOpenNode = async (nodeId) => {
    setLoading(nodeId);
    const nodeToBeChanged = treeData.find(({ id }) => id === nodeId);
    const indexToBeEdited = treeData.findIndex(({ id }) => id === nodeId);
    const objectCopy = [...treeData];
    const condition = findConditionRecursive(treeData, nodeToBeChanged, []);

    const nodeChanged = { ...nodeToBeChanged, active: true, type: !tableGroupingValues[nodeToBeChanged.depth] ? 'data' : 'node', query: { ...nodeToBeChanged.query, condition, orderBy: nodeToBeChanged.field, page: 0 } };
    if (nodeChanged.type === 'data') {
      const blocked = nodeChanged.query.condition.map((cond) => {
        return Object.entries(cond).flat()[0];
      });

      const orderBy = tableProps.headRows.find(({ id }) => !blocked.includes(id));
      nodeChanged.query.orderBy = orderBy.id;

      const newData = await fetchData(nodeChanged);
      nodeChanged.children = styleRows(newData.data);
      objectCopy.splice(indexToBeEdited, 1, nodeChanged);
      setTableGroupingControl([...objectCopy]);
    } else {
      if (!tableGroupingValues[nodeChanged.depth + 1]?.id) {
        const field = tableGroupingValues[nodeToBeChanged.depth]?.correction || tableGroupingValues[nodeToBeChanged.depth].id ;
        const query = { rowsPerPage: nodeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: 0, condition };
        const fakeNode = { field: field, value: 'empty', active: false, query, type: 'node', parent: nodeChanged.id, children: [] };
        const fetchResult = await fetchData(fakeNode);

        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query, type: 'node', parent: nodeChanged.id, children: [], depth: nodeChanged.depth + 1 };
        });
        nodeChanged.children = dataToNewNodes.map(({ id }) => id);
        nodeChanged.count = fetchResult.total.count;
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);

        setTableGroupingControl([...objectCopy, ...dataToNewNodes]);
      } else {
        const field = tableGroupingValues[nodeToBeChanged.depth]?.correction || tableGroupingValues[nodeToBeChanged.depth].id ;
        const query = { rowsPerPage: nodeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: 0, condition };
        const fakeNode = { field: field, value: 'empty', active: false, query, type: 'node', parent: nodeChanged.id, children: [] };
        const fetchResult = await fetchData(fakeNode);

        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query, type: 'node', parent: nodeChanged.id, children: [], depth: nodeChanged.depth + 1 };
        });
        nodeChanged.children = dataToNewNodes.map(({ id }) => id);
        nodeChanged.count = fetchResult.total.count;
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);

        const treeCopy = [...dataToNewNodes];

        const treeCopy2 = await Promise.all(
          treeCopy.map((node) => {
            const condition = findConditionRecursive(treeData, node, []);
            return fetchData({ ...node, field: tableGroupingValues[node.depth]?.correction || tableGroupingValues[node.depth]?.id, query: { ...node.query, condition } });
          })
        );
        const treeCopy3 = treeCopy.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count }));
        setTableGroupingControl([...objectCopy, ...treeCopy3]);
      }
    }
    setLoading(false); 
  };

  const controlFunctions = {
    handleChangeOrderBy: async (orderByField, nodeToBeChanged) => {
      const indexToBeEdited = treeData.findIndex(({ id }) => id === nodeToBeChanged.id);
      const objectCopy = [...treeData];
      const nodeChanged = { ...nodeToBeChanged, query: { ...nodeToBeChanged.query, orderBy: orderByField, order: nodeToBeChanged.query.order === 1 ? -1 : 1 } };

      const newData = await fetchData(nodeChanged);
      nodeChanged.children = styleRows(newData.data);
      objectCopy.splice(indexToBeEdited, 1, nodeChanged);
      setTableGroupingControl([...objectCopy]);
    },
    handleChangePage: async (newPage, nodeToBeChanged) => {
      setLoading(nodeToBeChanged.id);
      const indexToBeEdited = treeData.findIndex(({ id }) => id === nodeToBeChanged.id);
      if (nodeToBeChanged.type === 'data') {
        const objectCopy = [...treeData];
        const nodeChanged = { ...nodeToBeChanged, query: { ...nodeToBeChanged.query, page: newPage } };

        const newData = await fetchData(nodeChanged);
        nodeChanged.children = styleRows(newData.data);
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);
        setTableGroupingControl([...objectCopy]);
      } else if (tableGroupingValues[nodeToBeChanged.depth +1]) {
        const objectCopy = [...treeData];
        const condition = findConditionRecursive(treeData, nodeToBeChanged, []);
        const field = tableGroupingValues[nodeToBeChanged.depth]?.correction || tableGroupingValues[nodeToBeChanged.depth].id ;
        const nodeChanged = { ...nodeToBeChanged, query: { ...nodeToBeChanged.query, page: newPage }};
        const query = { rowsPerPage: nodeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: newPage, condition };

        const fakeNode = { field: field, value: 'empty', active: false, query, type: 'node', parent: nodeChanged.id, children: [] };
        const fetchResult = await fetchData(fakeNode);

        const dataToNewNodes = fetchResult.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query, type: 'node', parent: nodeChanged.id, children: [], depth: nodeChanged.depth + 1 };
        });
        nodeChanged.children = dataToNewNodes.map(({ id }) => id);
        nodeChanged.count = fetchResult.total.count;
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);

        const treeCopy2 = await Promise.all(
          dataToNewNodes.map((node) => {
            const condition = findConditionRecursive(treeData, node, []);
            return fetchData({ ...node, field: tableGroupingValues[node.depth]?.id, query: { ...node.query, condition } });
          })
        );
        const treeCopy3 = dataToNewNodes.map((node, ix) => ({ ...node, active: false, children: [], count: treeCopy2[ix].total.count }));
        setTableGroupingControl([...objectCopy, ...treeCopy3]);
      } 
      else {
        const condition = findConditionRecursive(treeData, nodeToBeChanged, []);
        const objectCopy = treeData.filter(({ id }) => !nodeToBeChanged.children.includes(id));
        const query = { rowsPerPage: nodeToBeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: 0, condition };

        const nodeChanged = { ...nodeToBeChanged, query: { ...nodeToBeChanged.query, page: newPage } };
        const field = tableGroupingValues[nodeToBeChanged.depth]?.correction || tableGroupingValues[nodeToBeChanged.depth].id ;

        const fakeNode = { field: field, value: 'empty', active: false, query: nodeChanged.query, type: 'node', parent: nodeChanged.id, children: [] };

        const newData = await fetchData(fakeNode);
        const dataToNewNodes = newData.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query, type: 'node', parent: nodeChanged.id, children: [], depth: nodeChanged.depth + 1 };
        });

        nodeChanged.children = dataToNewNodes.map(({ id }) => id);
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);

        setTableGroupingControl([...objectCopy, ...dataToNewNodes]);
      }
      setLoading(false);
    },
    handleChangeRowsPerPage: async (newValue, nodeToBeChanged) => {
      setLoading(nodeToBeChanged.id);

      const indexToBeEdited = treeData.findIndex(({ id }) => id === nodeToBeChanged.id);
      if (nodeToBeChanged.type === 'data') {
        const objectCopy = [...treeData];
        const nodeChanged = { ...nodeToBeChanged, query: { ...nodeToBeChanged.query, rowsPerPage: newValue } };

        const newData = await fetchData(nodeChanged);
        nodeChanged.children = styleRows(newData.data);
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);
        setTableGroupingControl([...objectCopy]);
      } else {
        const condition = findConditionRecursive(treeData, nodeToBeChanged, []);
        const objectCopy = treeData.filter(({ id }) => !nodeToBeChanged.children.includes(id));
        const query = { rowsPerPage: nodeToBeChanged.query.rowsPerPage, orderBy: '_id', order: 1, page: 0, condition };

        const nodeChanged = { ...nodeToBeChanged, query: { ...nodeToBeChanged.query, rowsPerPage: newValue } };
        const field = tableGroupingValues[nodeToBeChanged.depth]?.correction || tableGroupingValues[nodeToBeChanged.depth].id ;

        const fakeNode = { field: field, value: 'empty', active: false, query: nodeChanged.query, type: 'node', parent: nodeChanged.id, children: [] };

        const newData = await fetchData(fakeNode);
        const dataToNewNodes = newData.data.map(({ _id, count }) => {
          return { id: uuidv4().split('-').pop(), field: field, value: _id, count, active: false, query, type: 'node', parent: nodeChanged.id, children: [], depth: nodeChanged.depth + 1 };
        });

        nodeChanged.children = dataToNewNodes.map(({ id }) => id);
        objectCopy.splice(indexToBeEdited, 1, nodeChanged);

        setTableGroupingControl([...objectCopy, ...dataToNewNodes]);
      }
      setLoading(false);
    },
    onEdit,
    onDelete,
    onView
  }

  useEffect(() => {
    const localData = thisNode.type === 'data' ? thisNode.children : treeData.filter(({ id }) => thisNode.children.includes(id));
    setData(localData);
  }, [treeData])

  return (
    <>
      {
        data.map((row) => (
          <Row
            key={row.id}
            row={row}
            retrieveOpenData={handleOpenNode}
            tableProps={tableProps}
            thisNode={thisNode}
            controlFunctions={controlFunctions}
            permissions={permissions}
            tableGroupingProps={props}
            loading={loading}
            isFirst={isFirst}
          />
        ))
      }
    </>
  );
}


export default TableGrouping;
