import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { compareAsc } from 'date-fns';
import { TextField } from "@material-ui/core";
import './PasswordRecovery.scss';
import { getVerifyPasswordRecovery, changePassword } from "../../crud/api";

const PasswordRecovery = (props) => {
  const [status, setStatus] = useState({
    open: false,
    message: '',
    alert: 'error'
  });

  const [values, setValues] = useState({
    password: '',
    confirmPassword: ''
  });

  const [recoveryObject, setRecoveryObject] = useState({});

  const regex = new RegExp(props.regex || /\d+/, 'g');

  useEffect(() => {
    const code = window.location.href.split('?');
    
    getVerifyPasswordRecovery([{'code': code[code.length - 1]}])
      .then((response) => response.json())
      .then((data) => {
        
        const object = data.response[0];
              
        if (!data.response || !object) {
          setStatus({
            open: true,
            message: `The code provided is invalid, please try again.`,
            alert: 'error',
          });
        } else if (object.used === 'true' || object.used) {
          setStatus({
            open: true,
            message: `This code has already been used.`,
            alert: 'error',
          });
        } else if (compareAsc(Date.now(), object.timeStamp) > 0) {
          setStatus({
            open: true,
            message: `This code has expired, please try again.`,
            alert: 'error',
          });
        }

        setRecoveryObject(object);
      })
      .catch((error) => console.log('Error:', error))
  }, [])

  const handleSubmit = () => {
    changePassword({
      id: recoveryObject.userId,
      newPassword: values.password,
      recoveryObjectId: recoveryObject._id
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus({
          open: true,
          message: 'Your Password was changed correctly, please return to the Login',
          alert: 'success'
        });
      })
      .catch((error) => {
        console.log('error:', error)
        setStatus({
          open: true,
          message: 'There was an error, please try again',
          alert: 'error'
        })
      })
    
  };

  
  const disableSubmitButton = () => {
    if(!(values.password && values.confirmPassword && values.password === values.confirmPassword) || status.open){
      return true;
    } else if (values.email !== recoveryObject.email) {
      return true;
    }
    else {
      return false;
    }
  };

  const launchError = () => {
    if (values.password.length) {
      //This Console.log is necessary, if not the function doesn't work properly
      console.log('regex:', !regex.test(values.password) )
      return !regex.test(values.password);
    }
    return false;
  };

  const handleChange = (valueName) => (event) => {
    if (!event || !event.target) {
      return;
    }
    const value = event.target.value;
    setValues((prev) => ({
      ...prev,
      [valueName]: value
    }));
  };
  return (
    <>
      <div className='PR-loginTitle'>
          <h3>
            Password Recovery
          </h3>
      </div>
      <div className='PR-form-wrapper'>
        {status.open && (
          <div role="alert" className={`PR-${status.alert}-alert`} >
            <div>{status.message}</div>
          </div>
        )}
        <div className='PR-textfield-wrapper'>
          <TextField
            type="email"
            margin="normal"
            label="Email"
            className="kt-width-full"
            name="email"
            onChange={handleChange('email')}
            value={values.email}
            helperText={ values.email && recoveryObject.email && (values.email !== recoveryObject.email) ? `This email doesn't match with the code provided.` : ''}
            error={values.email && recoveryObject.email && (values.email !== recoveryObject.email)}
            disabled={status.open}
          />
        </div>
        <div className='PR-textfield-wrapper'>
          <TextField
            type="password"
            margin="normal"
            label="Password"
            className="kt-width-full"
            name="password"
            onChange={handleChange('password')}
            value={values.password}
            helperText={!regex.test(values.password) && values.password ? 'Please include at least one digit' : ''}
            error={launchError()}
            disabled={status.open}
          />
        </div>
        <div className='PR-textfield-wrapper'>
          <TextField
            type="password"
            margin="normal"
            label="Confirm Password"
            className="kt-width-full"
            name="confirmPassword"
            onChange={handleChange('confirmPassword')}
            value={values.confirmPassword}
            helperText={ values.password && values.confirmPassword && (values.password !== values.confirmPassword) ? 'Please verify that both passwords are the same' : ''}
            error={values.password && values.confirmPassword && (values.password !== values.confirmPassword)}
            disabled={status.open}
          />
        </div>
        <div className='PR-buttons-wrapper'>
          <Link to="/auth">
            <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary" stlye={{margin: '20px'}}>
              Login
            </button>
          </Link>

          <button
            disabled={disableSubmitButton()}
            className="btn btn-primary btn-elevate"
            stlye={{margin: '20px'}}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

export default PasswordRecovery;
