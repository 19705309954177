/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  FormControlLabel,
  Switch,
  Tab,
  TextField,
  Tabs,
  Typography,
  Tooltip
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { pick } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import HelpIcon from '@material-ui/icons/Help';
import { actions } from '../../../../store/ducks/general.duck';
import CustomFields from '../../Components/CustomFields/CustomFields';
import {
  postDB,
  getDB,
  getOneDB,
  updateDB,
  postFILE,
  getDBComplex,
  getCountDB
} from '../../../../crud/api';
import CustomRecordModal from '../../Components/CustomRecordModal';
import { GetTranslatedValue } from '../../utils';

function TabContainer4({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  content: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginTop: '0px',
      paddingTop: '30px'
    }
  },
  switch: {
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  labelPlacement: {
    marginLeft: '5px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0px'
    }
  }
}));

const ModalProcessStages = ({
  readOnly,
  showModal,
  setShowModal,
  reloadTable,
  id
}) => {
  const dispatch = useDispatch();
  const theme4 = useTheme();
  const [value4, setValue4] = useState(0);
  function handleChange4(event, newValue) {
    setValue4(newValue);
  }
  function handleChangeIndex4(index) {
    setValue4(index);
  }

  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    isAssetEdition: false,
    isCustomLockedStage: false,
    isSelfApprove: false,
    isSelfApproveContinue: false,
    isControlDueDate: false
  });
  const [types, setTypes] = useState([]);
  const emptyNameMessage = GetTranslatedValue('GENERAL.ALERT.NAME.REQUIRED', 'Please add a name');

  const { showCustomAlert, showSavedAlert, showUpdatedAlert } = actions;

  const handleChange = (name) => (event) => {
    if (name === 'selectedFunction') {
      if (event.target.value === 0) {
        setTypes([
          'Creation',
          'Movement',
          'Short Movement',
          'Decommission',
          'Maintenance'
        ]);
      } else if (event.target.value === 1) {
        setTypes(['Approval']);
      }
    }
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeCheck = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const updateProcesses = (body, _id) => {
    getDBComplex({
      collection: 'processes',
      condition: [{ "processStages": { "$elemMatch": { "id": _id } } }]
    })
      .then((response) => response.json())
      .then((data) => {
        Promise.all((data?.response || []).map((process) => {
          const { processStages } = process;
          const thisStageIndex = processStages.findIndex(({ id }) => id === _id);

          if (thisStageIndex >= 0 && processStages[thisStageIndex]) {
            const { goBackEnabled, goBackTo } = processStages[thisStageIndex];
            processStages[thisStageIndex] = { ...body, goBackEnabled, goBackTo, id: _id }
            const newBody = {
              ...process,
              processStages,
            }
            return updateDB('processes/', omit(newBody, '_id'), process._id).catch((error) => console.log('Update process error:', error));
          }
        })).then((_) => reloadTable());
      })
      .catch((error) => console.log('Error:', error))
  };

  const handleSave = () => {
    const body = {
      ...values,
      types,
      customFieldsTab,
      notifications,
      approvals
    };
    if (!values.name || !values.name.trim()) {
      dispatch(
        showCustomAlert({
          open: true,
          message: emptyNameMessage,
          type: 'warning'
        })
      );
      return;
    }

    if (!id) {
      postDB('processStages', body)
        .then((data) => data.json())
        .then((response) => {
          dispatch(showSavedAlert());
          const { _id } = response.response[0];
          saveAndReload('processStages', _id);
        })
        .catch((error) => console.log(error));
    } else {
      updateDB('processStages/', body, id[0])
        .then((data) => data.json())
        .then((response) => {
          dispatch(showUpdatedAlert());
          updateProcesses(body, id[0]);
          saveAndReload('processStages', id[0]);
        })
        .catch((error) => console.log(error));
    }

    handleCloseModal();
  };

  const getFileExtension = (file) => {
    if (!file) return '';
    const { type } = file;
    return type.split('/')[1];
  };

  const saveAndReload = (folderName, id) => {
    saveImage(folderName, id);
    reloadTable();
  };

  const saveImage = (folderName, id) => {
    if (image) {
      postFILE(folderName, id, image)
        .then((response) => {
          console.log('FIlE uploaded!', response);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleCloseModal = () => {
    setCustomFieldsTab({});
    setValues({
      name: '',
      isAssetEdition: false,
      isUserFilter: false,
      isCustomLockedStage: false,
      isSelfApprove: false,
      isSelfApproveContinue: false,
      isControlDueDate: false
    });
    setTypes([]);
    setShowModal(false);
    setValue4(0);
    setNotifications([]);
    setApprovals([]);
    setApprovalsOptions([]);
    setIsFirstStageInMaintenance(false);
  };

  const [users, setUsers] = useState([]);
  const [approvalsOptions, setApprovalsOptions] = useState([]);
  const [temporalApprovals, setTemporalApprovals] = useState([]);
  const [isFirstStageInMaintenance, setIsFirstStageInMaintenance] = useState(false);

  const isFirstStageInMaintenanceProcess = (stageId) => {
    return getDBComplex({
      collection: 'processes',
      condition: [{ selectedProcessType: 'maintenance', processStages: { $elemMatch: { id: stageId } } }]
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          let flag = false;

          for (let i = 0; i < data.response.length; i++) {
            const { selectedProcessType, processStages } = data.response[i];

            if (selectedProcessType === 'maintenance' && processStages[0].id === stageId) {
              flag = true;
              break;
            }
          }

          return flag;
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      (values.isSelfApprove || values.isSelfApproveContinue) &&
      approvals.length
    ) {
      setTemporalApprovals(approvals);
      setApprovals([]);
    }
    if (!values.isSelfApprove && !values.isSelfApproveContinue) {
      setApprovals(temporalApprovals);
    }
  }, [values.isSelfApprove, values.isSelfApproveContinue]);

  useEffect(() => {
    if (showModal) {
      getDBComplex({
        collection: 'user'
      })
        .then((response) => response.json())
        .then((userData) => {
          getDB('settingsGroups')
            .then((response) => response.json())
            .then((data) => {
              const groupUsers = data.response.map(
                ({ _id, name, members }) => ({
                  _id,
                  name,
                  email: `members: ${members?.length}`,
                  lastName: '',
                  isUserGroup: true,
                  members
                })
              );
              const users = userData.response.map((user) =>
                pick(user, ['_id', 'email', 'name', 'lastName'])
              );
              const approvalsTmp = userData.response.reduce((acc, user) => {
                if (user?.profilePermissions?.processes?.live?.includes('edit')) {
                  return [...acc, pick(user, ['_id', 'email', 'name', 'lastName'])]
                } else {
                  return acc;
                }
              }, []) || [];
              const bossUser = {
                _id: 'boss',
                email: 'auto',
                name: 'Direct',
                lastName: 'Boss'
              };
              const locationUser = {
                _id: 'locationManager',
                email: 'auto',
                name: 'Location',
                lastName: 'Manager'
              };
              const witnessUser = {
                _id: 'locationWitness',
                email: 'auto',
                name: 'Location',
                lastName: 'Witness'
              };
              const asssetSpecialistUser = {
                _id: 'assetSpecialist',
                email: 'auto',
                name: 'Asset',
                lastName: 'Specialist'
              };
              const initiator = {
                _id: 'initiator',
                email: 'auto',
                name: 'Process',
                lastName: 'Initiator'
              };
              setUsers([
                bossUser,
                locationUser,
                witnessUser,
                asssetSpecialistUser,
                initiator,
                ...groupUsers,
                ...users
              ]);
              setApprovalsOptions([
                bossUser,
                locationUser,
                witnessUser,
                asssetSpecialistUser,
                initiator,
                ...groupUsers,
                ...approvalsTmp

              ]);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }

    if (!id || !Array.isArray(id) || !showModal) {
      return;
    }

    getOneDB('processStages/', id[0])
      .then((response) => response.json())
      .then(async (data) => {
        const {
          types,
          customFieldsTab,
          notifications,
          approvals
        } = data.response;
        const obj = pick(data.response, [
          'name',
          'functions',
          'selectedFunction',
          'selectedType',
          'isAssetEdition',
          'isUserFilter',
          'isCustomLockedStage',
          'isSelfApprove',
          'isSelfApproveContinue',
          'isControlDueDate'
        ]);
        setValues(obj);
        setTypes(types);
        setCustomFieldsTab(customFieldsTab);
        setNotifications(notifications);
        setApprovals(approvals);

        if (!readOnly) {
          setIsFirstStageInMaintenance(await isFirstStageInMaintenanceProcess(id[0]));
        }
      })
      .catch((error) => console.log(error));
  }, [id, showModal]);

  const [customFieldsTab, setCustomFieldsTab] = useState({});

  const [image, setImage] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const onChangeNotificationsApprovals = (name) => (_, values) => {
    if (name === 'notifications') {
      setNotifications(values);
    } else if (name === 'approvals') setApprovals(values);
  };
  return (
    <CustomRecordModal
      id={id}
      handleCloseModal={handleCloseModal}
      handleSave={handleSave}
      key="Modal-Processes-Stages"
      readOnly={readOnly}
      showModal={showModal}
      tabs={
        <Tabs
          value={value4}
          onChange={handleChange4}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={`${!id ? GetTranslatedValue('PROCESSES.CAPTION.NEW.STAGE', 'New Stage') : GetTranslatedValue('PROCESSES.CAPTION.EDIT.STAGE', 'Edit Stage')} `} />
          <Tab label={GetTranslatedValue('GENERAL.CAPTION.CUSTOM.FIELDS', 'Custom Fields')} />
        </Tabs>
      }
      title="PROCESSES.STAGES.MODAL.CAPTION"
    >
      <SwipeableViews
        axis={theme4.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value4}
        onChangeIndex={handleChangeIndex4}
        slideStyle={{ overflow: 'hidden' }}
      >
        <TabContainer4 dir={theme4.direction}>
          <div className="profile-tab-wrapper" style={{ padding: '0px 10px' }}>
            <div className="profile-tab-wrapper__content">
              <TextField
                id="standard-name"
                label={GetTranslatedValue('RECORD.CAPTION.NAME')}
                className={classes.textField}
                value={values.name}
                onChange={handleChange('name')}
                margin="normal"
                inputProps={{
                  readOnly: readOnly
                }}
                style={{ marginTop: '0px' }}
              />
              {/* <FormControl className={classes.textField} style={{ marginTop: '10px' }}>
                        <InputLabel htmlFor="age-simple">Function</InputLabel>
                        <Select
                          value={values.selectedFunction}
                          onChange={handleChange('selectedFunction')}
                        >
                          {(values.functions || []).map((opt, ix) => (
                            <MenuItem key={`opt-${ix}`} value={ix}>{opt}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className={classes.textField} style={{ marginTop: '15px' }}>
                        <InputLabel htmlFor="age-simple">Type</InputLabel>
                        <Select
                          value={values.selectedType}
                          onChange={handleChange('selectedType')}
                        >
                          {(types || []).map((opt, ix) => (
                            <MenuItem key={`opt-${ix}`} value={ix}>{opt}</MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
              <Autocomplete
                style={{ marginTop: '15px' }}
                className={classes.textField}
                disabled={readOnly}
                multiple
                id="tags-standard"
                options={users}
                getOptionLabel={({ email, name, lastName }) =>
                  `${name} ${lastName} (${email})`
                }
                onChange={onChangeNotificationsApprovals('notifications')}
                defaultValue={notifications}
                value={notifications}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={GetTranslatedValue('TOPBAR.TOOLTIP.NOTIFICATIONS')}
                  />
                )}
              />
              <Autocomplete
                style={{ marginTop: '15px' }}
                className={classes.textField}
                disabled={
                  values.isSelfApprove ||
                  values.isSelfApproveContinue ||
                  readOnly
                }
                multiple
                id="tags-standard"
                options={approvalsOptions}
                getOptionLabel={({ email, name, lastName }) =>
                  `${name} ${lastName} (${email})`
                }
                onChange={onChangeNotificationsApprovals('approvals')}
                defaultValue={approvals}
                value={approvals}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={GetTranslatedValue('PROCESSES.CAPTION.APPROVALS')}
                  />
                )}
              />
            </div>
            <div
              className={clsx(classes.content, 'profile-tab-wrapper__content')}
            >
              <FormControlLabel
                value="start"
                classes={{
                  labelPlacementStart: classes.labelPlacement
                }}
                className={classes.switch}
                control={
                  <Switch
                    color="primary"
                    checked={values.isAssetEdition}
                    disabled={readOnly}
                    onChange={handleChangeCheck('isAssetEdition')}
                  />
                }
                label={GetTranslatedValue('PROCESSES.STAGES.CAPTION.ASSET.EDITION')}
                labelPlacement="start"
              />
              <FormControlLabel
                value="start"
                classes={{
                  labelPlacementStart: classes.labelPlacement
                }}
                className={classes.switch}
                control={
                  <Switch
                    color="primary"
                    checked={values.isCustomLockedStage}
                    disabled={readOnly}
                    onChange={handleChangeCheck('isCustomLockedStage')}
                  />
                }
                label={GetTranslatedValue('PROCESSES.STAGES.CAPTION.LOCKED')}
                labelPlacement="start"
              />
              {!readOnly && isFirstStageInMaintenance ? (
                <FormControlLabel
                  value="start"
                  classes={{
                    labelPlacementStart: classes.labelPlacement
                  }}
                  className={classes.switch}
                  control={
                    <>
                      <Tooltip
                        title={
                          <Typography>
                            {GetTranslatedValue('PROCESSES.SELF.APPROVE.LOCKED.MESSAGE')}
                          </Typography>
                        }
                        placement="top"
                      >
                        <HelpIcon fontSize="small" style={{ cursor: 'pointer', fill: '#8e8e8e' }} />
                      </Tooltip>
                      <Switch
                        color="primary"
                        checked={values.isSelfApprove}
                        disabled={readOnly || (!readOnly && isFirstStageInMaintenance)}
                        onChange={handleChangeCheck('isSelfApprove')}
                      />
                    </>
                  }
                  label={GetTranslatedValue('PROCESSES.CAPTION.SELF.APPROVE')}
                  labelPlacement="start"
                />
              ) : (
                <FormControlLabel
                  value="start"
                  classes={{
                    labelPlacementStart: classes.labelPlacement
                  }}
                  className={classes.switch}
                  control={
                    <Switch
                      color="primary"
                      checked={values.isSelfApprove}
                      disabled={readOnly || (!readOnly && isFirstStageInMaintenance)}
                      onChange={handleChangeCheck('isSelfApprove')}
                    />
                  }
                  label={GetTranslatedValue('PROCESSES.CAPTION.SELF.APPROVE')}
                  labelPlacement="start"
                />
              )}
              <FormControlLabel
                value="start"
                classes={{
                  labelPlacementStart: classes.labelPlacement
                }}
                className={classes.switch}
                control={
                  <Switch
                    color="primary"
                    checked={values.isSelfApproveContinue}
                    disabled={readOnly}
                    onChange={handleChangeCheck('isSelfApproveContinue')}
                  />
                }
                label={GetTranslatedValue('PROCESSES.CAPTION.SELF.APPROVE.CONTINUE')}
                labelPlacement="start"
              />
              <FormControlLabel
                value="start"
                classes={{
                  labelPlacementStart: classes.labelPlacement
                }}
                className={classes.switch}
                control={
                  <Switch
                    color="primary"
                    checked={values.isControlDueDate}
                    disabled={readOnly}
                    onChange={handleChangeCheck('isControlDueDate')}
                  />
                }
                label={GetTranslatedValue('PROCESSES.STAGES.CAPTION.CONTROL.DUE.DATE')}
                labelPlacement="start"
              />
            </div>
          </div>
        </TabContainer4>
        <TabContainer4 dir={theme4.direction}>
          <CustomFields
            customFieldsTab={customFieldsTab}
            setCustomFieldsTab={setCustomFieldsTab}
            readOnly={readOnly}
          />
        </TabContainer4>
      </SwipeableViews>
    </CustomRecordModal>
  );
};

export default ModalProcessStages;
